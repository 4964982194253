import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ReactComponent as TickMark } from "src/assets/TickMark.svg";
import { ReactComponent as TickMarkBlack } from "src/assets/TickMarkBlack.svg";

import { IdetailsPlanData } from "../cardsData";
import { PayWallWrapper } from "../styles";

import { POPUP_KEYS } from "src/redux/popups";
import { closePopup, openPopup } from "src/redux/popups/action/action.creators";

interface deatilPlan {
  data: IdetailsPlanData;
  key: number;
  isfrom?: boolean;
}
const DetailsPlan: React.FC<deatilPlan> = ({ data, isfrom }) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState<Boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.price === "200 €" || isfrom) {
      setIsHover(true);
    } else {
      setIsHover(false);
    }
  }, [data.price, isfrom]);

  return (
    <PayWallWrapper isHover={isHover}>
      <div
        className="inside_main_container"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => {
          if (isfrom ?? data.price === "200 €") {
            /* empty */
          } else {
            setIsHover(false);
          }
        }}
      >
        <div className="flex_main">
          <div className="price_detials">
            <div className="price_flex">
              <div className="price">{data.price}</div>
              {data.price === "200 €" ? <button>{t("BELIEBTESTE")}</button> : ""}
            </div>
            <div className="title">{data.titile}</div>
            <div className="description">{data.description}</div>
          </div>
          <div className="bullet">
            {data.bulletPoint.map((data: string, index: number) => {
              return (
                <div className="bullet_flex" key={index}>
                  <div className="circle">
                    {isHover ? <TickMarkBlack /> : <TickMark className="tickmark" />}
                  </div>
                  <div className="point_name">{data}</div>
                </div>
              );
            })}
          </div>
          <button
            className="choos_button"
            onClick={() => {
              if (!isfrom) {
                dispatch(closePopup(POPUP_KEYS.pay_wall));
                dispatch(openPopup(POPUP_KEYS.payment_with_card, { selectedPlan: data.index }));
              }
            }}
          >
            {t("Choose plan")}
          </button>
        </div>
      </div>
    </PayWallWrapper>
  );
};

export default DetailsPlan;
