import Button from "@mui/material/Button";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useAppSelector } from "src/hooks";

import SingleImg from "src/assets/application_step_1/consumption.building.1.png";
import MultiImg from "src/assets/application_step_1/consumption.building.2.png";
import { consumptionProfilesB2B, consumptionProfilesB2C } from "src/data";
import { PopupSkeleton } from "src/shared/components";

import { useStyles } from "./ConsumptionProfile.styles";

import { PopupActions } from "src/redux";
import { ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { ProjectSelectors } from "src/redux/selectors";

import "react-image-gallery/styles/css/image-gallery.css";

export const ConsumptionProfileDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();

  const { consumptionProfile } = useAppSelector(ProjectSelectors.getHousehold);
  const projectType = useAppSelector(ProjectSelectors.getProjectType);
  const { household: householdModel } = useSelector(ProjectSelectors.getModel);

  const [selectedProfile, setSelectedProfile] = useState(consumptionProfile.toString());

  const handleClose = (): void => {
    dispatch(PopupActions.closePopup(POPUP_KEYS.consumption_profile));
  };

  const setConsumptionProfile = useCallback(
    (i: string): void => {
      const profiles = projectType === "b2b" ? consumptionProfilesB2B : consumptionProfilesB2C;
      const selected = profiles[parseInt(i) as keyof typeof profiles];
      let required = householdModel.percentages.maxPossibleAutarky;
      if (selected) {
        required = selected.self_supply * 100;
      }

      dispatch(
        ProjectActions.updateHousehold({
          consumptionProfile: parseInt(i),
        }),
      );
      dispatch(
        ProjectActions.updateModel({
          household: {
            percentages: {
              autarky: Math.min(householdModel.percentages.maxPossibleAutarky, required),
            },
          },
        }),
      );
    },
    [dispatch, householdModel.percentages.maxPossibleAutarky, projectType],
  );

  const takeOverBtnClickHandler = (): void => {
    setConsumptionProfile(selectedProfile);
    handleClose();
  };

  const profiles = projectType === "b2b" ? consumptionProfilesB2B : consumptionProfilesB2C;

  return (
    <PopupSkeleton handleClose={handleClose} open={true}>
      <div className={classes.root}>
        <div className={classes.main_container}>
          <div className={classes.headerText}>
            <h1>{t("Which of the following building types matches you?")}</h1>
          </div>
          <div className={classes.profiles}>
            {Object.entries(profiles).map(([key, value], i) => (
              <div
                key={value.short_title}
                onClick={() => setSelectedProfile(key)}
                className={classes.inside_main}
                style={{
                  backgroundColor:
                    (selectedProfile === key && "rgba(253, 184, 0, 0.1)") || undefined,
                  border: (selectedProfile === key && "2px solid #2D4764") || undefined,
                }}
              >
                <img src={i % 2 === 0 ? MultiImg : SingleImg} alt="Home" />
                <h5 className={classes.center}>{t(value.name)}</h5>
              </div>
            ))}
          </div>
        </div>

        <div>
          <Button
            className={classes.button}
            style={{
              color: "white",
              background: "linear-gradient(226.42deg, #28334A 8.93%, #09152F 110.98%)",
              borderRadius: "10px",
            }}
            onClick={takeOverBtnClickHandler}
          >
            {t("Take Over")}
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  );
};
