import { NavigateFunction } from "react-router-dom";

import store from "src/redux/store";

export const logout = (navigate?: NavigateFunction): void => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("authMode");
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has("partner")) {
    window.location.replace(`/application?partner=${searchParams.get("partner")}`);
  } else {
    window.location.replace(`/application`);
  }

  // store.dispatch({
  //   type: "RESET_REDUX",
  // });
};
