import styled from "styled-components/macro";
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
  justify-content: center;
  padding: 15px 10px;
  width: 95%;
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
`;
export const Header = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const Header1 = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
export const HeaderText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #2d4764;
`;
export const HeaderText1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
`;
