import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useIsComponentAdded } from "src/hooks";

import { DownArrow } from "src/assets/svgs";
import { Mixpanel } from "src/mix-panel/mixpanel";
import * as trackingEvents from "src/mix-panel/trackingEvents";

import * as S from "./AddComponentsDropdown.styles";
import ItemList from "./components/ItemList/ItemList";

import { UserSelectors } from "src/redux/selectors";

export const AddComponentsDropdown: React.FC = () => {
  const { t } = useTranslation();

  const [showAddComponents, setShowAddComponents] = useState(false);
  const [isAnyInAddProcess, setIsAnyInAddProcess] = useState(false);

  const { areCarsAdded, isBatteryAdded, isHeatpumpAdded } = useIsComponentAdded();
  const areAllProductsAdded = isBatteryAdded && isHeatpumpAdded && areCarsAdded;
  const installerDetails = useSelector(UserSelectors?.getInstaller);

  const isAnyProductLeftToAdd = !areAllProductsAdded;

  if (areAllProductsAdded && !isAnyInAddProcess) return <></>;

  return (
    <>
      {showAddComponents && (
        <ItemList
          setIsAnyInAddProcess={setIsAnyInAddProcess}
          setShowAddComponents={setShowAddComponents}
        />
      )}
      {!showAddComponents && isAnyProductLeftToAdd && (
        <S.SubContainer222
          onClick={() => {
            if (!showAddComponents) {
              Mixpanel.track(trackingEvents.USER_CLICKS_ON_ADDCOMPONENTS_STEP1, {});
            }
            setShowAddComponents(!showAddComponents);
          }}
        >
          <S.AllSubMainText>
            <S.AllSubMain1Text>{t("ADD COMPONENTS")}</S.AllSubMain1Text>{" "}
            {`(${t("BATTERY")},${t("WALLBOX")}${
              !installerDetails || (installerDetails && installerDetails.isAllowHitPump)
                ? `,${t("HEATPUMP")}`
                : ""
            })`}
          </S.AllSubMainText>
          <S.SubContainer2222>
            <DownArrow />
          </S.SubContainer2222>
        </S.SubContainer222>
      )}
    </>
  );
};
