import { Button } from "@mui/material";
import {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Prediction, ThirdPartyApis } from "src/api";

import { useWindowDimensions, useKeyPress } from "src/hooks";

import { LocationIcon, RemoveSearchIcon, SearchIcon } from "src/assets/svgs";
import { useGoogleMapsAutocompleteAPI } from "src/hooks/apis";
import { parseReverseGeoGoogle } from "src/utils";

import { SearchSelectorWrapper } from "./styles";

import { POPUP_KEYS } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";
import { defaultPartnerCityPopupProps } from "src/redux/popups/reducer/reducer";
import { MapboxAddress } from "src/redux/project";
import { getInstaller } from "src/redux/user/selectors";
interface AddressSelectorProps {
  onAddressSelect: (mapboxAddress: MapboxAddress) => void;
  showPartnerCityPopup?: boolean;
}
const AddressSelector: React.FC<AddressSelectorProps> = (props: AddressSelectorProps) => {
  const { onAddressSelect, showPartnerCityPopup } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const prevCursorRef = useRef(0);

  const { width } = useWindowDimensions();
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");

  const { fetchData, response } = useGoogleMapsAutocompleteAPI();

  const [inputAddr, setInputAddr] = useState<string>("");
  const [matchingAddresses, setMatchingAddresses] = useState<Prediction[]>([]);
  const [selected, setSelected] = useState<any>(null);

  const [cursor, setCursor] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const installer = useSelector(getInstaller);

  useEffect(() => {
    if (!response) return;

    const { data } = response.data;

    if (data) {
      setMatchingAddresses(data.predictions);
    }
  }, [response]);

  useEffect(() => {
    const hasSomeResults = !!matchingAddresses.length;
    const shouldMoveCursor = hasSomeResults && (upPress || downPress);
    if (!shouldMoveCursor) return;

    setCursor((prevState) => {
      if (upPress) return Math.max(prevState - 1, 0);
      else return Math.min(prevState + 1, matchingAddresses.length - 1);
    });
  }, [matchingAddresses.length, downPress, upPress]);

  useEffect(() => {
    const hasSomeResults = !!matchingAddresses.length;
    if (!hasSomeResults) return;

    const nextAPIResult = matchingAddresses[cursor];
    setSelected(matchingAddresses[cursor]);

    const cursorHasChanged = prevCursorRef.current !== cursor;
    if (cursorHasChanged) {
      setInputAddr(matchingAddresses[cursor].description || "");
      prevCursorRef.current = cursor;
    }
  }, [matchingAddresses, cursor]);

  useEffect(() => {
    const hasSomeResults = !!matchingAddresses.length;
    const shouldSelectAddr = hasSomeResults && enterPress;
    if (!shouldSelectAddr) return;

    setInputAddr(matchingAddresses[cursor].description || "");
    setSelected(matchingAddresses[cursor]);
    setMatchingAddresses([]);
  }, [matchingAddresses, cursor, enterPress]);

  useEffect(() => {
    const hasSomeResults = !!matchingAddresses.length;
    const hasHovered = hasSomeResults && hoveredIndex !== null;
    if (!hasHovered) return;

    setCursor(hoveredIndex);
  }, [matchingAddresses.length, hoveredIndex]);

  const onClear = useCallback(() => {
    setInputAddr("");
    setMatchingAddresses([]);
    setSelected(null);
  }, []);

  const onInputChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const value = event.target.value;
      setInputAddr(value);

      const enteredEmptyAddr = value === "";
      if (enteredEmptyAddr) {
        onClear();
      } else {
        // makeMapboxApiCall(value);
        void fetchData({
          url: ThirdPartyApis.googleMapsAutocompleteQuery(value),
          method: "GET",
        });
      }
    },
    [fetchData, onClear],
  );

  const onDiscoverClick = useCallback<MouseEventHandler>(async () => {
    if (!selected) return;

    if (cursor) {
      const result: MapboxAddress | undefined = await parseReverseGeoGoogle(matchingAddresses[cursor].place_id);
      if (!result) return;
      setSelected(matchingAddresses[cursor]);
      if (result) {
        setInputAddr(result.complete);
        onAddressSelect(result);
      }
    }

    if (showPartnerCityPopup && !installer) {
      dispatch(openPopup(POPUP_KEYS.PARTNER_CITY_CONSULTINNG, defaultPartnerCityPopupProps));
    }
  }, [dispatch, onAddressSelect, selected, showPartnerCityPopup, installer]);

  const searchButtonContent = useMemo(() => {
    if (width < 600) {
      return <SearchIcon />;
    } else {
      return t("Discover");
    }
  }, [t, width]);

  return (
    <SearchSelectorWrapper>
      <div className={`mainBar ${inputAddr !== "" && matchingAddresses.length > 0 ? "extra" : ""}`}>
        <div className="secound_part">
          <div
            className={`left_side ${
              inputAddr !== "" && matchingAddresses.length > 0 ? "extra_left" : ""
            }`}
          >
            <LocationIcon />
            <input
              onChange={onInputChangeHandler}
              className="input_main"
              placeholder={t("ENTER YOUR LOCATION")}
              value={inputAddr}
            />
            {inputAddr !== "" && (
              <div className="text" onClick={onClear}>
                <RemoveSearchIcon />
              </div>
            )}
          </div>
          <Button
            className={`right_button ${
              inputAddr !== "" && matchingAddresses.length > 0 ? "extra_button" : ""
            }`}
            onClick={onDiscoverClick}
          >
            {searchButtonContent}
          </Button>
        </div>
        {inputAddr !== "" && matchingAddresses?.length > 0 && (
          <div className="resultContaijer">
            {matchingAddresses?.map((data: Prediction, index: number) => {
              return (
                <div
                  key={data.place_id}
                  className={`reslut ${index === cursor ? "active" : ""}`}
                  onClick={async () => {
                    const result: MapboxAddress | undefined = await parseReverseGeoGoogle(matchingAddresses[cursor].place_id);
                    setSelected(matchingAddresses[cursor]);
                    if (result) {
                      setInputAddr(result.complete);
                      onAddressSelect(result);
                      setMatchingAddresses([]);
                      if (showPartnerCityPopup && !installer) {
                        dispatch(
                          openPopup(
                            POPUP_KEYS.PARTNER_CITY_CONSULTINNG,
                            defaultPartnerCityPopupProps,
                          ),
                        );
                      }
                    }
                  }}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  {data?.description}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </SearchSelectorWrapper>
  );
};

export default AddressSelector;
