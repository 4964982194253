import { QuoteDB } from "src/db-types";
import { getNumber } from "src/utils";

import { Quote } from "src/redux/project";
import { QuoteVerifiedStatus } from "src/redux/project/types/quote";

export const getQuoteVerifiedStatus = (status: string | null): QuoteVerifiedStatus => {
  if (!status) return "not verified";

  const transformed = status.toLowerCase().trim();
  if (transformed === "verified") return "verified";
  else if (transformed === "not verified") return "not verified";
  else if (transformed === "pending") return "pending";

  return "not verified";
};

export const convertQuoteFromDB = (quote: QuoteDB): Quote => {
  const greenModuleQty = quote.profiles?.green?.moduleQuantity ?? 71;
  const smartModuleQty = Math.floor(quote.profiles?.smart?.moduleQuantity ?? greenModuleQty / 2);
  const ecoModuleQty = Math.floor(quote.profiles?.eco?.moduleQuantity ?? smartModuleQty / 2);

  const greenRoofTiltConv = parseInt(quote.profiles?.green?.roofTilt);
  const greenRoofTilt = isNaN(greenRoofTiltConv) ? 25 : greenRoofTiltConv;

  const smartRoofTiltConv = parseInt(quote.profiles?.smart?.roofTilt);
  const smartRoofTilt = isNaN(smartRoofTiltConv) ? greenRoofTilt : smartRoofTiltConv;

  const ecoRoofTiltConv = parseInt(quote.profiles?.eco?.roofTilt);
  const ecoRoofTilt = isNaN(ecoRoofTiltConv) ? greenRoofTilt : ecoRoofTiltConv;

  return {
    batchName: quote.batchName,
    buildingType: quote.buildingType,
    createdAt: quote.createdAt,
    createdBy: quote.createdBy,
    geohash: quote.geohash,
    id: quote._id,
    installerPartner: quote.installerPartner,
    status: quote.status,
    lat: getNumber(quote.lat),
    long: getNumber(quote.long),
    mapboxAddress: quote.mapboxAddress,
    updatedAt: quote.updatedAt,
    roofTypesList: quote.roofTypesList,
    accessCount: quote.accessCount,
    lastVisitedDate: quote.lastVisitedDate,
    Gemeindeschluessel8: quote.Gemeindeschluessel8,
    profiles: {
      green: {
        _id: quote.profiles?.green?._id,
        annualSolarGeneration: quote.profiles?.green?.annualSolarGeneration,
        annulElectricBill: quote.profiles?.green?.annulElectricBill,
        consumption: quote.profiles?.green?.consumption,
        currentAnnualConsumption: quote.profiles?.green?.currentAnnualConsumption,
        grn: quote.profiles?.green?.grn,
        isVerified: getQuoteVerifiedStatus(quote.profiles?.green?.isVerified),
        moduleQuantity: greenModuleQty,
        productionRatio: quote.profiles?.green?.productionRatio,
        projectSizeRating: quote.profiles?.green?.projectSizeRating,
        projectType: quote.profiles?.green?.projectType,
        rating: quote.profiles?.green?.rating,
        roofMaterial: quote.profiles?.green?.roofMaterial,
        roofMaterialRating: quote.profiles?.green?.roofMaterialRating,
        roofOrientation: quote.profiles?.green?.roofOrientation,
        roofOrientationRating: quote.profiles?.green?.roofOrientationRating,
        roofStatusRating: quote.profiles?.green?.roofStatusRating,
        roofStructureComplexityRating: quote.profiles?.green?.roofStructureComplexityRating,
        roofTilt: greenRoofTilt,
        productionCapacity: quote.profiles?.green?.productionCapacity,
        productionIncrese: quote.profiles?.green?.productionIncrese,
        roofType: quote.profiles?.green?.roofType,
        shadowsRating: quote.profiles?.green?.shadowsRating,
        totalCost: quote.profiles?.green?.totalCost,
        totalCostFromProvider: quote.profiles?.green?.totalCostFromProvider,
        imgURLs: quote.profiles?.green?.imgUrl ?? [],
        ModelNo: quote.profiles?.green?.ModelNo || "",
      },
      eco: {
        _id: quote.profiles?.eco?._id,
        annualSolarGeneration: quote.profiles?.eco?.annualSolarGeneration,
        annulElectricBill: quote.profiles?.eco?.annulElectricBill,
        consumption: quote.profiles?.eco?.consumption,
        currentAnnualConsumption: quote.profiles?.eco?.currentAnnualConsumption,
        grn: quote.profiles?.eco?.grn,
        isVerified: getQuoteVerifiedStatus(quote.profiles?.eco?.isVerified),
        moduleQuantity: ecoModuleQty,
        productionRatio: quote.profiles?.eco?.productionRatio,
        projectSizeRating: quote.profiles?.eco?.projectSizeRating,
        projectType: quote.profiles?.eco?.projectType,
        rating: quote.profiles?.eco?.rating,
        roofMaterial: quote.profiles?.eco?.roofMaterial,
        roofMaterialRating: quote.profiles?.eco?.roofMaterialRating,
        productionIncrese: quote.profiles?.eco?.productionIncrese,
        roofOrientation: quote.profiles?.eco?.roofOrientation,
        productionCapacity: quote.profiles?.eco?.productionCapacity,
        roofOrientationRating: quote.profiles?.eco?.roofOrientationRating,
        roofStatusRating: quote.profiles?.eco?.roofStatusRating,
        roofStructureComplexityRating: quote.profiles?.eco?.roofStructureComplexityRating,
        roofTilt: ecoRoofTilt,
        roofType: quote.profiles?.eco?.roofType,
        shadowsRating: quote.profiles?.eco?.shadowsRating,
        totalCost: quote.profiles?.eco?.totalCost,
        totalCostFromProvider: quote.profiles?.eco?.totalCostFromProvider,
        ModelNo: quote.profiles?.eco?.ModelNo || "",
        imgURLs: quote.profiles?.eco?.imgUrl ?? [],
      },
      smart: {
        _id: quote.profiles?.smart?._id,
        annualSolarGeneration: quote.profiles?.smart?.annualSolarGeneration,
        annulElectricBill: quote.profiles?.smart?.annulElectricBill,
        consumption: quote.profiles?.smart?.consumption,
        currentAnnualConsumption: quote.profiles?.smart?.currentAnnualConsumption,
        grn: quote.profiles?.smart?.grn,
        isVerified: getQuoteVerifiedStatus(quote.profiles?.smart?.isVerified),
        moduleQuantity: smartModuleQty,
        productionRatio: quote.profiles?.smart?.productionRatio,
        projectSizeRating: quote.profiles?.smart?.projectSizeRating,
        productionCapacity: quote.profiles?.smart?.productionCapacity,
        productionIncrese: quote.profiles?.smart?.productionIncrese,
        projectType: quote.profiles?.smart?.projectType,
        rating: quote.profiles?.smart?.rating,
        roofMaterial: quote.profiles?.smart?.roofMaterial,
        roofMaterialRating: quote.profiles?.smart?.roofMaterialRating,
        roofOrientation: quote.profiles?.smart?.roofOrientation,
        roofOrientationRating: quote.profiles?.smart?.roofOrientationRating,
        roofStatusRating: quote.profiles?.smart?.roofStatusRating,
        roofStructureComplexityRating: quote.profiles?.smart?.roofStructureComplexityRating,
        roofTilt: smartRoofTilt,
        roofType: quote.profiles?.smart?.roofType,
        shadowsRating: quote.profiles?.smart?.shadowsRating,
        totalCost: quote.profiles?.smart?.totalCost,
        totalCostFromProvider: quote.profiles?.smart?.totalCostFromProvider,
        ModelNo: quote.profiles?.smart?.ModelNo || "",
        imgURLs: quote.profiles?.smart?.imgUrl ?? [],
      },
    },
  };
};
