import { clone } from "lodash";

import { actionCreators, ProductsState } from "src/redux/products";

export const setProducts = (
  currentState: ProductsState,
  { payload }: ReturnType<typeof actionCreators.setProducts>,
): ProductsState => {
  const newState = clone(currentState);

  newState.products = clone(payload);
  newState.batteries = payload.filter((p) => p.type === "battery");
  newState.inverters = payload.filter((p) => p.type === "inverter");
  newState.solarPanels = payload.filter((p) => p.type === "solarPanel");
  newState.heatpumps = payload.filter((p) => p.type === "heatpump");
  newState.wallboxes = payload.filter((p) => p.type === "wallbox");
  newState.additionalSoftware = payload.filter((p) => p.type === "additionalSoftware");
  newState.additionalHardware = payload.filter((p) => p.type === "additionalHardware");
  newState.cars = payload.filter((p) => p.type === "car");
  newState.EMS = payload.filter((p) => p.type === "EMS");

  return newState;
};
