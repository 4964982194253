import { Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DownArrow } from "src/assets/svgs";
import { buildingStandards } from "src/data";

import * as S from "../../HeatpumpConfigurationBox.styles";

import { HeatpumpConfiguration } from "src/redux/project";
import { ProjectSelectors } from "src/redux/selectors";

const { Option } = Select;

interface BuildingStandardLineProps {
  onValueChange: (heatpumpConfig: Partial<HeatpumpConfiguration>) => void;
}
const BuildingStandardLine: React.FC<BuildingStandardLineProps> = (
  props: BuildingStandardLineProps,
) => {
  const { onValueChange } = props;

  const { t } = useTranslation();

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);

  const [selectedType, setSelectedType] = useState("");

  const handleChange = useCallback(
    (value: any) => {
      onValueChange({
        buildingStandard: value,
      });
    },
    [onValueChange],
  );

  useEffect(() => {
    const selected = configuration.buildingStandard;
    for (let i = 0; i < buildingStandards.length; i++) {
      if (buildingStandards[i].value === selected) {
        setSelectedType(t(buildingStandards[i].label));
        return;
      }
    }
  }, [configuration.buildingStandard, t]);

  return (
    <S.SubContainer1>
      <S.SubContainerText>{t("Building standard")}</S.SubContainerText>
      <Select
        onChange={handleChange}
        value={selectedType}
        style={{
          paddingTop: "5px",
          paddingBottom: "5px",
          height: "auto",
          wordWrap: "break-word",
        }}
        dropdownStyle={{ minWidth: "400px", right: "30px" }}
        suffixIcon={<DownArrow />}
      >
        {buildingStandards.map((data) => {
          return (
            <>
              <Option value={data.value} key={data.key}>
                {t(data.label)}
              </Option>
            </>
          );
        })}
      </Select>
    </S.SubContainer1>
  );
};

export default BuildingStandardLine;
