import styled from "styled-components/macro";
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-top: 0px;
  width: 90%;
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  .inside_copy {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .saprate {
    width: 100%;
    padding-bottom: 4px;
    border-bottom: 1px solid #efefef;
  }
`;
export const SubContainer3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin-top: 24px;
  padding-bottom: 10px;
  border-bottom: 0.5px dashed #969696;
  padding-right: 8px;
  position: relative;
`;
export const SubContainer33 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  margin-top: -3px;
  height: 56px;
`;
interface CircleItemProps {
  colorName: string;
}
interface MainTextProps {
  paddingRight?: string;
}
export const Circle = styled.div<CircleItemProps>`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: ${(props) => {
    return props.colorName;
  }};
`;
export const CostText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  color: #2d4764;
`;
export const MainText = styled.div<MainTextProps>`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;

  width: 50%;
  padding-left: ${(props) => {
    if (props.paddingRight) {
      return props.paddingRight;
    } else {
      return "0px";
    }
  }};
  font-size: 14px;
  color: #2d4764;
  /* width: 40%; */
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;
export const SubContainer11 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;
export const Text1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #2d4764;
`;
export const Text2 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #2d4764;
`;
export const Text3 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
`;
export const Number1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #2d4764;
  white-space: nowrap;
`;
export const Number2 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #ffd75d;
  white-space: nowrap;
`;
export const Number3 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
  white-space: nowrap;
`;
interface BarItemProps1 {
  height: string;
}
export const Bar2 = styled.div<BarItemProps1>`
  width: 15px;
  max-height: 60px;
  height: ${(props) => {
    return props.height;
  }};
  background: #df6569;
  position: absolute;
  right: 42px;
`;
export const SubContainerCopy = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  gap: 10px;
`;

interface BarItemProps {
  height: string;
  bottom: string;
}
export const Bar1 = styled.div<BarItemProps>`
  width: 15px;
  max-height: 60px;
  height: ${(props) => {
    return props.height;
  }};
  background: #ffd75d;
  position: absolute;
  right: 12px;
  bottom: ${(props) => {
    return props.bottom;
  }};
`;
export const Bar3 = styled.div<BarItemProps>`
  width: 15px;
  height: ${(props) => {
    return props.height;
  }};
  background: #8f8f8f;
  border: 1px solid #000000;
  position: absolute;
  right: 12px;
  bottom: ${(props) => {
    return props.bottom;
  }};
`;
export const Bar4 = styled.div<BarItemProps>`
  width: 15px;
  height: ${(props) => {
    return props.height;
  }};
  background: #e0e0e0;
  position: absolute;
  right: 12px;
  bottom: ${(props) => {
    return props.bottom;
  }};
`;
