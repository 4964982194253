export const heatpumpBuildingtypes = [
  {
    key: "Radiatoren (wandhängend)",
    label: "Radiators (wall mount)",
  },
  {
    key: "Radiatoren (frei stehend)",
    label: "Radiators (free standing)",
  },
  {
    key: "Flächenheizung (Fußboden-, Wand-, Deckenheizung)",
    label: "Panel heating (floor-, wall-, roof heating)",
  },
  {
    key: "Beides",
    label: "Both",
  },
];
