export const DEFAULT_BATTERY_KWH = 3;
export const DEFAULT_HEATPUMP_KWH = 15000;
export const DEFAULT_MICRO_INVERTER_COST = 120;

export const PROCESS_STATUS = {
  APPROVAL_PENDING: "APPROVAL_PENDING",
  REWORK_PENDING: "REWORK_PENDING",
  VERIFIED: "VERIFIED",
  TO_BE_PLANNED: "TO_BE_PLANNED",
  PRIORITY_PLANNING: "PRIORITY_PLANNING",
  URGENT_PLANNING: "URGENT_PLANNING",
};
