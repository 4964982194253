import { colors } from "src/utils";
import styled from "styled-components";
import { MainContainer } from "src/pages/UserOverview/UserOverview.styles";

export const Card = styled.div`
  display: flex;
  min-height: 515px;
  gap: 1rem;
  /* border-radius: 22px;
  background-color: ${colors.ctaSolid}; */

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProjectDetails = styled.div`
  flex: 3;
  padding: 5%;
  background-color: ${colors.white};
  border-radius: 22px 0px 0px 22px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media (max-width: 768px) {
    flex: 1;
    border-radius: 22px 22px 0px 0px;
  }
`;

export const InstallerDetails = styled.div`
  flex: 7;
  padding-inline: 5%;
  padding-top: 2%;
  height: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: space-around;
  border-radius: 22px;
  background-color: ${colors.ctaSolid};
  min-width: max-content;
  height: 100%;
  @media (max-width: 768px) {
    flex: 1;
    border-radius: 22px;
    margin-top: 5%;
  }
`;

export const Title = styled.div`
  color: var(--dark-boue, #2d4764);
  text-align: center;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Details = styled.div`
  color: var(--dark-boue, #2d4764);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`;

export const Logo = styled.img`
  width: 171px;
  height: 40px;
  flex-shrink: 0;
  object-fit: contain;
`;

export const ProjectCard = styled(MainContainer)`
  height: 100%;
  background-color: transparent;
  .card_main {
    border-radius: 22px;
  }
  .button_section {
    text-align: center;
  }
  @media (max-width: 768px) {
    .card_main {
      width: 98%;
      border-radius: 22px;
    }
  }
`;

export const ProjectImage = styled.img`
  width: 279px;
  height: 202px;
  flex-shrink: 0;
  border-radius: 10px;
`;
