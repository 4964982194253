import { clone } from "lodash";
import { actionCreators, ProjectState } from "src/redux/project";

export const updateThreeDModelsObj = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateThreeDModels>,
): ProjectState => {
  const newState = clone(currentState);
  newState.threeDModels = clone(payload);
  console.log(newState);
  

  return newState;
};
