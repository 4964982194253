import { cloneDeep, merge } from "lodash";

import { actionCreators, ApplicationState } from "src/redux/application";

export const updateAppState = (
  currentState: ApplicationState,
  { payload }: ReturnType<typeof actionCreators.updateAppState>,
): ApplicationState => {
  const newState = cloneDeep(merge({}, currentState, payload));
  return newState;
};
