import { clone } from "lodash";

import { actionCreators } from "../..";
import { WebinarState } from "../types";

export const setWebinarsId = (
  currentState: WebinarState,
  { payload }: ReturnType<typeof actionCreators.setWebinarId>,
): WebinarState => {
  const newState = clone(currentState);
  newState.selectedWebinarId = payload;
  return newState;
};
