import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useNumberFormat } from "src/hooks";

import { MasterInput } from "src/shared/components";
import { ParameterStyles } from "src/shared/StyledComponents";

import { CarConfiguration } from "src/redux/project";

interface EVYearlyLineProps {
  onValueChange: (data: Partial<CarConfiguration>) => void;
  evYearly: number;
}
const EVYearlyLine: React.FC<EVYearlyLineProps> = (props: EVYearlyLineProps) => {
  const { onValueChange, evYearly } = props;

  const { t } = useTranslation();

  const onChangeHandler = useCallback(
    (value: number) => {
      onValueChange({
        evYearlyConsumption: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line isPadding>
      <ParameterStyles.Label>{t("EV YEARLY CONSUMPTION")}</ParameterStyles.Label>
      <ParameterStyles.Value hasSlider>
        <MasterInput
          value={evYearly}
          appearance={{
            styles: { color: "#666666" },
          }}
          onChange={onChangeHandler}
          maxNumber={20000}
          label="kWh"
          roundTill={0}
        />
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default EVYearlyLine;
