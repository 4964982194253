import { clone, merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateModuleQuantity = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateModuleQuantity>,
): ProjectState => {
  const newState = clone(currentState);

  newState.quote.profiles.green.moduleQuantity = payload
  return newState;
};
