import { Button } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// import { sentEmailToSolarhubWhenUserRequestNewLaylout } from "src/api/email/sentEmail";

import { useAppDispatch, useURLData } from "src/hooks";

import { RemoveButtonIcon } from "src/assets/svgs";
import { AUTH_MODES, AuthPopupProps } from "src/popups";
import { isOnDefaultProject, isOnSavedProject } from "src/utils";

import * as S from "./MessageForPanels.styles";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { UserSelectors } from "src/redux/selectors";

interface MessageForPanelsProps {
  closeSolarMessageWindow: () => void;
}
const MessageForPanels: React.FC<MessageForPanelsProps> = (props: MessageForPanelsProps) => {
  const { closeSolarMessageWindow } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentUser = useSelector(UserSelectors.getUser);

  const { isOnSaved, isOnDefault } = useURLData();

  const requestButtonHandler = useCallback(() => {
    if (!currentUser) {
      const authProps: AuthPopupProps = {
        authMode: AUTH_MODES.signup,
      };
      dispatch(PopupActions.openPopup(POPUP_KEYS.auth, authProps));
      return;
    }

    if (!(isOnSaved || isOnDefault)) {
      dispatch(PopupActions.openPopup(POPUP_KEYS.welcome_address));
      return;
    }

    // @TODO
    // void sentEmailToSolarhubWhenUserRequestNewLaylout(project, currentUser);
    const message =
      "You have requested a new layout for your PV system - we will update you by mail as soon as we have recreated the layout for you.";
    toast.success(t(message));
    closeSolarMessageWindow();
  }, [closeSolarMessageWindow, currentUser, dispatch, isOnDefault, isOnSaved, t]);

  return (
    <S.MessageForPanelsWrapper>
      <div className="icon" onClick={closeSolarMessageWindow}>
        <RemoveButtonIcon />
      </div>
      <div className="middle_tow">
        <div>{t("MESSAGE FOR SOLARPANEL")}</div>
        <Button className="button_" onClick={requestButtonHandler}>
          {t("Request a new layout")}
        </Button>
      </div>
    </S.MessageForPanelsWrapper>
  );
};

export default MessageForPanels;
