import { Slider } from "antd";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PopupActions } from "src/redux";
import { ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { ProjectSelectors } from "src/redux/selectors";
import { ParameterStyles } from "src/shared/StyledComponents";
import { MasterInput } from "src/shared/components";

interface IElectricityPrice {
  title?: string;
}

const ElectricityPrice: React.FC<IElectricityPrice> = ({
  title = "ELECTRICITY PRICE FOR TENANTS",
}: IElectricityPrice) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { household: householdModel } = useSelector(ProjectSelectors.getModel);
  const { electricityPrice } = useSelector(ProjectSelectors.getHousehold);
  const {
    percentages: { autarky, maxPossibleAutarky },
  } = householdModel;

  const handleInputChange = useCallback(
    (value: number): void => {
      dispatch(
        ProjectActions.updateHousehold({
          electricityPrice: value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <ParameterStyles.Line
      hasSlider
      style={{ paddingInline: "10px", width: "93%" }}
    >
      <ParameterStyles.Label style={{ color: "black" }}>{t(title)}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider onChange={handleInputChange} min={0.2} max={0.8} step={0.01} value={electricityPrice} />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <ParameterStyles.ValueWrapper style={{ marginRight: "0px" }}>
          <MasterInput value={electricityPrice} onChange={handleInputChange} label="€/kWh" />
        </ParameterStyles.ValueWrapper>
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default ElectricityPrice;
