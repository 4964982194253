import styled from "styled-components";

interface CardContainerProps {
  isFixed?: boolean;
  isGreen?: boolean;
}

export const CardContainer = styled.div<CardContainerProps>`
  ${(props) =>
    props.isFixed
      ? `
      height: 119px;
      width: 236px;
    `
      : `
      padding: 28px 16px;
      border: 1px solid transparent;
    `}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 10px 0px #2d476480;
  background: ${(props) => (props.isGreen ? "#4D7E6D" : "#FFFFFF")};
  border-radius: 20px;
  cursor: pointer;
  ${(props) =>
    props.isGreen &&
    `h3,
    p {
      color: #fff;
    }`};

  :hover {
    background: #4d7e6d;
    border: 1px solid #2d4764;
    box-shadow: none;
    h3,
    p {
      color: #fff;
    }
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const CardTitle = styled.h3`
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
`;

export const CardText = styled.p`
  font-size: 15px;
  font-weight: 700;
  line-height: 16.58px;
`;

export const CardSubText = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  line-height: 15px;
`;
