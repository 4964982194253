import styled from "styled-components/macro";
export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
  align-items: center;
  gap: 2rem;
  padding-inline: 10px;
  min-width: 40vw;
  max-width: 70vw;
  font-family: "NuNito";
`;