import styled from "styled-components/macro";

interface InputAll {
  color?: string;
  cursor?: string;
  bgcolor?: string;
  isSubsidy?: boolean;
}
export const MasterInputContainer = styled("div")<InputAll>`
  .same_for_all {
    display: flex;
    background: ${(p) => p.bgcolor ?? "#fafafa"};
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 4px 5px 4px 4px;
    height: 27px;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    border-radius: 16px;
    font-family: "Nunito";
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: auto;
    cursor: ${(props) => props.cursor};
    color: ${(props) => props.color ?? "#304864"};
    input {
      outline: none;
      text-align: ${(props) => (props.isSubsidy ? "left" : "right")};
      padding-inline: ${(props) => props.isSubsidy && "8px"};

      width: 100%;
      background: ${(p) => p.bgcolor ?? "#fafafa"};
      cursor: ${(props) => props.cursor};
    }
  }
  .percentage {
    padding-right: ${(props) => (props.isSubsidy && "8px")};
  }
`;
