import styled from "styled-components";

export const WebinarProjectDetailsWrapper = styled("div")`
  width: min(1142px, 90vw);
  height: 100%;
  position: relative;
  @media (max-width: 500px) {
    width: min(1142px, 100vw);
  }
`;

export const StepperWrapper = styled("div")`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const NextStep = styled("div")`
  background: #ffd75d;
  height: 44px;
  width: 46px;
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 46%;
  right: 8px;
  z-index: 100;
`;

export const PrevStep = styled("div")`
  background: #ffd75d;
  height: 44px;
  width: 46px;
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 46%;
  left: 5px;
  z-index: 100;
`;

export const Step3Container = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 17px;
  margin-bottom: 31px;
  width: 100%;
  .uploadImageButton {
    width: auto;
    padding-inline: 20px;
    padding-top: 3px;
    height: 40px;
    background: #ffd75d;
    border-radius: 30px;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    color: #2d4764;
    cursor: pointer;
    position: sticky;
    bottom: 19px;
    @media (max-width: 500px) {
      bottom: 10px;
    }
  }
  .headers {
    display: flex;
    gap: 7px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 500px) {
      align-items: flex-start;
      width: 83%;
    }
    .head {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #2d4764;
    }
    .make_flex {
      display: flex;
      gap: 7px;
    }
    .paragraph {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #666666;
    }
  }
`;
