import store from "src/redux/store";

export interface MonthlyPersentageTypes {
  "heatDemand(withHotWater)": number;
  "heatDemand(withoutHotWater)": number;
  productionPv: number;
  electricityConsumption: number;
  heatingWater: number;
}

interface MonthlyEnergy {
  pvProduction: number;
  gridSupply: number;
  feedIn: number;
  consumption: number;
  ev: number;
  household: number;
  heatingWater: number;
  heatpump: number;
}
export const getMonthlyEnergyDetails = (): MonthlyEnergy[] => {
  const monthlyEnergies: MonthlyEnergy[] = [];

  const state = store.getState();
  const project = state.project;
  const { household, heatpump, ev, heatingRod } = project.model;

  const monthlyEvConsumption = ev.consumption / 12;
  for (const monthlyPercentage of monthlyEnergyDistributionPercentages) {
    const heatingWaterUsageThisMonth =
      heatingRod.consumption * (monthlyPercentage.heatingWater / 100);
    const pvProductionThisMonth = getMonthlyProduction(monthlyPercentage.productionPv);

    const householdUsageThisMonth =
      (household.consumption * monthlyPercentage.electricityConsumption) / 100;
    const heatpumpUsageThisMonth =
      (heatpump.requirement * monthlyPercentage["heatDemand(withoutHotWater)"]) / 100;

    const totalUsageThisMonth =
      householdUsageThisMonth +
      heatpumpUsageThisMonth +
      monthlyEvConsumption +
      heatingWaterUsageThisMonth;

    const usageThisMonthFromSolar =
      (householdUsageThisMonth * household.percentages.autarky) / 100 +
      (heatpumpUsageThisMonth * heatpump.percentages.autarky) / 100 +
      (monthlyEvConsumption * ev.percentages.autarky) / 100 +
      heatingWaterUsageThisMonth;

    const feedInThisMonth = Math.max(0, pvProductionThisMonth - usageThisMonthFromSolar);

    const gridSupplyThisMonth = Math.max(
      0,
      totalUsageThisMonth - Math.min(usageThisMonthFromSolar, pvProductionThisMonth),
    );

    monthlyEnergies.push({
      consumption: totalUsageThisMonth,
      feedIn: feedInThisMonth,
      pvProduction: pvProductionThisMonth,
      gridSupply: gridSupplyThisMonth,
      ev: monthlyEvConsumption,
      heatingWater: heatingWaterUsageThisMonth,
      heatpump: heatpumpUsageThisMonth,
      household: householdUsageThisMonth,
    });
  }

  return monthlyEnergies;
};

export const getMonthlyProduction = (percentageOfProduction: number) => {
  const state = store.getState();
  const production = state.project.energy.solarPanelProduction;

  return (production * percentageOfProduction) / 100;
};

export const getMonthlyHeatDemand = (percentageOfProduction: number) => {
  const state = store.getState();
  const production = state.project.energy.solarPanelProduction;

  return (production * percentageOfProduction) / 100;
};

export const getPersentageValueForFeedIn = (
  productionOfPVPercent: number,
  householdAutarkyOfThisMonth: number,
  heatpumpAutarkyOfThisMonth: number,
): number => {
  const state = store.getState();
  const totalProduction = state.project.energy.solarPanelProduction;

  const generatedThisMonth = (totalProduction * productionOfPVPercent) / 100;

  const project = state.project;

  const { household, heatpump } = project.model;

  const householdUsageThisMonth = (household.consumption * householdAutarkyOfThisMonth) / 100;

  const heatpumpUsageThisMonth = (heatpump.requirement * heatpumpAutarkyOfThisMonth) / 100;

  const usageThisMonthFromSolar =
    (householdUsageThisMonth * household.percentages.autarky) / 100 +
    (heatpumpUsageThisMonth * heatpump.percentages.autarky) / 100;

  const feedInThisMonth = Math.max(0, generatedThisMonth - usageThisMonthFromSolar);
  return feedInThisMonth;
};

// total consumption = Household + heat + EV consumption right?
export const totalConsumption = (houseHold: number, heatCon: number): number => {
  const project = store.getState().project;
  const yearlyConsumption = project.model.consumption;

  const houseHoldConsumtion = yearlyConsumption * (houseHold / 100);
  const heatConsumtion = project.model.heatpump.consumption * (heatCon / 100);
  const evConsumption = project.model.ev.consumption / 12;

  return houseHoldConsumtion + heatConsumtion + evConsumption;
};

export const totalGridConsumption = (
  houseHold: number,
  heatCon: number,
  pvProduction: number,
): number => {
  const project = store.getState().project;
  const totalSelfSupply = project.model.percentages.autarky;
  const pvConsumption = totalSelfSupply * (pvProduction / 100);
  return Math.max(0, totalConsumption(houseHold, heatCon) - pvConsumption);
};

export const monthlyEnergyDistributionPercentages: MonthlyPersentageTypes[] = [
  {
    "heatDemand(withHotWater)": 17,
    "heatDemand(withoutHotWater)": 17,
    productionPv: 3,
    electricityConsumption: 9.32,
    heatingWater: 12,
  },
  {
    "heatDemand(withHotWater)": 14,
    "heatDemand(withoutHotWater)": 14,
    productionPv: 6,
    electricityConsumption: 8.57,
    heatingWater: 10,
  },
  {
    "heatDemand(withHotWater)": 12,
    "heatDemand(withoutHotWater)": 11,
    productionPv: 7,
    electricityConsumption: 8.92,
    heatingWater: 9.5,
  },
  {
    "heatDemand(withHotWater)": 8,
    "heatDemand(withoutHotWater)": 6,
    productionPv: 13,
    electricityConsumption: 8.06,
    heatingWater: 7.5,
  },
  {
    "heatDemand(withHotWater)": 5,
    "heatDemand(withoutHotWater)": 3,
    productionPv: 12,
    electricityConsumption: 7.83,
    heatingWater: 6,
  },
  {
    "heatDemand(withHotWater)": 2,
    "heatDemand(withoutHotWater)": 2,
    productionPv: 15,
    electricityConsumption: 7.56,
    heatingWater: 5,
  },
  {
    "heatDemand(withHotWater)": 0,
    "heatDemand(withoutHotWater)": 2,
    productionPv: 13,
    electricityConsumption: 7.77,
    heatingWater: 5,
  },
  {
    "heatDemand(withHotWater)": 1,
    "heatDemand(withoutHotWater)": 2,
    productionPv: 12,
    electricityConsumption: 7.61,
    heatingWater: 6,
  },
  {
    "heatDemand(withHotWater)": 4,
    "heatDemand(withoutHotWater)": 3,
    productionPv: 9,
    electricityConsumption: 7.92,
    heatingWater: 7.5,
  },
  {
    "heatDemand(withHotWater)": 8,
    "heatDemand(withoutHotWater)": 8,
    productionPv: 5, // changed from 6 => 5
    electricityConsumption: 8.52,
    heatingWater: 9.5,
  },
  {
    "heatDemand(withHotWater)": 12,
    "heatDemand(withoutHotWater)": 13,
    productionPv: 3,
    electricityConsumption: 9.04,
    heatingWater: 10,
  },
  {
    "heatDemand(withHotWater)": 16,
    "heatDemand(withoutHotWater)": 17,
    productionPv: 2,
    electricityConsumption: 8.78, // changed 8.33 => 8.78
    heatingWater: 12,
  },
];

export const getLables = (): string[] => {
  const ln = store.getState().user.language;
  return ln === "en" ? months : labelsGerman;
};

export const labelsGerman = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
