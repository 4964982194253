/* eslint-disable @typescript-eslint/naming-convention */
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DownArrow, UpArrow } from "src/assets/svgs";
import { useNumberFormat } from "src/hooks/useNumberFormat";
import { Mixpanel } from "src/mix-panel/mixpanel";
import * as trackingEvents from "src/mix-panel/trackingEvents";
import { MasterInput } from "src/shared/components";
import { isQuoteNotVerified, isQuotePresent } from "src/utils";

import ShowRadiation from "../ShowRadiation/ShowRadiation";

import * as S from "./ConsumptionDetails.styles";

import { ProjectSelectors } from "src/redux/selectors";

interface ConsumptionDetailsProps {
  selectedOption: string;
  setSelectedOption: (key: string) => void;
  setFluxMonth: (key: number) => void;
  fluxMonth: number;
}

const ConsumptionDetails: React.FC<ConsumptionDetailsProps> = ({
  selectedOption,
  fluxMonth,
  setFluxMonth,
  setSelectedOption,
}) => {
  const { t } = useTranslation();

  const [openDetails, setOpenDetails] = useState(false);
  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const status = useSelector(ProjectSelectors.getQuoteStatus);

  const consumptionDetailsStyles: any = makeStyles((theme: any) => ({
    container: {
      "&:hover": {
        background: "#f9f9f9",
        cursor: "pointer",
        borderRadius: openDetails ? "0px" : "0px 0px 22px 22px",
      },
    },
    arrow: {
      height: "32px",
      width: "32px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        background: "#dfdfdf",
      },
    },
  }));
  const classes = consumptionDetailsStyles();

  const { fromPVAndBattery, fromGrid, feedIn } = useSelector(ProjectSelectors.getModel);
  const energy = useSelector(ProjectSelectors.getEnergy);
  const maxPossibleAutarkey = useSelector(ProjectSelectors.getMaxPossibleAutarky);

  const { formatNumber } = useNumberFormat();

  const isToShowSolarLayout = useMemo(
    () => isQuotePresent(quoteId) && isQuoteNotVerified(status),
    [quoteId, status],
  );

  return (
    <>
      <div className="flex flex-col w-full">
        {isToShowSolarLayout && (
          <ShowRadiation
            fluxMonth={fluxMonth}
            setFluxMonth={setFluxMonth}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        )}
        <Grid
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          height="48px"
          paddingX="17px"
          marginTop="5px"
          paddingBottom="5px"
          className={classes.container}
          onClick={() => {
            if (!openDetails) {
              Mixpanel.track(trackingEvents.USER_CLICKS_ON_CONSUMPTION_DETAILS_STEP1, {});
            }
            setOpenDetails(!openDetails);
          }}
        >
          {openDetails ? (
            <S.Text>
              <div className="headkey1">{t("HIDE CONSUMPTION DETAILS")}</div>
            </S.Text>
          ) : (
            <S.Text>
              <div className="headkey2">{t("CONSUMPTION DETAILS")}</div>
            </S.Text>
          )}
          <Grid
            onClick={() => {
              setOpenDetails(!openDetails);
            }}
            className={classes.arrow}
          >
            {openDetails ? <UpArrow /> : <DownArrow />}
          </Grid>
        </Grid>
      </div>
      {openDetails && (
        <Grid
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="flex-start"
          minHeight="163px"
          padding="0 17px 15px"
        >
          <Grid
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            position="relative"
            marginTop="10px"
            display="flex"
          >
            <S.Text>{t("Autarky Level")}</S.Text>
            <MasterInput disabled={true} value={Math.round(maxPossibleAutarkey)} label="%" />
          </Grid>
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="column"
            width="100%"
            minHeight="143px"
            gap="2px"
          >
            <Grid
              width="100%"
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              position="relative"
              paddingTop="20px"
            >
              <S.Text>{t("YEARLY PRODUCTION")}</S.Text>
              <S.Text>
                <div className="yearlyProduction">
                  {formatNumber(energy.solarPanelProduction)}{" "}
                  <span className="consumptionUnit">kWh</span>
                </div>
              </S.Text>
            </Grid>
            <div style={{ border: "1px solid #e2e2e2", opacity: "0.5", width: "100%" }}></div>
            <Grid
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              display="flex"
            >
              <S.Text> {t("YEARLY CONSUMPTION")}</S.Text>
              <S.Text>
                <div className="consumptionUnitData">
                  {formatNumber(Math.round(energy.yearlyConsumption))}{" "}
                  <span className="consumptionUnit">kWh</span>
                </div>
              </S.Text>
            </Grid>
            <Grid
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              display="flex"
            >
              <S.Text>{t("SELF CONSUMPTION")}</S.Text>
              <S.Text>
                <div className="consumptionUnitData">
                  {formatNumber(Math.round(fromPVAndBattery))}{" "}
                  <span className="consumptionUnit">kWh</span>
                </div>
              </S.Text>
            </Grid>
            <Grid
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              display="flex"
            >
              <S.Text>{t("GRID SUPPLY")}</S.Text>
              <S.Text>
                <div className="consumptionUnitData">
                  {formatNumber(Math.round(fromGrid))} <span className="consumptionUnit">kWh</span>
                </div>
              </S.Text>
            </Grid>
            <Grid
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              display="flex"
            >
              <S.Text>{t("FEED IN")}</S.Text>
              <S.Text>
                <div className="consumptionUnitData">
                  {formatNumber(feedIn)} <span className="consumptionUnit">kWh</span>
                </div>
              </S.Text>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default React.memo(ConsumptionDetails);
