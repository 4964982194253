import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// import { returnIsPathIsThere } from "src/components/popups/consumption/helperData";

import { useJoinTheWebinar } from "src/hooks";

import {
  LongLogo,
  MobileAddConfigIcon,
  MobileCustomerRefIcon,
  MobileLogoutIcon,
  MobileSavedOffersIcon,
  MobileOpenCommunityIcon,
  BackButton,
} from "src/assets/svgs";
import { LanguageDropdown, CloseButton, MobileNavLink } from "src/shared/components";
import { PrimaryButton, SecondaryButton } from "src/shared/StyledComponents/Buttons";
import { canShowBackBtn } from "src/utils";

import { NavItemKey } from "../../constants/navItemKey";
import RedDot from "../RedDot/RedDot";

import * as S from "./MobileMenu.styles";

import { selectors as UserSelectors } from "src/redux/user";
import { InstallerDB } from "src/db-types";

// import { getStateWebinarsStatus } from "src/redux/webinar/webinar.selectors";
type ArrElement<ArrType> = ArrType extends ReadonlyArray<infer ElementType> ? ElementType : never;

const NavButtonList = [
  {
    text: "saved offers",
    icon: MobileSavedOffersIcon,
    navKey: NavItemKey.OFFER,
    showForLoggedInUser: true,
    showForLoggedOutUser: false,
  },
  {
    text: "Add aditional configruation",
    navKey: NavItemKey.ADD_CONFIGURATION,
    icon: MobileAddConfigIcon,
    showForLoggedInUser: true,
    showForLoggedOutUser: false,
  },
  {
    text: "Customer referral",
    icon: MobileCustomerRefIcon,
    navKey: NavItemKey.REFERRAL,
    showForLoggedInUser: true,
    showForLoggedOutUser: false,
  },
  {
    text: "Open Community",
    navKey: NavItemKey.COMMUNITY,
    icon: MobileOpenCommunityIcon,
    showForLoggedInUser: true,
    showForLoggedOutUser: true,
  },
  {
    text: "Back to the project",
    icon: BackButton,
    navKey: NavItemKey.BACK_TO_PROJECT,
    showForLoggedInUser: true,
    showForLoggedOutUser: false,
  },
  {
    text: "Logout",
    icon: MobileLogoutIcon,
    navKey: NavItemKey.LOGOUT,
    showForLoggedInUser: true,
    showForLoggedOutUser: false,
  },
];

interface MobileMenuProps {
  menuButtonClickHandler: Function;
  installer: InstallerDB | null;
}

const MobileMenu: React.FC<MobileMenuProps> = (props) => {
  const { menuButtonClickHandler, installer } = props;
  const { isAnyWebinarInProgress } = useJoinTheWebinar();
  const onGoing = isAnyWebinarInProgress();

  const { t } = useTranslation();

  // const webinarStatus = useSelector(getStateWebinarsStatus);
  const currentUser = useSelector(UserSelectors.getUser);

  const shouldShowNavItem = useCallback(
    (navItem: ArrElement<typeof NavButtonList>): boolean => {
      const isBackToProjectBtn = navItem.navKey === NavItemKey.BACK_TO_PROJECT;
      if (isBackToProjectBtn) {
        return canShowBackBtn(location.pathname);
      }

      const isAuthRequired = navItem.showForLoggedInUser;
      if (isAuthRequired && currentUser) return true;

      const isGuestRequired = navItem.showForLoggedOutUser;
      if (isGuestRequired && !currentUser) return true;

      return false;
    },
    [currentUser],
  );

  return (
    <>
      <S.MobileBackdrop
        onClick={() => menuButtonClickHandler(NavItemKey.MOBILE_CLOSE_MENU)}
      ></S.MobileBackdrop>
      <S.MobileMenu>
        <S.CloseButtonPos>
          <CloseButton
            onClick={() => menuButtonClickHandler(NavItemKey.MOBILE_CLOSE_MENU)}
          ></CloseButton>
        </S.CloseButtonPos>
        <S.LogoTextWrap onClick={() => menuButtonClickHandler(NavItemKey.LOGO)}>
          <LongLogo />
        </S.LogoTextWrap>
        {(!installer || (installer && installer.isAllowWebinar)) && (
          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {onGoing && <RedDot />}
            <MobileNavLink
              style={{ width: "auto" }}
              linkText={t("Join Live Webinar")}
              onClick={() => menuButtonClickHandler(NavItemKey.WEBINAR)}
            ></MobileNavLink>
          </div>
        )}
        <S.Divider></S.Divider>
        <S.Links>
          {NavButtonList.map((navBtnDetails) => {
            const show = shouldShowNavItem(navBtnDetails);
            if (!show) return <></>;

            return (
              <MobileNavLink
                key={navBtnDetails.navKey}
                linkText={t(navBtnDetails.text)}
                Icon={navBtnDetails.icon}
                onClick={() => menuButtonClickHandler(navBtnDetails.navKey)}
              ></MobileNavLink>
            );
          })}
          <SecondaryButton onClick={() => menuButtonClickHandler(NavItemKey.KNOWLEDGE_HUB)}>
            {t("Knowledge Hub")}
          </SecondaryButton>
          <PrimaryButton>{t("Successful projects")}</PrimaryButton>
        </S.Links>
        <div style={{ textAlign: "center" }}>
          <LanguageDropdown></LanguageDropdown>
        </div>
      </S.MobileMenu>
    </>
  );
};

export default MobileMenu;
