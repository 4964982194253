import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { useAppDispatch, useAppSelector } from "src/hooks";

import { ProjectResponseDB } from "src/db-types/project";
import { useGetAllProjects, useLastLogApi } from "src/hooks/apis";
import { getErrorMessage } from "src/utils";

import { AppActions } from "src/redux";
import { AllProjectsSelectors, UserSelectors } from "src/redux/selectors";
import { useSelector } from "react-redux";
import { LastLogDB } from "src/db-types";
import { UserActions } from "src/redux/actionCreators";

interface IReturn {
  removeProject: (id: string) => void;
  projects: ProjectResponseDB[];
  loadAllProjects: (userId: string | undefined) => void;
}
export const useLoadAllProjects = (): IReturn => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const versionsMap = useSelector(AllProjectsSelectors.getVersionsMap);
  const [projects, setProjects] = useState<ProjectResponseDB[]>([]);
  const { fetchData: lastLogApiCall, response: lastLogApiResponse } = useLastLogApi();

  const user = useAppSelector(UserSelectors.getUser);

  const { error, fetchData, loading, response } = useGetAllProjects();

  useEffect(() => {
    dispatch(
      AppActions.updateAppState({
        apiStates: {
          isLoadAllProjectsApiLoading: loading,
        },
      }),
    );
  }, [dispatch, loading]);

  useEffect(() => {
    if (!response) return;

    const {
      data: { data: dbProjects },
    } = response;
    setProjects(dbProjects.filter((a) => a?.quoteID !== undefined));
  }, [response]);

  useEffect(() => {
    if (!error) return;

    const message = getErrorMessage(error);
    // toast.error(t(message));
  }, [error, t]);

  const loadAllProjects = useCallback(
    (userId: string | undefined) => {
      if (!userId) return;

      void fetchData({
        ...BackendApis.getAllProjects({
          uid: userId,
        }),
      });
    },
    [fetchData],
  );

  useEffect(() => {
    if (lastLogApiResponse) {
      const {
        data: { data: userDB },
      } = lastLogApiResponse;
      dispatch(UserActions.updateLastLog(userDB.lastLog));
    }
  }, [dispatch, lastLogApiResponse]);

  useEffect(() => {
    loadAllProjects(user?.id);
  }, [loadAllProjects, user?.id]);

  const removeProject = useCallback(
    (id: string) => {
      setProjects(projects.filter((p) => p._id !== id));
      const projectDetails = projects.find((p) => p._id == id);
      if (projectDetails) {
        const projectVersions = versionsMap[projectDetails?.shortId];
        const remainingProjectVersions = projectVersions?.filter(
          (p) => p.version !== projectDetails?.version,
        );

        if (user?.lastLog) {
          let newLastLog: LastLogDB = {
            pid: user?.lastLog?.pid,
            address: user?.lastLog?.address,
          };

          if (user?.lastLog?.pid === `${projectDetails?.shortId}/${projectDetails?.version}`) {
            newLastLog = {
              pid: `${remainingProjectVersions?.[0]?.shortId}/${remainingProjectVersions?.[0]?.version}`,
              address: user?.lastLog?.address,
            };
          }
          void lastLogApiCall({
            ...BackendApis.updateLastLog,
            data: newLastLog,
          });
        }
      }
    },
    [projects],
  );

  return { projects, removeProject, loadAllProjects };
};
