import React from "react";

const CloseIcon: React.FC = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="13" fill="#373737" fillOpacity="0.82" />
      <path
        d="M7.5512 7.5512C7.62668 7.47554 7.71634 7.4155 7.81505 7.37454C7.91376 7.33358 8.01958 7.3125 8.12645 7.3125C8.23332 7.3125 8.33915 7.33358 8.43786 7.37454C8.53657 7.4155 8.62623 7.47554 8.7017 7.5512L13.0015 11.8526L17.3012 7.5512C17.3767 7.47566 17.4664 7.41573 17.5651 7.37485C17.6638 7.33397 17.7696 7.31293 17.8765 7.31293C17.9833 7.31293 18.0891 7.33397 18.1878 7.37485C18.2865 7.41573 18.3762 7.47566 18.4517 7.5512C18.5272 7.62674 18.5872 7.71643 18.6281 7.81513C18.6689 7.91383 18.69 8.01962 18.69 8.12645C18.69 8.23329 18.6689 8.33907 18.6281 8.43778C18.5872 8.53648 18.5272 8.62616 18.4517 8.7017L14.1503 13.0015L18.4517 17.3012C18.5272 17.3767 18.5872 17.4664 18.6281 17.5651C18.6689 17.6638 18.69 17.7696 18.69 17.8765C18.69 17.9833 18.6689 18.0891 18.6281 18.1878C18.5872 18.2865 18.5272 18.3762 18.4517 18.4517C18.3762 18.5272 18.2865 18.5872 18.1878 18.6281C18.0891 18.6689 17.9833 18.69 17.8765 18.69C17.7696 18.69 17.6638 18.6689 17.5651 18.6281C17.4664 18.5872 17.3767 18.5272 17.3012 18.4517L13.0015 14.1503L8.7017 18.4517C8.62616 18.5272 8.53648 18.5872 8.43778 18.6281C8.33907 18.6689 8.23329 18.69 8.12645 18.69C8.01962 18.69 7.91383 18.6689 7.81513 18.6281C7.71643 18.5872 7.62674 18.5272 7.5512 18.4517C7.47566 18.3762 7.41573 18.2865 7.37485 18.1878C7.33397 18.0891 7.31293 17.9833 7.31293 17.8765C7.31293 17.7696 7.33397 17.6638 7.37485 17.5651C7.41573 17.4664 7.47566 17.3767 7.5512 17.3012L11.8526 13.0015L7.5512 8.7017C7.47554 8.62623 7.4155 8.53657 7.37454 8.43786C7.33358 8.33915 7.3125 8.23332 7.3125 8.12645C7.3125 8.01958 7.33358 7.91376 7.37454 7.81505C7.4155 7.71634 7.47554 7.62668 7.5512 7.5512Z"
        fill="#FFFBF2"
      />
    </svg>
  );
};

export default CloseIcon;
