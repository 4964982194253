import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useAppSelector } from "src/hooks";

import SingleImg from "src/assets/application_step_1/consumption.building.1.png";
import MultiImg from "src/assets/application_step_1/consumption.building.2.png";
import { buildingConsumptionsB2B } from "src/data";
import { PopupSkeleton } from "src/shared/components";

import { useStyles } from "./BuildingType.styles";

import { PopupActions } from "src/redux";
import { ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { ProjectSelectors } from "src/redux/selectors";

const b2cBuildings = [
  { title: "Single-family-building", imgSrc: SingleImg, key: "single" },
  {
    title: "Multi-family-building",
    imgSrc: MultiImg,
    key: "multi",
  },
];

const b2bBuildings = [
  { imgSrc: SingleImg, title: "Office", key: "Office" },

  { imgSrc: MultiImg, title: "Manufacturer", key: "Manufacturer" },

  { imgSrc: MultiImg, title: "Restaurant", key: "Restaurant" },

  { imgSrc: MultiImg, title: "Administration", key: "Administration" },

  { imgSrc: SingleImg, title: "Agriculture", key: "Agriculture" },

  { imgSrc: MultiImg, title: "School", key: "School" },

  { imgSrc: MultiImg, title: "Shopping", key: "Shopping" },

  { imgSrc: MultiImg, title: "Hotel", key: "Hotel" },

  { imgSrc: MultiImg, title: "Other", key: "Other" },
];

export const SelectBuildingType: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();

  const { buildingType } = useAppSelector(ProjectSelectors.getHousehold);
  const projectType = useAppSelector(ProjectSelectors.getProjectType);

  const closeDialog = (): void => {
    dispatch(PopupActions.closePopup(POPUP_KEYS.building_type));
  };

  const handleCardClick = (type: string): void => {
    if (projectType === "b2c") {
      dispatch(
        ProjectActions.updateHousehold({
          buildingType: type,
        }),
      );
    } else {
      dispatch(
        ProjectActions.updateHousehold({
          buildingType: type,
          kwaPerEmployee:
            buildingConsumptionsB2B[type as keyof typeof buildingConsumptionsB2B].kwa_per_employee,
          people:
            buildingConsumptionsB2B[type as keyof typeof buildingConsumptionsB2B].no_of_employees,
        }),
      );
    }
    closeDialog();

    if (type === "multi") {
      dispatch(
        ProjectActions.updateAdditionalSoftware({
          quantity: 1,
        }),
      );
      dispatch(
        ProjectActions.updateAdditionalHardware({
          quantity: 1,
        }),
      );
      dispatch(PopupActions.openPopup(POPUP_KEYS.multi_tenant));
    }
  };

  const validBuildings = projectType === "b2b" ? b2bBuildings : b2cBuildings;

  return (
    <PopupSkeleton handleClose={closeDialog} open={true}>
      <div className={classes.content}>
        <h4 className={[classes.center, classes.heading].join(" ")}>
          {projectType === "b2c" && t("In what building type do you live?")}
          {projectType === "b2b" && t("Which of the following business types matches you?")}
        </h4>
        <div className={classes.main_container}>
          {validBuildings.map((building) => {
            return (
              <BuildingCard
                key={building.key}
                title={building.title}
                imgSrc={building.imgSrc}
                onClick={() => handleCardClick(building.key)}
                isCardSelected={buildingType === building.key}
              />
            );
          })}
        </div>
      </div>
    </PopupSkeleton>
  );
};

interface BuildingCardProps {
  isCardSelected: boolean;
  imgSrc: string;
  title: string;
  onClick: () => void;
}
const BuildingCard: React.FC<BuildingCardProps> = (props: BuildingCardProps) => {
  const { isCardSelected, imgSrc, title, onClick } = props;
  const classes = useStyles();
  const appliedClasses = [classes.inside_main];
  if (isCardSelected) {
    appliedClasses.push("selected");
  }

  return (
    <div onClick={onClick} className={appliedClasses.join(" ")}>
      <img src={imgSrc} alt="Home" />
      <h5 className={classes.center}>{t(title)}</h5>
    </div>
  );
};
