import { clone } from "lodash";

import { ApplicationState } from "../../types";

import { actionCreators } from "src/redux/application";

export const setCurrentStep = (
  currentState: ApplicationState,
  { payload }: ReturnType<typeof actionCreators.setCurrentStep>,
): ApplicationState => {
  const newState = clone(currentState);

  newState.currentStep = payload;
  return newState;
};
