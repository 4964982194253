import { useEffect, useState } from "react";

import { BackendApis, constantItem } from "src/api";

import { useGetConstant } from "./apis";

import { updateProperties } from "src/redux/application/action/action.creators";
import { updateWebConstants } from "src/redux/project/action/action.creators";

import { useAppDispatch } from ".";
import { useSelector } from "react-redux";
import { UserSelectors } from "src/redux/selectors";
import { getProject } from "src/redux/project/selectors";

export const useGetConstants = (): void => {
  const dispatch = useAppDispatch();
  const installerDetails = useSelector(UserSelectors?.getInstaller);
  const project = useSelector(getProject);

  const [allData, setAllData] = useState<constantItem[]>([]);

  const { fetchData, error, response } = useGetConstant();

  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);

  useEffect(() => {
    if (!response) return;

    setAllData(response.data.data);
  }, [response]);

  useEffect(() => {
    void fetchData({
      ...BackendApis.constants,
    });
  }, [fetchData]);

  useEffect(() => {
    if (allData.length) {
      const germanyConstants: any = allData.find((data) => data.name === "germany");

      const properties: any = {};
      germanyConstants.properties.forEach((property: string) => {
        properties[property] = false;
      });

      const installerConastant = installerDetails?.constants?.filter((a) => a.isActive);
      const offerConstant = project?.selectedInstallers.find(
        (offer) => offer?.isDetailedOffer,
      )?.selectedConstants;

      dispatch(
        updateWebConstants({
          constants: !installerDetails
            ? germanyConstants?.constants
            : offerConstant || installerConastant,
        }),
      );

      dispatch(updateProperties(properties));
    }
  }, [allData, dispatch, installerDetails, project?.selectedInstallers]);
};
