import { clone, merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateCar = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateCar>,
): ProjectState => {
  const newState = clone(currentState);

  const ind = currentState.components.cars.findIndex((c) => c.id === payload.id);
  if (ind === -1) return newState;

  const cars = clone(currentState.components.cars);
  cars[ind] = merge({}, cars[ind], payload.car);
  cars[ind].configuration.evYearlyConsumption =
    (cars[ind].configuration.consumptionPer100Km * cars[ind].configuration.evKmsTravelled) / 100;

  return {
    ...currentState,
    components: {
      ...currentState.components,
      cars,
    },
  };
};
