import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { CloseButton } from "src/shared/components";

interface HeadingProps {
  onCloseIconClick: MouseEventHandler;
  text: string;
}
const Heading: React.FC<HeadingProps> = (props: HeadingProps) => {
  const { onCloseIconClick, text } = props;

  const { t } = useTranslation();

  return (
    <div className="same_part">
      <div className="text_first">{t(text)}</div>
      <CloseButton onClick={onCloseIconClick} variant="only-cross" />
    </div>
  );
};

export default Heading;
