import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  isNoQuotePresent,
  isQuoteNotVerified,
  isQuotePending,
  isQuotePresent,
  isQuoteVerified,
} from "src/utils";

import {
  NoAddressBar,
  NonVerifiedAddressBar,
  PendingAddressBar,
  VerifiedAddressBar,
} from "./components";
import ReferralAddressBar from "./components/referralAddressBar/ReferralAddressBar";
import { useAddressWithCountryReplacement } from "./hooks";
import { MainContainer, RefTextContainer } from "./styles";

import { selectors as ProjectSelectors } from "src/redux/project";
import { getUser } from "src/redux/user/selectors";

const ApplicationStatus: React.FC = () => {
  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const status = useSelector(ProjectSelectors.getQuoteStatus);
  const [query] = useSearchParams();
  const addressText = useAddressWithCountryReplacement();
  const refCode: string | null = query.get("refCode");
  const user = useSelector(getUser);
  const { t } = useTranslation();

  const isRefCodeIsMine = user?.refCode.find((refDetails) => refDetails.refCode === refCode);

  return (
    <>
      <MainContainer>
        {!isRefCodeIsMine && (
          <>
            {isNoQuotePresent(quoteId) && <NoAddressBar />}
            {isQuotePresent(quoteId) && isQuoteVerified(status) && (
              <VerifiedAddressBar addressText={addressText} />
            )}
            {isQuotePresent(quoteId) && isQuoteNotVerified(status) && (
              <NonVerifiedAddressBar addressText={addressText} />
            )}
            {isQuotePresent(quoteId) && isQuotePending(status) && (
              <PendingAddressBar addressText={addressText} />
            )}
          </>
        )}
        {isRefCodeIsMine && <ReferralAddressBar addressText={addressText} />}
      </MainContainer>
      {isRefCodeIsMine && <RefTextContainer>{t("Referral Programme text")}</RefTextContainer>}
    </>
  );
};

export default ApplicationStatus;
