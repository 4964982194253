import styled from "styled-components/macro";

export const Wrapper = styled.div`
  padding: 1rem;
  color: rgba(76, 78, 100, 0.68);
  font-size: 16px;
  display: flex;
  flex-direction: column;

  .numerical {
    font-size: 20px;
    color: rgba(76, 78, 100, 0.87);
  }

  .sub-numerical {
    font-size: 14px;
    color: rgba(76, 78, 100, 0.38);
  }
`;

interface LabelColumnType {
  align: "left" | "right";
}
export const LabelColumn = styled.div<LabelColumnType>`
  flex-grow: 1;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: ${(p) => (p.align == "left" ? "flex-start" : "flex-end")};
`;

export const Line = styled.div`
  flex-grow: 1;
  background-color: rgba(76, 78, 100, 0.12);
  width: 2px;
`;

export const Versus = styled.div`
  aspect-ratio: 1;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #6d788d;
  text-align: center;
  padding: 2px;
  margin-block: 4px;
  border-radius: 10px;
  color: rgba(76, 78, 100, 0.68);
  text-transform: uppercase;
`;

interface BarTypes {
  bgColor: string;
  topColor: string;
  topPercent: number;
}
export const Bar = styled.div<BarTypes>`
  height: 1rem;
  margin-block: 0.5rem;
  width: 100%;
  border-radius: 10px;
  background-color: ${(p) => p.bgColor};
  position: relative;
  overflow: hidden;

  .top-bar {
    width: ${(p) => p.topPercent}%; /* Use the percentage prop here */
    height: 1rem;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${(p) => p.topColor};
    transition: all 500ms;
  }
`;
