import styled from "styled-components/macro";

export const SubContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

export const MainText1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #304864;
  margin-bottom: 10px;
`;

export const ColContain = styled.div`
  height: 1px;
  width: 100%;
  background: #e3e3e3;
`;
