import { clone, cloneDeep, merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateModel = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateModel>,
): ProjectState => {
  const newState = clone(currentState);

  newState.model = merge({}, currentState.model, cloneDeep(payload));
  return newState;
};
