import { Matrix } from "src/prediction-model/types/model.types";
/**
 *
 * @param inputs 1 * n matrix
 * @param coeffs [n * NODES_COUNT, NODES_COUNT * 1] matrix
 * @param intercepts [1 * NODES_COUNT, 1 * 1] matrix
 *
 * layer_1 = input(1 * n) * coeffs[0](n * NODES_COUNT) + intercepts(1 * NODES_COUNT)
 * layer_1 = relu(layer_1)
 *
 * layer_2 = layer_1(1 * NODES_COUNT) * coeffs[1](NODES_COUNT, 1) + intercepts(1 * 1)
 * layer_3 = layer_2    // output
 */
export const forwardPropogation = (inputs: number[], coeffs: Matrix[], intercepts: Matrix) => {
  const N = inputs.length;
  const coeffs_0 = coeffs[0];
  const coeffs_1 = coeffs[1];
  const intr_0 = [intercepts[0]];
  const intr_1 = [intercepts[1]];

  const layer_1 = matrixAdd(matrixMultiply([inputs], coeffs_0), intr_0);
  reluActivationInplace(layer_1);

  const layer_2 = matrixAdd(matrixMultiply(layer_1, coeffs_1), intr_1);

  const layer2Rows = layer_2.length;
  const layer2Columns = layer_2[0].length;
  if (layer2Rows != 1 || layer2Columns != 1) throw new Error("Prediction is not (1 * 1) matrix");

  const prediction = layer_2[0][0];

  return prediction;
};

/**
 * Multiply a matrix
 * @param A Matrix a
 * @param B Matrix b
 * @returns resultant matrix
 */
const matrixMultiply = (A: Matrix, B: Matrix): Matrix => {
  const aRows = A.length;
  const aColumns = A[0].length;

  const bRows = B.length;
  const bColumns = B[0].length;

  if (aColumns != bRows) {
    throw new Error(
      "Matrix don't have proper sizes, A = (" +
        aRows.toString() +
        " * " +
        aColumns.toString() +
        ") and B = (" +
        bRows.toString() +
        " * " +
        bColumns.toString() +
        ")",
    );
  }

  const C: Matrix = [];
  for (let i = 0; i < aRows; i++) {
    C.push([]);
    for (let j = 0; j < bColumns; j++) {
      let cellValue = 0;
      for (let k = 0; k < aColumns; k++) {
        cellValue += A[i][k] * B[k][j];
      }
      C[i].push(cellValue);
    }
  }

  return C;
};

/**
 * Adds two matrix
 * @param A Matrix A
 * @param B Matrix B
 * @returns Addition of these matrix
 */
const matrixAdd = (A: Matrix, B: Matrix): Matrix => {
  const aRows = A.length;
  const aColumns = A[0].length;

  const bRows = B.length;
  const bColumns = B[0].length;

  if (aColumns != bColumns || aRows != bRows) {
    throw new Error(
      "Matrix don't have proper sizes, A = (" +
        aRows.toString() +
        " * " +
        aColumns.toString() +
        ") and B = (" +
        bRows.toString() +
        " * " +
        bColumns.toString() +
        ")",
    );
  }

  const C: Matrix = [];
  for (let i = 0; i < aRows; i++) {
    C.push([]);
    for (let j = 0; j < aColumns; j++) {
      C[i][j] = A[i][j] + B[i][j];
    }
  }

  return C;
};

/**
 * relu activation function : f(x) = max(0, x);
 * It does inplace to save memory
 * @param A Matrix on which relu activation funcation has to be applied
 */
const reluActivationInplace = (A: Matrix) => {
  const aRows = A.length;
  const aColumns = A[0].length;

  for (let i = 0; i < aRows; i++) {
    for (let j = 0; j < aColumns; j++) {
      A[i][j] = Math.max(0, A[i][j]);
    }
  }
};
