import { Add, NavigationOutlined } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useNaivgateAbsolute, useWindowDimensions } from "src/hooks";

import { AskCommunityIcon, FooterBtnCloseIcon, CometChatIcon } from "src/assets/svgs";
import { Mixpanel } from "src/mix-panel/mixpanel";
import * as trackingEvents from "src/mix-panel/trackingEvents";
import { AUTH_MODES, AuthPopupProps } from "src/popups";
import { isNoQuotePresent, userIsNotAuthenticated } from "src/utils";

import { TalkToOurExpertBtn, HoverableButton } from "./components";
import { useStyles } from "./styles";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { ProjectSelectors, UserSelectors } from "src/redux/selectors";

const FooterButtons: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNaivgateAbsolute();

  const [showChat, setshowChat] = useState(false);

  // const chat = useSelector(getChat);
  const currentUser = useSelector(UserSelectors.getUser);
  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const installerDetails = useSelector(UserSelectors?.getInstaller);

  // const [minimizedUser, setminimizedUser] = useState(null);
  // const [minimized, setminimized] = useState(false);
  const [showExpandableButtons, setShowExpandableButtons] = useState(true);

  const { width } = useWindowDimensions();
  const classes = useStyles();

  const reDirectToCommunity: any = useCallback(() => {
    if (userIsNotAuthenticated(currentUser)) {
      const authPopupProps: AuthPopupProps = {
        authMode: AUTH_MODES.login,
      };
      const action = PopupActions.openPopup(POPUP_KEYS.auth, authPopupProps);
      dispatch(action);
      return;
    }

    if (isNoQuotePresent(quoteId)) {
      const action = PopupActions.openPopup(POPUP_KEYS.welcome_address);
      dispatch(action);
      return;
    }

    localStorage.setItem("goBackUrl", window.location.pathname);
    navigate("/community/home");
  }, [currentUser, dispatch, navigate, quoteId]);

  // const minimizedHandler = useCallback((check: any, context: any): any => {
  //   if (check === "close") {
  //     setminimized(false);
  //   } else {
  //     setminimized((prev) => !prev);
  //   }
  //   if (context?.item) {
  //     setminimizedUser(context.item?.uid);
  //   } else {
  //     setminimizedUser(null);
  //   }
  // }, []);

  useEffect(() => {
    if (width < 550) setShowExpandableButtons(false);
    else setShowExpandableButtons(true);
  }, [width]);

  const isMobile = width < 550;

  return (
    <>
      {showExpandableButtons && (
        <div className={classes.bottomFixedIcons}>
          <TalkToOurExpertBtn />

          {!installerDetails && (
            <HoverableButton
              ImgIcon={AskCommunityIcon}
              text={"Ask the community"}
              onClick={() => {
                Mixpanel.track(trackingEvents.USER_CLICKS_ON_COMMUNITY, {});
                reDirectToCommunity();
              }}
            />
          )}

          {/* {currentUser && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: 58,
                minHeight: 51,
                userSelect: "none",
              }}
              onClick={() => {
                Mixpanel.track(trackingEvents.USER_CLICKS_ON_COMMET_CHAT_ICON, {});
                setshowChat((prev) => !prev);
                // setminimized(false);
              }}
              className={classes.cometChat}
            >
              <div>{showChat ? <FooterBtnCloseIcon /> : <CometChatIcon />}</div>
            </div>
          )} */}
        </div>
      )}
      {isMobile && (
        <div
          className={classes.bottomFixedIconsForMobile}
          onClick={() => setShowExpandableButtons((p) => !p)}
        >
          <Fab
            size="small"
            style={{ color: "#000", backgroundColor: "rgb(255, 215, 93)" }}
            aria-label="add"
            variant={showExpandableButtons ? "circular" : "extended"}
          >
            {showExpandableButtons ? (
              <Add style={{ transform: "rotate(45deg)" }} />
            ) : (
              <>
                <NavigationOutlined />
                {t("Contact")}
              </>
            )}
          </Fab>
        </div>
      )}

      {/* {chat.cometChatInitComplete && (
        <div style={cometChatstyle(showChat)}>
          <CometChatUserListWithMessages
            minimized={minimized}
            minimizedHandler={minimizedHandler}
            minimizedUser={minimizedUser}
          />
        </div>
      )} */}
    </>
  );
};

export default FooterButtons;
