import { merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateHeatpump = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateHeatpump>,
): ProjectState => {
  if (
    payload?.configuration?.consumptionHeatpump === 15000 &&
    currentState.components.heatpump.configuration.consumptionHeatpump !== 15000 &&
    currentState.components.heatpump.configuration.consumptionHeatpump > 0
  ) {
    return { ...currentState };
  }

  const heatpump = merge({}, currentState.components.heatpump, payload);

  return {
    ...currentState,
    components: {
      ...currentState.components,
      heatpump,
    },
  };
};
