import * as THREE from "three";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
let raycaster = new THREE.Raycaster();

export default class PanelGrid extends THREE.Object3D {
  bounds = new THREE.Box3();
  size = new THREE.Vector3();
  selectionSize = new THREE.Vector3();
  constructor({ modules, deleteObject, plusObject, dimentions }) {
    super();
    this.modules = modules;
    this.selectionMesh = planeMesh.clone();
    this.add(this.selectionMesh);
    this.deleteObject = deleteObject;
    this.plusObject = plusObject;
    this.fontLoader = new FontLoader();
    this.font = null;
    this.index = 1;
    this.dimentions = dimentions;

    // Load the font and store it in this.font
    this.fontLoader.load(
      "https://threejsfundamentals.org/threejs/resources/threejs/fonts/helvetiker_regular.typeface.json",
      (font) => {
        this.font = font;
      },
    );
  }

  setSize(x, y, z) {
    this.size.set(x, y, z);
  }

  calculateCenterPoint() {
    const center = new THREE.Vector3();
    this.selectionMesh.geometry.getCenter(center);
    this.add(center);
    return center;
  }

  recomputeOccupancy({ targets }) {
    let s = this.size;
    let panelX = this.dimentions.height;
    let panelY = this.dimentions.width;

    this.occupancy = PanelGrid.raycastGrid({
      root: this.children[0],
      targets,
      step: {
        x: panelX / s.x,
        y: panelY / -s.y,
      },
    });
    return this.occupancy;
  }

  static raycastGrid({ root, targets, step }) {
    let down = new THREE.Vector3(0, -1, 0);
    let results = [];
    let rows = 0;
    let cols;
    let panelSpacing = 0.005;
    let nx = (1 / step.x) | 0;
    let ny = (1 / step.y) | 0;
    let margx = (1 - nx * step.x) * 0.5 ;
    let margy = (1 - ny * step.y) * 0.5 ;

    for (let x = step.x * 0.5 + margx; x < 1 - step.x * 0.5; x += step.x+ panelSpacing) {
      cols = 0;
      for (let z = step.y * 0.5 + margy; z <= 1 - step.y * 0.5; z += step.y + panelSpacing) {
        const origin = new THREE.Vector3(x + step.x * 0.5 , z + step.y * 0.5, 1.1);
        root && root.localToWorld(origin);
        raycaster.set(origin, down);

        let res = {
          origin,
          object: targets[0],
        };
        results.push(res);

        const intersects = raycaster.intersectObjects(targets, true);
        if (intersects.length > 0) {
          res.point = intersects[0].point.clone();
          res.normal = intersects[0].normal.clone();
          res.object = intersects[0].object;

          const validPlanarThresholdWS = 1;
          if (res.origin.distanceTo(res.point) < validPlanarThresholdWS) res.isSupported = true;
        }
        cols++;
      }
      rows++;
    }

    return {
      results,
      rows,
      cols,
      root,
    };
  }

  disposeOccupancy() {
    if (!this.occupancy) return;
    let occupancy = this.occupancy;
    occupancy.results.forEach((res) => {
      res && res.indicator && res.indicator.parent.remove(res.indicator);
    });
    occupancy.results.length = 0;
  }

  redrawOccupancy() {
    let occupancy = this.occupancy;
    let results = occupancy.results;

    console.log(results, "results");
    this.index = 1;
    // let normal = v1.set(0, 0, 1).applyQuaternion(occupancy.root.quaternion);
    for (const [index, result] of results.entries()) {
      if (!result) continue;
      if (!result.object.isMesh) continue;

      const { boundsTree } = result.object.geometry;
      const module = this.modules[0].clone();
      let panel = new THREE.Object3D();
      panel.position.copy(result.origin);
      panel.rotation.setFromQuaternion(occupancy.root.parent.quaternion);
      panel.updateMatrix();
      result.object.attach(panel);
      panel.updateMatrixWorld();
      // panel.rotation.set(Math.PI / 2, 0, 0);

      let moduleIsObstructed = (module) => {
        let box = new THREE.Box3().setFromObject(module);
        return boundsTree.intersectsBox(box, panel.matrix);
      };

      let moduleIsSupported = (module) => {
        let box = new THREE.Box3().setFromObject(module);
        let panelThickness = box.max.z - box.min.z;
        const supportThickness = panelThickness;
        const supportLength = panelThickness * 20;
        let c0 = box.clone();
        c0.max.x = c0.min.x + supportThickness;
        c0.max.y = c0.min.y + supportThickness;
        c0.min.z = c0.max.z - supportLength;
        if (!boundsTree.intersectsBox(c0, panel.matrix)) return false;

        let c1 = box.clone();
        c1.min.x = c1.max.x - supportThickness;
        c1.max.y = c1.min.y + supportThickness;
        c1.min.z = c1.max.z - supportLength;
        if (!boundsTree.intersectsBox(c1, panel.matrix)) return false;

        let c2 = box.clone();
        c2.min.x = c2.max.x - supportThickness;
        c2.min.y = c2.max.y - supportThickness;
        c2.min.z = c2.max.z - supportLength;
        if (!boundsTree.intersectsBox(c2, panel.matrix)) return false;

        let c3 = box.clone();
        c3.max.x = c3.min.x + supportThickness;
        c3.min.y = c3.max.y - supportThickness;
        c3.min.z = c3.max.z - supportLength;
        if (!boundsTree.intersectsBox(c3, panel.matrix)) return false;
        return true;
      };
      let icons = [];
      if (!result.isSupported || moduleIsObstructed(module) || !moduleIsSupported(module)) {
        for (let i = 1; i < this.modules.length; i++) {
          let submodule = this.modules[i].clone();
          if (!moduleIsObstructed(submodule) && moduleIsSupported(module)) panel.add(submodule);
        }
      } else {
        module.children[0].originalMaterial = module.children[0].material;
        panel.add(module);
        panel.name = "supported";

        let deleteIcon = this.deleteObject.clone(); // changes
        deleteIcon.name = "deleteIcon"; // changes
        const box = new THREE.Box3().setFromObject(panel); // changes
        const panelSize = new THREE.Vector3();
        box.getSize(panelSize); // changes

        deleteIcon.position.set(
          0.2, // Adjusting for the size of the icon
          0.5, // Adjusting for the size of the icon
          0.3, // Set to slightly above the panel surface
        );
        deleteIcon.visible = false;
        // deleteIcon.position.set(0, 0, 0.3);
        // deleteIcon.visible = false
        panel.add(deleteIcon); // changes

        const greenMesh = this.plusObject.clone();
        greenMesh.rotation.x = -Math.PI / 2; // Rotate horizontally flat
        greenMesh.rotation.y = Math.PI / 2;
        greenMesh.position.set(0, 0, 0.3);
        greenMesh.visible = false; // Make it invisible by default
        greenMesh.name = "add-icon";
        panel.add(greenMesh);
        // if (this.font) {
        //   const textGeometry = new TextGeometry(this.index.toString(), {
        //     font: this.font,
        //     size: 0.3,
        //     height: 0.05,
        //   });
        //   const textMaterial = new THREE.MeshStandardMaterial({
        //     color: "white",
        //   });
        //   const textMesh = new THREE.Mesh(textGeometry, textMaterial);
        //   textMesh.position.set(
        //     -0.5, // Adjusting for the size of the icon
        //     -0.7,
        //     0.01,
        //   );
        //   this.index = this.index + 1; // Position text above the panel
        //   panel.add(textMesh);
        // }
      }

      this.attach(panel);

      result.indicator = panel;

      panel.updateMatrixWorld(true);

      /*
        let lookTarg = deleteIcon.position.clone();
        lookTarg.z+=1;
        deleteIcon.localToWorld(lookTarg);
        deleteIcon.lookAt(lookTarg);

        */
      icons.forEach((icon) => {
        let lookTarg = new THREE.Vector3(0, 0, 1);
        lookTarg.applyMatrix4(icon.matrixWorld);
        icon.lookAt(lookTarg);
      });

      panel.traverse((e) => (e.matrixAutoUpdate = false));
    }
  }
}

const baseMetal = 0.0;
const baseRough = 0.8;
PanelGrid.selectedStateMaterial = new THREE.MeshStandardMaterial({
  metalness: baseMetal,
  roughness: baseRough,
  transparent: true,
  opacity: 0.4,
  depthWrite: false,
  color: "teal",
});
PanelGrid.collidedStateMaterial = new THREE.MeshStandardMaterial({
  metalness: baseMetal,
  roughness: baseRough,
  transparent: true,
  depthWrite: false,
  opacity: 0.15,
  color: "red",
});
PanelGrid.nonCollidedStateMaterial = new THREE.MeshStandardMaterial({
  metalness: baseMetal,
  roughness: baseRough,
  transparent: true,
  depthWrite: false,
  opacity: 0.9,
  color: "green",
});
PanelGrid.deselectedStateMaterial = new THREE.MeshStandardMaterial({
  metalness: baseMetal,
  roughness: baseRough,
  transparent: true,
  depthWrite: false,
  opacity: 0.1,
  color: "blue",
});
let planeMesh = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), PanelGrid.selectedStateMaterial);
planeMesh.geometry.translate(0.5, 0.5, 0.5);
planeMesh.geometry.computeBoundingBox();
