import styled from "styled-components/macro";

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;

  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: rgb(191, 191, 191, 0.1);
`;
