import { Slider } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import * as S from "../../HeatpumpConfigurationBox.styles";

import { ProjectSelectors } from "src/redux/selectors";

const SolarPowerLine: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    heatpump: {
      percentages: { autarky, maxPossibleAutarky },
    },
  } = useSelector(ProjectSelectors.getModel);

  const onSliderChangeHandler = useCallback(
    (value: any) => {
      // dispatch(setHeatpumpSelfSupply(value));
      // dispatch(actionCustomAutarkyChange());
    },
    [dispatch],
  );

  return (
    <ParameterStyles.Line hasSlider>
      <ParameterStyles.Label hasSlider>{t("SOLAR POWER FOR HEATPUMP")}</ParameterStyles.Label>
      <ParameterStyles.Input style={{ width: "74%", paddingRight: "21px" }}>
        <Slider value={autarky} onChange={onSliderChangeHandler} max={maxPossibleAutarky} />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <S.SubContainerValue>{autarky.toFixed(0)} %</S.SubContainerValue>
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default SolarPowerLine;
