import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ProductPropertiesKey, ProductDB } from "src/db-types";
import { ValueOf } from "src/utils";

import { ProjectComponents } from "src/redux/project";
import { ProductSelectors } from "src/redux/selectors";
import { RootState } from "src/redux/store";

const getSelectorFn = (type: keyof ProjectComponents): ((state: RootState) => ProductDB[]) => {
  if (type === "battery") return ProductSelectors.getBatteries;
  if (type === "cars") return ProductSelectors.getCars;
  if (type === "heatpump") return ProductSelectors.getHeatpumps;
  if (type === "inverter") return ProductSelectors.getInverters;
  if (type === "solarPanel") return ProductSelectors.getSolarPanels;
  if (type === "wallbox") return ProductSelectors.getWallboxes;
  if (type === "EMS") return ProductSelectors.getEms;

  return ProductSelectors.getAllProducts;
};

export type PropertiesFilter = Record<ProductPropertiesKey, boolean | null>;
export type ProductFilters = PropertiesFilter & {
  applicableMaxCapacity: number | null;
};
export type ProductFilterKeys = keyof ProductFilters;
type FilterEntriesArray = Array<[ProductFilterKeys, ValueOf<ProductFilters>]>;

export const defaultFilters: ProductFilters | any = {
  "Made in Germany": false,
  "Glas / Glas Module": false,
  "High performance module >370 Wp": false,
  "Full black": false,
  "Data visualization": false,
  "Load management": false,
  "made in europe": false,
  hybrid: false,
  Extendible: false,
  "Emergency Power": false,
  Display: false,
  "Schnellladung 22KW": false,
  App: false,
  Authorisation: false,
  "Good energy management - Smart Home": false,
  "High performance module >420 Wp": false,
  "Air/Water-Heatpump": false,
  "App for heat management": false,
  "Warm water heating": false,
  applicableMaxCapacity: null,
  "25 Jahre Leistungsgarantie (mind 81%)": false,
  "more than 2 roof areas / shading": false,
};
interface UseProductsReturnType {
  products: Array<
    ProductDB & {
      isCompatible?: boolean;
    }
  >;
  setFilter: Dispatch<SetStateAction<ProductFilters>>;
  filters: ProductFilters;
}

interface UseProductsProps {
  type: keyof ProjectComponents;
}
export const useProducts = ({ type }: UseProductsProps): UseProductsReturnType => {
  const selectorFn = getSelectorFn(type);
  const products = useSelector(selectorFn);

  const [filters, setFilters] = useState<ProductFilters>({
    ...defaultFilters,
  });
  const [filteredProducts, setFilteredProducts] = useState<ProductDB[]>([]);

  useEffect(() => {
    const matchingProducts = products.filter((p) => {
      const entries = Object.entries(filters) as FilterEntriesArray;

      for (const [key, value] of entries) {
        const isFilterNotSet = !value;
        if (isFilterNotSet) continue;

        if (key === "applicableMaxCapacity") {
          const { applicableMaxCapacity } = p;
          if (!applicableMaxCapacity) return false;

          if (applicableMaxCapacity < (value as number) * 1000) return false;
        } else {
          if (p.properties?.[key] !== value) {
            return false;
          }
        }
      }
      return true;
    });

    setFilteredProducts(matchingProducts);
  }, [filters, products]);

  return {
    products: filteredProducts,
    setFilter: setFilters,
    filters,
  };
};
