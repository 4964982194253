import { Grid } from "@mui/material";
import { MouseEventHandler } from "react";

import Info from "./Info";

interface InfoIconProps {
  onClick?: MouseEventHandler;
}
const InfoIcon: React.FC<InfoIconProps> = (props: InfoIconProps) => {
  const { onClick } = props;

  return (
    <Grid
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <Info />
    </Grid>
  );
};

export default InfoIcon;
