import { ListItemText } from "@mui/material";
import { styled as muiStyled } from "@mui/system";
import styled from "styled-components/macro";

export const MainContainer = styled.div`
  .apply_text {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #2d4764 !important;
  }
  .mainDiv {
    background-color: antiquewhite;
  }
  .pointer {
    cursor: pointer;
  }
`;

export const CustomListItemText = muiStyled(ListItemText)({
  color: "#2D4764",
  fontWeight: "600 !important",
  fontSize: "14px",
});
