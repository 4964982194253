import React from "react";
import { useSelector } from "react-redux";

import { CarLine } from "./components";

import { ProjectSelectors } from "src/redux/selectors";

const CarsList: React.FC = () => {
  const cars = useSelector(ProjectSelectors.getCars);

  if (cars.length === 0) return <></>;
  
  return (
    <>
      {cars.map((data) => {
        return (
          <CarLine
            key={data.id}
            car={data}
            showEMobilityTitle={cars.length === 1 && !cars[0].item}
          />
        );
      })}
    </>
  );
};

export default CarsList;
