import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { useCreateNewVersion } from "./apis";

import { AppActions } from "src/redux";
import { AppSelectors, ProjectSelectors } from "src/redux/selectors";

interface IAddConfigReturn {
  addConfig: (quoteId: string) => Promise<void>;
}
export const useAddConfiguration = (): IAddConfigReturn => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { fetchData, response } = useCreateNewVersion();

  useEffect(() => {
    if (!response) return;

    toast.success(t("Solar Configuration new version created successfully"));
    dispatch(
      AppActions.updateAppState({
        actions: {
          shouldLoadUniqueProjects: true,
        },
      }),
    );
  }, [dispatch, response, t]);

  const addConfig = useCallback(
    async (quoteId: string) => {
      await fetchData({
        ...BackendApis.createNewVersion({ quoteId }),
      });
    },
    [fetchData],
  );

  return {
    addConfig,
  };
};
