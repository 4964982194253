import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DonoughtChart, DonoughtRecord } from "src/shared/components";
import { getPercentage } from "src/utils";

import { getChartRecordsFromModel } from "./HeatSupplyChartRecords";

import { UserSelectors, ProjectSelectors } from "src/redux/selectors";

const HeatSupplyChart: React.FC = () => {
  const { t } = useTranslation();

  const lang = useSelector(UserSelectors.getLangCode);
  const model = useSelector(ProjectSelectors.getModel);

  const [chartData, setChartData] = useState<DonoughtRecord[]>([]);

  useEffect(() => {
    const chartRecords = getChartRecordsFromModel(model);
    setChartData(chartRecords);
  }, [model, lang]);

  return (
    <DonoughtChart
      title={t("Heat supply")}
      chartData={chartData}
      middleText={{
        title: `${getPercentage(
          model.heatpump.fromPVAndBattery + model.heatingRod.fromPVAndBattery,
          model.heatpump.consumption + model.heatingRod.consumption,
        ).toFixed(0)}%`,
        subTitle: t("Solar for heat"),
      }}
    />
  );
};

export default HeatSupplyChart;
