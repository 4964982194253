import React from "react";
import * as R from "../InformationPartRight/InformationPart.styles";
import { useTranslation } from "react-i18next";
import { InfoIcon } from "src/shared/components";
import { FormControl, FormControlLabel, FormGroup } from "@mui/material";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { PrimaryButton } from "src/shared/StyledComponents";
import { useHubspotUpdate } from "src/pages/ApplicationPage/hooks/useHubspotUpdate";

const ThreeDModelInfo = ({
  droneDataChangeHandler,
  ThreeDModels,
  Sunnydesign,
  Solaredge,
  PVSol,
}: any) => {
  const { t } = useTranslation();
  const { createTask } = useHubspotUpdate();

  return (
    <>
      <R.Heading>
        {t("Add PV-Simulation")}
        <InfoIcon />
      </R.Heading>
      <FormControl component="fieldset" variant="standard" style={{ margin: "10px" }}>
        <FormGroup style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={ThreeDModels.threeDpvModelSunnyDesign}
                onChange={(e: any) =>
                  droneDataChangeHandler(e.target.checked, "threeDpvModelSunnyDesign")
                }
              />
            }
            className="checkbox"
            label={
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  paddingLeft: "8px",
                }}
              >
                <img src={Sunnydesign} alt="Solaredge" height={14} />
                {t("3D-PV-model (easily modify model)")}
              </div>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={ThreeDModels.threeDpvModelSolarEdgeDesign}
                onChange={(e: any) =>
                  droneDataChangeHandler(e.target.checked, "threeDpvModelSolarEdgeDesign")
                }
              />
            }
            className="checkbox"
            label={
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  paddingLeft: "8px",
                }}
              >
                <img src={Solaredge} alt="Solaredge" height={14} />
                {t("3D-PV-model (easily modify model)")}
              </div>
            }
          />
        </FormGroup>
      </FormControl>

      <PrimaryButton
        onClick={createTask}
        style={{
          width: "230px",
          marginBlock: "unset",
          marginInline: "unset",
        }}
      >
        {`${t("Request PV-Planning")}`}
      </PrimaryButton>
      <R.Heading>
        {t("Requested data")}
        <InfoIcon />
      </R.Heading>

      <FormControl component="fieldset" variant="standard" style={{ margin: "10px" }}>
        <FormGroup style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={ThreeDModels.pvSolModifyModel}
                onChange={(e: any) => droneDataChangeHandler(e.target.checked, "pvSolModifyModel")}
              />
            }
            className="checkbox"
            label={
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  paddingLeft: "8px",
                }}
              >
                <img src={PVSol} alt="PVSol" height={14} />
                {t("PVSol 3D-PV-model (easily modify model)")}
              </div>
            }
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

export default ThreeDModelInfo;
