import { Slider } from "antd";
import { t } from "i18next";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { MasterInput } from "src/shared/components";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import { HeatpumpConfiguration } from "src/redux/project";
import { ProjectSelectors } from "src/redux/selectors";

interface HeatConsumptionLineProps {
  onValueChange: (heatpumpPartData: Partial<HeatpumpConfiguration>) => void;
}
const HeatConsumptionLine: React.FC<HeatConsumptionLineProps> = (
  props: HeatConsumptionLineProps,
) => {
  const { onValueChange } = props;

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);

  const onSliderChangeHandler = useCallback(
    (value: any) => {
      onValueChange({
        consumptionHeatpump: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line hasSlider className="evKm" style={{ marginTop: "6px" }}>
      <ParameterStyles.Label>{t("HEAT CONSUMPTION")}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider
          step={0.01}
          value={configuration.consumptionHeatpump}
          onChange={onSliderChangeHandler}
          max={20000}
        />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <MasterInput
          value={configuration.consumptionHeatpump}
          onChange={onSliderChangeHandler}
          label="kWh"
        />
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default HeatConsumptionLine;
