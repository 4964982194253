import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Logo from "src/popups/paywall/vectors/Logo";
import { MasterCheckbox } from "src/shared/components";
import { isValidURL } from "src/utils";

import { VendorCardWrapper } from "./VendorCard.styles";

import { getAddress } from "src/redux/project/selectors";
import { ProjectSelectors } from "src/redux/selectors";

export const VendorCard: React.FC<any> = ({ setIsCreditTic, isCretiTick, finalPrice }) => {
  const { t } = useTranslation();
  const { isFinancing } = useSelector(ProjectSelectors.getFinancialDetails);
  const quoteProfile = useSelector(ProjectSelectors.getQuoteProfile);
  const address = useSelector(getAddress);

  const filteredImages = useMemo(() => {
    return quoteProfile?.imgURLs.filter((img) => {
      const fileExtension: any = img?.split(".").pop()?.toLowerCase();
      const isInvalidExtension = ["obj", "mtl", "pvprj"].includes(fileExtension);
      if (isInvalidExtension) {
        return false;
      }
      if (img.includes("Destination") && isValidURL(img)) {
        if (address.city === "Gundelfingen") {
          return !img.includes("Baked") && isValidURL(img);
        } else {
          return img.includes("Screenshot");
        }
      }
      return isValidURL(img);
    });
  }, [address, quoteProfile.imgURLs]);

  return (
    <VendorCardWrapper>
      <div className="logo_image">
        <Logo />
      </div>
      <div className="price">
        <h3 className="price_title">
          {isFinancing ? t("Financing rate solar system") : t("Buying rate solar system")}
        </h3>
        <h3>
          <strong className="price_rate">{finalPrice}</strong>
        </h3>
        <div style={{ marginBottom: "10px" }}>
          <MasterCheckbox
            checked={isCretiTick}
            onChange={(value) => {
              setIsCreditTic(value);
            }}
          >
            {t("Redeem referral credits")}
          </MasterCheckbox>
        </div>
        <img
          className="project_image"
          src={filteredImages.length ? filteredImages[0] : ""}
          alt=""
        />
      </div>
    </VendorCardWrapper>
  );
};
