import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from "axios";
import { isEmpty, isNil } from "lodash";
import { useState, useCallback, useRef } from "react";

import { ApiAxiosClient } from "src/axios";

export interface UseAxiosReturn<RequestBody, ResponseBody> {
  error: AxiosError | null;
  loading: boolean;
  response: AxiosResponse<ResponseBody> | null;
  fetchData: (params: AxiosRequestConfig<RequestBody>) => Promise<void>;
  toggleUploadPause: any;
}
interface UseAxiosProps {
  useBackendInstance?: boolean;
  debounceTime?: number;
  allowMultipleRequests?: boolean;
}

export const useAxios = <RequestBody, ResponseBody>(
  props: UseAxiosProps = {},
): UseAxiosReturn<RequestBody, ResponseBody> => {
  let { allowMultipleRequests, useBackendInstance } = props;
  if (isNil(allowMultipleRequests)) allowMultipleRequests = false;
  if (isNil(useBackendInstance)) useBackendInstance = true;

  const [response, setResponse] = useState<AxiosResponse<ResponseBody> | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState(false);
  const [isUploadPaused, setUploadPaused] = useState(false);
  const cancelSourceRef = useRef<CancelTokenSource>();

  const toggleUploadPause = useCallback((isPaused: boolean) => {
    setUploadPaused(isPaused);
  }, []);

  const fetchData = useCallback(
    async (params: AxiosRequestConfig<RequestBody>) => {
      setResponse(null);
      setError(null);
      setLoading(true);

      try {
        if (isUploadPaused) {
          setLoading(false);
          return;
        }

        if (!allowMultipleRequests && cancelSourceRef.current) {
          cancelSourceRef.current.cancel();
        }
        const axiosInstance = useBackendInstance ? ApiAxiosClient : axios;
        const cancelSource = axios.CancelToken.source();
        cancelSourceRef.current = cancelSource;

        const result = await axiosInstance.request({ ...params, cancelToken: cancelSource.token });

        setLoading(false);
        setResponse(result);
      } catch (err) {
        setLoading(false);
        setError(err as AxiosError);
      }

      setResponse(null);
      setError(null);
    },
    [allowMultipleRequests, useBackendInstance],
  );

  return { response, error, loading, fetchData, toggleUploadPause };
};

export type UseAxiosHook = typeof useAxios;
