import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/hooks";

import { CallNowIcon } from "src/assets/svgs";
import { InstallerDB } from "src/db-types";

import * as S from "./CallNow.styles";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { UserSelectors } from "src/redux/selectors";
import { PopupButton } from "react-calendly";
import { getUser } from "src/redux/user/selectors";

const URL_OPTIONS = {
  goToHubspot: "goToHubspot",
  goToEmail: "goToEmail",
  goToCalendy: "goToCalendy",
  goToRedirect: "goToRedirect",
};

const CallNow: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const installer: InstallerDB | null = useSelector(UserSelectors.getInstaller);
  const user = useSelector(getUser);

  const openHubspotPopup = useCallback(() => {
    if (installer?.whichOptionSelected === URL_OPTIONS.goToHubspot) {
      dispatch(PopupActions.openPopup(POPUP_KEYS.hubspot_meeting));
    } else if (installer?.whichOptionSelected === URL_OPTIONS.goToRedirect) {
      window.open(installer.appointmentURL, "_blank", "noreferrer");
    } else if (installer?.whichOptionSelected === URL_OPTIONS.goToEmail) {
      //@ts-ignore
      window.location.href = `mailto:${installer?.appointmentURL}`;
    }else{
      dispatch(PopupActions.openPopup(POPUP_KEYS.hubspot_meeting));
    }
  }, [dispatch, installer]);

  return (
    <S.Container onClick={openHubspotPopup}>
      {installer?.whichOptionSelected !== URL_OPTIONS.goToCalendy && (
        <>
          <CallNowIcon />
          <span className="call-now hideOnMobile">
            {t("CALL NOW")}
          </span>
        </>
      )}
      {installer?.whichOptionSelected === URL_OPTIONS.goToCalendy && (
        <>
          <CallNowIcon />
          {/** @ts-ignore */}
          <PopupButton url={installer?.appointmentURL} text={t("CALL NOW")} />
        </>
      )}
    </S.Container>
  );
};

export default CallNow;
