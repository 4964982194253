import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Mixpanel, trackingEvents } from "src/mix-panel";
import { AUTH_MODES, AuthPopupProps } from "src/popups";
import { AppActions, PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { getUser } from "src/redux/user/selectors";
import { isNotOnDefaultProject, isOnSavedProject, userIsNotAuthenticated } from "src/utils";

const useGetSaveProjectFun = () => {
  const currentUser = useSelector(getUser);
  const dispatch = useDispatch();
  const [query] = useSearchParams();
  const isAdmin = query.get("admin");

  const saveProject = useCallback(() => {
    if (isAdmin) return;

    Mixpanel.track(trackingEvents.USER_CLICKS_ON_PROJECT_SAVE_BUTTON, {});

    if (userIsNotAuthenticated(currentUser)) {
      const popupData: AuthPopupProps = {
        authMode: AUTH_MODES.signup,
        afterSuccess: () => {
          dispatch(
            AppActions.updateAppState({
              saveProjectRemaining: true,
            }),
          );
        },
      };
      const action = PopupActions.openPopup(POPUP_KEYS.auth, popupData);
      dispatch(action);
      return;
    }

    if (isNotOnDefaultProject() && !isOnSavedProject()) {
      dispatch(PopupActions.openPopup(POPUP_KEYS.welcome_address));
      return;
    }

    dispatch(
      AppActions.updateAppState({
        saveProjectRemaining: true,
      }),
    );
  }, [currentUser, dispatch]);

  return { saveProject };
};

export default useGetSaveProjectFun;
