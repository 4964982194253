import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUTH_MODES, AuthPopupProps } from "src/popups";
import { StepperPresentational } from "src/shared/components";
import { isNotOnCustomProject, userIsNotAuthenticated } from "src/utils";

import { useStepsChangeMixPanel } from "./hooks";
import * as S from "./Stepper.styles";

import { AppActions, PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { AppSelectors, UserSelectors } from "src/redux/selectors";

export const stepperHeaders = ["System", "Components", "Images", "Realization"];

const ApplicationStepper: React.FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(UserSelectors.getUser);
  const currentStep = useSelector(AppSelectors.getCurrentStep);

  useStepsChangeMixPanel();

  const onStepClickHandler = useCallback(
    (index: number) => {
      const stepRequiresAuth = userIsNotAuthenticated(user) && isNotOnCustomProject();
      // if (stepRequiresAuth) {
      //   const popupData: AuthPopupProps = {
      //     authMode: AUTH_MODES.signup,
      //   };
      //   const action = PopupActions.openPopup(POPUP_KEYS.auth, popupData);
      //   dispatch(action);
      //   dispatch(AppActions.setCurrentStep(0));
      //   return;
      // }

      dispatch(AppActions.setCurrentStep(index));
    },
    [dispatch, user],
  );

  return (
    <S.Container>
      <StepperPresentational
        activeStep={currentStep}
        onStepClick={onStepClickHandler}
        labels={stepperHeaders}
      />
    </S.Container>
  );
};

export default ApplicationStepper;
