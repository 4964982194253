import { actionNames } from "..";
import { IWebinars } from "../reducer/types";

import { ReduxAction } from "src/redux/common.types";

export const setWebinars = (payload: IWebinars[] | []): ReduxAction<IWebinars[] | []> => ({
  type: actionNames.SET_WEBINARS,
  payload,
});

export const setWebinarDate = (payload: Date): ReduxAction<Date> => ({
  type: actionNames.SET_SELECTED_WEBINAR_DATE,
  payload,
});

export const setWebinarId = (payload: number): ReduxAction<number> => ({
  type: actionNames.SET_SELECTED_WEBINAR_ID,
  payload,
});

export const setSelectedWebinarIsHybrid = (payload: boolean): ReduxAction<boolean> => ({
  type: actionNames.SET_SELECTED_WEBINAR_IS_HYBRID,
  payload,
});
