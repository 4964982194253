import { Slider } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useNumberFormat } from "src/hooks/useNumberFormat";
import { MasterInput } from "src/shared/components";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import { HeatpumpConfiguration } from "src/redux/project";
import { ProjectSelectors } from "src/redux/selectors";

interface TotalAreaToBeHeatedLineProps {
  onValueChange: (heatpumpPartData: Partial<HeatpumpConfiguration>) => void;
}
const TotalAreaToBeHeatedLine: React.FC<TotalAreaToBeHeatedLineProps> = (
  props: TotalAreaToBeHeatedLineProps,
) => {
  const { onValueChange } = props;
  const { t } = useTranslation();
  const { formatNumber } = useNumberFormat();

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);

  const totalAreaHeatedSlider = useCallback(
    (value: any) => {
      onValueChange({
        totalAreaToBeHeated: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line hasSlider>
      <ParameterStyles.Label hasSlider>{t("TOTAL AREA TO BE HEATED")}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider
          value={configuration.totalAreaToBeHeated}
          onChange={totalAreaHeatedSlider}
          max={500}
        />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <MasterInput
          value={configuration.totalAreaToBeHeated}
          onChange={totalAreaHeatedSlider}
          label="m²"
          appearance={{
            color:
              (configuration.totalAreaToBeHeated * configuration.energyDemand !==
                configuration.consumptionHeatpump &&
                "#666666") ||
              undefined,
          }}
        />
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default TotalAreaToBeHeatedLine;
