import { clone, merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateHousehold = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateHousehold>,
): ProjectState => {
  const newState = clone(currentState);

  newState.household = merge({}, currentState.household, payload);
  return newState;
};
