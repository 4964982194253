import { makeStyles, createStyles } from "@mui/styles";

export const classes: any = makeStyles((theme: any) =>
  createStyles({
    bottomFixedIcons: {
      display: "flex",
      justifyContent: "center",
      gap: 25,
      position: "fixed",
      bottom: "30px",
      right: "30px",
      alignItems: "center",
      zIndex: 100,
      [theme.breakpoints.down("xs")]: {
        gap: "11px",
        flexDirection: "column",
        alignItems: "self-end",
        bottom: "81px",
      },
    },
    bottomFixedIconsForMobile: {
      position: "fixed",
      bottom: "15px",
      right: "30px",
      zIndex: 100,
    },
    askTheCommunity: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      width: "61px",
      overflow: "hidden",
      height: "54px",
      transition: "width 0.5s",
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "10px 15px",
      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#eee",
        cursor: "pointer",
        width: "fit-content",
      },
    },
    talkToOurExpert: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      width: "61px",
      overflow: "hidden",
      height: "54px",
      transition: "width 0.5s",
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "10px 15px",
      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#eee",
        cursor: "pointer",
      },
    },
    cometChat: {
      backgroundColor: "white",
      padding: "10px 15px",
      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "#eee",
        cursor: "pointer",
      },
    },
    textSize: {
      fontWeight: 300,
      fontSize: 20,
      paddingLeft: "10px",
      color: "#2D4764",
    },
  }),
);

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    bottomFixedIcons: {
      display: "flex",
      justifyContent: "center",
      gap: 25,
      position: "fixed",
      bottom: "30px",
      right: "30px",
      alignItems: "center",
      zIndex: 100,
      "@media (max-width: 560px)": {
        left: "22px",
        right: "inherit",
        bottom: "72px",
        flexDirection: "column",
      },
      [theme.breakpoints.down("xs")]: {
        gap: "11px",
        flexDirection: "column",
        alignItems: "self-end",
        bottom: "81px",
      },
    },
    bottomFixedIconsForMobile: {
      display: "none",
      "@media (max-width: 560px)": {
        // right: "39px",
        // left: "28px",
        position: "fixed",
        bottom: "40px",
        right: "30px",
        zIndex: 100,
        display: "block",
      },
    },
    askTheCommunity: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      width: "61px",
      overflow: "hidden",
      height: "54px",
      transition: "width 0.5s",
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "10px 15px",
      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#eee",
        cursor: "pointer",
        width: "391px",
      },
    },
    talkToOurExpert: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      width: "61px",
      overflow: "hidden",
      height: "54px",
      transition: "width 0.5s",
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "10px 15px",
      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#eee",
        cursor: "pointer",
      },
    },
    cometChat: {
      backgroundColor: "white",
      padding: "10px 15px",
      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "#eee",
        cursor: "pointer",
      },
    },
    textSize: {
      fontWeight: 300,
      fontSize: 20,
      paddingLeft: "10px",
      color: "#2D4764",
    },
  }),
);

export const styles = makeStyles((theme) =>
  createStyles({
    talkToOurExpert: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      width: "61px",
      height: "54px",
      transition: "width 0.5s",
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "10px 15px",
      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#eee",
        cursor: "pointer",
        width: "391px",
      },
    },
    textSize: {
      fontWeight: 300,
      fontSize: 20,
      paddingLeft: "10px",
      color: "#2D4764",
      lineHeight: "100%",
      overflow: "hidden",
      textOverflow: "clip",
      whiteSpace: "nowrap",
    },
  }),
);

export const cometChatstyle = (showChat: any): any => {
  const opacity = showChat ? { opacity: 1 } : { opacity: 0 };
  const pointerEvent = showChat ? {} : { pointerEvents: "none" };

  return {
    position: "fixed",
    right: 17,
    bottom: 100,
    zIndex: 100,
    transition: "all ease 0.5",
    ...opacity,
    ...pointerEvent,
  };
};
