import { isEqual } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { BackendApis } from "src/api";

import { convertProjectToSaveRequest } from "src/db-converters";
import { ProjectSaveRequestDB } from "src/db-types/project";
import { isNotOnSavedProject } from "src/utils";

import { useUpdateProjectApi } from "./apis";

import { AppActions } from "src/redux";
import { ProjectSelectors, UserSelectors } from "src/redux/selectors";

import { useAppSelector } from ".";

export const useUpdateProject = (): void => {
  const dispatch = useDispatch();

  const currentUser = useAppSelector(UserSelectors.getUser);
  const project = useAppSelector(ProjectSelectors.getProject);

  const previousProjectDBState = useRef<ProjectSaveRequestDB | null>(null);
  const timeoutId = useRef<any>(null);

  const { error, fetchData, loading } = useUpdateProjectApi();

  useEffect(() => {
    dispatch(
      AppActions.updateAppState({
        isSavedProjectRequestLoading: loading,
      }),
    );
  }, [dispatch, loading]);

  useEffect(() => {
    // TODO: refetch the project
  }, [dispatch, error]);

  useEffect(() => {
    if (!currentUser) return;
    if (isNotOnSavedProject()) return;
    if (project.id === "project123") return;

    const projectDB = convertProjectToSaveRequest(project);
    const previousDB = previousProjectDBState.current;

    if (isEqual(projectDB, previousDB)) {
      return;
    }

    if (timeoutId.current) clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      console.log({ ss: projectDB.accessCount });
      void fetchData({
        ...BackendApis.updateProject(project.id),
        data: { ...projectDB, accessCount: projectDB.accessCount + 1 },
      });
      previousProjectDBState.current = projectDB;
    }, 1000);
  }, [currentUser, dispatch, fetchData, project]);
};
