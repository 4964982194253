import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { ProjectSelectors } from "src/redux/selectors";

const HomeType: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectType = useSelector(ProjectSelectors.getProjectType);
  const { buildingType } = useSelector(ProjectSelectors.getHousehold);

  const openSelectBuildingTypeDialog = useCallback(() => {
    const action = PopupActions.openPopup(POPUP_KEYS.building_type);
    dispatch(action);
  }, [dispatch]);

  const fieldName = useMemo(() => {
    if (projectType === "b2c") return "Home type";
    return "Business type";
  }, [projectType]);

  const fieldValue = useMemo(() => {
    if (projectType === "b2c") {
      if (buildingType === "single") return "Single-family-building";
      else return "Multi-family-building";
    } else {
      return buildingType;
    }
  }, [buildingType, projectType]);

  return (
    <div className="same_part">
      <div className="text_same">{t(fieldName)}</div>
      <div className="round_button" onClick={openSelectBuildingTypeDialog}>
        {t(fieldValue)}
      </div>
    </div>
  );
};

export default HomeType;
