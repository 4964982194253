import styled from "styled-components/macro";
interface mainContianer {
  isChangeEv?: boolean;
}
export const MainContainer = styled.div<mainContianer>`
  min-height: 209px;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
  position: ${({ isChangeEv }) => {
    if (isChangeEv) {
      return "";
    } else {
      return "";
    }
  }};
  background: #fafafa;
  border-radius: 12px;
  z-index: 2;
  margin-bottom: 6px;

  width: calc(100% + 20px);
  margin-left: -17px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 15px;
  .line {
    border-bottom: 1px solid #dfdfdf;
    width: 100%;
    height: 7px;
    margin-bottom: 10px;
  }
  .heatpump-cost {
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    padding-left: 5px;
    margin-inline: auto;
    margin-bottom: 0.5rem;
    p {
      font-weight: 600;
      font-size: 17px;
      line-height: 18px;
      color: #2d4764;
    }
  }

  @media (max-width: 500px) {
    margin-left: unset;
    width: 100%;
  }
`;
export const SubContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 106px;
  .evKm {
    margin-bottom: 2px;
  }
  .same_part {
    display: flex;
    width: 99%;
    padding-bottom: 5px;
    justify-content: space-between;
    align-items: center;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #2d4764;
  }
  .icon {
    cursor: pointer;
    margin-right: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 1rem;
    div {
      background: #2d4764dd;
      width: 1rem;
      height: 3px;
      border-radius: 3px;
    }
  }
  .text_first {
    font-size: 16px;
  }
`;
export const SubContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;
export const Percentage = styled.div`
  position: absolute;
  right: 13px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
`;
export const SubContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  margin-inline: auto;
  margin-bottom: 0.5rem;
  padding-top: 12px;
  padding-bottom: 8px;
`;
export const SubContainer3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-inline: 10px;
`;
export const SubContainerText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
  min-width: 160px;
`;
export const SubContainerValue = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #304864;
  min-width: 68px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
export const SubContainerText1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #2d4764;
  cursor: pointer;
`;
