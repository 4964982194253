import styled from "styled-components/macro";

export const ApplicationBodyContainer = styled.div`
  width: 100%;
  overflow: overlay;
  position: relative;
  background-color: var(--light-blue-background);

  .project-saving-indicator {
    position: fixed;
    top: 100px;
    left: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    color: var(--blue);
    transition: all 500ms;
  }

  .referral_text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: var(--fs-xl);
    color: var(--blue);
    padding-top: 31px;
  }
`;

export const HeaderWrapper = styled.div`
  max-width: var(--container-xxl);
  width: 93%;
  padding-top: 15px;
  margin: auto;
  position: relative;
  @media (min-width: 1000px) {
    width: 98%;
  }
`;

export const StepsWrapper = styled.div`
  max-width: min(97%, var(--container-xxl));
  margin: auto;
  margin-bottom: 3rem;

  @media only screen and (min-width: 500px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
