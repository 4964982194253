import { clone } from "lodash";

import { actionCreators, PopupState } from "src/redux/popups";

export const setPopup = (
  currentState: PopupState,
  { payload }: ReturnType<typeof actionCreators.openPopup>,
): PopupState => {
  const { data, open, popupKey } = payload;
  const newState = clone(currentState);

  newState[popupKey].open = open;
  if (data) newState[popupKey].data = data;

  return newState;
};
