import { useEffect } from "react";

import { AppActions, ProjectActions } from "src/redux/actionCreators";
import { AppSelectors, ProjectSelectors } from "src/redux/selectors";

import { useAppDispatch, useAppSelector } from ".";

export const useProfileChange = (): void => {
  const dispatch = useAppDispatch();

  const profile = useAppSelector(ProjectSelectors.getProfileName);
  const quote = useAppSelector(ProjectSelectors.getQuote);
  const {
    actions: { updateSolarQtyByProfile },
  } = useAppSelector(AppSelectors.getState);

  useEffect(() => {
    if (!updateSolarQtyByProfile) return;

    const qty = quote.profiles[profile].moduleQuantity;
    dispatch(
      ProjectActions.updateSolarPanel({
        quantity: qty,
      }),
    );

    dispatch(
      AppActions.updateAppState({
        actions: {
          updateSolarQtyByProfile: false,
        },
      }),
    );
  }, [dispatch, profile, quote.profiles, updateSolarQtyByProfile]);
};
