import moment from "moment";

import store from "src/redux/store";

export const generalFormat = (date: Date | undefined): string => {
  if (!date) return "";
  if (date == null) return "";

  const lang = store.getState().user.language;

  if (lang === "en") return moment(date).format("dddd, MMMM Do YYYY, h:mm a");
  else if (lang === "de")
    return date.toLocaleString("de-AT", {
      dateStyle: "full",
      timeStyle: "short",
      hourCycle: "h24",
    });
  else if (lang === "es")
    return date.toLocaleString("de-AT", {
      dateStyle: "full",
      timeStyle: "short",
      hourCycle: "h24",
    });
  return "";
};

export const timeBreaker = (totalSeconds: any): any => {
  const seconds = totalSeconds % 60;
  const minutesWM = (totalSeconds - seconds) / 60;
  const minutes = minutesWM % 60;
  const hoursWM = (minutesWM - minutes) / 60;
  const hours = hoursWM % 24;
  const days = (hoursWM - hours) / 24;

  return { seconds, minutes, hours, days };
};
