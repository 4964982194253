import { clone } from "lodash";

import { UserState, actionCreators } from "src/redux/user";

export const setLanguage = (
  currentState: UserState,
  { payload }: ReturnType<typeof actionCreators.setLanguage>,
): UserState => {
  const newState = clone(currentState);

  newState.language = payload;
  return newState;
};
