import { CardElement, useElements, IbanElement, useStripe } from "@stripe/react-stripe-js";
import { Switch } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import PaymentWithCard from "src/shared/components/paymentWithCard/PaymentWithCard";

import IbanForm from "../../../shared/components/IbanForm/IbanForm";
import { IdetailsPlanData } from "../cardsData";

import { PaymenWrapper } from "./PaymentFourmStyles";

import { getProject } from "src/redux/project/selectors";
import { getUser } from "src/redux/user/selectors";
import { ApiAxiosClient } from "src/axios";

interface PaymentFourms {
  planPaymentValue: string;
}
const PaymentFourm: React.FC<PaymentFourms> = ({ planPaymentValue }) => {
  const stripe: any = useStripe();
  const navigate = useNavigate();
  const elements = useElements();
  const currentUser = useSelector(getUser);
  const project = useSelector(getProject);
  // components states
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [succeeded] = useState(false);
  const [processing, setProcessing] = useState<Boolean>(false);
  const [error, setError] = useState(null);
  const [isPaymentWithBankAcc, setIsPaymentWithBankAcc] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientsecret] = useState(true);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("Germany");
  const [zipCode, setZipCode] = useState("");
  const { t } = useTranslation();

  // calling uesEffect for getting client intent for our backend server
  useEffect(() => {
    if (isPaymentWithBankAcc) {
      const getClientSecret = async (): Promise<void> => {
        const needThings = {
          name: currentUser?.displayName,
          address: {
            line1: project.quote.mapboxAddress.complete,
            postal_code: project.quote.mapboxAddress.complete,
            city: project.quote.mapboxAddress.city,
            state: project.quote.mapboxAddress.state,
            country: project.quote.mapboxAddress.country,
          },
        };
        const response = await ApiAxiosClient.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/payments/payment-with-bank-acc?total=${
            planPaymentValue.split(" ")[0]
          }&pid=${project?.shortId}`,
          {
            data: needThings,
          },
        );
        setClientsecret(response.data.clientSecret);
      };
      void getClientSecret();
    } else {
      const getClientSecret1 = async (): Promise<void> => {
        const needThings = {
          name: currentUser?.displayName,
          address: {
            line1: project.quote.mapboxAddress.complete,
            postal_code: project.quote.mapboxAddress.complete,
            city: project.quote.mapboxAddress.city,
            state: project.quote.mapboxAddress.state,
            country: project.quote.mapboxAddress.country,
          },
          id: currentUser?.id,
          description: project.id,
          email: currentUser?.email,
        };
        const response = await ApiAxiosClient.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/payments/payment-with-card?total=${
            planPaymentValue.split(" ")[0]
          }`,
          {
            data: needThings,
          },
        );
        setClientsecret(response.data.clientSecret);
      };
      void getClientSecret1();
    }
  }, [
    currentUser?.displayName,
    currentUser?.email,
    currentUser?.id,
    isPaymentWithBankAcc,
    planPaymentValue,
    project.id,
    project.quote.mapboxAddress.city,
    project.quote.mapboxAddress.complete,
    project.quote.mapboxAddress.country,
    project.quote.mapboxAddress.state,
  ]);

  // calling useEffect for checking is applepay,google pay or microsofe age pay is available or not
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Demo total",
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result: any) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  // submit hangler for card payments
  const submitHandler = async (event: any): Promise<void> => {
    event.preventDefault();
    setProcessing(true);

    await stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          // @ts-expect-error
          card: elements.getElement(CardElement),
        },
      })
      .then((res: any) => {
        alert("Payment done!" + JSON.stringify(res));
        navigate(-1);
      });
  };

  const onChange = (checked: boolean): void => {
    setIsPaymentWithBankAcc(!checked);
  };

  const handleSubmit = async (event: any): Promise<void> => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const iban = elements.getElement(IbanElement);
    const accountholderName = event.target["accountholder-name"];
    const email = event.target.email;

    const result = await stripe.confirmSepaDebitPayment(clientSecret, {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
          name: accountholderName.value,
          email: email.value,
        },
      },
    });
    if (result.error) {
      toast.error(result.error.message);
    } else {
      const { paymentIntent } = result;
      if (paymentIntent.status === "processing") {
        toast.success(
          t(
            "Your payment is processing. You can check payment status after some time in your dashboard profile",
          ),
        );
      }
    }
  };

  return (
    <PaymenWrapper>
      <div className="PaymentFourmMain">
        <div className="makeCenter">
          {isPaymentWithBankAcc
            ? "----- or Pay with BankAccount -----"
            : "----- or Pay with Card -----"}
          <Switch
            style={{
              background: isPaymentWithBankAcc ? "#FFD75D" : "#1B4963",
              marginLeft: "20px",
            }}
            // @ts-expect-error
            value={isPaymentWithBankAcc}
            onChange={onChange}
          />
        </div>
        {isPaymentWithBankAcc ? (
          <PaymentWithCard
            setDisabled={setDisabled}
            setError={setError}
            setIsPaymentWithBankAcc={setIsPaymentWithBankAcc}
            isPaymentWithBankAcc={isPaymentWithBankAcc}
            paymentRequest={paymentRequest}
            submitHandler={submitHandler}
            email={email}
            setEmail={setEmail}
            setName={setName}
            country={country}
            setCountry={setCountry}
            name={name}
            zipCode={zipCode}
            setZipCode={setZipCode}
            processing={processing}
            error={error}
            disabled={disabled}
            succeeded={succeeded}
          />
        ) : (
          <IbanForm
            onSubmit={handleSubmit}
            disabled={!stripe}
            price={planPaymentValue.split(" ")[0]}
          />
        )}
      </div>
    </PaymenWrapper>
  );
};

export default PaymentFourm;
