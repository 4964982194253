import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { BackendApis } from "src/api";

import { PartnerCityDB } from "src/db-types/partnerCity";

import { usePartnerCityAPI } from "./apis";

import { AppActions } from "src/redux";
import { getQuote } from "src/redux/project/selectors";

import { useAppDispatch } from ".";

export const useGetPartnerCityDetails = (): void => {
  const dispatch = useAppDispatch();
  const quote = useSelector(getQuote);
  const [allData, setAllData] = useState<PartnerCityDB | null>(null);

  const { fetchData, error, response } = usePartnerCityAPI();

  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);

  useEffect(() => {
    if (!response) return;

    setAllData(response.data.data);
  }, [response]);

  useEffect(() => {
    if (quote.Gemeindeschluessel8 ?? quote.mapboxAddress.city) {
      void fetchData({
        ...BackendApis.getPartnerCity({ id: quote.Gemeindeschluessel8 }),
        data: { city: quote.mapboxAddress.city }
      });
    }
  }, [fetchData, quote]);

  useEffect(() => {
    if (allData) {
      dispatch(
        AppActions.updatePartnerCityData({
          consultingStatus: allData?.consultingStatus,
          freeConsultings: allData?.freeConsultings,
          Gemeindeschluessel8: allData?.Gemeindeschluessel8,
          partnerStatus: allData?.partnerStatus,
          usedYearlyConsultings: allData?.usedYearlyConsultings,
          yearlyConsultings: allData?.yearlyConsultings,
        }),
      );
      dispatch(
        AppActions.updateAppState({
          actions: {
            updateDeal: true,
          },
        }),
      );
    }
  }, [allData, dispatch]);
};
