import {
  ChartData,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  registerables as registerablesJS,
  Tooltip,
} from "chart.js";
import html2canvas from "html2canvas";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { screenshotPlugin } from "src/shared/components/DonoughtChart/chart-plugins/screenshotPlugin";
import { createGradient } from "src/utils";

import { getLables, getMonthlyEnergyDetails } from "../MonthlyPersentage";

ChartJS.register(...registerablesJS);
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: window.innerWidth < 500 ? 1 / 1 : 2 / 1,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: any, index: any, values: any) {
          return `${value as number} kWh`;
        },
      },
      grid: {
        borderDash: [8, 4],
        display: true,
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
      },
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

interface MonthlyBalanceChartProps {
  setScreenshot?: (image: string) => void;
}

export const MonthlyBalanceChart: React.FC<MonthlyBalanceChartProps> = ({ setScreenshot }) => {
  const { t } = useTranslation();
  const screenShotRef = useRef(null);

  const captureScreenshot = useCallback(async () => {
    const chart = screenShotRef.current;
    if (!chart) {
      return;
    }

    const canvas = await html2canvas(chart, { useCORS: true });
    const image = canvas.toDataURL("image/png");

    if (typeof setScreenshot === "function") {
      setScreenshot(image);
    }
  }, [setScreenshot]);

  const data = useMemo(() => {
    const monthlyEnergies = getMonthlyEnergyDetails();
    return {
      labels: getLables(),
      datasets: [
        {
          label: t("Grid Supply"),
          data: monthlyEnergies.map((m) => m.gridSupply),
          fill: "start",
          lineTension: 0.5,
          type: "line" as const,
          start: "#853e40",
          end: "rgba(129, 51, 41, 0.24)",
          borderColor: "#853e40",
        },
        {
          label: t("Feed-in-gains"),
          data: monthlyEnergies.map((m) => m.feedIn),
          fill: "start",
          lineTension: 0.5,
          type: "line" as const,
          start: "#4D7E6D",
          end: "rgba(77, 126, 109, 0.24)",
          borderColor: "rgba(77, 126, 109, 1)",
        },
        {
          label: t("PV production"),
          data: monthlyEnergies.map((m) => m.pvProduction),
          fill: "start",
          lineTension: 0.5,
          type: "line" as const,
          start: "#FFD75D",
          end: "rgba(255, 215, 93, 0.18)",
          borderColor: "#FFD75D",
        },
        {
          label: t("Total consumption"),
          data: monthlyEnergies.map((m) => m.consumption),
          fill: "start",
          lineTension: 0.5,
          type: "line" as const,
          start: "#DF6569",
          end: " rgba(203, 0, 0, 0.4)",
          borderColor: "#DF6569",
        },
      ],
    };
  }, [t]);
  console.log({chartData: data})

  const chartRef = useRef<ChartJS>(null);
  const [chartData, setChartData] = useState<ChartData<"line">>({
    datasets: [],
  });

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        borderColor: dataset.borderColor,
        backgroundColor: createGradient(chart.ctx, chart.chartArea, dataset.start, dataset.end),
      })),
    };

    setChartData(chartData);
  }, [data]);

  return (
    <div ref={screenShotRef} style={{ width: setScreenshot ? 800 : "auto" }}>
      <Chart
        ref={chartRef}
        options={{
          ...options,
          plugins: {
            ...options.plugins,
            // @ts-expect-error
            screenshot_plugin: {
              screenshotCallback: () => {
                void captureScreenshot();
              },
            },
          },
        }}
        plugins={[screenshotPlugin]}
        type="line"
        data={chartData}
      />
    </div>
  );
};
