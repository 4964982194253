import { WebinarDB } from "src/db-types";

import { RootState } from "../store";

export const getWebinars = (state: RootState): WebinarDB[] | [] => state.webinar.webinars;
export const getSelectedWebinarId = (state: RootState): number | null =>
  state.webinar.selectedWebinarId;
export const getSelectedWebinarDate = (state: RootState): Date | null =>
  state.webinar.selectedWebinarDateTime;
export const getSelectedWebinarIdHybrid = (state: RootState): boolean =>
  state.webinar.selectedWebinarIsHybrid;
