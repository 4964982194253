/**
 * consumption dataset based on business_type and
 * each type having default, employees & kwa_per_employees
 */
export const buildingConsumptionsB2B = {
  Office: {
    default: 1000,
    no_of_employees: 10,
    kwa_per_employee: 100,
  },
  Manufacturer: {
    default: 2000,
    no_of_employees: 50,
    kwa_per_employee: 150,
  },
  Service: {
    default: 1000,
    no_of_employees: 10,
    kwa_per_employee: 100,
  },
  Restaurant: {
    default: 2000,
    no_of_employees: 50,
    kwa_per_employee: 150,
  },
  Administration: {
    default: 1000,
    no_of_employees: 10,
    kwa_per_employee: 100,
  },
  Agriculture: {
    default: 2000,
    no_of_employees: 50,
    kwa_per_employee: 150,
  },
  School: {
    default: 1000,
    no_of_employees: 10,
    kwa_per_employee: 100,
  },
  Shopping: {
    default: 2000,
    no_of_employees: 50,
    kwa_per_employee: 150,
  },
  Hotel: {
    default: 1000,
    no_of_employees: 10,
    kwa_per_employee: 100,
  },
  Other: {
    default: 2000,
    no_of_employees: 50,
    kwa_per_employee: 150,
  },
};
