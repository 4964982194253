import { Slider } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useAppDispatch } from "src/hooks";

import { MasterInput } from "src/shared/components";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";

const AmountOfPersonLine: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { people } = useSelector(ProjectSelectors.getHousehold);
  const projectType = useSelector(ProjectSelectors.getProjectType);

  const handleInputChange = useCallback(
    (value: number): void => {
      dispatch(
        ProjectActions.updateHousehold({
          people: value,
        }),
      );
    },
    [dispatch],
  );

  const maxPeople = projectType === "b2c" ? 10 : 500;

  return (
    <ParameterStyles.Line
      hasSlider
      style={{ paddingInline: "10px", width: "93%" }}
    >
      <ParameterStyles.Label style={{ color: "black" }}>
        {projectType === "b2c" && t("Amount of persons")}
        {projectType === "b2b" && t("Number of employees")}
      </ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider onChange={handleInputChange} max={maxPeople} min={1} value={people} />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <ParameterStyles.ValueWrapper style={{ marginRight: "0px" }}>
          <MasterInput
            value={people}
            maxNumber={maxPeople}
            roundTill={0}
            onChange={handleInputChange}
          />
        </ParameterStyles.ValueWrapper>
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default AmountOfPersonLine;
