import { useCallback } from "react";
import { useSelector } from "react-redux";

import store from "src/redux/store";
import { selectors as userSelectors, LanguagesShortCodes } from "src/redux/user";

const isNumberNotConvertible = (ns: string): boolean => {
  const containsOtherThanDigitsAndDot = ns.match(/[-\d.]/g)?.length !== ns.length;
  const moreThanOneDot = (ns.match(/[.]/g)?.length ?? 0) > 1;
  const isNumberNotConvertible = containsOtherThanDigitsAndDot || moreThanOneDot;

  return isNumberNotConvertible;
};

const separateWholeAndFractionalPart = (ns: string): [string, string] => {
  const decimalPointInd = ns.indexOf(".");
  const noDecimalPoints = decimalPointInd === -1;

  const fractionalPart = noDecimalPoints ? "" : "." + ns.substring(decimalPointInd + 1);
  const leftPart = noDecimalPoints ? ns : ns.substring(0, decimalPointInd);

  return [leftPart, fractionalPart];
};

const insertThousandsSeparator = (ns: string): string => {
  let formatted = "";
  let c = 0;
  const isNegative = ns[0] === "-";
  const stopIndex = isNegative ? 1 : 0;
  for (let i = ns.length - 1; i >= stopIndex; i--) {
    formatted += ns[i];
    c++;
    const shouldAddSeparater = c === 3 && i > stopIndex;
    if (shouldAddSeparater) {
      formatted += ",";
      c = 0;
    }
  }

  formatted = formatted.split("").reverse().join("");

  if (stopIndex) formatted = ns[0] + formatted;
  return formatted;
};

const formatByAmericanStyle = (n: string | number | null | undefined): string => {
  const ns = String(n);
  const [leftPart, decimalPart] = separateWholeAndFractionalPart(ns);
  const leftPartWithSeparator = insertThousandsSeparator(leftPart);
  const formattedNumber = leftPartWithSeparator + decimalPart;
  return formattedNumber;
};

const replaceCommaAndDot = (ns: string): string => {
  ns = ns.replace(/[.]/g, "*");
  ns = ns.replace(/[,]/g, ".");
  ns = ns.replace(/[*]/g, ",");

  return ns;
};

export const formatNumberByLang = (num: string, lang?: LanguagesShortCodes): string => {
  if (!lang) lang = store.getState().user.language;
  num = num.replace(/[,]/g, "");

  if (isNumberNotConvertible(String(num))) return "";

  let formatted = formatByAmericanStyle(num);
  if (formatted === "") return "";

  if (lang === "de" || lang === "es") {
    formatted = replaceCommaAndDot(formatted);
  }

  return formatted;
};

interface NumberFormatOptions {
  shouldApplyRound?: boolean;
  roundTill?: number;
}

const defaultFormattingOptions: NumberFormatOptions = {
  shouldApplyRound: true,
  roundTill: 0,
};

type FormattableNumber = undefined | null | number | string;
type FormatNumberReturnType = (num: FormattableNumber, options?: NumberFormatOptions) => string;
export function useNumberFormat(): { formatNumber: FormatNumberReturnType } {
  const lang = useSelector(userSelectors.getLangCode);

  const formatNumber: FormatNumberReturnType = useCallback(
    (num: FormattableNumber, options: NumberFormatOptions = defaultFormattingOptions): string => {
      options = {
        ...defaultFormattingOptions,
        ...options,
      };
      if (options.shouldApplyRound) {
        num = parseFloat(String(num));
        if (!(options.roundTill === Infinity)) {
          num = num.toFixed(options.roundTill);

          const decimalPart = num.split(".")[1];
          if (parseInt(decimalPart) === 0) num = num.split(".")[0];
        }
      }

      return formatNumberByLang(String(num), lang);
    },
    [lang],
  );

  return { formatNumber };
}
