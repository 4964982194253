export const consumptionProfilesB2B = {
  1: {
    name: "Only during working hours 80% self supply",
    self_supply: 0.8,
    short_title: "Only during working hours",
  },
  2: {
    name: "Consumption in the morning and in the evening 40% self supply",
    self_supply: 0.4,
    short_title: "Morning and in the evening",
  },
  3: {
    name: "All day continuous 80% self supply",
    self_supply: 0.8,
    short_title: "All day continuous",
  },
  4: {
    name: "High consumption at night 70% self supply",
    self_supply: 0.7,
    short_title: "High consumption at night",
  },
  5: {
    name: "Only during night times 20% self supply",
    self_supply: 0.2,
    short_title: "Only during night",
  },
  6: {
    name: "Low electricity usage 10% self supply",
    self_supply: 0.1,
    short_title: "Low energy consumption",
  },
};
