import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 9px rgba(162, 162, 162, 0.25);
  border-radius: 22px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;

  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
`;

interface HeadingProps {
  border: boolean;
  rotate: String;
}
export const Heading = styled.h2<HeadingProps>`
  padding: 30px 50px 20px;
  width: 100%;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #2d4764;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: ${(props: any) => (props.border ? "1px solid #E2E2E2" : "0")};
  position: relative;
  img {
    transform: rotate(${(props: any) => props.rotate});
  }
  @media only screen and (max-width: 500px) {
    padding: 25px;
  }
`;
export const MainHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
export const Form = styled.form`
  position: relative;
  margin-top: 2rem;
  img {
    position: absolute;
    left: 18px;
    top: 14px;
    transform: rotate(370deg);
  }
`;
export const Input = styled.input`
  background: #f9f9f9;
  border-radius: 59px;
  font-family: "Nunito";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 14px 120px 14px 30px;
  width: auto;

  &:placeholder {
    color: #c0c0c0;
  }
  @media only screen and (max-width: 500px) {
    width: 270px;
    padding: 14px 0 14px 50px;
  }
`;
export const Filters = styled.div`
  display: flex;
  margin: 40px 0 10px;
  flex-direction: row;
  & > div {
    display: flex;
    align-items: center;
    margin: 7px 0;
  }
  label {
    font-family: "Nunito";
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #2d4764;
    margin-left: 15px;
    margin-right: 30px;
    cursor: pointer;
  }
  input {
    outline: 2px solid #2d4764;
    border: 0;
    border-radius: 2px;
    height: 17px;
    width: 17px;
    cursor: pointer;
  }
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    margin: 90px 0 10px 10px;
    padding: 0;
  }
`;
export const InstallerCards = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
  padding: 30px 0;
  @media only screen and (max-width: 500px) {
    grid-template-columns: auto;
    gap: 10px;
    width: 85%;
  }
`;
export const ViewMore = styled.button`
  text-align: center;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: #2d4764;
  border: 1px solid #2d4764;
  border-radius: 10px;
  padding: 0.5rem;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
  span {
    margin-left: 10px;
    font-size: 1rem;
  }
`;

export const ViewMore2 = styled.div`
  text-align: center;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: #2d4764;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: 10px;
    font-size: 1rem;
  }
  padding: 13px;
`;

