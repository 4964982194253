import { clone, merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateBakeModelIds = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateBakeModelId>,
): ProjectState => {
  const newState = clone(currentState);

  newState.bakeModel = {
    artifacts: payload.artifacts,
    id: payload.id || ""
  };
  return newState;
};
