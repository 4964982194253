import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";

import PVSol from "src/assets/PVSol.png";
import Solaredge from "src/assets/Solaredge.png";
import Sunnydesign from "src/assets/Sunnydesign.png";
import { getFormattedCustomDate } from "src/pdf/Utils/helperMethods";
import { Bar } from "src/shared/components/HorizontalBarCardChart/HorizontalBarCardChart.styles";

import * as R from "./InformationPart.styles";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";
import ThreeDModelInfo from "../3DModalInfo/ThreeDModelInfo";

const InformationPart = () => {
  const dispatch = useDispatch();
  const ThreeDModels = useSelector(ProjectSelectors.getDroneData);
  const address = useSelector(ProjectSelectors.getAddress);
  const quote = useSelector(ProjectSelectors.getQuote);
  const date = getFormattedCustomDate(quote.createdAt);

  const droneDataChangeHandler: any = (value: string, name: string): any => {
    dispatch(
      ProjectActions.updateDroneData({
        [name]: value,
      }),
    );
  };

  return (
    <R.Container>
      <R.Heading>Details</R.Heading>
      <Bar
        bgColor={"#FFD75D"}
        topColor={"#FFD75D"}
        topPercent={20}
        style={{ width: "50%", height: "10px" }}
      >
        <div className="top-bar"></div>
      </Bar>
      <R.ButtonObj>{t("OBJ created")}</R.ButtonObj>
      <R.Address>
        <div className="address">{address.complete}</div>
        <div className="head">
          {t("Project started on:")}
          <span className="date">{date}</span>
        </div>
      </R.Address>
      <ThreeDModelInfo 
      ThreeDModels={ThreeDModels}
      droneDataChangeHandler={droneDataChangeHandler}
      Sunnydesign={Sunnydesign}
      Solaredge={Solaredge}
      PVSol={PVSol}
       />
    </R.Container>
  );
};

export default InformationPart;
