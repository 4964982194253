import React, { useCallback } from "react";
import { MultiTenantContainer, TenantCards } from "../MultiTenantStyles";
import TenantCard from "./tenantCard/tenantCard";

import fullPower from "src/assets/popups/fullPower.svg";
import singleCounter from "src/assets/popups/singleCounter.svg";
import fullFeed from "src/assets/popups/fullfeed.svg";
import { InfoIcon } from "src/shared/components";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "src/hooks";
import { ProjectActions } from "src/redux/actionCreators";
import { useSelector } from "react-redux";
import { ProjectSelectors } from "src/redux/selectors";

export const tenantCards = [
  {
    id: 1,
    title: "Full power delivery (tenant electricity concept)",
    text: "Everyone can benefit from the affordable solar power.",
    image: fullPower,
  },
  {
    id: 2,
    title: "Single-counter-model",
    text: "There is only one official main meter. This saves annual basic fees.",
    image: singleCounter,
  },
  {
    id: 3,
    title: "Full feed-in",
    text: "The PV electricity is fully fed into the public grid.",
    image: fullFeed,
  },
];

interface IOperationConcept {
  setactiveStep: (tab: number) => void;
}

const OperationConcept = ({ setactiveStep }: IOperationConcept) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { operationConcept } = useSelector(ProjectSelectors.getHousehold);

  const handleConceptSelect = useCallback(
    (value: string): void => {
      dispatch(
        ProjectActions.updateHousehold({
          operationConcept: value,
        }),
      );
    },
    [dispatch],
  );


  return (
    <div>
      <MultiTenantContainer>
        <div className="headers">
          <div className="make_flex">
            <div className="head">{t("Select your operation concept")}</div> <InfoIcon />
          </div>
          <div className="paragraph">
            {t(
              "For the implementation of a tenant electricity concept, there are various operational concepts - Choose your favorite",
            )}
          </div>
        </div>
        <TenantCards>
          {tenantCards.map((card) => {
            return (
              <TenantCard
                title={t(card.title)}
                text={t(card.text)}
                image={card.image}
                count={card.id}
                handleConceptSelect={handleConceptSelect}
                setactiveStep={setactiveStep}
                isActive={operationConcept === card.title}
              />
            );
          })}
        </TenantCards>
      </MultiTenantContainer>
    </div>
  );
};

export default OperationConcept;
