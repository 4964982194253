import { PrimaryButton } from "src/shared/StyledComponents";
import * as S from "./OfferCheckoutStyled";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useNaivgateAbsolute } from "src/hooks";
import { openPopup } from "src/redux/popups/action/action.creators";
import { POPUP_KEYS } from "src/redux/popups";
import { useSelector } from "react-redux";
import { PopupSelectors } from "src/redux/selectors";
import OfferReject from "src/popups/offerReject/offerReject";
import { useUpdateInstallerAPI } from "src/hooks/apis";

interface IOfferCheckout {
  finalPrice: number;
  discount: number;
  handleAcceptOffer: ({ id }: { id: string }) => void;
  handleRejectOffer: ({ id, reason }: { id: string; reason: string }) => void;
  id: string;
}

const OfferCheckout = ({
  finalPrice,
  discount,
  handleAcceptOffer,
  handleRejectOffer,
  id,
}: IOfferCheckout) => {
  const { t } = useTranslation();
  const navigate = useNaivgateAbsolute();
  const dispatch = useAppDispatch();
  const popups = useSelector(PopupSelectors.getPopup);

  const handleOpenFeedbackForm = () => {
    dispatch(openPopup(POPUP_KEYS.offer_feedback));
  };

  return (
    <S.Card>
      <S.PriceDetails>
        <div>Installer price offer</div>
        <S.Price>{finalPrice.toFixed()} €</S.Price>
        <div>inkl. MwSt.</div>
      </S.PriceDetails>
      <S.CheckoutDetails>
        <div>You will save {discount.toFixed()} EUR!</div>
        <S.ButtonsSection>
          <PrimaryButton
            style={{
              width: "129px",
            }}
            onClick={() => navigate("/payment")}
          >
            {t("Checkout")}
          </PrimaryButton>
          <PrimaryButton
            style={{
              width: "129px",
            }}
            onClick={() => handleAcceptOffer({ id })}
          >
            {t("Accepct Offer")}
          </PrimaryButton>

          <PrimaryButton
            style={{
              width: "129px",
            }}
            onClick={handleOpenFeedbackForm}
          >
            {t("Reject Offer")}
          </PrimaryButton>
        </S.ButtonsSection>
      </S.CheckoutDetails>
      {popups.OFFER_FEEDBACK.open && <OfferReject handleRejectOffer={handleRejectOffer} id={id} />}
    </S.Card>
  );
};

export default OfferCheckout;
