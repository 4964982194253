import React from "react";

interface ChartCircleProps {
  color: string;
  size?: number;
}

const ChartCircle: React.FC<ChartCircleProps> = (props: ChartCircleProps) => {
  const { color, size } = props;

  return (
    <div
      style={{
        height: size,
        width: size,
        borderRadius: "50%",
        backgroundColor: color,
      }}
    ></div>
  );
};

ChartCircle.defaultProps = {
  size: 16,
};

export default ChartCircle;
