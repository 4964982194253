import { Select } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DownArrow } from "src/assets/svgs";
import { heatpumpBuildingtypes } from "src/data";

import * as S from "../../HeatpumpConfigurationBox.styles";

import { HeatpumpConfiguration } from "src/redux/project";
import { ProjectSelectors } from "src/redux/selectors";

const { Option } = Select;

const selectStyle: React.CSSProperties = {
  maxWidth: "100%",
  paddingTop: "5px",
  paddingBottom: "5px",
  height: "auto",
  wordWrap: "break-word",
};

const dropDownStyle: React.CSSProperties = {
  minWidth: "300px",
  right: "30px",
  whiteSpace: "break-spaces",
  wordBreak: "break-word",
};

interface HeatDistributionLineProps {
  onValueChange: (heatpumpPartData: Partial<HeatpumpConfiguration>) => void;
}
const HeatDistributionLine: React.FC<HeatDistributionLineProps> = (
  props: HeatDistributionLineProps,
) => {
  const { onValueChange } = props;

  const { t } = useTranslation();

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);

  const handleChange = useCallback(
    (value: any) => {
      onValueChange({
        heatDistribution: value,
      });
    },
    [onValueChange],
  );

  return (
    <S.SubContainer1>
      <S.SubContainerText>{t("Heizverteilsystem")}</S.SubContainerText>
      <Select
        onChange={handleChange}
        style={selectStyle}
        dropdownStyle={dropDownStyle}
        suffixIcon={<DownArrow />}
        value={`${t(configuration.heatDistribution)}`}
      >
        {heatpumpBuildingtypes.map((data) => {
          return (
            <>
              <Option value={data.key} key={data.key}>
                {t(data.label)}
              </Option>
            </>
          );
        })}
      </Select>
    </S.SubContainer1>
  );
};

export default HeatDistributionLine;
