import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const Heading: React.FC = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [textHover, setTextHover] = useState(false);

  const openProductPopup = useCallback((): any => {
    // const formatedData: any = {
    //   project_details: {
    //     ...project,
    //   },
    //   pid: project?.pid,
    // };
    // const popupData = {
    //   type: "heatpump",
    //   data: formatedData,
    // };
    // dispatch(openPopup(POPUPS.PRODUCT_POPUP, popupData));
  }, [dispatch]);

  return (
    <div
      className="same_texts"
      onMouseEnter={() => setTextHover(true)}
      onMouseLeave={() => setTextHover(false)}
      onClick={openProductPopup}
    >
      {!textHover ? t("Consumption Heatpump") : t("Change Heatpump")}
    </div>
  );
};

export default Heading;
