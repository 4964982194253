import { Slider } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { MasterInput } from "src/shared/components";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import { HeatpumpConfiguration } from "src/redux/project";
import { ProjectSelectors } from "src/redux/selectors";

interface HeatLoadLineProps {
  onValueChange: (heatpumpPartData: Partial<HeatpumpConfiguration>) => void;
}
const HeatLoadLine: React.FC<HeatLoadLineProps> = (props: HeatLoadLineProps) => {
  const { onValueChange } = props;

  const { t } = useTranslation();

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);

  const onSliderChangeHandler = useCallback(
    (value: any) => {
      onValueChange({
        heatLoadBuilding: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line hasSlider>
      <ParameterStyles.Label hasSlider>{t("heat load building")}</ParameterStyles.Label>
      <ParameterStyles.Input style={{ width: "51%", paddingRight: "13px" }}>
        <Slider
          step={0.01}
          value={configuration.heatLoadBuilding}
          onChange={onSliderChangeHandler}
        />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <MasterInput
          value={configuration.heatLoadBuilding}
          onChange={onSliderChangeHandler}
          label="kW"
        />
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default HeatLoadLine;
