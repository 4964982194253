import { DotLoader } from "react-spinners";

import * as S from "./Loader.styles";

interface LoaderProps {
  loading: boolean;
  color: string;
}

export const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const { loading, color } = props;

  if (loading) {
    return (
      <S.LoaderContainer>
        <DotLoader color={color} loading={loading} size={150} />
      </S.LoaderContainer>
    );
  }

  return <></>;
};
