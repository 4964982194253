import { FormLabel, Button } from "@mui/material";
import { CardElement } from "@stripe/react-stripe-js";
import { Input, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { IcountryList, countryList } from "src/data";

import { PaymentCardWrapper } from "./styles";
const { Option } = Select;

const PaymentWithCard: React.FC<any> = ({
  setDisabled,
  setError,
  submitHandler,
  email,
  setEmail,
  setName,
  setCountry,
  zipCode,
  setZipCode,
  name,
  processing,
  error,
  disabled,
  succeeded,
}) => {
  const handleChange = (event: any): void => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const { t } = useTranslation();

  return (
    <PaymentCardWrapper>
      <div className="sr-main">
        <div>
          <div>
            <form onSubmit={submitHandler}>
              <FormLabel component="legend" className="lable_class">
                E-Mail
              </FormLabel>
              <Input
                placeholder={t("Enter E-Mail")}
                value={email}
                className="myInputClass"
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormLabel component="legend" className="lable_class">
                {t("Card Details")}
              </FormLabel>

              <CardElement className="myInputClass" onChange={handleChange} />
              <FormLabel component="legend" className="lable_class">
                {t("Name on Card")}
              </FormLabel>
              <Input
                placeholder="Enter Your Name"
                value={name}
                className="myInputClass"
                onChange={(e) => setName(e.target.value)}
              />
              <FormLabel component="legend" className="lable_class">
                {t("Country or Region")}
              </FormLabel>
              <Select
                showSearch
                placeholder={t("Select a contry")}
                optionFilterProp="children"
                onChange={(e) => setCountry(e.target.value)}
                filterOption={(input, option) =>
                  (option?.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {countryList.map((item: IcountryList) => (
                  <Option value={item.name} key={item.code}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              <FormLabel component="legend" className="lable_class">
                Zip code
              </FormLabel>
              <Input
                placeholder={t("Enter Zip Code")}
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className="myInputClass"
              />
              <Button
                fullWidth
                variant="contained"
                className="loginBtn"
                onClick={submitHandler}
                disabled={processing || disabled || succeeded}
              >
                <span>{processing ? <p> Processing</p> : t("Pay Now")}</span>
              </Button>

              {error && <div>{error}</div>}
            </form>
          </div>
        </div>
      </div>
    </PaymentCardWrapper>
  );
};

export default PaymentWithCard;
