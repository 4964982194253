import { ArcElement, Chart as ChartJS, Tooltip } from "chart.js";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  ConsumptionReview,
  ElectricityConsumptionChart,
  ElectricitySupplyChart,
  HeatShareChart,
  HeatSupplyChart,
  SelfSupplyChart,
} from "./components";
import { ChartsColumn } from "./ConsumptionCharts.styles";

import { ProjectSelectors } from "src/redux/selectors";

ChartJS.register(ArcElement, Tooltip);

const ConsumptionCharts: React.FC = () => {
  const model = useSelector(ProjectSelectors.getModel);

  useEffect(() => {
    return () => {
      // Necessary as tooltip element is positioned by us
      // and when consumption tab is closed we want to make
      // sure that this element is gone as if this element was
      // there then it is positioned way bottom of the page and
      // creates extra whitespace at the bottom which we don't want
      document.getElementById("chartjs-tooltip")?.remove();
    };
  }, []);

  const showHeatRelatedCharts = model.inputs.haveHeatingRod || model.inputs.haveHeatpump;

  return (
    <ChartsColumn>
      <ConsumptionReview />
      <ElectricitySupplyChart />
      <ElectricityConsumptionChart />
      {showHeatRelatedCharts && <HeatSupplyChart />}
      {showHeatRelatedCharts && <HeatShareChart />}
      <SelfSupplyChart />
    </ChartsColumn>
  );
};

export default ConsumptionCharts;
