import { clone, merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateWebConstants = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateWebConstants>,
): ProjectState => {
  const newState = clone(currentState);

  newState.webConstants = {
    ...newState.webConstants,
    // @ts-ignore
    constants: payload.constants,
  };
  return newState;
};
