import { clone } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const addSelectedVendor = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addSelectedVendor>,
): ProjectState => {
  const newState = clone(currentState);
  const newVendor = clone(payload);

  newState.vendor = {
    ...newState.vendor,
    selected: [...newState.vendor.selected, newVendor],
  };

  return newState;
};
