import { Button, styled as MaterialStyled } from "@mui/material";
import styled from "styled-components/macro";

export const NavMaterialButton = MaterialStyled(Button)({
  width: "100%",
  textTransform: "none",
  fontSize: 18,
  padding: 0,
});

export const MobileNavLink = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem;
  width: 100%;
  font-family: Nunito;
  color: #b6b6b6;
  font-size: 18px;
  font-weight: 800px;
  line-height: 24px;

  &:hover {
    > svg {
      fill: var(--blue);
    }
  }
`;

export const Icon = styled.div`
  min-width: 30px;
  margin-right: 1rem;

  img {
    width: 25px;
    height: 30px;
    margin: auto;
  }
`;
