import { Geometry } from "src/db-types";

export const getCalculateDistance = (coord1: Geometry, coord2: any): number => {
  const R = 6371;
  const lat1 = coord1.coordinates[1];
  const lon1 = coord1.coordinates[0];
  const lat2 = coord2.coordinates[1];
  const lon2 = coord2.coordinates[0];

  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;

  return distance;
};
