import styled from "styled-components/macro";

interface MainHeaderItemProps {
  showAddComponents: any;
}
export const SubContainer22 = styled.div<MainHeaderItemProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
  width: 100%;
  /* padding-bottom: ${(props) => {
    if (props.showAddComponents) {
      return "12px";
    }
  }}; */
  padding-left: 17px;
`;
export const SubContainer222 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  &:hover {
    cursor: pointer;
    background: #f9f9f9;
  }
`;
export const SubContainer2222 = styled.div`
  position: absolute;
  right: 9px;
  cursor: pointer;
`;
export const AllSubMainText = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
`;
export const AllSubMainValue = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #304864;
`;
export const AllSubMain1Text = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-feature-settings: "tnum" on, "lnum" on;
  cursor: pointer;
  color: #2d4764;
  @media (max-width: 600px) {
    font-weight: 700;
  }
`;
