import { PrimaryButton } from "src/shared/StyledComponents";
import styled from "styled-components/macro";

export const MainContainer = styled.div`
  max-width: min(97%, var(--container-xxl));
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  @media only screen and (max-width: 500px) {
    width: 95%;
    margin: auto;
  }
`;

export const SubContainer = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffce31;
  margin-right: 20px;
  margin-top: 4px;
`;

export const OfferSection = styled.div`
  display: flex !important;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  width: 100%;
`;

export const StyledButton = styled(PrimaryButton)`
  background: #287e97;
  color: #ffffff;
  border: none;
  width: 294px;
`;

export const SliderContainer = styled.div`
  width: 97%;
  margin: auto;
  overflow: hidden;
  position: relative;
`;

export const FinanceSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  @media only screen and (max-width: 500px) {
    width: 100%;
    margin: auto;
  }
`;

export const ArrowButton = styled.button<{ right?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: #287e97;
  background: transparent;
  border: none;
  cursor: pointer;
  ${(props) => (props.right ? "right: 0" : "left: 0")};
  z-index: 1;
`;
