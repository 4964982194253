import styled from "styled-components";

export const ChartTabsWrapper = styled("div")`
  height: 100%;
  width: 872px;
  @media (max-width: 500px) {
    width: 100%;
  }
  padding-top: 20px;
  .ant-select {
    width: 79%;
    margin-left: 24px;
    margin-bottom: 16px;
  }
  .ant-select-selector {
    padding: 5px;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    width: 100% !important;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100% !important;
    justify-content: space-between;
  }
  .ant-tabs-nav {
    margin-bottom: 20px !important;
  }
  .ant-tabs-tab {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #2d4764;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffd75d !important;
  }
  .ant-tabs-ink-bar {
    background: #ffd75d !important;
  }
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    width: 90%;
    margin: auto;
    padding-top: 8px;
  }
  .tabContainer {
    padding-inline: 70px;
  }
`;
