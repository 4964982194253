import { Slider } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch, useWindowDimensions } from "src/hooks";

import { UpGerrnIcon } from "src/assets/svgs";
import { ParameterStyles } from "src/shared/StyledComponents";

import InfoBar from "./InfoBar/InfoBar";
import { InvestMentChart } from "./InvestMentChart/InvestMentChart";
import { InvestMentWrapper } from "./InvestmentStyle";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";

const Investment: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const financialDetails = useSelector(ProjectSelectors.getFinancialDetails);

  const { width } = useWindowDimensions();

  const onTimeFrameChange = (value: number): void => {
    const action = ProjectActions.updateFinancialDetails({
      expectedReturnTime: value,
    });
    dispatch(action);
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <InvestMentWrapper>
        <div className="first_container">
          <div className="heading">
            <div className="energyHeading">{t("Investment Overview")}</div>
          </div>
          <InfoBar />
        </div>
        <div className="secound_container">
          <div className="year_des_heading">
            <div className="heading_2">
              {/* <div className="investHeading">{t("Investment Overview")}</div> */}
              <div className="invest_info">
                <span>
                  <UpGerrnIcon />
                </span>
                <span>{t("10% yearly returns")}</span>
                {t("starting on day one")}
              </div>
            </div>
            <div style={{ width: width > 500 ? "46%" : "80%" }}>
              <ParameterStyles.Line hasSlider>
                <ParameterStyles.Label hasSlider>{t("TIME FRAME")}</ParameterStyles.Label>
                <ParameterStyles.Input>
                  <Slider
                    onChange={onTimeFrameChange}
                    value={financialDetails.expectedReturnTime}
                    max={35}
                  />
                </ParameterStyles.Input>
                <ParameterStyles.Value hasSlider>
                  {financialDetails.expectedReturnTime} {t("YEARS")}
                </ParameterStyles.Value>
              </ParameterStyles.Line>
            </div>
          </div>
          <InvestMentChart />
        </div>
      </InvestMentWrapper>
    </div>
  );
};

export default Investment;
