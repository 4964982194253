import styled from "styled-components/macro";

export const MessageForPanelsWrapper = styled("div")`
  position: relative;
  width: 100%;
  background: #fff3d0;
  border-radius: 8px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2d4764;
  .icon {
    position: absolute;
    top: 10px;
    right: 11px;
    padding: 3px;
    cursor: pointer;
  }
  .icon:hover {
    color: #2d4764;
    font-weight: bold;
  }
  .button_ {
    width: auto;
    height: 28px;
    border: 1px solid #2d4764;
    box-sizing: border-box;
    border-radius: 16px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2d4764;
    line-height: 20px;
  }
  .middle_tow {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
  }
`;
