import { Dialog } from "@mui/material";
import { cloneDeep } from "lodash";
import React, { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import stripe from "src/assets/application_step_1/stripe.png";
import { MasterCheckbox } from "src/shared/components";

import cssClasses from "../auth/auth.module.css";

import { detailsPlanData } from "./cardsData";
import { CheckoutWithPaymentWrapper } from "./checkoutWithPaymentStyles";
import DetailsPlan from "./detailplan/DetailsPlan";
import PaymentFourm from "./paymentFourm/PaymentFourm";
import CloseIcon from "./vectors/closeIcon";
import Logo from "./vectors/Logo";

import { POPUP_KEYS } from "src/redux/popups";
import { closePopup } from "src/redux/popups/action/action.creators";
import { getPopup } from "src/redux/popups/selector";
import { getUser } from "src/redux/user/selectors";

const CheckoutWithPayment: FunctionComponent = () => {
  const popup = useSelector(getPopup);
  const dispatch = useDispatch();
  const [isCretiTick, setIsCreditTic] = useState<boolean>(false);
  const user = useSelector(getUser);
  const { t } = useTranslation();
  const priceData = cloneDeep(detailsPlanData);

  const selectedPlan = useMemo(
    () => popup.PAYMENT_WITH_CARD?.data?.selectedPlan || 0,
    [popup.PAYMENT_WITH_CARD.data],
  );

  const finalPrice = useMemo(() => {
    if (isCretiTick) {
      const credits = user?.credits.length ?? 0;
      const price = `${Number(priceData[selectedPlan].price.split(" ")[0]) - credits * 20} €`;
      priceData[selectedPlan].price = price;
      return price;
    }
    return priceData[selectedPlan].price;
  }, [isCretiTick, priceData, selectedPlan, user?.credits.length]);

  const handleClose = useCallback(
    () => dispatch(closePopup(POPUP_KEYS.payment_with_card)),
    [dispatch],
  );

  return (
    <Dialog onClose={handleClose} open={true} style={{ zIndex: 1000 }} maxWidth={false}>
      <CheckoutWithPaymentWrapper>
        <div className={cssClasses.closeIcon} onClick={handleClose}>
          <CloseIcon />
        </div>
        <div className="mainContainer">
          <div className="part_left">
            <div className="work">
              <Logo />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <MasterCheckbox
                checked={isCretiTick}
                onChange={(value) => {
                  setIsCreditTic(value);
                }}
              >
                {t("Redeem referral credits")}
              </MasterCheckbox>
            </div>
            <DetailsPlan data={priceData[selectedPlan]} key={1} isfrom={true} />
            <div className="work">
              <img src={stripe} alt="" />
            </div>
          </div>
          <div className="part_right">
            <PaymentFourm planPaymentValue={finalPrice} />
          </div>
        </div>
      </CheckoutWithPaymentWrapper>
    </Dialog>
  );
};

export default CheckoutWithPayment;
