import { cloneDeep } from "lodash";

import { BaseComponent, CarConfiguration, HeatpumpConfiguration } from "../types";
import { FilterableBaseComponent, FilterableComponent } from "../types/components";

import { defaultFilters } from "src/redux/application/reducer/application.reducer";

export const defaultComponent: FilterableBaseComponent = {
  extendedWarranty: false,
  item: null,
  quantity: 0,
  isExcludeCost: false,
  preferences: cloneDeep(defaultFilters),
};

export const defaultHeatpumpConfiguration: HeatpumpConfiguration = {
  consumptionHeatpump: 0,
  totalAreaToBeHeated: 100,
  energyDemand: 150,
  buildingStandard: 0.015,
  heatDistribution: "Radiators (wall mount)",
  oilGasCost: 0.15,
  heatingCapacity: 0,
  electricityCostHeatpump: 5000,
  heatLoadBuilding: 0,
  blockingTimes: 4,
  heatpumpDimension: 0,
  solarPowerForHeatpump: 50,
  futureOilGasCostIncrease: 5,
  jaz: 3.5
};

export const defaultCarConfiguration: CarConfiguration = {
  evKmsTravelled: 5000,
  consumptionPer100Km: 18,
  solarPowerForVehicle: 50,
  evYearlyConsumption: 0,
  buildingStandard: 0.015,
  electricityCostPer100Km: 18 * 0.3,
  gasPricePer100Km: 14,
};
