import { clone, merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateBakeModelProgress = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateBakeModelProgress>,
): ProjectState => {
  const newState = clone(currentState);

  newState.trackProgress = {
    percentage: payload.percentage || 0,
    time: payload.time || "",
    elapsed: payload.elapsed || 0
  };
  return newState;
};
