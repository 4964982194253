import styled from "styled-components/macro";

export const CarSelectorWrapper: any = styled("div")`
  .header {
    margin-top: 1rem;
    gap: 1rem;
    display: flex;
    padding-inline: 1rem;
    justify-content: space-between;
  }
  .header_text {
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
    }
  }
  .product_main {
    height: auto;
    min-width: 1124px;
    border-radius: 22px;
    position: relative;
  }
  .img_set {
    position: absolute;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    top: 20px;
  }
  .ant-checkbox-wrapper {
    margin-top: 13px;
  }
  .add_car {
    height: 40px;
    width: 209.756103515625px;
    left: 830px;
    top: 70px;
    border-radius: 36px;
    background: #ffd75d;
    border-radius: 36px;
    border: none;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    text-transform: uppercase;
    color: #2d4764;
  }
  .heading {
    flex: 1;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    padding-left: 10px;
    color: #304864;
    margin: auto;
    span {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #304864;
    }
  }
  .make_flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .first_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    width: 86%;
    margin: auto;
    gap: 15px;
    padding-top: 20px;
  }
  .check_box_contaienr {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
  .product_list_container {
    height: auto;
    margin-top: 22px;
    width: 100%;
    border-radius: 22px;
    background: #ffffff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    padding-left: 50px;
    padding-right: 50px;
  }
  .changeText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2d4764;
  }
  .linne {
    width: 100%;
    height: 0px;
    border: 1px solid #c0c0c0;
  }
  .slider_div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    gap: 5px;
    div {
      font-style: normal;
      width: 224px;
      font-weight: 400;
      font-size: 14px;
      color: #2d4764;
    }
  }
  .secound_continaer {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
  }
  .same_two {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .first_one {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .lable_text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #2d4764;
  }
  @media (max-width: 500px) {
    .product_main {
      min-width: 95% !important;
      width: 323px;
    }
    .product_list_container {
      box-shadow: none;
      padding-left: 0px;
      height: 467px;
      padding-right: 0px;
    }
    .first_container {
      margin-bottom: 6px;
    }
    .check_box_contaienr {
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 30px;
    }
    .heading {
      font-size: 16px;
    }
    .heading span {
      font-size: 16px;
    }
    .secound_continaer {
      flex-direction: column;
      width: 100%;
    }
    .same_two {
      flex-direction: column;
      width: 100%;
    }
    .lable_text {
      font-size: 14px;
    }
  }
`;
export const SubContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 47%;
`;
export const SubContainerText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
  min-width: 125px;
`;
export const SubContainerValue = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #304864;
  min-width: 68px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
