import { makeStyles } from "@mui/styles";

import { colors } from "src/utils";

export const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "100%",
    backgroundColor: "#EDF5FFAA",
    display: "flex",
    alignItems: "center",
  },
  button: {
    minWidth: "44px",
    minHeight: "44px",
    color: colors.darkBlue,
    borderRadius: "25px",
    fontSize: "14px",
    width: "160px",
    fontWeight: "bold",
    textAlign: "center",
    // marginLeft: "250px",
    height: "40px",
    transitionDelay: "1.3s",
    border: `1px solid ${colors.darkBlue}`,
    "@media (max-width: 900px)": {
      marginBottom: "30px",
      marginLeft: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
      width: "160px",
      marginLeft: "auto",
    },
    "&:hover": {
      [theme.breakpoints.down("xs")]: {
        width: "160px",
        marginLeft: "auto",
      },
      width: "310px",
      transition: "0.3s",
    },
    "&::after": {
      transition: "0.3s",
    },
  },
  logo: {
    "&:hover": {
      color: "#fff",
    },
  },
  mainMixButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "83%",
  },
  shareTo: {
    marginTop: "7px",
    marginLeft: "3px",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  commanDiff: {
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "3px",
    height: "26px",
    "&:hover": {
      transform: "scale(1.02)",
    },
    width: "26px",
  },
}));
