import React from "react";
import { MultiTenantContainer } from "../MultiTenantStyles";
import { InfoIcon } from "src/shared/components";
import { useTranslation } from "react-i18next";
import { OperationContainer, OperationOuterContainer } from "./operationConfigrationStyles";

import BeHouseHold from "src/pages/ApplicationPage/components/ApplicationStep1/components/Configuration/components/ConfigurationParam/components/BasicElectricityConsumption/components/ElectricityConsumptionEditor/components/BeHouseHold/BeHouseHold";
import ElectricityPrice from "src/pages/ApplicationPage/components/ApplicationStep1/components/Configuration/components/ConfigurationParam/components/BasicElectricityConsumption/components/ElectricityConsumptionEditor/components/ElectricityPrice/ElectricityPrice";
import ExpectedSolar from "src/pages/ApplicationPage/components/ApplicationStep1/components/Configuration/components/ConfigurationParam/components/BasicElectricityConsumption/components/ElectricityConsumptionEditor/components/ExpectedSolar/ExpectedSolar";
import ExpectedHardware from "src/pages/ApplicationPage/components/ApplicationStep1/components/Configuration/components/ConfigurationParam/components/BasicElectricityConsumption/components/ElectricityConsumptionEditor/components/ExpectedHardware/ExpectedHardware";
import ExpectedSoftware from "src/pages/ApplicationPage/components/ApplicationStep1/components/Configuration/components/ConfigurationParam/components/BasicElectricityConsumption/components/ElectricityConsumptionEditor/components/ExpectedSoftware/ExpectedSoftware";
import ExpectedTenants from "src/pages/ApplicationPage/components/ApplicationStep1/components/Configuration/components/ConfigurationParam/components/BasicElectricityConsumption/components/ElectricityConsumptionEditor/components/ExpectedTenants/ExpectedTenants";

const OperatorAdjust = () => {
  const { t } = useTranslation();
  return (
    <MultiTenantContainer>
      <div className="headers">
        <div className="make_flex">
          <div className="head">{t("Konfiguration der Lösung")}</div> <InfoIcon />
        </div>
        <div className="paragraph">
          {t(
            "Für die Umsetzung eines Mieterstromkonzeptes gibt es verschiedene Lösungsansätze - die Konfiguration lässt sich später anpassen",
          )}
        </div>
      </div>
      <OperationOuterContainer>
        <OperationContainer>
          <ExpectedTenants title="PARTICIPATING TENANTS" />
        </OperationContainer>
        <OperationContainer>
          <ElectricityPrice title="ELECTRICITY PRICE FOR TENANT (PV)" />
        </OperationContainer>
        <OperationContainer>
          <ExpectedSolar title="ELECTRICITY SUPPLY PER TENANT" />
        </OperationContainer>
      </OperationOuterContainer>
    </MultiTenantContainer>
  );
};

export default OperatorAdjust;
