import { CSSProperties, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { MainUserState, selectors as userSelectors } from "src/redux/user";

interface Prefill {
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  date: Date;
}

const getPrefillFromUserData = (currentUser: MainUserState | null): Prefill => {
  if (currentUser) {
    return {
      email: currentUser.email,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      name: currentUser.displayName,
      date: new Date(Date.now() + 86400000),
    };
  }
  return {
    email: "",
    firstName: "",
    lastName: "",
    name: "",
    date: new Date(Date.now() + 86400000),
  };
};

export const useCalendlyData = (): {
  url: string;
  prefill: Prefill;
  pageSettings: CSSProperties;
  utm: any;
} => {
  const currentUser = useSelector(userSelectors.getUser);
  const url = "https://calendly.com/experte-solarhub";

  const [prefill, setPrefill] = useState(getPrefillFromUserData(currentUser));

  useEffect(() => {
    const updatedPrefill = getPrefillFromUserData(currentUser);

    setPrefill(updatedPrefill);
  }, [currentUser]);

  const pageSettings = useMemo(() => {
    return {
      backgroundColor: "ffffff",
      hideEventTypeDetails: false,
      hideLandingPageDetails: false,
      primaryColor: "00a2ff",
      textColor: "4d5055",
    };
  }, []);

  const utm = useMemo(() => {
    return {
      utmCampaign: "Spring Sale 2019",
      utmContent: "Shoe and Shirts",
      utmMedium: "Ad",
      utmSource: "Facebook",
      utmTerm: "Spring",
    };
  }, []);

  return {
    url,
    prefill,
    pageSettings,
    utm,
  };
};
