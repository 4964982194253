import { Tooltip } from "@mui/material";
import styled from "styled-components/macro";

export const CustomTooltip = styled(({ className, ...other }) => (
  <Tooltip classes={{ tooltip: className }} {...other} />
))`
  background-color: black !important;
  font-size: 1rem !important;
  padding-inline: 1rem !important;
  margin-top: 10px !important;
  font-family: var(--theme-font);
  pointer-events: none;

  .MuiTooltip-arrow {
    color: black !important;
    pointer-events: none;
  }
`;

export const ChartWrapper = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
`;

export const Divider = styled.div`
  background-image: linear-gradient(to right, #444 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 8px 3px;
  background-repeat: repeat-x;
  height: 1px;
  width: 100%;
`;

export const Region = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
`;

interface ICircle {
  backgroundColor: string;
}
export const Circle = styled.div<ICircle>`
  height: 11px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  margin: 6px;
`;

interface IRegionLabel {
  verticalAlign: string;
}
export const RegionLabel = styled.div<IRegionLabel>`
  flex: 1;
  display: flex;
  align-items: center;
  color: var(--blue);
  font-weight: bold;

  ${(p) =>
    p.verticalAlign === "top" &&
    `
    margin-top: 16px;
    align-self: flex-start;
  `}

  ${(p) =>
    p.verticalAlign === "bottom" &&
    `
    margin-bottom: 16px;
    align-self: flex-end;
  `}
`;

interface IChartArea {
  verticalAlign: string;
  hovered: boolean;
}
export const ChartArea = styled.div<IChartArea>`
  height: 100%;
  flex: 1;
  position: relative;
  display: flex;
  align-items: ${(p) => (p.verticalAlign === "bottom" ? "flex-end" : "flex-start")};
  justify-content: center;

  ${(p) =>
    p.hovered &&
    `
    animation-name: to-left;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  `}

  ${(p) =>
    !p.hovered &&
    `
    animation-name: to-center;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  `}

  @keyframes to-left {
    0% {
      left: 0;
    }
    100% {
      left: -30px;
    }
  }

  @keyframes to-center {
    0% {
      left: -30px;
    }
    100% {
      left: 0;
    }
  }
`;

interface IBar {
  color: string;
  barWidthsToLeave?: number;
  labelAlign?: string;
  hovered?: boolean;
  lighten?: boolean;
  needNegativeBarsAlignAdjust?: boolean;
  showTopBorder?: boolean;
  isHoverActive?: boolean;
}
export const Bar = styled.div<IBar>`
  position: relative;
  width: 20px;
  z-index: 100;
  background-color: ${(props) => props.color};
  transition: all 200ms, outline 1ms;
  border-radius: 2px;
  margin-block: 1px;

  ${(p) => p.barWidthsToLeave != null && `left: ${p.barWidthsToLeave * 25}px;`}

  ${(p) =>
    p.lighten &&
    `
    background-color: ${p.color}55;
  `}

  .top-border {
    height: 2px;
    background-color: var(--primary-color);
    position: absolute;
    width: 0px;
    left: 18px;
    border-radius: 5px;
    pointer-events: none;

    z-index: 400;
    visibility: hidden;
    ${(p) => p.needNegativeBarsAlignAdjust && "bottom: 0;"}
  }

  ${(p) =>
    p.isHoverActive &&
    `
    .top-border {
      visibility: visible;
      transition: width 500ms ease-out;
      transition-delay: 500ms;
      width: 52px;
    }
  `}

  .bar-text {
    white-space: nowrap;
    position: absolute;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
    color: var(--blue);
    ${(p) =>
      p.labelAlign === "top" &&
      `
      top: -28px;
    `}
    ${(p) =>
      p.labelAlign === "bottom" &&
      `
      bottom: -28px;
    `}
    /* transform: translateX(50%); */
  }

  .positive-bars,
  .negative-bars {
    height: 100%;
    position: relative;
    animation-delay: 750ms;
    animation-duration: 500ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .positive-bars {
    animation-name: to-right-positive-bars;
    ${(p) => p.needNegativeBarsAlignAdjust && "position: absolute; top: calc(100% - 2px);"}

    .bottom-border-with-arrow {
      height: 2px;
      width: 45px;
      background-color: var(--primary-color);
      position: relative;
      left: -25px;
      bottom: 2px;
      border-radius: 5px;
      z-index: 555;
      pointer-events: none;

      ::before {
        content: "";
        position: absolute;
        width: 10px;
        background: var(--primary-color);
        z-index: 555;
        transform: rotate(-90deg);
        transform-origin: 0 0;
        right: 1px;
        height: 2px;
        pointer-events: none;
      }

      ::after {
        content: "";
        position: absolute;
        border: 10px solid var(--primary-color);
        border-color: transparent transparent var(--primary-color) transparent;
        border-top-width: 4px;
        border-bottom-width: 8px;
        border-right-width: 4px;
        border-left-width: 4px;
        right: 6px;
        bottom: 12px;
        z-index: 555;
        pointer-events: none;
      }
    }
  }

  .negative-bars {
    animation-name: to-right-negative-bars;
    ${(p) => p.needNegativeBarsAlignAdjust && "position: absolute; top: 0;"}
  }

  @keyframes to-right-positive-bars {
    0% {
      left: 0;
    }
    100% {
      left: 50px;
    }
  }

  @keyframes to-right-negative-bars {
    0% {
      left: 0;
    }
    100% {
      left: 25px;
    }
  }
`;
