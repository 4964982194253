import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useIsComponentAdded, useWindowDimensions } from "src/hooks";

import { ForHeatImage } from "src/assets/images";
import { HeatEnergyIcon, UpArrowIcon } from "src/assets/svgs";
import { useNumberFormat } from "src/hooks/useNumberFormat";
import { ConsumptionReview } from "src/pages/ApplicationPage/components/ApplicationStep1/components/ImageConfiguration/ConsumptionCharts/components";

import { MonthlyBalanceChart } from "../MonthlyBalance/MonthlyBalanceChart/MonthlyBalanceChart";

import { HomeEnergyWrapper } from "./HomeEnergyStyle";

import { ProjectSelectors } from "src/redux/selectors";

const HomeEnergy: React.FC = () => {
  const { t } = useTranslation();

  const model = useSelector(ProjectSelectors.getModel);
  const { solarPanelProduction } = useSelector(ProjectSelectors.getEnergy);

  const { width } = useWindowDimensions();
  const { formatNumber } = useNumberFormat();
  const { areCarsAdded, isHeatpumpAdded, isWallboxAdded } = useIsComponentAdded();

  return (
    <HomeEnergyWrapper>
      {width > 500 ? (
        <div className="first_box">
          <div className="heading">
            <div className="energyHeading">{t("Home energy balance - yearly overview")}</div>
            <div className="small_heading">
              <span>
                <UpArrowIcon />
              </span>
              <span>{Math.round(model.percentages.autarky)}%</span>{" "}
              {t("autarky with current setup")}
            </div>
          </div>
          <div className="home_wrapper">
            <div className="center">
              <div className="make_in_same_line">
                <div className="info_text">
                  <div className="upper">{formatNumber(model.consumption)} kWh</div>
                  <div className="lower">{t("Total consumption")}</div>
                </div>
                <div className="info_text">
                  <div className="upper_yellow">{formatNumber(solarPanelProduction)} kWh</div>
                  <div className="lower">{t("Generation")}</div>
                </div>
              </div>
              <div className="flex_row">
                {areCarsAdded ||
                isWallboxAdded ||
                !!model.heatingRod.consumption ||
                isHeatpumpAdded ? (
                  <div className="wra">
                    <div className="info_text22">
                      <div className="upper_dark_yellow">
                        {formatNumber(model.household.consumption)} kWh
                        <span>- {model.household.percentages.autarky.toFixed(0)}% Solarstrom</span>
                      </div>
                      <div className="lower">{t("Household consumption")}</div>
                    </div>
                    <div className="info_text3">
                      <div className="upper_green_yellow">
                        {formatNumber(model.ev.consumption)} kWh
                        <span>- {model.ev.percentages.autarky.toFixed(0)}% Solarstrom</span>
                      </div>
                      <div className="lower">{t("E-mobility consumption")}</div>
                    </div>
                    <div className="info_text2">
                      <div className="upper_red_dark">
                        {formatNumber(model.heatpump.consumption + model.heatingRod.consumption)}{" "}
                        kWh
                        {/* <span>
                          -{" "}
                          {(
                            model.heatpump.percentages.autarky +
                            model.heatingRod.percentages.autarky
                          ).toFixed(0)}
                          % Solarstrom
                        </span> */}
                      </div>
                      <div className="lower">{t("Heat consumption")}</div>
                    </div>
                  </div>
                ) : (
                  <div className="info_text2">
                    <div className="upper_dark_yellow">
                      {formatNumber(model.household.consumption)} kWh
                    </div>
                    <div className="lower">{t("Household consumption")}</div>
                  </div>
                )}
                <div className="relative_wrapper">
                  <img src={ForHeatImage} alt="" />
                  <div className="info_box">
                    <div className="info_text">
                      <div className="upper_red">{formatNumber(model.fromGrid)} kWh</div>
                      <div className="lower">{t("Grid supply")}</div>
                    </div>
                    <div className="info_text" style={{ paddingLeft: "17px" }}>
                      <div className="upper_green">{formatNumber(model.feedIn)} kWh</div>
                      <div className="lower">{t("Feed in")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ConsumptionReview />
      )}
      <div className="secound_box">
        <MonthlyBalanceChart />
      </div>
    </HomeEnergyWrapper>
  );
};

export default HomeEnergy;
