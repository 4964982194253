import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "src/hooks";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";

export const useHeatpumpCalculation = (): void => {
  const dispatch = useAppDispatch();

  const { configuration } = useAppSelector(ProjectSelectors.getHeatpump);
  const { JAZ, heatpumpShare } = useAppSelector(ProjectSelectors.getConstants);

  const {
    heatLoadBuilding,
    blockingTimes,
    buildingStandard,
    consumptionHeatpump,
    energyDemand,
    totalAreaToBeHeated,
    heatingCapacity,
  } = configuration;

  /**
   * Heating capacity
   */
  useEffect(() => {
    const blockingFactor = 24 / (24 - blockingTimes);
    const capacity = heatLoadBuilding * blockingFactor;

    dispatch(
      ProjectActions.updateHeatpump({
        configuration: {
          heatingCapacity: capacity,
        },
      }),
    );
  }, [blockingTimes, dispatch, heatLoadBuilding]);

  /**
   * Heatpump dimension
   */
  useEffect(() => {
    let dimension = (heatingCapacity * heatpumpShare) / 100;

    if (!isFinite(dimension)) dimension = 0;

    dispatch(
      ProjectActions.updateHeatpump({
        configuration: {
          heatpumpDimension: dimension,
        },
      }),
    );
  }, [dispatch, heatingCapacity, heatpumpShare]);

  /**
   * heating capacity
   */
  useEffect(() => {
    dispatch(
      ProjectActions.updateHeatpump({
        configuration: {
          heatLoadBuilding: buildingStandard * totalAreaToBeHeated,
        },
      }),
    );
  }, [buildingStandard, dispatch, totalAreaToBeHeated]);

  /**
   * consumption heatpump
   */
  useEffect(() => {
    dispatch(
      ProjectActions.updateHeatpump({
        configuration: {
          consumptionHeatpump: totalAreaToBeHeated * energyDemand,
        },
      }),
    );
  }, [dispatch, energyDemand, totalAreaToBeHeated]);

  /**
   * electricity cost heatpump
   */
  useEffect(() => {
    dispatch(
      ProjectActions.updateHeatpump({
        configuration: {
          electricityCostHeatpump: consumptionHeatpump / JAZ,
        },
      }),
    );
  }, [JAZ, consumptionHeatpump, dispatch]);
};
