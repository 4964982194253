import { useCallback, useEffect } from "react";

import { BackendApis } from "src/api";

import { useGetAllUniqueAddressProjectsApi } from "./apis";

import { AppActions } from "src/redux";
import { AllProjectActions } from "src/redux/actionCreators";
import { AppSelectors, UserSelectors } from "src/redux/selectors";

import { useAppDispatch, useAppSelector } from ".";

export const useGetAllUniqueAddressProjects = (): void => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(UserSelectors.getUser);
  const {
    actions: { shouldLoadUniqueProjects },
  } = useAppSelector(AppSelectors.getState);

  const { response, error, fetchData } = useGetAllUniqueAddressProjectsApi();

  const setLoaderFlag = useCallback(
    (status: boolean) => {
      dispatch(
        AppActions.updateAppState({
          actions: {
            shouldLoadUniqueProjects: status,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    setLoaderFlag(true);
  }, [dispatch, setLoaderFlag, user?.id]);

  useEffect(() => {
    if (!shouldLoadUniqueProjects) return;

    /** reset the flag, as we already know that flag was set, as above condition did not stopped */
    setLoaderFlag(false);

    if (!user?.id) return;

    void fetchData({
      ...BackendApis.getAllProjects({ uid: user.id }),
    });
  }, [fetchData, setLoaderFlag, shouldLoadUniqueProjects, user?.id]);

  useEffect(() => {
    if (!response) return;

    const {
      data: { data: allProjectDB },
    } = response;

    dispatch(AllProjectActions.setAllProjects(allProjectDB));
  }, [dispatch, response]);

  useEffect(() => {
    if (!error) return;
    console.error(error);
  }, [error]);
};
