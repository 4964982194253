import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
  content: {
    paddingTop: "2rem",
    marginBottom: "50px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "20px",
    },
  },
  heading: {
    fontSize: "20px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "28px",
    },
  },
  center: {
    textAlign: "center",
    color: "#2D4764",
  },
  main_container: {
    marginTop: "30px",
    paddingInline: "30px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  inside_main: {
    width: "208px",
    height: "140px",
    border: `1px solid #2D4764`,
    borderRadius: "19px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    "&.selected": {
      backgroundColor: "rgba(253, 184, 0, 0.1)",
      border: `2px solid #2D4764`,
      width: "208px",
      height: "140px",
    },
    "& img": {
      height: "64px",
      marginTop: "20px",
      width: "76px",
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(253, 184, 0, 0.1)",
      tansition: "0.3s",
    },
  },
}));
