import { cloneDeep } from "lodash";

import { PropertiesFilter } from "src/hooks";

import { ApplicationState } from "../types";

import { actionNames, actionHandlers } from "src/redux/application";
import { ReduxAction } from "src/redux/common.types";

type ProductFilters = PropertiesFilter & {
  applicableMaxCapacity: number | null;
};

export const defaultFilters: ProductFilters = {
  "Made in Germany": false,
  "Glas / Glas Module": false,
  "High performance module >370 Wp": false,
  "Full black": false,
  "Data visualization": false,
  "Load management": false,
  "made in europe": false,
  hybrid: false,
  Extendible: false,
  "Emergency Power": false,
  Display: false,
  "Schnellladung 22KW": false,
  App: false,
  Authorisation: false,
  "Air/Water-Heatpump": false,
  "App for heat management": false,
  "Warm water heating": false,
  applicableMaxCapacity: null,
  "25 Jahre Leistungsgarantie (mind 81%)": false,
  "more than 2 roof areas / shading": false,
};

const initialState: ApplicationState = {
  currentStep: 0,
  popupCurrentStep: 1,
  quoteFetching: false,
  saveProjectRemaining: false,
  isSavedProjectRequestLoading: false,
  isProjectLoading: false,
  shouldSaveLastLog: false,
  setAutarkiesTo0: false,
  setAutarkiesToMax: false,
  partnerCity: {
    yearlyConsultings: null,
    usedYearlyConsultings: null,
    freeConsultings: null,
    consultingStatus: null,
    Gemeindeschluessel8: null,
    partnerStatus: null,
  },
  apiStates: {
    isUserApiLoading: false,
    isUpdateUserApiLoading: false,
    isUpdateLastLogApiLoading: false,
    isQuoteApiLoading: false,
    isSavedProjectApiLoading: false,
    isProductApiLoading: false,
    isUpdateProjectApiLoading: false,
    isLoadAllProjectsApiLoading: false,
  },
  actions: {
    updateDeal: false,
    updateSolarQtyByProfile: false,
    shouldLoadUniqueProjects: false,
  },
  previousValues: {
    inverterPreference: null,
  },
  properties: defaultFilters,
  lastAccessedProject: null,
};

const reducer = (state = initialState, action: ReduxAction<any>): ApplicationState => {
  switch (action.type) {
    case actionNames.SET_CURRENT_STEP:
      return actionHandlers.setCurrentStep(state, action);
    case actionNames.SET_POPUP_CURRENT_STEP:
      return actionHandlers.setPopupCurrentStep(state, action);
    case actionNames.SET_QUOTE_FETCHING:
      return actionHandlers.setQuoteFetching(state, action);
    case actionNames.UPDATE_APP_STATE:
      return actionHandlers.updateAppState(state, action);
    case actionNames.UPDATE_INVERTER_PREVIOUS_PREF:
      return actionHandlers.updateInverterPreviousPreference(state, action);
    case actionNames.UPDATE_PARTNER_CITY_DATA:
      return actionHandlers.updatePartnerCityData(state, action);
    case actionNames.UPDATE_PROPERTIES:
      return actionHandlers.updateProperties(state, action);
    case "RESET_REDUX":
      return cloneDeep(initialState);
    default:
      return state;
  }
};

export default reducer;
