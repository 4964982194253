import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React from "react";
import { useTranslation } from "react-i18next";

import * as S from "./StepperPresentational.styles";
import useGetSaveProjectFun from "src/hooks/useGetSaveProjectFun";
import { useSearchParams } from "react-router-dom";

interface StepperPresentationalProps {
  activeStep: number;
  labels: string[];
  onStepClick: Function;
}

const StepperPresentational: React.FC<StepperPresentationalProps> = (
  props: StepperPresentationalProps,
) => {
  const { activeStep, labels, onStepClick } = props;
  const { saveProject } = useGetSaveProjectFun();

  const { t } = useTranslation();

  return ( //@ts-ignore
    <Stepper connector={<S.Connector />} activeStep={activeStep} alternativeLabel>
      {labels.map((heading: string, index: number) => (
        <Step key={heading}>
          <StepLabel
            onClick={() => {
              onStepClick(index);
                saveProject();
            }}
          >
            {t(heading)}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperPresentational;
