import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useIsComponentAdded } from "src/hooks";

import { HeatpumpConfigurationBox } from "src/shared/components";

import { Heading, InputBox } from "./components";

import { ProjectSelectors } from "src/redux/selectors";

const HeatpumpLine: React.FC = () => {
  const [isConfigOpen, setIsConfigOpen] = useState<boolean>(false);

  const { isHeatpumpAdded } = useIsComponentAdded();

  return (
    <>
      {isHeatpumpAdded && (
        <div className="same_container">
          <Heading />
          <InputBox onInputClick={() => setIsConfigOpen(true)} />
        </div>
      )}
      {isConfigOpen && <HeatpumpConfigurationBox onClose={() => setIsConfigOpen(false)} isEdit />}
    </>
  );
};

export default HeatpumpLine;
