import React from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, useIsComponentAdded, useNumberFormat } from "src/hooks";

import { ParameterStyles } from "src/shared/StyledComponents";

import { ProjectSelectors } from "src/redux/selectors";

export const YearlyConsumptionLine: React.FC = () => {
  const { yearlyConsumption } = useAppSelector(ProjectSelectors.getEnergy);

  const { formatNumber } = useNumberFormat();
  const { t } = useTranslation();
  const { areCarsNotAdded, isHeatpumpNotAdded } = useIsComponentAdded();

  if (areCarsNotAdded && isHeatpumpNotAdded) {
    return <></>;
  }

  return (
    <>
      <ParameterStyles.SectionDivider />
      <ParameterStyles.Line isEqualPadding>
        <ParameterStyles.Label bold style={{ alignItems: "center" }}>
          <span style={{ fontFamily: "sans-serif", fontSize: "1.2em" }}>Σ</span>{" "}
          {t("Yearly consumption")}
        </ParameterStyles.Label>
        <ParameterStyles.Value bold>{formatNumber(yearlyConsumption)} kWh</ParameterStyles.Value>
      </ParameterStyles.Line>
    </>
  );
};
