import { Slider } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { MasterInput } from "src/shared/components";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import { HeatpumpConfiguration } from "src/redux/project";
import { ProjectSelectors } from "src/redux/selectors";

interface BlockingTimeLineProps {
  onValueChange: (heatpumpConfig: Partial<HeatpumpConfiguration>) => void;
}
const BlockingTimeLine: React.FC<BlockingTimeLineProps> = (props: BlockingTimeLineProps) => {
  const { onValueChange } = props;

  const { t } = useTranslation();

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);

  const onSliderChangeHandler = useCallback(
    (value: number) => {
      onValueChange({
        blockingTimes: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line hasSlider>
      <ParameterStyles.Label hasSlider>{t("Blocking times")}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider
          step={0.01}
          value={configuration.blockingTimes}
          onChange={onSliderChangeHandler}
          max={24}
        />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <MasterInput
          value={configuration.blockingTimes}
          onChange={onSliderChangeHandler}
          maxNumber={23}
          label="h"
        />
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default BlockingTimeLine;
