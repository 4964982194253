import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DonoughtRecord, DonoughtChart } from "src/shared/components";
import { getPercentage } from "src/utils";

import { getChartRecordsFromModel } from "./HeatShareChartRecords";

import { UserSelectors, ProjectSelectors } from "src/redux/selectors";

const HeatShareChart: React.FC = () => {
  const { t } = useTranslation();

  const lang = useSelector(UserSelectors.getLangCode);
  const model = useSelector(ProjectSelectors.getModel);

  const [chartData, setChartData] = useState<DonoughtRecord[]>([]);

  useEffect(() => {
    const chartRecords = getChartRecordsFromModel(model);
    setChartData(chartRecords);
  }, [model, lang]);

  return (
    <DonoughtChart
      title={t("Heat share")}
      chartData={chartData}
      middleText={{
        title: `${getPercentage(
          model.heatShare.heatpump,
          model.heatShare.heatpump + model.heatShare.kessel,
        ).toFixed(0)}%`,
        subTitle: t("Heatpump share"),
      }}
    />
  );
};

export default HeatShareChart;
