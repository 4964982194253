import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DonoughtRecord, DonoughtChart } from "src/shared/components";

import { getChartRecordsFromModel } from "./ElectricitySupplyChartRecords";

import { ProjectSelectors, UserSelectors } from "src/redux/selectors";

interface ElectricitySupplyChartProps {
  captureScreenshot?: () => void;
  hideTitle?: boolean;
  disableExpandButton?: boolean;
}

const ElectricitySupplyChart: React.FC<ElectricitySupplyChartProps> = (props:ElectricitySupplyChartProps) => {
  const { t } = useTranslation();

  const lang = useSelector(UserSelectors.getLangCode);
  const model = useSelector(ProjectSelectors.getModel);

  const [electricitySupplyChartData, setElectricitySupplyChartData] = useState<DonoughtRecord[]>(
    [],
  );

  useEffect(() => {
    const chartRecords = getChartRecordsFromModel(model);
    setElectricitySupplyChartData(chartRecords);
  }, [model, lang]);

  useEffect(() => {
    if (typeof props.captureScreenshot === "function") {
      setTimeout(() => {
        void props.captureScreenshot?.();
      }, 2000);
    }
  }, [props.captureScreenshot, electricitySupplyChartData]);

  return (
    <DonoughtChart
      title={t("Electricity supply")}
      chartData={electricitySupplyChartData}
      disableExpandButton = {props.disableExpandButton}
      middleText={{
        title: `${model.percentages.autarky.toFixed(0)}%`,
        subTitle: t("Autarky"),
      }}
      hideTitle={Boolean(props.captureScreenshot)}
      openByDefault={Boolean(props.captureScreenshot)}
    />
  );
};

export default ElectricitySupplyChart;
