import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { SolarImage } from "src/assets/images";
import { LongLogo } from "src/assets/svgs";
import { PopupSkeleton } from "src/shared/components";

import cssClasses from "./auth.module.css";
import ForgotPassword from "./forms/ForgotPassword";
import LoginForm from "./forms/login";
import ResetPassword from "./forms/ResetPassword";
import SignUpPopup from "./forms/signup";
import UpdateDetailsForm from "./forms/UserDetails";
import { AuthPopupProps, AUTH_MODES } from "./types";

import {
  selectors as PopupSelectors,
  actionCreators as PopupActions,
  POPUP_KEYS,
} from "src/redux/popups";
import { UserSelectors } from "src/redux/selectors";
import { InstallerPartner } from "../WelcomePopupForNoAddress/components/MiddlePath/MiddlePath.styles";
const AuthPopup: React.FC = () => {
  const { t } = useTranslation();
  const disptach = useDispatch();

  const authPopupData = useSelector(PopupSelectors.getAuthPopup);

  const [authMode, setAuthMode] = useState<AUTH_MODES>(AUTH_MODES.signup);
  const [title, setTitle] = useState<string | React.ReactElement>("");
  const [subtitle, setSubtitle] = useState("");
  const installerDetails = useSelector(UserSelectors?.getInstaller);

  const generateTitle = useCallback(
    (title: string) => {
      return (
        <>
          <span className={cssClasses.redDot}></span>
          {`${title} ${t("and")} ${t("JOIN OUR WEBINAR")}`}
        </>
      );
    },
    [t],
  );

  useEffect(() => {
    const mode = authPopupData.data.authMode;
    setAuthMode(mode);

    if (mode === AUTH_MODES.webinar_signup) {
      setTitle(generateTitle(t("REGISTER")));
      setSubtitle(t("USERREGISTER TEXT SERVING TEXT"));
    } else if (mode === AUTH_MODES.webinar_login) {
      setTitle(generateTitle(t("LOGIN")));
      setSubtitle(t("USERREGISTER TEXT SERVING TEXT"));
    } else if (mode === AUTH_MODES.update_details) {
      setTitle(t("One more detail"));
      setSubtitle(t("We require following details from you for realising your PV Project"));
    } else {
      setTitle(t("SAVE YOUR SOLARPROJECT"));
      setSubtitle(t("YOUR PATH TO YOUR OWN ENERGY"));
    }
  }, [authPopupData.data.authMode, generateTitle, t]);

  useEffect(() => {
    const search = window.location.search;
    const query = new URLSearchParams(search);
    if (query.get("mode") === "resetPassword") {
      const data: AuthPopupProps = {
        authMode: AUTH_MODES.reset_password,
      };
      disptach(PopupActions.openPopup(POPUP_KEYS.auth, data));
    }
  }, [disptach]);

  const showForgotPassForm = authMode === AUTH_MODES.forgot_password;
  const showLoginForm = authMode === AUTH_MODES.login || authMode === AUTH_MODES.webinar_login;
  const showSignupForm = authMode === AUTH_MODES.signup || authMode === AUTH_MODES.webinar_signup;
  const showResetPasswordForm = authMode === AUTH_MODES.reset_password;
  const showDetailsForm = authMode === AUTH_MODES.update_details;

  const onCloseClick = useCallback(() => {
    if (showDetailsForm) return;

    disptach(PopupActions.closePopup(POPUP_KEYS.auth));
  }, [disptach, showDetailsForm]);

  return (
    <PopupSkeleton
      handleClose={onCloseClick}
      disableClose={showDetailsForm}
      open={true}
      styless={{ height: "100%", maxHeight: "fit-content" }}
      styleToDialog={{ zIndex: 2000 }}
    >
      <div className={cssClasses.popupContainer}>
        <div className={cssClasses.popupImage}>
          <img src={SolarImage} />
        </div>

        <div className={cssClasses.popupFormContainer}>
          <div className={cssClasses.formHeader}>
            {installerDetails ? (
              <InstallerPartner>
                {installerDetails.logoUrl.length ? (
                  <div className="image-container">
                    <img src={installerDetails.logoUrl[0]} alt="Intaller partner" />
                  </div>
                ) : (
                  <p className="installer-name">{installerDetails.companyName}</p>
                )}
              </InstallerPartner>
            ) : (
              <LongLogo />
            )}
          </div>
          <div className={cssClasses.formTitle}>{title}</div>
          <div className={cssClasses.formSubtitle}>{subtitle}</div>
          {showResetPasswordForm && <ResetPassword />}
          {showLoginForm && <LoginForm />}
          {showSignupForm && <SignUpPopup />}
          {showForgotPassForm && <ForgotPassword />}
          {showDetailsForm && <UpdateDetailsForm />}
        </div>
      </div>
    </PopupSkeleton>
  );
};

export default AuthPopup;
