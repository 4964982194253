import { DATASET_CONSTRAINTS } from "./dataset-constraints";
import { safeLog } from "./safe-log";

export default class Scaler {
  isLogScaled = false;

  constructor(isLogScaled: boolean = false) {
    this.isLogScaled = isLogScaled;
  }

  logMinMax(minMax: any) {
    return {
      min: safeLog(minMax.min),
      max: safeLog(minMax.max),
    };
  }

  scale(value: number, minMax: any) {
    if (this.isLogScaled) minMax = this.logMinMax(minMax);
    return (value - minMax.min) / (minMax.max - minMax.min);
  }

  noOfPeople(inp: number) {
    const minMax = DATASET_CONSTRAINTS.noOfPeople;
    return this.scale(inp, minMax);
  }

  householdConsumption(inp: number) {
    const minMax = DATASET_CONSTRAINTS.householdConsumption;
    return this.scale(inp, minMax);
  }

  evDistance(inp: number) {
    const minMax = DATASET_CONSTRAINTS.evDistance;
    return this.scale(inp, minMax);
  }

  pv(inp: number) {
    const minMax = DATASET_CONSTRAINTS.pv;
    return this.scale(inp, minMax);
  }

  battery(inp: number) {
    const minMax = DATASET_CONSTRAINTS.battery;
    return this.scale(inp, minMax);
  }

  haveHeatpump(inp: boolean) {
    const tInp = inp ? 1 : 0;
    const minMax = DATASET_CONSTRAINTS.haveHeatpump;
    return this.scale(tInp, minMax);
  }

  haveHeatingRod(inp: boolean) {
    const tInp = inp ? 1 : 0;
    const minMax = DATASET_CONSTRAINTS.haveHeatingRod;
    return this.scale(tInp, minMax);
  }

  heatpumpConsumption(inp: number) {
    const minMax = DATASET_CONSTRAINTS.heatpumpConsumption;
    return this.scale(inp, minMax);
  }

  householdThroughPV(inp: number) {
    const minMax = DATASET_CONSTRAINTS.householdThroughPV;
    return this.scale(inp, minMax);
  }

  evThroughPV(inp: number) {
    const minMax = DATASET_CONSTRAINTS.evThroughPV;
    return this.scale(inp, minMax);
  }

  heatpumpThroughPV(inp: number) {
    const minMax = DATASET_CONSTRAINTS.heatpumpThroughPV;
    return this.scale(inp, minMax);
  }

  heatShareHeatpump(inp: number) {
    const minMax = DATASET_CONSTRAINTS.heatShareHeatpump;
    return this.scale(inp, minMax);
  }

  heatShareKessel(inp: number) {
    const minMax = DATASET_CONSTRAINTS.heatShareKessel;
    return this.scale(inp, minMax);
  }

  heatpumpRequirement(inp: number) {
    const minMax = DATASET_CONSTRAINTS.heatPumpRequirement;
    return this.scale(inp, minMax);
  }

  heatingRodRequirement(inp: number) {
    const minMax = DATASET_CONSTRAINTS.heatingRodRequirement;
    return this.scale(inp, minMax);
  }
}
