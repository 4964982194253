import { useDispatch, useSelector } from "react-redux";

import { WebinarDB } from "src/db-types";

import { POPUP_KEYS } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";
import { getWebinars } from "src/redux/webinar/selector";

interface UseJoinTheWebinarReturnProps {
  isAnyWebinarInProgress: () => WebinarDB | undefined;
  joinWebinarHandler: () => void;
}

export const useJoinTheWebinar = (): UseJoinTheWebinarReturnProps => {
  const webinars: WebinarDB[] | [] = useSelector(getWebinars);
  const dispatch = useDispatch();

  const isAnyWebinarInProgress = (): WebinarDB | undefined => {
    const now = Date.now() / 1000; // Current time in seconds
    const durationInSec = 2 * 60 * 60; // Duration of 1 hour in seconds

    return webinars.find((web: WebinarDB) => {
      const getWebinarSecounds = new Date(web.start_time).getTime() / 1000;
      const webinarStartTime = getWebinarSecounds - 300; // 5 minutes earlier than the webinar start time
      const webinarEndTime = webinarStartTime + durationInSec;
      const isDateAfterNow = now >= webinarStartTime && now <= webinarEndTime;
      return isDateAfterNow;
    });
  };

  const joinWebinarHandler = (): void => {
    const onGoing = isAnyWebinarInProgress();
    if (onGoing) {
      window.open("https://us06web.zoom.us/w/89575040449");
      return;
    }
    const data = {
      title:
        "Please feel free to choose from our upcoming Events Your perfect start to your new energy system",
      heading: "SolarHub Solarwebinar",
      button_text: "REGISTER",
      extraContent: "",
    };
    dispatch(openPopup(POPUP_KEYS.webinar, data));
  };
  return { isAnyWebinarInProgress, joinWebinarHandler };
};
