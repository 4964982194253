import { createTheme } from "@mui/material";
import { responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "src/i18n/config";
import PopupRenderer from "src/popups/PopupRenderer";
import Routers from "src/router";
import { themeOptions } from "src/theme-overrides";

import {
  useGetAllUniqueAddressProjects,
  useInitIPRequest,
  useLastLog,
  useProfileChange,
  useSaveProject,
  useUpdateProject,
  useGetAllWebinars,
  useURLPath,
  useGetPartnerCityDetails,
  useIncrementAccessCount,
} from "./hooks";
import { useInitUser } from "./hooks/useInitUser";
import { useUserDetailsPopup } from "./hooks/useUserDetailsPopup";
import { Mixpanel, trackingEvents } from "./mix-panel";

import "antd/dist/antd.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "src/styles/global.css";

import useFetchArtifacts from "./hooks/useFetchArtifacts";
import { useRequestBakeModel } from "./hooks/apis";
import { useGetRentingOption } from "./hooks/useGetRentingOption";
import usePartnerSlug from "./hooks/usePatnerSlug";
import { useGetAllProductObjFiles } from "./hooks/useGetProductObjFIles";
import useCalculateMultiTenants from "./hooks/useCalculateMultiTenants";

let materialCustomTheme = createTheme(themeOptions);
materialCustomTheme = responsiveFontSizes(materialCustomTheme);

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  usePartnerSlug(navigate);
  useInitIPRequest();
  useInitUser();
  useUserDetailsPopup();
  useURLPath();
  useSaveProject();
  useUpdateProject();
  useIncrementAccessCount(location);
  useProfileChange();
  useLastLog();
  useGetAllUniqueAddressProjects();
  useGetAllWebinars();
  useGetPartnerCityDetails();
  useRequestBakeModel();
  useFetchArtifacts();
  useGetRentingOption();
  useGetAllProductObjFiles()
  useCalculateMultiTenants();
  // useQuoteBuildingTypeChange();
  // useCometChat();

  useEffect(() => {
    Mixpanel.track(trackingEvents.USER_COMES_ON_STEP1, {});
  }, []);

  return (
    <ThemeProvider theme={materialCustomTheme}>
      <Routers />
      <ToastContainer />
      <PopupRenderer />
    </ThemeProvider>
  );
};

export default App;
