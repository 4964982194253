import { ProductFilters } from "src/hooks";

import { RootState } from "../store";

import { ApplicationState, IpartnerCityData } from "./types";

export const getState = (state: RootState): ApplicationState => state.application;

export const getCurrentStep = (state: RootState): number => state.application.currentStep;
export const getPartnerCityData = (state: RootState): IpartnerCityData => state.application.partnerCity;
export const getPopupCurrentStep = (state: RootState): number => state.application.popupCurrentStep;
export const getQuoteFetching = (state: RootState): boolean => state.application.quoteFetching;
export const getPreviousInverterPref = (state: RootState): ProductFilters | null =>
  state.application.previousValues.inverterPreference;

export const getApiStates = (state: RootState): ApplicationState["apiStates"] =>
  state.application.apiStates;

export const getApplicaitonFilters = (state: RootState): ApplicationState["properties"] =>
  state.application.properties;

export const getLastAccessedProject = (state: RootState): ApplicationState["lastAccessedProject"] =>
  state.application.lastAccessedProject;
