import { clone, isNull } from "lodash";

import { UserState, actionCreators } from "src/redux/user";

export const setRantingData = (
  currentState: UserState,
  { payload }: ReturnType<typeof actionCreators.setUser>,
): UserState => {
  const newState = clone(currentState);
  let rantingData: any | null = null;

  if (!isNull(payload)) {
    rantingData = payload;
  }

  newState.rantingData = rantingData;
  return newState;
};
