import styled from "styled-components/macro";
export const ElectricityConsumptionWrapper: any = styled("div")`
  width: 100%;
  margin-bottom: 1rem;
  .main_container {
    background: #fafafa;
    border-radius: 12px;
    display: flex;
    margin-top: 5px;
    width: 100%;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
  }
  .text_first {
    font-family: Nunito;
    font-weight: bold;
    color: var(--blue);
    font-size: 16px;
  }
  .icon {
    color: #2d4764;
    cursor: pointer;
    margin-right: 6px;
  }
  .same_part {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    padding-top: 9px;
  }
  .round_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    padding: 4px 13px;
    width: auto;
    height: 27px;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    border-radius: 16px;
    @media (max-width: 400px) {
      font-size: 12px;
    }
  }
`;
