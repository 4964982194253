// import { LanguagesShortCodes } from "src/redux/language/utility/preferredLanguage";

// TODO : locale: LanguagesShortCodes
export function parseLocaleNumber(stringNumber: string, locale: any): number {
  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, "");
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, "");

  return parseFloat(
    stringNumber
      .replace(new RegExp("\\" + thousandSeparator, "g"), "")
      .replace(new RegExp("\\" + decimalSeparator), "."),
  );
}
