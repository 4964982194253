import styled from "styled-components/macro";

export const HorizontalCloseButtonWrapper = styled.div`
  cursor: pointer;
  margin-right: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 1rem;
  div {
    background: #2d4764dd;
    width: 1rem;
    height: 3px;
    border-radius: 3px;
  }
`;
