import styled from "styled-components";

export const MultiTenantWrapper = styled("div")`
  width: min(1142px, 90vw);
  height: 100%;
  position: relative;
  @media (max-width: 500px) {
    width: min(1142px, 100vw);
  }
`;

export const StepperWrapper = styled("div")`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* max-width: 80%; */
  width: 90%;
`;

export const MultiTenantContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 31px;
  margin-inline: auto;
  .uploadImageButton {
    width: auto;
    padding-inline: 20px;
    padding-top: 3px;
    height: 40px;
    background: #ffd75d;
    border-radius: 30px;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    color: #2d4764;
    cursor: pointer;
    position: sticky;
    bottom: 19px;
    @media (max-width: 500px) {
      bottom: 10px;
    }
  }
  .headers {
    display: flex;
    gap: 7px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 500px) {
      align-items: flex-start;
      width: 83%;
    }
    .head {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #2d4764;
    }
    .make_flex {
      display: flex;
      gap: 7px;
    }
    .paragraph {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #666666;
    }
  }
`;

export const TenantCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const ArrowContainer = styled.div`
  max-width: 10%;
  width: 40px;
  height: 40px;
`;

export const ArrowButton = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: black;
  font-size: 18px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const MultiTenantContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: 20px;
  @media (max-width: 500px) {
    margin-inline: 10px;
  }
`;
