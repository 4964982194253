import { clone } from "lodash";

import { actionCreators } from "../..";
import { WebinarState } from "../types";

export const setSelectedWebinarIsHybrid = (
  currentState: WebinarState,
  { payload }: ReturnType<typeof actionCreators.setSelectedWebinarIsHybrid>,
): WebinarState => {
  const newState = clone(currentState);
  newState.selectedWebinarIsHybrid = payload;
  return newState;
};
