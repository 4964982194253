import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/hooks";

import { ParameterStyles } from "src/shared/StyledComponents";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";

export const EnergyConsumptionPerEmployee: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { kwaPerEmployee, people } = useSelector(ProjectSelectors.getHousehold);

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      dispatch(
        ProjectActions.updateHousehold({
          kwaPerEmployee: e.target.valueAsNumber,
        }),
      );
    },
    [dispatch],
  );

  return (
    <>
      <div className="same_part">
        <div className="text_same">{t("Energy consumption per employee")}</div>
        <ParameterStyles.AllSubMainInput
          value={kwaPerEmployee}
          style={{ width: "61px" }}
          onChange={onInputChange}
        />
      </div>
      <div className="same_part">
        <div className="text_same">{t("Kwh consumption per employee")}</div>
        <ParameterStyles.AllSubMainInput
          value={kwaPerEmployee * people}
          style={{ width: "73px" }}
          disabled={true}
        />
      </div>
    </>
  );
};
