import axios from "axios";

import { AuthMethod } from "./popups";

// import { baseURL } from "./api/backendAPIEndpoints";

export const ApiAxiosClient = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  // baseURL,
});

ApiAxiosClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");

  let prepend = "JWT";
  const method = localStorage.getItem("authMode") as AuthMethod | null;
  if (method === AuthMethod.google) prepend = "Bearer";

  if (config.headers) {
    config.headers.Authorization = token ? `${prepend} ${token}` : "";
  }
  return config;
});
