import type { ChartArea } from "chart.js";

export function createGradient(
  ctx: CanvasRenderingContext2D,
  area: ChartArea,
  start: string,
  end: string,
): any {
  const colorStart = start;
  const colorEnd = end;

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}
