import { PrimaryButton } from "src/shared/StyledComponents";
import styled from "styled-components";

export const OfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 872px;
  padding: 10px 25px;
  overflow: hidden;

  .ant-progress-text {
    display: none !important;
  }
  .ant-progress-bg {
    background: linear-gradient(148.54deg, #ffe925 20.91%, #ffaa39 105.47%) !important;
  }
`;

export const HeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
`;

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 33px;
  align-items: center;
  width: 80%;
`;
export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
  border-top: 1px solid #fbda61;
  margin-top: 60px;
  padding-top: 10px;
`;

export const Address = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  gap: 10px;
`;

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  .ant-progress-circle {
    width: 48px !important;
    height: 48px !important;
  }
`;

export const ProgressContainer = styled.div`
  position: relative;
  height: 48px;
  width: 48px;
`;

export const OfferTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
`;

export const InsideProgress = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
`;

export const FeedbackTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: center;
  max-width: 450px;
`;

export const PowerText = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const OfferButton = styled.div`
  display: flex;
  gap: 5px;
`;

export const RefuseButton = styled(PrimaryButton)`
  padding-inline: 20px;
  background: #304864;
  color: white;
  border: none;
  margin-inline: 0;
`;

export const CancelButton = styled(PrimaryButton)`
  padding-inline: 10px;
  margin-inline: 0;
`;
