import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { UserSelectors } from "src/redux/selectors";

const usePartnerSlug = (navigate: any) => {
  const installerDetails = useSelector(UserSelectors?.getInstaller);
  const [query] = useSearchParams();
  const installerSlug = query.get("partner");

  useEffect(() => {
    if (installerDetails?.partnerSlug && !installerSlug) {
      const newPath = `${window.location.pathname}?partner=${installerDetails?.partnerSlug}`;
      navigate(newPath);
    }
  }, [installerDetails, window.location.pathname, navigate]);
};

export default usePartnerSlug;
