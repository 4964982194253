import { Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";

import { BackendApis } from "src/api";

import { InstallerDB } from "src/db-types";
import { useExploreInstallerAPI, useNearbyInstallerAPI } from "src/hooks/apis";
import { PrimaryButton } from "src/shared/StyledComponents";
import { generateSettings } from "src/utils";

import { useIsInstallerSelected } from "../../../../hooks/useIsInstallerSelected";
import InstallerCard from "../InstallerCard/InstallerCard";

import { FreeChoice } from "./components";
import * as S from "./NearbyInstallers.styles";

import { AppActions } from "src/redux";
import { ProjectSelectors } from "src/redux/selectors";

interface NearbyInstallerProps {
  onInstallerCancelRequest: (installer: InstallerDB) => void;
  onAcceptOffer: () => void;
  onRequestInstallerOffer: (installer: InstallerDB) => void;
  onFreeChoiceRequest: () => void;
}
export const NearbyInstallers: React.FC<NearbyInstallerProps> = (props: NearbyInstallerProps) => {
  const { onAcceptOffer, onRequestInstallerOffer, onFreeChoiceRequest } = props;
  const [query] = useSearchParams();
  const installerPartnerSlug = query.get("partner");
  const { fetchData: installerFetchData, response: installerResponse } = useExploreInstallerAPI();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const boxRef = useRef<HTMLDivElement>(null);

  const mapboxAddress = useSelector(ProjectSelectors.getAddress);
  const houseTopImages = useSelector(ProjectSelectors.getHouseTop).imageURLs;
  const houseBottomImages = useSelector(ProjectSelectors.getHouseBottom).imageURLs;

  const [nearbyInstallers, setNearbyInstallers] = useState<InstallerDB[]>([]);
  const [sliderSettings, setSliderSettings] = useState(generateSettings(500));

  const { isSelected: isInstallerSelected } = useIsInstallerSelected();
  const { error, fetchData, loading, response } = useNearbyInstallerAPI();
  const navigate = useNavigate();

  const handleResize = useCallback(() => {
    const newWidth = boxRef.current?.clientWidth ?? 500;
    setSliderSettings(generateSettings(newWidth - 120));
  }, []);

  const removeFromNearby = useCallback((id: string) => {
    setNearbyInstallers((p) => p.filter((p) => p._id !== id));
  }, []);

  const handleUploadRedirect = () => {
    let newPath = `${window.location.pathname}?upload=true`;
    if (installerPartnerSlug) {
      newPath = `${window.location.pathname}?partner=${installerPartnerSlug}&upload=true`;
    }
    navigate(newPath);
    dispatch(AppActions.setCurrentStep(2));
  };

  useEffect(() => {
    if (!error) return;

    toast.error(t("There was a problem loading nearby installers"));
  }, [error, t]);

  useEffect(() => {
    if (!response) return;

    const {
      data: { data: installers },
    } = response;
    setNearbyInstallers(installers);
  }, [response]);

  useEffect(() => {
    if (!installerPartnerSlug) {
      void fetchData({
        ...BackendApis.nearbyInstallers,
        data: mapboxAddress,
      });
    }
  }, [fetchData, mapboxAddress]);

  useEffect(() => {
    if (!boxRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });
    resizeObserver.observe(boxRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [handleResize]);

  useEffect(() => {
    if (installerPartnerSlug) {
      void installerFetchData({
        ...BackendApis.exploreInstaller,
        params: {
          searchTerm: installerPartnerSlug,
        },
      });
    }
  }, [installerFetchData, installerPartnerSlug]);

  useEffect(() => {
    if (!installerResponse) return;
    setNearbyInstallers(installerResponse?.data.data.docs);
  }, [installerResponse]);

  const noHouseImagesUploaded = houseBottomImages.length === 0 && houseTopImages.length === 0;

  return (
    <S.Container>
      <S.Heading>{t("Top recommended installers nearby you")}</S.Heading>
      <S.InstallerSlider ref={boxRef}>
        {loading && (
          <div className="vendors">
            <ClipLoader loading={true} size={20} />
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                marginLeft: "15px",
              }}
            >
              {t("We are searching for your local installation partner")}
            </Typography>
          </div>
        )}
        {!loading && (
          <Slider {...sliderSettings}>
            {!installerPartnerSlug && <FreeChoice onRequestBtnClick={onFreeChoiceRequest} />}
            {nearbyInstallers.map((installer) => (
              <InstallerCard
                key={installer._id}
                data={installer}
                isAlreadyRequested={isInstallerSelected(installer._id)}
                onAcceptBtnClick={onAcceptOffer}
                onCancelBtnClick={(installer) => {
                  removeFromNearby(installer._id);
                  // onInstallerCancelRequest(installer);
                }}
                onRequestBtnClick={onRequestInstallerOffer}
              />
            ))}
          </Slider>
        )}
      </S.InstallerSlider>
      {noHouseImagesUploaded && (
        <div className="makeItFlex">
          <PrimaryButton
            style={{
              padding: ".5rem 1rem",
              fontSize: 16,
            }}
            onClick={handleUploadRedirect}
          >
            {t("Upload image to request an installer")}
          </PrimaryButton>
        </div>
      )}
    </S.Container>
  );
};
