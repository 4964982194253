import React from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "src/hooks";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";

interface PendingAddressBarProps {
  addressText: string;
}
const PendingAddressBar: React.FC<PendingAddressBarProps> = (props: PendingAddressBarProps) => {
  const { addressText } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div
      className="adressFound"
      onClick={() => {
        const action = PopupActions.openPopup(POPUP_KEYS.welcome_address);
        dispatch(action);
      }}
    >
      {t("Project")} <span>{addressText}</span>
      {t("Message for pendind")}{" "}
    </div>
  );
};

export default PendingAddressBar;
