import { ProductWithPrice } from "src/db-types";

export const getCheapestProduct = (products: ProductWithPrice[]): ProductWithPrice | null => {
  if (products.length === 0) {
    console.error("No product provided to cheapest product function");
    return null;
  }

  let minPrice = products[0].buyInfo.totalPrice;
  let minPriceInd = 0;
  for (let i = 1; i < products.length; i++) {
    const currProdPrice = products[i].buyInfo.totalPrice;

    if (currProdPrice < minPrice) {
      minPrice = currProdPrice;
      minPriceInd = i;
    }
  }

  return products[minPriceInd];
};
