import styled from "styled-components/macro";

export const PrevButton = styled.button`
  border-radius: 50%;
  border: 3px solid var(--primary-color);
  padding: 10px 13px;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  @media only screen and (max-width: 500px) {
    padding: 7px;
    left: 12px;
  }
`;

export const NextButton = styled.button`
  border-radius: 50%;
  border: 3px solid var(--primary-color);
  padding: 10px 13px;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  @media only screen and (max-width: 500px) {
    right: 0px;
    padding: 7px;
  }
`;
