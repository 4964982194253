import { cloneDeep } from "lodash";

import { ReduxAction } from "../../common.types";
import { defaultProjectState } from "../initialState";
import { ProjectState } from "../types";

import * as handlers from "./handlers";

import { actions as ActionNames } from "src/redux/project";

export const projectReducer = (
  state: ProjectState = defaultProjectState,
  action: ReduxAction<any>,
): ProjectState => {
  switch (action.type) {
    case ActionNames.SET_QUOTE:
      return handlers.setQuote(state, action);
    case ActionNames.UPDATE_ENERGY_PRODUCTION_PARAMS:
      return handlers.updateEnergyProductionParams(state, action);
    case ActionNames.UPDATE_ENERGY:
      return handlers.updateEnergy(state, action);
    case ActionNames.ADD_WATER_HEATER:
      return handlers.addWaterHeater(state, action);
    case ActionNames.DELETE_WATER_HEATER:
      return handlers.deleteWaterHeater(state, action);
    case ActionNames.ADD_BATTERY:
      return handlers.addBattery(state, action);
    case ActionNames.ADD_BATTERY_PRODUCT:
      return handlers.addBatteryProduct(state, action);
    case ActionNames.UPDATE_BATTERY:
      return handlers.updateBattery(state, action);
    case ActionNames.DELETE_BATTERY:
      return handlers.deleteBattery(state, action);
    case ActionNames.ADD_WALLBOX:
      return handlers.addWallbox(state, action);
    case ActionNames.ADD_WALLBOX_PRODUCT:
      return handlers.addWallboxProduct(state, action);
    case ActionNames.UPDATE_WALLBOX:
      return handlers.updateWallbox(state, action);
    case ActionNames.DELETE_WALLBOX:
      return handlers.deleteWallbox(state, action);
    case ActionNames.ADD_CAR:
      return handlers.addCar(state, action);
    case ActionNames.UPDATE_CAR:
      return handlers.updateCar(state, action);
    case ActionNames.DELETE_CAR:
      return handlers.deleteCar(state, action);
    case ActionNames.ADD_HEATPUMP:
      return handlers.addHeatpump(state, action);
    case ActionNames.ADD_HEATPUMP_PRODUCT:
      return handlers.addHeatpumpProduct(state, action);
    case ActionNames.UPDATE_HEATPUMP:
      return handlers.updateHeatpump(state, action);
    case ActionNames.DELETE_HEATPUMP:
      return handlers.deleteHeatpump(state, action);
    case ActionNames.ADD_INVERTER:
      return handlers.addInverter(state, action);
    case ActionNames.UPDATE_INVERTER:
      return handlers.updateInverter(state, action);
    case ActionNames.DELETE_INVERTER:
      return handlers.deleteInverter(state, action);
    case ActionNames.ADD_EMS:
      return handlers.addEMS(state, action);
    case ActionNames.ADD_EMS_PRODUCT:
      return handlers.addEMSProduct(state, action);
    case ActionNames.UPDATE_EMS:
      return handlers.updateEMS(state, action);
    case ActionNames.DELETE_EMS:
      return handlers.deleteEMS(state, action);
    case ActionNames.ADD_SOLAR_PANEL:
      return handlers.addSolarPanel(state, action);
    case ActionNames.UPDATE_SOLAR_PANEL:
      return handlers.updateSolarPanel(state, action);
    case ActionNames.DELETE_SOLAR_PANEL:
      return handlers.deleteSolarPanel(state, action);
    case ActionNames.ADD_ADDITIONAL_SOFTWARE:
      return handlers.addAdditionalSoftware(state, action);
    case ActionNames.UPDATE_ADDITIONAL_SOFTWARE:
      return handlers.updateAdditionalSoftware(state, action);
    case ActionNames.DELETE_ADDITIONAL_SOFTWARE:
      return handlers.deleteAdditionalSoftware(state, action);
    case ActionNames.ADD_ADDITIONAL_HARDWARE:
      return handlers.addAdditionalHardware(state, action);
    case ActionNames.UPDATE_ADDITIONAL_HARDWARE:
      return handlers.updateAdditionalHardware(state, action);
    case ActionNames.DELETE_ADDITIONAL_HARDWARE:
      return handlers.deleteAdditionalHardware(state, action);
    case ActionNames.UPDATE_HOUSETOP_INFO:
      return handlers.updateHouseTop(state, action);
    case ActionNames.ADD_HOUSETOP_IMAGE:
      return handlers.addHouseTop(state, action);
    case ActionNames.UPDATE_HOUSEBOTTOM_INFO:
      return handlers.updateHouseBottom(state, action);
    case ActionNames.ADD_HOUSEBOTTOM_IMAGE:
      return handlers.addHouseBottom(state, action);
    case ActionNames.UPDATE_ELECTRIC_METER_INFO:
      return handlers.updateElectricMeter(state, action);
    case ActionNames.UPDATE_DRONEDATA_INFO:
      return handlers.updateThreeDModels(state, action);
    case ActionNames.ADD_ELECTRIC_METER_IMAGE:
      return handlers.addElectricMeter(state, action);
    case ActionNames.UPDATE_HOUSEHOLD:
      return handlers.updateHousehold(state, action);
    case ActionNames.UPDATE_FINANCIAL_DETAILS:
      return handlers.updateFinancialDetails(state, action);
    case ActionNames.UPDATE_VENDOR:
      return handlers.updateVendor(state, action);
    case ActionNames.ADD_SELECTED_VENDOR:
      return handlers.addSelectedVendor(state, action);
    case ActionNames.ADD_SUGGESTED_VENDOR:
      return handlers.addSuggestedVendor(state, action);
    case ActionNames.UPDATE_PAYMENT_INFO:
      return handlers.updatePaymentInfo(state, action);
    case ActionNames.UPDATE_CONSTANTS:
      return handlers.updateConstants(state, action);
    case ActionNames.UPDATE_WEB_CONSTANTS:
      return handlers.updateWebConstants(state, action);
    case ActionNames.UPDATE_MODEL:
      return handlers.updateModel(state, action);
    case ActionNames.UPDATE_OUTER_DETAILS:
      return handlers.updateOuterDetails(state, action);
    case ActionNames.UPDATE_DEAL:
      return handlers.updateDeal(state, action);
    case ActionNames.UPDATE_VERSION_AND_SHORT_ID:
      return handlers.updateVersionAndShortId(state, action);
    case ActionNames.ADD_SELECTED_INSTALLER:
      return handlers.addSelectedInstaller(state, action);
    case ActionNames.UPDATE_SELECTED_INSTALLER:
      return handlers.updateSelectedInstaller(state, action);
    case ActionNames.REMOVE_SELECTED_INSTALLER:
      return handlers.removeSelectedInstaller(state, action);
    case ActionNames.UPDATE_PROJECT:
      return handlers.updateProject(state, action);
    case ActionNames.UPDATE_BAKE_MODEL_ID:
      return handlers.updateBakeModelIds(state, action);
    case ActionNames.UPDATE_BAKE_MODEL_PROGRESS:
      return handlers.updateBakeModelProgress(state, action);
    case ActionNames.ADD_SELECTED_FILES:
      return handlers.addSelectedFiles(state, action);
    case ActionNames.UPDATE_QUOTE_QUANTITY:
      return handlers.updateModuleQuantity(state, action);
    case ActionNames.SET_ACCESS_COUNT:
      return handlers.setAccessCount(state, action);
    case ActionNames.UPDATE_INSTALLER_ID:
      return handlers.updateInstallerId(state, action);
    case ActionNames.UPDATE_THREE_D_MODELS:
      return handlers.updateThreeDModelsObj(state, action);
    case ActionNames.ADD_USER_INFO:
      return handlers.addUserInfo(state, action);
    case ActionNames.DELETE_SUBSIDY:
      return handlers.deleteSubsidy(state, action);
      case ActionNames.DELETE_MANUAL_SUBSIDY:
      return handlers.deleteManualSubsidy(state, action);
    case "RESET_REDUX":
      return cloneDeep(defaultProjectState);
    default:
      return state;
  }
};
