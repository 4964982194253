import styled from "styled-components/macro";

export const EVBoxWrapper = styled("div")`
  margin-block: 0.5rem;
  .car_tag {
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
  }
  .title_number {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #2d4764;
    width: 100%;
  }
  .close {
    width: 8px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8px;
    cursor: pointer;
    color: #2d4764;
  }
  width: 100%;
`;
