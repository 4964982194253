export const getNumber = (x) => parseFloat(x || 0);

export const getPercentage = (value, total) => {
  if (total === 0) return 0;
  return (value / total) * 100;
};

export const successiveGrowth = ({ initial, increaseRate, years }) => {
  const increaseRateDecimal = 1 + increaseRate / 100;
  const compoundingTillNow =
    (initial * (Math.pow(increaseRateDecimal, years) - 1)) / (increaseRateDecimal - 1);
  return Math.round(compoundingTillNow);
};

export const getFormattedDate = () => {
  // Get current date
  var currentDate = new Date();

  // Extract day, month, and year components
  var day = currentDate.getDate();
  var month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
  var year = currentDate.getFullYear();

  // Format day, month, and year with leading zeros if necessary
  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;
  year = year.toString().slice(-2); // Get the last two digits of the year

  // Concatenate the components in the desired format
  var formattedDate = day + "." + month + "." + year;

  return formattedDate;
};

export const getFormattedCustomDate = (customDate) => {
  // Get current date
  var currentDate = new Date(customDate);

  // Extract day, month, and year components
  var day = currentDate.getDate();
  var month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
  var year = currentDate.getFullYear();

  // Format day, month, and year with leading zeros if necessary
  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;
  year = year.toString().slice(-2); // Get the last two digits of the year

  // Concatenate the components in the desired format
  var formattedDate = day + "." + month + "." + year;

  console.log(customDate, formattedDate, "customDate");
  return formattedDate;
};

export const makeImgUrl = (file) => {
  return `https://solarhub-medias-staging.s3.us-east-1.amazonaws.com/${file.type}/${file["_id"]}/${file.fileName}`;
};

export const costDetails1 = (project) => {
  return `${project.buyInfo.quantity}  x € ${project.buyInfo.priceForSingle}`;
};
  