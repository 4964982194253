import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useIsComponentAdded } from "src/hooks";

import { HouseConsumptionImage } from "src/assets/svgs";
import { useNumberFormat } from "src/hooks/useNumberFormat";
import { ChartCard } from "src/shared/components";
import { ChartBody } from "src/shared/components/DonoughtChart/DonoughtChartStyles";

import { ConsumptionReviewWrapper, SameText } from "./ConsumptionReviewStyle";

import { ProjectSelectors } from "src/redux/selectors";

const ConsumptionReview: React.FC = () => {
  const { t } = useTranslation();

  const model = useSelector(ProjectSelectors.getModel);
  const { solarPanelProduction } = useSelector(ProjectSelectors.getEnergy);

  const { isWallboxAdded, areCarsAdded, isHeatpumpAdded } = useIsComponentAdded();

  const { formatNumber } = useNumberFormat();

  return (
    <ChartCard title={t("Home electricity")} isOpen={true}>
      <ChartBody style={{ paddingBottom: "0px" }}>
        <ConsumptionReviewWrapper>
          <div className="main-consumption">
            {/* <div className="makeItCenter">
              <div className="heading">{t("Home electricity")}</div>
            </div> */}
            <div className="makeFlex">
              <div className="firstContainer">
                <SameText color="#4D7E6D">{formatNumber(model.consumption)} kWh</SameText>
                <div className="smallText">{t("Total consumption")}</div>
              </div>
              <div className="firstContainer">
                <SameText>{formatNumber(solarPanelProduction)} kWh</SameText>
                <div className="smallText">{t("Generation")}</div>
              </div>
            </div>
            <div className="center">
              <HouseConsumptionImage style={{ paddingLeft: "22px" }} />
              <div className="mainContainer">
                <div className="firstContainer">
                  <SameText>{formatNumber(model.household.consumption)} kWh</SameText>
                  <div className="smallText">{t("Household consumption")}</div>
                </div>
                {(isWallboxAdded || areCarsAdded) && (
                  <div className="firstContainer">
                    <SameText color="#287E97">{formatNumber(model.ev.consumption)} kWh</SameText>
                    <div className="smallText">{t("E-mobility consumption")}</div>
                  </div>
                )}
                {(!!model.heatingRod.consumption || isHeatpumpAdded) && (
                  <div className="firstContainer">
                    <SameText color="#B5268D">
                      {formatNumber(model.heatingRod.consumption + model.heatpump.consumption)} kWh
                    </SameText>
                    <div className="smallText">{t("Heat consumption")}</div>
                  </div>
                )}
              </div>
              <div className="last_container">
                <div className="firstContainer">
                  <SameText color="#DF6569">{formatNumber(model.fromGrid)} kWh</SameText>
                  <div className="smallText">{t("Grid supply")}</div>
                </div>
                <div className="firstContainer">
                  <SameText color="#4D7E6D">{formatNumber(model.feedIn)} kWh</SameText>
                  <div className="smallText">{t("Feed in")}</div>
                </div>
              </div>
            </div>
          </div>
        </ConsumptionReviewWrapper>
      </ChartBody>
    </ChartCard>
  );
};

export default ConsumptionReview;
