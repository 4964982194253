import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import ShowImage from "./ShowImage";
import * as S from "./styles";
interface UploadedImageViewProps {
  imageURLs: string[];
  removeMediaHandler: (image: string) => void;
}
export const UploadedImageView: React.FC<UploadedImageViewProps> = (
  props: UploadedImageViewProps,
) => {
  const { imageURLs, removeMediaHandler } = props;

  const { t } = useTranslation();

  const deleteHandler = useCallback(
    (item: string) => {
      toast.success(t("Image removed successfully"), {
        toastId: "image-remove-toast",
        updateId: new Date().getTime(),
      });
      removeMediaHandler(item);
    },
    [removeMediaHandler, t],
  );

  return (
    <div>
      <S.MainText1>{t("Uploaded image")}</S.MainText1>
      <S.SubContainer11>
        {imageURLs.map((item: any) => {
          return (
            <ShowImage
              key={item}
              imageURL={item?.url || item}
              deleteHandler={() => deleteHandler(item)}
            />
          );
        })}
      </S.SubContainer11>
      <S.ColContain />
    </div>
  );
};
