import { t } from "i18next";
import React, {  useState } from "react";
import {  useSelector } from "react-redux";

import { isQuoteNotVerified } from "src/utils";

import { ImageBox, ConsumptionDetails } from "./components";

import { FlagSelectors, ProjectSelectors } from "src/redux/selectors";

const ImageView: React.FC = () => {
  const status = useSelector(ProjectSelectors.getQuoteStatus);
  const [selectedOption, setSelectedOption] = useState("HIDE");
  const [fluxMonth, setFluxMonth] = useState<number>(6);
  const screenshotMode = useSelector(FlagSelectors.getSolarScreenShotMode);

  return (
    <>
      <div className="image_section">
        <ImageBox is3DView={false} selectedOption={selectedOption} setFluxMonth={setFluxMonth} fluxMonth={fluxMonth} />
        {isQuoteNotVerified(status) && !screenshotMode && <div className="sample">{t("Sample")}</div>}
      </div>
      <ConsumptionDetails fluxMonth={fluxMonth} setFluxMonth={setFluxMonth} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
    </>
  );
};

export default ImageView;