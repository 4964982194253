export const ipInfo = "https://ipinfo.io/?token=7340f1287afe2a";

export const mapboxQuery = (query: string): string => {
  const token =
    "pk.eyJ1IjoicnVzaGlsLXBhdGVsIiwiYSI6ImNsczFrMGt2cjA5eWgyc21rN2l6aGV2MHYifQ.CQUKx8WJJrkVL3l3LnW2vg";
  return `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${token}&country=de&types=address&limit=5`;
};

// proxied through backend
export const googleMapsAutocompleteQuery = (query: string): string => {
  const encodedQuery = encodeURIComponent(query);
  return `${process.env.REACT_APP_BACKEND_URL}/api/quote/place/autocomplete/${encodedQuery}`;
}
