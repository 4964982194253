import { CardContainer, CardSubText, CardText, CardTitle } from "./OverviewCardStyles"

const OverviewCard = ({
  icon,
  title,
  text,
  subtext,
  isFixed = false,
  isGreen = false,
}: {
  icon?: any;
  title: string;
  text: string;
  subtext?: string;
  isFixed?: boolean;
  isGreen?: boolean;
}) => {
  return (
    <CardContainer isFixed={isFixed} isGreen={isGreen}>
      {icon && <img src={icon} alt="title" />}
      <CardTitle>{title}</CardTitle>
      <CardText>{text}</CardText>
      <CardSubText>{subtext}</CardSubText>
    </CardContainer>
  );
};

export default OverviewCard