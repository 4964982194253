import React from "react";

import { useIsComponentAdded } from "src/hooks";

import { ParameterStyles } from "src/shared/StyledComponents";

import { BatteryLine, CarsList, HeatpumpLine, YearlyConsumptionLine } from "./components";
import { ListSelectedProductWrapper } from "./ListSelectedProduct.styles";

export const ListSelectedProduct: React.FC = () => {
  const { isBatteryAdded, isBatteryNotAdded, isHeatpumpNotAdded, areCarsNotAdded } =
    useIsComponentAdded();

  if (isHeatpumpNotAdded && areCarsNotAdded && isBatteryNotAdded) {
    return <></>;
  }

  return (
    <ListSelectedProductWrapper>
      <HeatpumpLine />
      <CarsList />
      <YearlyConsumptionLine />
      {isBatteryAdded && (
        <>
          <ParameterStyles.SectionDivider />
          <BatteryLine />
        </>
      )}
    </ListSelectedProductWrapper>
  );
};
