import { EffectComposer } from "three/addons/postprocessing/EffectComposer.js";
import { RenderPass } from "three/addons/postprocessing/RenderPass.js";
import { GTAOPass } from "three/addons/postprocessing/GTAOPass.js";
import { UnrealBloomPass } from "three/addons/postprocessing/UnrealBloomPass.js";
import { OutputPass } from "three/addons/postprocessing/OutputPass.js";
import image from 'src/assets/holographic_ultra_dark_nighttime_arena_in_outer_s.jpg'
export default function Environment({
  THREE,
  scene,
  camera,
  controls,
  renderer,
  dirLight,
  gui,
  glbLoader,
  mixer,
  io,
  flow
}) {
  this.load = async () => {
    if (io.urlParams.has("nocabinet")) return;
    let cabinet = await glbLoader.loadAsync("./assets/cabinet.glb");
    cabinet.scene.traverse((e) => e.isMesh && (e.material.dithering = true));
    let cabscene = scene.clone(true);
    let gamecam = camera.clone();
    gamecam.position.set(0, 11.5, 0);
    cabinet.scene.rotation.y += Math.PI;
    camera.position.set(0, 10, 5);
    controls.target.set(0, 9.5, 0);
    cabinet.scene.scale.multiplyScalar(10);
    controls.update();
    const width = 1024;
    const height = 1024;
    gamecam.aspect = width / height;
    gamecam.updateProjectionMatrix();
    cabscene.add(cabinet.scene);

    await this.setupEnvironment(cabscene);

    const screenTarget = new THREE.WebGLRenderTarget(width, height, {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      format: THREE.RGBAFormat,
      // Additional parameters as needed
    });
    const postTarget = screenTarget.clone();
    let screen = cabscene.getObjectByName("gamescreen");
    screen.material = new THREE.MeshStandardMaterial({
      map: screenTarget.texture,
      emissiveMap: screenTarget.texture,
      emissive: "white",
      emissiveIntensity: 10,
      metalness: 0.8,
      roughness: 0.2,
    });
    let targetCenter = controls.target.clone();
    let targetLook = new THREE.Vector3();
    let postRenderer = new PostRenderer();

    let composer = new EffectComposer(renderer);

    const renderPass = new RenderPass(cabscene, camera);
    composer.addPass(renderPass);

    const params = {
      threshold: 0.23, //0,
      strength: 0.15, //1,
      radius: 0,
      exposure: 1,
    };

    const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      1.5,
      0.4,
      0.85
    );
    bloomPass.threshold = params.threshold;
    bloomPass.strength = params.strength;
    bloomPass.radius = params.radius;
    composer.addPass(bloomPass);

   flow.start(function* () {
      bloomPass.strength = 3;
      while (bloomPass.strength > params.strength) {
        bloomPass.strength = Math.max(
          params.strength,
          bloomPass.strength - 0.05
        );
        yield 0;
      }
      bloomPass.strength = params.strength;
    });

    const bloomFolder = gui.addFolder("bloom");

    bloomFolder.add(params, "threshold", 0.0, 1.0).onChange(function (value) {
      bloomPass.threshold = Number(value);
    });

    bloomFolder.add(params, "strength", 0.0, 3.0).onChange(function (value) {
      bloomPass.strength = Number(value);
    });

    bloomFolder
      .add(params, "radius", 0.0, 1.0)
      .step(0.01)
      .onChange(function (value) {
        bloomPass.radius = Number(value);
      });

    const wwidth = window.innerWidth;
    const wheight = window.innerHeight;
    const gtaoPass = new GTAOPass(cabscene, camera, wwidth, wheight);
    gtaoPass.output = GTAOPass.OUTPUT.Default;

    function onWindowResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;

      composer.setSize(width, height);
    }

    composer.addPass(gtaoPass);

    const aoParameters = {
      radius: 0.25,
      distanceExponent: 1,
      thickness: 1,
      scale: 1,
      samples: 16,
      distanceFallOff: 1,
      screenSpaceRadius: false,
    };
    const pdParameters = {
      lumaPhi: 10,
      depthPhi: 2,
      normalPhi: 3,
      radius: 4,
      radiusExponent: 1,
      rings: 2,
      samples: 16,
    };
    gtaoPass.updateGtaoMaterial(aoParameters);
    gtaoPass.updatePdMaterial(pdParameters);
    const gtaoFolder = gui.addFolder("gtao");
    gtaoFolder.add(gtaoPass, "blendIntensity").min(0).max(1).step(0.01);
    gtaoFolder
      .add(aoParameters, "radius")
      .min(0.01)
      .max(1)
      .step(0.01)
      .onChange(() => gtaoPass.updateGtaoMaterial(aoParameters));
    gtaoFolder
      .add(aoParameters, "distanceExponent")
      .min(1)
      .max(4)
      .step(0.01)
      .onChange(() => gtaoPass.updateGtaoMaterial(aoParameters));
    gtaoFolder
      .add(aoParameters, "thickness")
      .min(0.01)
      .max(10)
      .step(0.01)
      .onChange(() => gtaoPass.updateGtaoMaterial(aoParameters));
    gtaoFolder
      .add(aoParameters, "distanceFallOff")
      .min(0)
      .max(1)
      .step(0.01)
      .onChange(() => gtaoPass.updateGtaoMaterial(aoParameters));
    gtaoFolder
      .add(aoParameters, "scale")
      .min(0.01)
      .max(2.0)
      .step(0.01)
      .onChange(() => gtaoPass.updateGtaoMaterial(aoParameters));
    gtaoFolder
      .add(aoParameters, "samples")
      .min(2)
      .max(32)
      .step(1)
      .onChange(() => gtaoPass.updateGtaoMaterial(aoParameters));
    gtaoFolder
      .add(aoParameters, "screenSpaceRadius")
      .onChange(() => gtaoPass.updateGtaoMaterial(aoParameters));
    gtaoFolder
      .add(pdParameters, "lumaPhi")
      .min(0)
      .max(20)
      .step(0.01)
      .onChange(() => gtaoPass.updatePdMaterial(pdParameters));
    gtaoFolder
      .add(pdParameters, "depthPhi")
      .min(0.01)
      .max(20)
      .step(0.01)
      .onChange(() => gtaoPass.updatePdMaterial(pdParameters));
    gtaoFolder
      .add(pdParameters, "normalPhi")
      .min(0.01)
      .max(20)
      .step(0.01)
      .onChange(() => gtaoPass.updatePdMaterial(pdParameters));
    gtaoFolder
      .add(pdParameters, "radius")
      .min(0)
      .max(32)
      .step(1)
      .onChange(() => gtaoPass.updatePdMaterial(pdParameters));
    gtaoFolder
      .add(pdParameters, "radiusExponent")
      .min(0.1)
      .max(4)
      .step(0.1)
      .onChange(() => gtaoPass.updatePdMaterial(pdParameters));
    gtaoFolder
      .add(pdParameters, "rings")
      .min(1)
      .max(16)
      .step(0.125)
      .onChange(() => gtaoPass.updatePdMaterial(pdParameters));
    gtaoFolder
      .add(pdParameters, "samples")
      .min(2)
      .max(32)
      .step(1)
      .onChange(() => gtaoPass.updatePdMaterial(pdParameters));

    window.addEventListener("resize", onWindowResize);

    gui
      .add(gtaoPass, "output", {
        Default: GTAOPass.OUTPUT.Default,
        Diffuse: GTAOPass.OUTPUT.Diffuse,
        "AO Only": GTAOPass.OUTPUT.AO,
        "AO Only + Denoise": GTAOPass.OUTPUT.Denoise,
        Depth: GTAOPass.OUTPUT.Depth,
        Normal: GTAOPass.OUTPUT.Normal,
      })
      .onChange(function (value) {
        gtaoPass.output = value;
      });

    const outputPass = new OutputPass();

    composer.addPass(outputPass);

    io.render = () => {
      targetLook.copy(targetCenter);
      targetLook.x += io.mouseNDC.x * 1;
      targetLook.z += (io.mouseNDC.y - 1.25) * 1;
      controls.target.lerp(targetLook, 0.03);

      //renderer.setRenderTarget(screenTarget);
      //renderer.render(scene, gamecam);
      renderer.setRenderTarget(postTarget);
      renderer.render(scene, gamecam);
      let saveClear = renderer.autoClear;
      renderer.autoClear = false;
      postRenderer.renderPost(renderer, postTarget, screenTarget);
      renderer.autoClear = true;

      //renderer.render(cabscene, camera);
      composer.render(cabscene, camera);
    };
  };

function PostRenderer() {
  const copyShader = {
    vertexShader: `
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform sampler2D uTexture;
        varying vec2 vUv;
        const float abberation = .00025;
        vec2 offr=vec2(abberation,0.);
        vec2 offg=vec2(-abberation,0.);
        vec2 offb=vec2(0.,abberation);
        void main() {
            vec4 sr = texture2D(uTexture, vUv+offr);
            vec4 sg = texture2D(uTexture, vUv+offg);
            vec4 sb = texture2D(uTexture, vUv+offb);
            gl_FragColor = vec4(sr.r,sg.g,sb.b,.01);
            //gl_FragColor.rgb *= ((sin(vUv.y*500.)+1.)*.5*.75)+.25;
            gl_FragColor.rgb *= ((sin(vUv.x*2000.)+1.)*.5*.75)+.25;
            gl_FragColor.rgb *= ((sin(vUv.y*2000.)+1.)*.5*.75)+.25;
        }
    `,
    uniforms: {
      uTexture: {
        value: null,
      },
    },
    transparent: true,
    depthTest: false,
    depthWrite: false,
  };
  const scene = new THREE.Scene();
  const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
  const geometry = new THREE.PlaneGeometry(2, 2);
  const material = new THREE.ShaderMaterial({
    vertexShader: copyShader.vertexShader,
    fragmentShader: copyShader.fragmentShader,
    uniforms: THREE.UniformsUtils.clone(copyShader.uniforms),
  });
  const quad = new THREE.Mesh(geometry, material);
  scene.add(quad);
  this.renderPost = (renderer, sourceRenderTarget, destinationRenderTarget) => {
    quad.material.uniforms.uTexture.value = sourceRenderTarget.texture;
    renderer.setRenderTarget(destinationRenderTarget);
    renderer.render(scene, camera);
    renderer.setRenderTarget(null);
  };

}
this.setupEnvironment = async (scene) => {
  let txname = `./assets/holographic_ultra_dark_nighttime_arena_in_outer_s.jpg`;

  let textureLoader = new THREE.TextureLoader();
  let texture = await textureLoader.loadAsync(image);
  /*
	let hdrjpgLoader = new HDRJPGLoader();
	let quadrenderer = await hdrjpgLoader.loadAsync("./assets/quarry_cloudy_4k.jpg")
	let texture = quadrenderer.renderTarget.texture;		
	texture.mapping = THREE.EquirectangularReflectionMapping;
	texture.needsUpdate = true;
*/

  let pmremGenerator = new THREE.PMREMGenerator(renderer);
  pmremGenerator.compileEquirectangularShader();

  let equirect = pmremGenerator.fromEquirectangular(texture).texture;
  scene.environment = equirect;
  pmremGenerator.dispose();

  /*
let sphereGeom = new THREE.SphereGeometry(300.);
let verts = sphereGeom.attributes.position.array
for(var i=0;i<verts.length;i+=3){
  if(verts[i+1]<-100)
      verts[i+1]=-100;
}
sphereGeom.computeVertexNormals();
let sphere = new THREE.Mesh(sphereGeom,new THREE.MeshBasicMaterial({
    map: texture,
    side: THREE.BackSide
}))

sphere.position.y=100.0;//100-15;
scene.add(sphere)
*/
};

}