import { useEffect } from "react";
import { useSelector } from "react-redux";

import { BackendApis } from "src/api";

import { convertUserFromDB } from "src/db-converters";

import { useRegisterIntoWebinar } from "./apis";

import { AppActions, PopupActions, UserActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";
import { UserAuthStatus } from "src/redux/user";
import { getInstaller, getUser } from "src/redux/user/selectors";
import { getSelectedWebinarId, getSelectedWebinarIdHybrid } from "src/redux/webinar/selector";

import { useAppDispatch, useURLData } from ".";
import { getPartnerCityData } from "src/redux/application/selector";
import { getProject } from "src/redux/project/selectors";

const DUMMY_PASS = "dummypass";

interface IUserRegisterWebinarReturnType {
  registerForWebinar: (email?: string, password?: string) => void;
  loading: boolean;
}

export const useRegisterIntoWbinar = (): IUserRegisterWebinarReturnType => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const selectedWebinarId: number | null = useSelector(getSelectedWebinarId);
  const selectedWebinarIsHybrid = useSelector(getSelectedWebinarIdHybrid);
  const { fetchData, loading, response, error } = useRegisterIntoWebinar();
  const { isOnDefault } = useURLData();
  const installerParter = useSelector(getInstaller)
  const getPartnerCity = useSelector(getPartnerCityData)
  const project = useSelector(getProject);

  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);

  useEffect(() => {
    if (!response) return;

    const {
      data: {
        data: { user },
      },
    } = response;
    const userState = convertUserFromDB(user.user);

    localStorage.setItem("token", user.token);
    dispatch(UserActions.setUser(userState));
    dispatch(UserActions.setUserAuthStatus(UserAuthStatus.loggedIn));

    dispatch(PopupActions.closePopup(POPUP_KEYS.auth));
    const data = {
      title:
        "Nice, your registration was succesful, we´re looking forward to the webinar with you! We have send you a confirmation via mail so you can add the webinar to your calendar.",
      heading: "SolarHub Solarwebinar",
      button_text: "JOIN",
      extraContent:
        "To optimize your sustainable energy setup some information from your side is helpful",
    };
    dispatch(openPopup(POPUP_KEYS.webinar, data));

    if (isOnDefault) {
      dispatch(
        AppActions.updateAppState({
          saveProjectRemaining: true,
        }),
      );
    }
  }, [dispatch, isOnDefault, response]);

  const registerForWebinar = (email?: string, password: string = DUMMY_PASS): void => {
    if (!selectedWebinarId) return;
    const emailAddress = user ? user.email : email;
    if (!emailAddress) return;

    void fetchData({
      ...BackendApis.signupInWebinar,
      data: {
        address: user ? user?.address : "dummny address",
        email: emailAddress,
        firstName: user ? user?.firstName : "dummny firstName",
        lastName: user ? user?.lastName : "dummny lastName",
        mobile: user ? user?.phoneNumber : "dummny phoneNumber",
        password,
        isLogin: password !== DUMMY_PASS,
        isHybrid: selectedWebinarIsHybrid,
        webinarId: selectedWebinarId,
        white_label_lead_tag: installerParter ? true : false,
        gemeindeschlussel: project.quote.Gemeindeschluessel8 || getPartnerCity.Gemeindeschluessel8
      },
    });
  };

  return { registerForWebinar, loading };
};
