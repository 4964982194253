import { clone } from "lodash";

import { actionCreators } from "../..";
import { WebinarState } from "../types";

export const setWebinarsDate = (
  currentState: WebinarState,
  { payload }: ReturnType<typeof actionCreators.setWebinarDate>,
): WebinarState => {
  const newState = clone(currentState);
  newState.selectedWebinarDateTime = payload;
  return newState;
};
