import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ApplicationStep3 } from "../../pages/ApplicationPage/components/ApplicationStep3/ApplicationStep3";
import { getPopupCurrentStep } from "../../redux/application/selector";
import PopupSkeleton from "../../shared/components/PopupSkeleton/popupSkeleton";

import PopupStepper from "./PopupStepper/PopupStepper";
import {
  Step3Container,
  StepperWrapper,
  WebinarProjectDetailsWrapper,
} from "./WebinarProjectDetailsStyles";

import { ReduxAction } from "src/redux/common.types";
import { POPUP_KEYS } from "src/redux/popups";
import { closePopup } from "src/redux/popups/action/action.creators";

export const popupStepperHeading = ["Location provided", "Upload your images"];


export const WebinarProjectDetails: React.FC = () => {
  const { t } = useTranslation();
  const currentStep = useSelector(getPopupCurrentStep);
  const [currentStepComponent, setcurrentStepComponent]: any = useState(null);
  const dispatch = useDispatch();

  const handleClose = useCallback(
    (): ReduxAction<any> => dispatch(closePopup(POPUP_KEYS.webinar_project)),
    [dispatch],
  );

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setcurrentStepComponent(
          <Step3Container>
            <div className="headers">
              <div className="make_flex">
                <div className="head">{t("Upload your images")}</div>
              </div>
              <div className="paragraph">
                {t(
                  "The more information we have upfront the easier it is for us to plan a detailed version of your roof.",
                )}
              </div>
            </div>
            <ApplicationStep3 isFromWebinarPopup />
            <div className="uploadImageButton" onClick={handleClose}>
              {t("open project")}
            </div>
          </Step3Container>,
        );
        break;
      default:
        break;
    }
  }, [currentStep, handleClose, t]);

  return (
    <PopupSkeleton handleClose={handleClose} open={true} styless={{ position: "relative" }}>
      <WebinarProjectDetailsWrapper>
        <PopupStepper popupStepperHeading={popupStepperHeading} />
        <StepperWrapper>{currentStepComponent}</StepperWrapper>
      </WebinarProjectDetailsWrapper>
    </PopupSkeleton>
  );
};

export default WebinarProjectDetails;
