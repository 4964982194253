import { clone } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateVersionAndShortId = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateVersionAndShortId>,
): ProjectState => {
  const newState = clone(currentState);

  newState.shortId = payload.shortId;
  newState.version = payload.version;
  return newState;
};
