import { Grid, IconButton } from "@mui/material";
import { Slider } from "antd";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppSelector, useNumberFormat } from "src/hooks";

import { getAverageCapacity } from "src/helpers";
import { Tab } from "src/shared/components/TabBar/TabBar";
import { ParameterStyles } from "src/shared/StyledComponents";

import Shaddy_Sunny from "../../../../../../../../../../assets/chartTabs/Shaddy_Sunny.png";
import * as S from "../ConsumptionDetails/ConsumptionDetails.styles";

import { RadiationContainer, SelectFeedInWrapper } from "./ShowRadiation.styles";

import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";
import { getSolarAPIData } from "src/redux/solarAPI/selector";

const TAB_KEYS = {
  HIDE: "HIDE",
  ANNUAL: "ANNUAL",
  MONTHLY: "MONTHLY",
};

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

interface ConsumptionDetailsProps {
  selectedOption: string;
  setSelectedOption: (key: string) => void;
  setFluxMonth: (key: number) => void;
  fluxMonth: number;
}

const ShowRadiation: React.FC<ConsumptionDetailsProps> = ({
  selectedOption,
  setSelectedOption,
  fluxMonth,
  setFluxMonth,
}) => {
  const { t } = useTranslation();
  const { formatNumber } = useNumberFormat();
  const solarAPIData = useSelector(getSolarAPIData);
  const energy = useSelector(ProjectSelectors.getEnergy);
  const solarPanelProduct = useSelector(ProjectSelectors.getSolarPanel);
  const filteredSolarPanels = useAppSelector(ProductSelectors.getFilteredSolarPanels);
  const capacity = getAverageCapacity(filteredSolarPanels);

  const tabs: Tab[] = useMemo(() => {
    return [
      {
        key: TAB_KEYS.HIDE,
        label: t("HIDE"),
      },
      {
        key: TAB_KEYS.ANNUAL,
        label: t("ANNUAL"),
      },
      {
        key: TAB_KEYS.MONTHLY,
        label: t("MONTHLY"),
      },
    ];
  }, [t]);

  const headerItemClickHandler = useCallback(
    (key: string) => {
      setSelectedOption(key);
    },
    [setSelectedOption],
  );

  const yearlySavings = useMemo(() => {
    if (solarAPIData && energy) {
      return Math.round(
        (energy.solarPanelProduction)
      );
    }
    return 0;
  }, [energy, solarAPIData]);

  let systemCapacity = (capacity * solarPanelProduct.quantity) / 1000;
  if (solarPanelProduct?.item) {
    systemCapacity = (Number(solarPanelProduct?.item.capacity) * solarPanelProduct.quantity) / 1000;
  }

  return (
    <RadiationContainer>
      <p className="radiation_text">{t("Show radiation")}</p>
      <SelectFeedInWrapper>
        <div className="main_class">
          {tabs.map((tab) => {
            return (
              <div
                key={tab.key}
                className={selectedOption === tab.key ? "same_tab seleted" : "same_tab"}
                onClick={() => headerItemClickHandler(tab.key)}
              >
                {tab.label}
              </div>
            );
          })}
        </div>
      </SelectFeedInWrapper>
      {selectedOption === TAB_KEYS.ANNUAL && (
        <div className="shaddy_sunny">
          <div className="graph">
            <img src={Shaddy_Sunny} alt="" />
            <div className="shaddy_text">
              <p>{t("Shady")}</p>
              <p>{t("Sunny")}</p>
            </div>
          </div>
        </div>
      )}
      {selectedOption === TAB_KEYS.MONTHLY && (
          <ParameterStyles.Line hasSlider style={{width: "95%"}}>
          <ParameterStyles.Label hasSlider>{t("Select a month")}</ParameterStyles.Label>
          <ParameterStyles.Input>
          <Slider
            value={fluxMonth}
            min={0}
            max={11}
            aria-label="Small"
            tipFormatter={(value: any) => `${monthNames[value]}`}
            onChange={(value: number) => setFluxMonth(value)}
          />
          </ParameterStyles.Input>
        </ParameterStyles.Line>
      )}
       <Grid
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="flex-start"
          padding="0 17px 0 0"
          gap={"5px"}
        >
      <Grid
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        display="flex"
      >
        <S.Text>{t("Annual sunshine")}</S.Text>
        <S.Text>
          <div className="consumptionUnitData">
            {formatNumber(solarAPIData?.solarPotential?.maxSunshineHoursPerYear)}{" "}
            <span className="consumptionUnit">h</span>
          </div>
        </S.Text>
      </Grid>
      <Grid
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        display="flex"
      >
        <S.Text>{t("Roof area")}</S.Text>
        <S.Text>
          <div className="consumptionUnitData">
            {formatNumber(solarAPIData?.solarPotential?.maxArrayAreaMeters2)}{" "}
            <span className="consumptionUnit">m²</span>
          </div>
        </S.Text>
      </Grid>
      <Grid
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        display="flex"
      >
        <S.Text>{t("CO2-Savings")}</S.Text>
        <S.Text>
          <div className="consumptionUnitData">
            {formatNumber( (systemCapacity) * 0.33)} <span className="consumptionUnit">tCo2</span>
          </div>
        </S.Text>
      </Grid>
      <Grid
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        display="flex"
      >
        <S.Text>{t("Yearly energy production")}</S.Text>
        <S.Text>
          <div className="consumptionUnitData">
            {formatNumber(yearlySavings)} <span className="consumptionUnit">kWh</span>
          </div>
        </S.Text>
      </Grid>
      </Grid>
    </RadiationContainer>
  );
};

export default ShowRadiation;
