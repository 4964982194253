let loadInfo = document.createElement("span");
Object.assign(loadInfo.style, {
  position: "absolute",
  color: "white",
  zIndex: 6,
  left: "0px",
  top: "0px",
  fontSize: "11pt",
  fontFamily: `Arial, sans-serif`,
});
document.body.appendChild(loadInfo);
let loadText = "loading";
let loadFrame = 0;
// let loadSpinner = setInterval(
//   () => (loadInfo.innerText = loadText + "...".slice(3 - (loadFrame++ % 4))),
//   250,
// );

let showLoaderProgress = (THREE, totalFiles = 0) => {
  let loadDone = () => {
    loadInfo && document.body.removeChild(loadInfo);
    loadInfo = null;
    clearInterval(loadSpinner);
  };
  if (totalFiles == 0) {
    loadDone();
  }
  let url;
  let currentItemsLoaded;
  let loadFn = (cururl, itemsLoaded, itemsTotal) => {
    if (!currentItemsLoaded) {
      totalFiles += itemsLoaded;
      currentItemsLoaded = itemsLoaded;
    }
    loadText = "" + (((itemsLoaded * 100) / totalFiles) | 0) + "%";
    if (itemsLoaded == totalFiles) loadDone();
  };
  THREE.DefaultLoadingManager.onStart = loadFn;
  THREE.DefaultLoadingManager.onProgress = loadFn;
  THREE.DefaultLoadingManager.onError = (e)=>
    (loadInfo&&(loadInfo.innerText = 'Error loading file:' + e))||alert('Error loading file:' + e)
};
export default showLoaderProgress;
