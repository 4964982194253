import { cloneDeep } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { BackendApis, ThirdPartyApis } from "src/api";

import { convertProjectDBToState } from "src/db-converters";
import { convertQuoteFromDB } from "src/db-converters/quote";
import { AUTH_MODES } from "src/popups";
import { transformForURL, fromURLToMapboxQuery, parseReverseGeoGoogle } from "src/utils";

import { useGetProjectApi, useGoogleMapsAutocompleteAPI, usePartnerCityQuoteUpdateApi, useQuoteApi } from "./apis";
import { useURLData } from "./useURLData";

import { AppActions, PopupActions, ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { defaultProjectState } from "src/redux/project/initialState";
import { getQuote } from "src/redux/project/selectors";
import { ProjectSelectors } from "src/redux/selectors";

import { useAppDispatch, useAppSelector } from ".";

export const useGetAndSetQuote = (): { getAndSetQuote: (addr: string) => void } => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const completeAddr = useSelector(ProjectSelectors.getAddress).complete;
  const quote: any = useSelector(getQuote);
  const url = location.pathname;
  const hasData = url.includes("default") || url.includes("saved");
  const { t } = useTranslation();
  const { fetchData: updateLastVisit } = usePartnerCityQuoteUpdateApi();

  const [inProcess, setInProcess] = useState(false);

  const {
    error: googleMapsAutoCompleteError,
    fetchData: googleMapsAutoCompleteFetchData,
    response: googleMapsAutoCompleteResponse,
  } = useGoogleMapsAutocompleteAPI();

  const { error: quoteError, fetchData: quoteFetchData, response: quoteResponse } = useQuoteApi();

  useEffect(() => {
    if (!googleMapsAutoCompleteError && !quoteError) return;

    setInProcess(false);
    toast.error(t("Cannot retrieve quote"));
  }, [googleMapsAutoCompleteError, quoteError]);

  useEffect(() => {

    const asyncQuoteRequest = async ({placeId} : {placeId: string}) => {
      const mapboxAddr = await parseReverseGeoGoogle(predictions[0].place_id);
      void quoteFetchData({
        ...BackendApis.quote,
        data: {
          mapboxAddress: mapboxAddr,
        },
      });
    }

    if (!googleMapsAutoCompleteResponse) return;

    const {
      data: { predictions },
    } = googleMapsAutoCompleteResponse.data;

    if (predictions.length === 0) return;
    let add: any = decodeURIComponent(url.split("/")[url.split("/").length - 1]);
    add = add.split("-");
    add.shift();
    add = add.join(".*");
    add = add.replace(" ", ".*");
    
    if (add.includes("Street")) {
      void quoteFetchData({
        ...BackendApis.quote,
        data: {
          mapboxAddress: {complete: add},
        },
      });
    } else if (predictions[0].place_id) {
      asyncQuoteRequest({placeId: predictions[0].place_id});
    } else {
      toast(t("Cannot retrieve quote"));
    }

  }, [googleMapsAutoCompleteResponse, quoteFetchData, url]);

  useEffect(() => {
    if (quoteId === "" && !hasData) {
      void quoteFetchData({
        ...BackendApis.quote,
        data: {
          mapboxAddress: quote.mapboxAddress,
        },
      });
    }
  }, [completeAddr, quoteFetchData]);

  useEffect(() => {
    if (!quoteResponse) return;

    const {
      data: { data: quoteDB },
    } = quoteResponse;

    setInProcess(false);

    if (!quoteDB) {
      toast.error(t("Cannot retrieve quote"));
      return;
    }

    const quoteState = convertQuoteFromDB(quoteDB);

    void updateLastVisit({
      ...BackendApis.updateQuoteCount,
      data: {
        accessCount: quoteState?.accessCount,
        id: quoteState.id,
        lastVisitedDate: quoteState?.lastVisitedDate,
      },
    });

    dispatch(
      ProjectActions.updateOuterDetails({
        shortId: -1,
        version: -1,
        uid: "",
        id: "project123",
      }),
    );
    dispatch(
      ProjectActions.updateProject({
        ...cloneDeep(defaultProjectState),
        quote: quoteState,
        quoteId: hasData ? quoteState.id : "",
      }),
    );
    // dispatch(ProjectActions.setQuote(quoteState));
    dispatch(AppActions.updateInverterPreviousPreference(null));
    dispatch(
      AppActions.updateAppState({
        shouldSaveLastLog: true,
        actions: {
          updateSolarQtyByProfile: true,
        },
      }),
    );
  }, [dispatch, quoteResponse]);

  useEffect(() => {
    dispatch(AppActions.setQuoteFetching(inProcess));
  }, [dispatch, inProcess]);

  const getAndSetQuote = useCallback(
    (transformedAddr: string) => {
      setInProcess(true);
      void googleMapsAutoCompleteFetchData({
        url: ThirdPartyApis.googleMapsAutocompleteQuery(transformedAddr),
        method: "GET",
      });
    },
    [googleMapsAutoCompleteFetchData],
  );

  return {
    getAndSetQuote,
  };
};

export const useGetAndSetProject = (): {
  getAndSetProject: (shortId: number, version: number) => void;
} => {
  const { t } = useTranslation();
  const { fetchData, loading, response } = useGetProjectApi();
  const dispatch = useDispatch();

  const getAndSetProject = useCallback(
    (shortId: number, version: number): void => {
      void fetchData({
        ...BackendApis.getProject({ shortId, version }),
      });
    },
    [fetchData],
  );

  useEffect(() => {
    dispatch(
      AppActions.updateAppState({
        isProjectLoading: loading,
      }),
    );
  }, [dispatch, loading]);

  useEffect(() => {
    if (!response) return;

    const {
      data: { data: projectDB },
    } = response;

    if (projectDB.length === 0) {
      toast.error(t("There was error in loading the project"));
      return;
    }
    
    const projectState = convertProjectDBToState(projectDB[0]);

    dispatch(ProjectActions.setAccessCount(2));
    dispatch(ProjectActions.updateProject(projectState));
    dispatch(
      AppActions.updateAppState({
        shouldSaveLastLog: true,
      }),
    );
  }, [dispatch, response, t]);

  return { getAndSetProject };
};

export const useURLPath = (): void => {
  const dispatch = useAppDispatch();

  const { isOnDefault, defaultURLData, isOnSaved, savedURLData, isResetPass } = useURLData();

  const { getAndSetQuote } = useGetAndSetQuote();
  const { getAndSetProject } = useGetAndSetProject();

  const currentAddr = useAppSelector(ProjectSelectors.getAddress).complete;
  const { shortId, version } = useAppSelector(ProjectSelectors.getProject);

  useEffect(() => {
    if (!isOnDefault) return;

    const quoteAddrTransformed = transformForURL(currentAddr);
    const urlAddr = decodeURI(defaultURLData.addr);

    const shouldLoadQuote = quoteAddrTransformed !== urlAddr;

    if (shouldLoadQuote) {
      const query = fromURLToMapboxQuery(decodeURI(defaultURLData.addr)); 

      getAndSetQuote(query);
    }
  }, [currentAddr, defaultURLData.addr, getAndSetQuote, isOnDefault]);

  useEffect(() => {
    if (!isOnSaved) return;

    const notSameShortID = shortId !== savedURLData.shortId;
    const notSameVersion = version !== savedURLData.version;

    const shouldLoadProject = notSameShortID || notSameVersion;

    if (shouldLoadProject) {
      getAndSetProject(savedURLData.shortId, savedURLData.version);
    }
  }, [getAndSetProject, isOnSaved, savedURLData.shortId, savedURLData.version, shortId, version]);

  useEffect(() => {
    if (isResetPass) {
      dispatch(
        PopupActions.openPopup(POPUP_KEYS.auth, {
          authMode: AUTH_MODES.reset_password,
        }),
      );
    }
  }, [dispatch, isResetPass]);
};
