import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Input, InputNumber, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import profile_pics from "src/assets/profile_pic/profile_pics.png";
import { PopupSkeleton } from "src/shared/components";
import SolarHubLogo from "src/assets/logos/SolarhubLogo.svg";
import * as S from "./SubSidy.styles";
import { useWindowDimensions } from "src/hooks";
import { PrimaryButton, SecondaryButton } from "src/shared/StyledComponents";
import { getQuote } from "src/redux/project/selectors";
import axios from "axios";
import { PopupActions, UserActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { closePopup, openPopup } from "src/redux/popups/action/action.creators";
import { updateProject } from "src/redux/project/action/action.creators";
import { ProjectSelectors, UserSelectors } from "src/redux/selectors";
import { string } from "prop-types";

export const SubSidy: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const project = useSelector(ProjectSelectors.getProject);

  const [selectedSubsidies, setSelectedSubsidies] = React.useState<string[]>(
    project?.subsidy?.map((sub: any) => sub._id) || [],
  );
  const [manualSubsidy, setManualSubsidy] = React.useState<any>(
    project?.manualSubsidyDetails || {},
  );
  const [isManualSubsidy, setIsManualSubsidy] = React.useState<boolean>(false);
  const [subsidyOptions, setSubsidyOptions] = React.useState<{ label: string; value: any }[]>([]);
  const quote = useSelector(getQuote);
  const subsidyOptionsResponse = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchSubsidyOptions();
  }, [quote.mapboxAddress.city]);

  const fetchSubsidyOptions = useCallback(async () => {
    if (quote?.mapboxAddress?.city) {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/subsidy/search?searchTerm=${quote.mapboxAddress.city}`,
      );
      subsidyOptionsResponse.current = response.data.data.docs;
      setSubsidyOptions(
        response.data.data.docs.map((item: any) => ({ value: item._id, label: item.name })),
      );
    }
  }, [quote.mapboxAddress.city]);

  const handleSubsidyChange = useCallback((value) => {
    setSelectedSubsidies(value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (isManualSubsidy) {
      dispatch(updateProject({ ...project, manualSubsidyDetails: manualSubsidy }));
    } else {
      dispatch(
        updateProject({
          ...project,
          subsidy: selectedSubsidies.map((id: string) =>
            subsidyOptionsResponse.current?.find((subsidy: any) => subsidy._id === id),
          ),
        }),
      );
    }
    dispatch(PopupActions.closePopup(POPUP_KEYS.subsidy));
  }, [isManualSubsidy, selectedSubsidies, manualSubsidy, subsidyOptionsResponse.current]);

  const disableSubmit = useMemo(() => {
    if (isManualSubsidy) {
      if (
        manualSubsidy.subsidyType &&
        manualSubsidy.subsidyName &&
        manualSubsidy.amount &&
        manualSubsidy.usageType
      ) {
        return false;
      }
      return true;
    } else {
      if (selectedSubsidies.length > 0) {
        return false;
      }
      return true;
    }
  }, [selectedSubsidies, manualSubsidy, isManualSubsidy]);

  const toggleManualSubsidy = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsManualSubsidy((prev) => !prev);
    },
    [setIsManualSubsidy],
  );

  // useEffect(() => {
  //   setManualSubsidy({});
  // }, [isManualSubsidy]);

  return (
    <PopupSkeleton handleClose={() => dispatch(closePopup(POPUP_KEYS.subsidy))} open={true}>
      <S.MainContentWrapper>
        <img src={SolarHubLogo} style={{ height: 90, justifySelf: "center" }} alt="Logo" />

        <S.Section1>
          <div>{t("Funding Configurator")}</div>
          <div>
            <img src={profile_pics} style={{ height: 30 }} alt="profile_pics" />
          </div>
          <div>{t("Choose Your Funding")}</div>
        </S.Section1>

        <S.Section2>
          {!isManualSubsidy && (
            <div>
              <Select
                placeholder={t("Choose your subsidy")}
                style={{ width: 340 }}
                notFoundContent={
                  <S.StyledNoData>
                    {t("No Funding options known in your area - help us!")}
                  </S.StyledNoData>
                }
                onChange={handleSubsidyChange}
                mode="multiple"
                options={subsidyOptions}
                value={selectedSubsidies}
              />
            </div>
          )}
          {!isManualSubsidy && (
            <S.Button>
              <PrimaryButton
                style={{
                  width: "250px",
                  fontWeight: "800",
                }}
                onClick={toggleManualSubsidy}
              >
                {t("Add Manual Subsidy")}
              </PrimaryButton>
            </S.Button>
          )}
          {isManualSubsidy && (
            <>
              <Input
                type="string"
                id="subsidyName"
                value={manualSubsidy.subsidyName}
                placeholder={t("Enter subsidy name")}
                style={{
                  width: 340,
                  borderRadius: "20px",
                }}
                onChange={(e) =>
                  setManualSubsidy((prev: any) => ({ ...prev, subsidyName: e.target.value }))
                }
              />
              <Select
                id="subsidyType"
                placeholder={t("Select subsidy type")}
                value={manualSubsidy.subsidyType}
                style={{ width: 340 }}
                onChange={(value) =>
                  setManualSubsidy((prev: any) => ({ ...prev, subsidyType: value }))
                }
                options={[
                  { value: "PV system", label: t("PV system") },
                  { value: "battery storage", label: t("battery storage") },
                  { value: "heat pump", label: t("heat pump") },
                ]}
              />

              <Select
                id="usageType"
                placeholder={t("Select usage type")}
                value={manualSubsidy.usageType}
                style={{ width: 340 }}
                onChange={(value) =>
                  setManualSubsidy((prev: any) => ({ ...prev, usageType: value }))
                }
                options={[
                  { value: "Feed-In", label: t("Feed-In") },
                  { value: "Full-Feed", label: t("Full-Feed-in") },
                  { value: "Direct sale", label: t("Direct sale") },
                ]}
              />
              <S.StyledMasterInput
                value={manualSubsidy.amount}
                label={" €"}
                fullWidth
                isSubsidy
                placeholder={t("Enter amount")}
                onChange={(e) => {
                  setManualSubsidy((prev: any) => {
                    return { ...prev, amount: Number(e) };
                  });
                }}
              />

              <S.Button>
                <PrimaryButton
                  style={{
                    width: "200px",
                    fontWeight: "800",
                  }}
                  onClick={toggleManualSubsidy}
                >
                  {t("Go Back")}
                </PrimaryButton>
              </S.Button>
            </>
          )}
          <S.Button>
            <SecondaryButton
              onClick={handleSubmit}
              style={{ width: "100px" }}
              disabled={disableSubmit}
            >
              {t("Continue")}
            </SecondaryButton>
          </S.Button>
        </S.Section2>
      </S.MainContentWrapper>
    </PopupSkeleton>
  );
};

export default SubSidy;
