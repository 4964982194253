import { cloneDeep } from "lodash";

import { defaultComponent, defaultHeatpumpConfiguration } from "../../default-values";

import { actionCreators, ProjectState } from "src/redux/project";

export const deleteHeatpump = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.deleteHeatpump>,
): ProjectState => {
  const heatpump = {
    ...cloneDeep(defaultComponent),
    configuration: cloneDeep(defaultHeatpumpConfiguration),
  };

  return {
    ...currentState,
    components: {
      ...currentState.components,
      heatpump,
    },
  };
};
