import { WelcomeNoAddressProps } from "src/popups";
import { CarSelectorProps } from "src/popups/carselector/CarSelector";
import { ProductDetailsPopupProps } from "src/popups/ProductDetailsPopup/ProductDetailsPopup";
import { ProductInfoProps } from "src/popups/ProductInfo/ProductInfoPopup";
import { ProductPopupProps } from "src/popups/ProductPopup/ProductPopup";

import { ReduxAction } from "src/redux/common.types";
import { actionNames, POPUP_KEYS } from "src/redux/popups";

// TODO conditional types to be added
export const openPopup = (
  key: POPUP_KEYS,
  data?: any,
): ReduxAction<{
  popupKey: POPUP_KEYS;
  open: true;
  data: any;
}> => {
  return {
    type: actionNames.OPEN_POPUP,
    payload: {
      popupKey: key,
      open: true,
      data,
    },
  };
};

export const closePopup = (key: POPUP_KEYS): ReduxAction<any> => {
  return {
    type: actionNames.CLOSE_POPUP,
    payload: {
      popupKey: key,
      open: false,
    },
  };
};

export const updateData = (key: POPUP_KEYS, data: any): ReduxAction<any> => {
  return {
    type: actionNames.UPDATE_DATA,
    payload: {
      popupKey: key,
      data,
    },
  };
};

export const openProductDetailsPopup = (data: ProductDetailsPopupProps): ReduxAction<any> => {
  return openPopup(POPUP_KEYS.productDetailsPopup, data);
};

export const closeProductDetailsPopup = (): ReduxAction<any> => {
  return closePopup(POPUP_KEYS.productDetailsPopup);
};

export const openProductInfoPopup = (data: ProductInfoProps): ReduxAction<any> => {
  return openPopup(POPUP_KEYS.productInfo, data);
};

export const closeProductInfoPopup = (): ReduxAction<any> => {
  return closePopup(POPUP_KEYS.productInfo);
};

export const openAddressPopup = (data: WelcomeNoAddressProps = {}): ReduxAction<any> => {
  return openPopup(POPUP_KEYS.welcome_address, data);
};

export const openCarSelector = (data: CarSelectorProps): ReduxAction<any> => {
  return openPopup(POPUP_KEYS.car, data);
};

export const closeCarSelector = (): ReduxAction<any> => {
  return closePopup(POPUP_KEYS.car);
};

export const openSolarLayoutPopup = (data: any): ReduxAction<any> => {
  return openPopup(POPUP_KEYS.solar_layout_popup, data);
};

export const closeSolarLayoutPopup = (): ReduxAction<any> => {
  return closePopup(POPUP_KEYS.solar_layout_popup);
};

export const openDataPolicyPopup = (): ReduxAction<any> => {
  return {
    type: actionNames.OPEN_POPUP,
    payload: {
      popupKey: POPUP_KEYS.data_policy,
      open: true,
      data: {
        accept: false,
      },
    },
  };
};

export const closeDataPolicyPopup = (accept = false): ReduxAction<any> => {
  return {
    type: actionNames.CLOSE_POPUP,
    payload: {
      popupKey: POPUP_KEYS.data_policy,
      open: false,
      data: {
        accept: accept,
      },
    },
  };
};
