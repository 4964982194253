import { Box, Grid, Stack, Typography } from "@mui/material";
import { useMemo, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

// import BatteryStorage from "src/components/popups/batteryStorage/BatteryStorage";
// import ProductExplain from "src/components/popups/ProductExplain/ProductExplain";
// import ProductPopup from "src/components/popups/productpopup/ProductPopup";

import { useSearchParams } from "react-router-dom";

import { useWindowDimensions } from "src/hooks";

import { SaveIcon } from "src/assets/svgs";
import useGetSaveProjectFun from "src/hooks/useGetSaveProjectFun";
import { Mixpanel } from "src/mix-panel/mixpanel";
import * as trackingEvents from "src/mix-panel/trackingEvents";
import { AUTH_MODES, AuthPopupProps } from "src/popups";
import InfoIcon from "src/shared/components/InfoIcon/InfoIcon";
import { isNotOnDefaultProject, isNotOnSavedProject, userIsNotAuthenticated } from "src/utils";

import ShareProjectButton from "./components/shareProject/ShareButton";
import { stepperData } from "./headerData";
import { ButtonWrapper } from "./style";

import { AppActions, PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { AppSelectors, ProjectSelectors, UserSelectors } from "src/redux/selectors";
import { Switch } from "antd";
import { UserActions } from "src/redux/actionCreators";

const ApplicationHeader: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [query] = useSearchParams();

  const [isAdd, setIsAdd] = useState(true);
  const [productPopupOpen, setProductPopupOpen] = useState(false);
  const [openBatteryStorage, setOpenBatteryStorage] = useState(false);

  const simplify = useSelector(UserSelectors.getSimplify);
  const [simplified, setSimplified] = useState(false);

  const currentUser = useSelector(UserSelectors.getUser);
  const currentStep = useSelector(AppSelectors.getCurrentStep);

  // const { save } = useProjectTools();
  // const { saveForAdmin } = useAdminHook();
  const { width } = useWindowDimensions();
  const { saveProject } = useGetSaveProjectFun();

  const [openProductExplain, setProductExplain] = useState<Boolean>(false);
  const installerPartnerSlug = query.get("partner");

  useEffect(() => {
    if (typeof simplify === "boolean") {
      setSimplified(simplify);
    }
  }, [simplify]);

  // useEffect(() => {
  //   if (
  //     typeof simplified === "boolean" &&
  //     typeof simplify === "boolean" &&
  //     simplified !== simplify
  //   ) {
  //     const searchParams = new URLSearchParams(window.location.search);
  //     if (simplified) {
  //       searchParams.set("simplify", "true");
  //     } else {
  //       searchParams.delete("simplify");
  //     }
  //     const newSearch = searchParams.toString();
  //     window.location.href = `${window.location.pathname}?${newSearch}`;
  //   }
  // }, [simplify]);

  const updatedStepper = useMemo(() => {
    const stepper = stepperData;
    if (installerPartnerSlug) {
      stepper[3].h1 = "Almost done - request your realization time now!";
      stepper[3].h3 =
        "In this last step you can overview your project and when you´re happy with it request a realization time.";
      stepper[3].h1German = "Fast geschafft - frage nun einen Umsetzungszeitraum an";
      stepper[3].h3German =
        "Im letzten Schritt kannst du nun dein Projekt noch einmal überprüfen und anschließend einen Umsetzungszeitraum anfragen";
    }
    return stepper;
  }, [stepperData, installerPartnerSlug]);

  const isMobileWidth = width <= 500;

  return (
    <Stack
      direction={{ xs: "column", lg: "row", md: "row", sm: "row" }}
      flexGrow={1}
      gap={2}
      width="100%"
      p={2}
      mb={3}
      justifyContent="space-between"
      fontFamily="var(--theme-font)"
    >
      <Grid display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Grid container gap={2} direction="row" display={"flex"}>
            <Typography
              fontFamily="var(--theme-font)"
              fontSize={{ md: "1.6rem", xs: "1rem" }}
              color="#2d4764"
              fontWeight={700}
            >
              {t(updatedStepper[currentStep].h1)}
            </Typography>
            {/* {currentStep === 1 && <InfoIcon onClick={() => setProductExplain(true)} />} */}
          </Grid>
          <Typography
            fontFamily="var(--theme-font)"
            style={{ color: "#666666" }}
            fontWeight={500}
            fontSize={"15px"}
          >
            {t(updatedStepper[currentStep].h3)
              .split("<br>")
              .map((text, index, wholeArr) => {
                if (index === wholeArr.length - 1) return text;
                else
                  return (
                    <>
                      {text}
                      <br></br>
                    </>
                  );
              })}
          </Typography>
        </Box>
        {isMobileWidth && <ShareProjectButton />}
      </Grid>

      {/* share and save button */}
      <Grid display="flex" alignItems="center" gap={4}>
        {!isMobileWidth && <ShareProjectButton />}
        {isNotOnSavedProject() && (
          <ButtonWrapper onClick={() => saveProject()}>
            <div>
              <SaveIcon />
            </div>
            <div className="text-base font-semibold cursor-pointer">{t("SAVE")}</div>
          </ButtonWrapper>
        )}
        <ButtonWrapper>
          <div>
            <Switch
              style={{ backgroundColor: "#FFD75D" }}
              checked={simplified}
              onChange={(checked) => dispatch(UserActions.setSimplify(checked))}
            />
          </div>
          <div className="text-base font-semibold cursor-pointer">{t("SIMPLIFY")}</div>
        </ButtonWrapper>
      </Grid>

      {/* popups */}
      {/* <ProductExplain
        openProductExplain={openProductExplain}
        setProductExplain={setProductExplain}
      />
      <ProductPopup
        open={productPopupOpen}
        setOpen={setProductPopupOpen}
        isAdd={isAdd}
        type="battery"
        setIsAdd={setIsAdd}
        // data={""}
        setOpenBatteryStorage={setOpenBatteryStorage}
        coming={false}
      />
      <BatteryStorage open={openBatteryStorage} setOpen={setOpenBatteryStorage} /> */}
    </Stack>
  );
};

export default ApplicationHeader;
