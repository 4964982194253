import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Geometry, InstallerOfferDB } from "src/db-types";
import { getCalculateDistance } from "src/helpers/product/getCalculateDistance";
import { getFinancialDetails } from "src/redux/project/selectors";
import { ProjectSelectors } from "src/redux/selectors";

interface IUseInstallerDetailsReturn {
  id: null | string;
  realizationWeek: null | string[];
  realizationWeekShort: null | string;
  pdfUrl: string;
  finalPrice: number;
  companyName: string;
  distance: number;
  address: string;
  logoUrl: string[];
  userGeometry: Geometry | null;
  installerGeometry: Geometry | null;
  totalProjectCost: number;
}

export const UseInstallerDetails = (
  selectedInstaller: InstallerOfferDB,
): IUseInstallerDetailsReturn => {
  const selectedInstallersDetails = useSelector(ProjectSelectors.getinstallerDetails);
  const mapboxAddress = useSelector(ProjectSelectors.getAddress);
  const {
    costs: { totalProjectCost },
  } = useSelector(getFinancialDetails);

  const [installerDetails, setInstallerDetails] = useState<IUseInstallerDetailsReturn>({
    id: null,
    realizationWeek: null,
    realizationWeekShort: null,
    pdfUrl: "",
    finalPrice: 0,
    distance: 0,
    companyName: "",
    address: "",
    logoUrl: [],
    userGeometry: null,
    installerGeometry: null,
    totalProjectCost: 0,
  });

  useEffect(() => {
    if (selectedInstaller && selectedInstallersDetails) {
      const matchingDetails = selectedInstallersDetails.find(
        (installer) => installer._id === selectedInstaller.iid,
      );

      if (matchingDetails) {
        const { companyName, address, logoUrl, geometry } = matchingDetails;
        const distance = getCalculateDistance(mapboxAddress?.geometry, geometry);

        setInstallerDetails({
          id: selectedInstaller?._id,
          realizationWeek: selectedInstaller?.realizationWeek,
          realizationWeekShort: selectedInstaller?.realizationWeekShort,
          pdfUrl: selectedInstaller?.pdfUrl,
          finalPrice: selectedInstaller?.finalPrice,
          distance,
          companyName,
          address,
          logoUrl,
          userGeometry: mapboxAddress?.geometry,
          installerGeometry: geometry,
          totalProjectCost,
        });
      }
    }
  }, [selectedInstaller, selectedInstallersDetails]);

  return installerDetails;
};
