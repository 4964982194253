import { actionCreators, ProjectState } from "src/redux/project";

export const addWaterHeater = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addWaterHeater>,
): ProjectState => {
  return {
    ...currentState,
    components: {
      ...currentState.components,
      waterHeating: true,
    },
  };
};
