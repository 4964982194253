import { Slider } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { DownArrowIcon, UpArrow, UpArrowIcon } from "src/assets/svgs";
import { Mixpanel, trackingEvents } from "src/mix-panel";
import { MasterInput } from "src/shared/components";
import { ParameterStyles } from "src/shared/StyledComponents";

import {
  AllSubMain1Text,
  InputValueWrapper,
  MainContainer,
  SubContainer1,
  SubContainer22,
} from "./ManageAssumptions.styles";
import SelectFeedIn from "./SelectFeedIn";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";

export const ManageAssumptions: React.FC = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { feedInTariff, pricePerKwh, estimatedYearlyPriceIncrease, increaseRateByBank } =
    useSelector(ProjectSelectors.getFinancialDetails);
  const { roofPitch, productionReducedDueToShade } = useSelector(
    ProjectSelectors.getEnergyProductionParams,
  );

  const [openAssumptions, setOpenAssumptions] = useState(false);

  const increaseRateBankChangeHandler = (data: number): void => {
    dispatch(
      ProjectActions.updateFinancialDetails({
        increaseRateByBank: data,
      }),
    );
  };

  const roofPitchChangeHandler = (data: number): void => {
    dispatch(
      ProjectActions.updateEnergyProductionParams({
        roofPitch: data,
      }),
    );
  };

  const pricePerKwhChangeHandler = (value: number): void => {
    dispatch(
      ProjectActions.updateFinancialDetails({
        pricePerKwh: value,
      }),
    );
  };

  const productionReduceChangeHandler = (value: number): void => {
    dispatch(
      ProjectActions.updateEnergyProductionParams({
        productionReducedDueToShade: value,
      }),
    );
  };

  const feedInChangeHandler = (value: number): void => {
    dispatch(
      ProjectActions.updateFinancialDetails({
        feedInTariff: value,
      }),
    );
  };

  const yearlyPriceIncreaseChangeHandler = (value: number): void => {
    dispatch(
      ProjectActions.updateFinancialDetails({
        estimatedYearlyPriceIncrease: value,
      }),
    );
  };

  const openAssumptionBtnClickHandler = useCallback(() => {
    if (!openAssumptions) {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_SHOW_EDIT_ASSUMPTIONS_STEP1, {});
    }
    setOpenAssumptions((p) => !p);

    if (!openAssumptions) {
      setTimeout(() => {
        document?.getElementById("tries")?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [openAssumptions]);

  return (
    <MainContainer>
      <div id={"tries"} />
      <SubContainer1 onClick={openAssumptionBtnClickHandler} openAssumptions={!openAssumptions}>
        <AllSubMain1Text>
          {!openAssumptions ? t("SHOW EDIT ASSUMPTIONS") : t("HIDE EDIT ASSUMPTIONS")}
        </AllSubMain1Text>
        <SubContainer22 openAssumptions={openAssumptions}>
          {openAssumptions ? <UpArrow /> : <DownArrowIcon />}
        </SubContainer22>
      </SubContainer1>
      {openAssumptions && (
        <>
          <SelectFeedIn />
          <ParameterStyles.Line hasSlider isPadding>
            <ParameterStyles.Label hasSlider>{t("Feed-in-tariff")}</ParameterStyles.Label>
            <ParameterStyles.Input>
              <Slider
                onChange={feedInChangeHandler}
                value={feedInTariff}
                step={0.001}
                max={1}
                min={0.005}
              />
            </ParameterStyles.Input>
            <ParameterStyles.Value hasSlider>
              <InputValueWrapper>
                <MasterInput
                  value={feedInTariff}
                  onChange={feedInChangeHandler}
                  label="€"
                  appearance={{ bgcolor: "#fff" }}
                />
              </InputValueWrapper>
            </ParameterStyles.Value>
          </ParameterStyles.Line>
          {/* <ParameterStyles.Line hasSlider isPadding>
            <ParameterStyles.Label hasSlider>
              <div>{t("ROOF PITCH")}</div>
            </ParameterStyles.Label>
            <ParameterStyles.Input>
              <Slider onChange={roofPitchChangeHandler} value={roofPitch} max={90} />
            </ParameterStyles.Input>
            <ParameterStyles.Value>
              <InputValueWrapper>
                <MasterInput
                  value={roofPitch}
                  onChange={roofPitchChangeHandler}
                  appearance={{ bgcolor: "#fff" }}
                />
              </InputValueWrapper>
            </ParameterStyles.Value>
          </ParameterStyles.Line> */}
          <ParameterStyles.Line hasSlider isPadding>
            <ParameterStyles.Label hasSlider>
              <div>{t("CURRENT ELECTRICITY PRICE")}</div>
            </ParameterStyles.Label>
            <ParameterStyles.Input>
              <Slider
                value={pricePerKwh}
                onChange={pricePerKwhChangeHandler}
                step={0.01}
                min={0.05}
                max={1}
              />
            </ParameterStyles.Input>
            <ParameterStyles.Value hasSlider>
              <InputValueWrapper>
                <MasterInput
                  value={pricePerKwh}
                  onChange={pricePerKwhChangeHandler}
                  appearance={{ bgcolor: "#fff" }}
                />
              </InputValueWrapper>
            </ParameterStyles.Value>
          </ParameterStyles.Line>
          <ParameterStyles.Line hasSlider isPadding>
            <ParameterStyles.Label hasSlider>
              {t("Reduce production due to shades")}
            </ParameterStyles.Label>
            <ParameterStyles.Input>
              <Slider
                onChange={productionReduceChangeHandler}
                value={Math.round(productionReducedDueToShade)}
              />
            </ParameterStyles.Input>
            <ParameterStyles.Value hasSlider>
              <InputValueWrapper>
                <MasterInput
                  value={productionReducedDueToShade}
                  onChange={productionReduceChangeHandler}
                  maxNumber={100}
                  label="%"
                  appearance={{ bgcolor: "#fff" }}
                />
              </InputValueWrapper>
            </ParameterStyles.Value>
          </ParameterStyles.Line>
          <ParameterStyles.Line hasSlider isPadding>
            <ParameterStyles.Label hasSlider>
              {t("ELECTRICITY PRICE INCREASE")}
            </ParameterStyles.Label>
            <ParameterStyles.Input>
              <Slider
                onChange={yearlyPriceIncreaseChangeHandler}
                value={estimatedYearlyPriceIncrease}
                max={20}
              />
            </ParameterStyles.Input>
            <ParameterStyles.Value hasSlider>
              <InputValueWrapper>
                <MasterInput
                  appearance={{ bgcolor: "#fff" }}
                  value={estimatedYearlyPriceIncrease}
                  onChange={yearlyPriceIncreaseChangeHandler}
                  label="%"
                />
              </InputValueWrapper>
            </ParameterStyles.Value>
          </ParameterStyles.Line>
          <ParameterStyles.Line hasSlider isPadding>
            <ParameterStyles.Label hasSlider>{t("INCREASE RATE BY BANK")}</ParameterStyles.Label>
            <ParameterStyles.Input>
              <Slider
                onChange={increaseRateBankChangeHandler}
                value={increaseRateByBank}
                max={10}
              />
            </ParameterStyles.Input>
            <ParameterStyles.Value hasSlider>
              <InputValueWrapper>
                <MasterInput
                  value={increaseRateByBank}
                  onChange={increaseRateBankChangeHandler}
                  label="%"
                  maxNumber={10}
                  appearance={{ bgcolor: "#fff" }}
                />
              </InputValueWrapper>
            </ParameterStyles.Value>
          </ParameterStyles.Line>
        </>
      )}
    </MainContainer>
  );
};
