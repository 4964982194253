import { actionCreators, ProjectState } from "src/redux/project";

export const updateElectricMeter = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateElectricMeter>,
): ProjectState => {
  const {
    images: { electricMeter: currElecMeter },
  } = currentState;

  return {
    ...currentState,
    images: {
      ...currentState.images,
      electricMeter: {
        meterTypeId: payload.meterTypeId ?? currElecMeter.meterTypeId,
        description: payload.description ?? currElecMeter.description,
        imageURLs: payload.imageURLs ?? currElecMeter.imageURLs,
      },
    },
  };
};
