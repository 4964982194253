import styled from "styled-components/macro";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-right: 40px;
  margin-block: 1rem;
  border-bottom: "0.943991px solid #e2e2e2";
  &:hover {
    .portfolio-overlay {
      opacity: 0.8;
    }
  }
`;
export const MainContainer2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-left: 54px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    .portfolio-overlay {
      opacity: 0.8;
    }
  }
  .info_and_ratings {
    display: flex;
    flex: 1;
    justify-content: space-between;
    @media (max-width: 800px) {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const SubContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 200px;
  position: relative;

  .hoverd {
    display: none;
  }

  :hover {
    .hoverd {
      display: block;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img{
      filter: blur(50);
    }
  }
`;
export const SubContainer21 = styled.div`
  position: absolute;
  top: 65%;
  left: 20%;
`;
export const SubContainer22 = styled.div`
  position: absolute;
  width: 116.11px;
  height: 3.78px;
  left: 54.63px;
  top: 11.21px;
  background: #ffd75d;
  border-radius: 3.77596px;
`;
export const SubContainer23 = styled.div`
  position: absolute;
  left: 66.63px;
  top: -4.79px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 11.3279px;
  color: #000000;
`;
export const SubContainer232 = styled.div`
  position: absolute;
  left: 50.63px;
  top: 117.21px;
  font-weight: 500;
  font-size: 13.3279px;
  font-family: "Nunito";
  font-style: normal;

  color: #000000;
`;
export const SubContainer11 = styled.div`
  position: absolute;
  top: 30%;
  left: 43%;
`;
export const SubContainer111 = styled.div`
  position: absolute;
  top: 30%;
  left: 46%;
`;
export const SubContainer12 = styled.div`
  position: absolute;
  top: 52%;
  left: 43%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #2d4764;
`;
export const SubContainer121 = styled.div`
  position: absolute;
  top: 47%;
  left: 36%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #2d4764;
`;
export const SubContainer2 = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  margin-inline: 0.5rem;
`;
export const SubContainer3 = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  .text1 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #2d4764;
  }
  .text2 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #666666;
  }
  .text3 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 110.5%;
    text-decoration-line: underline;
    color: #2d4764;
    margin-top: 16px;
    cursor: pointer;
    padding-bottom: 7px;
    white-space: nowrap;
  }
  .text4 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #2d4764;
  }
`;

export const MainText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #2d4764;
  margin-bottom: 0.75rem;
`;
export const SubText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
  margin-left: 10px;
  margin-bottom: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2d4764 !important;
    border-color: #2d4764 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: white !important;
  }
`;
export const SubText1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-decoration-line: underline;
  color: #2d4764;
  cursor: pointer;
`;

export const PlusIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* White background with some transparency */
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;
