import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { consumptionProfilesB2B, consumptionProfilesB2C } from "src/data/consumption-profiles";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { ProjectSelectors } from "src/redux/selectors";

const ConsumptionProfileLine: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { consumptionProfile } = useSelector(ProjectSelectors.getHousehold);
  const projectType = useSelector(ProjectSelectors.getProjectType);

  const openConsumptionProfileDialog = useCallback(() => {
    const action = PopupActions.openPopup(POPUP_KEYS.consumption_profile);
    dispatch(action);
  }, [dispatch]);

  const [shortName, setShortName] = useState("");

  useEffect(() => {
    if (projectType === "b2b") {
      type b2bKey = keyof typeof consumptionProfilesB2B;
      setShortName(consumptionProfilesB2B[consumptionProfile as b2bKey].short_title);
    }
    if (projectType === "b2c") {
      type b2cKey = keyof typeof consumptionProfilesB2C;
      setShortName(consumptionProfilesB2C[consumptionProfile as b2cKey].short_title);
    }
  }, [consumptionProfile, projectType]);

  return (
    <div className="same_part">
      <div className="text_same">{t("Consumption profile")}</div>
      <div className="round_button" onClick={openConsumptionProfileDialog}>
        {t(shortName)}
      </div>
    </div>
  );
};

export default ConsumptionProfileLine;
