import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media only screen and (max-width: 500px) {
    width: 100%;
    margin-top: 2rem;
  }
`;

export const Heading = styled.div`
  font-family: "Nunito";
  font-style: normal;
  display: flex;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 22px;
  color: #304864;
  gap: 5px;
`;

export const ButtonObj = styled.div`
  border-radius: 20px;
  background: #dfdfdf;
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 2px 8px;
  color: #304864;
  width: fit-content;
`;

export const Address = styled.div`
  font-family: "Nunito";
  font-style: normal;
  display: flex;
  flex-direction: column;
  color: #2d4764;
  gap: 8px;
  .address {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.07px;
  }
  .head {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  .date {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.07px;
  }
`;
