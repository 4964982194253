import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useAddBatteryInverterChange, useNumberFormat } from "src/hooks";

import { DeleteItIcon } from "src/assets/svgs";
import { getAverageMaxCapacity } from "src/helpers";
import { ProductPopupProps } from "src/popups/ProductPopup/ProductPopup";
import { getNumber } from "src/utils";

import { PopupActions, ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { ProductSelectors } from "src/redux/selectors";

export const BatteryLine: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filteredBatteries = useSelector(ProductSelectors.getFilteredBatteries);

  const [hover, setHover] = useState(false);

  const { formatNumber } = useNumberFormat();
  const { restoreInverterPreference } = useAddBatteryInverterChange();

  const deleteBtnClickHandler = useCallback(() => {
    dispatch(ProjectActions.deleteBattery());
    restoreInverterPreference();
  }, [dispatch, restoreInverterPreference]);

  const openProductPopup = useCallback(() => {
    const data: ProductPopupProps = {
      type: "battery",
      onSelectCallback: (prod) => {
        dispatch(
          ProjectActions.updateBattery({
            item: prod,
          }),
        );
      },
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.product, data));
  }, [dispatch]);

  const maxCapacity = useMemo(() => {
    return getAverageMaxCapacity(filteredBatteries);
  }, [filteredBatteries]);

  return (
    <div className="same_container" style={{ marginBottom: "6px" }}>
      <div
        className="same_texts"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={openProductPopup}
      >
        {!hover ? t("BATTERY CAPACITY") : t("Change Battery")}
      </div>
      <div className="make_it_bc_flex">
        <div
          className="same_buttons"
          onClick={() => {
            // TODO
            // openProductHandler("battery");
          }}
        >
          {`${formatNumber(getNumber(maxCapacity) / 1000, { roundTill: 1 })} kWh`}
        </div>
        <DeleteItIcon style={{ cursor: "pointer" }} onClick={deleteBtnClickHandler} />
      </div>
    </div>
  );
};
