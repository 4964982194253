/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import SunCalc from "suncalc";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer";

import Loader from "src/assets/loader.gif";
import WhiteDot from "src/assets/whiteDot.svg";

export function ThreeDLoader(el, objUrl, mtlUrl, element) {
  let scene, renderer;
  let controls;

  const sprite = new THREE.TextureLoader().load(WhiteDot);
  sprite.colorSpace = THREE.SRGBColorSpace;
  init();

  function init() {
    let camera;
    let object;
    let isShiftPressed = false;
    document.body.style.userSelect = "none";
    const pointLight = new THREE.PointLight(0xffffff, 15);
    const labelRenderer = new CSS2DRenderer();

    const ambientLight = new THREE.AmbientLight(0xffffff, 5);

    // scene.add(ambientLight);

    // Create a directional light simulating sunlight from the left
    const directionalLight = new THREE.DirectionalLight(0xea1601, 50); // Yellowish sunlight
    directionalLight.position.set(-5, 3, 2); // Adjust position for left-side light

    // eslint-disable-next-line prefer-const
    camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 20);
    scene = new THREE.Scene();

    [ambientLight, pointLight, directionalLight, camera].forEach((el) => scene.add(el));

    const handlePaning = (event) => {
      if (isShiftPressed && event.button === 0) {
        controls.enablePan = true;
        controls.update();
      }
    };

    function loadModel() {
      scene.add(object);
      render();
    }

    const manager = new THREE.LoadingManager(loadModel);

    function onProgress(xhr) {
      if (xhr.lengthComputable) {
        const percentComplete = (xhr.loaded / xhr.total) * 100;
        if (element) {
          let loaderContainer = element.querySelector(".loader-container");
          if (!loaderContainer) {
            loaderContainer = document.createElement("div");
            loaderContainer.className = "loader-container";
            loaderContainer.style.cssText =
              "position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);";
            element.appendChild(loaderContainer);
          }

          loaderContainer.innerHTML = `
            <img src="${Loader}" alt="loader" style="height: 25px; width: 25px; margin: 5px; cursor: pointer;">
          `;
          if (percentComplete === 100) {
            setTimeout(() => {
              loaderContainer.remove();
            }, 3000);
          }
        }
      }
    }

    function onError() {}

    const calculateSolarIrradiation = (object, scene) => {
      // Assuming a simple model where solar panels are on the roof
      // You would need to determine the positions of solar panels in your actual model
      const solarPanels = object.children.filter((child) => child.name === "solar_panel");

      // Get current date and time
      const currentDate = new Date();
      const latitude = 51.5074; // London, UK (replace with your actual location)
      const longitude = -0.1278;

      // Calculate sun position
      const sunPosition = SunCalc.getPosition(currentDate, latitude, longitude);

      console.log("Sun Position:", SunCalc, sunPosition, object);

      // Calculate solar irradiation for each solar panel
      solarPanels.forEach((panel) => {
        // Calculate solar irradiation based on panel orientation and position
        const irradiation = calculateIrradiationForPanel(panel, sunPosition, currentDate);

        // Apply the irradiation color based on intensity
        let irradiationColor;
        if (irradiation > 0) {
          // Higher irradiation, closer to yellow
          irradiationColor = new THREE.Color(1, 1, 0); // Yellow
        } else {
          // Lower or no irradiation, closer to red
          irradiationColor = new THREE.Color(1, 0, 0); // Red
        }

        // Create a new material with the irradiation color
        const material = new THREE.MeshBasicMaterial({ color: irradiationColor });

        // Create a new mesh with the irradiation material
        const irradiationMesh = new THREE.Mesh(panel.geometry, material);

        // Position the irradiation mesh slightly above the original panel
        irradiationMesh.position.copy(panel.position);
        irradiationMesh.position.y += 0.1; // Adjust offset as needed

        // Add the irradiation mesh to the scene (optional for stacking effect)
        scene.add(irradiationMesh);

        console.log(`Solar irradiation for panel ${panel.name}: ${irradiation} kWh/m²`);
      });
    };

    // Function to calculate solar irradiation for a single solar panel
    const calculateIrradiationForPanel = (panel, sunPosition, currentDate) => {
      // Placeholder calculation (replace with your actual logic)
      // This assumes a perfect angle between the sun and the panel for maximum irradiation
      const isFacingSun = Math.abs(panel.rotation.y) < Math.PI / 4; // Check if panel faces the sun (within 45 degrees)
      const irradiation = isFacingSun ? 1 : 0; // Maximum irradiation if

      // Error handling (optional, replace with more robust calculations)
      if (!panel.geometry || !panel.material) {
        console.warn("Panel geometry or material not found, skipping irradiation calculation");
        return 0;
      }

      // Placeholder calculation (replace with more accurate calculation)
      // You would need to calculate the actual irradiation based on the panel's orientation
      return irradiation * 5; // Assuming units of kWh/m² and scaling by a factor of 5

      // Placeholder calculation (replace with more accurate calculation)
      // You would need to calculate the actual irradiation based on the panel's orientation
      return irradiation * 5; // Assuming units of kWh/m² and scaling by a factor of 5
    };

    const fitCameraToCenteredObject = (camera, object) => {
      const boundingBox = new THREE.Box3();

      boundingBox.setFromObject(object);

      const center = boundingBox.getCenter(new THREE.Vector3());
      const size = boundingBox.getSize(new THREE.Vector3());

      const maxSize = Math.max(size.x, size.y, size.z);

      const fov = camera.fov * (Math.PI / 180);
      const cameraZ = Math.abs(maxSize / 2 / Math.tan(fov / 2));

      camera.position.copy(center);
      camera.position.z += cameraZ;

      camera.near = maxSize / 100;
      camera.far = maxSize * 3;
      camera.updateProjectionMatrix();
    };

    const mtlLoader = new MTLLoader(manager);
    mtlLoader.load(mtlUrl, (materials) => {
      materials.preload();
      const loader = new OBJLoader(manager);
      loader.setMaterials(materials).load(
        objUrl,
        function (obj) {
          object = obj;
          fitCameraToCenteredObject(camera, obj);
          calculateSolarIrradiation(obj, scene);
        },
        onProgress,
        onError,
      );
    });

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      render();
    };

    renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);

    renderer.setClearColor(0xffffff);
    el.appendChild(renderer.domElement);

    controls = new OrbitControls(camera, renderer.domElement);
    controls.enablePan = false;
    controls.autoRotate = false;
    controls.mouseButtons.RIGHT = THREE.MOUSE.ROTATE;
    controls.mouseButtons.LEFT = THREE.MOUSE.ROTATE;

    controls.addEventListener("change", render);

    // Event listener for keydown to detect the shift key
    document.addEventListener("keydown", (event) => {
      if (event.key === "Shift") {
        isShiftPressed = true;
      }
    });

    // Event listener for keyup to detect when the shift key is released
    document.addEventListener("keyup", (event) => {
      if (event.key === "Shift") {
        isShiftPressed = false;
      }
    });

    document.addEventListener("pointerdown", handlePaning);

    animate();
    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
      labelRenderer.setSize(el.clientWidth, el.clientHeight);
    }

    function render() {
      labelRenderer.render(scene, camera);
      renderer.render(scene, camera);
    }

    window.addEventListener("resize", onWindowResize, false);

    return () => {
      if (element) {
        element.remove();
      }
      renderer.dispose();
      controls.dispose();
      document.body.style.userSelect = "auto";
      el.removeChild(renderer.domElement);
    };
  }
}
