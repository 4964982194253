import { Select } from "antd";
import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { generalFormat } from "src/utils";

import { IWebinars } from "src/redux/webinar";
import {
  setSelectedWebinarIsHybrid,
  setWebinarDate,
  setWebinarId,
} from "src/redux/webinar/action/action.creators";
import { getSelectedWebinarId } from "src/redux/webinar/selector";

interface WebinarSelectorProps {
  webinars: IWebinars[];
}

const { Option } = Select;

const WebinarSelector: React.FC<WebinarSelectorProps> = ({ webinars }) => {
  const dispatch = useDispatch();
  const selectedWebinarId = useSelector(getSelectedWebinarId);

  const onChange = (value: number): void => {
    const webinar: IWebinars | undefined = webinars.find((web: IWebinars) => web.id === value);
    if (webinar) {
      dispatch(setWebinarDate(webinar.start_time));
    }
    dispatch(setWebinarId(value));
    dispatch(setSelectedWebinarIsHybrid(false));
  };

  return (
    <Select
      style={{
        minWidth: "55%",
        height: 39,
      }}
      onChange={onChange}
      virtual={false}
      value={selectedWebinarId}
      placeholder={t("Choose your webinar")}
    >
      {webinars
        .filter((web: IWebinars) => new Date(web.start_time) >= new Date())
        .map((web: IWebinars) => {
          return (
            <Option key={web.id} value={web.id}>
              {generalFormat(new Date(web.start_time)) + " " + web?.topic?.split(" ")[0]}
            </Option>
          );
        })}
    </Select>
  );
};

export default WebinarSelector;
