import React from "react";

import * as S from "./HorizontalCloseButton.styles";

interface HorizontalCloseButtonProps {
  onClick: React.MouseEventHandler;
}
const HorizontalCloseButton: React.FC<HorizontalCloseButtonProps> = (
  props: HorizontalCloseButtonProps,
) => {
  const { onClick } = props;

  return (
    <S.HorizontalCloseButtonWrapper onClick={onClick}>
      <div></div>
    </S.HorizontalCloseButtonWrapper>
  );
};

export default HorizontalCloseButton;
