import styled from "styled-components";

export const ButtonWrapper = styled("button")`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  gap: 10px;
  border: 2px solid #2d4764;
  border-radius: 80px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding: 0 15px;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #2d4764;
  :hover {
    background: #e2e8f0;
  }
`;
