import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useYearlySystemProfit } from "src/hooks";

import { BuildingCostIcon, YearLineIcon, YearProfitIcon } from "src/assets/svgs";
import { useNumberFormat } from "src/hooks/useNumberFormat";

import { InfoBarWrapper } from "./InfoBarStyles";

import { ProjectSelectors } from "src/redux/selectors";
import { RootState } from "src/redux/store";

const InfoBar: React.FC = () => {
  const { t } = useTranslation();

  const { amortizationTime } = useSelector(ProjectSelectors.getFinancialDetails);
  const {
    costs: { appliedProjectCost },
    expectedReturnTime,
  } = useSelector(ProjectSelectors.getFinancialDetails);

  const { profit } = useYearlySystemProfit(expectedReturnTime);
  const { formatNumber } = useNumberFormat();

  return (
    <InfoBarWrapper>
      <div className="container">
        <BuildingCostIcon />
        <div className="makeItFlex">
          <div className="boldText">€{formatNumber(Math.round(appliedProjectCost))}</div>
          <div className="lightText">{t("Building cost")}</div>
        </div>
      </div>
      <div className="container">
        <YearLineIcon />
        <div className="makeItFlex">
          {amortizationTime !== null && profit > 0 ? (
            <div className="boldText">{`${amortizationTime}  ${t("YEARS")}`}</div>
          ) : (
            <div className="boldText">{t("Not Applicable")}</div>
          )}
          <div className="lightText">{t("Time to break even")}</div>
        </div>
      </div>
      <div className="container">
        <YearProfitIcon />
        <div className="makeItFlex">
          <div className="boldText">
            €{profit > 0 ? formatNumber(profit) : `- ${formatNumber(profit)}`}
          </div>
          <div className="lightText">
            {t("Profit after {{ year }} years", {
              year: expectedReturnTime,
            })}
          </div>
        </div>
      </div>
    </InfoBarWrapper>
  );
};

export default InfoBar;
