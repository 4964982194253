import { clone } from "lodash";

import { ApplicationState } from "../../types";

import { actionCreators } from "src/redux/application";

export const updateInverterPreviousPreference = (
  currentState: ApplicationState,
  { payload }: ReturnType<typeof actionCreators.updateInverterPreviousPreference>,
): ApplicationState => {
  return {
    ...currentState,
    previousValues: {
      ...currentState.previousValues,
      inverterPreference: payload,
    },
  };
};
