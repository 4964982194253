import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useWindowDimensions } from "src/hooks";

import { LongLogo } from "src/assets/svgs";

import genUseStyles, { InstallerPartner } from "./MiddlePath.styles";
import { useSelector } from "react-redux";
import { UserSelectors } from "src/redux/selectors";

const MiddlePath: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const classes = useCallback(genUseStyles(), [])();
  const installer = useSelector(UserSelectors.getInstaller);

  const heading = useMemo(() => (width > 600 ? "- " : ""), [width]);

  const showBreakPart = useMemo(
    () => (
      <div className={classes.subHeadingContainer}>
        {installer?.addressSelectorDescription ? (
          <div>{installer?.addressSelectorDescription}</div>
        ) : (
          <>
            <div className={classes.subHeading}>{t("ADDRESS_SELECTOR_DESCRIPTION_FIRST_PART")}</div>
            <div className={classes.subHeading}>
              {t("ADDRESS_SELECTOR_DESCRIPTION_SECOUND_PART")}
            </div>
          </>
        )}
      </div>
    ),
    [classes.subHeading, classes.subHeadingContainer, t],
  );

  return (
    <div className={classes.root}>
      <div className={classes.inline}>
        {installer ? (
          <InstallerPartner>
            {installer.logoUrl.length ? (
              <div className="image-container">
                <img src={installer.logoUrl[0]} alt="Intaller partner" />
              </div>
            ) : (
              <p className="installer-name">{installer.companyName}</p>
            )}
          </InstallerPartner>
        ) : (
          <LongLogo />
        )}

        <h2 className={classes.heading}>
          {heading}
          {installer
            ? installer?.addressSelectorText
              ? `${installer?.addressSelectorText}`
              : t("dein digitaler Energieberater.")
            : t("dein digitaler Energieberater.")}
        </h2>
      </div>
      <h2 className={classes.heading}>{t("Schön, dass du bei uns bist!")}</h2>
      {width < 600 ? (
        showBreakPart
      ) : (
        <div className={classes.subHeading}>
          {installer?.addressSelectorDescription || t("ADDRESS_SELECTOR_DESCRIPTION")}
        </div>
      )}
      {heading && (
        <div className={classes.addressPrefrence}>
          <div className={classes.subHeading}>{t("Wähle hier deine Adresse aus:")}</div>
        </div>
      )}
    </div>
  );
};

export default MiddlePath;
