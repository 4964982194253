import { PrimaryButton } from "src/shared/StyledComponents";
import styled from "styled-components";

export const OverviewContainer = styled.div`
  .home_image img {
    height: 298px;
    width: 338px;
    border-radius: 20px;
  }
  .label {
    background: #ffd75d;
    padding: 6px 16px;
    border-radius: 10px;
    margin-top: 30px !important;
    width: fit-content;
    margin: 0 auto;
    position: relative;
  }
  .label span {
    position: absolute;
    top: -15px;
    right: -15px;
    font-size: 21px;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  position: relative;
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  margin-bottom: 12px;
  width: 100%;
  max-width: 400px;
  height: 100%;
  min-height: 500px;
  justify-content: space-between;
  @media only screen and (max-width: 500px) {
    width: 99%;
    max-width: unset;
  }
  @media only screen and (min-width: 501px) {
    flex-shrink: 0;
  }
  padding-bottom: 37px;
  /* overflow-y: scroll; */
`;

export const OverviewCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 30px;
  justify-content: center;
`;

export const OverviewElectricityCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  justify-content: center;
  .dash {
    width: 24px;
    margin: 0 11px;
    align-self: center;
    height: 2px;
    background: #287e97;
  }
`;

export const OverviewCostsCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 46px;
  row-gap: 30px;
  justify-content: center;
  max-width: 550px;
`;

export const OverviewTitle = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #2d4764;
  text-align: center;
  text-transform: uppercase;
  margin: 18px 0 14px 0;
`;

export const StyledButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledButton = styled(PrimaryButton)`
  background-color: #4d7e6d;
  color: white;
  border: none;
  width: 142px;
`;

export const StyledROI = styled.div`
  width: 50%;

  @media only screen and (max-width: 500px) {
    width: 99%;
  }
`;
