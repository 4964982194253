import { useEffect } from "react";
import { useSelector } from "react-redux";

import { BackendApis } from "src/api";

import { useAppDispatch, useAppSelector } from "src/hooks";

import { useProductsApi } from "src/hooks/apis";

import { AppActions, ProductActions } from "src/redux/actionCreators";
import { ProductSelectors, UserSelectors } from "src/redux/selectors";

export const useLoadInitialProducts = (): void => {
  const products = useAppSelector(ProductSelectors.getAllProducts);
  const installer = useSelector(UserSelectors.getInstaller);
  const dispatch = useAppDispatch();

  const { fetchData, response, loading } = useProductsApi();

  useEffect(() => {
    const alreadyLoaded = products.length > 0;
    if (alreadyLoaded && !installer?._id) return;

    if (installer?._id) {
      void fetchData({
        ...BackendApis.allProducts,
        params: {
          installerId: installer?._id,
        },
      });
    } else {
      void fetchData({
        ...BackendApis.allProducts,
      });
    }
  }, [fetchData, products.length, installer]);

  useEffect(() => {
    if (!response) return;

    const { data: products } = response.data;
    if (installer?._id) {
      const installerProduct = products?.filter((product) => {
        return Object.hasOwn(product?.installerAccess || {}, installer?._id);
      });
      if (installerProduct?.length > 0) {
        dispatch(ProductActions.setProducts(installerProduct));
      } else {
        dispatch(ProductActions.setProducts(products));
      }
    } else {
      dispatch(ProductActions.setProducts(products));
    }
  }, [dispatch, response, installer]);

  useEffect(() => {
    dispatch(
      AppActions.updateAppState({
        apiStates: {
          isProductApiLoading: loading,
        },
      }),
    );
  }, [dispatch, loading]);
};
