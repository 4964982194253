import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: min(100%, 937px);
  max-width: 90%;
  background: white;
  padding: 3rem 1rem;
  margin: auto;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    padding: 0rem 0rem;
    height: 597px;
  }

  .flex_container {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
  }

  .sub_container{
    display: flex;
    flex-direction: column;
    gap: 58px;
    padding-bottom: 58px;
  }

  .title {
    text-align: center;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .description {
    color: #666;
    text-align: center;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

interface ButtonWrapperProps {
  color: string;
  width: string;
  textColor: string;
}

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  width: ${(props) => {
    return props.width;
  }};
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 100px;
  color: ${(props) => {
    return props.textColor;
  }};
  background: ${(props) => {
    return props.color;
  }};
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
  text-transform: uppercase;
`;
