import styled from "styled-components/macro";
export const MainContainer = styled.div`
  width: 100%;
  margin-left: 34px;
  margin-top: 16px;
  margin-bottom: 7px;
  @media screen and (max-width: 500px) {
    margin-left: unset;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    background: #f9f9f9;
    border: 1px solid #e2e2e2;
    border-radius: 100px;
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  .ant-select-selection-item {
    font-family: Nunito;
    font-weight: 700;
    color: var(--blue);
  }

  .ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 43%;
    right: 17px;
    display: flex;
    align-items: center;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
    border-radius: 50%;
    padding: 5px;
    padding-right: 0px;
    :hover {
      background: #ececec;
    }
  }
`;
