import { clone, isNull } from "lodash";

import { UserState, actionCreators } from "src/redux/user";

export const setSimplify = (
  currentState: UserState,
  { payload }: ReturnType<typeof actionCreators.setSimplify>,
): UserState => {
  const newState = clone(currentState);
  if (typeof payload === "boolean") {
    newState.simplify = payload;
  }
  return newState;
};
