import { actionCreators, ProjectState } from "src/redux/project";

export const updateHouseTop = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateHouseTop>,
): ProjectState => {
  const {
    images: { houseTop: currentHouseTop },
  } = currentState;

  return {
    ...currentState,
    images: {
      ...currentState.images,
      houseTop: {
        buildingYearId: payload.buildingYearId ?? currentHouseTop.buildingYearId,
        description: payload.description ?? currentHouseTop.description,
        imageURLs: payload.imageURLs ?? currentHouseTop.imageURLs,
      },
    },
  };
};
