import { cloneDeep } from "lodash";

import { ReduxAction } from "src/redux/common.types";
import { WebinarState, actionNames, handlers } from "src/redux/webinar";

const INITIAL_STATE: WebinarState = {
  webinars: [],
  selectedWebinarDateTime: null,
  selectedWebinarId: null,
  joinedWebinar: false,
  selectedWebinarIsHybrid: false,
};

const webinareducer = (state = INITIAL_STATE, action: ReduxAction): WebinarState => {
  switch (action.type) {
    case actionNames.SET_WEBINARS:
      return handlers.setWebinars(state, action);
    case actionNames.SET_SELECTED_WEBINAR_DATE:
      return handlers.setWebinarsDate(state, action);
    case actionNames.SET_SELECTED_WEBINAR_ID:
      return handlers.setWebinarsId(state, action);
    case actionNames.SET_SELECTED_WEBINAR_IS_HYBRID:
      return handlers.setSelectedWebinarIsHybrid(state, action);
    case "RESET_REDUX":
      return cloneDeep(INITIAL_STATE);
    default:
      return state;
  }
};

export default webinareducer;
