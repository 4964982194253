import styled from "styled-components/macro";

export const MainContainer = styled.div`
  display: flex;
  width: 370px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: max-content;
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 22px;

  @media only screen and (max-width: 500px) {
    min-height: 354px;
    width: 99%;
    margin-bottom: 38px;
  }

  @media only screen and (min-width: 501px) {
    flex-shrink: 0;
  }

  @media only screen and (min-width: 768px) {
    position: sticky;
    top: 0;
  }
  padding-bottom: 16px;
`;
export const MainHeader = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  height: 39px;
  border-radius: 20px 20px 0 0;
`;

interface MainHeaderItemProps {
  name: string;
  selected: string;
}
export const MainHeaderItem = styled.div<MainHeaderItemProps>`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: ${(props) => {
    if (props.name == props.selected) {
      return "3px solid #ffd75d";
    } else {
      return "3px solid #fff7dd";
    }
  }};
  font-family: "Nunito";
  font-style: normal;
  font-weight: ${(props) => {
    if (props.name == props.selected) {
      return "700";
    } else {
      return "600";
    }
  }};
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.005em;
  text-transform: capitalize;
  color: ${(props) => {
    if (props.name == props.selected) {
      return "#2D4764";
    } else {
      return "#666666";
    }
  }};

  :hover {
    background: ${(props) => {
      if (props.name == props.selected) {
        return "#FFF5D8";
      } else {
        return "#FFF5D8";
      }
    }};
    border-bottom: ${(props) => {
      if (props.name == props.selected) {
        return "3px solid #ffd75d";
      } else {
        return "3px solid #ffd75d";
      }
    }};
    font-weight: ${(props) => {
      if (props.name == props.selected) {
        return "700";
      } else {
        return "700";
      }
    }};
  }

  cursor: pointer;
`;
export const MainText2 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  text-decoration-line: underline;
  color: #2d4764;
  margin-top: 16px;
  cursor: pointer;
  margin-bottom: 18px;
`;
