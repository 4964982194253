import styled from "styled-components/macro";

interface MainHeaderItemProps {
  filesUploadHover: boolean;
}
export const UploadArea = styled.div<MainHeaderItemProps>`
  flex: 1;
  height: 184px;
  border: 1px dashed #e2e2e2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  cursor: pointer;
  border: ${(props) => {
    if (props.filesUploadHover) {
      return "1px dashed #9D9D9D";
    }
  }};
  background: ${(props) => {
    if (props.filesUploadHover) {
      return "#FFF5D7";
    }
  }};
`;
export const SubText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #9d9d9d;
  width: 142px;
  text-align: center;
  line-height: 19px;
  margin-top: 16px;
`;
export const SubText1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #9d9d9d;
  margin-top: 10px;
`;
export const SubText2 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #2d4764;
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
`;
