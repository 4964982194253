import { merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateAdditionalHardware = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateAdditionalHardware>,
): ProjectState => {
  const additionalHardware = merge({}, currentState.components.additionalHardware, payload);
  return {
    ...currentState,
    components: {
      ...currentState.components,
      additionalHardware,
    },
  };
};
