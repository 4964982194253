import styled from "styled-components/macro";

export const MainContainer = styled.div`
  min-height: 102px;
  margin-left: -22px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 12px;
  padding-top: 15px;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
  .battery-cost {
    display: flex;
    margin-top: 10px;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
    margin-right: auto;
    padding-left: 5px;
    p {
      font-weight: 600;
      font-size: 17px;
      line-height: 18px;
      color: #2d4764;
    }
  }
`;
export const SubContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  .evKm {
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 2px;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .same_part {
    display: flex;
    width: 99%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .flexing {
    display: flex;
    gap: 11px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #304864;
    justify-content: center;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .icon {
    cursor: pointer;
    margin-right: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 1rem;
    div {
      background: #2d4764dd;
      width: 1rem;
      height: 3px;
      border-radius: 3px;
    }
  }
  .text_first {
    font-size: 16px;
  }
`;
export const SubContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .css-eg0mwd-MuiSlider-thumb {
    background-color: #f7f7fc !important;
    border: 0.893617px solid #2d4764 !important;
    width: 14px !important;
    height: 14px !important;
  }
  .css-1tfve6q-MuiSlider-mark {
    width: 0px !important;
  }
`;
export const SubContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding-top: 12px;
  /* padding-bottom: 8px; */
`;
export const SubContainer3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
`;
export const SubContainerText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
  min-width: 122px;
`;
export const SubContainerValue = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #304864;
  min-width: 68px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
export const SubContainerText1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #2d4764;
  cursor: pointer;
`;
