import styled from "styled-components/macro";

interface ChartCardStyledProps {
  isExpanded: boolean;
}
export const ChartCardStyled = styled.div<ChartCardStyledProps>`
  width: 100%;
  max-height: ${(p) => (p.isExpanded ? "1000px" : "100px")};
  position: relative;
  padding: 0.5rem;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(237, 241, 242, 0.6) 0%,
    rgba(237, 241, 242, 0) 100%
  );

  display: flex;
  flex-direction: column;

  transition: max-height 500ms;

  ${(p) =>
    p.isExpanded && "filter: drop-shadow(0px 0px 6px rgba(45, 71, 100, 0.2))"}

  :hover {
    ${(p) =>
      !p.isExpanded &&
      "background: linear-gradient(180deg,rgba(237, 241, 242, 1) 0%, rgba(237, 241, 242, 0.9) 100%)"};
  }
`;

export const CardTitle = styled.div<ChartCardStyledProps>`
  width: 100%;
  padding-inline: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: var(--blue);
  font-weight: 700;
  cursor: pointer;
  user-select: none;

  span {
    flex: ${(p) => (p.isExpanded ? "none" : 1)};
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  }
`;

export const CardBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  gap: 1rem;
  align-items: center;

  .labels {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 5px;
  }

  .chart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .middle-text {
      position: absolute;
      display: flex;
      flex-direction: column;
      max-width: 100px;
      align-items: center;
      justify-content: center;
      left: 51%;
      top: 55%;
      transform: translate(-50%, -50%);

      .big-text {
        font-size: 24px;
        line-height: 24px;
        font-weight: 500;
        color: rgba(76, 78, 100, 0.87);
      }

      .small-text {
        text-align: center;
        font-size: 14px;
        color: rgba(76, 78, 100, 0.68);
      }
    }
  }
`;
