import styled from "styled-components";

export const InfoBarWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap:10px;
  @media (max-width: 500px) {
    flex-wrap: wrap;
    gap: 10px;
  }
  .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    width: fit-content;
  }
  .lightText {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
    color: rgba(76, 78, 100, 0.68);
  }
  .boldText {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.15px;
    color: rgba(76, 78, 100, 0.87);
  }
`;
