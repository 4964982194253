import { useDispatch, useSelector } from "react-redux";

import Common3dViewer from "./components/3DViewerLeft/Common3dViewer";
import InformationPart from "./components/InformationPartRight/InformationPart";
import * as S from "./styles";

import { ProjectSelectors } from "src/redux/selectors";
import { PrimaryButton } from "src/shared/StyledComponents";
import { useTranslation } from "react-i18next";
import { AppActions } from "src/redux";
import { useState } from "react";

interface IThreeDViewer {
  isFromSavedProject?: boolean;
}

const ThreeDViewer = ({ isFromSavedProject = false }: IThreeDViewer) => {
  const [modelFullView, setModelFullView] = useState<boolean>(true);
  const threeDViewerMaxWidth = useSelector(ProjectSelectors.getMaxWidth3dViewer);
  const maxWidth3DViewerWithImages = useSelector(ProjectSelectors.getMaxWidth3DViewerWithImages);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const nextStepBtnClickHandler = () => {
    dispatch(AppActions.setCurrentStep(3));
  };

  return (
    <S.Container threeDViewerMaxWidth={threeDViewerMaxWidth} modelFullView={modelFullView}>
      {!threeDViewerMaxWidth ? (
        <>
          <S.ObjViewContainer>
            <Common3dViewer
              from3DViwer={true}
              maxWidth3DViewer={threeDViewerMaxWidth}
              maxWidth3DViewerWithImages={maxWidth3DViewerWithImages}
              modelFullView={modelFullView}
              setModelFullView={setModelFullView}
            />
          </S.ObjViewContainer>

          {!modelFullView && <InformationPart />}
          {!isFromSavedProject ? (
            <PrimaryButton
              onClick={nextStepBtnClickHandler}
              style={{
                width: "230px",
                marginInline: "auto",
              }}
            >
              {`${t("NEXT STEP")}`}
            </PrimaryButton>
          ) : (
            <div style={{marginBlock:'0.5rem'}}/>
          )}
        </>
      ) : (
        <Common3dViewer
          from3DViwer={true}
          maxWidth3DViewer={threeDViewerMaxWidth}
          maxWidth3DViewerWithImages={maxWidth3DViewerWithImages}
          modelFullView={modelFullView}
          setModelFullView={setModelFullView}
        />
      )}
    </S.Container>
  );
};

export default ThreeDViewer;
