import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { successiveGrowth } from "src/utils";

import { ProjectSelectors } from "src/redux/selectors";
import { getRentingData } from "src/redux/user/selectors";

interface IProfitByYearPure {
  feedInSavings: number;
  expectedYears: number;
  electricityCostSavings: number;
  estimatedYearlyPriceIncrease: number;
  mobilitySavings: number;
  gasSavings: number;
  evGridCost: number;
  heatpumpGridCost: number;
  appliedProjectCost: number;
  carBuyingCost: number;
  futureOilGasCostIncrease: number;
}
export const profitByYearPure = (props: IProfitByYearPure): ProfitByYearReturn => {
  const {
    appliedProjectCost,
    carBuyingCost,
    electricityCostSavings,
    estimatedYearlyPriceIncrease,
    evGridCost,
    expectedYears,
    feedInSavings,
    gasSavings,
    heatpumpGridCost,
    mobilitySavings,
    futureOilGasCostIncrease,
  } = props;

  const feedInSavingsTillNow = Math.round(feedInSavings) * expectedYears;
  const electricityBillSavingsTillNow = successiveGrowth({
    initial: electricityCostSavings,
    increaseRate: estimatedYearlyPriceIncrease,
    years: expectedYears,
  });
  const mobilitySavingTillNow = Math.round(mobilitySavings * expectedYears);
  const gasOilBillSavingsTillNow = successiveGrowth({
    initial: gasSavings,
    increaseRate: futureOilGasCostIncrease,
    years: expectedYears,
  });

  const evGridCostTillNow = successiveGrowth({
    initial: evGridCost,
    increaseRate: estimatedYearlyPriceIncrease,
    years: expectedYears,
  });

  const heatpumpGridCostTillNow = successiveGrowth({
    initial: heatpumpGridCost,
    increaseRate: estimatedYearlyPriceIncrease,
    years: expectedYears,
  });

  const gains =
    feedInSavingsTillNow +
    mobilitySavingTillNow +
    gasOilBillSavingsTillNow +
    electricityBillSavingsTillNow;

  const investmentAndCost =
    heatpumpGridCostTillNow + evGridCostTillNow + appliedProjectCost + carBuyingCost;

  const profit = gains - investmentAndCost;

  return {
    profit: Math.round(profit),
    feedInSavingsTillNow,
    gasOilBillSavingsTillNow,
    mobilitySavingTillNow,
    electricityBillSavingsTillNow,
    gains,
    investmentAndCost,
    heatpumpGridCostTillNow,
    evGridCostTillNow,
    appliedProjectCost,
    carBuyingCost,
  };
};
interface ProfitByYearReturn {
  profit: number;
  feedInSavingsTillNow: number;
  gasOilBillSavingsTillNow: number;
  mobilitySavingTillNow: number;
  electricityBillSavingsTillNow: number;
  gains: number;
  investmentAndCost: number;
  heatpumpGridCostTillNow: number;
  evGridCostTillNow: number;
  appliedProjectCost: number;
  carBuyingCost: number;
}
interface YearlySystemProfitReturnType {
  profitByYear: (year: number, isRenting?: boolean) => ProfitByYearReturn;
  profit: number;
}

export const useYearlySystemProfit = (expectedYearFrame?: number): YearlySystemProfitReturnType => {
  const previousYearFrame = useRef(0);

  const [profit, setProfit] = useState(0);
  const rentingData = useSelector(getRentingData);

  const {
    estimatedYearlyPriceIncrease,
    costs: {
      appliedProjectCost,
      componentCosts: { carBuyingCost },
      gridCosts: { evGridCost, heatpumpGridCost },
      savings: { electricityCostSavings, feedInSavings, mobilitySavings, gasSavings },
    },
  } = useSelector(ProjectSelectors.getFinancialDetails);

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);

  const profitByYear = useCallback(
    (expectedYears: number, isRenting: boolean = false) => {
      const feedInSavingsTillNow = Math.round(feedInSavings) * expectedYears;
      const electricityBillSavingsTillNow = successiveGrowth({
        initial: electricityCostSavings,
        increaseRate: estimatedYearlyPriceIncrease,
        years: expectedYears,
      });
      const mobilitySavingTillNow = Math.round(mobilitySavings * expectedYears);
      const gasOilBillSavingsTillNow = successiveGrowth({
        initial: gasSavings,
        increaseRate: configuration.futureOilGasCostIncrease,
        years: expectedYears,
      });

      const evGridCostTillNow = successiveGrowth({
        initial: evGridCost,
        increaseRate: estimatedYearlyPriceIncrease,
        years: expectedYears,
      });

      const heatpumpGridCostTillNow = successiveGrowth({
        initial: heatpumpGridCost,
        increaseRate: estimatedYearlyPriceIncrease,
        years: expectedYears,
      });

      const gains =
        feedInSavingsTillNow +
        mobilitySavingTillNow +
        gasOilBillSavingsTillNow +
        electricityBillSavingsTillNow;

      const investmentAndCost =
        heatpumpGridCostTillNow +
        evGridCostTillNow +
        (!isRenting ? appliedProjectCost : (rentingData?.rate_with_vat || 0) * 12 * expectedYears) +
        carBuyingCost;

      const profit = gains - investmentAndCost;

      return {
        profit: Math.round(profit),
        feedInSavingsTillNow,
        gasOilBillSavingsTillNow,
        mobilitySavingTillNow,
        electricityBillSavingsTillNow,
        gains,
        investmentAndCost,
        heatpumpGridCostTillNow,
        evGridCostTillNow,
        appliedProjectCost,
        carBuyingCost,
      };
    },
    [
      appliedProjectCost,
      carBuyingCost,
      configuration.futureOilGasCostIncrease,
      electricityCostSavings,
      estimatedYearlyPriceIncrease,
      evGridCost,
      feedInSavings,
      gasSavings,
      heatpumpGridCost,
      mobilitySavings,
    ],
  );

  useEffect(() => {
    const sameAsPrevious = previousYearFrame.current === expectedYearFrame;

    if (!expectedYearFrame || sameAsPrevious) {
      setProfit(0);
    } else {
      setProfit(profitByYear(expectedYearFrame).profit);
      previousYearFrame.current = expectedYearFrame;
    }
  }, [expectedYearFrame, profitByYear]);

  return { profitByYear, profit };
};
