import { styled } from "@mui/system";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  useAppSelector,
  useIsComponentAdded,
  useNumberFormat,
  useAddBatteryInverterChange,
} from "src/hooks";

import { getFeedInTarrifByCapacity } from "src/data";
import { getAverageCapacity } from "src/helpers";
import { predModel } from "src/pages/ApplicationPage/hooks";

import { AppActions, ProjectActions } from "src/redux/actionCreators";
import { FeedInReason } from "src/redux/project/types/financial-details";
import { AppSelectors, ProductSelectors, ProjectSelectors } from "src/redux/selectors";

const SelectFeedInWrapper = styled("div")`
  margin-inline: auto;
  .main_class {
    box-sizing: border-box;
    width: 371px;
    height: 27px;
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 5px;

    @media (max-width: 600px) {
      width: auto;
    }
  }
  .same_tab {
    padding-inline: 10px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #2d4764;
    height: 27px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      background: #ffd75d;
      cursor: pointer;
    }
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }
  .seleted {
    background: #ffd75d;
  }
`;

const SelectFeedIn: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filteredSolarPanels = useSelector(ProductSelectors.getFilteredSolarPanels);
  const { quantity } = useSelector(ProjectSelectors.getSolarPanel);

  const capacity = useMemo(() => {
    return getAverageCapacity(filteredSolarPanels);
  }, [filteredSolarPanels]);

  const systemCapacity = (capacity * quantity) / 1000;

  const { selectedFeedInReason } = useSelector(ProjectSelectors.getFinancialDetails);
  const {
    household: {
      percentages: { maxPossibleAutarky: householdMax },
    },
    ev: {
      percentages: { maxPossibleAutarky: evMax },
    },
    heatpump: {
      percentages: { maxPossibleAutarky: heatpumpMax },
    },
  } = useSelector(ProjectSelectors.getModel);

  const reasonChangeHandler = (reason: FeedInReason): void => {
    dispatch(
      ProjectActions.updateFinancialDetails({
        selectedFeedInReason: reason,
      }),
    );

    const newFeedInTarrif = getFeedInTarrifByCapacity(systemCapacity, reason);
    if (newFeedInTarrif) {
      dispatch(
        ProjectActions.updateFinancialDetails({
          feedInTariff: newFeedInTarrif,
        }),
      );
    }

    if (reason === "feed-in-tariff" || reason === "direct-sale-to-3rd-party") {
      dispatch(AppActions.updateAppState({ setAutarkiesToMax: true }));
    }

    if (reason === "full-feed-in") {
      dispatch(AppActions.updateAppState({ setAutarkiesTo0: true }));
    }

    // dispatch(
    //   ProjectActions.updateHousehold({
    //     selfSupply: newHouseholdAutarky,
    //   }),
    // );
    // TODO
    // dispatch(actionCustomAutarkyChange());
  };

  return (
    <SelectFeedInWrapper>
      <div className="main_class">
        <div
          className={selectedFeedInReason === "feed-in-tariff" ? "same_tab seleted" : "same_tab"}
          onClick={() => reasonChangeHandler("feed-in-tariff")}
        >
          {t("FEED IN")}
        </div>
        <div
          className={selectedFeedInReason === "full-feed-in" ? "same_tab seleted" : "same_tab"}
          onClick={() => reasonChangeHandler("full-feed-in")}
        >
          {t("Full-Feed-in")}
        </div>
        <div
          className={
            selectedFeedInReason === "direct-sale-to-3rd-party" ? "same_tab seleted" : "same_tab"
          }
          onClick={() => reasonChangeHandler("direct-sale-to-3rd-party")}
        >
          {t("Direct sale")}
        </div>
      </div>
    </SelectFeedInWrapper>
  );
};

export default SelectFeedIn;
