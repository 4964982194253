import { clone, isNull } from "lodash";

// import { InstallerDB } from "src/db-types";

import { UserState, actionCreators } from "src/redux/user";

export const setInstaller = (
  currentState: UserState,
  { payload }: ReturnType<typeof actionCreators.setUser>,
): UserState => {
  const newState = clone(currentState);
  let installer: any | null = null;

  if (!isNull(payload)) {
    installer = payload;
  }

  newState.installer = installer;
  return newState;
};
