import styled from "styled-components/macro";

interface CardProps {
  noNeedToAlignHeight?: boolean;
  isPad?: boolean;
}
export const Card = styled.div<CardProps>`
  box-shadow: 0px 1.55382px 6.21527px rgba(0, 0, 0, 0.12);
  border-radius: 10.8767px;
  background: white;
  width: 300px;
  margin: 20px;
  max-width: fit-content;
  /* min-height: 420px; */
  /* height: auto; */
  min-height: ${({ isPad }) => {
    if (isPad) {
      return "350px";
    } else {
      return "420px";
    }
  }};
  display: flex;
  flex-direction: column;
  // &:hover {
  //   transform: scale(1.06);
  // }
  margin-inline: 0.5rem;
  @media screen and (max-width: 500px) {
    margin: 5px;
    ${({ noNeedToAlignHeight }) =>
      noNeedToAlignHeight &&
      `
      min-height: initial;
    `}
  }
`;
export const CardHeader = styled.div`
  border-radius: 10.8767px 10.8767px 0 0;
  border-bottom: 1px solid #ffd75d;
  padding: 10px 26px;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 1rem;
  color: #2d4764;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #9d9d9d;
    cursor: pointer;
  }
  .logo_url {
    height: 30px !important;
    padding-right: 10px !important;
    width: 99px !important;
    object-fit: contain !important;
  }
`;
export const Reviews = styled.div`
  margin-bottom: 12px;
  display: flex;
  gap: 8px;
  span {
    font-family: "Nunito";
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.02em;
    color: #2d4764;
  }
`;
export const Location = styled.div`
  margin-bottom: 13px;
  display: flex;
  gap: 8px;
  span {
    display: inline-block;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #2d4764;
    min-width: 150px;

    @media screen and (max-width: 500px) {
      font-size: 14px;
    }
  }
`;
export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 7px 25px 19px;
  h3 {
    font-family: "Nunito";
    font-weight: 700;
    font-size: 20px;
    color: #2d4764;
    margin: 15px 0 10px;

    @media screen and (max-width: 500px) {
      font-size: 16px;
    }
  }
  a,
  button {
    font-family: "Nunito";
    font-weight: 600;
    font-size: 0.8rem;
    color: #2d4764;
  }
  a {
    margin: 5px 0 5px 20px;
    width: fit-content;
    text-decoration: underline;
    img {
      display: inline-block;
      margin-left: 8px;
      margin-bottom: 2px;
    }
    span {
      display: inline-block;
      padding-left: 10px;
    }
  }
  button {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    border: 0.733394px solid #2d4764;
    border-radius: 26.4022px;
    background-color: #ffffff;
    padding: 10px 15px;
    width: fit-content;
    margin: 17px auto 0;
  }
`;
export const Date = styled.div`
  border: 1px solid #666666;
  font-family: "Nunito";
  font-weight: 600;
  font-size: 0.8rem;
  color: #666;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  padding: 5px 30px 5px 20px;
  width: fit-content;
  border-radius: 16px;
  margin: 10px auto 0;
`;

export const FinalPrice = styled.div`
text-align: center;
`
