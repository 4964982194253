import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  Plugin,
  PointElement,
  registerables as registerablesJS,
  Tooltip,
} from "chart.js";
import html2canvas from "html2canvas";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useYearlySystemProfit } from "src/hooks";

import { useMutationObserver } from "src/hooks/useMutationObserver";
import { screenshotPlugin } from "src/shared/components/DonoughtChart/chart-plugins/screenshotPlugin";
import { createGradient } from "src/utils";

import { InvestmentChartWrapepr } from "../InvestmentStyle";

import { ProjectSelectors } from "src/redux/selectors";

import type { ChartData } from "chart.js";

ChartJS.register(...registerablesJS);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  // counter
);
interface InvestMentChartProps {
  setScreenshot?: (image: string) => void;
}

export const InvestMentChart: React.FC<InvestMentChartProps> = ({ setScreenshot }) => {
  const { t } = useTranslation();
  const chartRef = useRef<ChartJS>(null);
  const screenShotRef = useRef(null);

  const captureScreenshot = useCallback(async () => {
    const chart = screenShotRef.current;

    if (!chart) {
      return;
    }

    const canvas = await html2canvas(chart, { useCORS: true });
    const image = canvas.toDataURL("image/png");

    if (typeof setScreenshot === "function") {
      setScreenshot(image);
    }
  }, [setScreenshot]);

  const { expectedReturnTime } = useSelector(ProjectSelectors.getFinancialDetails);

  const [chartData, setChartData] = useState<ChartData<"line">>({
    datasets: [],
  });
  const [plugins, setPlugIn] = useState<any>([]);

  const { profitByYear } = useYearlySystemProfit();

  const data = useMemo(() => {
    return {
      datasets: [
        {
          label: t("Yearly gains"),
          data: [100, 250, 350, 450, 550, 650, 850],
          fill: true,
          lineTension: 0.5,
          type: "line" as const,
        },
      ],
    };
  }, [t]);

  const optionsData = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: window.innerWidth < 500 ? 1 / 1 : 2 / 1,
      interaction: {
        mode: "index",
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            borderDash: [8, 4],
            display: true,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
          position: "top",
          align: "start",
          labels: {
            usePointStyle: "true",
            pointStyle: "circle",
          },
        },
        title: {
          display: false,
          text: "Chart.js Line Chart",
        },
        screenshot_plugin: {
          screenshotCallback: () => {
            void captureScreenshot();
          },
        },
      },
    };
  }, [captureScreenshot]);

  const [options, setOpetions] = useState<any>({ ...optionsData });

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      ...data,
      labels: [...new Array(expectedReturnTime)].map((data, index) => {
        return new Date().getFullYear() + index;
      }),
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        data: [...new Array(expectedReturnTime)].map((data, index) => {
          return profitByYear(index + 1).profit;
        }),
        borderColor: ["rgba(255, 194, 32, 1)"],
        backgroundColor: createGradient(chart.ctx, chart.chartArea, "#FFFFFF", "#FFC220"),
      })),
    };

    setChartData(chartData);

    setPlugIn([
      {
        id: "custom_canvas_background_color",
        beforeDraw: (chartInstance: any) => {
          const ctx = chartInstance.chart.ctx;
          ctx.fillStyle = "lightgreen";
          ctx.fillRect(30, 200, 955, 50);
        },
      },
    ]);
    setOpetions((prevOptionsData: any) => {
      return {
        ...prevOptionsData,
        plugins: {
          ...prevOptionsData.plugins,
          lines: {
            id: "lines",
            beforeDatasetsDraw() {
              const { ctx } = chart;
              // breakline
              ctx.beginPath();
              ctx.strokeStyle = "grey";
              ctx.lineWidth = 1;
              ctx.setLineDash([6, 6]);
              ctx.moveTo(100, 0);
              ctx.lineTo(100, 450);
              ctx.stroke();
              ctx.restore();
            },
          },
        },
      };
    });
  }, [data, expectedReturnTime, optionsData, profitByYear]);

  return (
    <InvestmentChartWrapepr ref={screenShotRef}>
      {!setScreenshot && (
        <div className="make_flex">
          <div className="circle"></div>
          <div className="title">{t("Yearly gains")}</div>
        </div>
      )}
      <Chart
        ref={chartRef}
        options={options}
        type="line"
        data={chartData}
        plugins={[...plugins, screenshotPlugin]}
      />
    </InvestmentChartWrapepr>
  );
};
