import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ProductWithPrice } from "src/db-types";
import { filterProducts } from "src/helpers/product";
import { getInverterBuyInfo, getProductBuyInfo } from "src/helpers/product/getProductBuyInfo";

import { ProductActions } from "src/redux/actionCreators";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

export const useUpdateFilteredProducts = (): void => {
  const dispatch = useDispatch();

  const projectType = useSelector(ProjectSelectors.getProjectType);

  const solarPanelProducts = useSelector(ProductSelectors.getSolarPanels);
  const inverterProducts = useSelector(ProductSelectors.getInverters);
  const wallboxProducts = useSelector(ProductSelectors.getWallboxes);
  const heatpumpProducts = useSelector(ProductSelectors.getHeatpumps);
  const batteryProducts = useSelector(ProductSelectors.getBatteries);
  const additionalSoftwareProducts = useSelector(ProductSelectors.getAdditionalSoftwares);
  const additionalHardwareProducts = useSelector(ProductSelectors.getAdditionalHardwares);
  const emsProducts = useSelector(ProductSelectors.getEms);


  const {
    battery,
    heatpump,
    inverter,
    solarPanel,
    wallbox,
    additionalSoftware,
    additionalHardware,
    EMS
  } = useSelector(ProjectSelectors.getComponents);

  /**
   * Battery Preferences
   */
  useEffect(() => {
    if (battery.quantity === 0) {
      dispatch(
        ProductActions.setFilteredProducts({
          batteries: [],
        }),
      );
      return;
    }
    const filtered = filterProducts({
      filters: battery.preferences,
      products: batteryProducts,
    });

    const pricedProducts: ProductWithPrice[] = filtered.map((prod) => {
      return {
        ...prod,
        buyInfo: getProductBuyInfo({ product: prod, projectType }),
      };
    });

    dispatch(
      ProductActions.setFilteredProducts({
        batteries: pricedProducts,
      }),
    );
  }, [battery.preferences, battery.quantity, batteryProducts, dispatch, projectType]);

  /**
   * Heatpump preferences
   */
  useEffect(() => {
    if (heatpump.quantity === 0) {
      dispatch(
        ProductActions.setFilteredProducts({
          heatpumps: [],
        }),
      );
      return;
    }
    const filtered = filterProducts({
      filters: heatpump.preferences,
      products: heatpumpProducts,
    });

    const pricedProducts: ProductWithPrice[] = filtered.map((prod) => {
      return {
        ...prod,
        buyInfo: getProductBuyInfo({ product: prod, projectType }),
      };
    });

    dispatch(
      ProductActions.setFilteredProducts({
        heatpumps: pricedProducts,
      }),
    );
  }, [dispatch, heatpump.preferences, heatpump.quantity, heatpumpProducts, projectType]);

  /**
   * Solarpanel preferences
   */
  useEffect(() => {
    if (solarPanel.quantity === 0) {
      dispatch(
        ProductActions.setFilteredProducts({
          solarPanels: [],
        }),
      );
      return;
    }
    const filtered = filterProducts({
      filters: solarPanel.preferences,
      products: solarPanelProducts,
    });

    const pricedProducts: ProductWithPrice[] = filtered.map((prod) => {
      return {
        ...prod,
        buyInfo: getProductBuyInfo({ product: prod, projectType, quantity: solarPanel.quantity }),
      };
    });

    dispatch(
      ProductActions.setFilteredProducts({
        solarPanels: pricedProducts,
      }),
    );
  }, [dispatch, projectType, solarPanel.preferences, solarPanel.quantity, solarPanelProducts]);

  /**
   * Inverter preferences
   */
  useEffect(() => {
    if (inverter.quantity === 0) {
      dispatch(
        ProductActions.setFilteredProducts({
          inverters: [],
        }),
      );
      return;
    }
    const filtered = filterProducts({
      filters: inverter.preferences,
      products: inverterProducts,
    });

    const pricedProducts: ProductWithPrice[] = filtered.map((prod) => {
      return {
        ...prod,
        buyInfo: getInverterBuyInfo({
          product: prod,
          projectType,
          solarPanelCount: solarPanel.quantity,
        }),
      };
    });

    dispatch(
      ProductActions.setFilteredProducts({
        inverters: pricedProducts,
      }),
    );
  }, [
    dispatch,
    inverter.preferences,
    inverter.quantity,
    inverterProducts,
    projectType,
    solarPanel.quantity,
  ]);

  /**
   * Wallbox preferences
   */
  useEffect(() => {
    if (wallbox.quantity === 0) {
      dispatch(
        ProductActions.setFilteredProducts({
          wallboxes: [],
        }),
      );
      return;
    }
    const filtered = filterProducts({
      filters: wallbox.preferences,
      products: wallboxProducts,
    });

    const pricedProducts: ProductWithPrice[] = filtered.map((prod) => {
      return {
        ...prod,
        buyInfo: getProductBuyInfo({ product: prod, projectType }),
      };
    });

    dispatch(
      ProductActions.setFilteredProducts({
        wallboxes: pricedProducts,
      }),
    );
  }, [dispatch, projectType, wallbox.preferences, wallbox.quantity, wallboxProducts]);

  /**
   * Additional Software Preferences
   */
  useEffect(() => {
    if (additionalSoftware.quantity === 0) {
      dispatch(
        ProductActions.setFilteredProducts({
          additionalSoftware: [],
        }),
      );
      return;
    }
    const filtered = filterProducts({
      filters: additionalSoftware.preferences,
      products: additionalSoftwareProducts,
    });

    const pricedProducts: ProductWithPrice[] = filtered.map((prod) => {
      return {
        ...prod,
        buyInfo: getProductBuyInfo({ product: prod, projectType }),
      };
    });

    dispatch(
      ProductActions.setFilteredProducts({
        additionalSoftware: pricedProducts,
      }),
    );
  }, [
    additionalSoftware.preferences,
    additionalSoftware.quantity,
    additionalSoftwareProducts,
    dispatch,
    projectType,
  ]);

  /**
   * Additional Hardware Preferences
   */
  useEffect(() => {
    if (additionalHardware.quantity === 0) {
      dispatch(
        ProductActions.setFilteredProducts({
          additionalHardware: [],
        }),
      );
      return;
    }
    const filtered = filterProducts({
      filters: additionalHardware.preferences,
      products: additionalHardwareProducts,
    });

    const pricedProducts: ProductWithPrice[] = filtered.map((prod) => {
      return {
        ...prod,
        buyInfo: getProductBuyInfo({ product: prod, projectType }),
      };
    });

    dispatch(
      ProductActions.setFilteredProducts({
        additionalHardware: pricedProducts,
      }),
    );
  }, [
    additionalHardware.preferences,
    additionalHardware.quantity,
    additionalHardwareProducts,
    dispatch,
    projectType,
  ]);

  /**
   * EMS Preferences
   */
  useEffect(() => {
    if (EMS.quantity === 0) {
      dispatch(
        ProductActions.setFilteredProducts({
          EMS: [],
        }),
      );
      return;
    }
    const filtered = filterProducts({
      filters: EMS.preferences,
      products: emsProducts,
    });

    const pricedProducts: ProductWithPrice[] = filtered.map((prod) => {
      return {
        ...prod,
        buyInfo: getProductBuyInfo({ product: prod, projectType }),
      };
    });

    dispatch(
      ProductActions.setFilteredProducts({
        EMS: pricedProducts,
      }),
    );
  }, [
    EMS.preferences,
    EMS.quantity,
    additionalHardwareProducts,
    dispatch,
    projectType,
  ]);
};
