import { Switch } from "@mui/material";
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

// import { Container } from "./ApplicationStep3.styles";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useTranslation } from "react-i18next";
import { make3dTransformValue } from "react-quick-pinch-zoom";
import { useSelector } from "react-redux";
import Slider from "react-slick";

import { useAppSelector, useWindowDimensions } from "src/hooks";

import Loader from "src/assets/loader.gif";
import { FullScreenIcon } from "src/assets/step3";
import { DeleteItIcon3 } from "src/assets/svgs";
import { InfoIcon } from "src/shared/components";
import { Bar } from "src/shared/components/HorizontalBarCardChart/HorizontalBarCardChart.styles";
import { isValidURL } from "src/utils";

import { DeleteModelButton, GreenButton, InnerContainer } from "./ApplicationStep3.styles";
import Common3dViewer from "./components/3DViewer/components/3DViewerLeft/Common3dViewer";
import {
  MainContainer,
  ObjPreview,
  StyledQuickPinchZoom,
  StyledSwitch,
  TopPreviewContainer,
  StyledLoader,
} from "./sliderStyled";

import { getAddress, getProject } from "src/redux/project/selectors";
import { ProjectSelectors } from "src/redux/selectors";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import useRequestBakeModelHook from "src/hooks/useRequestBakeModel";
import { Tab } from "src/shared/components/TabBar/TabBar";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ThreeDViewerWithImagesProps {
  setCurrentViewer: (_: any) => void;
  TABS: Tab[];
}

const ThreeDViewerWithImages: React.FC<ThreeDViewerWithImagesProps> = ({
  TABS,
  setCurrentViewer,
}) => {
  const { t } = useTranslation();
  const [nav1, setNav1] = useState<any>(null);
  const [nav2, setNav2] = useState<any>(null);
  const [view, setView] = useState<any>(false);
  const [isDronImages, setIsDronImages] = useState(false);
  const [activeSlide, setActiveSlide] = useState<any>(0);
  const slider1Ref = useRef<any>(null);
  const slider2Ref = useRef<any>(null);
  const quoteProfile = useSelector(ProjectSelectors.getQuoteProfile);
  const address = useSelector(getAddress);
  const selectedDronFiles = useAppSelector(ProjectSelectors.getSelectedFiles);
  const handle = useFullScreenHandle();
  const maxWidth3DViewerWithImages = useSelector(ProjectSelectors.getMaxWidth3DViewerWithImages);
  const maxWidth3dViwer = useSelector(ProjectSelectors.getMaxWidth3dViewer);

  const [zoomLevel, setZoomLevel] = useState(1);
  const dronImageDetails = useAppSelector(ProjectSelectors.getDroneData);
  const { width } = useWindowDimensions();

  const threeDModels = useSelector(ProjectSelectors.getThreeDModels);

  const project = useSelector(getProject);
  const houseImageDetails = useAppSelector(ProjectSelectors.getDroneData);
  const { requestBakeModel } = useRequestBakeModelHook();

  useEffect(() => {
    setNav1(slider1Ref.current);
    setNav2(slider2Ref.current);
  }, []);

  // const imgURLs = useMemo(() => {
  //   const providerImages = quoteProfile?.imgURLs.filter((img) => {
  //     const fileExtension: any = img?.split(".").pop()?.toLowerCase();
  //     const isInvalidExtension = ["obj", "mtl", "pvprj"].includes(fileExtension);
  //     if (isInvalidExtension) {
  //       return false;
  //     }
  //     if (img.includes("Destination") && isValidURL(img)) {
  //       if (address.city === "Gundelfingen") {
  //         return !img.includes("Baked") && isValidURL(img);
  //       } else {
  //         return img.includes("Screenshot");
  //       }
  //     }
  //     return isValidURL(img);
  //   });
  //   const dronImages = dronImageDetails?.imageURLs.map((img: any) => {
  //     return img.url;
  //   });

  //   if (isDronImages) {
  //     return dronImages;
  //   }

  //   return providerImages;
  // }, [address, quoteProfile.imgURLs, dronImageDetails?.imageURLs, isDronImages]);

  // const imgObjURLs: any = useMemo(() => {
  //   return quoteProfile.imgURLs.find((img) => img.includes(".obj"));
  // }, [quoteProfile.imgURLs]);

  const imgObjURLs = useMemo(() => {
    if (threeDModels.active) {
      return threeDModels.models[threeDModels.active]?.obj;
    }
    return null;
  }, [threeDModels]);

  const imgURLs = useMemo(() => {
    if (threeDModels?.active && threeDModels?.models[threeDModels?.active]?.images) {
      return threeDModels?.models[threeDModels?.active]?.images || [];
    }
    return []
  }, [threeDModels]);

  const handleChange = (value: any) => {
    setView(value);
  };

  const imgRef = useRef<any>();

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img?.style.setProperty("transform", value);
    }
  }, []);

  const isModelVisible = useMemo(
    () => project.bakeModel.id && !project?.bakeModel?.artifacts?.length,
    [project.bakeModel],
  );

  const [imageLoaded, setImageLoaded] = useState(Array(imgURLs.length).fill(false));

  const handleImageLoad = (index: any) => {
    const newImageLoaded = [...imageLoaded];
    newImageLoaded[index] = true;
    setImageLoaded(newImageLoaded);
  };

  return (
    <MainContainer>
      <TopPreviewContainer>
        <ObjPreview maxWidth3DViewerWithImages={maxWidth3DViewerWithImages}>
          {imgObjURLs ? (
            <Common3dViewer
              from3DViwer={false}
              maxWidth3DViewer={maxWidth3dViwer}
              maxWidth3DViewerWithImages={maxWidth3DViewerWithImages}
            />
          ) : (
            <img
              src={imgURLs[activeSlide] || imgURLs[imgURLs.length - 1]}
              alt={`Image ${activeSlide + 1}`}
              width="100%"
              style={{
                objectFit: "cover",
                height: "100%",
                filter: "blur(2.5px)",
              }}
            />
          )}
          {isModelVisible ? (
            <InnerContainer style={{ position: "absolute", top: "40%", left: "5%" }}>
              {project.bakeModel.id && (
                <DeleteModelButton>
                  <DeleteItIcon3 />
                </DeleteModelButton>
              )}
              <div className="head">
                {project?.bakeModel?.artifacts?.length
                  ? t("hurray, Your Pv-model has been sucessfully created")
                  : t(
                      "Your photogrammetric 3D-Model is being created and will be available in about",
                    ) +
                    `${JSON.parse(JSON.stringify(project.trackProgress.time || ""))}` +
                    " " +
                    t("Minutes_Backe_model")}
                <InfoIcon />
              </div>
              <Bar
                bgColor={"#FFD75D"}
                topColor={"#4D7E6D"}
                topPercent={Number(project.trackProgress.percentage)}
              >
                <div className="top-bar"></div>
              </Bar>
            </InnerContainer>
          ) : (
            !project.bakeModel.id &&
            !imgObjURLs && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translateY(-50%) translateX(-50%)",
                }}
              >
                <GreenButton
                  onClick={() => {
                    if (
                      selectedDronFiles?.length >= 12 ||
                      houseImageDetails.imageURLs.length >= 12
                    ) {
                      requestBakeModel();
                    } else {
                      setCurrentViewer(TABS[1].key);
                    }
                  }}
                >
                  {`${t("ADD 3d-Model / PV Planning")}`}
                </GreenButton>
              </div>
            )
          )}
        </ObjPreview>
        {!maxWidth3DViewerWithImages && (
          <>
            {" "}
            <Slider
              asNavFor={nav2}
              ref={slider1Ref}
              className="preview-slider"
              slidesToShow={1}
              arrows={false}
              afterChange={(current: number) => setActiveSlide(current)}
            >
              {imgURLs?.map((imageUrl, index) => (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    position: "relative",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={imageUrl}
                    alt={`Image ${index + 1}`}
                    width="100%"
                    className={"preview-img"}
                  />

                  {dronImageDetails?.imageURLs?.length > 0 && (
                    <StyledSwitch>
                      <Switch
                        checked={isDronImages}
                        onChange={() => {
                          setImageLoaded(Array(imgURLs.length).fill(false));
                          setIsDronImages(!isDronImages);
                        }}
                      />
                      <label> {t("Drone Images")}</label>
                    </StyledSwitch>
                  )}
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                      height: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      gap: "5px",
                      zIndex: "999",
                    }}
                  >
                    <img
                      src={FullScreenIcon}
                      alt="zoom"
                      onClick={handle.enter}
                      height={25}
                      width={25}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              ))}
            </Slider>
            <div style={view ? { display: "block" } : { display: "none" }}>
              <FullScreen handle={handle} onChange={handleChange}>
                <div style={{ width: "100%", position: "relative", height: "100%" }}>
                  <StyledQuickPinchZoom onUpdate={onUpdate}>
                    <img ref={imgRef} src={imgURLs[activeSlide] || imgURLs[imgURLs.length - 1]} />
                  </StyledQuickPinchZoom>
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                      height: "100px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={FullScreenIcon}
                      alt="zoom"
                      onClick={handle.exit}
                      height={25}
                      width={25}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </FullScreen>
            </div>{" "}
          </>
        )}
      </TopPreviewContainer>
      <Slider
        asNavFor={nav1}
        ref={slider2Ref}
        slidesToShow={width > 500 ? 5 : 2.5}
        swipeToSlide={true}
        focusOnSelect={true}
        arrows={false}
        className="main-slider"
      >
        {imgURLs?.map((imageUrl, index) => (
          <div
            key={index}
            className={`image-container ${imageLoaded[index] ? "loaded" : "loading"}`}
          >
            {!imageLoaded[index] && (
              <StyledLoader>
                <img src={Loader} alt="loader" />
              </StyledLoader>
            )}

            <img
              src={imageUrl}
              alt={`Image ${index + 1}`}
              height={180}
              onLoad={() => handleImageLoad(index)}
            />
          </div>
        ))}
      </Slider>
    </MainContainer>
  );
};

export default ThreeDViewerWithImages;
