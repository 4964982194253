import { clone } from "lodash";

import { actionCreators } from "../..";
import { SolarAPIState } from "../types";

export const setSolarAPIData = (
  currentState: SolarAPIState,
  { payload }: ReturnType<typeof actionCreators.setSolarAPIData>,
): SolarAPIState => {
  const newState = clone(currentState);
  newState.solarAPIData = payload;
  return newState;
};
