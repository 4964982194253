import Slider from "@mui/material/Slider";
import { Checkbox } from "antd";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { defaultFilters, useAddBatteryInverterChange } from "src/hooks";

import { BatteryIcon } from "src/assets/svgs";
import { PrimaryButton } from "src/shared/StyledComponents";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";
import { getNumber } from "src/utils";

import * as S from "./SelectBattery.styles";

import { ProjectActions } from "src/redux/actionCreators";

interface SelectBatteryProps {
  onClose: () => void;
}
export const SelectBattery: React.FC<SelectBatteryProps> = (props: SelectBatteryProps) => {
  const { onClose } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [batteryCapacity, setBatteryCapacity] = useState(8);
  const [shouldExcludeCost, setShouldExcludeCost] = useState(false);

  const { convertInverterToHybrid } = useAddBatteryInverterChange();

  const sliderChangeHandler = (e: any): void => {
    setBatteryCapacity(e.target.value);
  };

  const selectBtnClickHandler = (): void => {
    dispatch(
      ProjectActions.addBattery({
        extendedWarranty: false,
        item: null,
        quantity: 1,
        isExcludeCost: shouldExcludeCost,
        preferences: {
          ...defaultFilters,
          applicableMaxCapacity: batteryCapacity * 1000,
        },
      }),
    );

    const result = convertInverterToHybrid();
    if (!result) {
      dispatch(ProjectActions.deleteBattery());
    }

    onClose();
  };

  function getAriaValueText(value: number): string {
    return `${value}°C`;
  }

  function valueLabelFormat(value: number): number {
    return value;
  }

  const closeBtnClickHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <S.MainContainer>
      <S.SubContainer>
        <div className="same_part">
          <div className="flexing">
            <BatteryIcon />
            <div className="text_first">{t("BATTERY")}</div>
          </div>
          <div className="icon" onClick={closeBtnClickHandler}>
            <div></div>
          </div>
        </div>
        <ParameterStyles.Line hasSlider className="evKm">
          <ParameterStyles.Label hasSlider>{t("BATTERY CAPACITY")}</ParameterStyles.Label>
          <ParameterStyles.Input>
            <Slider
              aria-label="Restricted values"
              defaultValue={5}
              valueLabelFormat={valueLabelFormat}
              getAriaValueText={getAriaValueText}
              step={1}
              valueLabelDisplay="auto"
              max={20}
              style={{
                color: "rgb(45, 71, 100)",
                padding: "0px",
                borderRadius: "2px",
              }}
              // marks={capacities}
              onChange={sliderChangeHandler}
            />
          </ParameterStyles.Input>
          <ParameterStyles.Value hasSlider>{getNumber(batteryCapacity)} kWh</ParameterStyles.Value>
        </ParameterStyles.Line>
        <div className="battery-cost">
          <Checkbox
            checked={shouldExcludeCost}
            onChange={(e) => {
              setShouldExcludeCost(e.target.checked);
            }}
          />
          <p className="lable">{t("exclude battery cost")}</p>
        </div>
      </S.SubContainer>
      <S.SubContainer3>
        <PrimaryButton
          style={{
            width: "200px",
          }}
          onClick={selectBtnClickHandler}
        >
          {t("SELECT BATTERY")}
        </PrimaryButton>
      </S.SubContainer3>
    </S.MainContainer>
  );
};
