import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { useResetPassAPI } from "src/hooks/apis";

import { AUTH_MODES, AuthPopupProps } from "../types";

import cssClasses from "./login.module.css";

import {
  POPUP_KEYS,
  actionCreators as PopupActions,
  selectors as PopupSelectors,
} from "src/redux/popups";

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [passwordReset, setPasswordReset] = useState({
    newPass: "",
    confirmPass: "",
  });

  const { error, fetchData, loading, response } = useResetPassAPI();

  useEffect(() => {
    if (!response) return;

    toast.success(t("Password reset successful"));
    const data: AuthPopupProps = {
      authMode: AUTH_MODES.login,
    };
    dispatch(PopupActions.updateData(POPUP_KEYS.auth, data));
  }, [dispatch, response, t]);

  useEffect(() => {
    if (!error) return;

    toast.error(t("Password reset failed! Please try again"));
  }, [error, t]);

  const submitHandler = (): void => {
    if (loading) return;

    if (passwordReset.newPass !== passwordReset.confirmPass) {
      toast.error(t("Password doesn't match please check"));
      return;
    }
    const search = window.location.search;
    const query = new URLSearchParams(search);
    void fetchData({
      ...BackendApis.resetPass(query.get("token") ?? ""),
      data: {
        password: passwordReset.newPass,
      },
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <div className={cssClasses.loginContainer}>
        <TextField
          fullWidth
          id="email-form-field"
          label={t("Enter a new password")}
          variant="outlined"
          onChange={(e: any) => {
            const data = {
              ...passwordReset,
              newPass: e.target.value,
            };
            setPasswordReset(data);
          }}
        />
        <TextField
          fullWidth
          id="password-form-field"
          label={t("Confirm your password")}
          type="password"
          variant="outlined"
          onChange={(e: any) => {
            const data = {
              ...passwordReset,
              confirmPass: e.target.value,
            };
            setPasswordReset(data);
          }}
        />
      </div>
      <button className={cssClasses.loginButton2} onClick={submitHandler} disabled={loading}>
        {t("Reset Your Password")}
      </button>
    </div>
  );
};
export default ResetPassword;
