import { useTranslation } from "react-i18next";

import { SatelliteIcon } from "src/assets/svgs";

const SatelliteLabel: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <SatelliteIcon />
      </div>
      <div>{t("SATELLITE")}</div>
    </>
  );
};

export default SatelliteLabel;
