import { Slider } from "antd";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PopupActions } from "src/redux";
import { ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { ProjectSelectors } from "src/redux/selectors";
import { ParameterStyles } from "src/shared/StyledComponents";
import { MasterInput } from "src/shared/components";

interface IExpectedTenants {
  title?: string;
}

const ExpectedTenants: React.FC<IExpectedTenants> = ({
  title = "EXPECTED NUMBER OF PARTICIPATING TENANTS",
}: IExpectedTenants) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { household: householdModel } = useSelector(ProjectSelectors.getModel);
  const {
    percentages: { autarky, maxPossibleAutarky },
  } = householdModel;
  const { expectedTenants } = useSelector(ProjectSelectors.getHousehold);

  const handleInputChange = useCallback(
    (value: number): void => {
      dispatch(
        ProjectActions.updateHousehold({
          expectedTenants: value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <ParameterStyles.Line
      hasSlider
      style={{ paddingInline: "10px", width: "93%" }}
    >
      <ParameterStyles.Label style={{ color: "black" }}>{t(title)}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider
          onChange={handleInputChange}
          max={Math.round(maxPossibleAutarky)}
          value={Math.round(expectedTenants)}
        />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <ParameterStyles.ValueWrapper style={{ marginRight: "0px" }}>
          <MasterInput value={Math.round(expectedTenants)} onChange={handleInputChange} />
        </ParameterStyles.ValueWrapper>
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default ExpectedTenants;
