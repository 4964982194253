import { Checkbox } from "antd";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useAppSelector } from "src/hooks";

import { AddNewIcon } from "src/assets/svgs";
import { filterInStock, getUniqueCarId, sortProductsByMaxCapacity } from "src/helpers";
import { ParameterStyles, PrimaryButton, SecondaryButton } from "src/shared/StyledComponents";
import { getNumber } from "src/utils";

import { EVBox } from "./components";
import * as S from "./EVConfigurationBox.styles";

import { PopupActions, ProjectActions } from "src/redux/actionCreators";
import { CarComponent } from "src/redux/project";
import { defaultCarConfiguration, defaultComponent } from "src/redux/project/default-values";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

interface EVConfigurationBoxProps {
  editId?: string;
  isAdd?: boolean;
  onClose?: () => void;
}
const EVConfigurationBox: React.FC<EVConfigurationBoxProps> = (props: EVConfigurationBoxProps) => {
  const { editId, isAdd, onClose } = props;
  const isEdit = !!editId;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cars = useAppSelector(ProjectSelectors.getCars);

  const wallboxProducts = useAppSelector(ProductSelectors.getWallboxes);

  const [currSessionsCars, setCurrSessionCars] = useState<CarComponent[]>([]);

  useEffect(() => {
    setCurrSessionCars((p) => {
      const newCurrSessionCars: CarComponent[] = [];

      if (editId) {
        const car = cars.find((c) => c.id === editId);
        if (car) newCurrSessionCars.push(car);
      }
      for (let i = 0; i < p.length; i++) {
        if (p[i].id === editId) continue;

        const id = p[i].id;
        const car = cars.find((c) => c.id === id);
        if (!car) cars.push(p[i]);
        else newCurrSessionCars.push(car);
      }

      return newCurrSessionCars;
    });
  }, [editId, cars]);

  const addCarBtnClickHandler = useCallback(() => {
    const newCarId = getUniqueCarId();
    const newCar: CarComponent = {
      id: newCarId,
      configuration: cloneDeep(defaultCarConfiguration),
      ...cloneDeep(defaultComponent),
      name: newCarId,
      quantity: 1,
      isLeasing: false,
    };

    dispatch(ProjectActions.addCar(newCar));
    setCurrSessionCars((p) => [...p, newCar]);
  }, [dispatch]);

  useEffect(() => {
    if (!isAdd) return;
    addCarBtnClickHandler();
  }, [addCarBtnClickHandler, dispatch, isAdd]);

  const { isExcludeCost } = useAppSelector(ProjectSelectors.getWallbox);

  const selectCarBtnClickHandler = (): void => {
    dispatch(
      PopupActions.openCarSelector({
        isAdd: true,
        addSettings: {
          isMultipleAdd: true,
        },
      }),
    );
  };

  const selectWallboxBtnClickHandler = useCallback(() => {
    const inStockProducts = filterInStock(wallboxProducts);
    const sortedProducts = sortProductsByMaxCapacity(inStockProducts);

    const totalConsumption = currSessionsCars.reduce(
      (acc, car) => acc + car.configuration.evYearlyConsumption,
      0,
    );
    const matchingWallbox = sortedProducts.find(
      (p) => getNumber(p.applicableMaxCapacity) >= totalConsumption,
    );
    if (!matchingWallbox) {
      toast.warn(`No product available for ${totalConsumption} consumption`);
      return;
    }

    dispatch(
      ProjectActions.updateWallbox({
        item: matchingWallbox,
        quantity: 1,
      }),
    );

    if (onClose) onClose();
  }, [wallboxProducts, currSessionsCars, dispatch, onClose]);

  const removeCarsAddedThisSession = useCallback(() => {
    if (isAdd) {
      dispatch(ProjectActions.deleteWallbox());
    }

    const startInd = isAdd ? 0 : 1;
    for (let i = startInd; i < currSessionsCars.length; i++) {
      dispatch(ProjectActions.deleteCar(currSessionsCars[i].id));
    }
  }, [currSessionsCars, dispatch, isAdd]);

  const closeBtnClickHandler = useCallback(() => {
    if (isAdd) removeCarsAddedThisSession();
    if (onClose) onClose();
  }, [isAdd, onClose, removeCarsAddedThisSession]);

  const removeFromCurrSessionById = useCallback((id: string) => {
    setCurrSessionCars((p) => p.filter((c) => c.id !== id));
  }, []);

  const realCarCouldBeAdded = useMemo(() => {
    return currSessionsCars.filter((c) => c.id !== "wallbox").some((c) => c.item === null);
  }, [currSessionsCars]);

  return (
    <S.MainContainer>
      <S.SubContainer>
        <div className="same_part">
          <div className="text_first">
            {isAdd && t("E-mobility configuration")}
            {isEdit && t("Change E-mobility configuration details")}
          </div>
          <div className="icon" onClick={closeBtnClickHandler}>
            <div></div>
          </div>
        </div>
        {currSessionsCars.map((data: CarComponent, ind) => {
          return (
            <>
              <EVBox
                car={data}
                key={data.id}
                showEMobilityTitle={cars.length === 1 && !cars[0].item}
                onDelete={removeFromCurrSessionById}
                showCloseButton={data.id !== editId}
              />
              {ind !== currSessionsCars.length - 1 && <ParameterStyles.SectionDivider />}
            </>
          );
        })}
      </S.SubContainer>
      <S.SubContainer2 onClick={addCarBtnClickHandler}>
        <AddNewIcon />
        <S.SubContainerText1>{t("ADD ANOTHER CAR")}</S.SubContainerText1>
      </S.SubContainer2>
      <div className="heatpump-cost">
        <Checkbox
          onChange={(e) =>
            dispatch(
              ProjectActions.updateWallbox({
                isExcludeCost: e.target.checked,
              }),
            )
          }
          checked={isExcludeCost}
        />
        <p className="lable">{t("exclude wallbox cost")}</p>
      </div>
      {isAdd && (
        <S.SubContainer3>
          <SecondaryButton
            style={{ flex: 1, marginInline: 0 }}
            onClick={selectWallboxBtnClickHandler}
          >
            {t("Add e-mobility")}
          </SecondaryButton>
          {realCarCouldBeAdded && (
            <PrimaryButton style={{ flex: 1, marginInline: 0 }} onClick={selectCarBtnClickHandler}>
              {t("SELECT CAR")}
            </PrimaryButton>
          )}
        </S.SubContainer3>
      )}
      {/* TODO <CarSelector
				open={open1}
				setOpen={setOpen1}
				setSelectProduct={setSelectProduct}
				type="cars"
				setWallBoxParts={setWallBoxParts}
				setIsAdd={setIsAdd}
				isAdd={isAdd}
				carData={carsData}
				data={data}
				iscomingFromWallComponent={true}
				coming={true}
			/> */}
    </S.MainContainer>
  );
};

export default EVConfigurationBox;
