import { Slider } from "antd";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PopupActions } from "src/redux";
import { ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { ProjectSelectors } from "src/redux/selectors";
import { ParameterStyles } from "src/shared/StyledComponents";
import { MasterInput } from "src/shared/components";

const GeneralElectricityConsumption: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { generalElectricity } = useSelector(ProjectSelectors.getHousehold);
  const { household: householdModel } = useSelector(ProjectSelectors.getModel);
  const {
    percentages: { autarky, maxPossibleAutarky },
  } = householdModel;

  const handleInputChange = useCallback(
    (value: number): void => {
      dispatch(
        ProjectActions.updateHousehold({
          generalElectricity: value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <ParameterStyles.Line
      hasSlider
      style={{ paddingInline: "10px", width: "93%" }}
    >
      <ParameterStyles.Label style={{ color: "black" }}>
        {t("GENERAL ELECTRICITY CONSUMPTION")}
      </ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider
          onChange={handleInputChange}
          max={Math.round(15000)}
          value={Math.round(generalElectricity)}
          min={500}
        />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <ParameterStyles.ValueWrapper style={{ marginRight: "0px" }}>
          <MasterInput
            value={Math.round(generalElectricity)}
            onChange={handleInputChange}
            label="kWh"
          />
        </ParameterStyles.ValueWrapper>
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default GeneralElectricityConsumption;
