import styled from "styled-components/macro";
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 100%;
`;
export const SubContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  padding-bottom: 10px;
  border-bottom: 0.5px dashed #969696;
  padding-right: 8px;
  position: relative;
`;
export const SubContainerCopy = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  gap: 10px;
  padding-bottom: 8px;
`;
export const SubContainer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  margin-top: 15px;
`;
export const SubContainer2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
interface CircleItemProps {
  colorName: string;
}
interface MainTextProps {
  width?: string;
}
export const Circle = styled.div<CircleItemProps>`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: ${(props) => {
    return props.colorName;
  }};
`;
export const CostText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #2d4764;
`;
export const MainText = styled.div<MainTextProps>`
  font-family: "Nunito";
  font-style: normal;
  line-height: 17px;
  font-weight: 600;
  font-size: 14px;
  color: #2d4764;
  width: ${(props) => {
    if (props.width) {
      return props.width;
    } else {
      return "48%";
    }
  }};
`;
export const MainText1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #2d4764;
`;
interface BarItemProps {
  height: string;
  bottom: string;
}
interface BarItemProps1 {
  height: string;
}
export const Bar1 = styled.div<BarItemProps>`
  width: 15px;
  max-height: 70px;
  height: ${(props) => {
    return props.height;
  }};
  background: #ffd75d;
  position: absolute;
  right: 12px;
  bottom: ${(props) => {
    return props.bottom;
  }};
`;
export const Bar2 = styled.div<BarItemProps1>`
  width: 15px;
  max-height: 70px;
  height: ${(props) => {
    return props.height;
  }};
  background: #df6569;
  position: absolute;
  right: "33px";
`;
