// import { GoogleHandler } from "src/api/auth";
// import { getProjectDetails } from "src/helpers/Selector";
// import { useProjectTools } from "src/hooks/useProjectTools";
import { CircularProgress } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { useAppDispatch, useNaivgateAbsolute } from "src/hooks";

import { convertUserFromDB } from "src/db-converters";
import { useGoogleLoginAPI } from "src/hooks/apis";
import { Mixpanel } from "src/mix-panel/mixpanel";
import * as trackingEvents from "src/mix-panel/trackingEvents";
import { getErrorMessage, set50MinsGoogleRefresh } from "src/utils";

import { AUTH_MODES, AuthMethod } from "../types";

import cssClasses from "./authButtons.module.css";
import Google from "./images/Google Logo.png";

import { PopupActions, UserActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { PopupSelectors } from "src/redux/selectors";
import { UserAuthStatus } from "src/redux/user";

const AuthButtons: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNaivgateAbsolute();
  const dispatch = useAppDispatch();
  const popupProps = useSelector(PopupSelectors.getAuthPopup);

  const { fetchData, error, loading, response } = useGoogleLoginAPI();

  const isWebinarMode = useMemo(
    () =>
      popupProps.data.authMode === AUTH_MODES.webinar_signup ||
      popupProps.data.authMode === AUTH_MODES.webinar_login,
    [popupProps],
  );

  const loginByGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const { code } = codeResponse;
      void fetchData({
        ...BackendApis.googleLogin,
        data: {
          authCode: code,
        },
      });
    },
    onError: (error) => {
      console.error(error);
      toast.error(t("Something went wrong"));
    },
    flow: "auth-code",
  });

  useEffect(() => {
    if (!response) return;

    const {
      data: {
        data: { tokens, user },
      },
    } = response;

    const userState = convertUserFromDB(user);
    dispatch(UserActions.setUser(userState));
    dispatch(UserActions.setUserAuthStatus(UserAuthStatus.loggedIn));

    localStorage.setItem("token", tokens.id_token);
    localStorage.setItem("refresh_token", tokens.refresh_token);
    localStorage.setItem("authMode", AuthMethod.google);
    if (popupProps.data.afterSuccess) {
      popupProps.data.afterSuccess();
    }
    dispatch(PopupActions.closePopup(POPUP_KEYS.auth));
    set50MinsGoogleRefresh();
  }, [dispatch, popupProps.data, response]);

  useEffect(() => {
    if (!error) return;

    const message = getErrorMessage(error);
    const id = toast.error(t(message));

    dispatch(UserActions.setUserAuthStatus(UserAuthStatus.loggedOut));

    return () => {
      toast.dismiss(id);
    };
  }, [dispatch, error, t]);

  useEffect(() => {
    dispatch(UserActions.setIsAuthLoading(loading));
  }, [dispatch, loading]);

  // const { save } = useProjectTools();
  // const project = useSelector(getProjectDetails);
  return (
    <div className={cssClasses.buttonContainer}>
      {/* <div className={[cssClasses.authButton, cssClasses.microsoft].join(" ")}>
        <div className={cssClasses.btnImgText}>
          <img src={Microsoft} alt="" />
          <div>Login with Microsoft</div>
        </div>
      </div> */}
      {/* <div className={[cssClasses.authButton, cssClasses.google].join(" ")}>
        <div className={cssClasses.btnImgText}>
          <img src={Apple} alt="" />
          <div>Login with Apple</div>
        </div>
      </div> */}
      {!isWebinarMode && (
        <div className={[cssClasses.authButton, cssClasses.google].join(" ")}>
          <div className={cssClasses.btnImgText}>
            <img src={Google} alt="" />
            <div
              onClick={() => {
                Mixpanel.track(trackingEvents.USER_LOGIN, {});
                loginByGoogle();
                // GoogleHandler("google.com", navigate, project, save);
              }}
            >
              {loading && <CircularProgress size={20} className="circular-progress-custom" />}{" "}
              {t("Login with Google")}
            </div>
          </div>
        </div>
      )}

      {/* <div className={[cssClasses.authButton, cssClasses.linkedIn].join(" ")}>
        <div className={cssClasses.btnImgText}>
          <img src={Linkedln} alt="" />
          <div>Login with LinkedIn</div>
        </div>
      </div> */}
    </div>
  );
};

export default AuthButtons;
