import styled from "styled-components";

interface RowProps {
  align?: "flex-start" | "flex-end" | "center" | "intial";
  justify?: "flex-start" | "flex-end" | "center";
  gap?: string;
  reverse?: boolean;
}
export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: ${(p) => (p.reverse ? "row-reverse" : "row")};

  align-items: ${(p) => p.align ?? "flex-start"};
  justify-content: ${(p) => p.justify ?? "flex-start"};
  gap: ${(p) => p.gap ?? "initial"};
`;

interface ColumnProps {
  align?: "flex-start" | "flex-end" | "center";
  justify?: "flex-start" | "flex-end" | "center";
  gap?: string;
  reverse?: boolean;
}
export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: ${(p) => (p.reverse ? "column-reverse" : "column")};

  align-items: ${(p) => p.align ?? "flex-start"};
  justify-content: ${(p) => p.justify ?? "flex-start"};
  gap: ${(p) => p.gap ?? "initial"};
`;
