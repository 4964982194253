import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { ProjectActions } from "src/redux/actionCreators";
import store from "src/redux/store";

export const useIncrementAccessCount = (location: any) => {
  const dispatch = useDispatch();
  const state = store.getState();
  useEffect(() => {
    if (location.pathname.includes("saved")) {
      dispatch(ProjectActions.setAccessCount(state.project.accessCount));
    }
  }, [location]);
};
