import { actionCreators, ProjectState } from "src/redux/project";

export const deleteWaterHeater = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.deleteWaterHeater>,
): ProjectState => {
  return {
    ...currentState,
    components: {
      ...currentState.components,
      waterHeating: false,
    },
  };
};
