import { clone, merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updatePaymentInfo = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updatePaymentInfo>,
): ProjectState => {
  const newState = clone(currentState);
  newState.payment = merge({}, currentState.payment, payload);
  return newState;
};
