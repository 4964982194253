import React from "react";
import { MultiTenantContainer } from "../MultiTenantStyles";
import { InfoIcon, ProductCard, Step2ComponentKeys } from "src/shared/components";
import { useTranslation } from "react-i18next";
import { OperationContainer, OperationProducts, OperationTitle } from "./OperationProductStyles";
import { useSelector } from "react-redux";
import { ProjectSelectors } from "src/redux/selectors";
import { ProductDB } from "src/db-types";

interface IOperationProduct {
  productName: Step2ComponentKeys;
  productItem: ProductDB | null;
}

const OperationProduct = ({ productName, productItem }: IOperationProduct) => {
  const { t } = useTranslation();

  return (
    <MultiTenantContainer>
      <div className="headers">
        <div className="make_flex">
          <div className="head">{t("Wähle die Komponenten")}</div> <InfoIcon />
        </div>
        <div className="paragraph">
          {t(
            "Für die Umsetzung des Mieterstromkonzeptes sind Smartmeter sowie Datenlogger erforderlich",
          )}
        </div>
      </div>
      <OperationContainer>
        <OperationTitle>{t("Required components tenant electicity concept")}</OperationTitle>
        <OperationProducts>
          <ProductCard productName={productName} item={productItem} />
        </OperationProducts>
      </OperationContainer>
    </MultiTenantContainer>
  );
};

export default OperationProduct;
