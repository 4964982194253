import { createStore, applyMiddleware, compose } from "redux";

import rootReducer from "./rootReducer";

const composeEnhancer =
  // @ts-expect-error
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({
    trace: true,
    traceLimit: 25,
  }) ?? compose;

const store = createStore(rootReducer, composeEnhancer(applyMiddleware()));

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
