import React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ActiveUploadIcon, UploadIcon } from "src/assets/svgs";
import { Mixpanel, trackingEvents } from "src/mix-panel";

import * as S from "./UploadFileCard.styles";

interface UploadFileCardProps {
  onFileAdd: (files: File[]) => void;
}
export const UploadFileCard: React.FC<UploadFileCardProps> = (props: UploadFileCardProps) => {
  const { onFileAdd } = props;

  const { t } = useTranslation();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      "image/jpeg": [".jpg", ".jpeg"],
      "text/html": [".html", ".htm"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "image/svg+xml": [".svg"],
    },
    useFsAccessApi: false,
    onDrop: (acceptedFiles: any) => {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_ADDING_IMAGES, {});
      onFileAdd(acceptedFiles);
    },
  });

  return (
    <div>
      <S.UploadArea {...getRootProps({ className: "dropzone" })} filesUploadHover={isDragActive}>
        {isDragActive ? <ActiveUploadIcon /> : <UploadIcon />}

        <input {...getInputProps()} />
        <S.SubText>{t("Drag and drop images to upload here")}</S.SubText>
        <S.SubText1>{t("OR")}</S.SubText1>
        <S.SubText2>{t("Browse")}</S.SubText2>
      </S.UploadArea>
    </div>
  );
};
