import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useNumberFormat } from "src/hooks/useNumberFormat";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import { ProjectSelectors } from "src/redux/selectors";

const ProductionPerYearLine: React.FC = () => {
  const { t } = useTranslation();

  const production = useSelector(ProjectSelectors.getEnergy).solarPanelProduction;

  const { formatNumber } = useNumberFormat();

  return (
    <ParameterStyles.Line isPadding>
      <ParameterStyles.Label bold>
        <div>{t("PRODUCTION PER YEAR")}</div>
      </ParameterStyles.Label>
      <ParameterStyles.Value bold>{formatNumber(production)} kWh</ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default ProductionPerYearLine;
