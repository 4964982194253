import { actionCreators, ProjectState } from "src/redux/project";

export const updateHouseBottom = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateHouseBottom>,
): ProjectState => {
  const {
    images: { houseBottom: currHouseBottom },
  } = currentState;

  return {
    ...currentState,
    images: {
      ...currentState.images,
      houseBottom: {
        description: payload.description ?? currHouseBottom.description,
        imageURLs: payload.imageURLs ?? currHouseBottom.imageURLs,
      },
    },
  };
};
