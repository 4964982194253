import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { DeleteItIcon } from "src/assets/svgs";
import { useNumberFormat } from "src/hooks/useNumberFormat";
import { MasterInput, EVConfigurationBox } from "src/shared/components";

import { PopupActions, ProjectActions } from "src/redux/actionCreators";
import { CarComponent } from "src/redux/project";

interface CarLineProps {
  car: CarComponent;
  showEMobilityTitle?: boolean;
}

export const CarLine: React.FC<CarLineProps> = (props: CarLineProps) => {
  const { car, showEMobilityTitle } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [hoveredOnTitle, setHoveredOnTitle] = useState<Boolean>(false);
  const [hoveredOnDelete, setIsHoveredOnDelete] = useState<Boolean>(false);
  const [openCarConfig, setOpenCarConfig] = useState<Boolean>(false);

  const deleteBtnClickHandler = useCallback(
    (id: string) => {
      dispatch(ProjectActions.deleteCar(car.id));
    },
    [car.id, dispatch],
  );

  const inputChangeHandler = useCallback(
    (value: number) => {
      dispatch(
        ProjectActions.updateCar({
          id: car.id,
          car: {
            configuration: {
              evYearlyConsumption: value,
            },
          },
        }),
      );
    },
    [car.id, dispatch],
  );

  let nameOfCar = "";
  if (showEMobilityTitle) nameOfCar = t("E-mobility");
  else if (car.item?.name) nameOfCar = car.item.name;
  else nameOfCar = `${t("car")} ${car.name.split(" ")[1]}`;

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div className="same_container3">
        <div
          className="make_hover"
          onMouseEnter={() => {
            setHoveredOnTitle(true);
          }}
          onMouseLeave={() => {
            setHoveredOnTitle(false);
          }}
        >
          <div
            className="same_texts"
            onClick={() => {
              dispatch(
                PopupActions.openCarSelector({
                  isAdd: true,
                  addSettings: {
                    isMultipleAdd: false,
                    idToAddAt: car.id,
                  },
                }),
              );
            }}
          >
            {hoveredOnTitle && `${t("Change")} `}
            {nameOfCar}
          </div>
        </div>

        <div
          className="same_buttons2"
          onMouseEnter={() => setIsHoveredOnDelete(true)}
          onMouseLeave={() => setIsHoveredOnDelete(false)}
        >
          <div className="make_it_bc_flex">
            <MasterInput
              value={car.configuration.evYearlyConsumption}
              label="kWh"
              onChange={inputChangeHandler}
              onClick={() => {
                setOpenCarConfig(true);
              }}
              roundTill={0}
            />
            {hoveredOnDelete && (
              <DeleteItIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  deleteBtnClickHandler(car.id);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {openCarConfig && (
        <EVConfigurationBox editId={car.id} onClose={() => setOpenCarConfig(false)} />
      )}
    </div>
  );
};
