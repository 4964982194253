import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { ProjectCard } from "src/pages/UserOverview/components";
import { useLoadAllProjects } from "src/pages/UserOverview/hooks";
import { isValidURL } from "src/utils";

import InstallerDetailsCard from "./components/InstallerDetailsCard/InstallerDetailsCard";
import * as S from "./WhiteLabelInstallerStyles";

import { getAddress } from "src/redux/project/selectors";
import { UserSelectors } from "src/redux/selectors";

const WhiteLabelInstaller: React.FC = () => {
  const { projects, removeProject, loadAllProjects } = useLoadAllProjects();
  const user = useSelector(UserSelectors.getUser);
  const uid = user?.id;

  useEffect(() => {
    if (uid) {
      loadAllProjects(uid);
    }
  }, [uid, loadAllProjects]);

  const { pathname } = window.location;
  const urlParts = pathname.split("/");
  const savedNumber = urlParts[urlParts.length - 2].replace("saved-", "");
  const versionNumber = urlParts[urlParts.length - 1];
  const address = useSelector(getAddress);

  const filteredProjects = useMemo(
    () =>
      projects.filter(
        (project) =>
          project.shortId === parseInt(savedNumber) && project.version === parseInt(versionNumber),
      ),
    [projects, savedNumber, versionNumber],
  );

  const projectExists = filteredProjects.length > 0;

  const filteredImages = useMemo(() => {
    return filteredProjects[0]?.quoteID?.profiles?.green?.imgUrl?.filter((img) => {
      const fileExtension: any = img?.split(".").pop()?.toLowerCase();
      const isInvalidExtension = ["obj", "mtl", "pvprj"].includes(fileExtension);
      if (isInvalidExtension) {
        return false;
      }
      if (img.includes("Destination") && isValidURL(img)) {
        if (address.city === "Gundelfingen") {
          return !img.includes("Baked") && isValidURL(img);
        } else {
          return img.includes("Screenshot");
        }
      }
      return isValidURL(img);
    });
  }, [address.city, filteredProjects]);

  return (
    <S.WhiteLabelInstaller>
      {projectExists && (
        <InstallerDetailsCard images={filteredImages} pid={filteredProjects[0]?._id} />
      )}
      {projectExists && (
        <S.ProjectDetails>
          <ProjectCard
            key={1}
            project={filteredProjects[0]}
            savedProjectsEnabled={false}
            onDelete={removeProject}
            savedProject={false}
          />
        </S.ProjectDetails>
      )}
    </S.WhiteLabelInstaller>
  );
};

export { WhiteLabelInstaller };
