import { PROCESS_STATUS } from "src/data";

import { Quote } from "src/redux/project";

export const isNoQuotePresent = (resource: string | Quote): boolean => {
  if (typeof resource === "object") return resource.id === "";
  return resource === "";
};

export const isQuotePresent = (resource: string | Quote): boolean => !isNoQuotePresent(resource);

export const isQuoteVerified = (status: string): boolean => {
  return status === PROCESS_STATUS.VERIFIED;
};

export const isQuoteNotVerified = (status: string): boolean => {
  return (status !== PROCESS_STATUS.VERIFIED && status !== PROCESS_STATUS.APPROVAL_PENDING);
};

export const isQuotePending = (status: string): boolean => {
  return status === PROCESS_STATUS.APPROVAL_PENDING
};
