import { clone } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const addInverter = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addInverter>,
): ProjectState => {
  const newInverter = clone(payload);

  return {
    ...currentState,
    components: {
      ...currentState.components,
      inverter: {
        ...currentState.components.inverter,
        item: newInverter,
      },
    },
  };
};
