import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { disableConsoleLogsIfOnProd, initSentry } from "./startup-functions";

import store from "src/redux/store";

disableConsoleLogsIfOnProd();
initSentry();

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById("root"),
);
