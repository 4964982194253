import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getFeedInTarrifByCapacity } from "src/data";
import { getAverageCapacity } from "src/helpers";

import { ProjectActions } from "src/redux/actionCreators";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

export const useFeedInSync = (): void => {
  const dispatch = useDispatch();

  const filteredSolarPanels = useSelector(ProductSelectors.getFilteredSolarPanels);
  const { quantity } = useSelector(ProjectSelectors.getSolarPanel);

  const capacity = useMemo(() => {
    return getAverageCapacity(filteredSolarPanels);
  }, [filteredSolarPanels]);

  const systemCapacity = (capacity * quantity) / 1000;

  const { selectedFeedInReason } = useSelector(ProjectSelectors.getFinancialDetails);

  useEffect(() => {
    const newFeedInTarrif = getFeedInTarrifByCapacity(systemCapacity, selectedFeedInReason);
    if (newFeedInTarrif) {
      dispatch(
        ProjectActions.updateFinancialDetails({
          feedInTariff: newFeedInTarrif,
        }),
      );
    }
  }, [dispatch, selectedFeedInReason, systemCapacity]);
};
