import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";

import { useWindowDimensions } from "src/hooks";

import SolarHubSmall from "src/assets/application_step_1/SolarHubSmall.svg";
import App from "src/assets/popups/App.svg";
import Famaily from "src/assets/popups/Famaily.svg";
import webinar from "src/assets/popups/webinar.svg";
import { PROCESS_STATUS } from "src/data";
import { openCorrPopupBasedOnPartnerStatus } from "src/helpers/partner-cities/partnerCityHelper";
import { PopupSkeleton } from "src/shared/components";

import "./PartnerCityConsulting.css";

import { PopupActions } from "src/redux";
import { getPartnerCityData } from "src/redux/application/selector";
import { POPUP_KEYS } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";
import { getPopup } from "src/redux/popups/selector";

// Import Swiper and Swiper styles
import { getQuote } from "src/redux/project/selectors";
import { getUser } from "src/redux/user/selectors";

import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/swiper.min.css";
import { UserSelectors } from "src/redux/selectors";

interface IcardData {
  title: string;
  subTitle: string;
  description: string;
  img: any;
  onClick: () => void;
}

const SampleNextArrow = (props: any): JSX.Element => {
  const { onClick } = props;
  return <ArrowForwardIos className="arrow_icon arrow_right" onClick={onClick} />;
};

const SamplePrevArrow = (props: any): JSX.Element => {
  const { onClick } = props;
  return <ArrowBackIos className="arrow_icon arrow_left" onClick={onClick} />;
};

const settings = {
  dots: false,
  accessibility: true,
  infinite: true,
  speed: 600,
  slidesToShow: 1,
  slidesToScroll: 1,
  beforeChange: function (prev: any, next: any) {},
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

export const PartnerCityConsulting: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const quote = useSelector(getQuote);
  const user = useSelector(getUser);
  const applicationData = useSelector(getPartnerCityData);
  const partnerCityData = applicationData;
  const isQuoteVerified = quote.status === PROCESS_STATUS.VERIFIED;
  const isConsultingAvalible =
    Number(partnerCityData.yearlyConsultings) - Number(partnerCityData.usedYearlyConsultings);
  const isCityPartnerCity = partnerCityData.partnerStatus;
  const isPailotCity = partnerCityData.partnerStatus === "Shades are on";
  const city = quote.mapboxAddress.city;
  const installerDetails = useSelector(UserSelectors?.getInstaller);

  const {
    PARTNER_CITY_CONSULTINNG: {
      data: { title, description, question },
    },
  } = useSelector(getPopup);

  const closeDialog = useCallback((): void => {
    dispatch(PopupActions.closePopup(POPUP_KEYS.PARTNER_CITY_CONSULTINNG));
  }, [dispatch]);

  const logoWithCutomeText = useMemo(
    () => (
      <div className="logoWithCustomeText">
        S<img src={SolarHubSmall} alt="smallLogo" />
        LAR
      </div>
    ),
    [],
  );

  const titleAndDescription = useMemo(() => {
    const data = {
      title: "",
      description: "",
    };
    switch (true) {
      case !isQuoteVerified && !isCityPartnerCity: // city is not partner-city + quote is not verified
        data.title =
          "Bisher wurde für dein Gebäude noch keine Planung angelegt, lass uns gerne loslegen!";
        data.description =
          "Mit unserem vielfältigen Angebot helfen wir dir dabei, deine individuelle Energielösung zu finden. Wir bieten dir eine unabhängige Beratung von Solarenergie über Wärmepumpe zu E-Mobilität. Wir sind dein Ansprechpartner um die beste Entscheidung zu treffen.";
        break;
      case isQuoteVerified && !isCityPartnerCity: // city is not partner-city + quote is verified
        data.title = "Gute Neuigkeiten - deine Planung ist fertiggestellt!";
        data.description =
          "Du kannst nun auf deine individuelle Planung zugreifen. Gerne kannst du nun kostenlos bei einem unserer regelmäßig stattfindenden Webinaren teilnehmen oder eine individuelle Beratung mit uns buchen.";
        break;
      case !!(isConsultingAvalible && !isQuoteVerified && isCityPartnerCity && !isPailotCity): // city is partner-city + quote is not verified + consulting avalible
        data.title = `Gute Neuigkeiten - in ${city} erstellen wir für jeden Bürger eine PV-Planung`;
        data.description =
          "Derzeit sind wir beschäftigt mit der Erstellung der Planungen - deine Anfrage bearbeiten wir nun priorisiert. Wir melden uns per Mail bei dir (in 2-3 Tagen) sobald die Planung fertigstellt ist. Das Angebot ist durch deine Gemeinde finanziert und somit für dich kostenlos!";
        break;
      case !!(isConsultingAvalible && !isQuoteVerified && isCityPartnerCity && isPailotCity): // city is partner-city + quote is not verified + consulting avalible
        data.title = `Gute Neuigkeiten - wir haben noch freie Beratungen in ${city} verfügbar`;
        data.description =
          "Danke für deine Anfrage wir bearbeiten diese nun zeitnah. Wir melden uns per Mail bei dir (in 2-3 Tagen) sobald die Planung fertigstellt ist. Das Angebot ist durch deine Gemeinde finanziert und somit für dich kostenlos!";
        break;
      case !!(isConsultingAvalible && isQuoteVerified && isCityPartnerCity): // city is partner-city + quote verified + consulting avalible
        data.title = "Gute Neuigkeiten - deine Planung ist fertiggestellt";
        data.description =
          "Du kannst nun auf deine individuelle Planung zugreifen Gerne kannst du nun auch direkt bei einem unserer regelmäßig stattfindenden Webinaren teilnehmen oder eine Beratung mit uns buchen. Das Angebot ist durch deine Gemeinde finanziert und somit für dich kostenlos!";
        break;
      case !!(!isConsultingAvalible && isQuoteVerified && isCityPartnerCity): // city is partner-city + quote verified + consulting not avalible
        data.title = "Gute Neuigkeiten - deine Planung ist fertiggestellt!";
        data.description =
          "Du kannst nun auf deine individuelle Planung zugreifen. Gerne kannst du nun kostenlos bei einem unserer regelmäßig stattfindenden Webinaren teilnehmen oder eine individuelle Beratung mit uns buchen. Die Planung deines Daches wurde durch deine Gemeinde finanziert und ist damit kostenlos für dich.";
        break;
      case !!(!isConsultingAvalible && !isQuoteVerified && isCityPartnerCity): // city is partner-city + quote not verified + consulting not avalible
        data.title = "Gute Neuigkeiten - deine Planung ist fertiggestellt";
        data.description =
          "Du kannst nun auf deine individuelle Planung zugreifen Gerne kannst du nun auch direkt bei einem unserer regelmäßig stattfindenden Webinaren teilnehmen oder eine Beratung mit uns buchen. Das Angebot ist durch deine Gemeinde finanziert und somit für dich kostenlos!";
        break;
      default:
        data.title =
          "Bisher wurde für dein Gebäude noch keine Planung angelegt, lass uns gerne loslegen!";
        data.description =
          "Mit unserem vielfältigen Angebot helfen wir dir dabei, deine individuelle Energielösung zu finden. Wir bieten dir eine unabhängige Beratung von Solarenergie über Wärmepumpe zu E-Mobilität.Wir sind dein Ansprechpartner um die beste Entscheidung zu treffen.";
        break;
    }

    return data;
  }, [city, isCityPartnerCity, isConsultingAvalible, isQuoteVerified, isPailotCity]);

  const cardData: IcardData[] = useMemo(
    () => [
      {
        title: t("BERATUNG"),
        subTitle: t("Beratung Vereinbaren"),
        img: Famaily,
        description: t("Sprech mit einem unserer Experten dein nachhaltiges Energieprojekt durch"),
        onClick: () => openCorrPopupBasedOnPartnerStatus(),
      },
      {
        title: t("WEBINAR"),
        subTitle: t("Zur Anmeldung"),
        img: webinar,
        description: t(
          "Wir besprechen gemeinsam mit allen Teilnehmern die wichtigsten Fragen Energielösung.",
        ),
        onClick: () => {
          const data = {
            title:
              "Please feel free to choose from our upcoming Events Your perfect start to your new energy system",
            heading: "SolarHub Solarwebinar",
            button_text: "REGISTER",
            extraContent: "",
          };
          dispatch(openPopup(POPUP_KEYS.webinar, data));
          closeDialog();
        },
      },
      {
        title: t("APP"),
        img: App,
        subTitle: isQuoteVerified ? t("Mein Projekt öffnen") : t("Mit beispieldaten testen"),
        description: isQuoteVerified
          ? t(
              "Entdecke dein neues Energiesystem und konfiguriere es selbstständig oder durch die Hilfe mit unseren Experten",
            )
          : t("Lerne unser System kennen und teste die Funktionen anhand eines Beispielprojektes"),
        onClick: () => closeDialog(),
      },
    ],
    [t, isQuoteVerified, dispatch, closeDialog],
  );

  return (
    <PopupSkeleton handleClose={closeDialog} open={true}>
      <div className="partner-consulting">
        <div className="partner-consulting-textContainer">
          <p className="partner-consulting-title">{t(titleAndDescription.title)}</p>

          <p className="partner-consulting-description">
            {installerDetails?.pvPlanModalText || t(titleAndDescription.description)}
          </p>
          <p className="partner-consulting-question">{t(question)}</p>
        </div>
        {width < 768 ? (
          <Slider {...settings} className="partner-consulting-cards-slider">
            {cardData.map((item: IcardData, index: number) => (
              <CardDetails
                key={item.title}
                item={item}
                index={index}
                logoWithCutomeText={logoWithCutomeText}
                onClick={item.onClick}
              />
            ))}
          </Slider>
        ) : (
          <div className="partner-consulting-cards">
            {cardData.map((item: IcardData, index: number) => (
              <CardDetails
                key={item.title}
                item={item}
                index={index}
                logoWithCutomeText={logoWithCutomeText}
                onClick={item.onClick}
              />
            ))}
          </div>
        )}
      </div>
    </PopupSkeleton>
  );
};

interface CardDetailsProps {
  item: IcardData;
  logoWithCutomeText: any;
  onClick: () => void;
  index: number;
}

const CardDetails: React.FC<CardDetailsProps> = ({ item, logoWithCutomeText, onClick, index }) => (
  <div
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    className={`card-container ${index === 1 && "background"}`}
    key={item.title}
    onClick={onClick}
  >
    <div className="card-logo">
      {logoWithCutomeText} <span className="card-title">{item.title}</span>
    </div>
    <div className="makeItFlex">
      <img src={item.img} alt="cardImage" className="cardImage" />
    </div>
    <p className="card-subtitle">{item.subTitle}</p>
    <div className="card-des-container">
      <p className="card-description">{item.description}</p>
    </div>
  </div>
);

export default PartnerCityConsulting;
