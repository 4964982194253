import styled from "styled-components";

export const IbanFormWrapper = styled("div")`
  .sr-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: var(--form-width);
    min-width: 450px;
    align-self: center;
    box-shadow: rgba(50, 50, 93, 0.1) 0px 0px 0px 0.5px, rgba(50, 50, 93, 0.1) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.07) 0px 1px 1.5px 0px;
    padding: 32px 50px;
    background: var(--body-color);
    border-radius: var(--radius);
  }
  .sr-combo-inputs-row:first-child {
    margin-top: 0px;
  }
  .sr-combo-inputs-row {
    margin-top: 20px;
  }
  .sr-combo-inputs-row {
    width: 100%;
    margin-top: 20px;
  }
  .sr-combo-inputs-row {
    display: flex;
  }
  .sr-combo-inputs-row .col:not(:last-child) {
    margin-right: 20px;
  }
  .sr-combo-inputs-row .col {
    width: 100%;
  }
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    max-width: 100%;
    color: rgb(107, 124, 147);
  }
  #work {
    width: 100%;
    outline: none;
    height: 40px;
    color: rgb(50, 50, 93);
    background-color: white;
    box-shadow: rgb(230, 235, 241) 0px 1px 3px 0px;
    padding: 10px 12px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    border-radius: 4px;
    transition: box-shadow 150ms ease 0s;
    :-internal-autofill-selected {
      appearance: menulist-button;
      background-image: none !important;
      background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
      color: -internal-light-dark(black, white) !important;
    }
  }
  .StripeElement--webkit-autofill {
    background-color: rgb(254, 253, 229) !important;
  }
  .StripeElement {
    height: 40px;
    color: rgb(50, 50, 93);
    background-color: white;
    box-shadow: rgb(230, 235, 241) 0px 1px 3px 0px;
    padding: 10px 12px;
    border-width: 1px;
    border-style: solid;
    width: 100%;
    border-color: transparent;
    border-image: initial;
    border-radius: 4px;
    transition: box-shadow 150ms ease 0s;
  }
  #mandate-acceptance {
    font-size: 14px;
    text-align: justify;
    margin: 20px 0px;
  }
  #confirm-mandate:hover {
    filter: contrast(115%);
  }
  #confirm-mandate {
    color: white;
    margin-top: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 5.5px 0px;
    width: 100%;
    background: #32325d;
    border-radius: 6px;
    margin-bottom: 10px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    padding: 12px 16px;
    transition: all 0.2s ease 0s;
  }
`;
