import { clone } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const addEMSProduct = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addEMSProduct>,
): ProjectState => {
  const EMSDetails = clone(payload);

  return {
    ...currentState,
    components: {
      ...currentState.components,
      EMS: {
        ...currentState.components.EMS,
        item: EMSDetails,
      },
    },
  };
};


export const addEMS = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addEMS>,
): ProjectState => {
  const EMS = clone(payload);

  return {
    ...currentState,
    components: {
      ...currentState.components,
      EMS,
    },
  };
};