import styled from "styled-components/macro";
export const MainContainer = styled.div`
  width: 100%;
  padding: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .saprate {
    width: 100%;
    padding-bottom: 4px;
    border-bottom: 1px solid #efefef;
  }
`;

interface SubContainerProps {
  hovered?: boolean;
}
export const SubContainer = styled.div<SubContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding-inline: 3px;

  ${({ hovered }) =>
    hovered &&
    `
    border-radius: 5px;
    background-color: var(--hover-color);
    box-shadow: var(--shadow-color);
  `}
`;

export const SubContainer1 = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;
export const PositiveContainer = styled.div`
  width: 100%;
  height: auto;
  background: rgba(36, 125, 56, 0.1);
  border-radius: 10px 10px 0px 0px;
  padding: 10px;
  padding-left: 1px;
  --hover-color: rgba(36, 125, 56, 0.2);
  --shadow-color: rgba(36, 125, 56, 0.8);
`;
export const NegativeContainer = styled.div`
  width: 100%;
  height: auto;
  background: rgba(231, 75, 75, 0.2);
  border-radius: 0px 0px 10px 10px;
  padding: 10px;
  padding-left: 1px;

  --hover-color: rgba(231, 75, 75, 0.3);
  --shadow-color: rgba(231, 75, 75, 0.8);
`;
export const Text1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #2d4764;
`;
export const Text2 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #2d4764;
`;
export const Text3 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
`;
export const Number1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #2d4764;
  white-space: nowrap;
`;
export const Number2 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #ffd75d;
  white-space: nowrap;
`;
export const Number3 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
  white-space: nowrap;
`;
