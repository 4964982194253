import { clone } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const addWallbox = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addWallbox>,
): ProjectState => {
  const wallbox = clone(payload);

  return {
    ...currentState,
    components: {
      ...currentState.components,
      wallbox,
    },
  };
};

export const addWallboxProduct = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addWallboxProduct>,
): ProjectState => {
  const wallbox = clone(payload);

  return {
    ...currentState,
    components: {
      ...currentState.components,
      wallbox: {
        ...currentState.components.wallbox,
        item: wallbox,
      },
    },
  };
};
