import { Select } from "antd";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";
import { ParameterStyles } from "src/shared/StyledComponents";

const { Option, OptGroup } = Select;

const SystemConsept: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { systemConsept } = useSelector(ProjectSelectors.getHousehold);

  const handleChange = useCallback(
    (value: string): void => {
      dispatch(
        ProjectActions.updateHousehold({
          systemConsept: value,
        }),
      );
    },
    [dispatch],
  );

   const options = [
     {
       label: t("Stromlieferung im Haus"),
       options: [
         {
           value: "Vollstromlieferung (Mieterstrom)",
           label: t("Vollstromlieferung (Mieterstrom)"),
         },
         {
           value: "Einzählermodell (Kollektive Stromversorgung)",
           label: t("Einzählermodell (Kollektive Stromversorgung)"),
         },
       ],
     },
     {
       label: t("Allgemeinstromversorgung"),
       options: [
         { value: "Allgemeinstrom ohne Wärme", label: t("Allgemeinstrom ohne Wärme") },
         { value: "Allgemeinstrom plus Wärme", label: t("Allgemeinstrom plus Wärme") },
       ],
     },
     {
       label: t("Einzelanlage"),
       options: [
         {
           value: "Einzelanlagen (mehrere separate PV-Anlagen auf einem Dach)",
           label: t("Einzelanlagen (mehrere separate PV-Anlagen auf einem Dach)"),
         },
         { value: "Anlagenmiete", label: t("Anlagenmiete") },
         { value: "Balkon-Solar", label: t("Balkon-Solar") },
       ],
     },
     {
       label: t("Volleinspeisung"),
       options: [
         {
           value: "Volleinspeisung ohne Eigenverbrauch direkt ins Netz",
           label: t("Volleinspeisung ohne Eigenverbrauch direkt ins Netz"),
         },
       ],
     },
   ];

  return (
    <ParameterStyles.Line
      hasSlider
      style={{ paddingInline: "10px", width: "93%" }}
    >
      <ParameterStyles.Label style={{ color: "black" }}>
        {t("SYSTEM CONCEPT")}
      </ParameterStyles.Label>
      <Select
        defaultValue={systemConsept}
        style={{ width: 300 }}
        onChange={handleChange}
        virtual={false}
      >
        {options.map((group) => (
          <OptGroup label={group.label} key={group.label}>
            {group.options.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </OptGroup>
        ))}
      </Select>
    </ParameterStyles.Line>
  );
};

export default SystemConsept;
