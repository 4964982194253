import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch, useIsComponentAdded } from "src/hooks";

import { AddIcon, BatteryIcon, HeatpumpLogo, UpArrowIconStep1, WallboxIcon } from "src/assets/svgs";
import { EVConfigurationBox, HeatpumpConfigurationBox } from "src/shared/components";

import { SelectBattery } from "./components";
import {
  MainContainer,
  MainHeader,
  MainHeaderText,
  MainHeaderText1,
  SubContainer,
  SubContainer1,
  SubContainer2,
  SubContainer22,
  SubContainer3,
  SubContainerText1,
  SubContainerText2,
} from "./styles";

import { ProjectActions } from "src/redux/actionCreators";
import { defaultCarConfiguration } from "src/redux/project/default-values";
import { ProjectSelectors, UserSelectors } from "src/redux/selectors";

interface ItemListProps {
  setShowAddComponents: (show: boolean) => void;
  setIsAnyInAddProcess: (flag: boolean) => void;
}
const ItemList: React.FC<ItemListProps> = (props: ItemListProps) => {
  const { setShowAddComponents, setIsAnyInAddProcess } = props;

  const { t } = useTranslation();

  const [currSelect, setCurrSelect] = useState<"battery" | "heatpump" | "wallbox" | null>(null);
  const { areCarsAdded, isBatteryAdded, isHeatpumpAdded } = useIsComponentAdded();
  const isBatterySelected = currSelect === "battery";
  const installerDetails = useSelector(UserSelectors?.getInstaller);

  useEffect(() => {
    if (currSelect === null) {
      setIsAnyInAddProcess(false);
    } else {
      setIsAnyInAddProcess(true);
    }
  }, [currSelect, setIsAnyInAddProcess]);

  return (
    <MainContainer>
      <MainHeader
        onClick={() => {
          setShowAddComponents(false);
        }}
      >
        <div>
          <MainHeaderText>{t("COMPONENTS")}</MainHeaderText>
          <MainHeaderText1>
            {`(${t("BATTERY")},${t("WALLBOX")}${
              !installerDetails || (installerDetails && installerDetails.isAllowHitPump)
                ? `,${t("HEATPUMP")}`
                : ""
            })`}
          </MainHeaderText1>
        </div>
        <SubContainer22
          onClick={() => {
            setShowAddComponents(false);
          }}
        >
          <UpArrowIconStep1 />
        </SubContainer22>
      </MainHeader>

      <SubContainer>
        {isBatterySelected && <SelectBattery onClose={() => setCurrSelect(null)} />}
        {!isBatterySelected && (
          <>
            {!isBatteryAdded && (
              <SubContainer3 onClick={() => setCurrSelect("battery")}>
                <SubContainer1>
                  <div style={{ minWidth: "24px" }}>
                    <BatteryIcon />
                  </div>
                  <div>
                    <div style={{ display: "flex", gap: "6px" }}>
                      <SubContainerText1>{t("ADD BATTERY")}</SubContainerText1>
                    </div>
                    <SubContainerText2>{t("BATTERY TEXT")}</SubContainerText2>
                  </div>
                </SubContainer1>
                <SubContainer2>
                  <AddIcon onClick={() => setCurrSelect("battery")} />
                </SubContainer2>
              </SubContainer3>
            )}

            {(!areCarsAdded || currSelect === "wallbox") && (
              <SubContainer3 onClick={() => setCurrSelect("wallbox")}>
                <SubContainer1>
                  <div style={{ minWidth: "24px" }}>
                    <WallboxIcon height={24} width={24} />
                  </div>
                  <div>
                    <div style={{ display: "flex", gap: "6px" }}>
                      <SubContainerText1>{t("ADD WALLBOX")}</SubContainerText1>
                    </div>
                    <SubContainerText2>{t("WALLBOX TEXT")}</SubContainerText2>
                  </div>
                </SubContainer1>
                <SubContainer2>
                  <AddIcon onClick={() => setCurrSelect("wallbox")} />
                </SubContainer2>
              </SubContainer3>
            )}
            {currSelect === "wallbox" && (
              <EVConfigurationBox isAdd onClose={() => setCurrSelect(null)} />
            )}
            {!installerDetails
              ? !isHeatpumpAdded && (
                  <SubContainer3 onClick={() => setCurrSelect("heatpump")}>
                    <SubContainer1>
                      <div style={{ minWidth: "24px" }}>
                        <HeatpumpLogo />
                      </div>
                      <div>
                        <div style={{ display: "flex", gap: "6px" }}>
                          <SubContainerText1>{t("ADD HEATPUMP")}</SubContainerText1>
                        </div>
                        <SubContainerText2>{t("HEATPUMP TEXT")}</SubContainerText2>
                      </div>
                    </SubContainer1>
                    <SubContainer2>
                      <AddIcon onClick={() => setCurrSelect("heatpump")} />
                    </SubContainer2>
                  </SubContainer3>
                )
              : !isHeatpumpAdded &&
                installerDetails.isAllowHitPump && (
                  <SubContainer3 onClick={() => setCurrSelect("heatpump")}>
                    <SubContainer1>
                      <div style={{ minWidth: "24px" }}>
                        <HeatpumpLogo />
                      </div>
                      <div>
                        <div style={{ display: "flex", gap: "6px" }}>
                          <SubContainerText1>{t("ADD HEATPUMP")}</SubContainerText1>
                        </div>
                        <SubContainerText2>{t("HEATPUMP TEXT")}</SubContainerText2>
                      </div>
                    </SubContainer1>
                    <SubContainer2>
                      <AddIcon onClick={() => setCurrSelect("heatpump")} />
                    </SubContainer2>
                  </SubContainer3>
                )}
            {currSelect === "heatpump" && (
              <HeatpumpConfigurationBox isAdd onClose={() => setCurrSelect(null)} />
            )}
          </>
        )}
      </SubContainer>
      {/* <ProductPopup
        open={open}
        setOpen={setOpen}
        type={type}
        setIsAdd={setIsAdd}
        data={data}
        coming={true}
      /> */}
    </MainContainer>
  );
};

export default ItemList;
