import { isArray } from "lodash";

import { formatNumberByLang } from "src/hooks/useNumberFormat";

const convertNumberToAmericanFormat = (numStr: string) => {
  const commaInd = numStr.indexOf(",");
  const dotInd = numStr.indexOf(".");
};

const tooltipPlugin = (tooltipModel: any, chart: any) => {
  if (!chart) return;
  let text;
  if (isArray(tooltipModel.body) && isArray(tooltipModel.body[0].lines))
    text = tooltipModel.body[0].lines[0];
  else text = "";

  const numericalText = text.split(": ")[1];

  text =
    text.split(": ")[0] +
    " " +
    formatNumberByLang(parseFloat(tooltipModel.dataPoints?.[0]?.raw).toFixed(0));

  // Tooltip Element
  let tooltipEl = document.getElementById("chartjs-tooltip");

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.innerHTML = "<div class='custom-tooltip-chartjs'></div>";
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  function getBody(bodyItem: any) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    const innerHtml = text;

    const tableRoot = tooltipEl.querySelector("div");
    // @ts-expect-error
    tableRoot.innerHTML = innerHtml;
  }

  // `this` will be the overall tooltip
  const position = chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  // @ts-expect-error
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = "absolute";
  tooltipEl.style.transition = "all 500ms";
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
  tooltipEl.style.pointerEvents = "none";
  tooltipEl.style.zIndex = "100000";
};

export default tooltipPlugin;
