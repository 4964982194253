import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useAppSelector } from "src/hooks";

import { Mixpanel, trackingEvents } from "src/mix-panel";
import { MasterInput } from "src/shared/components";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors, UserSelectors } from "src/redux/selectors";

interface ElectricityConsumptionInputLineProps {
  openYearlyEditorCallback: React.MouseEventHandler;
}
const ElectricityConsumptionInputLine: React.FC<ElectricityConsumptionInputLineProps> = (
  props: ElectricityConsumptionInputLineProps,
) => {
  const { openYearlyEditorCallback } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { consumption } = useAppSelector(ProjectSelectors.getHousehold);
  const simplify = useSelector(UserSelectors.getSimplify);

  const onInputChangeHandler = useCallback(
    (value: number) => {
      dispatch(
        ProjectActions.updateHousehold({
          consumption: value,
        }),
      );
    },
    [dispatch],
  );

  const onInputClickHandler: React.MouseEventHandler = useCallback(
    (e) => {
      Mixpanel.track(trackingEvents.USER_IS_CHANGING_ELECTRICITY_CONSUMPTION, {});
      !simplify && openYearlyEditorCallback(e);
    },
    [openYearlyEditorCallback, simplify],
  );

  return (
    <ParameterStyles.Line isPadding>
      <ParameterStyles.Label>{t("BASIC ELECTRICITY CONSUMPTION")}</ParameterStyles.Label>
      <MasterInput
        value={consumption} // || calculatedConsumption
        label="kWh"
        onClick={onInputClickHandler}
        onChange={onInputChangeHandler}
        appearance={{
          cursor: "pointer",
        }}
        fitContent
      />
    </ParameterStyles.Line>
  );
};

export default ElectricityConsumptionInputLine;
