export const isOnUserOverview = (): boolean => {
  return location.pathname.includes("useroverview");
};
export const isNotOnUserOverview = (): boolean => !isOnUserOverview();

export const isOnWebinar = (): boolean => {
  return location.pathname.includes("webinar");
};
export const isNotOnWebinar = (): boolean => !isOnWebinar();

export const isOnResetPasswordPage = (queryParams: URLSearchParams): boolean => {
  return queryParams.get("mode") === "resetPassword";
};

export const isOnSavedProject = (url?: string): boolean => {
  return (url ?? location.pathname).includes("saved");
};
export const isNotOnSavedProject = (): boolean => !isOnSavedProject();

export const isOnDefaultProject = (url?: string): boolean => {
  return (url ?? location.pathname).includes("default");
};
export const isNotOnDefaultProject = (): boolean => !isOnDefaultProject();

export const isOnCustomProject = (url?: string): boolean => {
  return (url ?? location.pathname).includes("custom");
};
export const isNotOnCustomProject = (): boolean => !isOnCustomProject();
