import { useCallback, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "src/hooks";

import { buildingConsumptionsB2B, buildingConsumptionsB2C } from "src/data";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";

interface CalculatedConsB2BProps {
  buildingType: string;
  noOfEmployees: number;
  kwaPerEmployee: number;
}
const calculatedConsForB2B = ({
  buildingType,
  kwaPerEmployee,
  noOfEmployees,
}: CalculatedConsB2BProps): number => {
  type B2BKey = keyof typeof buildingConsumptionsB2B;
  const businessDefault = buildingConsumptionsB2B[buildingType as B2BKey].default;
  const employeesCons = noOfEmployees * kwaPerEmployee;
  const total = businessDefault + employeesCons;
  return total;
};

interface CalculatedConsB2CProps {
  buildingType: string;
  noOfPeople: number;
  isWaterHeating: boolean;
}
const calculatedConsForB2C = ({
  buildingType,
  isWaterHeating,
  noOfPeople,
}: CalculatedConsB2CProps): number => {
  type B2CKey = keyof typeof buildingConsumptionsB2C;
  const cons =
    buildingConsumptionsB2C[buildingType as B2CKey][noOfPeople - 1][isWaterHeating ? 1 : 0];
  return cons;
};

export const useCalculatedConsumption = (): void => {
  const dispatch = useAppDispatch();

  const { buildingType, people, kwaPerEmployee } = useAppSelector(ProjectSelectors.getHousehold);
  const isWaterHeater = useAppSelector(ProjectSelectors.getWaterHeating);
  const projectType = useAppSelector(ProjectSelectors.getProjectType);

  // useEffect(() => {
  //   let cons;
  //   if (projectType === "b2b")
  //     cons = calculatedConsForB2B({ buildingType, noOfEmployees: people, kwaPerEmployee });
  //   else
  //     cons = calculatedConsForB2C({
  //       buildingType,
  //       isWaterHeating: isWaterHeater,
  //       noOfPeople: people,
  //     });

  //   dispatch(
  //     ProjectActions.updateHousehold({
  //       // calculatedConsumption: cons,
  //       consumption: cons,
  //     }),
  //   );
  // }, [buildingType, dispatch, isWaterHeater, kwaPerEmployee, people, projectType]);
};
