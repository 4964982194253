import { AxiosError } from "axios";

export const getErrorMessage = (err: AxiosError): string => {
  const { response } = err;

  if (response?.data?.message) {
    return response.data.message;
  }

  return "Something went wrong";
};
