import { Slider } from "antd";
import { t } from "i18next";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { useNumberFormat } from "src/hooks";

import { MasterInput } from "src/shared/components";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import * as S from "../../HeatpumpConfigurationBox.styles";

import { HeatpumpConfiguration } from "src/redux/project";
import { ProjectSelectors } from "src/redux/selectors";

interface JazLineProps {
  onValueChange: (heatpumpPartData: Partial<HeatpumpConfiguration>) => void;
}
const JazLine: React.FC<JazLineProps> = (
  props: JazLineProps,
) => {
  const { onValueChange } = props;
  const { formatNumber } = useNumberFormat();

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);

  const onSliderChangeHandler = useCallback(
    (value: any) => {
      onValueChange({
        jaz: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line hasSlider className="evKm" style={{ marginTop: "6px" }}>
      <ParameterStyles.Label>{t("JAZ (Jahresarbeitszahl)")}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider
          step={0.01}
          value={configuration.jaz || 3.5}
          onChange={onSliderChangeHandler}
          max={5}
          min={2.5}
        />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <S.SubContainerValue>
          {formatNumber(configuration.jaz || 3.5, { roundTill: 2 })}
        </S.SubContainerValue>
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default JazLine;
