import { Slider } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useNumberFormat } from "src/hooks/useNumberFormat";
import { ParameterStyles } from "src/shared/StyledComponents";

interface SolarSupplyLineProps {
  onValueChange: (data: any) => void;
  solarSupply: number;
}
const SolarSupplyLine: React.FC<SolarSupplyLineProps> = (props: SolarSupplyLineProps) => {
  const { onValueChange, solarSupply } = props;

  const { t } = useTranslation();
  const { formatNumber } = useNumberFormat();

  const onChangeHandler = useCallback(
    (value: number) => {
      onValueChange({
        solarpower_for_vehicle: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line hasSlider isPadding>
      <ParameterStyles.Label hasSlider>{t("SOLAR POWER FOR VEHICLES")}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider onChange={onChangeHandler} value={solarSupply} />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>{formatNumber(solarSupply)}%</ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default SolarSupplyLine;
