import { UserState, actionCreators } from "src/redux/user";

export const setUserAuthStatus = (
  currentState: UserState,
  { payload }: ReturnType<typeof actionCreators.setUserAuthStatus>,
): UserState => {
  return {
    ...currentState,
    authStatus: payload,
  };
};
