import { cloneDeep } from "lodash";

import { AllProjectsState } from "../types";

import { actionNames, actionHandlers } from "src/redux/all-projects";
import { ReduxAction } from "src/redux/common.types";

const initialState: AllProjectsState = {
  uniqueAddressProjects: [],
  versionsMap: {},
};

const reducer = (state = initialState, action: ReduxAction<any>): AllProjectsState => {
  switch (action.type) {
    case actionNames.SET_ALL_PROJECTS:
      return actionHandlers.setAllProjects(state, action);
    case actionNames.SET_VERSIONS:
      return actionHandlers.setAllVersions(state, action);
    default:
      return state;
  }
};

export default reducer;
