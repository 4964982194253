import styled from "styled-components/macro";

export const MainContainer = styled.div`
  width: fit-content;
  max-width: min(90%, 1166px);
  margin: auto;
  padding: 4px 2rem;
  background: #fff3d0;

  @media (max-width: 600px) {
    padding: 6px 1rem;
  }

  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  .referral_thing {
    color: #2d4764;
    font-weight: 700;
    cursor: pointer;
    span {
      text-decoration: underline;
      color: #2d4764;
      font-weight: 700;
      cursor: pointer;
      :hover {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        text-decoration-line: underline;
        color: #2662a5;
      }
    }
  }
  .adressNotSelected {
    text-align: center;
    span {
      text-decoration: underline;
      color: #2d4764;
      font-weight: 700;
      cursor: pointer;
      text-align: center;
      :hover {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        text-decoration-line: underline;
        color: #2662a5;
      }
    }
  }
  .adressFound {
    text-align: center;
    span {
      color: #2d4764;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .refrallText {
    text-decoration: underline;
    color: #2d4764;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
  }
  .adressNotFound {
    color: #2d4764;
  }
  .addressNotFound {
    display: flex;
    align-items: center;
    gap: 10px;
    .loader {
      margin: "10px";
      padding-top: "5px";
    }
    .text {
      text-align: center;
    }
  }
`;

export const RefTextContainer = styled.div`
  color: #2d4764;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  padding-top: 17px;
  font-size: 19px;
  span {
    text-decoration: underline;
    color: #2d4764;
    font-weight: 700;
    cursor: pointer;
    :hover {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-decoration-line: underline;
      color: #2662a5;
    }
  }
`;
