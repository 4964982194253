import { useEffect } from "react";
import { useSelector } from "react-redux";

import { Mixpanel } from "src/mix-panel/mixpanel";
import * as trackingEvents from "src/mix-panel/trackingEvents";

import { AppSelectors } from "src/redux/selectors";

const useStepsChangeMixPanel = (): void => {
  const currentStep = useSelector(AppSelectors.getCurrentStep);

  useEffect(() => {
    if (currentStep === 0) {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_NEXT_STEP_STEP1, {});
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_STEP1, {});
    }
    if (currentStep === 1) {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_ARROW_BUTTON, {});
      Mixpanel.track(trackingEvents.USER_CLICKS_ON__STEP2, {});
    }
    if (currentStep === 2) {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_ARROW_BUTTON, {});
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_STEP3, {});
    }
    if (currentStep === 3) {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_ARROW_BUTTON, {});
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_STEP4, {});
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_CHECKOUT_BUTTON, {});
    }
  }, [currentStep]);
};

export default useStepsChangeMixPanel;
