import _, { merge } from "lodash";

import { allProducts } from "src/api/backend-endpoints";
import { RecursivePartial } from "src/utils";

import { actionCreators, AllProjectsState } from "src/redux/all-projects";

export const setAllProjects = (
  currentState: AllProjectsState,
  { payload }: ReturnType<typeof actionCreators.setAllProjects>,
): AllProjectsState => {
  return {
    ...currentState,
    uniqueAddressProjects: payload,
    versionsMap: _.groupBy(payload, (project) => project.shortId),
  };
};
