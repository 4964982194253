export const SET_QUOTE = "project/set-quote";
export const UPDATE_OUTER_DETAILS = "project/update-outer-details";

export const UPDATE_ENERGY_PRODUCTION_PARAMS = "project/set-energy-production-params";
export const UPDATE_ENERGY = "project/set-energy";

export const ADD_WATER_HEATER = "project/add-water-heater";
export const DELETE_WATER_HEATER = "project/delete-water-heater";

export const ADD_BATTERY = "project/add-battery";
export const ADD_BATTERY_PRODUCT = "project/add-battery-product";
export const UPDATE_BATTERY = "project/update-battery";
export const DELETE_BATTERY = "project/delete-battery";

export const ADD_WALLBOX = "project/add-wallbox";
export const ADD_WALLBOX_PRODUCT = "project/add-product";

export const UPDATE_WALLBOX = "project/update-wallbox";
export const DELETE_WALLBOX = "project/delete-wallbox";

export const ADD_EMS = "project/add-ems";
export const UPDATE_EMS = "project/update-ems";
export const DELETE_EMS = "project/delete-ems";
export const ADD_EMS_PRODUCT = "project/add-ems-product";

export const ADD_CAR = "project/add-car";
export const UPDATE_CAR = "project/update-car";
export const DELETE_CAR = "project/delete-car";

export const ADD_HEATPUMP = "project/add-heatpump";
export const ADD_HEATPUMP_PRODUCT = "project/add-heatpump-product";
export const UPDATE_HEATPUMP = "project/update-heatpump";
export const DELETE_HEATPUMP = "project/delete-heatpump";

export const ADD_INVERTER = "project/add-inverter";
export const UPDATE_INVERTER = "project/update-inverter";
export const DELETE_INVERTER = "project/delete-inverter";

export const ADD_SOLAR_PANEL = "project/add-solar-panel";
export const UPDATE_SOLAR_PANEL = "project/update-solar-panel";
export const DELETE_SOLAR_PANEL = "project/delete-solar-panel";

export const ADD_ADDITIONAL_SOFTWARE = "project/add-addition-software";
export const ADD_ADDITIONAL_SOFTWARE_PRODUCT = "project/add-addition-software-products";
export const UPDATE_ADDITIONAL_SOFTWARE = "project/update-addition-software";
export const DELETE_ADDITIONAL_SOFTWARE = "project/delete-addition-software";


export const ADD_ADDITIONAL_HARDWARE = "project/add-addition-hardware";
export const ADD_ADDITIONAL_HARDWARE_PRODUCT = "project/add-addition-hardware-products";
export const UPDATE_ADDITIONAL_HARDWARE = "project/update-addition-hardware";
export const DELETE_ADDITIONAL_HARDWARE = "project/delete-addition-hardware";

export const UPDATE_HOUSETOP_INFO = "project/update-housetop-info";
export const ADD_HOUSETOP_IMAGE = "project/add-housetop-image";

export const UPDATE_HOUSEBOTTOM_INFO = "project/update-housebottom-info";
export const ADD_HOUSEBOTTOM_IMAGE = "project/add-housebottom-image";

export const UPDATE_DRONEDATA_INFO = "project/update-dronedata-info";
export const ADD_DRONEDATA_IMAGE = "project/add-dronedata-image";

export const UPDATE_ELECTRIC_METER_INFO = "project/update-electric-meter-info";
export const ADD_ELECTRIC_METER_IMAGE = "project/add-electric-meter-image";

export const UPDATE_HOUSEHOLD = "project/update-household";
export const UPDATE_BAKE_MODEL_ID = "project/update-bake-model-id";
export const UPDATE_BAKE_MODEL_PROGRESS = "project/update-bake-model-progress";

export const UPDATE_FINANCIAL_DETAILS = "project/update-financial-details";

export const UPDATE_VENDOR = "project/update-vendor";
export const ADD_SELECTED_VENDOR = "project/add-selected-vendor";
export const ADD_SUGGESTED_VENDOR = "project/add-suggested-vendor";

export const UPDATE_PAYMENT_INFO = "project/update-payment-info";

export const UPDATE_CONSTANTS = "project/update-constants";

export const UPDATE_WEB_CONSTANTS = "project/update-webs-constants";

export const UPDATE_MODEL = "project/update-model";

export const UPDATE_DEAL = "project/update-deal";

export const UPDATE_VERSION_AND_SHORT_ID = "project/update-version-and-short-id";
export const UPDATE_INSTALLER_ID = "Project/update-installer-id"
export const UPDATE_PROJECT = "project/update-project";

export const ADD_SELECTED_INSTALLER = "project/add-selected-installer";
export const UPDATE_SELECTED_INSTALLER = "project/update-selected-installer";
export const REMOVE_SELECTED_INSTALLER = "project/remove-selected-installer";

export const ADD_SELECTED_FILES = "project/add-selected-files";

export const UPDATE_QUOTE_QUANTITY = "project/UPDATE_QUOTE_QUANTITY";

export const SET_ACCESS_COUNT = "project/UPDATE_ACCESS_COUNT";

export const UPDATE_THREE_D_MODELS = "project/update-threeD-models";

export const ADD_USER_INFO = "project/add-user-info";

export const DELETE_SUBSIDY = "project/delete-subsidy";
export const DELETE_MANUAL_SUBSIDY = "project/delete-manual-subsidy";