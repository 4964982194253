import { Button } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useNumberFormat } from "src/hooks";

import { ProductDB } from "src/db-types";
import { getNumber } from "src/utils";

import { ProductCardWrapper } from "./ProductCardStyles";

import { ProjectSelectors } from "src/redux/selectors";

interface Props {
  onSelect: (car: string, isLeasing: boolean) => void;
  onRemove: (carId: string) => void;
  car: ProductDB;
  isSelected: boolean;
  isLeasing?: boolean;
}
const ProductCard: React.FC<Props> = (props) => {
  const { onSelect, car, isSelected, onRemove, isLeasing } = props;

  const { t } = useTranslation();

  const projectType = useSelector(ProjectSelectors.getProjectType);

  const { formatNumber } = useNumberFormat();

  const leasingCostDetails = useCallback((): string => {
    let carCost = getNumber(car.priceB2c);
    if (projectType === "b2b") {
      carCost = getNumber(car.priceB2b);
    }

    return `${25}  x 12 x €${formatNumber(carCost)}`;
  }, [car.priceB2b, car.priceB2c, formatNumber, projectType]);

  const buyingCostDetails = useCallback((): string => {
    return `€${formatNumber(Math.round(getNumber(car.buyingCarPrice)))} `;
  }, [car.buyingCarPrice, formatNumber]);

  const leasingButtonText = useCallback((): string => {
    if (isSelected && isLeasing) {
      return `${t("Leasing choosed")}`;
    } else {
      return `${t("choose leasing")}`;
    }
  }, [isLeasing, isSelected, t]);

  const buyingButtonText = useCallback((): string => {
    if (isSelected && !isLeasing) {
      return `${t("Buying choosed")}`;
    } else {
      return `${t("choose buying")}`;
    }
  }, [isLeasing, isSelected, t]);

  const LeasingButton = useMemo(() => {
    return (
      <div>
        <div>
          <div className="down_text">{leasingCostDetails()}</div>
        </div>
        <Button
          className="selet_prdocut"
          onClick={() => {
            if (isSelected) {
              onRemove(car._id);
            } else {
              onSelect(car._id, true);
            }
          }}
        >
          {leasingButtonText()}
        </Button>
      </div>
    );
  }, [car._id, isSelected, leasingButtonText, leasingCostDetails, onRemove, onSelect]);

  const BuyingButton = useMemo(() => {
    return (
      <div>
        <div className="texts1">{buyingCostDetails()}</div>
        <Button
          className="selet_prdocut"
          onClick={() => {
            if (isSelected) {
              onRemove(car._id);
            } else {
              onSelect(car._id, false);
            }
          }}
        >
          {buyingButtonText()}
        </Button>
      </div>
    );
  }, [buyingButtonText, buyingCostDetails, car._id, isSelected, onRemove, onSelect]);

  return (
    <ProductCardWrapper>
      <div className="procut_container">
        <div className="mekeItCenter">
          <div className="upper_image">
            <img src={car.imgUrls?.[0]} alt="" />
          </div>
          <div className="secount_part">
            <div>
              <div className="texts">{car.name}</div>
              <div className="texts">{car.capacity}</div>
            </div>
            <div className="bullet_points">
              <ul>
                {car.bullet_point_list?.map((point) => {
                  return <li key={point}>{point}</li>;
                })}
              </ul>
            </div>
          </div>
          <div className="third_part">
            {!isSelected && (
              <div className="third_sub_part">
                {LeasingButton}
                {BuyingButton}
              </div>
            )}
            {isSelected && isLeasing && LeasingButton}
            {isSelected && !isLeasing && BuyingButton}
          </div>
        </div>
      </div>
    </ProductCardWrapper>
  );
};

export default ProductCard;
