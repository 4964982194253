import Select from "antd/lib/select";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useWindowDimensions } from "src/hooks";

import { DownArrow } from "src/assets/svgs";
import { Mixpanel, trackingEvents } from "src/mix-panel";

import { MainContainer } from "./styles";

const { Option } = Select;

export type FinanceTypeLiteral = "monthly-returns" | "return-on-investment";
export const FinanceTypes: Record<FinanceTypeLiteral, FinanceTypeLiteral> = {
  "monthly-returns": "monthly-returns",
  "return-on-investment": "return-on-investment",
};

interface FinanceDropdownProps {
  setDropdownType: (type: FinanceTypeLiteral) => void;
  dropDownType: FinanceTypeLiteral;
}
export const FinanceDropdown: React.FC<FinanceDropdownProps> = (props: FinanceDropdownProps) => {
  const { setDropdownType, dropDownType } = props;
  const { width } = useWindowDimensions();

  const { t } = useTranslation();

  const handleChange = useCallback(
    (value: string): void => {
      if (value === FinanceTypes["monthly-returns"]) {
        Mixpanel.track(trackingEvents.USER_CLICK_ON_MONTHLY_DROPDOWN, {});
      }
      setDropdownType(value as FinanceTypeLiteral);
    },
    [setDropdownType],
  );

  return (
    <MainContainer>
      <Select
        defaultValue={FinanceTypes["return-on-investment"]}
        suffixIcon={<DownArrow />}
        style={{ width: 233, height: 39, marginLeft: width < 700 ? "12px" : "unset" }}
        onChange={handleChange}
        value={dropDownType}
      >
        <Option value="monthly-returns" title=" ">
          {t("MONTHLY ELECTRICITY COSTS")}
        </Option>
        <Option value="return-on-investment" title=" ">
          {t("RETURN ON INVEST")}
        </Option>
      </Select>
    </MainContainer>
  );
};
