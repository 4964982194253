import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  OfferContainer,
  HeadContainer,
  FeedbackContainer,
  BottomContainer,
  Address,
  RatingContainer,
  ProgressContainer,
  InsideProgress,
  OfferTitle,
  FeedbackTitle,
  PowerText,
  ButtonsContainer,
  RefuseButton,
  CancelButton,
  OfferButton,
} from "./offerRejectStyles";
import { MapIcon, PdfIcon } from "src/assets/svgs";
import { Input, Progress } from "antd";
import { PopupSkeleton } from "src/shared/components";
import { ReduxAction } from "src/redux/common.types";
import { useAppDispatch } from "src/hooks";
import { closePopup } from "src/redux/popups/action/action.creators";
import { POPUP_KEYS } from "src/redux/popups";


const OfferReject = ({ handleRejectOffer, id }: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [reason, setReason] = useState("");

  const handleClose = useCallback(
    (): ReduxAction<any> => dispatch(closePopup(POPUP_KEYS.offer_feedback)),
    [dispatch],
  );

  const handleRemoveOffer = () => {
    handleRejectOffer({ id, reason });
  };


  const { TextArea } = Input;
  return (
    <PopupSkeleton handleClose={handleClose} open={true}>
      <OfferContainer>
        <Progress
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            padding: "0px 10px",
          }}
          strokeColor={{
            "0%": "#ffe925",
            "100%": "#ffaa39",
          }}
          percent={50}
        />
        <HeadContainer>
          <OfferTitle>Offer 2 - Ecologic</OfferTitle>
          <Address>
            <div>Hafenbahnstraße 16, 70327 Stuttgart</div>
            <MapIcon />
          </Address>
          <RatingContainer>
            <ProgressContainer>
              <Progress
                type="circle"
                percent={90}
                strokeWidth={5}
                strokeColor={{
                  "0%": "#ffe925",
                  "100%": "#ffaa39",
                }}
              />
              <InsideProgress>4</InsideProgress>
            </ProgressContainer>
            <PowerText>600 kwh</PowerText>
          </RatingContainer>
        </HeadContainer>
        <FeedbackContainer>
          <FeedbackTitle>
            <div>You´re not convinced by the offer?</div>
            <div>We would love to improve please help us.</div>
          </FeedbackTitle>

          <TextArea
            rows={4}
            placeholder="What can we improve?"
            style={{ borderRadius: "8px", height: "100px" }}
            onChange={(e) => setReason(e.target.value)}
          />
        </FeedbackContainer>
        <BottomContainer>
          <OfferButton>
            <PdfIcon /> Open offer
          </OfferButton>
          <ButtonsContainer>
            <RefuseButton onClick={handleRemoveOffer}>Refuse Offer </RefuseButton>
            <CancelButton onClick={handleClose}>Cancel </CancelButton>
          </ButtonsContainer>
        </BottomContainer>
      </OfferContainer>
    </PopupSkeleton>
  );
};

export default OfferReject;
