import {
  actionSetHover,
  actionSetTooltip,
  actionShowCalculations,
} from "./ReturnOnInvest/hover.reducer";

const STAY_EXPANDED_TIME = 10000;
const TOOLTIP_WAIT_TIME = 500;

let calcCollapseTimeout: any = null;
let tooltipTimeout: any = null;

const clearTimeoutById = (id: number | null) => {
  const isThereTimeout = id != null;
  if (isThereTimeout) {
    clearTimeout(id);
  }
};

export const setHoverHelper = (bar: string, dispatch: any) => {
  dispatch(actionSetHover(bar));

  clearTimeoutById(calcCollapseTimeout);
  clearTimeoutById(tooltipTimeout);

  const nonExpandableBars = [""];
  const shouldCollapseCalc = nonExpandableBars.includes(bar);

  if (shouldCollapseCalc) {
    calcCollapseTimeout = setTimeout(() => {
      dispatch(actionShowCalculations(false));
      dispatch(actionSetTooltip(false));
    }, STAY_EXPANDED_TIME);
  } else {
    dispatch(actionShowCalculations(true));
    tooltipTimeout = setTimeout(() => {
      dispatch(actionSetTooltip(true));
    }, TOOLTIP_WAIT_TIME);
  }
};
