import { useSearchParams } from "react-router-dom";

import { isOnCustomProject, isOnSavedProject } from "./pathHelpers";

import store from "src/redux/store";

const replaceGermanLetters = (str: string): string => {
  str = str.replace(/Ä/g, "AE");
  str = str.replace(/Ö/g, "OE");
  str = str.replace(/Ü/g, "UE");
  str = str.replace(/ẞ/g, "SS");
  str = str.replace(/ö/g, "oe");
  str = str.replace(/ü/g, "ue");
  str = str.replace(/ä/g, "ae");
  str = str.replace(/ß/g, "ss");
  return str;
};

const sanitizeAndEncodeAddressPart = (part: string): string => {
  part = part.trim().replace(/ /g, "-");
  part = replaceGermanLetters(part);
  return encodeURIComponent(part);
};

// const getQueryParamURL = () => {
//   const pathRequiresID = isOnSavedProject() || isOnCustomProject();

//   const params: string[] = [];
//   if (pathRequiresID) {
//     const shortId = store.getState().notification.short_id;
//     params.push(`id=${shortId}`);
//   }

//   const queryParamURL = params.join("&");
//   return queryParamURL;
// };

export const sanitizeCurrentURL = (): string => {
  const address = store.getState().project.quote.mapboxAddress.complete;
  const addressIsNotThere = address === undefined || address === null;
  const [query] = useSearchParams();
  const installerPartnerSlug = query.get("partner");

  if (addressIsNotThere) return window.location.href;

  let addressParts: string[] = address.split(",") ?? [];
  const doesNotHaveAddress = addressParts.length === 0;

  if (doesNotHaveAddress) return window.location.href;

  addressParts = addressParts.map(sanitizeAndEncodeAddressPart);

  const projectURL = addressParts.join("-");
  const projectType = store.getState().project.type;
  let fullURL = `${window.location.origin}/application/${projectType}/default-${projectURL}`;

  if (installerPartnerSlug) {
    fullURL += "?" + `partner=${installerPartnerSlug}`;
  }

  // const queryParamURL = getQueryParamURL();
  // const areThereAnyQueryParams = queryParamURL.length > 0;

  // if (areThereAnyQueryParams) {
  //   fullURL += "?" + queryParamURL;
  // }

  return fullURL;
};
