import { createStyles } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";

const genUseStyles: any = () => {
  return makeStyles((theme: any) =>
    createStyles({
      root: {
        "--blue-text": "#304864",
        "--green-text": "#4D7E6D",
        width: "97%",
        height: "auto",
        margin: "auto",
        fontFamily: "Nunito",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: "2.5rem",
        [theme.breakpoints.up("sm")]: {
          width: "100%",
          justifyContent: "center",
        },
      },
      inline: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      },
      addressPrefrence: {
        marginTop: "-10px",
        marginBottom: "21px",
      },
      heading: {
        fontWeight: "700",
        fontSize: "24px",
        color: "var(--blue-text)",
        [theme.breakpoints.up("sm")]: {
          fontSize: "30px",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "30px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "20px",
        },
      },
      subHeading: {
        fontSize: "16px",
        color: "var(--blue-text)",
        textAlign: "left",
        lineHeight: "30px",
        "margin-top": "18px",
        [theme.breakpoints.up("sm")]: {
          fontWeight: "400",
          fontSize: "23px",
          textAlign: "center",
          marginBottom: "7px",
          lineHeight: "initial",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "18px",
          textAlign: "center",
        },
      },
      subHeadingContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        paddingBottom: 10,
      },
      text: {
        fontFamily: "Nunito",
        fontWeight: "400",
        fontSize: "16px",
        color: "var(--blue-text)",
        textAlign: "left",
        lineHeight: "30px",
        [theme.breakpoints.up("sm")]: {
          fontSize: "22px",
          color: "var(--green-text)",
          textAlign: "center",
          lineHeight: "initial",
        },
        marginBottom: "30px",
      },
    }),
  );
};

export default genUseStyles;

export const InstallerPartner = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  .image-container {
    width: 115px;
    height: 45px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .horzontal-line {
    width: 37px;
    height: 0px;
    border: 1px solid #2d4764;
    transform: rotate(90deg);

    @media (max-width: 500px) {
      display: none;
    }
  }

  p {
    color: #2d4764;
    font-weight: 700;
    cursor: pointer;
  }

  img {
    width: 137px;
    height: 32px;
  }
`;
