interface SuccessiveGrowth {
  initial: number;
  increaseRate: number;
  years: number;
}
export const successiveGrowth = ({ initial, increaseRate, years }: SuccessiveGrowth): number => {
  if (increaseRate === 0) {
    return initial * years;
  }

  const increaseRateDecimal = 1 + increaseRate / 100;
  const compoundingTillNow =
    (initial * (Math.pow(increaseRateDecimal, years) - 1)) / (increaseRateDecimal - 1);
  return Math.round(compoundingTillNow);
};
