import styled from "styled-components/macro";

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px;
  align-items: center;
  gap: 2rem;
  padding-inline: 10px;
  max-width: 70vw;
  font-family: "NuNito";
`;

export const PrimaryButton = styled.button`
  border-radius: 100px;
  color: #2d4764;
  padding: 0.5rem 1rem;
  font-weight: 700;
  font-size: 16px;
  background: #ffd75d;
  :disabled {
    cursor: not-allowed;
  }
`;