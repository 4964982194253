import { DEFAULT_MICRO_INVERTER_COST } from "src/data";
import { ProductDB } from "src/db-types";

import { ProjectType } from "src/redux/project";

export interface ProductCostProps {
  projectType: ProjectType;
  product: ProductDB;
}

export const getProductCost = (props: ProductCostProps): number => {
  const { product, projectType } = props;

  const isMicroInverter = product.type === "inverter" && product.subtype === "premium";
  if (isMicroInverter) return DEFAULT_MICRO_INVERTER_COST;

  if (projectType === "b2b") return product.priceB2b;
  else return product.priceB2c;
};
