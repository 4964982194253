import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PopupSkeleton } from "src/shared/components";

import * as S from "./PdfPreview.styles";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import SolarHubLogo from "src/assets/logos/SolarhubLogo.svg";
import { PopupSelectors, UserSelectors } from "src/redux/selectors";
import { pdfjs, Document, Page } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


export const PdfPreview: React.FC = () => {
  const dispatch = useDispatch();
  const pdfData = useSelector(PopupSelectors.getPdfPreviewPopup);

  const handleClose = useCallback(() => {
    dispatch(PopupActions.closePopup(POPUP_KEYS.pdfPreview));
  }, [dispatch]);

  const [numPages, setNumPages] = useState<any>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <PopupSkeleton handleClose={handleClose} open={true}>
      <S.MainContentWrapper>
      <Document
        file={decodeURI(pdfData?.data?.url)}
        onLoadSuccess={onDocumentLoadSuccess}

      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={index} pageNumber={index + 1} />
        ))}
      </Document>
      </S.MainContentWrapper>
    </PopupSkeleton>
  );
};

export default PdfPreview;
