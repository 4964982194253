import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DonoughtRecord, DonoughtChart } from "src/shared/components";

import { getChartRecordsFromModel } from "./ElectricityConsumptionChartRecords";

import { UserSelectors, ProjectSelectors } from "src/redux/selectors";

interface ElectricityConsumptionChartProps {
  captureScreenshot?: () => void;
}

const ElectricityConsumptionChart: React.FC<ElectricityConsumptionChartProps> = ({
  captureScreenshot,
}) => {
  const { t } = useTranslation();

  const lang = useSelector(UserSelectors.getLangCode);
  const model = useSelector(ProjectSelectors.getModel);

  const [chartData, setChartData] = useState<DonoughtRecord[]>([]);

  useEffect(() => {
    const chartRecords = getChartRecordsFromModel(model);
    setChartData(chartRecords);
  }, [model, lang]);

  useEffect(() => {
    if (typeof captureScreenshot === "function") {
      setTimeout(() => {
        void captureScreenshot();
      }, 2000);
    }
  }, [chartData, captureScreenshot]);

  return (
    <DonoughtChart
      title={t("Electricity consumption")}
      chartData={chartData}
      middleText={{
        title: `${model.percentages.autarky.toFixed(0)}%`,
        subTitle: t("Autarky"),
      }}
      hideTitle={Boolean(captureScreenshot)}
      openByDefault={Boolean(captureScreenshot)}
    />
  );
};

export default ElectricityConsumptionChart;
