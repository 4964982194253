import { formatNumberByLang } from "src/hooks/useNumberFormat";
import { OverallConsumptionModel } from "src/prediction-model/types/model.types";
import { DonoughtRecord } from "src/shared/components";

export const getChartRecordsFromModel = (model: OverallConsumptionModel): DonoughtRecord[] => {
  return [
    {
      color: "#FFD75D",
      hoverLabel: "PV-direct supply",
      value: parseInt(model.fromPV.toFixed(0)),
      shadowColor: "rgba(255, 215, 93, 1)",
      label: {
        mainLabel: "PV-direct supply",
        subLine: `${formatNumberByLang(model.percentages.fromPV.toFixed(0))}%, ${formatNumberByLang(
          model.fromPV.toFixed(0),
        )} kWh`,
      },
    },
    {
      color: "#DF6569",
      hoverLabel: "Grid-supply",
      value: parseInt(model.fromGrid.toFixed(0)),
      shadowColor: "rgba(223, 101, 105, 1)",
      label: {
        mainLabel: "Grid-supply",
        subLine: `${formatNumberByLang(
          model.percentages.fromGrid.toFixed(0),
        )}%, ${formatNumberByLang(model.fromGrid.toFixed(0))} kWh`,
      },
    },
    {
      color: "#4D7E6D",
      hoverLabel: "Battery supply",
      value: parseInt(model.fromBattery.toFixed(0)),
      shadowColor: "rgba(77, 126, 109, 1)",
      label: {
        mainLabel: "Battery supply",
        subLine: `${formatNumberByLang(
          model.percentages.fromBattery.toFixed(0),
        )}%, ${formatNumberByLang(model.fromBattery.toFixed(0))} kWh`,
      },
    },
  ];
};
