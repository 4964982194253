import { merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateEMS = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateEMS>,
): ProjectState => {
  const EMS = merge({}, currentState.components.EMS, payload);
  return {
    ...currentState,
    components: {
      ...currentState.components,
      EMS,
    },
  };
};
