import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getLangCode } from ".././../redux/user/selectors";
import PopupSkeleton from "../../shared/components/PopupSkeleton/popupSkeleton";

import { IdetailsPlanData } from "./cardsData";
import DetailsPlan from "./detailplan/DetailsPlan";
import { PayWallWrapper } from "./styles";

import { POPUP_KEYS } from "src/redux/popups";
import { closePopup } from "src/redux/popups/action/action.creators";

function getDetailsPlanData(): IdetailsPlanData[] {
  return [
    {
      price: t("60 €"),
      titile: t("3D-Simulation"),
      description: t("PLAN_DESCRIPTION_1"),
      bulletPoint: [
        t("3D-Simulation"),
        t("Selection of optimal components"),
        t("Take part in solar webinars"),
        t("Access to solar learning"),
      ],
      index: 0,
    },
    {
      price: t("200 €"),
      titile: t("Energy consulting"),
      description: t("PLAN_DESCRIPTION_2"),
      bulletPoint: [
        t("Everything from 3D-simulation"),
        t("Online consulting"),
        t("1 hour phone + chat support"),
        t("Installer search"),
      ],
      index: 1,
    },
    {
      price: t("750 €"),
      titile: t("Realization"),
      description: t("PLAN_DESCRIPTION_3"),
      bulletPoint: [
        t("Check installer offers"),
        t("Check energy setup"),
        t("Energy and tax consultation*"),
        t("Grid enrollment"),
      ],
      index: 2,
    },
  ];
}
const PayWall: React.FC = () => {
  const { t } = useTranslation();
  const languageCode = useSelector(getLangCode);
  const dispatch = useDispatch();
  const [dataOfPaywall, setDataOfPaywall] = useState(getDetailsPlanData());

  useEffect(() => {
    setDataOfPaywall(getDetailsPlanData());
  }, [languageCode]);

  const handleClose = useCallback(() => dispatch(closePopup(POPUP_KEYS.pay_wall)), [dispatch]);

  return (
    <PopupSkeleton handleClose={handleClose} open={true}>
      <PayWallWrapper>
        <div className="checkoutMain">
          <div className="hading_work">
            <div className="heading_text1">{t("SolarHub - die digitale Energieberatung")}</div>
            <div className="heading_text2">{t("Choose your preferred option")}</div>
          </div>
          <div className="detailPlan_mainContainer">
            {dataOfPaywall.map((data: IdetailsPlanData) => (
              <DetailsPlan data={data} key={data.index} />
            ))}
          </div>
          <div className="sampleProjectButton" onClick={handleClose}>
            {t("Play around with sample Project")}
          </div>
        </div>
      </PayWallWrapper>
    </PopupSkeleton>
  );
};

export default PayWall;
