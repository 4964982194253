import { useEffect, useState } from "react";

import { BackendApis } from "src/api";

import { WebinarDB } from "src/db-types";

import { useWebinarsApi } from "./apis";

import { WebinarActions } from "src/redux/actionCreators";

import { useAppDispatch } from ".";

export const useGetAllWebinars = (): void => {
  const dispatch = useAppDispatch();
  const [allData, setAllData] = useState<WebinarDB[]>([]);

  const { fetchData, error, response } = useWebinarsApi();

  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);

  useEffect(() => {
    if (!response) return;

    setAllData(response.data.data);
  }, [response]);

  useEffect(() => {
    void fetchData({
      ...BackendApis.getAllWebinars,
    });
  }, [fetchData]);

  useEffect(() => {
    dispatch(WebinarActions.setWebinars(allData));
  }, [allData, dispatch]);
};
