import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/hooks";

import { PopupActions } from "src/redux";
import { getPartnerCityData } from "src/redux/application/selector";
import { POPUP_KEYS } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";
import { getInstaller } from "src/redux/user/selectors";

const URL_OPTIONS = {
  goToHubspot: "goToHubspot",
  goToEmail: "goToEmail",
  goToCalendy: "goToCalendy",
  goToRedirect: "goToRedirect",
};
interface NonVerifiedAddressBarProps {
  addressText: string;
}
const NonVerifiedAddressBar: React.FC<NonVerifiedAddressBarProps> = (
  props: NonVerifiedAddressBarProps,
) => {
  const { addressText } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const partnerCityData = useSelector(getPartnerCityData);
  const isCityPartnerCity = partnerCityData.partnerStatus;
  const [partnerStatus, setParterStatus] = useState<null | string>(null);
  const installerPartner = useSelector(getInstaller);

    const openHubspotPopup = useCallback(() => {
    if (installerPartner?.whichOptionSelected === URL_OPTIONS.goToHubspot) {
      dispatch(PopupActions.openPopup(POPUP_KEYS.hubspot_meeting));
    } else if (installerPartner?.whichOptionSelected === URL_OPTIONS.goToRedirect) {
      window.open(installerPartner.appointmentURL, "_blank", "noreferrer");
    } else if (installerPartner?.whichOptionSelected === URL_OPTIONS.goToEmail) {
      //@ts-ignore
      window.location.href = `mailto:${installerPartner?.appointmentURL}`;
    }else{
      dispatch(PopupActions.openPopup(POPUP_KEYS.hubspot_meeting));
    }
  }, [dispatch, installerPartner]);

  useEffect(() => {
    setParterStatus(isCityPartnerCity);
  }, [isCityPartnerCity]);

  return (
    <div className="adressFound">
      {installerPartner ? (
        <>
          <span
            onClick={() => {
              const action = PopupActions.openPopup(POPUP_KEYS.welcome_address);
              dispatch(action);
            }}
          >
            {t("For your building we are showing an automatic panel placement")}
          </span>{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={openHubspotPopup}
          >
            {t("- as a next step we create a detailed planning for you")}
          </span>
        </>
      ) : (
        <>
          {t("Project")}{" "}
          <span
            onClick={() => {
              const action = PopupActions.openPopup(POPUP_KEYS.welcome_address);
              dispatch(action);
            }}
          >
            {addressText}
          </span>{" "}
          {partnerStatus ? (
            <span style={{ cursor: "pointer" }}>
              {t("- wir melden uns sobald deine Planung fertiggestellt ist.")}
            </span>
          ) : (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(openPopup(POPUP_KEYS.pay_wall))}
            >
              {t("- Choose a plan to receive a detailed 3D-Model soon")}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default NonVerifiedAddressBar;
