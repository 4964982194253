import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { useURLData } from "src/hooks";

import { InstallerDB } from "src/db-types";
import { useAddInstallerAPI, useDeleteInstallerAPI } from "src/hooks/apis";
import { AuthPopupProps, AUTH_MODES } from "src/popups";

import { useIsInstallerSelected } from "../../hooks/useIsInstallerSelected";

import { NearbyInstallers, Explore } from "./components";
import * as S from "./Installers.styles";

import { AppActions, PopupActions } from "src/redux";
import { ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { InstallerPopupData } from "src/redux/popups/reducer/reducer";
import { UserSelectors, ProjectSelectors, PopupSelectors } from "src/redux/selectors";
import { WhiteLabelInstaller } from "../WhiteLabelInstaller/WhiteLabelInstaller";

export const Installers: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentUser = useSelector(UserSelectors.getUser);
  const isFreeInstallerRequested = useSelector(ProjectSelectors.getVendor).freeChoiceInstaller;
  const {
    data: { noOfTimesShown: confirmationPopupCount },
  } = useSelector(PopupSelectors.getInstallerRequestedPopup);
  const pid = useSelector(ProjectSelectors.getProjectID);
  const installerDetails = useSelector(UserSelectors?.getInstaller);
  const { isOnDefault } = useURLData();
  const {
    error: addInstallerError,
    fetchData: addInstallerApi,
    response: addInstallerResponse,
  } = useAddInstallerAPI();
  const {
    response: deleteInstallerResponse,
    error: deleteInstallerError,
    fetchData: deleteInstallerApi,
  } = useDeleteInstallerAPI();
  const { isSelected: isInstallerSelected } = useIsInstallerSelected();

  const openAddressPopup = useCallback(() => {
    dispatch(PopupActions.openPopup(POPUP_KEYS.welcome_address));
  }, [dispatch]);

  const openAuthPopup = useCallback(() => {
    const data: AuthPopupProps = {
      authMode: AUTH_MODES.signup,
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.auth, data));
  }, [dispatch]);

  const showInstallerPopup = useCallback(() => {
    // TODO: Hook popup
    if (confirmationPopupCount >= 1) {
      toast.success(t("installer has been requested"));
      return;
    }

    const popupProps: InstallerPopupData = {
      noOfTimesShown: confirmationPopupCount + 1,
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.installer_confirmation, popupProps));
  }, [confirmationPopupCount, dispatch, t]);

  const validateBeforeRequestingOffer = useCallback(() => {
    if (!currentUser) {
      openAuthPopup();
      return false;
    }

    if (isOnDefault) {
      openAddressPopup();
      return false;
    }

    // TODO: save project when user is on
    // if (
    //   project?.quoteStatus === "found" &&
    //   !window?.location.pathname.includes("saved")
    // ) {
    //   return save(null, false, false);
    // }

    return true;
  }, [currentUser, isOnDefault, openAddressPopup, openAuthPopup]);

  const requestInstaller = useCallback(
    (installer: InstallerDB) => {
      const shouldRequest = validateBeforeRequestingOffer();
      if (!shouldRequest) return;

      if (isInstallerSelected(installer._id)) {
        return;
      }

      void addInstallerApi({
        ...BackendApis.addInstaller,
        data: {
          iid: installer._id,
          pid,
        },
      });
      dispatch(
        AppActions.updateAppState({
          actions: {
            updateDeal: true,
          },
        }),
      );
    },
    [addInstallerApi, dispatch, isInstallerSelected, pid, validateBeforeRequestingOffer],
  );

  const requestFreeChoice = useCallback(() => {
    const shouldRequest = validateBeforeRequestingOffer();
    if (!shouldRequest) return;

    if (isFreeInstallerRequested) return;

    dispatch(
      ProjectActions.updateVendor({
        freeChoiceInstaller: true,
      }),
    );
    // TODO
    // sendEmailForFreeChoiceInstaller(project, currentUser);

    toast.success(t("installer has been requested"));

    // TODO
    // updateDealForInstallerRequest();
  }, [dispatch, isFreeInstallerRequested, t, validateBeforeRequestingOffer]);

  const cancelInstaller = useCallback(
    (installer: InstallerDB) => {
      const shouldRequest = validateBeforeRequestingOffer();
      if (!shouldRequest) return;

      const notSelected = !isInstallerSelected(installer._id);
      if (notSelected) {
        return;
      }

      void deleteInstallerApi({
        ...BackendApis.deleteInstaller({
          iid: installer._id,
          pid,
        }),
      });

      dispatch(
        AppActions.updateAppState({
          actions: {
            updateDeal: true,
          },
        }),
      );
    },
    [deleteInstallerApi, isInstallerSelected, pid, validateBeforeRequestingOffer],
  );

  const acceptOffer = useCallback(() => {
    // TODO : Accept offer callback
  }, []);

  useEffect(() => {
    if (!addInstallerResponse) return;

    const {
      data: { data },
    } = addInstallerResponse;

    dispatch(ProjectActions.addSelectedInstaller(data));
    showInstallerPopup();
  }, [addInstallerResponse, dispatch, showInstallerPopup]);

  useEffect(() => {
    if (!addInstallerError) return;

    console.error(addInstallerError);
    toast.error(t("Something went wrong while requesting installer"));
  }, [addInstallerError, t]);

  useEffect(() => {
    if (!deleteInstallerError) return;

    console.error(deleteInstallerError);
    toast.error(t("Something went wrong while removing installer"));
  }, [deleteInstallerError, t]);

  return !installerDetails ? (
    <S.Installer>
      <NearbyInstallers
        onAcceptOffer={acceptOffer}
        onFreeChoiceRequest={requestFreeChoice}
        onInstallerCancelRequest={cancelInstaller}
        onRequestInstallerOffer={requestInstaller}
      />
      <Explore
        onAcceptOffer={acceptOffer}
        onInstallerCancelRequest={cancelInstaller}
        onRequestInstallerOffer={requestInstaller}
      />
    </S.Installer>
  ) : (
    <WhiteLabelInstaller />
  );
};
