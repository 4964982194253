import { clone } from "lodash";

import { actionCreators } from "../..";
import { WebinarState } from "../types";

export const setWebinars = (
  currentState: WebinarState,
  { payload }: ReturnType<typeof actionCreators.setWebinars>,
): WebinarState => {
  const newState = clone(currentState);
  newState.webinars = payload;
  return newState;
};
