import { formatNumberByLang } from "src/hooks/useNumberFormat";
import { OverallConsumptionModel } from "src/prediction-model/types/model.types";
import { DonoughtRecord } from "src/shared/components";
import { getPercentage } from "src/utils";

export const getChartRecordsFromModel = (model: OverallConsumptionModel): DonoughtRecord[] => {
  const heatpumpSharePercent = getPercentage(
    model.heatShare.heatpump,
    model.heatShare.heatpump + model.heatShare.kessel + model.heatingRod.fromPVAndBattery,
  );
  const heatingRodSharePercent = getPercentage(
    model.heatingRod.fromPVAndBattery,
    model.heatShare.heatpump + model.heatingRod.fromPVAndBattery,
  );

  return [
    {
      color: "#B5262B",
      hoverLabel: "Heatpump",
      value: parseInt(model.heatShare.heatpump.toFixed(0)),
      shadowColor: "rgba(181, 38, 43, 1)",
      label: {
        mainLabel: "Heatpump",
        subLine: `${formatNumberByLang(heatpumpSharePercent.toFixed(0))}%, ${formatNumberByLang(
          model.heatShare.heatpump.toFixed(0),
        )} kWh`,
      },
    },
    {
      color: "#B5268D",
      hoverLabel: "Heating-rod",
      value: parseInt(model.heatingRod.fromPVAndBattery.toFixed(0)),
      shadowColor: "rgba(181, 38, 141, 1)",
      label: {
        mainLabel: "Heating-rod",
        subLine: `${formatNumberByLang(heatingRodSharePercent.toFixed(0))}%, ${formatNumberByLang(
          model.heatingRod.fromPVAndBattery.toFixed(0),
        )} kWh`,
      },
    },
  ];
};
