import { clone } from "lodash";

import { UserState, actionCreators } from "src/redux/user";

export const updateLastLog = (
  currentState: UserState,
  { payload }: ReturnType<typeof actionCreators.updateLastLog>,
): UserState => {
  if (!currentState.user) return currentState;

  return {
    ...currentState,
    user: {
      ...currentState.user,
      lastLog: payload,
    },
  };
};
