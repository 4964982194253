export enum NavItemKey {
  LOGOUT,
  WEBINAR,
  OFFER,
  COMMUNITY,
  KNOWLEDGE_HUB,
  ADD_CONFIGURATION,
  BACK_TO_PROJECT,
  LOGO,
  MOBILE_CLOSE_MENU,
  MOBILE_OPEN_MENU,
  REFERRAL,
}
