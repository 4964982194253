import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNumberFormat } from "src/hooks/useNumberFormat";
import { Column, Row } from "src/shared/StyledComponents/Flex";
import { getPercentage } from "src/utils";

import { Bar, LabelColumn, Line, Versus, Wrapper } from "./HorizontalBarCardChart.styles";

interface SelfSupplyChartType {
  left: {
    value: number;
    IconComponent: React.FunctionComponent;
    title: string;
    color: string;
  };
  right: {
    value: number;
    IconComponent: React.FunctionComponent;
    title: string;
    color: string;
  };
  subLabelFormatting?: Function;
}

const HorizontalBarCardChart: React.FC<SelfSupplyChartType> = (props: SelfSupplyChartType) => {
  const { left, right } = props;
  const { t } = useTranslation();

  const [leftPartPercentage, setLeftPartPercentage] = useState(0);

  const { formatNumber } = useNumberFormat();

  useEffect(() => {
    const leftPercent = getPercentage(left.value, right.value + left.value);
    setLeftPartPercentage(leftPercent);
  }, [left.value, right.value]);

  return (
    <Wrapper>
      <Row align="intial">
        <LabelColumn align="left">
          <Row align="center" gap={"5px"}>
            <left.IconComponent />
            <span>{t(left.title)}</span>
          </Row>
          <Column gap="3px">
            <div className="numerical">{formatNumber(leftPartPercentage)} %</div>
            <div className="sub-numerical">
              {props.subLabelFormatting?.(formatNumber(left.value)) ?? formatNumber(left.value)}
            </div>
          </Column>
        </LabelColumn>
        <Column align="center">
          <Line />
          <Versus>VS</Versus>
          <Line />
        </Column>
        <LabelColumn align="right">
          <Row gap="5px" reverse>
            <right.IconComponent />
            <span>{t(right.title)}</span>
          </Row>
          <Column gap="3px" align="flex-end">
            <div className="numerical">
              {formatNumber(100 - parseInt(String(leftPartPercentage)))} %
            </div>
            <div className="sub-numerical">
              {props.subLabelFormatting?.(formatNumber(right.value)) ?? formatNumber(right.value)}
            </div>
          </Column>
        </LabelColumn>
      </Row>

      <Bar bgColor={right.color} topColor={left.color} topPercent={leftPartPercentage}>
        <div className="top-bar"></div>
      </Bar>
    </Wrapper>
  );
};

export default HorizontalBarCardChart;
