import styled from "styled-components/macro";

export const ChartBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  gap: 1rem;
  align-items: center;
  position: relative;

  .labels {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 5px;
  }

  .chart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .middle-text {
      position: absolute;
      display: flex;
      flex-direction: column;
      max-width: 100px;
      align-items: center;
      justify-content: center;
      left: 51%;
      top: 51%;
      transform: translate(-50%, -50%);

      .big-text {
        font-size: 24px;
        line-height: 24px;
        font-weight: 500;
        color: rgba(76, 78, 100, 0.87);
      }

      .small-text {
        font-size: 14px;
        text-align: center;
        color: rgba(76, 78, 100, 0.68);
      }
    }
  }
`;
