import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { HeroImage } from "src/assets/images";
import { PopupSkeleton } from "src/shared/components";
import { convertToEmbedYoutubeLink } from "src/utils";

import { ProductMainBody, ProductTitle, ProductVideo, SubBody } from "./ProductInfoText";
import * as S from "./styles";

import { PopupActions } from "src/redux";
import { ProjectComponentsKeys } from "src/redux/project/types/components";
import { PopupSelectors } from "src/redux/selectors";

export interface ProductInfoProps {
  type: Exclude<ProjectComponentsKeys, "cars" | "waterHeating">;
}
export const ProductInfoPopup: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    data: { type },
  } = useSelector(PopupSelectors.getProductInfoPopup);

  return (
    <PopupSkeleton
      open={true}
      handleClose={() => {
        dispatch(PopupActions.closeProductInfoPopup());
      }}
    >
      <S.MainContainer>
        <div className="image_and_title">
          <S.MainText>{t(ProductTitle[type])}</S.MainText>
          <div className="hero_image">
            <img src={HeroImage} />
          </div>
        </div>
        <S.SubText1>{t(SubBody[type])}</S.SubText1>
        <S.SubText>
          <ul style={{ listStyleType: "disc" }}>
            {ProductMainBody[type].map((text: any) => {
              return <li key={text}>{t(text)}</li>;
            })}
          </ul>
        </S.SubText>
        <S.SubText3>
          {t(
            "In our selector we give you the flexibility to choose according to your criteria To get more insights about choosing the rights product check our explainer video",
          )}
        </S.SubText3>
        <S.VideoContainer>
          <iframe
            frameBorder="0"
            width="100%"
            height="100%"
            allowFullScreen
            src={convertToEmbedYoutubeLink(ProductVideo[type])}
          ></iframe>
        </S.VideoContainer>
      </S.MainContainer>
    </PopupSkeleton>
  );
};

export default ProductInfoPopup;
