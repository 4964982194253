import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PopupSkeleton } from "src/shared/components";

import * as S from "./PdfTemplateSelection.styles";
import { PopupActions } from "src/redux";
import { PopupSelectors, UserSelectors } from "src/redux/selectors";
import { POPUP_KEYS } from "src/redux/popups";
import { Select } from "antd";
import { CircularProgress } from "@mui/material";
import { ApiAxiosClient } from "src/axios";
import { getAddress, getProject } from "src/redux/project/selectors";
import { downloadProjectPdf } from "src/api/backend-endpoints";
import useDownloadPdf from "src/pages/ApplicationPage/hooks/useDownloadPdf";
import axios from "axios";

export const PdfTemplateSelection: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const installerDetails = useSelector(UserSelectors.getInstaller);
  const [templates, setTemplates] = useState<[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const mapboxAddress = useSelector(getAddress);
  const project = useSelector(getProject);
  const userDetails = useSelector(UserSelectors.getUser);
  const generatePdfData = useDownloadPdf();

  const fetchPdfTemplates = useCallback(async () => {
    try {
      setLoading(true);
      if (!userDetails?.iid) return;

      const response = await ApiAxiosClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/comman/templates/${userDetails?.iid}`,
      );
      setTemplates(
        response.data.templates.map((template: any) => ({
          label: template.name,
          value: template.id,
        })),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [userDetails?.iid]);

  useEffect(() => {
    fetchPdfTemplates();
  }, []);

  const getOfferPdf = useCallback(async () => {
    const pdfName = `Solarprojekt-SolarHub-${mapboxAddress?.address} ${mapboxAddress?.postcode}-${
      project?.shortId ? project?.shortId : ""
    }`;

    const payload = await generatePdfData({ templateId: selectedTemplate });

    const response = await ApiAxiosClient.post(
      `${downloadProjectPdf.url}/?fileName=${pdfName}`,
      payload,
    );
    const url = encodeURI(response.data.url);
    return url;
  }, [selectedTemplate, mapboxAddress.address, mapboxAddress.postcode, project.shortId]);

  const handleDownloadPdf = useCallback(async () => {
    try {
      setLoading(true);
      const url = await getOfferPdf();

      const response = await axios.get(url, { responseType: "blob" });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlBlob;

      const pdfName = `Solarprojekt-SolarHub-${mapboxAddress?.address} ${mapboxAddress?.postcode}-${
        project?.shortId ? project?.shortId : ""
      }`;

      link.download = `${pdfName}.pdf`;
      link.click();

      dispatch(PopupActions.closePopup(POPUP_KEYS.pdf_template_selection));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [getOfferPdf, dispatch]);

  const handleClose = useCallback(() => {
    dispatch(PopupActions.closePopup(POPUP_KEYS.pdf_template_selection));
  }, [dispatch]);

  return (
    <PopupSkeleton handleClose={handleClose} open={true}>
      <S.MainContentWrapper>
        <img src={installerDetails?.logoUrl?.[0]} className="max-h-28" alt="Installer Logo" />
        <Select
          placeholder={t("Select Template")}
          options={templates}
          onChange={(value: string) => setSelectedTemplate(value)}
          style={{ width: 400, textAlign: "center" }}
        />
        {loading && !!selectedTemplate ? (
          <CircularProgress color="warning" />
        ) : (
          <S.PrimaryButton onClick={handleDownloadPdf} disabled={loading || !selectedTemplate}>
            {t("Download PDF")}
          </S.PrimaryButton>
        )}
      </S.MainContentWrapper>
    </PopupSkeleton>
  );
};

export default PdfTemplateSelection;
