import React from "react";
import { useTranslation } from "react-i18next";

import { HeatpumpLogo } from "src/assets/svgs";

const Heading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flexing">
      <HeatpumpLogo />
      <div className="text_first">{t("HEATPUMP")}</div>
    </div>
  );
};

export default Heading;
