import React, { useCallback } from "react";
import { MultiTenantContainer } from "../MultiTenantStyles";
import { InfoIcon } from "src/shared/components";
import { useTranslation } from "react-i18next";
import { OperationCards, OperationContainer, OperationTitle } from "./OperatorModelStyles";
import OperationCard from "./operationCard/OperationCard";

import fullService from "src/assets/popups/fullService.svg";
import partialService from "src/assets/popups/partialService.svg";
import selfRealization from "src/assets/popups/selfRealization.svg";
import { useSelector } from "react-redux";
import { ProjectSelectors } from "src/redux/selectors";
import { ProjectActions } from "src/redux/actionCreators";
import { useAppDispatch } from "src/hooks";

export const operationCards = [
  {
    id: 1,
    title: "Voll-Service",
    text: "Ein Contractor (Betreiber) pachtet, finanziert, errichtet und betreibt die PV-Anlage und übernimmt die Belieferungen der Wohnungen.",
    image: fullService,
  },
  {
    id: 2,
    title: "Teil-Service",
    text: "Eine Service-PartnerIn übernimmt einzelne Pflichten, z.B. den Messstellenbetrieb und die Stromlieferung und Rechnungsstellung (Lieferkettenmodell).",
    image: partialService,
  },
  {
    id: 3,
    title: "Eigenständige Umsetzung",
    text: "Die Gebäudeeigentümer*innen machen alles selbst einschließlich der (gewerblichen) Stromlieferung an die Mietenden als Elektrizitätsunternehmen.",
    image: selfRealization,
  },
];

interface IOperatorModel {
  setactiveStep: (tab: number) => void;
}

const OperatorModel = ({ setactiveStep }: IOperatorModel) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { operationModal } = useSelector(ProjectSelectors.getHousehold);

  const handleConceptSelect = useCallback(
    (value: string): void => {
      dispatch(
        ProjectActions.updateHousehold({
          operationModal: value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <MultiTenantContainer>
      <div className="headers">
        <div className="make_flex">
          <div className="head">{t("Select your operator model")}</div> <InfoIcon />
        </div>
        <div className="paragraph">
          {t("For the realization of the tenant electricity concept there is multiple options")}
        </div>
      </div>
      <OperationContainer>
        <OperationTitle>{t("How do you want to manage your system?")}</OperationTitle>
        <OperationCards>
          {operationCards.map((card) => {
            return (
              <OperationCard
                title={card.title}
                text={card.text}
                image={card.image}
                count={card.id}
                handleConceptSelect={handleConceptSelect}
                setactiveStep={setactiveStep}
                isActive={operationModal === card.title}
              />
            );
          })}
        </OperationCards>
      </OperationContainer>
    </MultiTenantContainer>
  );
};

export default OperatorModel;
