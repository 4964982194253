import { InstallerDetails, InstallerOfferDB } from "src/db-types";
import { OverallConsumptionModel } from "src/prediction-model/types/model.types";

import { RootState } from "../store";

import {
  BaseHouseImages,
  CarComponent,
  ElectricMeterImages,
  HeatpumpComponent,
  HouseTopImages,
  HouseholdEnergyParams,
  MapboxAddress,
  ProjectComponents,
  ProjectConstants,
  ProjectFinancialDetails,
  ProjectState,
  ProjectType,
  ProjectVendor,
  ProjectWebConstants,
  Quote,
  QuoteProfile,
  QuoteProfileName,
  ProjectImages,
} from "./types";
import { FilterableBaseComponent } from "./types/components";
import { Energy } from "./types/energy";
import { EnergyProductionParams } from "./types/energy-production-params";
import { ThreeDModels } from "./types/house-images";
import { threeDModelUrls, threeDModels } from "./types/project-state";

export const getProject = (state: RootState): ProjectState => state.project;
export const getProjectID = (state: RootState): string => state.project.id;
export const getProjectType = (state: RootState): ProjectType => state.project.type;

/** Quote selectors */
export const getQuoteId = (state: RootState): string => state.project.quoteId;
export const getQuote = (state: RootState): Quote => state.project.quote;
export const getAddress = (state: RootState): MapboxAddress => state.project.quote.mapboxAddress;
export const getAddressCoords = (state: RootState): [number, number] =>
  state.project.quote.mapboxAddress.geometry.coordinates;
export const getProfileName = (state: RootState): QuoteProfileName => state.project.profile;
export const getQuoteProfile = (state: RootState): QuoteProfile =>
  state.project.quote.profiles[state.project.profile];
export const getCompleteAddress = (state: RootState): string =>
  state.project.quote.mapboxAddress.complete;
export const getQuoteStatus = (state: RootState): string => state.project.quote.status;

/** Model selectors */
export const getModel = (state: RootState): OverallConsumptionModel => state.project.model;
export const getMaxPossibleAutarky = (state: RootState): number =>
  state.project.model.percentages.maxPossibleAutarky;

/** Energy related */
export const getEnergy = (state: RootState): Energy => state.project.energy;
export const getEnergyProductionParams = (state: RootState): EnergyProductionParams =>
  state.project.energyProductionParams;

/** Component selectors */
export const getComponents = (state: RootState): ProjectComponents => state.project.components;
export const getWallbox = (state: RootState): FilterableBaseComponent =>
  state.project.components.wallbox;
export const getSolarPanel = (state: RootState): FilterableBaseComponent =>
  state.project.components.solarPanel;
export const getWaterHeating = (state: RootState): boolean => state.project.components.waterHeating;
export const getHeatpump = (state: RootState): HeatpumpComponent =>
  state.project.components.heatpump;
export const getInverter = (state: RootState): FilterableBaseComponent =>
  state.project.components.inverter;
export const getBattery = (state: RootState): FilterableBaseComponent =>
  state.project.components.battery;
export const getCars = (state: RootState): CarComponent[] => state.project.components.cars;
export const getAdditionalSoftware = (state: RootState): FilterableBaseComponent =>
  state.project.components.additionalSoftware;
export const getAdditionalHardware = (state: RootState): FilterableBaseComponent =>
  state.project.components.additionalHardware;
export const getProductEMS = (state: RootState): FilterableBaseComponent =>
  state.project.components.EMS;



/** Financial details */
export const getFinancialDetails = (state: RootState): ProjectFinancialDetails =>
  state.project.financialDetails;

/* get images */
export const getImages = (state: RootState): ProjectImages => state.project.images;

/** Household */
export const getHousehold = (state: RootState): HouseholdEnergyParams => state.project.household;

/** Web constants */
export const getConstants = (state: RootState): ProjectConstants => state.project.constants;
export const getWebConstants = (state: RootState): ProjectWebConstants =>
  state.project.webConstants;

/** Project images */
export const getHouseTop = (state: RootState): HouseTopImages => state.project.images.houseTop;
export const getHouseBottom = (state: RootState): BaseHouseImages =>
  state.project.images.houseBottom;
export const getElectricMeter = (state: RootState): ElectricMeterImages =>
  state.project.images.electricMeter;
export const getDroneData = (state: RootState): ThreeDModels => state.project.images.ThreeDModels;

export const getVendor = (state: RootState): ProjectVendor => state.project.vendor;
export const getSelectedInstaller = (state: RootState): InstallerOfferDB[] =>
  state.project.selectedInstallers;
export const getinstallerDetails = (state: RootState): InstallerDetails[] =>
  state.project.installerDetails;

export const getSelectedFiles = (state: RootState): any =>
  state.project.images.ThreeDModels.selectedFiles;

export const getBakeModelImages = (state: RootState): string[] => state.project.bakeModel.artifacts;

export const getMaxWidth3dViewer = (state: RootState): boolean => state.project.maxWidth3DViewer;
export const getMaxWidth3DViewerWithImages = (state: RootState) : boolean => state.project.maxWidth3DViewerWithImages

export const getThreeDModels = (state: RootState): threeDModels => state.project.threeDModels;
export const getAdminGeneratedBakeModelUrls = (state: RootState): string[] | undefined => state.project.bakeModelAdminGenerated?.id?.assetUrls;
export const getAdminGeneratedImageurls = (state: RootState): string[] | undefined => state.project.bakeModelAdminGenerated?.id?.imageUrls;
export const getUserInfo = (state: RootState): any => state.project?.userInfo;