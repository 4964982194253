import { CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Provider, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { useURLData } from "src/hooks";

import { downloadProjectPdf, getProject } from "src/api/backend-endpoints";
import { ApiAxiosClient } from "src/axios";
import { InstallerDB } from "src/db-types";
import { getCalculateDistance } from "src/helpers/product/getCalculateDistance";
import { useAddInstallerAPI } from "src/hooks/apis";
import useDownloadPdf from "src/pages/ApplicationPage/hooks/useDownloadPdf";
import { AUTH_MODES, AuthPopupProps } from "src/popups";
import { InvestMentChart } from "src/popups/ChartsTabs/Investment/InvestMentChart/InvestMentChart";
import { MonthlyBalanceChart } from "src/popups/ChartsTabs/MonthlyBalance/MonthlyBalanceChart/MonthlyBalanceChart";
import { PrimaryButton, SecondaryButton } from "src/shared/StyledComponents";
import { saveBlobToFile } from "src/utils";

import { useIsInstallerSelected } from "../../../../hooks/useIsInstallerSelected";

import * as S from "./InstallerDetailsCardStyles";

import { AppActions, PopupActions } from "src/redux";
import { ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { InstallerPopupData } from "src/redux/popups/reducer/reducer";
import { updateProject } from "src/redux/project/action/action.creators";
import { PopupSelectors, ProjectSelectors, UserSelectors } from "src/redux/selectors";
import { getSolarAPIData } from "src/redux/solarAPI/selector";
import store from "src/redux/store";


interface IInstallerDetailsCard {
  images: string[] | undefined;
  pid: string;
}

const InstallerDetailsCard = ({ images, pid }: IInstallerDetailsCard) => {
  const { t } = useTranslation();
  const chartTimer = useRef<any>(null);
  const [query, setSearchParams] = useSearchParams();
  const [renderChartsForPDF, setRenderChartsForPDF] = useState(false);
  const [investmentChartSS, setInvestmentChartSS] = useState<any>(null);
  const [monthlyChartSS, setMonthlyChartSS] = useState<any>(null);
  const [supplyChartSS, setSupplyChartSS] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [electrictySupplyChart, setElectrictySupplyChart] = useState<any>(null);
  const mapboxAddress = useSelector(ProjectSelectors.getAddress);
  const houseTopImages = useSelector(ProjectSelectors.getHouseTop).imageURLs;
  const houseBottomImages = useSelector(ProjectSelectors.getElectricMeter).imageURLs;
  const { isSelected: isInstallerSelected } = useIsInstallerSelected();
  const currentUser = useSelector(UserSelectors.getUser);
  const installerDetails = useSelector(UserSelectors?.getInstaller);
  const solarAPIData = useSelector(getSolarAPIData);
  const { isOnDefault } = useURLData();
  const user = useSelector(UserSelectors.getUser);
  const project = useSelector(ProjectSelectors.getProject);
  const pdfData = useDownloadPdf();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const installerPartnerSlug = query.get("partner");
  const {
    data: { noOfTimesShown: confirmationPopupCount },
  } = useSelector(PopupSelectors.getInstallerRequestedPopup);

  const {
    error: addInstallerError,
    fetchData: addInstallerApi,
    response: addInstallerResponse,
  } = useAddInstallerAPI();

  const haveImages = houseTopImages.length > 0 || houseBottomImages.length > 0;


  const pdfBtnClickHandler = useCallback(async() => {
    setRenderChartsForPDF(true);
    const pdfName = `Solarprojekt-SolarHub-${mapboxAddress?.address} ${mapboxAddress?.postcode}-${
      project?.shortId ? project?.shortId : ""
    }`
    try {
    const response = await ApiAxiosClient.post(`${downloadProjectPdf.url}/?templateId=${process.env.REACT_APP_TEMPLATE_ID}&fileName=${pdfName}`, pdfData) 
    const url = response.data.url;
    const a = document.createElement("a");
    a.href = url;
    a.download = pdfName;
    a.click();
    setRenderChartsForPDF(false);
    dispatch(updateProject({ ...project, projectPdf: url }));
    } catch (error) {
      setRenderChartsForPDF(false);
    }
  }, [dispatch, mapboxAddress?.address, mapboxAddress?.postcode, pdfData, project]);

  const blobCallback = ({ blob, url, loading, error }: any): React.ReactNode => {
    if (url) {
      const pdfName = `Solarprojekt-SolarHub-${mapboxAddress?.address} ${
        mapboxAddress?.postcode
      }-${""}.pdf`;
      if (chartTimer.current) clearTimeout(chartTimer.current);
      chartTimer.current = setTimeout(() => {
        saveBlobToFile(url, pdfName);
        setRenderChartsForPDF(false);
        setAnchorEl(null);
      }, 5000);
    }
    return <div>There's something going on on the fly</div>;
  };

  const openAuthPopup = useCallback(() => {
    const data: AuthPopupProps = {
      authMode: AUTH_MODES.signup,
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.auth, data));
  }, [dispatch]);

  const distance = useMemo(() => {
    if (mapboxAddress?.geometry && installerDetails?.geometry) {
      return getCalculateDistance(mapboxAddress?.geometry, installerDetails?.geometry)?.toFixed();
    }
    return 0;
  }, [mapboxAddress, installerDetails]);

  const openAddressPopup = useCallback(() => {
    dispatch(PopupActions.openPopup(POPUP_KEYS.welcome_address));
  }, [dispatch]);

  const validateBeforeRequestingOffer = useCallback(() => {
    if (!currentUser) {
      openAuthPopup();
      return false;
    }

    if (isOnDefault) {
      openAddressPopup();
      return false;
    }
    return true;
  }, [currentUser, isOnDefault, openAddressPopup, openAuthPopup]);

  const showInstallerPopup = useCallback(() => {
    // TODO: Hook popup
    if (confirmationPopupCount >= 1) {
      toast.success(t("installer has been requested"));
      return;
    }

    const popupProps: InstallerPopupData = {
      noOfTimesShown: confirmationPopupCount + 1,
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.installer_confirmation, popupProps));
  }, [confirmationPopupCount, dispatch, t]);

  useEffect(() => {
    if (!addInstallerResponse) return;

    const {
      data: { data },
    } = addInstallerResponse;

    dispatch(ProjectActions.addSelectedInstaller(data));
    showInstallerPopup();
  }, [addInstallerResponse, dispatch, showInstallerPopup]);

  const requestInstaller = useCallback(
    async (installer: InstallerDB) => {
      const shouldRequest = validateBeforeRequestingOffer();
      if (!shouldRequest) return;

      if (isInstallerSelected(installer._id)) {
        return;
      }
      const state: any = store.getState();

      await addInstallerApi({
        ...BackendApis.addInstaller,
        data: {
          iid: installer._id,
          pid,
          partnerEmail: state?.user?.installer?.email,
        },
      });

      // dispatch(
      //   updateInstallerId({
      //     iid: installer._id,
      //   }),
      // );

      dispatch(
        AppActions.updateAppState({
          actions: {
            updateDeal: true,
          },
        }),
      );
    },
    [addInstallerApi, dispatch, isInstallerSelected, pid, validateBeforeRequestingOffer, user],
  );

  const handleUploadRedirect = () => {
    let newPath = `${window.location.pathname}?upload=true`;
    if (installerPartnerSlug) {
      newPath = `${window.location.pathname}?partner=${installerPartnerSlug}&upload=true`;
    }
    navigate(newPath);
    dispatch(AppActions.setCurrentStep(2));
  };

  const dataPolicyPopupAccept = useSelector(PopupSelectors.getDataPolicyPopupAccept);
  const [requestRealizationTime, setRequestRealizationTime] = useState(false);
  const handleRequestRealizationTime = useCallback(() => {
    if (isInstallerSelected(installerDetails?._id as string)) return;
    setRequestRealizationTime(true);
    dispatch(PopupActions.openDataPolicyPopup());
  }, [isInstallerSelected, installerDetails]);

  useEffect(() => {
    if (
      !dataPolicyPopupAccept.open &&
      dataPolicyPopupAccept.accept &&
      requestRealizationTime
    ) {
      setRequestRealizationTime(false);
      dispatch(PopupActions.closeDataPolicyPopup());
      requestInstaller(installerDetails as InstallerDB);
    } else if (
      !dataPolicyPopupAccept.open &&
      !dataPolicyPopupAccept.accept &&
      requestRealizationTime
    ) {
      setRequestRealizationTime(false);
    }
  }, [dataPolicyPopupAccept]);

  return (
    <S.ContainerWrapper>
      <S.InstallerDetailsCard>
        <S.Details>
          <S.Title>{t("Your Installation partner:")}</S.Title>
          <S.Logo src={installerDetails?.logoUrl[0]} className="logo_url" alt="" />
          {installerDetails?.telephone && (
            <S.ContactDetails>Tel. {installerDetails?.telephone}</S.ContactDetails>
          )}
          <S.ContactDetails>{installerDetails?.email}</S.ContactDetails>
        </S.Details>
        <S.AdressDetails>
          <div>
            {t("Distance to your house:")} {distance} km
          </div>
          <div>{installerDetails?.address}</div>
        </S.AdressDetails>
        {!solarAPIData && images?.[0] && (
          <S.ProjectImage src={images?.[0]} className="logo_url" alt="" />
        )}
        <S.Buttons>
          <S.BorderLessButton onClick={pdfBtnClickHandler} disabled={renderChartsForPDF}>
            {renderChartsForPDF && <CircularProgress size={20} color="success" />}
            <>
              {renderChartsForPDF && t("Saving Project as a PDF")}
              {!renderChartsForPDF && t("Download PDF Offer")}
            </>
          </S.BorderLessButton>

          {installerDetails?.isUploadImageRequired && !haveImages && (
            <SecondaryButton onClick={handleUploadRedirect}>
              {t("Upload image to request an installer")}
            </SecondaryButton>
          )}
        </S.Buttons>
      </S.InstallerDetailsCard>
      {installerDetails && (!installerDetails?.isUploadImageRequired || haveImages) && (
        <S.ReqButton onClick={handleRequestRealizationTime}>
          {isInstallerSelected(installerDetails?._id)
            ? t("waiting for confirmation")
            : t("Request realization time")}
        </S.ReqButton>
      )}
    </S.ContainerWrapper>
  );
};

export default InstallerDetailsCard;
