import { actionCreators, ProjectState } from "src/redux/project";

export const updateProject = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateProject>,
): ProjectState => {
  return {
    ...currentState,
    ...payload,
  };
};
