import { Slider } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { MasterInput } from "src/shared/components";
import { ParameterStyles } from "src/shared/StyledComponents";

import { CarConfiguration } from "src/redux/project";

interface KilometersTravelledLineProps {
  onValueChange: (data: Partial<CarConfiguration>) => void;
  kmTravelled: number;
}
const KilometersTravelledLine: React.FC<KilometersTravelledLineProps> = (
  props: KilometersTravelledLineProps,
) => {
  const { onValueChange, kmTravelled } = props;

  const { t } = useTranslation();

  const onChangeHandler = useCallback(
    (value: number) => {
      onValueChange({
        evKmsTravelled: value,
        // ev_yearly_consumption: (value * consumption_per_100km_value) / 100,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line hasSlider className="evKm" isPadding>
      <ParameterStyles.Label hasSlider>{t("EV KILOMETERS TRAVELLED")}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider step={500} onChange={onChangeHandler} value={kmTravelled} max={50000} />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <MasterInput value={kmTravelled} onChange={onChangeHandler} label="Km" />
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default KilometersTravelledLine;
