import { Checkbox } from "antd";
import { t } from "i18next";
import { cloneDeep } from "lodash";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/hooks";

import { filterInStock, sortProductsByMaxCapacity } from "src/helpers";
import { HorizontalCloseButton } from "src/shared/components";
import { getNumber } from "src/utils";

import {
  BlockingTimeLine,
  BuildingStandardLine,
  ElectricityConsumptionLine,
  EnergyCertificateLine,
  FuturePriceIncreaseForOilGas,
  Heading,
  HeatConsumptionLine,
  HeatDistributionLine,
  HeatLoadLine,
  HeatpumpDimensionLine,
  OilGasCostLine,
  SelectHeatpumpButton,
  SolarPowerLine,
  TotalAreaToBeHeatedLine,
} from "./components";
import JazLine from "./components/JazLine/JazLine";
import * as S from "./HeatpumpConfigurationBox.styles";
import { useHeatpumpCalculation } from "./hooks";

import { ProjectActions } from "src/redux/actionCreators";
import { HeatpumpConfiguration } from "src/redux/project";
import { initialState as ProjectInitialState } from "src/redux/project/initialState";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

interface HeatpumpConfigurationBoxProps {
  onClose?: () => void;
  isEdit?: boolean;
  isAdd?: boolean;
}
const HeatpumpConfigurationBox: React.FC<HeatpumpConfigurationBoxProps> = (
  props: HeatpumpConfigurationBoxProps,
) => {
  const { onClose, isAdd } = props;

  const dispatch = useAppDispatch();

  const heatpumpProducts = useSelector(ProductSelectors.getHeatpumps);
  const { configuration, isExcludeCost } = useSelector(ProjectSelectors.getHeatpump);

  useHeatpumpCalculation();

  const updateHeatpumpConfig = useCallback(
    (data: Partial<HeatpumpConfiguration>) => {
      dispatch(
        ProjectActions.updateHeatpump({
          configuration: {
            ...data,
          },
        }),
      );
    },
    [dispatch],
  );

  const selectButtonClickHandler = useCallback(() => {
    const inStockProducts = filterInStock(heatpumpProducts);
    const sortedHeatpumps = sortProductsByMaxCapacity(inStockProducts);

    const requiredCapacity = configuration.heatingCapacity * 1000;
    const selectedInd = sortedHeatpumps.findIndex(
      (h) => getNumber(h.applicableMaxCapacity) >= requiredCapacity,
    );
    if (selectedInd === -1) {
      console.error("Could not find heat pump with capacity");
      return;
    }

    const fittingHeapump = sortedHeatpumps[selectedInd];
    dispatch(
      ProjectActions.updateHeatpump({
        item: fittingHeapump as Record<string, any>,
        quantity: 1,
      }),
    );

    if (onClose) onClose();
  }, [configuration.heatingCapacity, dispatch, heatpumpProducts, onClose]);

  const resetHeatpump = useCallback(() => {
    dispatch(
      ProjectActions.updateHeatpump({
        ...cloneDeep(ProjectInitialState.components.heatpump),
      }),
    );
  }, [dispatch]);

  const closeButtonCallback = useCallback(
    (event: React.MouseEvent) => {
      if (isAdd) {
        resetHeatpump();
      }

      if (onClose) onClose();
    },
    [isAdd, onClose, resetHeatpump],
  );

  return (
    <S.MainContainer>
      <S.SubContainer>
        <div className="same_part">
          <Heading />
          <HorizontalCloseButton onClick={closeButtonCallback} />
        </div>
        <BuildingStandardLine onValueChange={updateHeatpumpConfig} />
        <HeatDistributionLine onValueChange={updateHeatpumpConfig} />
        <TotalAreaToBeHeatedLine onValueChange={updateHeatpumpConfig} />
        <EnergyCertificateLine onValueChange={updateHeatpumpConfig} />
        <HeatConsumptionLine onValueChange={updateHeatpumpConfig} />
        <JazLine onValueChange={updateHeatpumpConfig} />
        <ElectricityConsumptionLine onValueChange={updateHeatpumpConfig} />
        <OilGasCostLine onValueChange={updateHeatpumpConfig} />
        <FuturePriceIncreaseForOilGas onValueChange={updateHeatpumpConfig} />
        <SolarPowerLine />
        <HeatLoadLine onValueChange={updateHeatpumpConfig} />
        <BlockingTimeLine onValueChange={updateHeatpumpConfig} />
        <HeatpumpDimensionLine onValueChange={updateHeatpumpConfig} />
        <div className="heatpump-cost">
          <Checkbox
            onChange={(e) =>
              dispatch(
                ProjectActions.updateHeatpump({
                  isExcludeCost: e.target.checked,
                }),
              )
            }
            checked={isExcludeCost}
          />
          <p className="lable">{t("exclude heat pump cost")}</p>
        </div>
      </S.SubContainer>
      {isAdd && <SelectHeatpumpButton onClick={selectButtonClickHandler} />}
    </S.MainContainer>
  );
};

export default HeatpumpConfigurationBox;
