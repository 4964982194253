export const DATASET_CONSTRAINTS = Object.freeze({
  householdConsumption: {
    min: 2000,
    max: 6000,
  },
  noOfPeople: {
    min: 2,
    max: 5,
  },
  heatpumpConsumption: {
    min: 0,
    max: 9000,
  },
  evDistance: {
    min: 0,
    max: 30000,
  },
  pv: {
    min: 5,
    max: 15,
  },
  battery: {
    min: 4,
    max: 16,
  },
  haveHeatpump: {
    min: 0,
    max: 1,
  },
  haveHeatingRod: {
    min: 0,
    max: 1,
  },
  heatShareKessel: {
    min: 0,
    max: 12248,
  },
  heatShareHeatpump: {
    min: 0,
    max: 13015,
  },
  householdThroughPV: {
    min: 639,
    max: 2176,
  },
  evThroughPV: {
    min: 0,
    max: 507,
  },
  heatpumpThroughPV: {
    min: 0,
    max: 2407,
  },
  heatPumpRequirement: {
    min: 0,
    max: 4773,
  },
  heatingRodRequirement: {
    min: 0,
    max: 2943,
  },
});
