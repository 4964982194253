import { CircularProgress, TextField } from "@mui/material";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { useNaivgateAbsolute } from "src/hooks";

import { convertUserFromDB } from "src/db-converters";
import { openCorrPopupBasedOnPartnerStatus } from "src/helpers/partner-cities/partnerCityHelper";
import { useRegisterAPI, useRegisterIntoWebinar } from "src/hooks/apis";
import { Mixpanel } from "src/mix-panel/mixpanel";
import * as trackingEvents from "src/mix-panel/trackingEvents";
import { AddressSelector } from "src/shared/components";
import { getErrorMessage, transformForURL } from "src/utils";

import AuthButtons from "../modeButtons/authButtons";
import { AUTH_MODES, AuthPopupProps } from "../types";

import cssClasses from "./login.module.css";

import { AppActions, UserActions } from "src/redux/actionCreators";
import { getPartnerCityData } from "src/redux/application/selector";
import {
  POPUP_KEYS,
  actionCreators as PopupActions,
  selectors as PopupSelectors,
} from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";
import { MapboxAddress, selectors as ProjectSelectors } from "src/redux/project";
import { getProject, getQuote } from "src/redux/project/selectors";
import { UserAuthStatus, selectors as UserSelector } from "src/redux/user";
import { getInstaller } from "src/redux/user/selectors";
import { getSelectedWebinarId, getSelectedWebinarIdHybrid } from "src/redux/webinar/selector";

interface SignupForm {
  firstname: string;
  lastname: string;
  phoneNumber: string;
  address: string;
  email: string;
  password: string;
}

const defaultSignupForm: SignupForm = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  address: "",
  phoneNumber: "",
};

const SignUpPopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNaivgateAbsolute();
  const selectedWebinarId: number | null = useSelector(getSelectedWebinarId);
  const selectedWebinarisHybrid = useSelector(getSelectedWebinarIdHybrid);
  const installerParter = useSelector(getInstaller)
  const getPartnerCity = useSelector(getPartnerCityData)
  const project = useSelector(getProject);

  const { fetchData, error, loading, response } = useRegisterAPI();
  const {
    fetchData: registerIntoWebinar,
    loading: webinarLoading,
    response: webinarResponse,
    error: webinarError,
  } = useRegisterIntoWebinar();
  // const { save } = useProjectTools();

  // const project = useSelector(getProjectDetails);
  const popupProps = useSelector(PopupSelectors.getAuthPopup);

  const [showAddressField, setShowAddressField] = useState(true);
  const [signupForm, setSignupForm] = useState<SignupForm>(cloneDeep(defaultSignupForm));

  const isAuthLoading = useSelector(UserSelector.getIsAuthLoading);
  const quote = useSelector(getQuote);

  const validateForm = (): { isValid: boolean; messages: string[] } => {
    const status = {
      isValid: true,
      messages: [] as string[],
    };

    if (signupForm.email === "") {
      status.isValid = false;
      status.messages.push("Please provide your email address to continue the process");
    }

    if (signupForm.password === "") {
      status.isValid = false;
      status.messages.push("Please provide your password to continue the process");
    }

    if (signupForm.phoneNumber === "") {
      status.isValid = false;
      status.messages.push("Please provide your phone number to continue the process");
    }

    if (signupForm.firstname === "") {
      status.isValid = false;
      status.messages.push("Please provide your first name to continue the process");
    }

    if (signupForm.lastname === "") {
      status.isValid = false;
      status.messages.push("Please provide your last name to continue the process");
    }

    return status;
  };

  const isWebinarMode = useMemo(
    () =>
      popupProps.data.authMode === AUTH_MODES.webinar_signup ||
      popupProps.data.authMode === AUTH_MODES.webinar_login,
    [popupProps],
  );

  const registerBtnClickHandler = (): void => {
    const { isValid, messages } = validateForm();
    if (!isValid) {
      toast.warn(messages.map((m) => t(m)).join("\n"), { style: { whiteSpace: "pre-line" } });
      return;
    }

    if (isWebinarMode) {
      if (!selectedWebinarId) return;

      void registerIntoWebinar({
        ...BackendApis.signupInWebinar,
        data: {
          address: signupForm.address,
          email: signupForm.email,
          firstName: signupForm.firstname,
          lastName: signupForm.lastname,
          mobile: signupForm.phoneNumber,
          password: signupForm.password,
          webinarId: selectedWebinarId,
          isHybrid: selectedWebinarisHybrid,
          white_label_lead_tag: !!installerParter,
          gemeindeschlussel: project.quote.Gemeindeschluessel8 || getPartnerCity.Gemeindeschluessel8
        },
      });

      return;
    }

    Mixpanel.track(trackingEvents.USER_REGISTER, {});
    void fetchData({
      ...BackendApis.signup,
      data: {
        address: signupForm.address,
        email: signupForm.email,
        firstName: signupForm.firstname,
        lastName: signupForm.lastname,
        mobile: signupForm.phoneNumber,
        password: signupForm.password,
        white_label_lead_tag: !!installerParter,
        gemeindeschlussel: project.quote.Gemeindeschluessel8 || getPartnerCity.Gemeindeschluessel8
      },
    });
    if(quote.mapboxAddress.complete !== "Ringstraße 7, 79252 Stegen, Germany") {
      openCorrPopupBasedOnPartnerStatus();
    }
  };

  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const completeAddr = useSelector(ProjectSelectors.getAddress).complete;

  const updateForm = (target: keyof SignupForm, value: string): void => {
    setSignupForm((prev: SignupForm) => {
      const newForm: SignupForm = { ...prev };
      newForm[target] = value;
      return newForm;
    });
  };

  useEffect(() => {
    const addrAlreadySelected = quoteId !== "";
    if (addrAlreadySelected) {
      updateForm("address", completeAddr);
      setShowAddressField(false);
    }
  }, [quoteId, completeAddr]);

  useEffect(() => {
    if (!error) return;

    const message = getErrorMessage(error);
    const id = toast.error(t(message));
  }, [error, t]);

  useEffect(() => {
    if (!response) return;

    const {
      data: {
        data: { token, user },
      },
    } = response;
    const userState = convertUserFromDB(user);

    localStorage.setItem("token", token);
    dispatch(UserActions.setUser(userState));
    dispatch(UserActions.setUserAuthStatus(UserAuthStatus.loggedIn));

    const addr = transformForURL(signupForm.address);
    navigate(`application/default-${addr}`);

    toast.success(t("Sign up successful"));
    dispatch(PopupActions.closePopup(POPUP_KEYS.auth));
    dispatch(
      AppActions.updateAppState({
        saveProjectRemaining: true,
      }),
    );
  }, [dispatch, navigate, response, signupForm.address, t]);

  // UseEffect for webinar register
  useEffect(() => {
    if (!webinarError) return;

    const message = getErrorMessage(webinarError);
    const id = toast.error(t(message));

    dispatch(UserActions.setUserAuthStatus(UserAuthStatus.loggedOut));

    return () => {
      toast.dismiss(id);
    };
  }, [webinarError, t, dispatch]);

  useEffect(() => {
    if (!webinarResponse) return;

    const {
      data: {
        data: { user },
      },
    } = webinarResponse;
    const userState = convertUserFromDB(user.user);

    localStorage.setItem("token", user.token);
    dispatch(UserActions.setUser(userState));

    const addr = transformForURL(signupForm.address);
    navigate(`application/default-${addr}`);

    toast.success(t("Sign up successful"));
    dispatch(PopupActions.closePopup(POPUP_KEYS.auth));
    const data = {
      title:
        "Please feel free to choose from our upcoming Events Your perfect start to your new energy system",
      heading: "SolarHub Solarwebinar",
      button_text: "JOIN",
      extraContent:
        "To optimize your sustainable energy setup some information from your side is helpful",
    };
    dispatch(openPopup(POPUP_KEYS.webinar, data));
    dispatch(
      AppActions.updateAppState({
        saveProjectRemaining: true,
      }),
    );
  }, [dispatch, navigate, webinarResponse, signupForm.address, t]);

  useEffect(() => {
    dispatch(UserActions.setIsAuthLoading(loading || webinarLoading));
  }, [dispatch, loading, webinarLoading]);

  const goToLogin = useCallback(() => {
    let nextMode = AUTH_MODES.login;

    const isWebinarSignup = popupProps.data.authMode === AUTH_MODES.webinar_signup;
    if (isWebinarSignup) nextMode = AUTH_MODES.webinar_login;

    const props: AuthPopupProps = {
      authMode: nextMode,
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.auth, props));
  }, [dispatch, popupProps.data.authMode]);

  const addressUpdateHandler = useCallback((addr: MapboxAddress): void => {
    updateForm("address", addr.complete);
  }, []);

  return (
    <>
      <div className={cssClasses.loginContainer} id="signup-form">
        <TextField
          fullWidth
          id="first-name-form-field"
          label={t("Enter first name")}
          variant="outlined"
          onChange={(e) => {
            updateForm("firstname", e.target.value);
          }}
        />

        <TextField
          fullWidth
          id="last-name-form-field"
          label={t("Enter last name")}
          variant="outlined"
          onChange={(e) => {
            updateForm("lastname", e.target.value);
          }}
        />

        <TextField
          fullWidth
          id="email-form-field"
          label={t("Enter E-Mail")}
          variant="outlined"
          onChange={(e) => {
            updateForm("email", e.target.value);
          }}
        />

        <TextField
          fullWidth
          id="password-form-field"
          label={t("Password")}
          type="password"
          variant="outlined"
          onChange={(e) => {
            updateForm("password", e.target.value);
          }}
        />

        <TextField
          fullWidth
          id="phone-number-field"
          label={t("Enter phone number")}
          variant="outlined"
          onChange={(e) => {
            updateForm("phoneNumber", e.target.value);
          }}
        />

        {showAddressField && (
          <AddressSelector onAddressSelect={addressUpdateHandler}></AddressSelector>
        )}
      </div>

      <button
        className={cssClasses.loginButton}
        onClick={registerBtnClickHandler}
        disabled={isAuthLoading}
        id="register"
      >
        {t("REGISTER")}
        {isAuthLoading && <CircularProgress size={20} className="circular-progress-custom" />}
      </button>

      <AuthButtons />

      <div className={cssClasses.formFooter}>
        <div className={cssClasses.blueLinkText} onClick={goToLogin}>
          {t("ALREADY HAVE AN ACCOUNT")}
        </div>
        <div className={cssClasses.yellowLinkText} onClick={goToLogin}>
          {t("LOGIN NOW")}
        </div>
      </div>

      <div className={cssClasses.formFooter} style={{ marginTop: "5px" }}>
        <div className={cssClasses.blueLinkText}>{t("ANY QUESTION")}</div>
        <a
          className={cssClasses.yellowLinkText2}
          style={{
            textDecoration: "none",
          }}
          href="mailto:info@solarhub24.de"
        >
          {t("CONTACT US NOW")}
        </a>
      </div>
    </>
  );
};

export default SignUpPopup;
