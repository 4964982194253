import QuickPinchZoom from "react-quick-pinch-zoom";
import styled from "styled-components";

export const StyledQuickPinchZoom = styled(QuickPinchZoom)`
  height: 100%;
  img {
    height: 100%;
  }
  .slick-slide img {
    height: 100% !important;
  }
  .kvfysmfp {
    height: 100% !important;
  }
`;

export const StyledSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute !important;
  top: 10px !important;
  left: 10px;
  z-index: 999;
  height: 30px !important;
  font-weight: bolder;
`;

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  .image-container {
    position: relative;
  }

  .image-container img {
    filter: blur(20px); 
    transition: filter 0.3s ease-in-out;
  }

  .image-container.loaded img {
    filter: blur(0); 
  }
`;
interface Container {
  maxWidth3DViewerWithImages: boolean;
}


export const TopPreviewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 500px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const ObjPreview = styled.div<Container>`
  width: ${(props: any) => (props.maxWidth3DViewerWithImages ? "100%" : "50%")};
  height: ${(props: any) => (props.maxWidth3DViewerWithImages ? "100%" : "400px")};
  position: relative;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const StyledLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`;
