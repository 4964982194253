import { cloneDeep } from "lodash";
import { actionHandlers, actionNames, FLAG_KEYS, FlagState } from "src/redux/flags";

const INITIAL_STATE: FlagState = {
  MAP_SCREENSHOT_MODE: {
    open: false,
    data: null,
  },
};

const flagReducer = (state = INITIAL_STATE, action: any): typeof INITIAL_STATE => {
  switch (action.type) {
    case actionNames.SET_FLAG:
      return actionHandlers.setFlag(state, action);
    case "RESET_REDUX":
      return cloneDeep(INITIAL_STATE);
    default:
      return state;
  }
};

export default flagReducer;
