import { useEffect, useRef } from "react";

import { ThreeDLoader } from "./ThreeDLoader";

const ObjViewer = () => {
  const threejsEl: any = useRef(null);
  const element: any = useRef(null);
  const initialized = useRef(false);

  useEffect(() => {
    const [, , bucket, ...objectKeyParts] =
      "s3://prod-photogrammetry-api-outputsbucket107f8b6f-dpavlhd8ojqg/bakedModels/65eef1e58725214d33f9d84b/odm_texturing/small_model/odm_textured_model_geo.obj".split(
        "/",
      );
    const objectKey = objectKeyParts.join("/");
    const httpsObj = `https://${bucket.replace(
      ".s3.",
      "",
    )}.s3.eu-central-1.amazonaws.com/${objectKey}`;
    const [, , bucket1, ...objectKeyParts1] =
      "s3://prod-photogrammetry-api-outputsbucket107f8b6f-dpavlhd8ojqg/bakedModels/65eef1e58725214d33f9d84b/odm_texturing/small_model/odm_textured_model_geo.obj.mtl".split(
        "/",
      );
    const objectKey1 = objectKeyParts1.join("/");
    const httpsObj1 = `https://${bucket1.replace(
      ".s3.",
      "",
    )}.s3.eu-central-1.amazonaws.com/${objectKey1}`;
    const mtlUrl = httpsObj1;
    const objUrl = httpsObj;

    console.log(mtlUrl, objUrl, "objUrl");

    if (!initialized.current) {
      ThreeDLoader(threejsEl.current, objUrl, mtlUrl, element.current);
    }
    initialized.current = false;
  }, []);
  return (
    <div className="content" ref={element}>
      <div
        ref={threejsEl}
        className="threeD_loader_component"
        style={{ position: "relative" }}
      ></div>
    </div>
  );
};

export default ObjViewer;
