import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: min(100%, 937px);
  max-width: 90%;
  background: white;
  padding: 3rem 1rem;
  margin: auto;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    padding: 0rem 0rem;
    height: 597px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
  .ant-picker-input input::placeholder {
    @media (max-width: 500px) {
      font-size: 15px !important;
    }
    font-size: 25px !important;
  }
  .ant-select-selection__placeholder {
    color: blue;
  }
  .ant-select-selection-placeholder {
    font-size: 17px !important;
    font-weight: 600;
    color: #9397a4 !important;
  }
  img {
    padding: 1rem;
  }
  .logo {
    display: flex;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    color: #2d4764;
    margin-top: 54px;
    @media (max-width: 500px) {
      margin-top: 26px;
    }
  }
  .hybrid-selection {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
    padding-top: 20px;

    p {
      color: #2d4764;
      font-size: 16px;
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      line-height: 34px;
    }
  }
  .title_2 {
    font-size: 18px;
    font-weight: 700;
    color: #2d4764;
    padding-bottom: 15px;
  }
  .small_heading {
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    color: #2d4764;
    margin-bottom: 30px;
  }
  .small_heading_2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    margin-top: 24px;
    color: #2d4764;
    width: 100%;
  }
  .webinar-time {
    font-weight: 700;
    font-size: 18px;
    color: #2d4764;
  }
  .timer-countdown {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 17px;
    margin-bottom: 17px;
    div {
      text-align: center;
      color: #2d4764;
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-direction: column;
    }
  }
  .webinar-text {
    text-decoration: underline;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    color: #2d4764;
    cursor: pointer;
  }
  .google-login {
    font-weight: 600;
    box-shadow: none;
    border: 1px solid black;
    background: white;
    color: #2d3748;
    margin-top: 32px;
  }
  .show_date {
    height: 40px;
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    padding-inline: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    color: #2d4764;
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }
  .register-button {
    width: min(320px, 100%);
    text-align: center;
    color: #2d4764;
    font-size: 16px;
    font-weight: 700;
    background: #ffd75d;
    border-radius: 20px;
    padding: 10px;
    margin-top: 17px;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 500px) {
      margin-top: 50px;
    }
  }

  .webinar-button {
    text-align: center;
    color: #2d4764;
    font-size: 16px;
    font-weight: 700;
    background: #ffd75d;
    padding: 10px;
    margin-top: 32px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding-inline: 29px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 9px;
    @media (max-width: 500px) {
      padding-inline: 20px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .login {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 88px;
    margin-top: 12px;
    .yellow {
      color: #ffbf1b;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    background: #f9f9f9;
    border: 1px solid #e2e2e2;
    border-radius: 150px;
  }

  .ant-select-selection-item {
    font-family: Nunito;
    font-weight: 700;
    color: var(--blue);
    font-size: 18px;
    @media (max-width: 500px) {
      font-size: 15px;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 4px 11px;
  }
  .ant-select-selector {
  }
  .ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 43%;
    right: 10px;
    display: flex;
    align-items: center;
    margin-top: -3px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
    border-radius: 50%;
    padding: 5px;
    padding-right: 0px;
    :hover {
      background: #ececec;
    }
  }

  .ant-select-selector {
    border: 1px solid #e2e2e2 !important;
    box-sizing: border-box !important;
    border-radius: 20px !important;
  }
`;
