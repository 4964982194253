import axios from "axios";
import { t } from "i18next";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { hubSpotOwnerDetails } from "src/data/hubspot/hubspotOwnerDetails";

import { ProjectResponseDB } from "src/db-types/project";

import { AppActions } from "src/redux";
import { getPartnerCityData } from "src/redux/application/selector";
import { AllProjectsSelectors, AppSelectors, ProjectSelectors } from "src/redux/selectors";
import { getInstaller, getUser } from "src/redux/user/selectors";

const hubspotAxios = axios.create({
  baseURL: "https://backend.solarhub24.de/api/hubspotv4api",
  timeout: 10000,
  headers: { "X-Custom-Header": "foobar" },
});

export const useHubspotUpdate = () => {
  const dispatch = useDispatch();

  const allProjects = useSelector(AllProjectsSelectors.getAllProjects);
  const installerParter = useSelector(getInstaller)
  const currentProject = useSelector(ProjectSelectors.getProject);
  const getPartnerCity = useSelector(getPartnerCityData)
  const {
    actions: { updateDeal },
  } = useSelector(AppSelectors.getState);
  const user = useSelector(getUser);

   const getNextDayDate = () => {
    let afterOneDay = new Date().setDate(new Date().getDate() + 1);
    const utcDate = new Date(afterOneDay).toISOString();
    return utcDate;
  };

  const associateTaskWithContact = async (
    contactId: string,
    taskId: string
  ) => {
    let sendData = [
      {
        associationCategory: "HUBSPOT_DEFINED",
        associationTypeId: 3
      }
    ];
    try {
      const response: any = await hubspotAxios.put(
        `/tasks/${taskId}/associations/default/contacts/${contactId}`,
        sendData
      );
      if (response) {
        return response?.data;
      }
    } catch (err) {
      console.log("hubspot error", err);
    }
  };

  const associateTaskWithDeal = async (dealId: string, taskId: string) => {
    let sendData = [
      {
        associationCategory: "HUBSPOT_DEFINED",
        associationTypeId: 3
      }
    ];
    try {
      const response: any = await hubspotAxios.put(
        `/deal/${dealId}/associations/default/tasks/${taskId}`,
        sendData
      );
      if (response) {
        return response?.data;
      }
    } catch (err) {
      console.log("hubspot error", err);
    }
  };
 

  const createTask = async () => {
    const taskDetails = {
      hs_timestamp: getNextDayDate(), 
      hs_task_body: "PV-Planning requested",
      hubspot_owner_id: hubSpotOwnerDetails[1].id,
      hs_task_subject: `PV-Planning 3d model request by ${user?.firstName} ${user?.lastName}`,
      hs_task_status: "NOT_STARTED",
      hs_task_type: "TODO",
      hs_task_priority: "MEDIUM"
    };
  
    try {
      const response: any = await hubspotAxios.post("tasks", {
        properties: taskDetails
      });
      if (response) {
        toast.success(t("PV-Planning sucessfully requested"))
        if(user?.hubSpotContactId) {
          associateTaskWithContact(user?.hubSpotContactId, response?.data?.id);
          if(currentProject.deal?.dealId) {
            associateTaskWithDeal( currentProject.deal?.dealId, response?.data?.id)
          }
        }
      }
    } catch (err) {
      console.log("hubspot error", err);
    }
  };

  const hasAnotherProjectImages = useCallback(
    (allProjects: ProjectResponseDB[], currentProjectId: string, quoteId: string) => {
      let result = false;
      allProjects.forEach((proj) => {
        if (proj?.quoteID?._id !== quoteId) return;
        if (proj._id === currentProjectId) return;

        if (proj.images.electricMeter.imageURLs.length) result = true;
        if (proj.images.houseBottom.imageURLs.length) result = true;
        if (proj.images.houseTop.imageURLs.length) result = true;
      });
      return result;
    },
    [],
  );

  const hasAnotherProjectInstaller = useCallback(
    (allProjects: ProjectResponseDB[], currentProjectId: string, quoteId: string) => {
      let result = false;
      allProjects.forEach((proj) => {
        if (proj?.quoteID?._id !== quoteId) return;
        if (proj._id === currentProjectId) return;

        if (proj.selectedInstallers.length) result = true;
      });
      return result;
    },
    [],
  );

  useEffect(() => {
    if (!updateDeal) return;

    const deal = currentProject.deal;
    if (!deal) return;

    const { dealId } = deal;
    if (dealId === "") return;

    const hasElectricImages = !!currentProject.images.electricMeter.imageURLs.length;
    const hasHouseBottomImages = !!currentProject.images.houseBottom.imageURLs.length;
    const hasHouseTopImages = !!currentProject.images.houseTop.imageURLs.length;
    const currentProjectHasImage = hasElectricImages || hasHouseBottomImages || hasHouseTopImages;

    const currentProjectHasInstaller = !!currentProject.selectedInstallers.length;

    let hasImages: string | boolean =
      currentProjectHasImage ||
      hasAnotherProjectImages(allProjects, currentProject.id, currentProject.quoteId);
    let hasInstaller: string | boolean =
      currentProjectHasInstaller ||
      hasAnotherProjectInstaller(allProjects, currentProject.id, currentProject.quoteId);

    hasImages = hasImages ? "Ja" : "Nein";
    hasInstaller = hasInstaller ? "Ja" : "Nein";

    const cost = currentProject.financialDetails.costs.appliedProjectCost;
    const urlHost = location.host.includes("localhost")
      ? "solarhub-refactored.web.app"
      : location.host;
    const projectURL = `https://${urlHost}/application/saved-${currentProject.shortId}/${currentProject.version}?admin=true`;
    const savedprojectUrl = `https://${urlHost}/application/saved-${currentProject.shortId}/${currentProject.version}`;

    const updatedDetails = {
      project_url: projectURL,
      pid: currentProject.id,
      amount: cost,
      bilder_hochgeladen: hasImages,
      installateur_angefragt: hasInstaller,
      saved_project_url: savedprojectUrl,
      pipeline: "default",
      white_label_lead_tag: installerParter ? true : false,
      gemeindeschlussel: currentProject.quote.Gemeindeschluessel8 || getPartnerCity.Gemeindeschluessel8
    };

    try {
      hubspotAxios
        .patch(`deal/${dealId}`, {
          properties: updatedDetails,
        })
        .then((response) => {
          dispatch(
            AppActions.updateAppState({
              actions: {
                updateDeal: false,
              },
            }),
          );
        })
        .catch((error) => console.error(error));
    } catch (err) {
      console.log("hubspot error", err);
    }
  }, [
    allProjects,
    currentProject.deal,
    currentProject.financialDetails.costs.appliedProjectCost,
    currentProject.id,
    currentProject.images.electricMeter.imageURLs.length,
    currentProject.images.houseBottom.imageURLs.length,
    currentProject.images.houseTop.imageURLs.length,
    currentProject.quoteId,
    currentProject.selectedInstallers.length,
    currentProject.shortId,
    currentProject.version,
    dispatch,
    hasAnotherProjectImages,
    hasAnotherProjectInstaller,
    updateDeal,
    installerParter,
    getPartnerCity
  ]);

  return { createTask }
};
