import { useEffect, useState } from "react";

import { BackendApis, files } from "src/api";


import { useGetProductObjFiles } from "./apis";

import { ProductActions } from "src/redux/actionCreators";

import { useAppDispatch } from ".";

export const useGetAllProductObjFiles = (): void => {
  const dispatch = useAppDispatch();
  const [allData, setAllData] = useState<files[]>([]);

  const { fetchData, error, response } = useGetProductObjFiles();

  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);

  useEffect(() => {
    if (!response) return;

    setAllData(response.data.data);
  }, [response]);

  useEffect(() => {
    void fetchData({
      ...BackendApis.listOfProductObjFiles,
    });
  }, [fetchData]);

  useEffect(() => {
    dispatch(ProductActions.setProductObjFiles(allData));
  }, [allData, dispatch]);
};
