import { Tabs } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  ElectricityConsumptionChart,
  ElectricitySupplyChart,
} from "src/pages/ApplicationPage/components/ApplicationStep1/components/ImageConfiguration/ConsumptionCharts/components";

import { DonoughtChartsRow, MonthlyBalanceWrapper, ShadowBoxWrapper } from "./MonthlyBalanceStyle";
import { MonthlyEnergyChart } from "./MonthyEnergyChart/MonthyEnergyChart";

const MonthlyBalance: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      // Necessary as tooltip element is positioned by us
      // and when consumption tab is closed we want to make
      // sure that this element is gone as if this element was
      // there then it is positioned way bottom of the page and
      // creates extra whitespace at the bottom which we don't want
      document.getElementById("chartjs-tooltip")?.remove();
    };
  }, []);

  return (
    <MonthlyBalanceWrapper>
      <ShadowBoxWrapper>
        <div className="chartTitle">{t("Monthly energy balance")}</div>
        <Tabs centered size="small" className="ant-tab-custom" defaultActiveKey="1">
          <Tabs.TabPane tab={t("Simple")} key="1">
            <MonthlyEnergyChart />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("Detailed")} key="2">
            <MonthlyEnergyChart detailed />
          </Tabs.TabPane>
        </Tabs>
      </ShadowBoxWrapper>
      <DonoughtChartsRow>
        <ElectricitySupplyChart />
        <ElectricityConsumptionChart />
      </DonoughtChartsRow>
    </MonthlyBalanceWrapper>
  );
};

export default MonthlyBalance;
