import styled from "styled-components/macro";

export const SelectWrapper = styled("div")`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 24px !important;
  }
  .ant-select .ant-select-focused {
    border: none !important;
    outline: none;
  }
  .ant-select-selection-item {
    font-family: Nunito;
    font-weight: 700 !important;
    color: var(--blue);
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    background-color: #edf5ffaa !important;
  }
  .ant-select-arrow {
    /* color: #2d4764 !important; */
  }
`;
