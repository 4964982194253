import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-inline: auto;
  margin-top: 32px;
  width: min(95%, 900px);

  .MuiStepper-root {
    width: 100%;
    cursor: pointer;
  }

  .MuiStepLabel-label,
  .MuiStepLabel-iconContainer {
    font-family: var(--theme-font);
    color: #c0c0c0 !important;
    cursor: pointer;
  }

  .MuiStepLabel-label.Mui-active,
  .MuiStepLabel-iconContainer.Mui-active {
    color: var(--blue) !important;
    font-weight: bold;
  }

  .Mui-completed .MuiStepIcon-text,
  .MuiSvgIcon-root.Mui-active > .MuiStepIcon-text {
    fill: var(--blue) !important;
  }

  @media (max-width: 500px) {
    .MuiStepLabel-label {
      display: none;
    }
  }
`;
