import { actionCreators, ProjectState } from "src/redux/project";

export const addSelectedFiles = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addSelectedFiles>,
): ProjectState => {
  return {
    ...currentState,
    images: {
      ...currentState.images,
      ThreeDModels: {
        ...currentState.images.ThreeDModels,
        selectedFiles: payload,
      },
    },
  };
};
