import { useEffect } from "react";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";

import { useAppDispatch, useAppSelector, useIsComponentAdded } from ".";

export const useYearlyConsumption = (): void => {
  const dispatch = useAppDispatch();

  const household = useAppSelector(ProjectSelectors.getHousehold);
  const cars = useAppSelector(ProjectSelectors.getCars);
  const {
    configuration: { electricityCostHeatpump },
  } = useAppSelector(ProjectSelectors.getHeatpump);
  const { consumption: waterHeatingConsumption } = useAppSelector(
    ProjectSelectors.getModel,
  ).heatingRod;

  const { isHeatpumpAdded, isWaterHeaterAdded } = useIsComponentAdded();

  useEffect(() => {
    const carsConsumption = cars.reduce(
      (acc, car) => acc + car.configuration.evYearlyConsumption,
      0,
    );
    const heatpumpConsumption = isHeatpumpAdded ? electricityCostHeatpump : 0;
    const householdCons = household.consumption; // || household.calculatedConsumption
    const waterHeating = isWaterHeaterAdded ? waterHeatingConsumption : 0;

    const cons = carsConsumption + heatpumpConsumption + householdCons + waterHeating;
    dispatch(
      ProjectActions.updateEnergy({
        yearlyConsumption: cons,
      }),
    );
  }, [
    cars,
    dispatch,
    electricityCostHeatpump,
    // household.calculatedConsumption,
    household.consumption,
    isHeatpumpAdded,
    isWaterHeaterAdded,
    waterHeatingConsumption,
  ]);
};
