import React from "react";
import { CardContainer, CardCount, CardImage, CardText, CardTitle } from "./OperationCardStyles";
import { useTranslation } from "react-i18next";

const OperationCard = ({
  image,
  title,
  text,
  count,
  isActive,
  handleConceptSelect,
  setactiveStep,
}: any) => {
  const { t } = useTranslation();

  const handleSelectConcept = () => {
    handleConceptSelect(title);
    setactiveStep(3);
  };

  return (
    <CardContainer isActive={isActive} onClick={handleSelectConcept}>
      <CardTitle>{t(title)}</CardTitle>
      <CardImage src={image} />
      <CardText>{t(text)}</CardText>
      <CardCount>{count}</CardCount>
    </CardContainer>
  );
};

export default OperationCard;
