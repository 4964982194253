import mixpanel from "mixpanel-browser";

import * as AppConstants from "src/app-constants";
import { isOnProduction } from "src/utils";

mixpanel.init(AppConstants.MixPanel.InitId);

const actions = {
  identify: (id: any) => {
    if (isOnProduction()) mixpanel.identify(id);
  },
  alias: (id: any) => {
    if (isOnProduction()) mixpanel.alias(id);
  },
  track: (name: any, props: any) => {
    if (isOnProduction()) mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      if (isOnProduction()) mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
