import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { Divider, Form, Input, Select, Slider ,Checkbox} from "antd";
import { Autocomplete,  Paper, Switch, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { BorderAllRounded } from "@mui/icons-material";
//@ts-ignore
import { FilterContainer } from "./FilterStyled";
import {  UserSelectors } from "src/redux/selectors";
import { useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "src/shared/StyledComponents";
import { values } from "lodash";



interface FilterProps {
  form: any;
  onFormSubmit: (values: any) => void;
}

const Filter: React.FC<FilterProps> = ({ form, onFormSubmit }: FilterProps) => {
  const filters = useSelector(UserSelectors.getFilters);
  const handleSubmit = (values: any) => {
    onFormSubmit(values);
  };
  const handleReset = (values: any) => {
    onFormSubmit(values);
  };

  return (
    <FilterContainer style={{ borderRadius: "20px", marginLeft: "10px" }}>
      <Form
        form={form}
        onFinish={handleSubmit}
        onReset={handleReset}
        className="flex flex-col rounded-[20px] justify-center p-4"
      >
        <Form.Item name="address">
          <Input placeholder={t("Search address")} style={{ borderRadius: "20px" }} defaultValue={filters?.address}/>
        </Form.Item>
        <Form.Item name="name">
          <Input placeholder={t("Search name")} style={{ borderRadius: "20px" }} defaultValue={filters?.name}/>
        </Form.Item>
        <Form.Item name="city">
          <Input placeholder={t("Search city")} style={{ borderRadius: "20px" }} defaultValue={filters?.city}/>
        </Form.Item>

        <Form.Item name="status">
          <Select
            showSearch
            placeholder={t("Select Status")}
            // filterOption={(input, option) =>
            //   (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            // }
            style={{ width: "100%" }}
            defaultValue={filters?.status}
          >
            <Select.Option value="To be planned">{t("To be planned")}</Select.Option>
            <Select.Option value="Priority planning">{t("Priority planning")}</Select.Option>
            <Select.Option value="Urgent planning">{t("Urgent planning")}</Select.Option>
            <Select.Option value="Approval pending">{t("Approval pending")}</Select.Option>
            <Select.Option value="Rework pending">{t("Rework pending")}</Select.Option>
            <Select.Option value="Verified">{t("Verified")}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="buildingType">
          <Select
            showSearch
            placeholder={t("Select building type")}
            defaultValue={filters?.buildingType}
            // filterOption={(input, option) =>
            //   (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            // }
            style={{ width: "100%" }}
          >
            <Select.Option value="Multi-Family Building">
              {t("Multi-Family Building")}
            </Select.Option>
            <Select.Option value="Single-Family Building">
              {t("Single-Family Building")}
            </Select.Option>
            <Select.Option value="Office Building">{t("Office Building")}</Select.Option>
            <Select.Option value="Industry Building">{t("Industry Building")}</Select.Option>
            <Select.Option value="Agriculture">{t("Agriculture")}</Select.Option>
            <Select.Option value="Industrial">{t("Industrial")}</Select.Option>
            <Select.Option value="Residential">{t("Residential")}</Select.Option>
            <Select.Option value="Administration">{t("Administration")}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="owner">
          <Select
            showSearch
            placeholder={t("Select owner")}
            // filterOption={(input, option) =>
            //   (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            // }
            style={{ width: "100%" }}
            defaultValue={filters?.owner}
          >
            <Select.Option value="Domestic">{t("Domestic")}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="threedmodel" valuePropName="checked" >
          <Checkbox style={{marginLeft:'2rem'}} defaultChecked={filters?.threedmodel}>{t("View 3D model only")}</Checkbox>
        </Form.Item>

        <Form.Item>
          <PrimaryButton type="submit" style={{ width: "70%", position: "absolute", left: "8%" }}>
            {t("Apply filters")}
          </PrimaryButton>
        </Form.Item>
        <Form.Item>
          <SecondaryButton type="reset" style={{ width: "70%", position: "absolute", left: "8%" }}>
            {t("Remove filters")}
          </SecondaryButton>
        </Form.Item>
      </Form>
    </FilterContainer>
  );
};

export default Filter;
