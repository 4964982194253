import { ProductFilters } from "src/hooks";

import { ApplicationState, IpartnerCityData } from "../types";

import * as actionTypes from "./action.names";

import { ReduxAction } from "src/redux/common.types";

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export const setCurrentStep = (step: number): ReduxAction<number> => {
  return {
    payload: step,
    type: actionTypes.SET_CURRENT_STEP,
  };
};

export const setPopupCurrentStep = (step: number): ReduxAction<number> => {
  return {
    payload: step,
    type: actionTypes.SET_POPUP_CURRENT_STEP,
  };
};

export const setQuoteFetching = (isFecthing: boolean): ReduxAction<boolean> => {
  return {
    payload: isFecthing,
    type: actionTypes.SET_QUOTE_FETCHING,
  };
};

export const updateProperties = (
  data: any,
): ReduxAction<RecursivePartial<ApplicationState>> => {
  return {
    payload: data,
    type: actionTypes.UPDATE_PROPERTIES,
  };
};

export const updateAppState = (
  data: RecursivePartial<ApplicationState>,
): ReduxAction<RecursivePartial<ApplicationState>> => {
  return {
    payload: data,
    type: actionTypes.UPDATE_APP_STATE,
  };
};

export const updateInverterPreviousPreference = (
  data: ProductFilters | null,
): ReduxAction<ProductFilters | null> => {
  return {
    payload: data,
    type: actionTypes.UPDATE_INVERTER_PREVIOUS_PREF,
  };
};

export const updatePartnerCityData = (
  data: IpartnerCityData | null,
): ReduxAction<IpartnerCityData | null> => {
  return {
    payload: data,
    type: actionTypes.UPDATE_PARTNER_CITY_DATA,
  };
};

export const updateLastAccessedProject = (data: string | null): ReduxAction<string | null> => {
  return {
    payload: data,
    type: actionTypes.UPDATE_LAST_ACCESSED_PROJECT,
  };
};
