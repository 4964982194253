import { clone } from "lodash";

import { actionCreators, FlagState } from "src/redux/flags";

export const setFlag = (
  currentState: FlagState,
  { payload }: ReturnType<typeof actionCreators.setFlag>,
): FlagState => {
  const { data, open, flagKey } = payload;
  const newState = clone(currentState);

  newState[flagKey].open = open;
  if (data) newState[flagKey].data = data;

  return newState;
};
