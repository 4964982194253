import { clone } from "lodash";

import { actionCreators, ProductsState } from "src/redux/products";

export const setFilteredProducts = (
  currentState: ProductsState,
  { payload }: ReturnType<typeof actionCreators.setFilteredProducts>,
): ProductsState => {
  return {
    ...currentState,
    filteredProducts: {
      ...currentState.filteredProducts,
      ...payload,
    },
  };
};
