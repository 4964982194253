import React from "react";
import { useTranslation } from "react-i18next";

import {
  CloseMeterImage,
  HouseImage,
  HouseTopImage,
  OpenMeterImage,
  Sample1,
  Sample2,
} from "src/assets/images";

import * as S from "./SampleImages.styles";

import { ProjectImagesKey } from "src/redux/project/types/house-images";

interface SampleImagesProps {
  imagesType: ProjectImagesKey;
}
export const SampleImages: React.FC<SampleImagesProps> = (props: SampleImagesProps) => {
  const { imagesType } = props;

  const { t } = useTranslation();

  return (
    <div style={{ marginTop: "10px" }}>
      <S.MainText1>{t("Sample images")}</S.MainText1>
      <S.SubContainer>
        {imagesType === "electricMeter" && <img src={OpenMeterImage} />}
        {imagesType === "electricMeter" && <img src={CloseMeterImage} />}
        {imagesType === "ThreeDModels" && <img src={Sample1} />}
        {imagesType === "ThreeDModels" && <img src={Sample2} />}
        {imagesType !== "electricMeter" && imagesType !== "ThreeDModels" && (
          <img src={HouseTopImage} />
        )}
        {imagesType !== "electricMeter" && imagesType !== "ThreeDModels" && (
          <img src={HouseImage} />
        )}
      </S.SubContainer>
      <S.ColContain />
    </div>
  );
};
