import styled from "styled-components/macro";

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;
export const SubContainer = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffce31;
  margin-right: 20px;
  margin-top: 4px;
`;
