import styled from "styled-components/macro";

const RedDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: red;
  box-shadow: 0px 0px 0px 2px white, 0px 0px 10px red;
`;

export default RedDot;
