import styled from "styled-components/macro";

export const ChartLabelStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  padding: 8px 5px 8px 2px;
  border-radius: 10px;

  :hover {
    background-color: rgba(25, 25, 25, 0.2);
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 3px;

    color: var(--blue);
    font-weight: 600;
  }
`;
