import React, { useEffect, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import ChartCard from "../ChartCard/ChartCard";

import tooltipPlugin from "./chart-plugins/tooltipPlugin";
import ChartLabel from "./ChartLabel/ChartLabel";
import { ChartBody } from "./DonoughtChartStyles";

export interface DonoughtRecord {
  hoverLabel: string;
  label: {
    mainLabel: string;
    subLine: string;
  };
  value: number;
  color: string;
  shadowColor: string;
}

interface DonoughtChartProps {
  title: string;
  chartData: DonoughtRecord[];
  middleText: {
    title: string;
    subTitle: string;
  };
  disableExpandButton?: boolean;
  openByDefault?: boolean;
  hideLabels?: boolean;
  hideTitle?: boolean;
}

const DonoughtChart: React.FC<DonoughtChartProps> = (props: DonoughtChartProps) => {
  const { title, chartData, middleText, hideTitle, openByDefault, hideLabels } = props;

  const { t } = useTranslation();
  const chartRef: any = useRef(null);

  const [datasetConf, setDatasetConf]: [any, any] = useState({
    datasets: [],
    labels: [],
  });

  useEffect(() => {
    const chartDatasetConfirguration = {
      datasets: [
        {
          data: chartData.map((x) => x.value),
          // borderWidth: 0,
          // borderRadius: 20,
          // spacing: -15,
          // hoverOffset: 20,
          // backgroundColor: ["#F2994A", "#287E97", "#B5262B", "#B5268D"],
          backgroundColor: chartData.map((x) => x.color),
          hoverOuterGlowWidth: 10,
          hoverOuterGlowColor: chartData.map((x) => x.shadowColor),
          // hoverOuterGlowColor: [
          //   "rgba(242, 153, 74, 0.8)",
          //   "rgba(40, 126, 151, 0.8)",
          //   "rgba(181, 38, 43, 0.8)",
          //   "rgba(181, 38, 141, 0.8)",
          // ],
        },
      ],
      labels: chartData.map((x) => t(x.hoverLabel)),
    };

    setDatasetConf(chartDatasetConfirguration);
  }, [chartData, t]);

  const fireLabelMouseOverAndOutEvent = (eventName: string, labelIdx: number): void => {
    const chart = chartRef?.current;
    if (!chart) return;

    const meta = chart.getDatasetMeta(0);
    const rect = chart.canvas.getBoundingClientRect();
    const point = meta.data[labelIdx].getCenterPoint();
    const evt = new MouseEvent(eventName, {
      clientX: (rect.left as number) + (point.x as number),
      clientY: (rect.top as number) + (point.y as number),
    });
    const node = chart.canvas;
    node.dispatchEvent(evt);
  };

  const labelMouseOverCallback = (idx: number): void => {
    fireLabelMouseOverAndOutEvent("mousemove", idx);
  };

  const labelMouseOutCallback = (idx: number): void => {
    fireLabelMouseOverAndOutEvent("mouseout", idx);
  };

  return (
    <ChartCard
      title={title}
      disableExpand={props.disableExpandButton}
      hideTitle={hideTitle}
      isOpen={openByDefault}
    >
      <ChartBody style={{ padding: hideTitle ? 0 : 0 }}>
        {/* <Beta /> */}
        <div className="chart" style={{ width: "100%" }}>
          <div className="middle-text">
            <span className="big-text">{t(middleText.title)}</span>
            <span className="small-text">{t(middleText.subTitle)}</span>
          </div>
          <Doughnut
            ref={chartRef}
            data={datasetConf}
            options={{
              layout: {
                padding: 10,
                autoPadding: true,
              },
              cutout: "80%",
              responsive: true,
              aspectRatio: 2 / 1.25,
              animation: {
                animateRotate: true,
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                  external: (context: any) => {
                    const hasChart = chartRef.current;
                    if (hasChart) {
                      tooltipPlugin(context.tooltip, chartRef.current);
                    }
                  },
                },
              },
              radius: 100,
            }}
          ></Doughnut>
        </div>
        {!hideLabels && (
          <div className="labels">
            {chartData.map((item, idx) => {
              if (item.value === 0) return <></>;

              return (
                <ChartLabel
                  key={item.label.mainLabel}
                  title={item.label.mainLabel}
                  subTitle={item.label.subLine}
                  legendColor={item.color}
                  onMouseOver={() => {
                    labelMouseOverCallback(idx);
                  }}
                  onMouseOut={() => {
                    labelMouseOutCallback(idx);
                  }}
                ></ChartLabel>
              );
            })}
          </div>
        )}
      </ChartBody>
    </ChartCard>
  );
};

export default DonoughtChart;
