import { clone, merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const setAccessCount = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.setAccessCount>,
): ProjectState => {
  const newState: any = clone(currentState);
  newState.accessCount = payload + 1;
  return newState;
};
