import React from "react";
import { useTranslation } from "react-i18next";

import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

const Heading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ParameterStyles.Header>
      <ParameterStyles.Heading>{t("CONSUMPTION PARAMETERS")}</ParameterStyles.Heading>
      <ParameterStyles.HeadingDivider />
    </ParameterStyles.Header>
  );
};

export default Heading;
