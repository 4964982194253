import { clone, merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateEnergyProductionParams = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateEnergyProductionParams>,
): ProjectState => {
  const data = clone(payload);

  const updated = merge({}, currentState.energyProductionParams, data);

  return {
    ...currentState,
    energyProductionParams: updated,
  };
};
