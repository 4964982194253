import styled from "styled-components/macro";
interface LetMake {
  openDescription?: Boolean;
}

export const LoadContainer = styled.div`
  height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const MainContainer = styled.div`
  /* width: 100%;
  max-width: min(400px, 95%); */
  flex: 1;
  width: 360px;
  /* max-width: 35%; */
  /* height: max-content; */
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px;
  @media (max-width: 500px) {
    max-width: 95%;
  }
`;
export const ArrowContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background: #e3e3e3;
  }
  border-radius: 50%;
`;
export const SubContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;
export const SubContainer11 = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;
export const SubContainer12 = styled.div`
  position: absolute;
  width: 22px;
  height: 22px;
  right: 5px;
  top: 3px;
  background: #e2e2e2;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const SubContainer1 = styled.div<LetMake>`
  height: 51px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  :hover {
    background: #f9f9f9;
    border-radius: ${(props) => {
      if (!props.openDescription) {
        return "0px 0px 22px 22px";
      } else {
        return "0px";
      }
    }};
    cursor: pointer;
  }
`;
export const SubContainer112 = styled.div`
  height: 51px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .checkbox span {
    font-size: 14px;
    font-family: "Nunito";
    font-style: normal;
    text-transform: capitalize;
  }
`;
export const ColContain = styled.div`
  height: 1px;
  width: 100%;
  background: #e3e3e3;
`;
export const MainText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 22px;
  color: #304864;
  display: flex;
  gap: 5px;
`;
export const MainText1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #304864;
  margin-bottom: 10px;
`;
export const MainText11 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #304864;
`;
interface MainHeaderItemProps {
  filesUploadHover: boolean;
}
export const UploadArea = styled.div<MainHeaderItemProps>`
  flex: 1;
  height: 184px;
  border: 1px dashed #e2e2e2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  cursor: pointer;
  border: ${(props) => {
    if (props.filesUploadHover) {
      return "1px dashed #9D9D9D";
    }
  }};
  background: ${(props) => {
    if (props.filesUploadHover) {
      return "#FFF5D7";
    }
  }};
`;
export const SubText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #9d9d9d;
  width: 142px;
  text-align: center;
  line-height: 19px;
  margin-top: 16px;
`;
export const SubText1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #9d9d9d;
  margin-top: 10px;
`;
export const SubText2 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #2d4764;
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
`;
export const SubText3 = styled.textarea`
  width: 277px;
  height: 150px;
  background: #f9f9f9;
  border-radius: 10px;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
  padding: 15px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: black;
  margin-bottom: 20px;

  ::placeholder {
    color: #333;
  }
`;
export const SubText4 = styled.textarea`
  width: 277px;
  height: 150px;
  background: #f9f9f9;
  border-radius: 10px;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
  padding: 15px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-bottom: 20px;

  ::placeholder {
    color: #333;
  }
`;
