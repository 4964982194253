import styled from "styled-components";

export const InvestMentWrapper = styled("div")`
  height: 100%;
  width: 92%;
  margin: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 51px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 90px 40px 90px;
  @media (max-width: 500px) {
    padding: 10px;
    gap: 5px;
  }
  .small_heading {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.4px;
    color: #247d38;
    span {
      color: rgba(76, 78, 100, 0.68);
      font-weight: 400;
      font-size: 12px;
    }
  }
  .energyHeading {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.15px;
    color: #2d4764;
  }
  .investHeading {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.442553px;
    color: #2d4764;
  }
  .invest_info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    span {
      color: #67748e;
    }
    color: #252f40;
    @media (max-width: 500px) {
      flex-wrap: wrap;
      gap: 3px;
    }
  }
  .year_des_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .first_container {
    display: flex;
    flex-direction: column;
    gap: 23px;
    @media (max-width: 500px) {
      padding: 19px;
    }
  }
  .secound_container {
    display: flex;
    flex-direction: column;
    gap: 26px;
  }
`;

export const InvestmentChartWrapepr = styled(`div`)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  .circle {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ffbf1b;
  }
  .make_flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }
  .title {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #2d4764;
  }
`;
