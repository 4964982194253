import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { BulbIcon, ChargerIcon } from "src/assets/svgs";
import { ChartCard, HorizontalBarCardChart } from "src/shared/components";

import { ProjectSelectors } from "src/redux/selectors";

interface SelfSupplyChartProps {
  hideTitle?: boolean;
  isOpen?: boolean;
  disableExpand?: boolean;
}

const SelfSupplyChart: React.FC<SelfSupplyChartProps> = (props:SelfSupplyChartProps) => {
  const { t } = useTranslation();

  const model = useSelector(ProjectSelectors.getModel);

  return (
    <ChartCard title={t("Self-supply")}  hideTitle={props.hideTitle} isOpen={props.isOpen} disableExpand={props.disableExpand}>
      <HorizontalBarCardChart
        left={{
          color: "#FFD75D",
          IconComponent: BulbIcon,
          value: model.fromPVAndBattery ?? 0,
          title: "self-supply",
        }}
        right={{
          color: "#4D7E6D",
          IconComponent: ChargerIcon,
          value: model.feedIn ?? 0,
          title: "feed-in",
        }}
        subLabelFormatting={(value: string) => {
          return value + " kWh";
        }}
      />
    </ChartCard>
  );
};

export default SelfSupplyChart;
