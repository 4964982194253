import { Select } from "antd";
import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

// import { db } from "src/firebase-global";

import { useURLData } from "src/hooks";

import { DownArrow } from "src/assets/svgs";
// import i18n from "src/i18n/config";

import { MinimalProjectDB, ProjectResponseDB } from "src/db-types/project";

import * as S from "./VersionMenu.styles";

import { AllProjectsSelectors, AppSelectors } from "src/redux/selectors";
import { RootState } from "src/redux/store";
const { Option } = Select;

export const VersionMenu: React.FC = () => {
  const [projectVersions, setProjectVersions] = useState<ProjectResponseDB[]>([]);
  const [disable, setDisable] = useState(false);

  const [versionText, setVersionText] = React.useState("");

  const { isSavedProjectApiLoading } = useSelector(AppSelectors.getApiStates);
  const versionsMap = useSelector(AllProjectsSelectors.getVersionsMap);

  const { isOnSaved, savedURLData } = useURLData();
  const absoluteNavigate = useNavigate();
  const [query] = useSearchParams();
  const installerPartnerSlug = query.get("partner");

  useEffect(() => {
    setDisable(isSavedProjectApiLoading);
  }, [isSavedProjectApiLoading]);

  useEffect(() => {
    if (!isOnSaved) {
      setProjectVersions([]);
      return;
    }

    if (!savedURLData.shortId) {
      return;
    }

    setProjectVersions(versionsMap[savedURLData.shortId] ?? []);
  }, [isOnSaved, savedURLData.shortId, versionsMap]);

  useEffect(() => {
    if (isOnSaved) {
      setVersionText(t("solar configuration version") + " " + `${savedURLData.version}`);
    }
  }, [isOnSaved, savedURLData.version]);

  const handleChange = useCallback(
    (value: number) => {
      if (!installerPartnerSlug) {
        absoluteNavigate(`/application/saved-${savedURLData.shortId}/${value}`);
      } else {
        absoluteNavigate(
          `/application/saved-${savedURLData.shortId}/${value}?partner=${installerPartnerSlug}`,
        );
      }
    },
    [absoluteNavigate, savedURLData.shortId, installerPartnerSlug],
  );

  return (
    <div>
      {projectVersions.length > 1 && (
        <S.SelectWrapper>
          <Select
            value={savedURLData.version}
            virtual={false}
            onChange={handleChange}
            disabled={disable}
            suffixIcon={<DownArrow />}
          >
            {projectVersions?.map((data) => {
              return (
                <Option key={data._id} value={data.version}>
                  {window.innerWidth > 500
                    ? t("solar configuration version {{ version }}", {
                        version: data.version,
                      })
                    : `v${data.version}`}
                </Option>
              );
            })}
          </Select>
        </S.SelectWrapper>
      )}
    </div>
  );
};

export default VersionMenu;
