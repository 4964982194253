import StepConnector from "@mui/material/StepConnector";
import { withStyles } from "@mui/styles";

export const Connector = withStyles({
  alternativeLabel: {
    top: 11,
    cursor: "pointer",
    width: "calc(100% - 24px) !important",
    right: "24px !important",
    transform: "translateX(-50%)",
    left: "0 !important",
  },
  lineVertical: {
    height: 20,
  },
  active: {
    "& $line": {
      borderColor: "var(--primary-color)",
    },
  },
  completed: {
    "& $line": {
      borderColor: "var(--primary-color)",
    },
  },
  line: {
    mouse: "pointer",
  },
})(StepConnector);
