import * as S from "./OfferAccepctedStyled";
import { useSelector } from "react-redux";
import { ProjectSelectors, UserSelectors } from "src/redux/selectors";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { PrimaryButton, SecondaryButton } from "src/shared/StyledComponents";
import { profitByYearPure, useNumberFormat } from "src/hooks";
import { InstallerOfferDB } from "src/db-types";
import { UseInstallerDetails } from "../../../hooks/useInstallerDetails";

interface IOfferAccepcted {
  acceptedOffer: InstallerOfferDB;
}

const OfferAccepcted = ({ acceptedOffer }: IOfferAccepcted) => {
  const selectedProject = useSelector(ProjectSelectors.getProject);
  const Installer = useSelector(UserSelectors?.getInstaller);

  const { t } = useTranslation();
  const { formatNumber } = useNumberFormat();
  const installerDetails = UseInstallerDetails(acceptedOffer);
  const after20Years = profitByYearPure({
    appliedProjectCost: installerDetails?.finalPrice,
    carBuyingCost: selectedProject.financialDetails.costs.componentCosts.carBuyingCost,
    electricityCostSavings: selectedProject.financialDetails.costs.savings.electricityCostSavings,
    estimatedYearlyPriceIncrease: selectedProject.financialDetails.estimatedYearlyPriceIncrease,
    evGridCost: selectedProject.financialDetails.costs.gridCosts.evGridCost,
    expectedYears: 20,
    feedInSavings: selectedProject.financialDetails.costs.savings.feedInSavings,
    gasSavings: selectedProject.financialDetails.costs.savings.gasSavings,
    heatpumpGridCost: selectedProject.financialDetails.costs.gridCosts.heatpumpGridCost,
    futureOilGasCostIncrease:
      selectedProject.financialDetails.costs.savings.futureOilGasCostIncrease,
    mobilitySavings: selectedProject.financialDetails.costs.savings.mobilitySavings,
  });

  return (
    <S.Card>
      <S.ProjectCard>
        <div className="card_main">
          <div className="header_">
            <div className="text">
              {t("Offer")} {selectedProject.profile} {selectedProject.version}
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></div>
          </div>
          <div className="borders" />
          <div className="next_text">
            <Tooltip
              placement="top"
              title={`Valid until ${
                installerDetails?.realizationWeekShort?.split("-")[1] ||
                Installer?.realizationWithIn6Weeks
              } `}
            >
              Valid until{" "}
              {installerDetails?.realizationWeekShort?.split("-")[1] ||
                Installer?.realizationWithIn6Weeks}
            </Tooltip>
          </div>
          <div className="borders" />
          <div className="continaer">
            <div className="same_part">
              <div className="heading">{t("Energy production")}</div>
              <div className="info">
                <div className="first_thing">
                  <div className="again_same">
                    <p>{t("Amount of panels")}</p>
                  </div>
                  <div className="poew">{selectedProject.components.solarPanel.quantity}</div>
                </div>
                <div className="first_thing">
                  <div className="again_same">
                    <p>{t("Panel capacity")}</p>
                  </div>
                  <div className="poew">{78 + " " + "kwp"}</div>
                </div>
                <div className="first_thing">
                  <div className="again_same1">
                    <p>{t("System capacity")}</p>
                  </div>
                  <div className="poew">89 kWatt</div>
                </div>
              </div>
            </div>
            <div className="borders1"></div>
            <div className="same_part">
              <div className="heading">{t("Energy production")}</div>
              <div className="info">
                <div className="first_thing">
                  <div className="again_same">
                    <p>{t("Battery")}</p>
                  </div>
                  <div className="poew">
                    {formatNumber(
                      selectedProject.financialDetails.costs.componentCosts.batteryCost,
                    )}
                    €
                  </div>
                </div>
                <div className="first_thing">
                  <div className="again_same">
                    <p>{t("Wallbox")}</p>
                  </div>
                  <div className="poew">
                    {" "}
                    {formatNumber(
                      selectedProject.financialDetails.costs.componentCosts.wallboxCost,
                    )}
                    €
                  </div>
                </div>
                <div className="first_thing">
                  <div className="again_same1">
                    <p>{t("Heat pump")}</p>
                  </div>
                  <div className="poew">
                    {" "}
                    {formatNumber(
                      selectedProject.financialDetails.costs.componentCosts.heatpumpCost,
                    )}
                    €
                  </div>
                </div>
              </div>
            </div>
            <div className="borders1"></div>
            <div className="inside_container">
              <div className="new_same">
                <div className="text_same">{t("Price total project")}</div>
                <div className="text_same">
                  {" "}
                  {formatNumber(Math.floor(installerDetails?.finalPrice))} €
                </div>
              </div>
              <div className="new_same">
                <div className="littel_diff">{t("Amortisation time")}</div>
                <div className="littel_diff">
                  {" "}
                  {selectedProject.financialDetails.amortizationTime} {t("YEARS")}
                </div>
              </div>
              <div className="new_same">
                <div className="text_same">{t("ROI after 20 Years")}</div>
                <div className="text_same">{formatNumber(Math.floor(after20Years?.profit))} €</div>
              </div>
            </div>
            <div className="button_section">
              <PrimaryButton>{t("KONFIGURATION")}</PrimaryButton>
            </div>
          </div>
        </div>
      </S.ProjectCard>
      <S.InstallerDetails>
        <S.Title>Ihr Installationspartner:</S.Title>
        <S.Logo src={installerDetails?.logoUrl[0]} className="logo_url" alt="" />{" "}
        <S.Details>
          <div>
            {t("Entfernung zu deinem Haus:")} {(installerDetails?.distance).toFixed(2)} km
          </div>
          <div>{installerDetails?.address}</div>
        </S.Details>
        <S.ProjectImage
          src={selectedProject?.quote?.profiles?.green?.imgURLs?.[0]}
          className="logo_url"
          alt=""
        />
        <SecondaryButton onClick={() => (window.location.href = installerDetails?.pdfUrl)}>
          {" "}
          Download PDF offer
        </SecondaryButton>
      </S.InstallerDetails>
    </S.Card>
  );
};

export default OfferAccepcted;
