import { MasterInput } from "src/shared/components";
import styled from "styled-components/macro";

export const MainContentWrapper = styled.div`
  display: grid;
  flex-direction: column;
  margin: 30px;

  @media (max-width: 600px) {
    margin: 9px;
    max-width: 100vw;
  }
  gap: 2rem;
  min-width: 40vw;
  max-width: 70vw;
  font-family: "NuNito";
`;

export const Section1 = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  color: #2d4764;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  align-items: center;
`;

export const Section2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 20px;
  font-weight: 300;
  color: #2d4764;
  width: fit-content;
  justify-self: center;
  column-gap: 30px;
`;

export const Button = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
`;

