import { merge } from "lodash";

import { RecursivePartial } from "src/utils";

import { actionCreators, AllProjectsState } from "src/redux/all-projects";

export const setAllVersions = (
  currentState: AllProjectsState,
  { payload }: ReturnType<typeof actionCreators.setAllVersions>,
): AllProjectsState => {
  return {
    ...currentState,
    versionsMap: payload,
  };
};
