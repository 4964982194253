import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import PopupSkeleton from "../../shared/components/PopupSkeleton/popupSkeleton";

import {
  StepperWrapper,
  MultiTenantWrapper,
  ArrowButton,
  MultiTenantContent,
  ArrowContainer,
} from "./MultiTenantStyles";
import OperationConcept from "./operationConcept/OperationConcept";
import OperatorAdjust from "./operationConfigration/operationConfigration";
import OperationProduct from "./operationProduct/OperationProduct";
import OperatorModel from "./operatorModel/OperatorModel";
import OverviewModal from "./overviewModal/OverviewModal";
import MultiTenantPopupStepper from "./PopupStepper/PopupStepper";

import { ReduxAction } from "src/redux/common.types";
import { POPUP_KEYS } from "src/redux/popups";
import { closePopup } from "src/redux/popups/action/action.creators";
import { ProjectSelectors } from "src/redux/selectors";

export const popupStepperHeading = [
  "Mieterstrom Konfiguration",
  "Betriebskonzept",
  "Betreiber",
  "Konfiguration",
  "Abrechnung",
  "Komponenten",
  "Übersicht",
];

export const MultiTenant: React.FC = () => {
  const { t } = useTranslation();
  const [activeStep, setactiveStep] = useState(1);
  const [currentStepComponent, setcurrentStepComponent]: any = useState(null);
  const dispatch = useDispatch();
  const additionalSoftwareProduct = useSelector(ProjectSelectors.getAdditionalSoftware);
  const additionalHardwareProduct = useSelector(ProjectSelectors.getAdditionalHardware);

  const handleClose = useCallback(
    (): ReduxAction<any> => dispatch(closePopup(POPUP_KEYS.multi_tenant)),
    [dispatch],
  );

  useEffect(() => {
    switch (activeStep) {
      case 1:
        setcurrentStepComponent(<OperationConcept setactiveStep={setactiveStep} />);
        break;
      case 2:
        setcurrentStepComponent(<OperatorModel setactiveStep={setactiveStep} />);
        break;
      case 3:
        setcurrentStepComponent(<OperatorAdjust />);
        break;
      case 4:
        setcurrentStepComponent(
          <OperationProduct
            productName="additionalSoftware"
            productItem={additionalSoftwareProduct?.item}
          />,
        );
        break;
      case 5:
        setcurrentStepComponent(
          <OperationProduct
            productName="additionalHardware"
            productItem={additionalHardwareProduct?.item}
          />,
        );
        break;
      case 6:
        setcurrentStepComponent(<OverviewModal />);
        break;
      default:
        break;
    }
  }, [activeStep, handleClose, t, additionalSoftwareProduct, additionalHardwareProduct]);

  const handleNextStep = () => {
    setactiveStep((prevStep) => Math.min(prevStep + 1, popupStepperHeading.length));
  };

  const handlePrevStep = () => {
    setactiveStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  return (
    <PopupSkeleton handleClose={handleClose} open={true} styless={{ position: "relative" }}>
      <MultiTenantWrapper>
        <MultiTenantPopupStepper
          popupStepperHeading={popupStepperHeading}
          setactiveStep={setactiveStep}
          activeStep={activeStep}
        />
        <MultiTenantContent>
          <ArrowContainer>
            {activeStep !== 1 && (
              <ArrowButton onClick={handlePrevStep}>
                <ArrowBackIos
                  className="arrow_left"
                  style={{
                    color: "rgb(102, 102, 102)",
                    transform: "none",
                    fontSize: 20,
                    position: "initial",
                  }}
                />
              </ArrowButton>
            )}
          </ArrowContainer>
          <StepperWrapper>{currentStepComponent}</StepperWrapper>
          <ArrowContainer>
            {activeStep !== 6 && (
              <ArrowButton onClick={handleNextStep}>
                <ArrowForwardIos
                  className="arrow_right"
                  style={{
                    color: "rgb(102, 102, 102)",
                    transform: "none",
                    fontSize: 20,
                    position: "initial",
                  }}
                />
              </ArrowButton>
            )}
          </ArrowContainer>
        </MultiTenantContent>
      </MultiTenantWrapper>
    </PopupSkeleton>
  );
};

export default MultiTenant;
