import {
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  RegisterResponse,
  ProductsResponse,
  IPApiResponse,
  UserResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  GoogleLoginRequest,
  GoogleLoginResponse,
  UserDetailsRequest,
  UserDetailsResponse,
  QuoteRequest,
  QuoteResponse,
  SaveProjectResponse,
  GetProjectResponse,
  SaveProjectRequest,
  GetAllUniqueAddressProjectResponse,
  GetAllVersionsResponse,
  MediaRequest,
  MediaResponse,
  NearbyInstallerRequestBody,
  NearbyInstallerResponseBody,
  AddInstallerRequestBody,
  AddInstallerResponseBody,
  ExploreInstallerRequestBody,
  ExploreInstallerResponseBody,
  WebinarResponse,
  WebinarRegisterResponse,
  HubspotDealCreateRequest,
  HubspotDealCreateResponse,
  ResetPassRequest,
  PartnerCityResponse,
  ConstantResponse,
  GetPartnerCityBody,
  RefCreditRequest,
  RentingAPIResponse,
  RentingAPIRequestBody,
  ProductObjFilesResponse,
  GoogleMapsAutocompleteBackendResponse,
} from "src/api";

import { LastLogDB } from "src/db-types";

import { UseAxiosReturn, useAxios } from "./useAxios";

/**
 * ----------------------- Login / Regsiter flow -----------------------
 */
export const useLoginAPI = (): UseAxiosReturn<LoginRequest, LoginResponse> =>
  useAxios<LoginRequest, LoginResponse>();

export const useResetPassAPI = (): UseAxiosReturn<ResetPassRequest, any> =>
  useAxios<ResetPassRequest, any>();

export const useGoogleLoginAPI = (): UseAxiosReturn<GoogleLoginRequest, GoogleLoginResponse> =>
  useAxios<GoogleLoginRequest, GoogleLoginResponse>();

export const useRegisterAPI = (): UseAxiosReturn<RegisterRequest, RegisterResponse> =>
  useAxios<RegisterRequest, RegisterResponse>();

export const useForgotPasswordAPI = (): UseAxiosReturn<
  ForgotPasswordRequest,
  ForgotPasswordResponse
> => useAxios<ForgotPasswordRequest, ForgotPasswordResponse>();

/**
 * ----------------------- User related apis -----------------------
 */
export const useUserApi = (): UseAxiosReturn<undefined, UserResponse> =>
  useAxios<undefined, UserResponse>();

export const useUserDetailsApi = (): UseAxiosReturn<UserDetailsRequest, UserDetailsResponse> =>
  useAxios<UserDetailsRequest, UserDetailsResponse>();

export const useLastLogApi = (): UseAxiosReturn<LastLogDB, UserResponse> =>
  useAxios<LastLogDB, UserResponse>();

export const userUpdateRefCredits = (): UseAxiosReturn<RefCreditRequest, any> =>
  useAxios<RefCreditRequest, any>();

/**
 * ----------------------- Quote and projects apis -----------------------
 */
export const useQuoteApi = (): UseAxiosReturn<QuoteRequest, QuoteResponse> =>
  useAxios<QuoteRequest, QuoteResponse>();

export const useSaveProjectApi = (): UseAxiosReturn<SaveProjectRequest, SaveProjectResponse> =>
  useAxios<SaveProjectRequest, SaveProjectResponse>();

export const useCreateNewVersion = (): UseAxiosReturn<undefined, SaveProjectResponse> =>
  useAxios<undefined, SaveProjectResponse>();

export const useGetProjectApi = (): UseAxiosReturn<any, GetProjectResponse> =>
  useAxios<any, GetProjectResponse>();

export const useSaveDeal = (): UseAxiosReturn<
  HubspotDealCreateRequest,
  HubspotDealCreateResponse
> => useAxios<HubspotDealCreateRequest, HubspotDealCreateResponse>();

export const useGetAllProjects = (): UseAxiosReturn<any, GetProjectResponse> =>
  useAxios<any, GetProjectResponse>();

export const useUpdateProjectApi = (): UseAxiosReturn<SaveProjectRequest, any> =>
  useAxios<SaveProjectRequest, any>();

export const useGetAllUniqueAddressProjectsApi = (): UseAxiosReturn<
  any,
  GetAllUniqueAddressProjectResponse
> => useAxios<any, GetAllUniqueAddressProjectResponse>();

export const useGetAllVersionsApi = (): UseAxiosReturn<any, GetAllVersionsResponse> =>
  useAxios<any, GetAllVersionsResponse>({ allowMultipleRequests: true });

export const useSaveMedia = (): UseAxiosReturn<MediaRequest, MediaResponse> =>
  useAxios<MediaRequest, MediaResponse>({ allowMultipleRequests: true });
/**
 * ----------------------- Products api -----------------------
 */
export const useProductsApi = (): UseAxiosReturn<undefined, ProductsResponse> =>
  useAxios<undefined, ProductsResponse>();

/**
 * ----------------------- webinar api -----------------------
 */

export const useWebinarsApi = (): UseAxiosReturn<undefined, WebinarResponse> =>
  useAxios<undefined, WebinarResponse>({ allowMultipleRequests: true });

/**
 * ----------------------- webinar api -----------------------
 */

export const usePartnerCityAPI = (): UseAxiosReturn<GetPartnerCityBody, PartnerCityResponse> =>
  useAxios<GetPartnerCityBody, PartnerCityResponse>({ allowMultipleRequests: true });

export const useGetRentingDataFromGolfStorm = (): UseAxiosReturn<
  RentingAPIRequestBody,
  RentingAPIResponse | any
> => useAxios<RentingAPIRequestBody, RentingAPIResponse>({ allowMultipleRequests: true });

export const useGetRentingOfferFromGolfStorm = (): UseAxiosReturn<RentingAPIRequestBody, any> =>
  useAxios<RentingAPIRequestBody, any>({ allowMultipleRequests: true });

export const usePartnerCityQuoteUpdateApi = (): UseAxiosReturn<any, any> => useAxios<any, any>();

export const useRegisterIntoWebinar = (): UseAxiosReturn<
  RegisterRequest,
  WebinarRegisterResponse
> => useAxios<RegisterRequest, WebinarRegisterResponse>({ allowMultipleRequests: true });

/**
 * ----------------------- constant api -----------------------
 */

export const useGetConstant = (): UseAxiosReturn<undefined, ConstantResponse> =>
  useAxios<undefined, ConstantResponse>({ allowMultipleRequests: true });

/**
 * ----------------------- constant api -----------------------
 */

export const useGetRefCode = (): UseAxiosReturn<undefined, UserResponse> =>
  useAxios<undefined, UserResponse>({ allowMultipleRequests: true });

/**
 * ----------------------- 3rd party apis -----------------------
 */
export const useIPApi = (): UseAxiosReturn<undefined, IPApiResponse> =>
  useAxios<undefined, IPApiResponse>({ useBackendInstance: false });

// proxies through backend
export const useGoogleMapsAutocompleteAPI = (): UseAxiosReturn<
  string,
  GoogleMapsAutocompleteBackendResponse
> => useAxios<string, GoogleMapsAutocompleteBackendResponse>();

/**
 * ------------------------ Installer apis --------------------------------
 */
export const useNearbyInstallerAPI = (): UseAxiosReturn<
  NearbyInstallerRequestBody,
  NearbyInstallerResponseBody
> => useAxios<NearbyInstallerRequestBody, NearbyInstallerResponseBody>();

export const useAddInstallerAPI = (): UseAxiosReturn<
  AddInstallerRequestBody,
  AddInstallerResponseBody
> => useAxios<AddInstallerRequestBody, AddInstallerResponseBody>();

export const useDeleteInstallerAPI = (): UseAxiosReturn<any, any> =>
  useAxios<any, any>({
    allowMultipleRequests: true,
  });

export const useUpdateInstallerAPI = (): UseAxiosReturn<any, any> => useAxios<any, any>();

export const useExploreInstallerAPI = (): UseAxiosReturn<
  ExploreInstallerRequestBody,
  ExploreInstallerResponseBody
> => useAxios<ExploreInstallerRequestBody, ExploreInstallerResponseBody>();

/**
 * ======== request bake model api ========
 */

export const useRequestBakeModel = (): UseAxiosReturn<any, any> =>
  useAxios<any, any>({
    allowMultipleRequests: true,
  });

export const useRequestGetAtrifacts = (): UseAxiosReturn<any, any> =>
  useAxios<any, any>({
    allowMultipleRequests: true,
  });

export const useDeleteSinglePanelAPI = (): UseAxiosReturn<any, any> =>
  useAxios<any, any>({
    allowMultipleRequests: true,
  });

export const useGetProductObjFiles = (): UseAxiosReturn<undefined, ProductObjFilesResponse> =>
  useAxios<undefined, ProductObjFilesResponse>({ allowMultipleRequests: true });
