import styled from "styled-components";

export const CardContainer = styled.div<{ isActive: boolean }>`
  padding: 30px 20px 30px 20px;
  gap: 14px;
  border-radius: 6px;
  opacity: 0px;
  max-width: 278px;
  box-shadow: 1px 1px 10px 0px #2d476480;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  position: relative;
  ${({ isActive }) => isActive && `background-color: rgba(253, 184, 0, 0.1);`};

  :hover {
    cursor: pointer;
    background-color: rgba(253, 184, 0, 0.1);
    transition: 0.3s;
  }
`;

export const CardTitle = styled.h3`
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
`;

export const CardText = styled.p`
  font-size: 14px;
`;

export const CardImage = styled.img`
  width: 238px;
  height: 137.34px;
`;

export const CardCount = styled.div`
  position: absolute;
  top: -20px;
  width: 40px;
  height: 40px;
  border: 1px solid white;
  box-shadow: 0px 4px 10px 0px #00000040;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  left: 50%;
  transform: translate(-50%, 0);
`;