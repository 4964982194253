import styled from "styled-components/macro";

import { PrimaryButton } from "src/shared/StyledComponents";

export const BigContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 500px) {
    margin-bottom: 5rem;
  }
  .address {
    font-family: "Nunito";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    color: #2d4764;
    flex-direction: column;
    align-items: center;
    display: flex;
    gap: 3px;
    .des {
      font-family: "Nunito";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: #666;
    }
  }
  .progress-total {
    flex-direction: row;
  }
  .custom-table {
    width: 95%;
    text-align: center;
    border-radius: 8px;
    border-radius: 20px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  }
  .fullscreen {
    width: 100%;
    height: 100%;
  }
  .custom-table tbody tr:last-child td.last-cell {
    border-bottom-left-radius: 8px; /* Adjust the radius as needed */
  }
  /* Style table headers */
  .custom-table th {
    padding: 8px;
    border-left: 1px solid #dfdfdf; /* Add left column borders for cells */
    border-right: 1px solid #dfdfdf;
    text-align: center;
  }

  /* Style table cells */
  .custom-table td {
    padding: 8px;
    border-left: 1px solid #dfdfdf; /* Add left column borders for cells */
    border-right: 1px solid #dfdfdf;
    text-align: center;
  }

  .custom-table th:nth-child(1),
  .custom-table td:nth-child(1) {
    width: 20%;
    border-left: none !important;
  }

  .custom-table th:nth-child(2),
  .custom-table td:nth-child(2) {
    width: 12%;
  }

  .custom-table th:nth-child(3),
  .custom-table td:nth-child(3) {
    width: 40%;
    padding-inline: 2%;
  }

  .custom-table th:nth-child(4),
  .custom-table td:nth-child(4) {
    width: 12%;
  }

  .custom-table th:nth-child(5),
  .custom-table td:nth-child(5) {
    width: 8%;
    border-right: none !important;
    .icon-div {
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
  }

  .top-bar {
    font-size: 12px;
    color: white;
    font-weight: bolder;
    text-align: end;
    padding-right: 5px;
  }
  .content {
    height: 100% !important;
    width: 100% !important;
    position: relative !important;
  }
  .threeD_loader_component {
    height: 100% !important;
    width: 100% !important;
    position: relative !important;
    canvas {
      height: 100% !important;
      width: 100% !important;
    }
  }

  .cards {
    display: flex;
    justify-content: center;
    gap: 30px;
    width: 100%;
    padding: 0 0 10px 0;
    overflow-x: auto;
    overflow-y: hidden;
    @media only screen and (max-width: 800px) {
      flex-wrap: wrap;
    }
    @media only screen and (max-width: 500px) {
      flex-direction: column;
      gap: 10px;
    }

    .cards::-webkit-scrollbar {
      width: 0px !important;
      background: transparent !important;
    }
  }
`;

export const InnerContainer = styled.div`
  width: 90%;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  position: relative;
  .address {
    font-family: "Nunito";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    color: #2d4764;
    flex-direction: column;
    align-items: center;
    display: flex;
    gap: 3px;
    .des {
      font-family: "Nunito";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: #666;
    }
  }
  .progress {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
  .head {
    font-family: "Nunito";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: #666;
    display: flex;
    gap: 10px;
  }
  .top-bar {
    font-size: 12px;
    color: white;
    font-weight: bolder;
    text-align: end;
    padding-right: 5px;
  }
`;

// export const Container = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 50%;
//   position: relative;
//   .left-panel {
//     display: flex;
//     flex-direction: column;
//     gap: 10px;
//     justify-content: center;
//     align-items: center;
//     border-radius: 20px;
//     background: #2d4764;
//     position: absolute;
//     padding: 10px 5px;
//     z-index: 99;
//   }
//   .right-panel {
//     display: flex;
//     flex-direction: column;
//     gap: 10px;
//     justify-content: center;
//     align-items: center;
//     border-radius: 20px;
//     background: #2d4764;
//     position: absolute;
//     padding: 10px 5px;
//     z-index: 99;
//     right: 10px;
//     top: 10px;
//   }
// `;

export const GreenButton = styled(PrimaryButton)`
  width: 230px;
  background-color: #4d7e6d !important;
  border: none !important;
  color: #fff !important;
  padding-inline: 5% !important;
`;

export const DeleteModelButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

export const UploadingActionButtons = styled.div`
  display: flex;
  gap: 5px;
`;

export const UploadBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    gap: 1rem;
    margin-inline: auto;
    align-items: center;
  }
`;

export const StyledDownloadButton = styled.div`
  cursor: pointer;
  position: relative;

  img {
    visibility: visible;
    z-index: 1;
  }

  span {
    visibility: hidden;
    position: absolute;
    left: 50px;
    width: 400px;
    z-index: 2;
  }

  &:hover span {
    visibility: visible;
  }
`;
