import styled from "styled-components/macro";
import { colors } from "src/utils";
import { PrimaryButton, SecondaryButton } from "src/shared/StyledComponents";

export const InstallerDetailsCard = styled.div`
  padding-inline: 5%;
  padding-top: 2%;
  height: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: space-around;
  border-radius: 22px;
  background-color: #ffffff;
  height: 100%;
  min-width: 350px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    flex: 1;
    border-radius: 22px;
    margin-top: 5%;
  }
`;
export const Title = styled.div`
  color: var(--dark-boue, #2d4764);
  text-align: center;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Details = styled.div`
  color: var(--dark-boue, #2d4764);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const AdressDetails = styled.div`
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--Leas-color-inspiration-White, #fff);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const Logo = styled.img`
  width: 171px;
  height: 40px;
  flex-shrink: 0;
  object-fit: contain;
`;

export const ProjectImage = styled.img`
  width: 279px;
  height: 202px;
  flex-shrink: 0;
  border-radius: 10px;
`;

export const ContactDetails = styled.div`
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const BorderLessButton = styled(SecondaryButton)`
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const Buttons = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;
export const ReqButton = styled(PrimaryButton)`
width: max-content;
text-align: center;
padding-inline: 1.5rem;
text-transform: uppercase;
`