import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { DownArrow } from "src/assets/svgs";
import { Mixpanel } from "src/mix-panel/mixpanel";
import * as trackingEvents from "src/mix-panel/trackingEvents";
import { getLanguageFullName, getShortCode } from "src/utils";

import * as S from "./LanguageDropdown.styles";

import { actionCreators as userActions, selectors as userSelectors } from "src/redux/user";

const flags = {
  English: "/images/English.png",
  German: "/images/German.png",
  Spanish: "/images/spain.jpg",
  French: "/images/France.png"
};

const LanguageDropdown: React.FC = () => {
  const { i18n } = useTranslation();
  const classes = S.useStyles();
  const lang = useSelector(userSelectors.getLangCode);
  const dispatch = useDispatch();

  const [selectedLanguage, setSelectedLanguage]: any = useState(getLanguageFullName(lang));

  useEffect(() => {
    setSelectedLanguage(getLanguageFullName(lang));
    localStorage.setItem("preferredLanguage", getLanguageFullName(lang));
    if (i18n.language === lang) return;
    void i18n.changeLanguage(lang);
  }, [i18n, lang]);

  const changeLanguage = (e: any): void => {
    setSelectedLanguage(e.target.value);
    const shortCode = getShortCode(e.target.value);

    dispatch(userActions.setLanguage(shortCode));
    void i18n.changeLanguage(shortCode);

    Mixpanel.track(trackingEvents.USER_CLICK_ON_CHANGE_LANGUAGE, {});
  };

  return (
    <S.WrapperOfMaterialUiSelect>
      <Select
        margin="dense"
        variant="standard"
        className={classes.selectHalper}
        displayEmpty={true}
        renderValue={() => {
          return (
            <img src={flags[selectedLanguage as keyof typeof flags]} className={classes.image} />
          );
        }}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        disableUnderline
        IconComponent={DownArrow}
        value={selectedLanguage}
        onChange={(e) => changeLanguage(e)}
      >
        <MenuItem value={"English"}>English</MenuItem>
        <MenuItem value={"German"}>German</MenuItem>
        <MenuItem value={"Spanish"}>Spanish</MenuItem>
        <MenuItem value={"French"}>French</MenuItem>
      </Select>
    </S.WrapperOfMaterialUiSelect>
  );
};

export default LanguageDropdown;
