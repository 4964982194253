export enum AUTH_MODES {
  "signup" = "SIGN_UP",
  "login" = "LOG_IN",
  "webinar_signup" = "WEBINAR_SIGN_UP",
  "webinar_login" = "WEBINAR_LOG_IN",
  "forgot_password" = "FORGOT_PASSWORD",
  "reset_password" = "RESET_PASSWORD",
  "update_details" = "UPDATE_DETAILS",
}
export interface AuthPopupProps {
  authMode: AUTH_MODES;
  afterSuccess?: Function;
}
export interface PartnerCityPopupProps {
  title: string;
  description: string;
  question: string;
}

export enum AuthMethod {
  "google" = "google",
  "system" = "system",
}
