import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useIsComponentAdded, useNumberFormat } from "src/hooks";

import { ProductDB } from "src/db-types";
import { getNumber } from "src/utils";

import chartColors from "../chartColors";
import {
  NegativeContainer,
  PositiveContainer,
  SubContainer,
  SubContainer1,
} from "../ReturnOnInvest/calculations/styles";
import { hoverBarsKey, hoverReducer, InitialState } from "../ReturnOnInvest/hover.reducer";
import {
  Bar,
  ChartArea,
  ChartWrapper,
  Circle,
  CustomTooltip,
  Divider,
  Region,
  RegionLabel,
} from "../ReturnOnInvest/price-break-down-chart/chart.styles";
import { setHoverHelper } from "../setHoverLogicHelper";

import { MainContainer, Number1, Number2, Number3, Text1, Text2 } from "./styles";

import { ProjectSelectors } from "src/redux/selectors";
import { getRentingData } from "src/redux/user/selectors";

interface Calculations {
  savingsBill: number;
  feedInGains: number;
  savingsMobilityBill: number;
  savingsHeatBill: number;
  gridPurchaseEV: number;
  gridPurchaseHeat: number;
  leasedEV: number;
  financingCost: number;
  profit: number;
  loss: number;
  negativeCosts: number;
  positiveCosts: number;
  buildingCost: number;
}

const defaultCalculations: Calculations = {
  savingsBill: 0,
  feedInGains: 0,
  savingsMobilityBill: 0,
  savingsHeatBill: 0,
  gridPurchaseEV: 0,
  gridPurchaseHeat: 0,
  leasedEV: 0,
  financingCost: 0,
  buildingCost: 0,
  profit: 0,
  loss: 0,
  negativeCosts: 0,
  positiveCosts: 0,
};

function getProductPriceKey(projectType: string): keyof ProductDB {
  return projectType === "b2b" ? "priceB2b" : "priceB2c";
}

interface IMonthlyElectricityCost {
  isRenting: boolean;
}

export const MonthlyElectricityCost: React.FC<IMonthlyElectricityCost> = ({
  isRenting = false,
}: IMonthlyElectricityCost) => {
  const { t } = useTranslation();
  const { formatNumber } = useNumberFormat();

  const {
    isFinancing,
    costs: {
      totalProjectCost,
      totalFinancingCost,
      gridCosts: { evGridCost, heatpumpGridCost },
      savings: { electricityCostSavings, feedInSavings, gasSavings, mobilitySavings },
    },
  } = useSelector(ProjectSelectors.getFinancialDetails);
  const cars = useSelector(ProjectSelectors.getCars);
  const projectType = useSelector(ProjectSelectors.getProjectType);
  const rentingData = useSelector(getRentingData);

  const [calculations, setCalculations] = useState(defaultCalculations);
  const [barHeights, setBarHeights]: [any, any] = useState({});

  const { areCarsAdded, isHeatpumpAdded } = useIsComponentAdded();

  const getPercentage = (part: number, total: number): number => {
    return (part / total) * 100;
  };

  const shouldShowTooltip = (hoverKey: string): boolean => {
    return hoverState.tooltip && hoverState.hoveredBar === hoverKey;
  };

  const [hoverState, hoverDispatch] = useReducer(hoverReducer, InitialState);

  const setHover = (hoverKey: string): void => {
    setHoverHelper(hoverKey, hoverDispatch);
  };

  const shouldLighten = (hoverKey: string): boolean => {
    const currBarIsHovered = hoverKey === hoverState.hoveredBar;
    if (currBarIsHovered) return false;

    const isAnyBarHovered = hoverState.hoveredBar !== "";
    if (isAnyBarHovered) return true;

    return false;
  };

  const getTitle = (numeral: string, text: string): any => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <span>{numeral} €</span>
        <span>{text}</span>
      </div>
    );
  };

  useEffect(() => {
    const savingsBill = electricityCostSavings / 12;
    const feedInGains = feedInSavings / 12;
    const savingsMobilityBill = mobilitySavings / 12;
    const savingsHeatBill = gasSavings / 12;

    const gridPurchaseEV = evGridCost / 12;
    const gridPurchaseHeat = heatpumpGridCost / 12;

    const leasedEV =
      cars.reduce((acc, car) => {
        if (!car.isLeasing) return acc;
        if (!car.item) return acc;

        const priceKey: keyof ProductDB = getProductPriceKey(projectType);
        return acc + getNumber(car.item?.[priceKey]);
      }, 0) ?? 0;

    let financingCost = 0;
    let monthlyBuildingCost = 0;
    monthlyBuildingCost = totalProjectCost / 12 / 12;
    if (isRenting) {
      monthlyBuildingCost = Number(rentingData?.rate_with_vat);
    }

    if (isFinancing && !isRenting) {
      financingCost = Math.round((totalFinancingCost - totalProjectCost) / 12 / 12);
    }

    const positiveCosts = savingsBill + savingsMobilityBill + savingsHeatBill + feedInGains;
    const negativeCosts =
      gridPurchaseHeat + gridPurchaseEV + leasedEV + financingCost + monthlyBuildingCost;

    const profit = positiveCosts - negativeCosts;
    const loss = savingsBill;

    setCalculations({
      savingsBill,
      feedInGains,
      savingsMobilityBill,
      savingsHeatBill,
      gridPurchaseEV,
      gridPurchaseHeat,
      leasedEV,
      financingCost,
      profit,
      loss,
      positiveCosts,
      negativeCosts,
      buildingCost: monthlyBuildingCost,
    });

    const total = profit + loss;
    let maxBar = total;
    maxBar = Math.max(positiveCosts, negativeCosts, Math.abs(profit), loss);
    maxBar = maxBar * 1.35;

    const heights: any = {};
    heights.profit = getPercentage(Math.abs(profit), maxBar);
    heights.loss = getPercentage(loss, maxBar);

    heights.feedInGains = getPercentage(feedInGains, Math.abs(profit));
    heights.savingsMobilityBill = getPercentage(savingsMobilityBill, Math.abs(profit));
    heights.savingsBill = getPercentage(savingsBill, Math.abs(profit));
    heights.savingsHeatBill = getPercentage(savingsHeatBill, Math.abs(profit));

    heights.gridPurchaseEV = getPercentage(gridPurchaseEV, Math.abs(profit));
    heights.gridPurchaseHeat = getPercentage(gridPurchaseHeat, Math.abs(profit));
    heights.leasedEV = getPercentage(leasedEV, Math.abs(profit));
    heights.financingCost = getPercentage(financingCost, Math.abs(profit));
    heights.buildingCost = getPercentage(monthlyBuildingCost, Math.abs(profit));

    setBarHeights(heights);
  }, [
    cars,
    electricityCostSavings,
    evGridCost,
    feedInSavings,
    gasSavings,
    heatpumpGridCost,
    isFinancing,
    mobilitySavings,
    projectType,
    totalFinancingCost,
    totalProjectCost,
    isRenting,
  ]);

  const getNumericalText = (num: number): string => {
    const txt = formatNumber(num);
    if (num < 0) return txt;
    else return "+" + txt;
  };

  return (
    <MainContainer>
      <ChartWrapper>
        <Region>
          <RegionLabel verticalAlign="bottom">
            <Circle backgroundColor={chartColors.PROFIT}></Circle> {t("WITH SOLAR SYSTEM")}
          </RegionLabel>
          <ChartArea hovered={hoverState.showCalculation} verticalAlign="bottom" style={{ marginLeft: "40px"}}>
            <CustomTooltip
              title={getTitle(
                getNumericalText(calculations.profit),
                t(calculations.profit < 0 ? "COST NEW SYSTEM" : "MONTHLY PLUS"),
              )}
              arrow
              open={shouldShowTooltip(hoverBarsKey.PROFIT_HOVER)}
            >
              <Bar
                color={chartColors.PROFIT}
                style={{
                  height: `${barHeights.profit}%`,
                  transform: calculations?.profit < 0 ? "translateY(calc(100% + 3px))" : "initial",
                }}
                barWidthsToLeave={0.5}
                labelAlign={"top"}
                hovered={hoverState.hoveredBar === hoverBarsKey.PROFIT_HOVER}
                isHoverActive={hoverState.showCalculation}
                onMouseEnter={() => setHover(hoverBarsKey.PROFIT_HOVER)}
                onMouseLeave={() => setHover("")}
                lighten={shouldLighten(hoverBarsKey.PROFIT_HOVER)}
                needNegativeBarsAlignAdjust={calculations?.profit < 0}
              >
                <div className="top-border"></div>
                <div className="bar-text">{getNumericalText(calculations.profit)} €</div>
                {
                  <div
                    className="positive-bars"
                    style={{
                      display: hoverState.showCalculation ? "block" : "none",
                    }}
                  >
                    <CustomTooltip
                      title={getTitle(
                        getNumericalText(calculations?.feedInGains),
                        t("FEED IN GAINS"),
                      )}
                      arrow
                      open={shouldShowTooltip(hoverBarsKey.FEED_IN_HOVER)}
                    >
                      <Bar
                        color={chartColors.FEED_IN}
                        style={{ height: `${barHeights.feedInGains}%` }}
                        hovered={hoverState.hoveredBar === hoverBarsKey.FEED_IN_HOVER}
                        onMouseEnter={() => setHover(hoverBarsKey.FEED_IN_HOVER)}
                        onMouseLeave={() => setHover("")}
                        lighten={shouldLighten(hoverBarsKey.FEED_IN_HOVER)}
                      ></Bar>
                    </CustomTooltip>

                    <CustomTooltip
                      title={getTitle(
                        getNumericalText(calculations?.savingsBill),
                        t("SAVING IN BILL"),
                      )}
                      arrow
                      open={shouldShowTooltip(hoverBarsKey.SAVINGS_HOVER)}
                    >
                      <Bar
                        color={chartColors.SAVINGS}
                        style={{ height: `${barHeights.savingsBill}%` }}
                        hovered={hoverState.hoveredBar === hoverBarsKey.SAVINGS_HOVER}
                        isHoverActive={hoverState.showCalculation}
                        onMouseEnter={() => setHover(hoverBarsKey.SAVINGS_HOVER)}
                        onMouseLeave={() => setHover("")}
                        lighten={shouldLighten(hoverBarsKey.SAVINGS_HOVER)}
                      ></Bar>
                    </CustomTooltip>
                    <CustomTooltip
                      title={getTitle(
                        getNumericalText(calculations?.savingsMobilityBill),
                        t("Savings - mobility bill"),
                      )}
                      arrow
                      open={shouldShowTooltip(hoverBarsKey.MOBILITY_SAVINGS_HOVER)}
                    >
                      <Bar
                        color={chartColors.MOBILITY_SAVINGS}
                        style={{ height: `${barHeights.savingsMobilityBill}%` }}
                        hovered={hoverState.hoveredBar === hoverBarsKey.MOBILITY_SAVINGS_HOVER}
                        onMouseEnter={() => setHover(hoverBarsKey.MOBILITY_SAVINGS_HOVER)}
                        onMouseLeave={() => setHover("")}
                        lighten={shouldLighten(hoverBarsKey.MOBILITY_SAVINGS_HOVER)}
                      ></Bar>
                    </CustomTooltip>

                    <CustomTooltip
                      title={getTitle(
                        getNumericalText(calculations?.savingsHeatBill),
                        t("Savings - heat bill"),
                      )}
                      arrow
                      open={shouldShowTooltip(hoverBarsKey.HEAT_SAVINGS_HOVER)}
                    >
                      <Bar
                        color={chartColors.GAS_SAVINGS}
                        style={{ height: `${barHeights.savingsHeatBill}%` }}
                        hovered={hoverState.hoveredBar === hoverBarsKey.HEAT_SAVINGS_HOVER}
                        onMouseEnter={() => setHover(hoverBarsKey.HEAT_SAVINGS_HOVER)}
                        onMouseLeave={() => setHover("")}
                        lighten={shouldLighten(hoverBarsKey.HEAT_SAVINGS_HOVER)}
                      ></Bar>
                    </CustomTooltip>

                    <div className="bottom-border-with-arrow"></div>
                  </div>
                }

                {
                  <div
                    className="negative-bars"
                    style={{
                      display: hoverState.showCalculation ? "block" : "none",
                    }}
                  >
                    <CustomTooltip
                      title={getTitle(
                        getNumericalText(-calculations?.gridPurchaseEV),
                        t("Grid electricity cost- EV"),
                      )}
                      arrow
                      open={shouldShowTooltip(hoverBarsKey.MOBILITY_GRID_HOVER)}
                    >
                      <Bar
                        color={chartColors.MOBILITY_GRID}
                        style={{ height: `${barHeights.gridPurchaseEV}%` }}
                        hovered={hoverState.hoveredBar === hoverBarsKey.MOBILITY_GRID_HOVER}
                        onMouseEnter={() => setHover(hoverBarsKey.MOBILITY_GRID_HOVER)}
                        onMouseLeave={() => setHover("")}
                        lighten={shouldLighten(hoverBarsKey.MOBILITY_GRID_HOVER)}
                      ></Bar>
                    </CustomTooltip>
                    <CustomTooltip
                      title={getTitle(
                        getNumericalText(-calculations?.gridPurchaseHeat),
                        t("Grid electricity cost- heat pump"),
                      )}
                      arrow
                      open={shouldShowTooltip(hoverBarsKey.HEAT_GRID_HOVER)}
                    >
                      <Bar
                        color={chartColors.HEAT_GRID}
                        style={{ height: `${barHeights.gridPurchaseHeat}%` }}
                        hovered={shouldShowTooltip(hoverBarsKey.HEAT_GRID_HOVER)}
                        onMouseEnter={() => setHover(hoverBarsKey.HEAT_GRID_HOVER)}
                        onMouseLeave={() => setHover("")}
                        lighten={shouldLighten(hoverBarsKey.HEAT_GRID_HOVER)}
                      ></Bar>
                    </CustomTooltip>
                    <CustomTooltip
                      title={getTitle(getNumericalText(-calculations?.leasedEV), t("Cars Cost"))}
                      arrow
                      open={shouldShowTooltip(hoverBarsKey.CARS_HOVER)}
                    >
                      <Bar
                        color={chartColors.CARS}
                        style={{ height: `${barHeights.leasedEV}%` }}
                        hovered={hoverState.hoveredBar === hoverBarsKey.CARS_HOVER}
                        onMouseEnter={() => setHover(hoverBarsKey.CARS_HOVER)}
                        onMouseLeave={() => setHover("")}
                        lighten={shouldLighten(hoverBarsKey.CARS_HOVER)}
                      ></Bar>
                    </CustomTooltip>

                    <CustomTooltip
                      title={getTitle(
                        getNumericalText(-calculations?.buildingCost),
                        !isRenting ? t("Investment / building cost") : t("Renting / rental cost"),
                      )}
                      open={shouldShowTooltip(hoverBarsKey.BUILDING_COST_HOVER)}
                      arrow
                    >
                      <Bar
                        color={chartColors.BUILDING_COST}
                        style={{ height: `${barHeights.buildingCost}%` }}
                        data-title={`${getNumericalText(-calculations?.buildingCost)} € ${
                          !isRenting ? t("Investment / building cost") : t("Renting / rental cost")
                        }`}
                        hovered={hoverState.hoveredBar === hoverBarsKey.BUILDING_COST_HOVER}
                        isHoverActive={hoverState.showCalculation}
                        onMouseEnter={(event) => setHover(hoverBarsKey.BUILDING_COST_HOVER)}
                        onMouseLeave={(event) => setHover("")}
                        lighten={shouldLighten(hoverBarsKey.BUILDING_COST_HOVER)}
                      ></Bar>
                    </CustomTooltip>

                    <CustomTooltip
                      title={getTitle(
                        getNumericalText(-calculations?.financingCost),
                        t("Financing cost"),
                      )}
                      arrow
                      open={shouldShowTooltip(hoverBarsKey.FINANCING_COST_HOVER)}
                    >
                      <Bar
                        color={chartColors.FINANCING_COST}
                        style={{ height: `${barHeights.financingCost}%` }}
                        hovered={hoverState.hoveredBar === hoverBarsKey.FINANCING_COST_HOVER}
                        isHoverActive={hoverState.showCalculation}
                        onMouseEnter={() => setHover(hoverBarsKey.FINANCING_COST_HOVER)}
                        onMouseLeave={() => setHover("")}
                        lighten={shouldLighten(hoverBarsKey.FINANCING_COST_HOVER)}
                      ></Bar>
                    </CustomTooltip>
                  </div>
                }
              </Bar>
            </CustomTooltip>
          </ChartArea>
        </Region>
        <Divider></Divider>
        <Region>
          <RegionLabel verticalAlign="top">
            <Circle backgroundColor={chartColors.LOSS}></Circle> {t("WITHOUT SOLAR SYSTEM")}
          </RegionLabel>
          <ChartArea hovered={hoverState.showCalculation} verticalAlign="top">
            <CustomTooltip
              title={getTitle(getNumericalText(-calculations?.loss), t("Loss"))}
              arrow
              open={shouldShowTooltip(hoverBarsKey.LOSS_HOVER)}
            >
              <Bar
                color={chartColors.LOSS}
                style={{ height: `${barHeights.loss}%` }}
                barWidthsToLeave={-0.5}
                labelAlign="bottom"
                hovered={hoverState.hoveredBar === hoverBarsKey.LOSS_HOVER}
                onMouseEnter={() => setHover(hoverBarsKey.LOSS_HOVER)}
                onMouseLeave={() => setHover("")}
                lighten={shouldLighten(hoverBarsKey.LOSS_HOVER)}
              >
                <div className="bar-text">{`${getNumericalText(-calculations?.loss)} €`}</div>
              </Bar>
            </CustomTooltip>
          </ChartArea>
        </Region>
      </ChartWrapper>

      <PositiveContainer>
        <SubContainer
          hovered={hoverState.hoveredBar === hoverBarsKey.FEED_IN_HOVER}
          onMouseEnter={() => setHover(hoverBarsKey.FEED_IN_HOVER)}
          onMouseLeave={() => setHover("")}
        >
          <SubContainer1>
            <Circle backgroundColor={chartColors.FEED_IN} />
            <Text1>{t("FEED IN GAINS")}</Text1>
          </SubContainer1>
          <Number1> {getNumericalText(Math.round(calculations.feedInGains))}€</Number1>
        </SubContainer>
        <SubContainer
          hovered={hoverState.hoveredBar === hoverBarsKey.SAVINGS_HOVER}
          onMouseEnter={() => setHover(hoverBarsKey.SAVINGS_HOVER)}
          onMouseLeave={() => setHover("")}
        >
          <SubContainer1>
            <Circle backgroundColor={chartColors.SAVINGS} />
            <Text1>{t("SAVING IN BILL")}</Text1>
          </SubContainer1>
          <Number1> {getNumericalText(Math.round(calculations.savingsBill))}€</Number1>
        </SubContainer>
        {cars.length !== 0 ? (
          <SubContainer
            hovered={hoverState.hoveredBar === hoverBarsKey.MOBILITY_SAVINGS_HOVER}
            onMouseEnter={() => setHover(hoverBarsKey.MOBILITY_SAVINGS_HOVER)}
            onMouseLeave={() => setHover("")}
          >
            <SubContainer1>
              <Circle backgroundColor={chartColors.MOBILITY_SAVINGS} />
              <Text1>{t("Savings - mobility bill")}</Text1>
            </SubContainer1>
            <Number1>{getNumericalText(Math.round(calculations.savingsMobilityBill))}€</Number1>
          </SubContainer>
        ) : (
          ""
        )}
        {isHeatpumpAdded ? (
          <SubContainer
            hovered={hoverState.hoveredBar === hoverBarsKey.HEAT_SAVINGS_HOVER}
            onMouseEnter={() => setHover(hoverBarsKey.HEAT_SAVINGS_HOVER)}
            onMouseLeave={() => setHover("")}
          >
            <SubContainer1>
              <Circle backgroundColor={chartColors.GAS_SAVINGS} />
              <Text1>{t("Savings - heat bill")}</Text1>
            </SubContainer1>
            <Number1>{getNumericalText(Math.round(calculations.savingsHeatBill))}€</Number1>
          </SubContainer>
        ) : (
          ""
        )}
      </PositiveContainer>
      <NegativeContainer>
        {areCarsAdded && (
          <SubContainer
            hovered={hoverState.hoveredBar === hoverBarsKey.MOBILITY_GRID_HOVER}
            onMouseEnter={() => setHover(hoverBarsKey.MOBILITY_GRID_HOVER)}
            onMouseLeave={() => setHover("")}
          >
            <SubContainer1>
              <Circle backgroundColor={chartColors.MOBILITY_GRID} />
              <Text1>{t("Grid electricity cost- EV")}</Text1>
            </SubContainer1>
            <Number1>- {formatNumber(Math.round(calculations.gridPurchaseEV))}€</Number1>
          </SubContainer>
        )}
        {isHeatpumpAdded && (
          <SubContainer
            hovered={hoverState.hoveredBar === hoverBarsKey.HEAT_GRID_HOVER}
            onMouseEnter={() => setHover(hoverBarsKey.HEAT_GRID_HOVER)}
            onMouseLeave={() => setHover("")}
          >
            <SubContainer1>
              <Circle backgroundColor={chartColors.HEAT_GRID} />
              <Text1>{t("Grid electricity cost- heat pump")}</Text1>
            </SubContainer1>
            <Number1>- {formatNumber(calculations.gridPurchaseHeat)}€</Number1>
          </SubContainer>
        )}
        <SubContainer
          hovered={hoverState.hoveredBar === hoverBarsKey.BUILDING_COST_HOVER}
          onMouseEnter={() => setHover(hoverBarsKey.BUILDING_COST_HOVER)}
          onMouseLeave={() => setHover("")}
        >
          <SubContainer1>
            <Circle backgroundColor={chartColors.BUILDING_COST} />
            <Text1>
              {!isRenting ? t("Investment / building cost") : t("Renting / rental cost")}
            </Text1>
          </SubContainer1>
          <Number1>-{formatNumber(Math.round(calculations.buildingCost))}€</Number1>
        </SubContainer>
        {isFinancing && !isRenting && (
          <SubContainer
            hovered={hoverState.hoveredBar === hoverBarsKey.FINANCING_COST_HOVER}
            onMouseEnter={() => setHover(hoverBarsKey.FINANCING_COST_HOVER)}
            onMouseLeave={() => setHover("")}
          >
            <SubContainer1>
              <Circle backgroundColor={chartColors.FINANCING_COST} />
              <Text1>{t("Financing cost")}</Text1>
            </SubContainer1>
            <Number1>-{formatNumber(Math.round(calculations.financingCost))}€</Number1>
          </SubContainer>
        )}
        {areCarsAdded &&
          cars
            .filter((c) => c.isLeasing)
            .map((car) => {
              return (
                <SubContainer
                  key={car.id}
                  hovered={hoverState.hoveredBar === hoverBarsKey.CARS_HOVER}
                  onMouseEnter={() => setHover(hoverBarsKey.CARS_HOVER)}
                  onMouseLeave={() => setHover("")}
                >
                  <SubContainer1>
                    <Circle backgroundColor={chartColors.CARS} />
                    <Text1>{car?.item?.name}</Text1>
                  </SubContainer1>
                  <Number1>
                    {getNumericalText(
                      -Math.round((car.item?.[getProductPriceKey(projectType)] as number) ?? 0),
                    )}
                    €
                  </Number1>
                </SubContainer>
              );
            })}
      </NegativeContainer>
      <div className="saprate"></div>
      <div style={{ padding: "10px", width: "100%" }}>
        <SubContainer
          onMouseEnter={() => setHover(hoverBarsKey.PROFIT_HOVER)}
          onMouseLeave={() => setHover("")}
        >
          <SubContainer1>
            <Circle backgroundColor={chartColors.PROFIT} />
            <Text2>{calculations.profit < 0 ? t("COST NEW SYSTEM") : t("MONTHLY PLUS")}</Text2>
          </SubContainer1>
          <Number2> {`${formatNumber(calculations.profit)} €`}</Number2>
        </SubContainer>
      </div>
      <Number3 style={{ width: "100%", textAlign: "left" }}>{`* ${t("prices are netto")}`}</Number3>
    </MainContainer>
  );
};

export default MonthlyElectricityCost;
