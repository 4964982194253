export const transformForURL = (addr: string): string => {
  return addr.replaceAll(" ", "-").replaceAll(",", "");
};

export const fromURLToMapboxQuery = (addr: string): string => {
  return addr.replaceAll("-", " ").replaceAll("/", " ");
};

interface IsURLAndQuoteAddrSameProps {
  url: string;
  quoteAddr: string;
}

export const getQuoteAddrFromURL = (url: string): string => {
  const urlAddr = url.split("default-")[1];
  const urlAddrTransformed = decodeURI(urlAddr);
  return urlAddrTransformed;
};

export const isURLAndQuoteAddrSame = (props: IsURLAndQuoteAddrSameProps): boolean => {
  const { quoteAddr, url } = props;

  const urlAddrTransformed = getQuoteAddrFromURL(url);
  const quoteAddrTransformed = transformForURL(quoteAddr);

  return urlAddrTransformed === quoteAddrTransformed;
};

export const getShortIdAndVersionFromURL = (
  url: string,
  separator: string = "saved-",
): { shortId: number; version: number } => {
  const data = url.split(separator)[1];

  return {
    shortId: parseInt(data.split("/")[0]),
    version: parseInt(data.split("/")[1]),
  };
};

export const getDataFromViewEditURL = (
  url: string,
): { shortId: number; version: number; secret: string } => {
  const data = url.split("view-and-edit/")[1];

  const [secret, shortId, version] = data.split("/");

  return {
    secret,
    shortId: parseInt(shortId),
    version: parseInt(version),
  };
};
