import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// import { returnIsPathIsThere } from "src/components/popups/consumption/helperData";

import { useSelector } from "react-redux";

import { useWindowDimensions } from "src/hooks";

import { BackButton } from "src/assets/svgs";
import { canShowBackBtn } from "src/utils";

import { NavItemKey } from "../../constants/navItemKey";

import { VersionMenu } from "./components";

import { selectors as UserSelectors } from "src/redux/user";

interface MiddleNavProps {
  menuButtonClickHandler: Function;
}

const MiddleNav: React.FC<MiddleNavProps> = (props) => {
  const { menuButtonClickHandler } = props;

  const { pathname } = location;
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const isAuthLoading = useSelector(UserSelectors.getIsAuthLoading);

  const [showVersionMenu, setShowVersionMenu] = useState(false);

  useEffect(() => {
    const isSavedProjURL = pathname.includes("saved");
    setShowVersionMenu(isSavedProjURL);
  }, [pathname]);

  if (isAuthLoading) return <></>;

  const shouldShowBackBtn = canShowBackBtn(location.pathname) && width > 500;

  return (
    <div className="secound">
      {showVersionMenu && <VersionMenu />}
      {shouldShowBackBtn && (
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
          }}
          className="back-to-project-button"
          onClick={() => menuButtonClickHandler(NavItemKey.BACK_TO_PROJECT)}
        >
          <BackButton style={{ marginRight: "6px" }} />
          <div style={{ fontSize: "15px" }} className="text">
            {t("Back to the project")}
          </div>
        </div>
      )}
    </div>
  );
};

export default MiddleNav;
