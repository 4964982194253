import { useCallback, useState } from "react";

import { ElectricityConsumptionInputLine, ElectricityConsumptionEditor } from "./components";
import { useCalculatedConsumption } from "./hooks";

const BasicElectricityConsumption: React.FC = () => {
  const [openYearlyEditor, setOpenYearlyEditor] = useState<Boolean>(false);

  const closeEditor = useCallback(() => setOpenYearlyEditor(false), []);
  const openEditor = useCallback(() => setOpenYearlyEditor(true), []);

  useCalculatedConsumption();

  return (
    <>
      <ElectricityConsumptionInputLine openYearlyEditorCallback={openEditor} />
      {openYearlyEditor && <ElectricityConsumptionEditor onCloseBtnClick={closeEditor} />}
    </>
  );
};

export default BasicElectricityConsumption;
