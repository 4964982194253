import { ProductDB, ProductWithPrice } from "src/db-types";
import { getNumber } from "src/utils";

export const sortProductsByMaxCapacity = (products: ProductDB[]): ProductDB[] => {
  return products.slice().sort((a, b) => {
    return getNumber(a.applicableMaxCapacity) - getNumber(b.applicableMaxCapacity);
  });
};

export const sortProductByPrice = (products: ProductWithPrice[]): ProductWithPrice[] => {
  return products.slice().sort((a, b) => {
    return a.buyInfo.totalPrice - b.buyInfo.totalPrice;
  });
};
