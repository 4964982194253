
import { Button } from "antd";
import styled from "styled-components";

export const FilterContainer = styled.div`
  min-width: 17rem;
  background-color: white;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

