import {
  Home,
  Light,
  Polygon,
  ZoomIn,
  FullScreenIcon,
  ZoomOut,
  HomeYellow,
  PlanetYellow,
  HandWhite,
  MesureYellow,
  PopupWhite,
  PopupYellow,
  MaxWidth,
  SolarPanel,
  SolarPanelYellow,
  ScreenShotIcon,UndoIcon,
  CabelingIcon,
  CabelingYellowIcon
} from "src/assets/step33dViwer/index";

export const panelIcons = [
  {
    key: "home",
    image: Home,
    msg: "Initial View",
    hoverImage: HomeYellow,
  },
  {
    key: "planet",
    image: PlanetYellow,
    msg: "Planet",
    hoverImage: PlanetYellow,
  },
  // {
  //   key: "drone",
  //   image: Drone,
  //   msg: "Coming Soon",
  //   hoverImage: DroneYellow,
  // },
  {
    key: "light",
    image: HandWhite,
    msg: "Hand View",
    hoverImage: Light,
  },
  {
    key: "polygon",
    image: Polygon,
    msg: "Coming Soon",
    hoverImage: MesureYellow,
  },
  {
    key: "solarpanel",
    image: SolarPanel,
    msg: "solarpanel",
    hoverImage: SolarPanelYellow,
  },
  {
    key: "cabeling",
    image: CabelingIcon,
    msg: "cabeling",
    hoverImage: CabelingYellowIcon,
  },
  {
    key: "popup",
    image: PopupWhite,
    msg: "Coming Soon",
    hoverImage: PopupYellow,
  },
  {
    key: "screenshot",
    image: ScreenShotIcon,
    msg: "Coming Soon",
    hoverImage: ScreenShotIcon,
  },
];

export const rightPanelIcons = [
  {
    key: "FullScreenIcon",
    image: FullScreenIcon,
    msg: "FullScreenIcon",
  },
  {
    key: "MaxWidth",
    image: MaxWidth,
    msg: "MaxWidth",
  },
  {
    key: "ZoomIn",
    image: ZoomIn,
    msg: "ZoomIn",
  },
  {
    key: "ZoomOut",
    image: ZoomOut,
    msg: "ZoomOut",
  },

  {
    key: "Undo",
    image: UndoIcon,
    msg: "Undo",
  },
];
