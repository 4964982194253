import Slider from "react-slick";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { BackendApis } from "src/api";

import { InstallerOfferDB } from "src/db-types";
import { useUpdateInstallerAPI } from "src/hooks/apis";
import Finance from "src/pages/ApplicationPage/shared/Finance/Finance";
import * as S from "./ApplicationStep4.styles";
import { Installers } from "./components";

import { ProjectSelectors, UserSelectors } from "src/redux/selectors";
import Offers from "./components/Offers/Offers";
import OfferAccepcted from "./components/Offers/OfferAccepcted/OfferAccepcted";
import { useAppDispatch, useURLPath } from "src/hooks";
import { PopupActions, ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { toast } from "react-toastify";

export const ApplicationStep4: React.FC = () => {
  const { t } = useTranslation();
  const selectedInstallers = useSelector(ProjectSelectors.getSelectedInstaller);
  const [offerView, setOfferView] = useState(false);
  const [successView, setSuccessView] = useState(false);
  const [acceptedOffer, setAcceptedOffer] = useState<InstallerOfferDB | undefined>(undefined);
  const { fetchData: updateInstallerApi, response, error } = useUpdateInstallerAPI();
  const dispatch = useAppDispatch();
  const installersOffers = useMemo(() => {
    return selectedInstallers?.filter(
      (offer: InstallerOfferDB) => offer.pdfUrl && offer.pdfUrl !== "",
    );
  }, [selectedInstallers]);
  const installerDetails = useSelector(UserSelectors?.getInstaller);

  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    let hasOfferAccepted = false;
    let hasPdfUrl = false;

    selectedInstallers.forEach((installer) => {
      if (installer.offerAccepted === true) {
        hasOfferAccepted = true;
        setAcceptedOffer(installer);
      }
      if (installer.pdfUrl) {
        hasPdfUrl = true;
      }
    });

    if (hasOfferAccepted) {
      setSuccessView(true);
    } else if (hasPdfUrl) {
      setOfferView(true);
    }
  }, [selectedInstallers]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current?.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current?.slickPrev();
    }
  };

  const handleAcceptOffer = async ({ id }: { id: string }) => {
    void updateInstallerApi({
      ...BackendApis.updateInstaller({
        id,
      }),
      data: { offerAccepted: true },
    });
  };

  const handleRejectOffer = ({ id, reason }: { id: string; reason: string }) => {
    void updateInstallerApi({
      ...BackendApis.updateInstaller({
        id,
      }),
      data: { offerAccepted: false, offerRejected: true, reason },
    });
    setSuccessView(false);
    setOfferView(true);
  };

  useEffect(() => {
    const message = error?.response?.data;

    if (message) {
      toast.error(`${message}`);
    }
  }, [error]);

  useEffect(() => {
    if (!response) return;

    const { data } = response;

    console.log(data?.data, "data?.data");

    if (data?.data?.offerRejected) {
      toast.success("Offer Rejected Successfully");
    }

    if (data?.data?.offerAccepted) {
      toast.success("Offer Accepted Successfully");
      dispatch(ProjectActions.updateSelectedInstaller({ installerId: data?.data?._id }));
      const acceptedOffer = selectedInstallers.find(
        (installer) => installer._id === data?.data?._id,
      );
      setAcceptedOffer(acceptedOffer);
      setSuccessView(true);
      setOfferView(false);
    }

    dispatch(PopupActions.closePopup(POPUP_KEYS.offer_feedback));
  }, [response]);

  return (
    <S.MainContainer>
      {/* {project?.vendor?.suggested?.length > 0 && !isOfferAccepted ? (
        <Installers
          setIsOfferAccepted={setIsOfferAccepted}
          isOfferAccepted={isOfferAccepted}
          offerAcceptedData={offerAcceptedData}
          setOfferAcceptedData={setOfferAcceptedData}
        />
      ) : (
        project?.vendor?.suggested?.length > 0 && (
          <FinalScreen offerAcceptedData={offerAcceptedData} />
        )
      )} */}
      {offerView ? (
        <S.SliderContainer>
          <Slider {...settings} ref={sliderRef}>
            {installersOffers?.map((offer: InstallerOfferDB) => (
              <S.OfferSection key={offer._id}>
                <Offers
                  offer={offer}
                  handleAcceptOffer={handleAcceptOffer}
                  handleRejectOffer={handleRejectOffer}
                />
                {!installerDetails && (
                  <S.StyledButton onClick={() => setOfferView(false)}>
                    {t("Request another Installer")}
                  </S.StyledButton>
                )}
              </S.OfferSection>
            ))}
          </Slider>
          <S.ArrowButton onClick={handlePrev}>
            <ArrowBackIos />
          </S.ArrowButton>
          <S.ArrowButton right onClick={handleNext}>
            <ArrowForwardIos />
          </S.ArrowButton>
        </S.SliderContainer>
      ) : successView && acceptedOffer ? (
        <OfferAccepcted acceptedOffer={acceptedOffer} />
      ) : (
        <>
          <Installers />
          <S.FinanceSection>
            <Finance />
            {installersOffers?.length > 0 && (
              <S.StyledButton onClick={() => setOfferView(true)}>
                {t("See All Offers")}
              </S.StyledButton>
            )}
          </S.FinanceSection>
        </>
      )}
    </S.MainContainer>
  );
};
