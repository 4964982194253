import { Button, CircularProgress, Container, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components/macro";

import { BackendApis } from "src/api";

import { useForgotPasswordAPI } from "src/hooks/apis";
import { colors, getErrorMessage } from "src/utils";

import { AUTH_MODES, AuthPopupProps } from "../types";

import { styles } from "./ForgotPassword.styles";

import { POPUP_KEYS, actionCreators as PopupActions } from "src/redux/popups";

const ForgotPasswordWrapper = styled("div")`
  width: 100%;
  .loginContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem !important;
    margin-inline: 2rem;
    align-self: stretch;
  }
  .loginButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 350px;
    height: 44px;
    background: var(--primary-color);
    border-radius: 5px;
    /* font-family: "Roboto"; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: var(--blue);
    cursor: pointer;
    margin-top: 20px;
    :hover {
      background: var(--primary-color);
    }
  }
`;

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = styles();

  const { fetchData, response, error, loading } = useForgotPasswordAPI();

  const [email, setEmail] = useState("");

  const forgotBtnClickHandler = (): void => {
    if (email === "") {
      toast.error(t("Please enter your Email"));
      return;
    }

    void fetchData({
      ...BackendApis.forgotPassword,
      data: {
        email,
      },
    });
    // var actionCodeSettings = {
    //   url: window.location.origin,
    //   handleCodeInApp: true,
    // };
    // firebase
    //   .auth()
    //   .sendPasswordResetEmail(email, actionCodeSettings)
    //   .then((result) => {
    //     toast.success(`${t("Email message")}`);
    //     dispatch(closePopup(POPUPS.AUTH_POPUP));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  useEffect(() => {
    if (!error) return;

    const message = getErrorMessage(error);
    toast.error(t(message));
  }, [error, t]);

  useEffect(() => {
    if (!response) return;

    toast.success(`${t("Email message")}`);
    dispatch(PopupActions.closePopup(POPUP_KEYS.auth));
  }, [dispatch, response, t]);

  return (
    <ForgotPasswordWrapper>
      <Container maxWidth="xs">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3 className={classes.accountH1}>
            <span>{t("Enter your Registered Email")}</span>
          </h3>
        </div>
        <div style={{ color: "#fff" }} className="loginContainer">
          <TextField
            fullWidth
            id="email-form-field"
            label="Email"
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            className="loginButton"
            onClick={forgotBtnClickHandler}
            disabled={loading}
          >
            {t("Forget Password")}
            {loading && <CircularProgress size={20} className="circular-progress-custom" />}
          </Button>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3 className={classes.accountH1}>
            <span>{t("Have an Account?")}</span>{" "}
            <span
              style={{
                color: colors.ctaSolid,
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                const data: AuthPopupProps = {
                  authMode: AUTH_MODES.login,
                };

                dispatch(PopupActions.openPopup(POPUP_KEYS.auth, data));
              }}
            >
              {t("Login Now")}
            </span>
          </h3>
        </div>
      </Container>
    </ForgotPasswordWrapper>
  );
};

export default ForgotPassword;
