import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ProjectSelectors, UserSelectors } from "src/redux/selectors";

const useAddressWithCountryReplacement = (): string => {
  const [addressText, setAddressText] = useState("");

  const mapboxAddr = useSelector(ProjectSelectors.getAddress);
  const languageCode = useSelector(UserSelectors.getLangCode);

  useEffect(() => {
    const address = mapboxAddr.complete;
    const needReplacement = languageCode !== "en";

    if (needReplacement) setAddressText(address.replace("Germany", "Deutschland"));
    else setAddressText(address);
  }, [languageCode, mapboxAddr.complete]);

  return addressText;
};

export default useAddressWithCountryReplacement;
