import { actionCreators, ProjectState } from "src/redux/project";

export const removeSelectedInstaller = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.removeSelectedInstaller>,
): ProjectState => {
  return {
    ...currentState,
    selectedInstallers: currentState.selectedInstallers.filter((p) => p.iid !== payload.id),
  };
};
