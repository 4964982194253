import { isEqual } from "lodash";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { BackendApis } from "src/api";

import { LastLogDB } from "src/db-types";

import { useLastLogApi } from "./apis";

import { AppActions } from "src/redux";
import { UserActions } from "src/redux/actionCreators";
import { AppSelectors, UserSelectors } from "src/redux/selectors";

import { useAppDispatch, useAppSelector, useNaivgateAbsolute, useURLData, useURLPath } from ".";

export const isValidPidPath = (pidPart: string): boolean => {
  const [shortId, version] = pidPart.split("/");
  if (isNaN(parseInt(shortId)) || isNaN(parseInt(version))) return false;
  return true;
};

export const useLastLog = (): void => {
  const navigate = useNaivgateAbsolute();
  const dispatch = useAppDispatch();
  const [query] = useSearchParams();
  const installerPartnerSlug = query.get("partner");
  const user = useAppSelector(UserSelectors.getUser);
  const { shouldSaveLastLog } = useAppSelector(AppSelectors.getState);

  const {
    isOnUserOverview,
    isOnDefault,
    isOnSaved,
    isOnView,
    isOnPdf,
    isOnViewAndEdit,
    defaultURLData,
    savedURLData,
  } = useURLData();
  const { fetchData: lastLogApiCall, response: lastLogApiResponse, loading } = useLastLogApi();

  useEffect(() => {
    if (lastLogApiResponse) {
      const {
        data: { data: userDB },
      } = lastLogApiResponse;
      dispatch(UserActions.updateLastLog(userDB.lastLog));
    }
  }, [dispatch, lastLogApiResponse]);

  useEffect(() => {
    dispatch(
      AppActions.updateAppState({
        apiStates: {
          isUpdateLastLogApiLoading: loading,
        },
      }),
    );
  }, [dispatch, loading]);

  useEffect(() => {
    if (isOnDefault) return;
    if (isOnView) return;
    if (isOnSaved) return;
    if (isOnViewAndEdit) return;
    if (isOnUserOverview) return;
    if (isOnPdf) return;

    if (!user) return;

    const { address, pid } = user?.lastLog;
    const isValidPid = pid && isValidPidPath(pid);
    const isValidAddress = address && address !== "";
    if (isValidPid) {
      if (installerPartnerSlug) {
        navigate(`/application/saved-${pid}?partner=${installerPartnerSlug}`);
      } else {
        navigate(`/application/saved-${pid}`);
      }
    } else if (isValidAddress) {
      navigate(`/application/default-${address}`);
    }
  }, [isOnDefault, isOnSaved, isOnUserOverview, isOnView, isOnViewAndEdit, navigate, user]);

  useEffect(() => {
    const canSaveLastLog = shouldSaveLastLog && user && (isOnDefault || isOnSaved);
    if (!canSaveLastLog) return;

    const newPid = isOnSaved ? `${savedURLData.shortId}/${savedURLData.version}` : user.lastLog.pid;
    const newAddr = isOnDefault ? defaultURLData.addr : user.lastLog.address;

    const newLastLog: LastLogDB = {
      pid: newPid,
      address: newAddr,
    };

    if (isEqual(newLastLog, user.lastLog)) {
      return;
    }

    void lastLogApiCall({
      ...BackendApis.updateLastLog,
      data: newLastLog,
    });

    if (shouldSaveLastLog) {
      dispatch(
        AppActions.updateAppState({
          shouldSaveLastLog: false,
        }),
      );
    }
  }, [
    defaultURLData.addr,
    dispatch,
    isOnDefault,
    isOnSaved,
    isOnView,
    isOnViewAndEdit,
    lastLogApiCall,
    navigate,
    savedURLData.shortId,
    savedURLData.version,
    shouldSaveLastLog,
    user,
  ]);
};
