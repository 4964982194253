import { Select, Tabs } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useWindowDimensions } from "src/hooks";

import { PopupSkeleton } from "src/shared/components";

import { ChartTabsWrapper } from "./ChartTabsStyles";
import HomeEnergy from "./HomeEnergy/HomeEnergy";
import Investment from "./Investment/Investment";
import MonthlyBalance from "./MonthlyBalance/MonthlyBalance";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
const { Option } = Select;

const ChartTabs: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const [showMobileComponent, setShowMobileComponent] = useState(<HomeEnergy />);
  const components = [
    <HomeEnergy key="home-energy" />,
    <MonthlyBalance key="montly-balance" />,
    <Investment key="investment" />,
    // <CO2Charts />,
  ];

  const handleClose = (): void => {
    const action = PopupActions.closePopup(POPUP_KEYS.consumption_charts);
    dispatch(action);
  };

  const onChange = (value: any): void => {
    setShowMobileComponent(components[value]);
  };

  return (
    <PopupSkeleton handleClose={handleClose} open={true}>
      <ChartTabsWrapper>
        {width > 500 ? (
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={t("Home-energy")} key="1">
              <HomeEnergy />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Monthly balance")} key="2">
              <MonthlyBalance />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Investment")} key="3">
              <Investment />
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab={t("CO2-Savings")} key="4">
              <CO2Charts />
            </Tabs.TabPane> */}
          </Tabs>
        ) : (
          <>
            <Select
              showSearch
              optionFilterProp="children"
              onChange={onChange}
              defaultValue={0}
              filterOption={(input, option) =>
                (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option value={0}>{t("Home-energy")}</Option>
              <Option value={1}>{t("Monthly balance")}</Option>
              <Option value={2}>{t("Investment")}</Option>
              {/* <Option value={3}>{t("CO2-Savings")}</Option> */}
            </Select>
            {showMobileComponent}
          </>
        )}
      </ChartTabsWrapper>
    </PopupSkeleton>
  );
};

export default ChartTabs;
