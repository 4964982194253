import { WebConstant } from "../types/project-constants";

export const webConstants: WebConstant[] = [
  {
    title: "SolarHub Margin",
    priceB2c: "10",
    type: "percentage of project cost",
    priceB2b: "10",
  },
  {
    type: "Fix for project",
    priceB2b: "1000",
    priceB2c: "1000",
    title: "Construction stage",
  },
  {
    title: "Documentation",
    priceB2c: "250",
    priceB2b: "200",
    type: "Fix for project",
  },
  {
    priceB2c: "280",
    type: "Fix for project",
    priceB2b: "114",
    title: "Overvoltage protector DC Typ I+II, 2 MPPT",
  },
  {
    priceB2c: "180",
    title: "registration with grid operator",
    type: "Fix for project",
    priceB2b: "180",
  },
  {
    title: "on-site-visit",
    type: "Fix for project",
    priceB2c: "250",
    priceB2b: "210",
  },
  {
    priceB2c: "32",
    priceB2b: "32",
    title: "Racking System for gable roof",
    type: "Fix per panel",
  },
  {
    priceB2c: "25",
    title: "Roof hook/ cross hook",
    type: "Fix per panel",
    priceB2b: "25",
  },
  {
    priceB2c: "1",
    type: "percentage of project cost",
    priceB2b: "1",
    title: "5 year warranty - 1% of project fee",
  },
  {
    title: "Current electricity price",
    priceB2b: "0.25",
    type: "Fix per panel",
    priceB2c: "0.3",
  },
  {
    priceB2b: "162",
    type: "Fix for project",
    title: "Delivery",
    priceB2c: "65",
  },
  {
    title: "electro installation (incl. material)",
    type: "Fix for project",
    priceB2b: "1000",
    priceB2c: "1500",
  },
  {
    priceB2c: "0.0595",
    type: "Fix for project",
    priceB2b: "0.0595",
    title: "Feed-in-tariff",
  },
  {
    priceB2b: "65",
    title: "Installation",
    type: "Fix per panel",
    priceB2c: "65",
  },
  {
    type: "Fix for project",
    priceB2c: "600",
    priceB2b: "550",
    title: "SMA Sunny Home Manager 2.0 (inkl. Energy Meter)",
  },
  {
    type: "Fix for project",
    title: "Setup data visualizer SMA Sunny Portal",
    priceB2b: "97",
    priceB2c: "120",
  },
];
