import { clone, cloneDeep } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const addCar = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addCar>,
): ProjectState => {
  const newCar = cloneDeep(payload);
  const carsArray = clone(currentState.components.cars);
  carsArray.push(newCar);

  return {
    ...currentState,
    components: {
      ...currentState.components,
      cars: carsArray,
    },
  };
};
