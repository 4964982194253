import styled from "styled-components/macro";

export const PaymentWrapper = styled("div")`
  height: 100%;
  width: 100%;

  .mainContainer {
    padding-top: 5%;
    padding-inline: 5%;
    border-radius: 16px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 600px) {
      padding-inline: 5%;
    }
  }
  .part_left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .work {
      height: 115px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 600px) {
      width: 100%;
      height: 100%;
    }
  }
  .part_right {
    width: 50%;
    @media (max-width: 600px) {
      width: 100%;
      height: 100%;
    }
  }
`;
