import { clone } from "lodash";

import { defaultComponent } from "../../default-values";

import { actionCreators, ProjectState } from "src/redux/project";

export const deleteAdditionalHardware = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.deleteAdditionalHardware>,
): ProjectState => {
  const solarPanel = clone(defaultComponent);

  return {
    ...currentState,
    components: {
      ...currentState.components,
      solarPanel,
    },
  };
};
