import { clone } from "lodash";

import { UserState, actionCreators } from "src/redux/user";

export const setFilters = (
  currentState: UserState,
  { payload }: ReturnType<typeof actionCreators.setFilters>,
): UserState => {
  const newState = clone(currentState);

  newState.filters = payload;
  return newState;
};
