import React from "react";
import { Outlet } from "react-router-dom";

import { NavBar } from "./components";

const AppPage: React.FC = () => {
  return (
    <>
      <NavBar></NavBar>
      <Outlet></Outlet>
    </>
  );
};

export default AppPage;
