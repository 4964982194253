import styled from "styled-components/macro";
export const PaymenWrapper = styled("div")`
  .PaymentFourmMain {
    margin-right: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 600px) {
      margin-right: 0px;
      margin-top: 30px;
    }
  }
  .makeCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    margin-bottom: 10px;
    @media (max-width: 600px) {
      font-size: 11px;
    }
  }
`;
