import { Dialog } from "@mui/material";
import _, { cloneDeep, update } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ProductDB } from "src/db-types";
import { CloseButton } from "src/shared/components";

import CarHeader from "./CarHeader";
import { CarSelectorWrapper } from "./CarSelectorStyles";
import { CarList } from "./components/CarList/CarList";

import { PopupActions } from "src/redux";
import { ProjectActions } from "src/redux/actionCreators";
import { PopupSelectors, ProjectSelectors } from "src/redux/selectors";

export interface CarSelectorProps {
  isAdd?: boolean;
  addSettings?: {
    isMultipleAdd: boolean;
    idToAddAt?: string;
  };
  isChange?: boolean;
}

const CarSelector: React.FC = () => {
  const dispatch = useDispatch();

  const props = useSelector(PopupSelectors.getCarSelectorPopup);
  const {
    data: { addSettings, isAdd, isChange },
    open,
  } = props;

  const cars = useSelector(ProjectSelectors.getCars);
  const [addCarCount, setAddCarCount] = useState(-1);
  const [addCarSelected, setAddCarSelected] = useState<
    Array<{ car: ProductDB; isLeasing: boolean }>
  >([]);

  const addSelectedCarsToState = useCallback(() => {
    const addableCars = cars.filter((car) => Object.keys(car.item ?? {}).length === 0);

    addableCars.forEach((car, index) => {
      if (!addCarSelected[index]) return;

      const carDB = addCarSelected[index].car;
      dispatch(
        ProjectActions.updateCar({
          car: {
            item: cloneDeep(carDB),
            isLeasing: addCarSelected[index].isLeasing,
            configuration: {
              consumptionPer100Km: carDB.electricCarConsumption ?? 0,
            },
          },
          id: car.id,
        }),
      );
    });
  }, [addCarSelected, cars, dispatch]);

  const addCarToId = useCallback(() => {
    if (addCarSelected.length <= 0) return;
    if (!addSettings?.idToAddAt) return;

    const carDB = addCarSelected[0].car;
    dispatch(
      ProjectActions.updateCar({
        car: {
          item: cloneDeep(carDB),
          isLeasing: addCarSelected[0].isLeasing,
          configuration: {
            consumptionPer100Km: carDB.electricCarConsumption ?? 0,
          },
        },
        id: addSettings?.idToAddAt,
      }),
    );
  }, [addCarSelected, addSettings?.idToAddAt, dispatch]);

  const handleClose = useCallback(() => {
    if (isAdd && addSettings?.isMultipleAdd) {
      addSelectedCarsToState();
    }

    dispatch(PopupActions.closeCarSelector());
  }, [addSelectedCarsToState, addSettings?.isMultipleAdd, dispatch, isAdd]);

  useEffect(() => {
    if (addCarCount !== -1) return;
    if (!isAdd) return;
    if (Object.keys(addSettings ?? {}).length <= 0) return;

    if (addSettings?.isMultipleAdd) {
      const addableCarCount = cars.filter((c) => Object.keys(c.item ?? {}).length === 0).length;
      setAddCarCount(addableCarCount);
    } else {
      setAddCarCount(1);
    }
  }, [addCarCount, addSettings, cars, isAdd]);

  useEffect(() => {
    if (!isAdd) return;
    if (!addSettings?.idToAddAt) return;
    if (addCarSelected.length <= 0) return;

    addCarToId();
    handleClose();
  }, [addCarSelected.length, addCarToId, addSettings?.idToAddAt, handleClose, isAdd]);

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={false} style={{ margin: "-15px" }}>
      <CarSelectorWrapper>
        <div className="product_main">
          <div className="header">
            <CarHeader addCarCount={addCarCount} />
            <CloseButton onClick={handleClose} />
          </div>
          {isAdd && (
            <CarList
              carCount={addCarCount}
              isListOpen={true}
              onSelectionChange={setAddCarSelected}
              notShownCarIds={[addSettings?.idToAddAt ?? ""]}
            />
          )}
        </div>
      </CarSelectorWrapper>
    </Dialog>
  );
};

export default CarSelector;
