import styled from "styled-components";

export const OperationContainer = styled.div`
  padding: 30px 20px 30px 20px;
  gap: 14px;
  border-radius: 6px;
  opacity: 0px;
  max-width: 1000px;
  box-shadow: 1px 1px 10px 0px #2d476480;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
`;

export const OperationCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const OperationTitle = styled.div`
  border-bottom: 4px solid #ffbf1b;
  padding-bottom: 10px;
  width: 100%;
`;