import { Button } from "@mui/material";
import { Switch } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAppDispatch } from "src/hooks";

import { AddNewIcon, GoogleMapIcon } from "src/assets/svgs";
import { useSaveProjectApi } from "src/hooks/apis";

import { HeaderWrapper } from "./Header.styles";

import { PopupActions } from "src/redux";
import { MapboxAddress } from "src/redux/project";
import { Tune } from "@mui/icons-material";

interface IHeader {
  showProjects: boolean;
  setShowProjects: (f: boolean) => void;
  showMapView: boolean;
  setShowMapView: (f: boolean) => void;
  onAddDummyProjectClick: () => void;
  onProjectSave: (addr: MapboxAddress) => void;
  setshowfilters: (f: boolean) => void;
  showfilters: boolean;
}
export const Header: React.FC<IHeader> = (props: IHeader) => {
  const {
    showProjects,
    setShowMapView,
    setShowProjects,
    showMapView,
    onAddDummyProjectClick,
    onProjectSave,
    setshowfilters,
    showfilters,
  } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <HeaderWrapper>
      <div className={"header_main"}>
        <div className="left_side">{t("Overview of your energy configurations")}</div>
        <div className="right_side">
          <Button
            startIcon={<AddNewIcon />}
            className="add_config"
            onClick={onAddDummyProjectClick}
          >
            {t("Add configuration")}
          </Button>
          <div className="sideByside">
            <div className="ted">
              <Switch
                style={{
                  background: showProjects ? "#FFD75D" : "#1B4963",
                  marginRight: "7px",
                }}
                checked={showProjects}
                onChange={() => setShowProjects(!showProjects)}
              />
              {showProjects ? t("saved projects") : t("Overview")}
            </div>
          </div>
          <Button
            startIcon={<AddNewIcon />}
            className="add_config"
            onClick={() => {
              dispatch(
                PopupActions.openAddressPopup({
                  onAddressSelect: onProjectSave,
                }),
              );
            }}
          >
            {t("Add project")}
          </Button>
          <Button
            startIcon={<Tune />}
            className="add_config"
            onClick={() => {
              setshowfilters(!showfilters);
            }}
          >
            {t("Filter")}
          </Button>

          {/* <Button
            startIcon={<GoogleMapIcon />}
            className="map_view"
            onClick={() => setShowMapView(!showMapView)}
          >
            {showMapView ? t("Hide map") : t("map View")}
          </Button> */}
        </div>
      </div>
    </HeaderWrapper>
  );
};
