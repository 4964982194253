import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Finance from "../../shared/Finance/Finance";

import * as S from "./ApplicationStep2.styles";
import { ProductCards } from "./components";

export const ApplicationStep2: React.FC = () => {
  useEffect(() => {
    // TODO : access link check
    // if (user === null && !location.pathname.includes("custom")) {
    //   dispatch(openPopup(POPUPS.AUTH_POPUP));
    //   dispatch(
    //     setPopupData(POPUPS.AUTH_POPUP, {
    //       authMode: AUTH_MODES.LOG_IN,
    //     })
    //   );
    // }
  }, []);

  return (
    <S.MainContainer>
      <ProductCards />
      <Finance />
    </S.MainContainer>
  );
};
