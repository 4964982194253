import { clone, merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateWallbox = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateWallbox>,
): ProjectState => {
  const wallbox = clone(currentState.components.wallbox);
  const newWallbox = merge({}, wallbox, payload);

  return {
    ...currentState,
    components: {
      ...currentState.components,
      wallbox: newWallbox,
    },
  };
};
