import { ProjectResponseDB } from "src/db-types/project";

import * as actionTypes from "./action.names";

import { ReduxAction } from "src/redux/common.types";

export const setAllProjects = (data: ProjectResponseDB[]): ReduxAction<ProjectResponseDB[]> => {
  return {
    payload: data,
    type: actionTypes.SET_ALL_PROJECTS,
  };
};

export const setAllVersions = (
  data: Record<number, ProjectResponseDB[]>,
): ReduxAction<Record<number, ProjectResponseDB[]>> => {
  return {
    payload: data,
    type: actionTypes.SET_VERSIONS,
  };
};

interface UpdateVersionByIDProps {
  shortId: number;
  data: ProjectResponseDB;
}
export const updateVersionByID = ({
  data,
  shortId,
}: UpdateVersionByIDProps): ReduxAction<UpdateVersionByIDProps> => {
  return {
    payload: {
      shortId,
      data,
    },
    type: actionTypes.UPDATE_VERSIONS_BY_ID,
  };
};
