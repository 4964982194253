export const ROOF_PITCH_FACTORS: Array<{ angle: number; factor: number }> = [
  { angle: 0.0, factor: 0.865 },
  { angle: 1.0, factor: 0.87 },
  { angle: 2.0, factor: 0.87 },
  { angle: 3.0, factor: 0.875 },
  { angle: 4.0, factor: 0.88 },
  { angle: 5.0, factor: 0.88 },
  { angle: 6.0, factor: 0.89 },
  { angle: 7.0, factor: 0.9 },
  { angle: 8.0, factor: 0.91 },
  { angle: 9.0, factor: 0.92 },
  { angle: 10.0, factor: 0.929 },
  { angle: 11.0, factor: 0.93 },
  { angle: 12.0, factor: 0.935 },
  { angle: 13.0, factor: 0.94 },
  { angle: 14.0, factor: 0.95 },
  { angle: 15.0, factor: 0.953 },
  { angle: 16.0, factor: 0.96 },
  { angle: 17.0, factor: 0.97 },
  { angle: 18.0, factor: 0.97 },
  { angle: 19.0, factor: 0.97 },
  { angle: 20.0, factor: 0.973 },
  { angle: 21.0, factor: 0.975 },
  { angle: 22.0, factor: 0.98 },
  { angle: 23.0, factor: 0.985 },
  { angle: 24.0, factor: 0.985 },
  { angle: 25.0, factor: 0.987 },
  { angle: 26.0, factor: 0.99 },
  { angle: 27.0, factor: 0.99 },
  { angle: 28.0, factor: 0.995 },
  { angle: 29.0, factor: 0.995 },
  { angle: 30.0, factor: 0.996 },
  { angle: 31.0, factor: 1.0 },
  { angle: 32.0, factor: 1.0 },
  { angle: 33.0, factor: 1.0 },
  { angle: 34.0, factor: 1.0 },
  { angle: 35.0, factor: 1.0 },
  { angle: 36.0, factor: 1.0 },
  { angle: 37.0, factor: 1.0 },
  { angle: 38.0, factor: 1.0 },
  { angle: 39.0, factor: 1.0 },
  { angle: 40.0, factor: 1.0 },
  { angle: 41.0, factor: 0.99 },
  { angle: 42.0, factor: 0.99 },
  { angle: 43.0, factor: 0.99 },
  { angle: 44.0, factor: 0.99 },
  { angle: 45.0, factor: 0.99 },
  { angle: 46.0, factor: 0.99 },
  { angle: 47.0, factor: 0.98 },
  { angle: 48.0, factor: 0.98 },
  { angle: 49.0, factor: 0.98 },
  { angle: 50.0, factor: 0.98 },
  { angle: 51.0, factor: 0.97 },
  { angle: 52.0, factor: 0.97 },
  { angle: 53.0, factor: 0.96 },
  { angle: 54.0, factor: 0.96 },
  { angle: 55.0, factor: 0.96 },
  { angle: 56.0, factor: 0.96 },
  { angle: 57.0, factor: 0.95 },
  { angle: 58.0, factor: 0.94 },
  { angle: 59.0, factor: 0.94 },
  { angle: 60.0, factor: 0.94 },
  { angle: 61.0, factor: 0.94 },
  { angle: 62.0, factor: 0.93 },
  { angle: 63.0, factor: 0.92 },
  { angle: 64.0, factor: 0.92 },
  { angle: 65.0, factor: 0.91 },
  { angle: 66.0, factor: 0.91 },
  { angle: 67.0, factor: 0.91 },
  { angle: 68.0, factor: 0.9 },
  { angle: 69.0, factor: 0.89 },
  { angle: 70.0, factor: 0.88 },
  { angle: 71.0, factor: 0.87 },
  { angle: 72.0, factor: 0.86 },
  { angle: 73.0, factor: 0.85 },
  { angle: 74.0, factor: 0.85 },
  { angle: 75.0, factor: 0.84 },
  { angle: 76.0, factor: 0.84 },
  { angle: 77.0, factor: 0.83 },
  { angle: 78.0, factor: 0.82 },
  { angle: 79.0, factor: 0.81 },
  { angle: 80.0, factor: 0.8 },
  { angle: 81.0, factor: 0.79 },
  { angle: 82.0, factor: 0.78 },
  { angle: 83.0, factor: 0.77 },
  { angle: 84.0, factor: 0.76 },
  { angle: 85.0, factor: 0.75 },
  { angle: 86.0, factor: 0.74 },
  { angle: 87.0, factor: 0.73 },
  { angle: 88.0, factor: 0.72 },
  { angle: 89.0, factor: 0.71 },
  { angle: 90.0, factor: 0.706 },
];
