import { ProductBuyInfo } from "src/db-types";

import { isMicroInverter } from "./getIsMicroInverter";
import { ProductCostProps, getProductCost } from "./getProductCost";

interface ProductBuyInfoProps extends ProductCostProps {
  quantity?: number;
}
export const getProductBuyInfo = (props: ProductBuyInfoProps): ProductBuyInfo => {
  let { quantity } = props;

  if (!quantity) quantity = 1;

  const singleProdCost = getProductCost(props);
  return {
    priceForSingle: singleProdCost,
    quantity,
    totalPrice: quantity * singleProdCost,
  };
};

interface InverterBuyInfo extends ProductBuyInfoProps {
  solarPanelCount: number;
}
export const getInverterBuyInfo = (props: InverterBuyInfo): ProductBuyInfo => {
  const qtyToBuy = isMicroInverter(props.product) ? props.solarPanelCount : 1;

  return getProductBuyInfo({
    product: props.product,
    projectType: props.projectType,
    quantity: qtyToBuy,
  });
};
