import { merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateBattery = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateBattery>,
): ProjectState => {
  const battery = merge({}, currentState.components.battery, payload);
  return {
    ...currentState,
    components: {
      ...currentState.components,
      battery,
    },
  };
};
