import { Plugin } from "chart.js";

export const screenshotPlugin: Plugin = {
  id: "screenshot_plugin",
  afterRender: (chartInstance, _, options) => {
    const screenshotCallback = options.screenshotCallback;
    if (!screenshotCallback) return;

    if (typeof screenshotCallback === "function") {
      screenshotCallback();
    }
  },
};
