import styled from "styled-components/macro";

export const MainContainer = styled.div`
  width: 100%;
  max-width: 727px;
  padding: 0 30px 40px;
  padding-top: 2rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .image_and_title {
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    .hero_image {
      position: relative;
      top: -20px;
    }

    @media (max-width: 600px) {
      height: auto;
      width: 100%;
      flex-direction: column;
      .hero_image {
        position: initial;
        max-width: 200px;
        margin: auto;
      }
    }
  }
`;
export const MainText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #2d4764;
  margin-bottom: 32px;
  @media (max-width: 600px) {
    margin-bottom: 0;
  }
`;
export const SubText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2d4764;
  margin-left: 26px;
  margin-top: 12px;
`;
export const SubText1 = styled.div`
  width: 60%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2d4764;
`;
export const SubText3 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2d4764;
`;
export const VideoContainer = styled.div`
  width: 100%;
  max-width: 600px;
  aspect-ratio: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  margin-top: 36px;
`;
