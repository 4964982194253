import { clone, merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateOuterDetails = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateOuterDetails>,
): ProjectState => {
  const newState = clone(currentState);
  if (payload.id) newState.id = payload.id;
  if (payload.profile) newState.profile = payload.profile;
  if (payload.quoteId) newState.quoteId = payload.quoteId;
  if (payload.shortId) newState.shortId = payload.shortId;
  if (payload.type) newState.type = payload.type;
  if (payload.uid) newState.uid = payload.uid;
  if (payload.version) newState.version = payload.version;
  return newState;
};
