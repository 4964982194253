import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import PaymentFourm from "src/popups/paywall/paymentFourm/PaymentFourm";

import { VendorCard } from "./components/VendorCard/VendorCard";
import { PaymentWrapper } from "./Payment.styles";

import { getUser } from "src/redux/user/selectors";

export const Payment: React.FC = () => {
  const [isCretiTick, setIsCreditTic] = useState(null);
  const user = useSelector(getUser);

  const finalPrice = useMemo(() => {
    if(isCretiTick) {
      const credits = user?.credits.length ?? 0;
      return `${500 - (credits * 20)} €`
    }
    return "500 €"
  },[isCretiTick, user])

  return (
    <PaymentWrapper>
      <div className="mainContainer">
        <div className="part_left">
          <VendorCard setIsCreditTic={setIsCreditTic} isCretiTick={isCretiTick} finalPrice={finalPrice}/>
        </div>
        <div className="part_right">
          <PaymentFourm planPaymentValue={finalPrice} />
        </div>
      </div>
    </PaymentWrapper>
  );
};
