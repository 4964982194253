import { useEffect } from "react";

import { AUTH_MODES, AuthPopupProps } from "src/popups";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { UserSelectors } from "src/redux/selectors";

import { useAppDispatch, useAppSelector } from ".";

export const useUserDetailsPopup = (): void => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(UserSelectors.getUser);

  useEffect(() => {
    if (!user) return;

    if (!user.phoneNumber) {
      const authProps: AuthPopupProps = { authMode: AUTH_MODES.update_details };
      dispatch(PopupActions.openPopup(POPUP_KEYS.auth, authProps));
    }
  }, [dispatch, user]);
};
