import styled from "styled-components";

export const HeaderWrapper = styled("div")`
  .header_main {
    width: 97%;
    max-width: min(97%, 1166px);
    padding: 15px;
    border-radius: 22px;
    border-radius: 22px;
    margin: auto;
    padding-block: 40px;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 500px) {
      padding-block: 20px;
    }
  }
  .add_config {
    border: 1px solid #2d4764;
    border-radius: 36px;
    height: 40px;
    color: #2d4764;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
  }
  .sideByside {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 40px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 36px;
    color: #2d4764;
    text-transform: uppercase;
    border: 1px solid #2d4764;
  }
  .left_side {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #2d4764;
  }
  .right_side {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 11px;
  }

  @media (max-width: 650px) {
    .adjest_width {
      width: 84vw !important;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }
  @media (max-width: 403px) {
    .map_view {
      font-size: 14px;
    }
  }
`;
