import { TextField } from "@mui/material";
import { Button, Form, Input, Upload } from "antd";
import styled from "styled-components";

export const COLORS = {
  PRIMARY: "#FFD75D",
  SECONDARY: "#2d4764",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  DIVIDERS: "#d9d9d9",
};

export const pickThemeColor = (colorName: keyof typeof COLORS): any =>
  COLORS[colorName] || COLORS.BLACK;

export const StyledButton = styled(Button)<{ color?: any }>`
  background-color: ${({ color }) => (color || "#304864")};
  color: #fff;
  border-radius: 10px;
  width: max-content;
`;

export const StyledInputContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 20px;
  max-width: 100%;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

export const StyledInput = styled(Input)`
  max-height: 30px;
  max-width: 150px;
`;

export const StyledOuterUpload = styled.div`
  gap: 2px;
  border-radius: 14px;
  box-shadow: 1px 1px 10px 0px #2d476480;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledUploadBox = styled.div`
  padding: 20px;
  margin: 5px;
  width: 98%;
  gap: 2px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed #dfdfdf;
`;

export const StyledFinalPrice = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const StyledInputBoxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const StyledLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledLocation = styled.div`
  text-align: center;
`;

export const StyledContentDetails = styled.div`
  padding: 5px 0px 5px 0px;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px 0px #0000001a;
  width: 55rem;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 3rem;
`;

export const StyledContentHeading = styled.div`
  border-bottom: 5px solid #ffbf1b;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding-block: 10px;
`;

export const StyledContentTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 27.28px;
  text-align: start;
  margin-bottom: 10px;
`;

export const StyledFilesTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 27.28px;
  text-align: start;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const StyledContentMiddle = styled.div`
  padding: 20px;
`;

export const StyledHR = styled.hr`
  border: 1px solid #2d4764;
`;

export const HrWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5px;
`;

export const TotalBox = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  color: #2d4764;
  font-size: 16px;
  font-weight: 700;
  padding: 10px;
  gap: 20px;
  margin-bottom: 10px;
`;

export const StyledImageBox = styled.div`
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px 0px #0000001a;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  grid-column: span 1;
`;

export const StyledDetailBox = styled.div`
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px 0px #0000001a;
  grid-row: 1 / span 2;
  grid-column: 2;
`;

export const StyledUploadBoxContainer = styled.div<{ expanded: boolean }>`
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px 0px #0000001a;
  text-align: center;
  grid-column: span ${({ expanded }) => (expanded ? 1 : 2)};
  .ant-upload-list {
    max-width: 300px !important;
  }
  .ant-upload.ant-upload-select {
    width: 95%;
  }
`;

export const StyledImageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export const StyledFormContent = styled(Form)<{ expanded: boolean }>`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 70%;

  .ant-form-item {
    margin-bottom: 20px !important;
  }
  .MuiAccordion-gutters {
    width: fit-content;
    min-width: 100%;
    width: ${({ expanded }) => !expanded && "100% !important"};
  }
  .ant-input-affix-wrapper {
    &:not(:disabled):hover,
    &:not(:disabled):focus {
      border: 1px solid ${pickThemeColor("PRIMARY")};
    }

    &:not(&-disabled):hover,
    &-focused {
      border: 1px solid ${pickThemeColor("PRIMARY")};
    }
    box-shadow: none;

    &:hover {
      border: 1px solid ${pickThemeColor("PRIMARY")} !important;
    }
  }
  .ant-picker-focused {
    border-color: ${pickThemeColor("PRIMARY")} !important;
    box-shadow: none;
  }
  .ant-picker:hover {
    border-color: ${pickThemeColor("PRIMARY")} !important;
  }
  .MuiPaper-elevation {
    border-top: none;
    border: 1px solid ${pickThemeColor("DIVIDERS")} !important;
    box-shadow: none;
  }
  .MuiPaper-elevation:hover {
    border: 1px solid ${pickThemeColor("PRIMARY")} !important;
  }
`;

export const StyledMarginBottom = styled.div`
  margin-bottom: 20px;
`;

export const ModuleInputField = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.82px;
  text-align: center;

  .ant-input {
    text-align: center !important;
  }
`;

export const StyledContentBottom = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const RealizationModalWrapper = styled.div`
  &.ant-modal-wrap {
    top: -90px;
  }
`;

export const BottomContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  .signatureCanvas {
    border: 1px solid #2d4764;
    width: 90%;
    margin-top: 1rem;
  }

  .FinalOfferCalculation{

  }
`;

export const FinalOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;