import { keyframes } from "styled-components";
import styled from "styled-components/macro";

const rightToLeft = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
`;

export const MobileMenu = styled.div`
  position: fixed;
  right: 0;
  top: 0;

  overflow-y: auto;

  width: min(75%, 300px);
  height: 100%;

  background: #ffffff;
  box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 36px 0px 0px 0px;

  z-index: 1001;

  animation: ${rightToLeft} 150ms linear;
`;

export const CloseButtonPos = styled.div`
  margin: 5px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 1rem;
  padding-top: 0.5rem;
`;

export const LogoTextWrap = styled.div`
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

export const Divider = styled.div`
  border: 1px solid #dfdfdf;
  margin-block: 1rem;
  width: 100%;
`;

export const Links = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: fit-content;
  padding-inline: 10px;
`;

export const MobileBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgb(64 64 64 / 52%);
  z-index: 1000;

  @media (min-width: 800px) {
    display: none;
  }
`;
