import React from "react";
import { useTranslation } from "react-i18next";
import ChartCircle from "src/shared/ChartCircle";
import { ChartLabelStyled } from "./ChartLabel.styles";

type ChartLabelProps = {
  onMouseOver: Function;
  onMouseOut: Function;
  title: string;
  subTitle: string;
  legendColor: string;
};

const ChartLabel = (props: ChartLabelProps) => {
  const { subTitle, title, onMouseOut, onMouseOver, legendColor } = props;
  const { t } = useTranslation();

  return (
    <ChartLabelStyled onMouseOut={() => onMouseOut()} onMouseOver={() => onMouseOver()}>
      <ChartCircle color={legendColor}></ChartCircle>
      <div className="text">
        <div className="title">{t(title)}</div>
        <div className="numericals">{t(subTitle)}</div>
      </div>
    </ChartLabelStyled>
  );
};

export default ChartLabel;
