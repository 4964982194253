import { clone } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const addSuggestedVendor = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addSuggestedVendor>,
): ProjectState => {
  const newState = clone(currentState);
  const newVendor = clone(payload);

  newState.vendor = {
    ...newState.vendor,
    suggested: [...newState.vendor.suggested, newVendor],
  };
  return newState;
};
