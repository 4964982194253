// import React, { useCallback } from "react";
// import { useSelector } from "react-redux";

// import { KilometersTravelledLine } from "./components";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RemoveButtonIcon } from "src/assets/svgs";

import {
  ConsumptionPer100KMLine,
  EVYearlyLine,
  GasPriceLine,
  KilometersTravelledLine,
  SolarSupplyLine,
} from "./components";
import * as S from "./EVBox.styles";

import { ProjectActions } from "src/redux/actionCreators";
import { CarComponent, CarConfiguration } from "src/redux/project";

// import { getStateCarById } from "src/redux/project/project.selector";

interface EVBoxProps {
  car: CarComponent;
  onDelete: (id: string) => void;
  showCloseButton: boolean;
  showEMobilityTitle: boolean;
}
export const EVBox: React.FC<EVBoxProps> = (props: EVBoxProps) => {
  const { car, onDelete, showCloseButton, showEMobilityTitle } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const configChangeHandler = useCallback(
    (data: Partial<CarConfiguration>) => {
      dispatch(
        ProjectActions.updateCar({
          id: car.id,
          car: {
            configuration: {
              ...data,
            },
          },
        }),
      );
    },
    [car.id, dispatch],
  );

  const deleteBtnClickHandler = useCallback(() => {
    dispatch(ProjectActions.deleteCar(car.id));
    if (onDelete) onDelete(car.id);
  }, [car.id, dispatch, onDelete]);

  useEffect(() => {
    dispatch(
      ProjectActions.updateCar({
        id: car.id,
        car: {
          configuration: {
            evYearlyConsumption:
              (car.configuration.consumptionPer100Km * car.configuration.evKmsTravelled) / 100,
          },
        },
      }),
    );
  }, [car.configuration.consumptionPer100Km, car.configuration.evKmsTravelled, car.id, dispatch]);

  return (
    <S.EVBoxWrapper>
      {!showEMobilityTitle && (
        <div className="car_tag">
          <div className="title_number">
            {car.item?.name}
            {!car.item && t("Car")} {car.name.split(" ")[1]}
          </div>

          {showCloseButton && (
            <div className="close" onClick={deleteBtnClickHandler}>
              <RemoveButtonIcon />
            </div>
          )}
        </div>
      )}
      <KilometersTravelledLine
        kmTravelled={car.configuration.evKmsTravelled}
        onValueChange={configChangeHandler}
      />
      <ConsumptionPer100KMLine
        consPer100KM={car.configuration.consumptionPer100Km}
        onValueChange={configChangeHandler}
      />
      <EVYearlyLine
        evYearly={car.configuration.evYearlyConsumption}
        onValueChange={configChangeHandler}
      />
      <SolarSupplyLine solarSupply={50} onValueChange={() => {}} />
      <GasPriceLine
        gasPrice={car.configuration.gasPricePer100Km}
        onValueChange={configChangeHandler}
      />
    </S.EVBoxWrapper>
  );
};
