import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { BackendApis } from "src/api";

import {
  isValidPidPath,
  useAddConfiguration,
  useAppDispatch,
  useJoinTheWebinar,
  useNaivgateAbsolute,
} from "src/hooks";

import { InstallerDB } from "src/db-types";
import { useExploreInstallerAPI } from "src/hooks/apis";
import { logout } from "src/utils";

import { LeftNav, MiddleNav, MobileMenu, RightNav } from "./components";
import * as L from "./components/LeftNav/LeftNav.styles";
import { NavItemKey } from "./constants/navItemKey";
import * as S from "./NavBar.styles";

import { AppActions } from "src/redux";
import { UserActions } from "src/redux/actionCreators";
import { AppSelectors, ProjectSelectors, UserSelectors } from "src/redux/selectors";
import { LongLogo } from "src/assets/svgs";
import { useTranslation } from "react-i18next";
import Image from 'src/assets/svgs/long-logo.png'

const NavBar: React.FC = () => {
  const [query] = useSearchParams();
  const dispatch = useAppDispatch();
  const {t} = useTranslation()
  const installerPartnerSlug = query.get("partner");
  const simplify = query.get("simplify");
  const { fetchData, response } = useExploreInstallerAPI();
  const { joinWebinarHandler } = useJoinTheWebinar();
  const { addConfig } = useAddConfiguration();

  const urlBeforeUserOverview = useSelector(AppSelectors.getLastAccessedProject);
  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const user = useSelector(UserSelectors.getUser);
  const installer = useSelector(UserSelectors.getInstaller);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [installerPartner, setInstallerPartner] = useState<InstallerDB | null>(null);

  const navigate = useNaivgateAbsolute();

  useEffect(() => {
    if (!response) return;
    const installerData: InstallerDB | null = response?.data.data.docs.length
      ? response?.data.data.docs[0]
      : null;
    // setInstallerPartner(response?.data.data.docs.length ? response?.data.data.docs[0] : null);
    dispatch(UserActions.setInstaller(installerData));
    dispatch(
      AppActions.updateAppState({
        actions: {
          updateDeal: true,
        },
      }),
    );
  }, [response]);

  useEffect(() => {
    dispatch(UserActions.setSimplify(simplify === "true"));
  }, [simplify]);

  useEffect(() => {
    if (installerPartnerSlug) {
      void fetchData({
        ...BackendApis.exploreInstaller,
        // @ts-expect-error
        data: {
          partnerSlug: installerPartnerSlug,
        },
      });
    }
  }, [fetchData, installerPartnerSlug]);

  const installerPartnerLogo = useMemo(() => {
    if (!installer) return null;

    if (!installer?.partnerFrontEnd) return null;

    return (
      <L.InstallerPartner>
        {!installer?.isOnlySmallSolarhubLogo && <div className="horzontal-line" />}
        {installer.logoUrl.length ? (
          <div className="image-container">
            <img src={installer.logoUrl[0]} alt="Intaller partner" />
          </div>
        ) : (
          <p className="installer-name">{installer.companyName}</p>
        )}
        {installer?.isOnlySmallSolarhubLogo && (
          <L.SmallLogo>
            <p style={{ height: "20px" }}> {t("In Kooperation mit")}</p>
            <LongLogo style={{ height: "22px", width: "65px" }} />{" "}
          </L.SmallLogo>
        )}
      </L.InstallerPartner>
    );
  }, [installer]);

  const offersHandler = useCallback(() => {
    if (!installerPartnerSlug) {
      navigate("/useroverview/my-project-id");
    } else {
      navigate(`/useroverview/my-project-id?partner=${installerPartnerSlug}`);
    }
    dispatch(AppActions.updateLastAccessedProject(location.pathname));
  }, [dispatch, navigate, installerPartnerSlug]);

  const addDummyProject = useCallback(() => {
    void addConfig(quoteId);
  }, [addConfig, quoteId]);

  const backToProjectHandler = useCallback(() => {
    if (urlBeforeUserOverview) {
      navigate(urlBeforeUserOverview);
      return;
    }

    if (user?.lastLog) {
      const { address, pid } = user.lastLog;
      const isValidPid = pid && isValidPidPath(pid);
      const isValidAddress = address && address !== "";
      if (isValidPid) {
        if (!installerPartnerSlug) {
          navigate(`/application/saved-${pid}`);
        } else {
          navigate(`/application/saved-${pid}?partner=${installerPartnerSlug}`);
        }
      } else if (isValidAddress) {
        navigate(`/application/default-${address}`);
      } else {
        navigate("/application");
      }
    } else {
      navigate("/application");
    }
  }, [navigate, urlBeforeUserOverview, user?.lastLog, installerPartnerSlug]);

  const menuBtnClickHandler = (command: NavItemKey): void => {
    if (command === NavItemKey.LOGOUT) {
      logout(navigate);
    } else if (command === NavItemKey.WEBINAR) {
      joinWebinarHandler();
    } else if (command === NavItemKey.OFFER) {
      offersHandler();
    } else if (command === NavItemKey.COMMUNITY) {
      reDirectToCommunity(false);
    } else if (command === NavItemKey.KNOWLEDGE_HUB) {
      reDirectToCommunity(true);
    } else if (command === NavItemKey.ADD_CONFIGURATION) {
      addDummyProject();
    } else if (command === NavItemKey.BACK_TO_PROJECT) {
      backToProjectHandler();
    } else if (command === NavItemKey.LOGO) {
      // dispatch(setCurrentStep(0));
      // TODO : logo click
    } else if (command === NavItemKey.MOBILE_OPEN_MENU) {
      setIsMenuOpen(true);
    }

    if (isMenuOpen) setIsMenuOpen(false);
  };

  const reDirectToCommunity = useCallback((isToKnowledgeHub: boolean) => {
    // TODO : HANDLERS
  }, []);

  return (
    <S.Navbar>
      <div className="main-nav">
        {isMenuOpen && (
          <MobileMenu menuButtonClickHandler={menuBtnClickHandler} installer={installer} />
        )}
        <LeftNav
          menuButtonClickHandler={menuBtnClickHandler}
          installerPartnerLogo={installerPartnerLogo}
          installer={installer}
        />
        <MiddleNav menuButtonClickHandler={menuBtnClickHandler} />
        <RightNav menuButtonClickHandler={menuBtnClickHandler} />
      </div>
      {installerPartnerLogo && (
        <div className="partner-installer">
          <p>In Kooperation mit</p>
          {!installer?.isOnlySmallSolarhubLogo && installerPartnerLogo}
          {installer?.isOnlySmallSolarhubLogo && (
            <img src={Image} style={{ height: "40px", width: "100px" }} />
          )}
        </div>
      )}
    </S.Navbar>
  );
};

export default NavBar;
