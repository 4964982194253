import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import * as AppConstants from "src/app-constants";
import { isOnDevelopment } from "src/utils";

export const initSentry = (): void => {
  if (isOnDevelopment()) {
    return;
  }

  Sentry.init({
    dsn: AppConstants.Sentry.DSN,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
};
