import { merge } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  getDataFromViewEditURL,
  getQuoteAddrFromURL,
  getShortIdAndVersionFromURL,
} from "src/utils";

interface StateByURL {
  isOnDefault: boolean;
  isOnSaved: boolean;
  isOnView: boolean;
  isOnPdf: boolean;
  isOnViewAndEdit: boolean;
  isOnUserOverview: boolean;
  isResetPass: boolean;

  defaultURLData: {
    addr: string;
  };

  savedURLData: {
    shortId: number;
    version: number;
  };

  viewURLData: {
    shortId: number;
    version: number;
  };

  viewAndEditURLData: {
    shortId: number;
    version: number;
    secret: string;
  };
}

const defaultData: StateByURL = {
  isOnDefault: false,
  isOnSaved: false,
  isOnView: false,
  isOnPdf: false,
  isOnViewAndEdit: false,
  isOnUserOverview: false,
  isResetPass: false,

  defaultURLData: {
    addr: "",
  },

  savedURLData: {
    shortId: -1,
    version: -1,
  },

  viewURLData: {
    shortId: -1,
    version: -1,
  },

  viewAndEditURLData: {
    shortId: -1,
    version: -1,
    secret: "",
  },
};

export const useURLData = (): StateByURL => {
  const location = useLocation();

  const [data, setData] = useState(defaultData);

  const handleDefaultURL = useCallback((url: string) => {
    const changes: Partial<StateByURL> = {
      isOnDefault: true,
      defaultURLData: {
        addr: getQuoteAddrFromURL(url),
      },
    };

    setData(merge({}, defaultData, changes));
  }, []);

  const handleUseroverviewURL = useCallback((url: string) => {
    const changes: Partial<StateByURL> = {
      isOnUserOverview: true,
    };

    setData(merge({}, defaultData, changes));
  }, []);

  useEffect(() => {
    const url = location.pathname + location.search;
    if (/application\/reset-password\?token=.*/.test(url)) {
      setData((p) => {
        return {
          ...p,
          isResetPass: true,
        };
      });
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    const url = location.pathname;

    if (url.includes("default")) {
      handleDefaultURL(url);
    }

    if (url.includes("saved")) {
      const changes: Partial<StateByURL> = {
        isOnSaved: true,
        savedURLData: {
          ...getShortIdAndVersionFromURL(url),
        },
      };

      setData(merge({}, defaultData, changes));
    }

    if (url.startsWith('/pdf')) {
      const changes: Partial<StateByURL> = {
        isOnPdf: true,
      };

      setData(merge({}, defaultData, changes));
    }

    if (url.includes("/view") && !url.includes("view-and-edit")) {
      const changes: Partial<StateByURL> = {
        isOnView: true,
        viewURLData: {
          ...getShortIdAndVersionFromURL(url, "view-"),
        },
      };

      setData(merge({}, defaultData, changes));
    }

    if (url.includes("view-and-edit")) {
      const changes: Partial<StateByURL> = {
        isOnViewAndEdit: true,
        viewAndEditURLData: {
          ...getDataFromViewEditURL(url),
        },
      };

      setData(merge({}, defaultData, changes));
    }

    if (url.includes("useroverview")) {
      handleUseroverviewURL(url);
    }
  }, [handleDefaultURL, handleUseroverviewURL, location.pathname]);

  return data;
};
