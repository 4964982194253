import { cloneDeep } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { defaultFilters, useAddBatteryInverterChange, useIsComponentAdded } from "src/hooks";

import { BatteryButtonIcon, HeatpumpButtonIcon, WallboxButtonIcon } from "src/assets/svgs";
import { DEFAULT_BATTERY_KWH, DEFAULT_HEATPUMP_KWH } from "src/data";

import { AddProductButton } from "./components";
import * as S from "./styles";

import { PopupActions, ProjectActions } from "src/redux/actionCreators";
import { defaultHeatpumpConfiguration } from "src/redux/project/default-values";
import { ProjectComponentsKeys } from "src/redux/project/types/components";
import { UserSelectors } from "src/redux/selectors";

export const AddProductButtonsRow: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isBatteryAdded, isHeatpumpAdded, isWallboxAdded, isEMSAdded } = useIsComponentAdded();
  const { convertInverterToHybrid } = useAddBatteryInverterChange();
  const installerDetails = useSelector(UserSelectors?.getInstaller);

  const addBatteryHandler = useCallback(() => {
    dispatch(
      ProjectActions.addBattery({
        item: null,
        extendedWarranty: false,
        isExcludeCost: false,
        quantity: 1,
        preferences: {
          ...cloneDeep(defaultFilters),
          applicableMaxCapacity: DEFAULT_BATTERY_KWH,
        },
      }),
    );
    const result = convertInverterToHybrid();
    if (!result) {
      dispatch(ProjectActions.deleteBattery());
    }
  }, [convertInverterToHybrid, dispatch]);

  const addHeatpump = useCallback(() => {
    dispatch(
      ProjectActions.addHeatpump({
        item: null,
        extendedWarranty: false,
        isExcludeCost: false,
        quantity: 1,
        preferences: {
          ...cloneDeep(defaultFilters),
          applicableMaxCapacity: DEFAULT_HEATPUMP_KWH,
        },
        configuration: {
          ...cloneDeep(defaultHeatpumpConfiguration),
        },
      }),
    );
  }, [dispatch]);

  const addWallbox = useCallback(() => {
    dispatch(
      ProjectActions.addWallbox({
        item: null,
        extendedWarranty: false,
        isExcludeCost: false,
        quantity: 1,
        preferences: {
          ...cloneDeep(defaultFilters),
        },
      }),
    );
  }, [dispatch]);

  const addEMS = useCallback(() => {
    dispatch(ProjectActions.addEMS({
      item: null,
        extendedWarranty: false,
        isExcludeCost: false,
        quantity: 1,
        preferences: {
          ...cloneDeep(defaultFilters),
        },
    }))},[dispatch]);

  const buttonClickHandler = useCallback(
    (addType: "battery" | "heatpump" | "wallbox" | "EMS") => {
      if (addType === "battery") {
        addBatteryHandler();
      } else if (addType === "heatpump") {
        addHeatpump();
      } else if (addType === "wallbox") {
        addWallbox();
      } else if (addType === "EMS") {
        addEMS();
      }
    },
    [addBatteryHandler, addHeatpump, addWallbox, addEMS],
  );

  const openInfoPopup = useCallback(
    (productName: Exclude<ProjectComponentsKeys, "cars" | "waterHeating">) => {
      dispatch(
        PopupActions.openProductInfoPopup({
          type: productName,
        }),
      );
    },
    [dispatch],
  );

  return (
    <S.MainContainer2>
      {!isBatteryAdded && (
        <AddProductButton
          text={t("ADD BATTERY")}
          ImageComponent={<BatteryButtonIcon />}
          onClick={() => buttonClickHandler("battery")}
          onInfoIconClick={() => openInfoPopup("battery")}
        />
      )}
      {!isWallboxAdded && (
        <AddProductButton
          text={t("ADD WALLBOX")}
          ImageComponent={<WallboxButtonIcon />}
          onClick={() => buttonClickHandler("wallbox")}
          onInfoIconClick={() => openInfoPopup("wallbox")}
        />
      )}
      {!installerDetails
        ? !isHeatpumpAdded && (
            <AddProductButton
              text={t("ADD HEATPUMP")}
              ImageComponent={<HeatpumpButtonIcon />}
              onClick={() => buttonClickHandler("heatpump")}
              onInfoIconClick={() => openInfoPopup("heatpump")}
            />
          )
        : installerDetails.isAllowHitPump &&
          !isHeatpumpAdded && (
            <AddProductButton
              text={t("ADD HEATPUMP")}
              ImageComponent={<HeatpumpButtonIcon />}
              onClick={() => buttonClickHandler("heatpump")}
              onInfoIconClick={() => openInfoPopup("heatpump")}
            />
          )}
      {!isEMSAdded && (
        <AddProductButton
          text={t("ADD EMS")}
          ImageComponent={<WallboxButtonIcon />}
          onClick={() => buttonClickHandler("EMS")}
          onInfoIconClick={() => openInfoPopup("EMS")}
        />
      )}
    </S.MainContainer2>
  );
};
