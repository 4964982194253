import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import { BackendApis } from "src/api";

import { getAddress, getProject, getProjectType } from "src/redux/project/selectors";
import { ProductSelectors } from "src/redux/selectors";

import { OfferType } from "src/db-types";
import { useTranslation } from "react-i18next";

interface IuseGetRentingOffer {
  type: OfferType;
}

const useGetRentingOffer = () => {
  const project = useSelector(getProject);
  const mapboxAddress = useSelector(getAddress);
  const projectType = useSelector(getProjectType);
  const solarPanels = useSelector(ProductSelectors?.getSolarPanels);
  const inverters = useSelector(ProductSelectors?.getInverters);
  const isB2b = projectType === "b2b";
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isOfferLoading, setIsOfferLoading] = useState(false);
  const {t} = useTranslation()

  const handleGetOffer = async ({ type }: IuseGetRentingOffer) => {
    if (type === OfferType.PDF) setIsPdfLoading(true);
    if (type === OfferType.REDIRECT) setIsOfferLoading(true);
    if (mapboxAddress.address) {
      try {
        const components = [
          {
            name: project.components.solarPanel.item?.name || solarPanels?.[0]?.name,
            quantity: project.components.solarPanel.quantity,
            type: "solarmodul",
            price: isB2b
              ? project.components.solarPanel.item?.priceB2b || solarPanels?.[0]?.priceB2b
              : project.components.solarPanel.item?.priceB2c || solarPanels?.[0]?.priceB2c,
            output:
              project.components.solarPanel.item?.applicableMaxCapacity ||
              solarPanels?.[0]?.applicableMaxCapacity,
          },
          {
            name: project.components.inverter.item?.name || inverters?.[0]?.name,
            quantity: project.components.inverter.quantity,
            type: "inverter",
            price: isB2b
              ? project.components.inverter.item?.priceB2b || inverters?.[0]?.priceB2b
              : project.components.inverter.item?.priceB2c || inverters?.[0]?.priceB2c,
            output:
              project.components.inverter.item?.applicableMaxCapacity ||
              inverters?.[0]?.applicableMaxCapacity,
          },
        ];

        if (project.components.battery.item) {
          components.push({
            name: project.components.battery.item?.name,
            quantity: project.components.battery.quantity,
            type: "battery",
            price: isB2b
              ? project.components.battery.item?.priceB2b
              : project.components.battery.item?.priceB2c,
            output: project.components.battery.item?.applicableMaxCapacity,
          });
        }

        if (project.components.wallbox.item) {
          components.push({
            name: project.components.wallbox.item?.name,
            quantity: project.components.wallbox.quantity,
            type: "wallbox",
            price: isB2b
              ? project.components.wallbox.item?.priceB2b
              : project.components.wallbox.item?.priceB2c,
            output: project.components.wallbox.item?.applicableMaxCapacity,
          });
        }

        if (project.financialDetails.costs.totalProjectCost) {
          const apiResponse = await axios.patch(
            BackendApis.getRentingOffer?.url,
            {
              address: {
                city: mapboxAddress.city,
                postcode: mapboxAddress.postcode,
                street1: mapboxAddress.address,
              },
              partner_id: uuid(),
              planed_construction_date: new Date().toISOString().split("T")[0],
              price: project.financialDetails.costs.totalProjectCost,
              components,
            },
            {
              headers: {
                Authorization: "Bearer 5QEBU9mySTUdzCFB",
              },
            },
          );

          if (apiResponse) {
            if (type === OfferType.PDF) {
              window.open(apiResponse.data?.pdf.link, "_blank");
              setIsPdfLoading(false);
            }
            if (type === OfferType.REDIRECT) {
              window.open(apiResponse?.data?.link, "_blank");
              setIsOfferLoading(false);
            }
          }
        }
      } catch (e) {
        toast.error(t("Something went wrong"));

        setIsPdfLoading(false);
        setIsOfferLoading(false);
      }
    }
  };

  return { handleGetOffer, isOfferLoading, isPdfLoading };
};

export default useGetRentingOffer;
