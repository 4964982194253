import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/hooks";

import { getAverageCapacity } from "src/helpers";
import { useNumberFormat } from "src/hooks/useNumberFormat";
import { ProductPopupProps } from "src/popups/ProductPopup/ProductPopup";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";
import { getNumber } from "src/utils";

import { PopupActions } from "src/redux";
import { ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

const PanelCapacityLine: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const filteredSolarPanels = useSelector(ProductSelectors.getFilteredSolarPanels);
  const solarPanelProduct = useSelector(ProjectSelectors.getSolarPanel);

  const capacity = useMemo(() => {
    return getAverageCapacity(filteredSolarPanels);
  }, [filteredSolarPanels]);

  const [hover, setHover] = useState(false);

  const { formatNumber } = useNumberFormat();

  const openProductPopup = useCallback(() => {
    const data: ProductPopupProps = {
      type: "solarPanel",
      onSelectCallback: (prod) => {
        dispatch(
          ProjectActions.updateSolarPanel({
            item: prod,
          }),
        );
      },
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.product, data));
  }, [dispatch]);

  return (
    <>
      <ParameterStyles.Line
        isPadding
        link
        onClick={openProductPopup}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        {hover ? (
          <ParameterStyles.Label>{t("Change panel")}</ParameterStyles.Label>
        ) : (
          <ParameterStyles.Label>{t("PANEL CAPACITY")}</ParameterStyles.Label>
        )}
        <ParameterStyles.Value>
          {formatNumber(
            Math.floor(
              getNumber(solarPanelProduct?.item ? solarPanelProduct?.item?.capacity : capacity),
            ),
          )}{" "}
          Watt
        </ParameterStyles.Value>
      </ParameterStyles.Line>
      {/* <ProductPopup open={open} setOpen={setOpen} type={type} data={data} coming={true} /> */}
    </>
  );
};

export default PanelCapacityLine;
