import { clone, cloneDeep } from "lodash";

import { defaultComponent } from "../../default-values";

import { actionCreators, ProjectState } from "src/redux/project";

export const deleteCar = (
  currentState: ProjectState,
  { payload: idToDelete }: ReturnType<typeof actionCreators.deleteCar>,
): ProjectState => {
  const filteredCars = clone(
    currentState.components.cars.filter((c) => {
      return c.id !== idToDelete;
    }),
  );

  const wallbox = cloneDeep(defaultComponent);

  return {
    ...currentState,
    components: {
      ...currentState.components,
      cars: filteredCars,
      wallbox: filteredCars.length === 0 ? wallbox : currentState.components.wallbox,
    },
  };
};
