import { CircularProgress } from "@mui/material";
import { Checkbox } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useRegisterIntoWbinar, useWindowDimensions } from "src/hooks";

import Logo from "src/assets/application_step_1/SolarHub_Logo.svg";
import persons from "src/assets/chartTabs/persons.svg";
import { LongLogo } from "src/assets/svgs";
import { PopupSkeleton } from "src/shared/components";
import { timeBreaker } from "src/utils";

import { AUTH_MODES } from "../auth";

import { Container } from "./styles";
import WebinarSelector from "./WebinarSelector";

import { ReduxAction } from "src/redux/common.types";
import { POPUP_KEYS } from "src/redux/popups";
import { closePopup, openPopup } from "src/redux/popups/action/action.creators";
import { WebinarPopupProps } from "src/redux/popups/reducer/reducer";
import { getWebinarPopup } from "src/redux/popups/selector";
import { JoinedWebinar } from "src/redux/user";
import { getJoinedWebinars, getUser } from "src/redux/user/selectors";
import { IWebinars } from "src/redux/webinar";
import { setSelectedWebinarIsHybrid } from "src/redux/webinar/action/action.creators";
import {
  getSelectedWebinarDate,
  getSelectedWebinarId,
  getSelectedWebinarIdHybrid,
  getWebinars,
} from "src/redux/webinar/selector";

const HYBRID_WEBINAR_MATCH_TEXT = "Veranstaltung";

export const WebinarPopup: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const webinarDetails: WebinarPopupProps = useSelector(getWebinarPopup).data;
  const webinars: IWebinars[] = useSelector(getWebinars);
  const user = useSelector(getUser);
  const { width } = useWindowDimensions();
  const selectedWebinarDate: Date | null = useSelector(getSelectedWebinarDate);
  const selectedWebinarId: number | null = useSelector(getSelectedWebinarId);
  const joinedWebinar: JoinedWebinar | undefined = useSelector(getJoinedWebinars);
  const selectedWebinarisHybrid = useSelector(getSelectedWebinarIdHybrid);
  const { loading, registerForWebinar } = useRegisterIntoWbinar();
  const [timerCountdown, settimerCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [userSelectedHybrid, setUserSelectedHybrid] = useState(false);

  const isUserAlreadyJoinedWebinar = useMemo(
    () => selectedWebinarId && joinedWebinar && joinedWebinar[selectedWebinarId],
    [joinedWebinar, selectedWebinarId],
  );

  const isToShowHybridOption = useMemo(() => {
    const hybridWebinar = webinars.find(
      (webinar) =>
        webinar.id === selectedWebinarId &&
        webinar.agenda.toLowerCase().includes(HYBRID_WEBINAR_MATCH_TEXT.toLowerCase()),
    );
    if (!hybridWebinar) return { maxAllowed: 0, hybrdiRegisteredCount: 0 };
    let maxAllowed = 60;
    const splitWithHashTag = hybridWebinar.topic.split("#");
    if (splitWithHashTag.length >= 2) {
      maxAllowed = Number(splitWithHashTag[1]);
    }
    return { maxAllowed, hybrdiRegisteredCount: hybridWebinar.hybridRgisteredCount };
  }, [webinars, selectedWebinarId]);

  const isUserOkayWithTheHybrid = useMemo(() => {
    if (!selectedWebinarId || !joinedWebinar) return false;
    return joinedWebinar[selectedWebinarId]?.isOffline;
  }, [joinedWebinar, selectedWebinarId]);

  useEffect(() => {
    setUserSelectedHybrid(selectedWebinarisHybrid);
  }, [selectedWebinarisHybrid]);

  const clearAllIntervals = (): void => {
    const intervalId = window.setInterval(function () {}, Number.MAX_SAFE_INTEGER);

    for (let i = 1; i < intervalId; i++) {
      window.clearInterval(i);
    }
  };

  const countDownHandler = useCallback((webinarDate: any): void => {
    const diff = moment().diff(webinarDate);

    const { seconds, minutes, hours, days } = timeBreaker(Math.abs(diff / 1000).toFixed(0));

    if (seconds === 0 && minutes === 0 && hours === 0 && days === 0) {
      clearAllIntervals();
    }

    settimerCountdown({
      days,
      hours,
      minutes,
      seconds,
    });
  }, []);

  useEffect(() => {
    if (!selectedWebinarDate) return;
    const diff = moment().diff(selectedWebinarDate);

    clearAllIntervals();
    if (diff > 0) {
      settimerCountdown({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    } else {
      setInterval(() => {
        countDownHandler(selectedWebinarDate);
      }, 1000);
    }
  }, [countDownHandler, selectedWebinarDate]);

  const joinWebinarHandler = (): void => {
    if (isUserAlreadyJoinedWebinar) {
      dispatch(openPopup(POPUP_KEYS.webinar_project));
      handleClose();
      return;
    }
    if (!selectedWebinarId) {
      toast.warn(t("choose a webinar"));
      return;
    }

    if (!user) {
      handleClose();
      return openAuthPopup();
    }

    registerForWebinar();
  };

  const openAuthPopup = (): void => {
    dispatch(
      openPopup(POPUP_KEYS.auth, {
        authMode: AUTH_MODES.webinar_signup,
      }),
    );
  };

  const handleClose = useCallback(
    (): ReduxAction<any> => dispatch(closePopup(POPUP_KEYS.webinar)),
    [dispatch],
  );

  const registerWebinarButtonText = useMemo(() => {
    if (isUserAlreadyJoinedWebinar) {
      return `${t("Let's start collaborating")}`;
    }
    return `${t("REGISTER")}`;
  }, [isUserAlreadyJoinedWebinar, t]);

  const isToShowCheckBox = useMemo(
    () =>
      isToShowHybridOption &&
      isToShowHybridOption.maxAllowed > isToShowHybridOption.hybrdiRegisteredCount,
    [isToShowHybridOption],
  );

  const getTitle = useMemo(
    () =>
      isUserAlreadyJoinedWebinar
        ? "Nice, your registration was succesful, we´re looking forward to the webinar with you! We have send you a confirmation via mail so you can add the webinar to your calendar."
        : webinarDetails?.title,
    [isUserAlreadyJoinedWebinar, webinarDetails?.title],
  );

  return (
    <PopupSkeleton open={true} handleClose={handleClose} styless={{ height: "fit-content" }}>
      <div style={{ width: "min(100vw, 937px)", paddingTop: "2rem" }}>
        <Container>
          <div className="logo">
            <LongLogo style={{ cursor: "pointer" }} />
          </div>
          {webinarDetails && <div className="title">{t(`${webinarDetails?.heading}`)}</div>}
          {webinarDetails && (
            <div
              className="small_heading"
              style={{
                width: webinarDetails.extraContent === "" ? (width > 500 ? "87%" : "100%") : "92%",
              }}
            >
              {t(`${getTitle}`)}
            </div>
          )}
          <img src={persons} alt="" />
          <div className="title_2">{t("Live Webinar startet in")}</div>
          {selectedWebinarDate && (
            <div className="timer-countdown">
              <div className="days">
                <div>{t("Days")}</div>
                <div>{timerCountdown.days}</div>
              </div>
              <div className="hours">
                <div>{t("Hours")}</div>
                <div>{timerCountdown.hours}</div>
              </div>
              <div className="minutes">
                <div>{t("Minutes")}</div>
                <div>{timerCountdown.minutes}</div>
              </div>
              <div className="seconds">
                <div>{t("Seconds")}</div>
                <div>{timerCountdown.seconds}</div>
              </div>
            </div>
          )}
          {!!webinars.length && <WebinarSelector webinars={webinars} />}
          {isToShowCheckBox && (
            <div className="hybrid-selection">
              <Checkbox
                checked={userSelectedHybrid}
                defaultChecked={isUserOkayWithTheHybrid}
                disabled={isUserOkayWithTheHybrid}
                onChange={(e) => {
                  dispatch(setSelectedWebinarIsHybrid(e.target.checked));
                }}
              />
              <p>
                {t(
                  "ich möchte an der Vor-Ort Veranstaltung teilnehmen (max. Teilnehmerzahl {{ max }})",
                  { max: isToShowHybridOption.maxAllowed },
                )}
              </p>
            </div>
          )}
          <div className="webinar-button" onClick={joinWebinarHandler}>
            {loading && (
              <CircularProgress size={20} className="circular-progress-custom-secoundery" />
            )}
            {registerWebinarButtonText}
          </div>
        </Container>
      </div>
    </PopupSkeleton>
  );
};

export default WebinarPopup;
