import _ from "lodash";

import { getPercentage } from "src/utils";

import CoeffsAndIntercepts from "./data/neural-coefficients-intercepts.json";
import {
  HaveBattery,
  HaveEVKeys,
  HaveEvWithBattery,
  HaveHeatingRodKeys,
  HaveHeatpumpKeys,
  HaveHeatpumpKeysWithBattery,
  HaveHeatShareKesselKeys,
  PartitionKeys,
} from "./types/dataset-partition-key.types";
import {
  ConsumptionModel,
  Matrix,
  ModelInputs,
  OverallConsumptionModel,
} from "./types/model.types";
import { defaultModelState } from "./utility/default-model-values";
import { forwardPropogation } from "./utility/forward-propogation";
import Scaler from "./utility/min-max-scaler";
import { safeLog } from "./utility/safe-log";

export class PredictionModel {
  logScaledInputs!: ModelInputs;
  linearScaledInputs!: ModelInputs;

  state: OverallConsumptionModel;

  conditions: boolean[] = [];

  constructor(inputs: ModelInputs) {
    this.state = _.cloneDeep(defaultModelState);
    this.setInputsAndPredict(inputs);
  }

  setInputsAndPredict(inputs?: ModelInputs): void {
    if (inputs) {
      this.state.inputs = _.cloneDeep(inputs);
    } else {
      console.log("Using current inputs ", { inputs: this.state.inputs });
    }
    this.linearScaledInputs = this.scaleInputsLinearly(this.state.inputs, new Scaler());
    this.logScaledInputs = this.scaleInputsLogarithmically(this.state.inputs);

    const { household, ev, heatpump } = this.state;
    // Required to have ev consumption before hand
    // as we want to adjust the predictions based on consumption after the prediction
    household.consumption = this.state.inputs.householdConsumption;
    ev.consumption = (1689.557645922011 * this.state.inputs.evDistance) / 10000;
    heatpump.consumption = this.state.inputs.heatpumpRequirement;

    this.predictValues();

    this.adjustPredictions();

    this.calculateDerivedValues();
    this.state.household.percentages.maxPossibleAutarky = this.state.household.percentages.autarky;
    this.state.ev.percentages.maxPossibleAutarky = this.state.ev.percentages.autarky;
    this.state.heatpump.percentages.maxPossibleAutarky = this.state.heatpump.percentages.autarky;
  }

  scaleInputsLinearly(inputs: ModelInputs, scaler: Scaler): ModelInputs {
    return {
      noOfPeople: scaler.noOfPeople(inputs.noOfPeople),
      householdConsumption: scaler.householdConsumption(inputs.householdConsumption),
      evDistance: scaler.evDistance(inputs.evDistance),
      pv: scaler.pv(inputs.pv),
      battery: scaler.battery(inputs.battery),
      haveHeatpump: inputs.haveHeatpump,
      haveHeatingRod: inputs.haveHeatingRod,
      heatpumpConsumption: scaler.heatpumpConsumption(inputs.heatpumpConsumption),
      heatpumpRequirement: scaler.heatpumpRequirement(inputs.heatpumpRequirement),
    };
  }

  scaleInputsLogarithmically(inputs: ModelInputs): ModelInputs {
    const logInputs: ModelInputs = {
      noOfPeople: safeLog(inputs.noOfPeople),
      householdConsumption: safeLog(inputs.householdConsumption),
      evDistance: safeLog(inputs.evDistance),
      pv: safeLog(inputs.pv),
      battery: safeLog(inputs.battery),
      haveHeatpump: inputs.haveHeatpump,
      haveHeatingRod: inputs.haveHeatingRod,
      heatpumpConsumption: safeLog(inputs.heatpumpConsumption),
      heatpumpRequirement: safeLog(inputs.heatpumpRequirement),
    };

    return this.scaleInputsLinearly(logInputs, new Scaler(true));
  }

  updateDerivedValuesOnAutarkyChange(autarkies: {
    household: number;
    ev: number;
    heatpump: number;
  }): void {
    this.adjustToAutarky(this.state.household, autarkies.household);
    this.adjustToAutarky(this.state.ev, autarkies.ev);
    this.adjustToAutarky(this.state.heatpump, autarkies.heatpump);

    this.state.household.percentages.autarky = autarkies.household;
    this.state.ev.percentages.autarky = autarkies.ev;
    this.state.heatpump.percentages.autarky = autarkies.heatpump;

    this.calculateDerivedValues();
  }

  adjustToAutarky(component: ConsumptionModel, newAutarky: number): void {
    const newPVAndBattery = (component.consumption * newAutarky) / 100;
    const change = newPVAndBattery - component.fromPVAndBattery;

    let pvRatio = component.fromPV / component.fromPVAndBattery;
    if (isNaN(pvRatio)) pvRatio = 0.5;

    let batteryRatio = component.fromBattery / component.fromPVAndBattery;
    if (isNaN(batteryRatio)) batteryRatio = 0.5;

    component.fromPV += change * pvRatio;
    component.fromBattery += change * batteryRatio;

  }

  predictValues(): void {
    // heat-share
    this.state.heatShare.heatpump = this.predHeatShareHeatpump();
    this.state.heatShare.kessel = this.predHeatShareKessel();

    // heating-requirements
    // this.predictedOutputs.heatpumpRequirement = this.predHeatpumpReq();
    this.state.heatpump.requirement = this.state.inputs.heatpumpRequirement;
    this.state.heatingRod.requirement = this.predHeatingRodReq();
    this.state.heatingRod.consumption = this.state.heatingRod.requirement;
    this.state.heatingRod.fromPV = this.state.heatingRod.requirement;
    this.state.heatingRod.fromBattery = 0;

    // heatpump pv-battery
    this.state.heatpump.fromPV = this.predHeatpumpPV();
    this.state.heatpump.fromBattery = this.predictHeatpumpBattery();

    // household pv-battery
    this.state.household.fromPV = this.predHouseholdPV();
    this.state.household.fromBattery = this.predHouseholdBattery();

    // ev pv-battery
    this.state.ev.fromPV = this.predEVPV();
    this.state.ev.fromBattery = this.predEVBattery();
  }

  private adjustPredictions(): void {
    const { heatingRod } = this.state;
    heatingRod.consumption = this.state.heatingRod.requirement;

    // every predication should be greater than 0
    this.max0Adjustment();

    this.adjustToMax99Autarky(this.state.household);
    this.adjustToMax99Autarky(this.state.ev);
    this.adjustToMax99Autarky(this.state.heatpump);
    this.adjustToMax99Autarky(this.state.heatingRod);
  }

  private max0Adjustment(): void {
    const { household, ev, heatpump, heatingRod, heatShare } = this.state;
    household.fromBattery = Math.max(household.fromBattery, 0);
    household.fromPV = Math.max(household.fromPV, 0);

    ev.fromBattery = Math.max(ev.fromBattery, 0);
    ev.fromPV = Math.max(ev.fromPV, 0);

    heatpump.fromBattery = Math.max(heatpump.fromBattery, 0);
    heatpump.fromPV = Math.max(heatpump.fromPV, 0);

    heatingRod.fromBattery = Math.max(heatingRod.fromBattery, 0);
    heatingRod.fromPV = Math.max(heatingRod.fromPV, 0);

    heatShare.heatpump = Math.max(heatShare.heatpump, 0);
    heatShare.kessel = Math.max(heatShare.kessel, 0);
  }

  private adjustToMax99Autarky(consumptionElement: ConsumptionModel): void {
    let battery = consumptionElement.fromBattery;
    let pv = consumptionElement.fromPV;
    const maxTotal = consumptionElement.consumption;

    const total = battery + pv;

    // 99% of self-autarky at max
    const maxTotalShouldBe = maxTotal * 0.99;

    if (total <= maxTotalShouldBe) return;

    const percentOverflowed = (total / maxTotalShouldBe) * 100 - 100;

    battery -= (battery / 100) * percentOverflowed;
    pv -= (pv / 100) * percentOverflowed;

    consumptionElement.fromBattery = battery;
    consumptionElement.fromPV = pv;
  }

  calculateDerivedValues(): void {
    const { household, ev, heatpump, heatingRod } = this.state;

    this.calculateDerivedValuesForConsumptionModel(household);
    this.calculateDerivedValuesForConsumptionModel(ev);
    this.calculateDerivedValuesForConsumptionModel(heatingRod);
    this.calculateDerivedValuesForConsumptionModel(heatpump);

    this.state.consumption =
      household.consumption + ev.consumption + heatpump.requirement + heatingRod.requirement;

    this.state.fromBattery = household.fromBattery + ev.fromBattery + heatpump.fromBattery;
    this.state.fromBatteryWithAdjustment = this.state.fromBattery / 0.90606635699992;
    this.state.fromPV = household.fromPV + ev.fromPV + heatpump.fromPV;
    this.state.fromPVAndBattery = this.state.fromBattery + this.state.fromPV;
    this.state.fromPVAndAdjustedBattery = this.state.fromBatteryWithAdjustment + this.state.fromPV;

    const pvProduction = this.state.inputs.pv * 999.6088673569609;
    this.state.feedIn = pvProduction - this.state.fromPVAndAdjustedBattery;
    this.state.fromGrid = this.state.consumption - this.state.fromPVAndBattery;

    this.state.percentages.autarky = getPercentage(
      this.state.fromPVAndBattery,
      this.state.consumption,
    );
    this.state.percentages.fromBattery = getPercentage(
      this.state.fromBattery,
      this.state.consumption,
    );
    this.state.percentages.fromPV = getPercentage(this.state.fromPV, this.state.consumption);
    this.state.percentages.fromGrid = getPercentage(this.state.fromGrid, this.state.consumption);
    this.state.percentages.maxPossibleAutarky = this.state.percentages.autarky;
  }

  calculateDerivedValuesForConsumptionModel(component: ConsumptionModel): void {
    component.fromPVAndBattery = component.fromBattery + component.fromPV;
    component.fromGrid = component.consumption - component.fromPVAndBattery;

    component.percentages.autarky = getPercentage(
      component.fromPVAndBattery,
      component.consumption,
    );
    component.percentages.fromBattery = getPercentage(component.fromBattery, component.consumption);
    component.percentages.fromPV = getPercentage(component.fromPV, component.consumption);
    component.percentages.fromGrid = getPercentage(component.fromGrid, component.consumption);
  }

  private hasNoHeatpump(): boolean {
    return !this.state.inputs.haveHeatpump;
  }

  private hasHeatpump(): boolean {
    return !this.hasNoHeatpump();
  }

  private hasNoHeatingRod(): boolean {
    return !this.state.inputs.haveHeatingRod;
  }

  private hasHeatingRod(): boolean {
    return !this.hasNoHeatingRod();
  }

  private hasNoBattery(): boolean {
    return this.state.inputs.battery <= 4;
  }

  private hasBattery(): boolean {
    return !this.hasNoBattery();
  }

  private hasNoEV(): boolean {
    return this.state.inputs.evDistance === 0;
  }

  private hasEV(): boolean {
    return !this.hasNoEV();
  }

  private getDatasetPartitionKey(): PartitionKeys {
    const keyParts = [];
    if (this.hasBattery()) keyParts.push("YB");
    else keyParts.push("NB");

    if (this.hasEV()) keyParts.push("YE");
    else keyParts.push("NE");

    if (this.hasHeatpump()) keyParts.push("YHP");
    else keyParts.push("NHP");

    if (this.hasHeatingRod()) keyParts.push("YHR");
    else keyParts.push("NHR");

    return keyParts.join("_") as PartitionKeys;
  }

  predHeatShareHeatpump(): number {
    if (this.hasNoHeatpump()) {
      return 0;
    }

    const features = [
      this.linearScaledInputs.noOfPeople,
      this.linearScaledInputs.haveHeatpump ? 1 : 0,
      this.linearScaledInputs.haveHeatingRod ? 1 : 0,
      this.linearScaledInputs.heatpumpConsumption,
    ];

    const partitionKey = this.getDatasetPartitionKey() as HaveHeatpumpKeys;
    const coeffs: Matrix[] = CoeffsAndIntercepts[partitionKey]["heat-share-heatpump"].coeff;
    const intercepts: Matrix = CoeffsAndIntercepts[partitionKey]["heat-share-heatpump"].intercept;

    return forwardPropogation(features, coeffs, intercepts);
  }

  predHeatShareKessel(): number {
    if (this.hasHeatpump()) return 0;

    const features = [
      this.linearScaledInputs.noOfPeople,
      this.linearScaledInputs.heatpumpConsumption,
      this.linearScaledInputs.haveHeatpump ? 1 : 0,
      this.linearScaledInputs.haveHeatingRod ? 1 : 0,
    ];

    const partitionKey = this.getDatasetPartitionKey() as HaveHeatShareKesselKeys;
    const coeffs: Matrix[] = CoeffsAndIntercepts[partitionKey]["heat-share-kessel"].coeff;
    const intercepts: Matrix = CoeffsAndIntercepts[partitionKey]["heat-share-kessel"].intercept;

    return forwardPropogation(features, coeffs, intercepts);
  }

  predHeatpumpReq(): number {
    if (this.hasNoHeatpump()) return 0;

    const features = [
      this.linearScaledInputs.noOfPeople,
      this.linearScaledInputs.householdConsumption,
      this.linearScaledInputs.haveHeatpump ? 1 : 0,
      new Scaler().heatShareHeatpump(this.state.heatShare.heatpump),
    ];

    const partitionKey = this.getDatasetPartitionKey() as HaveHeatpumpKeys;
    const coeffs: Matrix[] = CoeffsAndIntercepts[partitionKey]["heatpump-requirement"].coeff;
    const intercepts: Matrix = CoeffsAndIntercepts[partitionKey]["heatpump-requirement"].intercept;

    return forwardPropogation(features, coeffs, intercepts);
  }

  predHeatingRodReq(): number {
    if (this.hasNoHeatingRod()) return 0;

    const features = [
      this.logScaledInputs.noOfPeople,
      this.logScaledInputs.pv,
      this.logScaledInputs.battery,
      this.logScaledInputs.householdConsumption,
      this.logScaledInputs.haveHeatingRod ? 1 : 0,
      new Scaler(true).heatpumpRequirement(safeLog(this.state.inputs.heatpumpRequirement)),
    ];

    const partitionKey = this.getDatasetPartitionKey() as HaveHeatingRodKeys;
    const coeffs: Matrix[] = CoeffsAndIntercepts[partitionKey]["heating-rod-requirement"].coeff;
    const intercepts: Matrix =
      CoeffsAndIntercepts[partitionKey]["heating-rod-requirement"].intercept;

    return forwardPropogation(features, coeffs, intercepts);
  }

  predHeatpumpPV(): number {
    if (this.hasNoHeatpump()) return 0;

    const features = [
      new Scaler(true).heatpumpRequirement(safeLog(this.state.inputs.heatpumpRequirement)),
      this.logScaledInputs.pv,
      this.logScaledInputs.noOfPeople,
      this.logScaledInputs.householdConsumption,
      this.logScaledInputs.battery,
    ];

    const partitionKey = this.getDatasetPartitionKey() as HaveHeatpumpKeys;
    const coeffs: Matrix[] = CoeffsAndIntercepts[partitionKey].heatpump.pv.coeff;
    const intercepts: Matrix = CoeffsAndIntercepts[partitionKey].heatpump.pv.intercept;

    return forwardPropogation(features, coeffs, intercepts);
  }

  predictHeatpumpBattery(): number {
    if (this.hasNoHeatpump()) return 0;
    if (this.hasNoBattery()) return 0;

    const features = [
      new Scaler(true).heatpumpRequirement(safeLog(this.state.inputs.heatpumpRequirement)),
      this.logScaledInputs.battery,
      this.logScaledInputs.evDistance,
      this.logScaledInputs.householdConsumption,
    ];

    const partitionKey = this.getDatasetPartitionKey() as HaveHeatpumpKeysWithBattery;
    const coeffs: Matrix[] = CoeffsAndIntercepts[partitionKey].heatpump.battery.coeff;
    const intercepts: Matrix = CoeffsAndIntercepts[partitionKey].heatpump.battery.intercept;

    return forwardPropogation(features, coeffs, intercepts);
  }

  predHeatingRodPV(): number {
    return this.state.heatingRod.requirement;
  }

  predHouseholdPV(): number {
    const features = [this.logScaledInputs.householdConsumption, this.logScaledInputs.pv];

    const partitionKey = this.getDatasetPartitionKey();
    const coeffs: Matrix[] = CoeffsAndIntercepts[partitionKey].household.pv.coeff;
    const intercepts: Matrix = CoeffsAndIntercepts[partitionKey].household.pv.intercept;

    return forwardPropogation(features, coeffs, intercepts);
  }

  predHouseholdBattery(): number {
    if (this.hasNoBattery()) return 0;

    const features = [
      this.logScaledInputs.battery,
      this.logScaledInputs.householdConsumption,
      this.logScaledInputs.heatpumpConsumption,
      this.logScaledInputs.pv,
      this.logScaledInputs.evDistance,
    ];

    const partitionKey = this.getDatasetPartitionKey() as HaveBattery;
    const coeffs: Matrix[] = CoeffsAndIntercepts[partitionKey].household.battery.coeff;
    const intercepts: Matrix = CoeffsAndIntercepts[partitionKey].household.battery.intercept;

    return forwardPropogation(features, coeffs, intercepts);
  }

  predEVPV(): number {
    if (this.hasNoEV()) return 0;

    const features = [
      this.logScaledInputs.evDistance,
      this.logScaledInputs.pv,
      new Scaler(true).heatingRodRequirement(safeLog(this.state.heatingRod.requirement)),
    ];

    const partitionKey = this.getDatasetPartitionKey() as HaveEVKeys;
    const coeffs: Matrix[] = CoeffsAndIntercepts[partitionKey].ev.pv.coeff;
    const intercepts: Matrix = CoeffsAndIntercepts[partitionKey].ev.pv.intercept;

    return forwardPropogation(features, coeffs, intercepts);
  }

  predEVBattery(): number {
    if (this.hasNoEV()) return 0;
    if (this.hasNoBattery()) return 0;
    const features = [
      this.logScaledInputs.evDistance,
      this.logScaledInputs.battery,
      this.logScaledInputs.pv,
      this.logScaledInputs.householdConsumption,
      new Scaler(true).heatpumpRequirement(safeLog(this.state.inputs.heatpumpRequirement)),
    ];

    const partitionKey = this.getDatasetPartitionKey() as HaveEvWithBattery;
    const coeffs: Matrix[] = CoeffsAndIntercepts[partitionKey].ev.battery.coeff;
    const intercepts: Matrix = CoeffsAndIntercepts[partitionKey].ev.battery.intercept;

    return forwardPropogation(features, coeffs, intercepts);
  }
}
