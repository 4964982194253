import { Divider } from "@mui/material";
import { Empty } from "antd";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ProductDB } from "src/db-types";

import ProductCard from "./ProductCard/ProductCard";

import { ProductSelectors } from "src/redux/selectors";

interface Props {
  isListOpen: boolean;
  carCount: number;
  onSelectionChange: (cars: Array<{ car: ProductDB; isLeasing: boolean }>) => void;
  notShownCarIds?: string[];
}
export const CarList: React.FC<Props> = (props: Props) => {
  const { carCount, onSelectionChange, notShownCarIds, isListOpen } = props;

  const { t } = useTranslation();

  const [selected, setSelected] = useState<Array<{ car: ProductDB; isLeasing: boolean }>>([]);

  const carProducts = useSelector(ProductSelectors.getCars);

  const alertUserFromAddingExtraCars = useCallback((): void => {
    toast.warn(
      t(
        `You can't select more than ${carCount} cars as you have only added ${selected.length} cars`,
      ),
    );
  }, [carCount, selected.length, t]);

  const addCarToSelection = useCallback(
    (carId: string, isLeasing: boolean) => {
      if (selected.length >= carCount) {
        alertUserFromAddingExtraCars();
        return;
      }

      const selectedCar = carProducts.find((c) => c._id === carId);
      if (!selectedCar) {
        return;
      }

      setSelected((prev) => [...prev, { car: cloneDeep(selectedCar), isLeasing }]);
    },
    [alertUserFromAddingExtraCars, carCount, carProducts, selected.length],
  );

  const removeCarFromSelection = useCallback((carId: string) => {
    setSelected((prev) => prev.filter((d) => d.car._id !== carId));
  }, []);

  useEffect(() => {
    onSelectionChange(selected);
  }, [onSelectionChange, selected]);

  const shownCars = useMemo(() => {
    return carProducts.filter((car) => !notShownCarIds?.includes(car._id));
  }, [carProducts, notShownCarIds]);

  if (!isListOpen) {
    return <></>;
  }

  if (shownCars.length === 0) {
    return (
      <Empty
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
        description={t("No product available")}
      />
    );
  }

  return (
    <div className="product_list_container">
      {shownCars.map((car, index) => {
        return (
          <>
            <ProductCard
              car={car}
              isSelected={selected.some((c) => c.car._id === car._id)}
              onRemove={removeCarFromSelection}
              onSelect={addCarToSelection}
              isLeasing={selected.find((c) => c.car._id === car._id)?.isLeasing}
              key={car._id}
            />
            {index + 1 < shownCars.length && <Divider />}
          </>
        );
      })}
    </div>
  );
};
