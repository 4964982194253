import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CarSelectorWrapper } from "./CarSelectorStyles";

interface Props {
  addCarCount?: number;
}

const CarHeader: React.FC<Props> = (props: Props) => {
  const { addCarCount } = props;
  const { t } = useTranslation();

  const showChooseText = !!addCarCount && addCarCount > 0;

  return (
    <div className="header_text">
      <div className="heading">
        E-Fahrzeuge <span>Wählen Sie ihre Präferenzen</span>
      </div>
      {showChooseText && (
        <div
          className="heading"
          style={{
            fontSize: "21px",
          }}
        >
          {t("Choose any {{ count }} cars", {
            count: addCarCount,
          })}
        </div>
      )}
    </div>
  );
};

export default CarHeader;
