import { clone } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const addHouseTop = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addHouseTop>,
): ProjectState => {
  const newHouseTop = clone(payload);

  return {
    ...currentState,
    images: {
      ...currentState.images,
      houseTop: newHouseTop,
    },
  };
};
