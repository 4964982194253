import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import { ShareIcon } from "src/assets/svgs";
import { Mixpanel } from "src/mix-panel/mixpanel";
import * as trackingEvents from "src/mix-panel/trackingEvents";
import { MixButton, ShareDrawer } from "src/shared/components";

import { Container } from "./ShareButton.styles";

import { AppSelectors } from "src/redux/selectors";

const ShareProjectButton: React.FC = () => {
  const [query] = useSearchParams();
  const [hover, setHover] = useState<any>();
  const [trackId, setTrackId] = useState<any | null>(null);
  const [visibleShare, setvisibleShare] = useState(false);

  const currentStep = useSelector(AppSelectors.getCurrentStep);

  const onMouseHoverOnShare = useCallback(() => {
    Mixpanel.track(trackingEvents.USER_IS_HOVRING_ON_SHARE, {});

    const timer = (): any =>
      setTimeout(() => {
        setHover(true);
      }, 200);

    const timerId = timer();
    setTrackId(timerId);
  }, []);

  const onMouseLeaveOnShare = useCallback(() => {
    Mixpanel.track(trackingEvents.USER_IS_HOVRING_ON_SHARE, {});
    if (!hover) {
      clearTimeout(trackId);
    }
    setHover(false);
  }, [hover, trackId]);

  const onClickOnShare = useCallback(() => {
    if (window.innerWidth < 700) {
      setvisibleShare(true);
    } else {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_PROJECT_SHARE_BUTTON, {});
      setHover(true);
    }
  }, []);

  return (
    <Container currentStep={currentStep}>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#EDF5FFAA",
        }}
      >
        <div
          className={"buttonShare"}
          onMouseEnter={onMouseHoverOnShare}
          onMouseLeave={onMouseLeaveOnShare}
          onClick={onClickOnShare}
        >
          <ShareIcon />
          {hover && window.innerWidth > 700 && <MixButton />}
        </div>
      </div>
      <ShareDrawer
        referral={query.get("referral")}
        visibleShare={visibleShare}
        setvisibleShare={setvisibleShare}
      />
    </Container>
  );
};

export default ShareProjectButton;
