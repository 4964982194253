import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useNumberFormat } from "src/hooks/useNumberFormat";
import { MasterInput } from "src/shared/components";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import { HeatpumpConfiguration } from "src/redux/project";
import { ProjectSelectors } from "src/redux/selectors";

interface ElectricityConsumptionLineProps {
  onValueChange: (heatpumpPartData: Partial<HeatpumpConfiguration>) => void;
}
const ElectricityConsumptionLine: React.FC<ElectricityConsumptionLineProps> = (
  props: ElectricityConsumptionLineProps,
) => {
  const { onValueChange } = props;

  const { formatNumber } = useNumberFormat();
  const { t } = useTranslation();

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);
  const webConstantCalculation = useSelector(ProjectSelectors.getConstants);

  const onSliderChangeHandler = useCallback(
    (value: number) => {
      onValueChange({
        electricityCostHeatpump: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line>
      <ParameterStyles.Label>{t("Electricity consumption heat pump")}</ParameterStyles.Label>

      <ParameterStyles.Value>
        <MasterInput
          value={Math.round(configuration.consumptionHeatpump / (configuration.jaz || 3.5))}
          onChange={onSliderChangeHandler}
          label="kWh"
        />
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default ElectricityConsumptionLine;
