import { useTranslation } from "react-i18next";

import { InfoIcon } from "src/shared/components";

import homeImage from "../../../assets/images/solar-image.png";
import economicsImage from "../../../assets/overview/economics.svg";
import HomeHeat from "../../../assets/overview/HomeHeat.svg";
import { MultiTenantContainer } from "../MultiTenantStyles";
import ReactToPrint from "react-to-print";
import OverviewCard from "./overviewCards/OverviewCard";
import {
  MainContainer,
  OverviewCardsContainer,
  OverviewContainer,
  OverviewCostsCardsContainer,
  OverviewElectricityCardsContainer,
  OverviewTitle,
  StyledButton,
  StyledButtons,
  StyledROI,
} from "./OverviewModelStyles";

import ElectricitySupplyChart from "src/pages/ApplicationPage/components/ApplicationStep1/components/ImageConfiguration/ConsumptionCharts/components/ElectricitySupplyChart/ElectricitySupplyChart";
import { useMemo, useRef } from "react";
import SelfSupplyChart from "src/pages/ApplicationPage/components/ApplicationStep1/components/ImageConfiguration/ConsumptionCharts/components/SelfSupplyChart/SelfSupplyChart";
import BarChart from "./returnsBarChart/BarchartTest";
import useCalculateMultiTenants from "src/hooks/useCalculateMultiTenants";
import { useSelector } from "react-redux";
import { ProjectSelectors } from "src/redux/selectors";
import { getFinancialDetails } from "src/redux/project/selectors";
import { formatNumberByLang, useYearlySystemProfit } from "src/hooks";
import { PrimaryButton, SecondaryButton } from "src/shared/StyledComponents";
import ReturnOnInvest from "src/pages/ApplicationPage/shared/Finance/components/ReturnOnInvest/ReturnOnInvest";
import { ImageConfiguration } from "src/pages/ApplicationPage/components/ApplicationStep1/components";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const OverviewModal = () => {
  const { t } = useTranslation();
  const { expectedTenants, electricityPrice } = useSelector(ProjectSelectors.getHousehold);
  const {
    costs: { totalProjectCost },
    amortizationTime,
    pricePerKwh,
  } = useSelector(getFinancialDetails);

  const { profitByYear } = useYearlySystemProfit();
  const selectedInstallers = useSelector(ProjectSelectors.getSelectedInstaller);

  const {
    newSelfConsumption,
    chargeForTotalsAndGenerationMeters,
    multiTenantTurnOverBaseFees,
    operationalCost,
    feedInForMultiTenants,
    yearlyGains,
    batteryStorage,
    totalInvestMent,
    systemSize,
    totalEnergyProduction,
    totalEnergyConsumption,
    advantageTenantYearly,
    projectingCost,
    baseTiff,
    equipmentCost,
    fullFeedInROI,
    ROIinMeterGrid,
  } = useCalculateMultiTenants();

  const componentRef = useRef();

  return (
    // @ts-ignore
    <MultiTenantContainer ref={componentRef}>
      <div className="headers">
        <div className="make_flex">
          <div className="head">{t("Your tenant electricity concept")}</div> <InfoIcon />
        </div>
      </div>
      <OverviewContainer>
        <ImageConfiguration />
        <div className="label">
          {t("Your project is very profitable")}
          <span>🎉</span>
        </div>
      </OverviewContainer>
      <OverviewCardsContainer>
        <OverviewCard isFixed title={`${expectedTenants} ${t("Units")}`} text={t("Tenants")} />
        <OverviewCard
          isFixed
          title={`${formatNumberByLang(String(totalInvestMent?.toFixed()))}€`}
          text={t("Total investment")}
        />
        <OverviewCard
          isFixed
          title={`${formatNumberByLang(String(profitByYear(25)?.profit))}€`}
          text={t("Profit")}
          subtext={t("(in 25 years)")}
          isGreen
        />
        <OverviewCard
          isFixed
          title={`${amortizationTime}`}
          text={t("Amortisation time")}
          subtext={t("(in years)")}
        />
        <OverviewCard
          isFixed
          title={`${formatNumberByLang(String(systemSize?.toFixed()))} kWP`}
          text={t("System size")}
        />
        <OverviewCard
          isFixed
          title={`${formatNumberByLang(String(totalEnergyProduction?.toFixed()))} kWh`}
          text={t("Total energy production")}
          subtext={t("(annually)")}
        />
        <OverviewCard isFixed title={`${batteryStorage} kWh`} text={t("Battery Size")} />
      </OverviewCardsContainer>
      <OverviewTitle>{t("Electricity usage")}</OverviewTitle>
      <div className="flex flex-wrap w-full justify-around items-center">
        <MainContainer>
          <ElectricitySupplyChart hideTitle={true} disableExpandButton={true} />
          <p className="font-bold">{t("Direct Supply")}</p>
          <p>{t("Consumed PV energy direct use in the building")}</p>
        </MainContainer>
        <MainContainer>
          <SelfSupplyChart hideTitle={true} isOpen={true} disableExpand={false} />
          <div>
            {/* <p className="text-3xl font-bold">43,9%</p> */}
            <p className="font-bold">{t("Self-consumption vs. Feed-in")}</p>
            <p>{`${expectedTenants} ${t("Units")}`}</p>
          </div>
        </MainContainer>
      </div>
      {/* <img src={electricityImage} alt="electricity" /> */}
      <OverviewTitle>{t("Tenant electricity tariff")}</OverviewTitle>
      <OverviewElectricityCardsContainer>
        <OverviewCard
          isFixed
          icon={HomeHeat}
          title={`${formatNumberByLang(String(electricityPrice))} Ct / kWh`}
          text={t("Ø electricity price PV")}
          subtext={t("(annually)")}
        />
        <span className="dash" />
        <OverviewCard
          isFixed
          icon={HomeHeat}
          title={`${formatNumberByLang(String(pricePerKwh))} Ct / kWh`}
          text={t("Energy Price Grid")}
          subtext={t("(annually)")}
        />
        <span className="dash" />
        <OverviewCard
          isFixed
          icon={HomeHeat}
          title={`${formatNumberByLang(String(baseTiff))} € / ${t("Month")}`}
          text={t("Ø Base tariff")}
          subtext={t("(annually)")}
        />
      </OverviewElectricityCardsContainer>
      <OverviewTitle>{t("Tenant electricity investment costs")}</OverviewTitle>
      <OverviewCostsCardsContainer>
        <OverviewCard title={`${projectingCost} €`} text={t("Project planning cost")} />
        <OverviewCard
          title={`${equipmentCost}€`}
          text={t("Equipment cost Multi tenant")}
          subtext={t("(including remote reading)")}
        />
      </OverviewCostsCardsContainer>
      <OverviewTitle>{t("Tenant electricity operating costs")}</OverviewTitle>
      <OverviewCostsCardsContainer>
        <OverviewCard
          title={`${formatNumberByLang(String(34800))} € / ${t("Year")}`}
          text={t("Grid operation")}
          subtext={t("(annually)")}
        />
        <OverviewCard
          title={`${formatNumberByLang(String(600))} € / ${t("Counter and Month")}`}
          text={`Ø ${t("Tenant savings")}`}
          subtext={t("(annually)")}
        />
      </OverviewCostsCardsContainer>
      <OverviewTitle>{t("Tenant electricity")}</OverviewTitle>
      <OverviewCardsContainer>
        <OverviewCard
          title={`${formatNumberByLang(String(multiTenantTurnOverBaseFees))} € / ${t("Year")}`}
          text={t("Basic fee revenues")}
          subtext={t("(tenant electricity)")}
        />
        <OverviewCard
          title={`${formatNumberByLang(String(operationalCost))} € / ${t("Year")}`}
          text={t("Operating Cost")}
          subtext={t("(tenant electricity)")}
        />
      </OverviewCardsContainer>
      <OverviewTitle>{t("Return on Investment")}</OverviewTitle>
      <BarChart fullFeedInROI={fullFeedInROI} ROIinMeterGrid={ROIinMeterGrid} />
      <OverviewTitle>{t("Tenant Electricity Profitabilit")}</OverviewTitle>
      <StyledROI>
        <ReturnOnInvest isRenting={false} />
      </StyledROI>

      <BulletPoint points="Metering and remote reading technology, infrastructure costs such as meter cabinets or transformer measurements are not included and must be determined by the electrician.#Fee for electricity meters.#All tenant electricity participants + electricity meters (Z1 + Z2)."></BulletPoint>
      <StyledButtons>
        <PrimaryButton style={{ width: "277px" }}>{t("Request offer")}</PrimaryButton>
        {/* <StyledButton>{t("Print PDF")}</StyledButton> */}
        <ReactToPrint
          trigger={() => <StyledButton>{t("Print PDF")}</StyledButton>}
          // @ts-ignore
          content={() => componentRef.current}
        />
      </StyledButtons>
    </MultiTenantContainer>
  );
};

const BulletPoint = (prop: any) => {
  const points = prop.points.split("#");
  const { t } = useTranslation();
  return points.map((data: string, i: number) => {
    return (
      <>
        <div className=" text-xs flex flex-row w-full">
          <div className="rounded-2xl p-1 w-8 h-6 text-center bg-[#2D4764] text-white mx-4">
            {i + 1 + "."}
          </div>
          <div className="">{t(data)}</div>
          <br />
        </div>
      </>
    );
  });
};

export default OverviewModal;
