import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxAction } from "src/redux/common.types";
import { POPUP_KEYS } from "src/redux/popups";
import { closePopup } from "src/redux/popups/action/action.creators";
import { PopupSkeleton } from "src/shared/components";
import { ButtonWrapper, Container } from "./styles";
import { useTranslation } from "react-i18next";
import ThreeDModelInfo from "src/pages/ApplicationPage/components/ApplicationStep3/components/3DViewer/components/3DModalInfo/ThreeDModelInfo";
import { ProjectSelectors } from "src/redux/selectors";
import { ProjectActions } from "src/redux/actionCreators";
import Sunnydesign from "src/assets/Sunnydesign.png";
import Solaredge from "src/assets/Solaredge.png";
import PVSol from "src/assets/PVSol.png";
import useRequestBakeModelHook from "src/hooks/useRequestBakeModel";

const RequestModelConfirmation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ThreeDModels = useSelector(ProjectSelectors.getDroneData);
  const { requestBakeModel } = useRequestBakeModelHook();

  const handleClose = useCallback(
    (): ReduxAction<any> => dispatch(closePopup(POPUP_KEYS.request_pv_model)),
    [dispatch],
  );

  const droneDataChangeHandler: any = (value: string, name: string): any => {
    dispatch(
      ProjectActions.updateDroneData({
        [name]: value,
      }),
    );
  };

  const onYesClick = () => {
    requestBakeModel();
  }

  return (
    <PopupSkeleton handleClose={handleClose} open={true}>
      <Container>
        <div className="sub_container">
          <div className="title">
            {t("Thank you for requesting to add a 3D-Model from your drone data")}
          </div>
          <div className="description">
            {t(
              "Currently you still have uploads ongoing we will start processing as soon as the current upload is completed",
            )}
          </div>
          <div className="flex_container">
            <ButtonWrapper width="188px" color="#FFD75D" textColor="black" onClick={onYesClick}>
              {t("YES")}
            </ButtonWrapper>
            <ButtonWrapper width="400px" color="#2D4764" textColor="white" onClick={handleClose}>
              {t("No, I will Upload more Data")}
            </ButtonWrapper>
          </div>
        </div>
        <ThreeDModelInfo
          ThreeDModels={ThreeDModels}
          droneDataChangeHandler={droneDataChangeHandler}
          Sunnydesign={Sunnydesign}
          Solaredge={Solaredge}
          PVSol={PVSol}
        />
      </Container>
    </PopupSkeleton>
  );
};

export default RequestModelConfirmation;
