import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { MasterInput } from "src/shared/components";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import { HeatpumpConfiguration } from "src/redux/project";
import { ProjectSelectors } from "src/redux/selectors";

interface HeatpumpDimensionLineProps {
  onValueChange: (heatpumpPartData: Partial<HeatpumpConfiguration>) => void;
}
const HeatpumpDimensionLine: React.FC<HeatpumpDimensionLineProps> = (
  props: HeatpumpDimensionLineProps,
) => {
  const { onValueChange } = props;

  const { t } = useTranslation();

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);

  const onSliderChangeHandler = useCallback(
    (value: any) => {
      onValueChange({
        heatpumpDimension: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line>
      <ParameterStyles.Label>{t("Heatpump dimension")}</ParameterStyles.Label>
      <ParameterStyles.Value>
        <MasterInput
          value={configuration.heatpumpDimension}
          onChange={onSliderChangeHandler}
          label="kw"
        />
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default HeatpumpDimensionLine;
