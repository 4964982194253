import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface MasterCheckBoxProps {
  onChange?: (e: boolean) => void;
  checked: any;
  children?: any;
}
const MasterCheckbox: React.FC<MasterCheckBoxProps> = (props: MasterCheckBoxProps) => {
  const { onChange, children, checked } = props;
  return (
    <Checkbox
      onChange={(e) => {
        if (onChange) onChange(e.target.checked);
      }}
      checked={checked}
    >
      {children}
    </Checkbox>
  );
};

export default MasterCheckbox;
