import styled from "styled-components";

export const PrimaryButton = styled.button`
  margin-block: 0.5rem;
  margin-inline: 1rem;
  padding: 0.4rem;

  border-radius: 20px;
  border: 1px solid var(--primary-color);

  background: var(--primary-color);

  color: var(--blue);
  cursor: pointer;

  text-align: center;
  font-family: "Nunito";
  font-weight: 600;
  font-size: 14px;
`;

export const SecondaryButton = styled(PrimaryButton)`
  border: 1px solid var(--blue);
  background: inherit;
  padding-inline: 10px;
  :disabled {
    cursor: not-allowed;
  }
`;
