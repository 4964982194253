import { ApplicationState } from "../../types";

import { actionCreators } from "src/redux/application";

export const updateProperties = (
  currentState: ApplicationState,
  { payload }: ReturnType<typeof actionCreators.updateProperties>,
): ApplicationState => {
  if (!payload) {
    return currentState;
  }
  return {
    ...currentState,
    properties: payload
  };
};
