import { formatNumberByLang } from "src/hooks/useNumberFormat";
import { OverallConsumptionModel } from "src/prediction-model/types/model.types";
import { DonoughtRecord } from "src/shared/components";

export const getChartRecordsFromModel = (model: OverallConsumptionModel): DonoughtRecord[] => {
  return [
    {
      color: "#FFB45D",
      hoverLabel: "Solar for heat supply",
      value: parseInt(model.heatpump.fromPVAndBattery.toFixed(0)),
      shadowColor: "rgba(181, 38, 43, 1)",
      label: {
        mainLabel: "Solar for heat supply",
        subLine: `${formatNumberByLang(
          model.heatpump.percentages.autarky.toFixed(0),
        )}%, ${formatNumberByLang(model.heatpump.fromPVAndBattery.toFixed(0))} kWh`,
      },
    },
    {
      color: "#B5262B",
      hoverLabel: "Grid supply Heat",
      value: parseInt(model.heatpump.fromGrid.toFixed(0)),
      shadowColor: "rgba(181, 38, 43, 1)",
      label: {
        mainLabel: "Grid supply Heat",
        subLine: `${formatNumberByLang(
          model.heatpump.percentages.fromGrid.toFixed(0),
        )}%, ${formatNumberByLang(model.heatpump.fromGrid.toFixed(0))} kWh`,
      },
    },
    {
      color: "#B5268D",
      hoverLabel: "Heating-rod",
      value: parseInt(model.heatingRod.fromPVAndBattery.toFixed(0)),
      shadowColor: "rgba(181, 38, 141, 1)",
      label: {
        mainLabel: "Heating-rod",
        subLine: `${formatNumberByLang(
          model.heatingRod.percentages.autarky.toFixed(0),
        )}%, ${formatNumberByLang(model.heatingRod.fromPVAndBattery.toFixed(0))} kWh`,
      },
    },
  ];
};
