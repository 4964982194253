/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { makeStyles } from "@mui/styles";
import styled from "styled-components";

import { colors } from "src/utils/colors";

interface ContainerProps {
  currentStep: number;
}
export const Container = styled("div")<ContainerProps>`
  .buttonShare {
    height: 2.5rem;
    aspect-ratio: 1;
    color: #1b4963;
    border-radius: 25px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    transition-delay: 1.3s;
    cursor: pointer;
    padding: 10px 10px;
    border: 2px solid #2d4764;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media screen and (min-width: 700px) {
      :hover {
        width: 192px;
        transition: 0.3s;
        height: 40px !important;
      }
    }
    ::after {
      transition: 0.3s;
    }
    .newBtt {
      border-radius: 25px;
    }
  }

  .smallScreenButton {
    img {
      min-width: 24px;
    }
  }

  @media screen and (max-width: 500px) {
    ${({ currentStep }) => currentStep !== 0 && "display: none"}
  }
`;
