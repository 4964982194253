import { AxiosResponse } from "axios";

import {
  BackendApis,
  GoogleLoginResponse,
  GoogleRefreshRequest,
  GoogleRefreshResponse,
} from "src/api";

import { ApiAxiosClient } from "src/axios";
import { AuthMethod } from "src/popups";

import { logout } from "./logout";

const googleRefreshTimeout = 0;
const MILLIS_IN_50_MINUTES = 50 * 60 * 1000;
const MILLIS_IN_10_SECONDS = 10 * 1000;

export const googleRefreshAPI = async (
  refreshToken: string,
): Promise<AxiosResponse<GoogleRefreshResponse, any>> => {
  return await ApiAxiosClient.request<GoogleRefreshRequest, AxiosResponse<GoogleRefreshResponse>>({
    ...BackendApis.googleRefresh,
    data: {
      refreshToken,
    },
  });
};

export const handleSuccessRefresh = (data: AxiosResponse<GoogleRefreshResponse, any>): void => {
  const {
    // eslint-disable-next-line
    data: { id_token, refresh_token },
  } = data;
  localStorage.setItem("token", id_token);
  localStorage.setItem("refresh_token", refresh_token);
  localStorage.setItem("authMode", AuthMethod.google);
};

export const set50MinsGoogleRefresh = (): void => {
  if (googleRefreshTimeout) clearTimeout(googleRefreshTimeout);

  setTimeout(() => {
    const method = localStorage.getItem("authMode") as AuthMethod | null;
    if (method !== AuthMethod.google) return;

    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) return;

    googleRefreshAPI(refreshToken)
      .then((response) => {
        handleSuccessRefresh(response);
        set50MinsGoogleRefresh();
      })
      .catch((error) => {
        console.error(error);
        logout();
      });
  }, MILLIS_IN_50_MINUTES);
};
