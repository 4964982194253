import styled from "styled-components";

export const HomeEnergyWrapper = styled("div")`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 19px;
  .first_box {
    padding: 20px;
    padding-top: 0px;
    border-radius: 10px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 50px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    margin: auto;
    width: 95%;
    .small_heading {
      display: flex;
      justify-content: flex-start;
      gap: 5px;
      align-items: center;
      /* font-family: "Inter"; */
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.38723403215408325px;
      text-align: left;
      color: rgba(77, 126, 109, 1);
      span {
        color: rgba(103, 116, 142, 1);
      }
    }
  }
  .wra {
    display: flex;
    flex-direction: column;
    span {
      font-size: 14px !important;
    }
  }
  .heading {
    padding-left: 6px;
    margin-top: 10px;
  }
  .energyHeading {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.15px;
    color: #2d4764;
  }
  .secound_box {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    margin: auto;
    margin-bottom: 20px;
    width: 95%;
  }
  .home_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .home_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96%;
    position: relative;
    img {
      padding-left: 11px;
    }
    padding-bottom: 44px;
  }
  .info_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .upper {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #4d7e6d;
  }
  .lower {
    font-family: "Nunito";
    font-style: normal;
    font-size: 13px;
    line-height: 25px;
    color: rgba(45, 71, 100, 1);
  }
  .make_in_same_line {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 155px;
  }
  .upper_yellow {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: #ffd75d;
  }
  .upper_dark_yellow {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #f2994a;
  }
  .upper_green_yellow {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #287e97;
  }
  .upper_red_dark {
    color: #b5268d;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }
  .flex_row {
    display: flex;
    flex-direction: row;
  }
  .info_text2 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .info_text22 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 21px;
  }
  .info_text3 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 18px;
    margin-bottom: 11px;
  }
  .info_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    right: 92px;
    bottom: -28px;
  }
  .relative_wrapper {
    position: relative;
  }
  .upper_red {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #df6569;
  }
  .upper_green {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #4d7e6d;
  }
`;
