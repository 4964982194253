import Finance from "../../shared/Finance/Finance";

import { Configuration, ImageConfiguration } from "./components";
import * as S from "./styles";

const ApplicationStep1: React.FC = () => {
  return (
    <S.MainContainer>
      <ImageConfiguration />
      <Configuration />
      <Finance />
    </S.MainContainer>
  );
};

export default ApplicationStep1;
