import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { HeroImage } from "src/assets/images";
import { PopupSkeleton } from "src/shared/components";

import * as S from "./InstallerRequestSuccessfull.styles";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { UserSelectors } from "src/redux/selectors";

export const InstallerRequestSuccessfull: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const installerDetails = useSelector(UserSelectors?.getInstaller);

  const handleClose = useCallback(() => {
    dispatch(PopupActions.closePopup(POPUP_KEYS.installer_confirmation));
  }, [dispatch]);

  return (
    <PopupSkeleton handleClose={handleClose} open={true}>
      <S.InstallerRequestSuccessfullWrapper>
        <S.MainText>
          {installerDetails?.installationConfirmationTitle ||
            t(
              "We're requesting possible installation times for your PV project. On average projects get realized in 6-8 weeks",
            )}
        </S.MainText>
        <div className="image_and_text">
          <div className="text">
            <S.SubText1>
              {installerDetails?.installationConfirmationText ||
                t("We will let you know as soon as we have received possible relization dates.")}
            </S.SubText1>
            <S.SubText1>
              {installerDetails?.installationConfirmationSubText ||
                t("In the meantime feel free to change your setup and browse your project")}
            </S.SubText1>
          </div>
          <img src={HeroImage} />
        </div>
      </S.InstallerRequestSuccessfullWrapper>
    </PopupSkeleton>
  );
};

export default InstallerRequestSuccessfull;
