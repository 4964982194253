import styled from "styled-components";

export const MonthlyBalanceWrapper = styled("div")`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 19px;

  .ant-tab-custom {
    .ant-tabs-nav {
      width: auto;
    }
  }
`;
export const ShadowBoxWrapper = styled("div")`
  width: 93%;
  margin: auto;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  padding-top: 20px;
  border-radius: 10px;
  .chartTitle {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #2d4764;
    margin-bottom: 11px;
  }
`;

export const DonoughtChartsRow = styled("div")`
  width: 93%;
  margin: auto;
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;

  @media screen and (max-width: 700px) {
    grid-template-columns: 100%;
  }
`;
