import styled from "styled-components/macro";

export const MainContainer = styled.div`
  height: 525px;
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
export const MainContainer1 = styled.div`
  max-width: 261.6px;
  flex: 1;
  height: 78.2px;
  background: #ffffff;
  box-shadow: 0px 1.86191px 7.44764px rgba(0, 0, 0, 0.12);
  border-radius: 18.6191px;
  display: flex;
  align-items: center;
  gap: 15px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14.8953px;
  text-transform: uppercase;
  color: #304864;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;

  .text {
    flex-grow: 1;
  }
`;
export const MainContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-bottom: 18px;
  gap: 12px;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 363px;
    gap: 16px;
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;
