import { ProductDB } from "src/db-types";

import { FilteredProductsMap } from "../types";

import * as actionTypes from "./action.names";

import { ReduxAction } from "src/redux/common.types";

export const setProducts = (products: ProductDB[]): ReduxAction<ProductDB[]> => {
  return {
    payload: products,
    type: actionTypes.SET_PRODUCTS,
  };
};

export const setFilteredProducts = (
  products: Partial<FilteredProductsMap>,
): ReduxAction<Partial<FilteredProductsMap>> => {
  return {
    payload: products,
    type: actionTypes.SET_FILTERED_PRODUCTS,
  };
};


export const setProductObjFiles = (data: any): ReduxAction<any> => {
  return {
    type: actionTypes.SET_PRODUCT_OBJ_FILES,
    payload: data,
  };
};
