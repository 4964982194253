import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { useJoinTheWebinar, useWindowDimensions } from "src/hooks";

import { LongLogo } from "src/assets/svgs";

import { NavItemKey } from "../../constants/navItemKey";
import RedDot from "../RedDot/RedDot";

import * as S from "./LeftNav.styles";
import { InstallerDB } from "src/db-types";

interface LevNavProps {
  menuButtonClickHandler: Function;
  installerPartnerLogo: ReactElement | null;
  installer: InstallerDB | null;
}

const LeftNav: React.FC<LevNavProps> = (props) => {
  const { menuButtonClickHandler, installerPartnerLogo, installer } = props;
  const { width } = useWindowDimensions();

  const { t } = useTranslation();
  const { isAnyWebinarInProgress } = useJoinTheWebinar();
  const onGoing = isAnyWebinarInProgress();

  return (
    <div className="first">
      {(!installer || (installer && !installer.isOnlySmallSolarhubLogo)) && (
        <LongLogo
          onClick={() => menuButtonClickHandler(NavItemKey.LOGO)}
          style={{ cursor: "pointer" }}
        />
      )}
      {installer &&
        width < 500 &&
        installer.isOnlySmallSolarhubLogo &&
        (installer?.logoUrl.length ? (
          <div className="image-container">
            <S.InstallerMobileLogo src={installer.logoUrl[0]} alt="Intaller partner" />
          </div>
        ) : (
          <p className="installer-name">{installer?.companyName}</p>
        ))}
      <div className="hideOnMobile">{installerPartnerLogo}</div>
      {width > 500 && (!installer || (installer && installer.isAllowWebinar)) && (
        <S.WebinarLink
          className="hideOnMobile"
          style={{ display: "flex" }}
          onClick={() => menuButtonClickHandler(NavItemKey.WEBINAR)}
        >
          {onGoing && <RedDot />}
          {t("Join Live Webinar")}
        </S.WebinarLink>
      )}
    </div>
  );
};

export default LeftNav;
