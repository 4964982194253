import { binaryPalette, ironPalette, panelsPalette, rainbowPalette, sunlightPalette } from './colors';
import { downloadGeoTIFF, type DataLayersResponse, type LayerId, type Bounds } from './solar';
import { renderPalette, renderRGB } from './visualize';

export interface Palette {
    colors: string[];
    min: string;
    max: string;
  }
  
  export interface Layer {
    id: LayerId;
    render: (showRoofOnly: boolean, month: number, day: number) => HTMLCanvasElement[];
    bounds: Bounds;
    palette?: Palette;
  }
  
  export async function getLayer(
    layerId: LayerId,
    urls: DataLayersResponse,
    googleMapsApiKey: string,
  ): Promise<Layer> {
    const get: Record<LayerId, () => Promise<Layer>> = {
      monthlyFlux: async () => {
        const [mask, data] = await Promise.all([
          downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
          downloadGeoTIFF(urls.monthlyFluxUrl, googleMapsApiKey),
        ]);
        const colors = ironPalette;
        return {
          id: layerId,
          bounds: mask.bounds,
          palette: {
            colors: colors,
            min: 'Shady',
            max: 'Sunny',
          },
          render: (showRoofOnly) =>
            [...Array.from(Array(12).keys())].map((month) =>
              renderPalette({
                data: data,
                mask: showRoofOnly ? mask : undefined,
                colors: colors,
                min: 0,
                max: 200,
                index: month,
              }),
            ),
        };
      },
      annualFlux: async () => {
        const [mask, data] = await Promise.all([
          downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
          downloadGeoTIFF(urls.annualFluxUrl, googleMapsApiKey),
        ]);
        const colors = ironPalette;
        return {
          id: layerId,
          bounds: mask.bounds,
          palette: {
            colors: colors,
            min: 'Shady',
            max: 'Sunny',
          },
          render: (showRoofOnly) => [
            renderPalette({
              data: data,
              mask: showRoofOnly ? mask : undefined,
              colors: colors,
              min: 0,
              max: 1800,
            }),
          ],
        };
      },
    };
    try {
      return get[layerId]();
    } catch (e) {
      console.error(`Error getting layer: ${layerId}\n`, e);
      throw e;
    }
  }