import styled from "styled-components/macro";
interface MainHeaderItemProps {
  showAddComponents: any;
}
interface subC0ntainer1Props {
  isProduct?: Boolean;
}

export const MainContainer = styled.div`
  width: 100%;
  padding-left: 17px;
`;
export const MainHeader = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
export const MainHeaderText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #2d4764;
`;
export const MainHeaderText1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #2d4764;
`;
export const SubContainer22 = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #dfdfdf;
  }
`;
export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 12px;
`;
export const SubContainer1 = styled.div<subC0ntainer1Props>`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 80%;
  padding-left: 5px;
  align-items: ${(props) => {
    if (props.isProduct == true) {
      return "center";
    } else {
      return "";
    }
  }};
`;
export const SubContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 36px;
  height: 36px;
  margin-right: 5px;
  cursor: pointer;
  &:hover {
    background: #dfdfdf;
    border-radius: 50%;
  }
`;
export const SubContainer3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  :hover {
    background: #f9f9f9;
    cursor: pointer;
    border-radius: 12px;
  }
`;
export const SubContainerText1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: #304864;

  @media (max-width: 500px) {
    text-transform: capitalize;
  }
`;
export const SubContainerText2 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.005em;
  color: #666666;
`;
