import { useState } from "react";
import { useTranslation } from "react-i18next";

import { styles } from "../styles";

interface HoverableButtonI {
  onClick: any;
  ImgIcon: any;
  text: any;
}

const HoverableButton: React.FC<HoverableButtonI> = (props: HoverableButtonI) => {
  const [hovered, setHovered] = useState(false);

  const classes = styles();
  const { t } = useTranslation();

  return (
    <div
      onClick={props.onClick}
      className={classes.talkToOurExpert}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div>
        <props.ImgIcon />
      </div>
      {hovered && <div className={classes.textSize}>{t(props.text)}</div>}
    </div>
  );
};

export default HoverableButton;
