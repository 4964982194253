import styled from "styled-components/macro";

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px;
  align-items: center;
  gap: 3rem;
  padding-inline: 10px;
  max-width: 70vw;
  font-family: "NuNito";
`;
