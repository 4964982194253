import { clone } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateInstallerId = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateInstallerId>,
): ProjectState => {
  const newState = clone(currentState);

  newState.iid = payload.iid;
  return newState;
};
