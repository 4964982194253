import styled from "styled-components/macro";

export const WebinarLink = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  color: "#2D4764";
  cursor: "pointer";
  :hover {
    color: #2d4764;
    font-weight: 700;
    cursor: pointer;
  }
`;

export const InstallerPartner = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  .image-container {
    width: 165px;
    height: 50px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .horzontal-line {
    width: 37px;
    height: 0px;
    border: 1px solid #2d4764;
    transform: rotate(90deg);

    @media (max-width: 500px) {
      display: none;
    }
  }

  p {
    color: #2d4764;
    font-weight: 700;
    cursor: pointer;
  }

  img {
    width: 137px;
    height: 32px;
  }
`;

export const SmallLogo = styled.div`
  display: flex;
  align-items: end;
  font-size: 12px;
  height: 50px;
  color: #2d4764
`;
export const InstallerMobileLogo = styled.img`
  height: 50px;
  margin-bottom: 5px;
`;
