import { ProductDB } from "src/db-types";
import { getNumber } from "src/utils";

export const getAverageProductFieldValue = (
  products: ProductDB[],
  fieldName: keyof ProductDB,
): number => {
  const validProducts = products.filter((prod) => {
    return prod[fieldName] !== null && prod[fieldName] !== undefined && prod[fieldName] !== "";
  });
  if (validProducts.length === 0) return 0;

  const total = validProducts.reduce((acc, prod) => acc + getNumber(prod[fieldName]), 0);
  return total / validProducts.length;
};

export const getAverageCapacity = (products: ProductDB[]): number => {
  return getAverageProductFieldValue(products, "capacity");
};

export const getAverageMaxCapacity = (products: ProductDB[]): number => {
  return getAverageProductFieldValue(products, "applicableMaxCapacity");
};
