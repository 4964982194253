export const SET_USER = "user/set-user";
export const SET_USER_AUTH_LOADING = "user/set-user-auth-loading";
export const SET_LANGUAGE = "user/set-language";
export const UPDATE_LAST_LOG = "user/update-last-log";
export const USER_AUTH_STATUS = "user/set-auth-status";
export const SET_INSTALLER = "user/set-installer";
export const SET_RENTING_DATA = "user/set-ranting-data";
export const SET_SIMPLIFY = "user/set-simplify";
export const SET_SUBSIDY = "user/set-subsidy";
export const DELETE_SUBSIDY = "user/delete-subsidy";
export const SET_FILTERS= "user/set-filters"