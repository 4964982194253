import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 50px;
  border-radius: 22px;
  background: var(--leas-color-inspiration-white, #fff);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const PriceDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const Price = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;

export const CheckoutDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

export const ButtonsSection = styled.div`
  display: flex;
`;
