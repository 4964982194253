// import useCometChat from "./useCometChat";
// import useQuoteBuildingTypeChange from "./useQuoteBuildingTypeChange";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import type { RootState, AppDispatch } from "src/redux/store";

const useAppDispatch: () => AppDispatch = useDispatch;
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export * from "./useNumberFormat";
export * from "./windowDimension";
export * from "./useCalendlyData";
export * from "./useKeyPress";
export { default as useImageLoading } from "./useImageLoading";
export { default as useOutsideAlerter } from "./useOutsideAlerter";
export * from "./useIsComponentAdded";
export * from "./useYearlySystemProfit";
export * from "./useProjectCosts";
export * from "./useFinancialCalculations";
export * from "./useAmortizationTime";
export * from "./useAxios";
export * from "./useInitIPRequest";
export * from "./useURLPath";
export * from "./useSaveProject";
export * from "./useDelayUnmount";
export * from "./useUpdateProject";
export * from "./useIncrementAccessCount";
export * from "./useProfileChange";
export * from "./useURLData";
export * from "./useLastLog";
export * from "./useNavigateContext";
export * from "./useGetAllUniqueAddressProjects";
export * from "./useProductFilter";
export * from "./useImageOnLoad";
export * from "./useGetAllWebinars";
export * from "./useRegisterIntoWbinar";
export * from "./useJoinTheWebinar";
export * from "./useValidPreferenceMap";
export * from "./useAddBatteryInverterChange";
export * from "./useSynchroniseInverterPreference";
export * from "./useAddConfiguration";
export * from "./useFeedInSync";
export * from "./useGetPartnerCityDetails";
export * from "./useGetSaveProjectFun";
export {
  // useCometChat, useQuoteBuildingTypeChange,
  useAppDispatch,
  useAppSelector,
};
