import styled from "styled-components/macro";

export const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #2d4764;
  .headkey1 {
    font-size: 16px;
    font-weight: 600;
  }
  .headkey2 {
    font-size: 16px;
  }
  .consumptionUnitData {
    font-weight: 700;
  }
  .yearlyProduction {
    font-weight: 700;
    color: #247d38;
  }
`;
