import { cloneDeep } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const addHeatpump = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addHeatpump>,
): ProjectState => {
  const newHeatpump = cloneDeep(payload);

  return {
    ...currentState,
    components: {
      ...currentState.components,
      heatpump: newHeatpump,
    },
  };
};

export const addHeatpumpProduct = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addHeatpumpProduct>,
): ProjectState => {
  const newHeatpump = cloneDeep(payload);

  return {
    ...currentState,
    components: {
      ...currentState.components,
      heatpump: {
        ...currentState.components.heatpump,
        item: newHeatpump,
      },
    },
  };
};
