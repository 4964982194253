import { Quote, QuoteProfile } from "../types";

const defaultProfile: QuoteProfile = {
  imgURLs: [
    "https://firebasestorage.googleapis.com/v0/b/solarhub-production.appspot.com/o/sample-quote%2FMicrosoftTeams-image%20(4).png?alt=media&token=947be065-0db9-40d0-8555-8556c462d754",
  ],
  moduleQuantity: 71,
  consumption: 27.335,
  totalCost: 36554,
  rating: 4,
  grn: "07vcGa6FR2xTIbJLMzGAJhEa",
  roofTilt: 45,
  roofType: "Arched roof",
  roofMaterial: "metal roof",
  roofOrientation: "South orientation",
  roofMaterialRating: 2,
  productionRatio: 830,
  totalCostFromProvider: 34612,
  productionCapacity: 0,
  productionIncrese: 0,
  projectSizeRating: 3,
  currentAnnualConsumption: 4500,
  shadowsRating: 1,
  annualSolarGeneration: 19144,
  roofOrientationRating: 4,
  roofStatusRating: 1,
  projectType: "R",
  annulElectricBill: 1699.44,
  roofStructureComplexityRating: 3,
  isVerified: "not verified",
  _id: "",
  ModelNo: "",
};

export const quote: Quote = {
  roofTypesList: [
    "Flatroof",
    "Tentroof",
    "Hiproof",
    "Trapezoidal Profiles",
    "Gable roof",
    "Singlepitch roof",
    "Archedroof",
    "Halfhipped roof",
    "Foldedplate roof",
    "Walldormer",
  ],
  createdAt: Date.now().toString(),
  createdBy: "system",
  updatedAt: Date.now().toString(),
  batchName: "default-batch",
  buildingType: "Multi-Family Building",
  lat: 47.9809618,
  long: 7.9618841,
  geohash: "u0t8vnttm7",
  id: "",
  installerPartner: "",
  status: "URGENT_PLANNING",
  Gemeindeschluessel8: null,
  mapboxAddress: {
    address: "Ringstraße 7",
    city: "Stegen",
    complete: "Musterplatz, 74722 Buchen, Germany",
    country: "Germany",
    geometry: {
      type: "Point",
      coordinates: [7.961882, 47.980944],
    },
    locality: "Oberbirken",
    number: "7",
    postcode: "79252",
    state: "Baden-Württemberg",
  },
  profiles: {
    green: {
      ...defaultProfile,
    },
    smart: {
      ...defaultProfile,
      moduleQuantity: 35,
    },
    eco: {
      ...defaultProfile,
      moduleQuantity: 17,
    },
  },
};
