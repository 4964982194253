import { ReduxAction } from "src/redux/common.types";
import { actionNames, FLAG_KEYS } from "src/redux/flags";

export const setFlag = (
  key: FLAG_KEYS,
  open: boolean,
  data?: any,
): ReduxAction<{
  flagKey: FLAG_KEYS;
  open: boolean;
  data: any;
}> => {
  return {
    type: actionNames.SET_FLAG,
    payload: {
      flagKey: key,
      open,
      data,
    },
  };
};
