import { isArray } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import { TabTitle, TabTitleRow } from "./TabBarStyles";

export interface Tab {
  key: string;
  label?: string;
  disabled?: boolean;
  labelComponent?: React.ReactElement;
}
interface TabBarProps {
  clickHandler: (key: string) => any;
  tabs: Tab[];
  defaultSelected?: string;
}
const TabBar: React.FC<TabBarProps> = (props: TabBarProps) => {
  const { clickHandler, tabs, defaultSelected } = props;

  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    const areTabsEmpty = !isArray(tabs) || tabs.length <= 0;
    if (areTabsEmpty) {
      console.error("Empty tabs passed to TabBar");
      return;
    }

    const noTabSelected = selectedTab === "";
    if (noTabSelected) {
      let selectedKey = tabs[0].key;
      if (defaultSelected) {
        selectedKey = defaultSelected;
      }

      setSelectedTab(selectedKey);
    }
  }, [clickHandler, defaultSelected, selectedTab, tabs]);

  useEffect(() => {
    if (defaultSelected) {
      setSelectedTab(defaultSelected);
    }
  }, [defaultSelected]);

  const tabClick = useCallback(
    (key: string) => {
      setSelectedTab(key);
      clickHandler(key);
    },
    [clickHandler, setSelectedTab],
  );

  return (
    <TabTitleRow>
      {tabs.map((tab: any) => {
        return (
          <TabTitle
            key={tab.key}
            selected={selectedTab === tab.key}
            disabled={tab.disabled}
            onClick={() => tabClick(tab.key)}
          >
            {tab.labelComponent ?? tab.label}
          </TabTitle>
        );
      })}
    </TabTitleRow>
  );
};

export default TabBar;
