import { InstallerDetails, InstallerOfferDB, ProductDB } from "src/db-types";
import { ModelProgress } from "src/db-types/project";
import { OverallConsumptionModel } from "src/prediction-model/types/model.types";

import {
  BakeModelProgress,
  BaseHouseImages,
  CarComponent,
  ElectricMeterImages,
  HeatpumpComponent,
  HouseTopImages,
  HouseholdEnergyParams,
  Payment,
  ProjectConstants,
  ProjectFinancialDetails,
  ProjectVendor,
  ProjectWebConstants,
  Quote,
} from "../types";
import { FilterableBaseComponent } from "../types/components";
import { Energy } from "../types/energy";
import { EnergyProductionParams } from "../types/energy-production-params";
import { ThreeDModels } from "../types/house-images";
import { Deal, ProjectOuterDetails, ProjectState } from "../types/project-state";

import * as ACTION from "./actions";

import { ReduxAction } from "src/redux/common.types";

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

/** ----- Quote related action creators ----- */
export const setQuote = (data: Quote): ReduxAction<Quote> => {
  return {
    type: ACTION.SET_QUOTE,
    payload: data,
  };
};

/** ----- Energy Params ----- */
export const updateEnergy = (
  data: RecursivePartial<Energy>,
): ReduxAction<RecursivePartial<Energy>> => {
  return {
    type: ACTION.UPDATE_ENERGY,
    payload: data,
  };
};

export const updateEnergyProductionParams = (
  data: RecursivePartial<EnergyProductionParams>,
): ReduxAction<RecursivePartial<EnergyProductionParams>> => {
  return {
    type: ACTION.UPDATE_ENERGY_PRODUCTION_PARAMS,
    payload: data,
  };
};

/** ----- Water heater ----- */
export const addWaterHeater = (): ReduxAction<null> => {
  return {
    type: ACTION.ADD_WATER_HEATER,
    payload: null,
  };
};

export const deleteWaterHeater = (): ReduxAction<null> => {
  return {
    type: ACTION.DELETE_WATER_HEATER,
    payload: null,
  };
};

/** ----- Battery ----- */
export const addBattery = (data: FilterableBaseComponent): ReduxAction<FilterableBaseComponent> => {
  return {
    type: ACTION.ADD_BATTERY,
    payload: data,
  };
};

export const addBatteryProduct = (data: ProductDB | null): ReduxAction<ProductDB | null> => {
  return {
    type: ACTION.ADD_BATTERY_PRODUCT,
    payload: data,
  };
};

export const updateBattery = (
  data: RecursivePartial<FilterableBaseComponent>,
): ReduxAction<RecursivePartial<FilterableBaseComponent>> => {
  return {
    type: ACTION.UPDATE_BATTERY,
    payload: data,
  };
};

export const deleteBattery = (): ReduxAction<null> => {
  return {
    type: ACTION.DELETE_BATTERY,
    payload: null,
  };
};

/** ---- Wallbox ----- */
export const addWallbox = (data: FilterableBaseComponent): ReduxAction<FilterableBaseComponent> => {
  return {
    type: ACTION.ADD_WALLBOX,
    payload: data,
  };
};

export const addWallboxProduct = (data: ProductDB): ReduxAction<ProductDB> => {
  return {
    type: ACTION.ADD_WALLBOX_PRODUCT,
    payload: data,
  };
};

export const updateWallbox = (
  data: RecursivePartial<FilterableBaseComponent>,
): ReduxAction<RecursivePartial<FilterableBaseComponent>> => {
  return {
    type: ACTION.UPDATE_WALLBOX,
    payload: data,
  };
};


export const deleteWallbox = (): ReduxAction<null> => {
  return {
    type: ACTION.DELETE_WALLBOX,
    payload: null,
  };
};


/** ---- EMS ----- */
export const addEMS = (data: FilterableBaseComponent): ReduxAction<FilterableBaseComponent> => {
  return {
    type: ACTION.ADD_EMS,
    payload: data,
  };
};

export const addEMSProduct = (data: ProductDB): ReduxAction<ProductDB> => {
  return {
    type: ACTION.ADD_EMS_PRODUCT,
    payload: data,
  };
}

export const updateEMS = (
  data: RecursivePartial<FilterableBaseComponent>,
): ReduxAction<RecursivePartial<FilterableBaseComponent>> => {
  return {
    type: ACTION.UPDATE_EMS,
    payload: data,
  };
}

export const deleteEMS = (): ReduxAction<null> => {
  return {
    type: ACTION.DELETE_EMS,
    payload: null,
  };
}

/** ----- Car ----- */
export const addCar = (data: CarComponent): ReduxAction<CarComponent> => {
  return {
    type: ACTION.ADD_CAR,
    payload: data,
  };
};

interface UpdateCarProps {
  car: RecursivePartial<CarComponent>;
  id: string;
}
export const updateCar = (data: UpdateCarProps): ReduxAction<UpdateCarProps> => {
  return {
    type: ACTION.UPDATE_CAR,
    payload: data,
  };
};

export const deleteCar = (id: string): ReduxAction<string> => {
  return {
    type: ACTION.DELETE_CAR,
    payload: id,
  };
};

/** ----- Heatpump ----- */
export const addHeatpump = (data: HeatpumpComponent): ReduxAction<HeatpumpComponent> => {
  return {
    type: ACTION.ADD_HEATPUMP,
    payload: data,
  };
};

export const addHeatpumpProduct = (data: ProductDB): ReduxAction<ProductDB> => {
  return {
    type: ACTION.ADD_HEATPUMP_PRODUCT,
    payload: data,
  };
};

export const updateHeatpump = (
  data: RecursivePartial<HeatpumpComponent>,
): ReduxAction<RecursivePartial<HeatpumpComponent>> => {
  return {
    type: ACTION.UPDATE_HEATPUMP,
    payload: data,
  };
};

export const deleteHeatpump = (): ReduxAction<null> => {
  return {
    type: ACTION.DELETE_HEATPUMP,
    payload: null,
  };
};

/** ----- Inverter ----- */
export const addInverter = (data: ProductDB): ReduxAction<ProductDB> => {
  return {
    type: ACTION.ADD_INVERTER,
    payload: data,
  };
};

export const updateInverter = (
  data: RecursivePartial<FilterableBaseComponent>,
): ReduxAction<RecursivePartial<FilterableBaseComponent>> => {
  return {
    type: ACTION.UPDATE_INVERTER,
    payload: data,
  };
};

export const deleteInverter = (): ReduxAction<null> => {
  return {
    type: ACTION.DELETE_INVERTER,
    payload: null,
  };
};

/** ----- Solar Panel ----- */
export const addSolarPanel = (data: ProductDB): ReduxAction<ProductDB> => {
  return {
    type: ACTION.ADD_SOLAR_PANEL,
    payload: data,
  };
};

export const updateSolarPanel = (
  data: RecursivePartial<FilterableBaseComponent>,
): ReduxAction<RecursivePartial<FilterableBaseComponent>> => {
  return {
    type: ACTION.UPDATE_SOLAR_PANEL,
    payload: data,
  };
};

export const deleteSolarPanel = (): ReduxAction<null> => {
  return {
    type: ACTION.DELETE_SOLAR_PANEL,
    payload: null,
  };
};

/** ----- Additional Software ----- */
export const addAdditionalSoftware = (data: ProductDB): ReduxAction<ProductDB> => {
  return {
    type: ACTION.ADD_ADDITIONAL_SOFTWARE,
    payload: data,
  };
};

export const addAdditionalSoftwareProduct = (data: ProductDB | null): ReduxAction<ProductDB | null> => {
  return {
    type: ACTION.ADD_ADDITIONAL_SOFTWARE_PRODUCT,
    payload: data,
  };
};

export const updateAdditionalSoftware = (
  data: RecursivePartial<FilterableBaseComponent>,
): ReduxAction<RecursivePartial<FilterableBaseComponent>> => {
  return {
    type: ACTION.UPDATE_ADDITIONAL_SOFTWARE,
    payload: data,
  };
};

export const deleteAdditionalSoftware = (): ReduxAction<null> => {
  return {
    type: ACTION.DELETE_ADDITIONAL_SOFTWARE,
    payload: null,
  };
};

/** ----- Multi Family ----- */
export const addAdditionalHardware = (data: ProductDB): ReduxAction<ProductDB> => {
  return {
    type: ACTION.ADD_ADDITIONAL_HARDWARE,
    payload: data,
  };
};

export const addAdditionalHardwareProduct = (data: ProductDB | null): ReduxAction<ProductDB | null> => {
  return {
    type: ACTION.ADD_ADDITIONAL_HARDWARE_PRODUCT,
    payload: data,
  };
};

export const updateAdditionalHardware = (
  data: RecursivePartial<FilterableBaseComponent>,
): ReduxAction<RecursivePartial<FilterableBaseComponent>> => {
  return {
    type: ACTION.UPDATE_ADDITIONAL_HARDWARE,
    payload: data,
  };
};

export const deleteAdditionalHardware = (): ReduxAction<null> => {
  return {
    type: ACTION.DELETE_ADDITIONAL_HARDWARE,
    payload: null,
  };
};

/** ---- Housetop Info ---- */
export const addHouseTop = (data: HouseTopImages): ReduxAction<HouseTopImages> => {
  return {
    type: ACTION.ADD_HOUSETOP_IMAGE,
    payload: data,
  };
};

type UpdateHouseTopProps = RecursivePartial<HouseTopImages> & {
  imageURLs?: string[];
};
export const updateHouseTop = (data: UpdateHouseTopProps): ReduxAction<UpdateHouseTopProps> => {
  return {
    type: ACTION.UPDATE_HOUSETOP_INFO,
    payload: data,
  };
};

/** ---- House bottom Info ---- */
export const addHouseBottom = (data: BaseHouseImages): ReduxAction<BaseHouseImages> => {
  return {
    type: ACTION.ADD_HOUSEBOTTOM_IMAGE,
    payload: data,
  };
};

type UpdateHouseBottomProps = RecursivePartial<BaseHouseImages> & {
  imageURLs?: string[];
};
export const updateHouseBottom = (
  data: UpdateHouseBottomProps,
): ReduxAction<UpdateHouseBottomProps> => {
  return {
    type: ACTION.UPDATE_HOUSEBOTTOM_INFO,
    payload: data,
  };
};

/** ----Drone Data Info ---- */
export const addDroneData = (data: BaseHouseImages): ReduxAction<BaseHouseImages> => {
  return {
    type: ACTION.ADD_DRONEDATA_IMAGE,
    payload: data,
  };
};

type UpdateDroneDataProps = RecursivePartial<ThreeDModels> & {
  imageURLs?: string[];
};
export const updateDroneData = (data: UpdateDroneDataProps): ReduxAction<UpdateDroneDataProps> => {
  return {
    type: ACTION.UPDATE_DRONEDATA_INFO,
    payload: data,
  };
};

/** ---- Electric meter ---- */
export const addElectricMeter = (data: ElectricMeterImages): ReduxAction<ElectricMeterImages> => {
  return {
    type: ACTION.ADD_ELECTRIC_METER_IMAGE,
    payload: data,
  };
};

type UpdateElectricMeterProps = RecursivePartial<ElectricMeterImages> & {
  imageURLs?: string[];
};
export const updateElectricMeter = (
  data: UpdateElectricMeterProps,
): ReduxAction<UpdateElectricMeterProps> => {
  return {
    type: ACTION.UPDATE_ELECTRIC_METER_INFO,
    payload: data,
  };
};

export const updateBakeModelId = (
  data: BakeModelProgress,
): ReduxAction<RecursivePartial<BakeModelProgress>> => {
  return {
    type: ACTION.UPDATE_BAKE_MODEL_ID,
    payload: data,
  };
};

export const updateBakeModelProgress = (
  data: ModelProgress,
): ReduxAction<RecursivePartial<ModelProgress>> => {
  return {
    type: ACTION.UPDATE_BAKE_MODEL_PROGRESS,
    payload: data,
  };
};

/** ---- Household ---- */
export const updateHousehold = (
  data: RecursivePartial<HouseholdEnergyParams>,
): ReduxAction<RecursivePartial<HouseholdEnergyParams>> => {
  return {
    type: ACTION.UPDATE_HOUSEHOLD,
    payload: data,
  };
};

/** ---- Financial ---- */
export const updateFinancialDetails = (
  data: RecursivePartial<ProjectFinancialDetails>,
): ReduxAction<RecursivePartial<ProjectFinancialDetails>> => {
  return {
    type: ACTION.UPDATE_FINANCIAL_DETAILS,
    payload: data,
  };
};

/** ---- Vendor ---- */
export const updateVendor = (
  data: RecursivePartial<ProjectVendor>,
): ReduxAction<RecursivePartial<ProjectVendor>> => {
  return {
    type: ACTION.UPDATE_VENDOR,
    payload: data,
  };
};

export const addSelectedVendor = (
  data: RecursivePartial<any>,
): ReduxAction<RecursivePartial<any>> => {
  return {
    type: ACTION.ADD_SELECTED_VENDOR,
    payload: data,
  };
};

export const addSuggestedVendor = (
  data: RecursivePartial<any>,
): ReduxAction<RecursivePartial<any>> => {
  return {
    type: ACTION.ADD_SUGGESTED_VENDOR,
    payload: data,
  };
};

/** ---- Payment info ---- */
export const updatePaymentInfo = (
  data: RecursivePartial<Payment>,
): ReduxAction<RecursivePartial<Payment>> => {
  return {
    type: ACTION.UPDATE_PAYMENT_INFO,
    payload: data,
  };
};

/** ---- Constants ---- */
export const updateConstants = (
  data: RecursivePartial<ProjectConstants>,
): ReduxAction<RecursivePartial<ProjectConstants>> => {
  return {
    type: ACTION.UPDATE_CONSTANTS,
    payload: data,
  };
};

/** ---- Web Constants ---- */
export const updateWebConstants = (
  data: RecursivePartial<ProjectWebConstants>,
): ReduxAction<RecursivePartial<ProjectWebConstants>> => {
  return {
    type: ACTION.UPDATE_WEB_CONSTANTS,
    payload: data,
  };
};

/** ---- Model ---- */
export const updateModel = (
  data: RecursivePartial<OverallConsumptionModel>,
): ReduxAction<RecursivePartial<OverallConsumptionModel>> => {
  return {
    type: ACTION.UPDATE_MODEL,
    payload: data,
  };
};

/** ---- Outer details ---- */
export const updateOuterDetails = (
  data: RecursivePartial<ProjectOuterDetails>,
): ReduxAction<RecursivePartial<ProjectOuterDetails>> => {
  return {
    type: ACTION.UPDATE_OUTER_DETAILS,
    payload: data,
  };
};

export const updateDeal = (data: RecursivePartial<Deal>): ReduxAction<RecursivePartial<Deal>> => {
  return {
    type: ACTION.UPDATE_DEAL,
    payload: data,
  };
};

interface IUpdateVersionAndShortId {
  shortId: number;
  version: number;
}
export const updateVersionAndShortId = (
  data: IUpdateVersionAndShortId,
): ReduxAction<IUpdateVersionAndShortId> => {
  return {
    type: ACTION.UPDATE_VERSION_AND_SHORT_ID,
    payload: data,
  };
};

interface IupdateInstallerId {
  iid: string;
}

export const updateInstallerId = (data: IupdateInstallerId): ReduxAction<IupdateInstallerId> => {
  return {
    type: ACTION.UPDATE_INSTALLER_ID,
    payload: data,
  };
};

export const updateProject = (data: Partial<ProjectState>): ReduxAction<Partial<ProjectState>> => {
  return {
    type: ACTION.UPDATE_PROJECT,
    payload: data,
  };
};

export const addSelectedInstaller = (data: InstallerOfferDB): ReduxAction<InstallerOfferDB> => {
  return {
    type: ACTION.ADD_SELECTED_INSTALLER,
    payload: data,
  };
};

export const updateSelectedInstaller = (data: any): ReduxAction<InstallerOfferDB> => {
  return {
    type: ACTION.UPDATE_SELECTED_INSTALLER,
    payload: data,
  };
};

export const removeSelectedInstaller = (id: string): ReduxAction<{ id: string }> => {
  return {
    type: ACTION.REMOVE_SELECTED_INSTALLER,
    payload: { id },
  };
};

export const addSelectedFiles = (data: any): ReduxAction<any> => {
  return {
    type: ACTION.ADD_SELECTED_FILES,
    payload: data,
  };
};

export const updateModuleQuantity = (data: number): ReduxAction<any> => {
  return {
    type: ACTION.UPDATE_QUOTE_QUANTITY,
    payload: data,
  };
};

/** ----- Access count action creators ----- */
export const setAccessCount = (data: any): ReduxAction<any> => {
  return {
    type: ACTION.SET_ACCESS_COUNT,
    payload: data,
  };
};

export const updateThreeDModels = (data: any): ReduxAction<any> => {
  return {
    type: ACTION.UPDATE_THREE_D_MODELS,
    payload: data,
  }
}

/* User info in saved project */

export const addUserInfo = (data: any): ReduxAction<any> => {
  return {
    type: ACTION.ADD_USER_INFO,
    payload: data,
  }
}

export const deleteSubsidy = (data: any): ReduxAction<any> => ({
  type: ACTION.DELETE_SUBSIDY,
  payload: data,
});

export const deleteManualSubsidy = (data: any): ReduxAction<any> => ({
  type: ACTION.DELETE_MANUAL_SUBSIDY,
  payload: data,
});