import { CircularProgress } from "@mui/material";
import { uniqueId } from "lodash";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components/macro";

export const DefaultLoaderDiv = styled.div`
  circle {
    stroke: var(--primary-color) !important;
  }
`;

function DefaultLoader({ className, style }: any) {
  return (
    <DefaultLoaderDiv
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </DefaultLoaderDiv>
  );
}

/**
 * Hook that inserts loading until passed ref (an image ref) loads
 */
function useImageLoading(ref: React.RefObject<HTMLImageElement>, Loader: React.FC = DefaultLoader) {
  useEffect(() => {
    const id = uniqueId("loading-");

    if (ref.current == null) return cleanUpFunction;

    function cleanUpFunction(): void {
      // If image was loading but was forced to unmount,
      // then also remove loader
      removeLoader();

      // Unbind the event listener on clean up
      ref.current?.removeEventListener("load", afterLoad);
    }

    function insertLoader(): void {
      const loaderDiv = document.createElement("DIV");
      loaderDiv.id = id;
      ref.current?.parentNode?.insertBefore(loaderDiv, ref.current);
      ReactDOM.render(<Loader />, loaderDiv);
    }

    function hideImage(): void {
      if (!ref.current) return;
      ref.current.style.display = "none";
    }

    function showImage(): void {
      if (!ref.current) return;
      ref.current.style.display = "block";
    }

    function removeLoader(): void {
      if (id) {
        const childNode = document.getElementById(id);
        childNode?.remove();
      }
    }

    function afterLoad(): void {
      removeLoader();
      showImage();
    }

    const isLoaded = ref.current?.complete && ref.current?.naturalHeight !== 0;
    if (isLoaded) return cleanUpFunction;

    hideImage();
    insertLoader();

    // Bind the event listener
    ref.current?.addEventListener("load", afterLoad);
    return cleanUpFunction;
  }, [Loader, ref, ref.current?.currentSrc]);
}

export default useImageLoading;
