import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BackendApis } from "src/api";

import { convertUserFromDB } from "src/db-converters";
import { AuthMethod } from "src/popups";
import { googleRefreshAPI, handleSuccessRefresh, logout, set50MinsGoogleRefresh } from "src/utils";

import { useUserApi } from "./apis";

import { AppActions, UserActions } from "src/redux/actionCreators";
import { UserAuthStatus } from "src/redux/user";

import { useAppDispatch } from ".";

export const useInitUser = (): void => {
  const dispatch = useAppDispatch();

  const { fetchData, response, error, loading } = useUserApi();

  const googleRefresh = useCallback(async (): Promise<boolean> => {
    const method = localStorage.getItem("authMode") as AuthMethod | null;
    if (method === AuthMethod.google) {
      try {
        const refreshToken = localStorage.getItem("refresh_token");
        if (!refreshToken) return false;

        const response = await googleRefreshAPI(refreshToken);
        handleSuccessRefresh(response);
        set50MinsGoogleRefresh();
        return true;
      } catch (err) {
        logout();
        return false;
      }
    }
    return true;
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const tokenIsInvalid = [null, ""].includes(token);
    if (tokenIsInvalid) return;

    googleRefresh()
      .then((shouldCall) => {
        if (shouldCall) {
          dispatch(UserActions.setIsAuthLoading(true));

          void fetchData({
            ...BackendApis.user,
          });
        }
      })
      .catch((err) => console.error(err));
  }, [dispatch, fetchData, googleRefresh]);

  useEffect(() => {
    if (!response) return;

    const {
      data: { data: userInfo },
    } = response;
    const userState = convertUserFromDB(userInfo);
    dispatch(UserActions.setUser(userState));
    dispatch(UserActions.setUserAuthStatus(UserAuthStatus.loggedIn));
  }, [dispatch, response]);

  useEffect(() => {
    if (!error) return;

    localStorage.removeItem("token");
    dispatch(UserActions.setUserAuthStatus(UserAuthStatus.loggedOut));
  }, [dispatch, error]);

  useEffect(() => {
    dispatch(UserActions.setIsAuthLoading(loading));
    dispatch(
      AppActions.updateAppState({
        apiStates: {
          isUserApiLoading: loading,
        },
      }),
    );
  }, [dispatch, loading]);
};
