import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PopupSkeleton } from "src/shared/components";
import SolarHubLogo from "src/assets/logos/SolarhubLogo.svg";
import * as S from "./RealisationTime.styles";
import { PrimaryButton } from "src/shared/StyledComponents";
import { POPUP_KEYS } from "src/redux/popups";
import { closePopup } from "src/redux/popups/action/action.creators";
import { useAddInstallerAPI, useUpdateInstallerAPI } from "src/hooks/apis";
import { BackendApis } from "src/api";
import { UserSelectors } from "src/redux/selectors";
import moment from "moment";
import axios from "axios";

interface IRealisationTime {
  data: any;
}

export const RealisationTime: React.FC<IRealisationTime> = ({ data }: IRealisationTime) => {
  const { t } = useTranslation();
  const [realisationDate, setRealisationDate] = useState<any>({});
  const [visitDate, setVisitDate] = useState("");
  const user = useSelector(UserSelectors?.getUser);
  const {
    error: addInstallerError,
    fetchData: addInstallerApi,
    response: addInstallerResponse,
  } = useAddInstallerAPI();

  const RealisationDate = (week: any | null) => {
    const startOfWeek = week.clone().startOf("week");
    const endOfWeek = week.clone().endOf("week");
    const realizationWeek = [];
    let currentDay = startOfWeek;

    while (currentDay <= endOfWeek) {
      realizationWeek.push(currentDay.clone().toISOString());
      currentDay.add(1, "days");
    }

    const realizationWeekShort = `${startOfWeek.format("DD.")}-${endOfWeek.format("DD.MM.YYYY")}`;

    const response = {
      realizationWeek,
      realizationWeekShort,
    };
    setRealisationDate(response);
  };



  const VisitDate = (date: any, dateString: any) => {
    setVisitDate(date);
  };

  const { fetchData: updateInstallerApi, response: updateResponse } = useUpdateInstallerAPI();

  const submitDate = async () => {
    if (!user?.iid) {
      return;
    }
    if (data?.realizationWeek?.realizationWeek?.length || data?.realizationWeek?.visitDate) {
      void updateInstallerApi({
        ...BackendApis.updateInstaller({
          id: data?.realizationWeek?._id,
        }),
        data: { ...realisationDate, visitDate: visitDate },
      });
      data.setfilteredData((filterData: any) => {
        const updatedProject = filterData?.find(
          (saveProject: any) => saveProject?._id === data?.pid,
        );
        let isExist = updatedProject?.selectedInstallers?.find(
          (project: any) => project?._id === data?.realizationWeek?._id,
        );
        isExist = {
          ...isExist,
          realizationWeek: realisationDate?.realizationWeek || isExist?.realizationWeek,
          realizationWeekShort:
            realisationDate?.realizationWeekShort || isExist?.realisationDate?.realizationWeekShort,
          visitDate: visitDate || isExist?.visitDate,
        };
        const remaining = updatedProject?.selectedInstallers?.filter(
          (project: any) => project?._id !== data?.realizationWeek?._id,
        );

        const newUpdated = {
          ...updatedProject,
          selectedInstallers: [isExist, ...remaining],
        };
        let finalProjects = filterData;
        if (isExist) {
          finalProjects = filterData?.map((saveProject: any) =>
            saveProject?._id !== updatedProject?._id ? saveProject : newUpdated,
          );
        }
        return finalProjects;
      });
    } else {
      const response = await axios.post(
        `${BackendApis.addInstaller.url}`,
        {
          iid: user?.iid,
          pid: data?.pid,
          ...realisationDate,
          visitDate: visitDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        },
      );

      data.setfilteredData((filterData: any) => {
        const updatedProject = filterData?.find(
          (saveProject: any) => saveProject?._id === data?.pid,
        );
        const newUpdated = {
          ...updatedProject,
          selectedInstallers: [response?.data?.data],
        };
        let finalProjects = filterData;
        if (response?.data?.data) {
          finalProjects = filterData?.map((saveProject: any) =>
            saveProject?._id !== updatedProject?._id ? saveProject : newUpdated,
          );
        }
        return finalProjects;
      });
    }

    dispatch(closePopup(POPUP_KEYS.realisationtime));
  };
  const dispatch = useDispatch();
  return (
    <PopupSkeleton handleClose={() => dispatch(closePopup(POPUP_KEYS.realisationtime))} open={true}>
      <S.MainContentWrapper>
        <img src={SolarHubLogo} style={{ height: 90, justifySelf: "center" }} alt="Logo" />

        <S.Section1>
          <div>{t("Choose Realization Time")}</div>
        </S.Section1>

        <S.Section2>
          <div className="flex gap-4 justify-center items-center my-2">
            <span>Enter realisation week :</span>{" "}
            <DatePicker
              onChange={RealisationDate}
              format={(value) => {
                if (!value) return ''; 
                const weekOfMonth = Math.ceil(value.date() / 7); 
                const month = value.format("MMMM");
                const year = value.format("YYYY"); 
                return `${weekOfMonth} week of ${month} ${year}`; 
              }}
              picker="week"
              defaultValue={
                data?.realizationWeek?.realizationWeek?.length
                  ? moment(data.realizationWeek.realizationWeek[0])
                  : undefined
              }
            />
          </div>
          <div className="flex gap-4 justify-center items-center my-2">
            <span>Enter visit date :</span>{" "}
            <DatePicker
              format={"DD/MM/YYYY"}
              onChange={VisitDate}
              defaultValue={
                data?.realizationWeek?.visitDate
                  ? moment(data.realizationWeek.visitDate)
                  : undefined
              }
            />
          </div>
          <S.Button>
            <PrimaryButton
              style={{
                width: "250px",
                fontWeight: "800",
              }}
              onClick={submitDate}
            >
              {t("Submit")}
            </PrimaryButton>
          </S.Button>
        </S.Section2>
      </S.MainContentWrapper>
    </PopupSkeleton>
  );
};

export default RealisationTime;
