import { combineReducers } from "redux";

import { allProjectsReducer } from "./all-projects";
import { applicationReducer } from "./application";
import { popupReducer } from "./popups";
// import languageReducer from "./language/language.reducer";
import { productReducer } from "./products";
import { projectReducer } from "./project";
import { userReducer } from "./user";
import { webinareducer } from "./webinar";
import { solarAPIReducer } from "./solarAPI";
import { flagReducer } from "./flags";
// import quoteReducer from "./quote/quote.reducer";

// import applicationReducer from "./application/application.reducer";
// import chatReducer from "./chat/chat.reducer";
// import communityReducer from "./community/community.reducer";
// import notificationReducer from "./notification/notification.reducer";
// import popupReducer from "./popups/popups.reducer";
// import webinareducer from "./webinar/webinar.reducer";
// import productReducer from "./products/prodcut.reducer";

export default combineReducers({
  user: userReducer,
  project: projectReducer,
  popup: popupReducer,
  flag: flagReducer,
  products: productReducer,
  allProjects: allProjectsReducer,
  // global: languageReducer,
  // quote: quoteReducer,
  // chat: chatReducer,
  // notification: notificationReducer,
  // community: communityReducer,
  // popups: popupReducer,
  webinar: webinareducer,
  application: applicationReducer,
  solarAPI: solarAPIReducer,
  // product:productReducer,
});
