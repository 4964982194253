import { Slider } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { MasterInput } from "src/shared/components";
import { ParameterStyles } from "src/shared/StyledComponents";

import { CarConfiguration } from "src/redux/project";

interface GasPriceLineProps {
  onValueChange: (data: Partial<CarConfiguration>) => void;
  gasPrice: number;
}
const GasPriceLine: React.FC<GasPriceLineProps> = (props: GasPriceLineProps) => {
  const { onValueChange, gasPrice } = props;

  const { t } = useTranslation();

  const onChangeHandler = useCallback(
    (value: number) => {
      onValueChange({
        gasPricePer100Km: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line hasSlider isPadding>
      <ParameterStyles.Label hasSlider>{t("Gas price / 100km")}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider onChange={onChangeHandler} value={gasPrice} step={0.1} />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <MasterInput value={gasPrice} onChange={onChangeHandler} label="€" maxNumber={20} />
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default GasPriceLine;
