import styled from "styled-components";

export const VendorCardWrapper = styled.div`
  & .logo_image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .logo_image_text {
    margin-left: 1rem;
  }

  & .price {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 5px;
  }

  & .price_title {
    font-family: "Nunito";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
  }

  & .price_rate {
    font-family: "Nunito";
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
  }

  & .price_rate_interval {
    font-family: "Nunito";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: #666666;
  }

  & .project_image {
    width: 100%;
    max-width: 500px;
    border-radius: 5px;
  }
`;
