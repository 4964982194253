import * as S from "src/shared/StyledComponents/Parameters";

import {
  BasicElectricityConsumption,
  Heading,
  AddComponentsDropdown,
  ListSelectedProduct,
  ManageAssumptions,
} from "./components";
import { useSelector } from "react-redux";
import { UserSelectors } from "src/redux/selectors";

const ConfigurationParam: React.FC = () => {
  const simplify = useSelector(UserSelectors.getSimplify);

  return (
    <S.Container>
      <Heading />
      <BasicElectricityConsumption />
      <ListSelectedProduct />
      <S.SectionDivider />
      <AddComponentsDropdown />
      <S.SectionDivider />
      {!simplify && <ManageAssumptions />}
    </S.Container>
  );
};

export default ConfigurationParam;
