import styled from "styled-components/macro";
interface HoverOrNot {
  isHover?: Boolean;
}
export const PayWallWrapper: any = styled("div")<HoverOrNot>`
  .checkoutMain {
    width: 100%;
    border-radius: 16px;
    height: 714px;
    width: 1050px;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 29px;
    padding-top: 30px;
    @media (max-width: 1200px) {
      width: 100%;
      height: auto;
    }
  }
  .sampleProjectButton {
    height: 50px;
    width: 363px;
    border-radius: 36px;
    box-sizing: border-box;
    background: #ffd75d;
    border-radius: 36px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 100%;
    text-transform: uppercase;
    color: #2d4764;
    cursor: pointer;
    @media (max-width: 1200px) {
      width: 80%;
    }
  }
  .heading_text1 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    color: #2d4764;
  }
  .heading_text2 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #666666;
  }
  .detailPlan_mainContainer {
    width: 100%;
    padding-inline: 1rem;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 26px;
  }
  .inside_main_container {
    box-sizing: border-box;
    width: 300px;
    padding: 20px 15px;
    background: ${({ isHover }) => {
      if (isHover) {
        return "linear-gradient(168.55deg,#fbda61 68.9%,#fad44c 84.53%,#ee7d3e 113.04%,#ee7d3e 129.72%)";
      } else {
        return "#ffffff";
      }
    }};
    border: ${({ isHover }) => {
      if (isHover) {
        return "none";
      } else {
        return "1px solid #dfdfdf";
      }
    }};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    transform: ${({ isHover }) => {
      if (isHover) {
        return "scale(1.06)";
      } else {
        return "0";
      }
    }};
    box-shadow: ${({ isHover }) => {
      if (isHover) {
        return "0px 30px 34px rgba(238, 144, 76, 0.53)";
      } else {
        return "0";
      }
    }};
    background: ${({ isHover }) => {
      if (isHover) {
        return "linear-gradient(168.55deg,#fbda61 68.9%,#fad44c 84.53%,#ee7d3e 113.04%,#ee7d3e 129.72%)";
      } else {
        return "#ffffff";
      }
    }};
    @media (max-width: 600px) {
      width: 86%;
    }
  }
  .flex_main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 34px;
  }
  .price_detials {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
    .price {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 46px;
      color: ${(props) => {
        if (props.isHover) {
          return "#404040";
        } else {
          return "#231d4f";
        }
      }};
      @media (max-width: 600px) {
        font-size: 25px;
      }
    }
    .title {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
      color: ${(props) => {
        if (props.isHover) {
          return "#404040";
        } else {
          return "#231d4f";
        }
      }};
      @media (max-width: 600px) {
        font-size: 25px;
      }
    }
    .description {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      padding-top: 5px;
      color: ${(props) => {
        if (props.isHover) {
          return "#404040";
        } else {
          return "#3e3637";
        }
      }};
    }
  }
  .bullet {
    display: flex;
    flex-direction: column;
    gap: 11px;
  }
  .bullet_flex {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    .circle {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      background: ${(props) => {
        if (props.isHover) {
          return "#E8CE6C";
        } else {
          return "linear-gradient(180deg,rgba(251, 218, 97, 0.3) 0%,rgba(250, 212, 76, 0.231) 33.16%,rgba(238, 125, 62, 0.093) 100%);";
        }
      }};
      .tickmark:hover {
        fill: #000000;
      }
    }
    .point_name {
      font-family: Nunito;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
  .choos_button {
    border-radius: 24px;
    height: 45px;
    width: 207px;
    border-radius: 24px;
    margin: auto;
    border: ${(props) => {
      if (props.isHover) {
        return "none";
      } else {
        return "1px solid #dfdfdf";
      }
    }};
    background: ${(props) => {
      if (props.isHover) {
        return "#2D4764";
      } else {
        return "#ffffff";
      }
    }};
    color: ${(props) => {
      if (props.isHover) {
        return "#ffffff";
      } else {
        return "#000000";
      }
    }};
  }
  .price_flex {
    display: flex;
    align-items: center;
    gap: 15px;
    button {
      background: linear-gradient(135deg, #fbda61 2.88%, #ffc371 100%);
      border-radius: 13.5px;
      height: 27px;
      width: 121px;
    }
  }
`;
