import React, { useEffect, useRef } from "react";

import createProto from "./proto.js";
import Renderer3 from "./js/lib/renderer3.js";
const ThreeScene = () => {
  const threejsEl = useRef(null);
  //@ts-ignore
  let app = new Renderer3(threejsEl);

  let { THREE } = app;
  const popupRef = useRef();
  const linesGroupRef = useRef(new THREE.Group());
  const dragControls = useRef(null);

  // useEffect(() => {
  //   let proto = createProto({ threejsEl, popupRef, linesGroupRef, dragControls });
  //   //FIRE IT UP

  //   return () => {
  //     console.log("DISPOSE");
  //     proto && proto.destroy();
  //   };
  // }, []);

  return (
    <div ref={threejsEl} className="hello" style={{ width: "100vw", height: "100vh" }}>
      hello
    </div>
  );
};

export default ThreeScene;
