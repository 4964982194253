import styled from "styled-components";

interface installerChoiceProps {
  isPad?: boolean;
}

export const InstallerOurChoice = styled.div<installerChoiceProps>`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1.55382px 6.21527px rgba(0, 0, 0, 0.12);
  border-radius: 10.8767px;
  background: white;
  width: 300px;
  margin: 20px;
  margin-inline: auto;
  min-height: ${({ isPad }) => {
    if (isPad) {
      return "350px";
    } else {
      return "420px";
    }
  }};
  // &:hover {
  //   transform: scale(1.06);
  // }
  margin-inline: 0.5rem;

  @media only screen and (max-width: 500px) {
    width: 265px;
  }
`;

export const CardHeader = styled.div`
  border-radius: 10.8767px 10.8767px 0 0;
  border-top: 6px solid #ffd75d;
  border-bottom: 1px solid #ffd75d;
  padding: 10px 26px;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 20px;
  color: #2d4764;
  display: flex;
  justify-content: space-between;
  span {
    color: #9d9d9d;
    cursor: pointer;
  }
`;
export const CardDetails = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 25px 20px;
  p {
    margin: 10px 0;
  }
  .location {
    margin: 8px 0;
    display: flex;
    gap: 8px;
  }
  p,
  .location,
  a,
  button {
    font-family: "Nunito";
    font-weight: 600;
    font-size: 14px;
    color: #2d4764;
  }
  .free-choice-text,
  .location {
    font-size: 14px;
  }
  a {
    margin: 5px 0;
    width: fit-content;
    text-decoration: underline;
    font-size: 14px;
    img {
      display: inline-block;
      margin-left: 8px;
      margin-bottom: 2px;
    }
    span {
      display: inline-block;
      padding-left: 10px;
    }
  }
  button {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    border: 0.733394px solid #2d4764;
    border-radius: 26.4022px;
    background-color: #ffffff;
    padding: 10px 15px;
    width: fit-content;
    margin: 10px auto 0;
  }
`;
