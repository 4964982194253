import styled from "styled-components/macro";

export const WhiteLabelInstaller = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }
`;

export const ProjectDetails = styled.div`
  margin: -18px !important;
  @media only screen and (max-width: 768px) {
    margin: 0px !important
  }
`;
