import { makeStyles } from "@mui/styles";

import { colors } from "src/utils";

export const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "100%",
    padding: "0 25px 25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  center: {
    textAlign: "center",
    color: "#2D4764",
  },
  main_container: {
    // width: "100%",
  },
  profiles: {
    display: "flex",
    flexWrap: "wrap",
    flexShrink: "0",
    justifyContent: "center",
    alignItems: "center",
  },
  inside_main: {
    width: "175px",
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
    height: "140px",
    margin: "5px",
    border: "1px solid #2D4764",
    borderRadius: "19px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    "& img": {
      height: "64px",
      marginTop: "20px",
      width: "76px",
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(253, 184, 0, 0.1)",
    },
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
  },
  headerText: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    "& h1": {
      color: colors.darkBlue,
      fontSize: "20px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "28px",
      },
    },
    "& h3": {
      color: colors.darkBlue,
      fontSize: "13px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "21px",
      },
      marginBottom: "15px",
    },
  },
  button: {
    background: colors.darkBlue,
    color: "#fff",
    "&:hover": {
      background: "linear-gradient(226.42deg, #1B4963 8.93%, #09152F 110.98%)",
    },
    margin: "10px",
  },
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "10px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
