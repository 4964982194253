import { makeStyles } from "@mui/styles";

export const useShareButtonStyles = makeStyles((theme: any) => ({
  flex_x: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  pa10: {
    padding: "10px",
  },
}));
