import { formatNumberByLang } from "src/hooks/useNumberFormat";
import { OverallConsumptionModel } from "src/prediction-model/types/model.types";
import { DonoughtRecord } from "src/shared/components";
import { getPercentage } from "src/utils";

export const getChartRecordsFromModel = (model: OverallConsumptionModel): DonoughtRecord[] => {
  const { household, ev, heatpump, heatingRod } = model;
  const householdPercent = getPercentage(household.fromPVAndBattery, model.fromPVAndBattery);
  const evPercent = getPercentage(ev.fromPVAndBattery, model.fromPVAndBattery);
  const heatpumpPercent = getPercentage(heatpump.fromPVAndBattery, model.fromPVAndBattery);
  const heatingRodPercent = getPercentage(heatingRod.fromPVAndBattery, model.fromPVAndBattery);

  return [
    {
      color: "#F2994A",
      hoverLabel: "Home electricity",
      value: parseInt(household.fromPVAndBattery.toFixed(0)),
      shadowColor: "rgba(242, 153, 74, 1)",
      label: {
        mainLabel: "Home electricity",
        subLine: `${formatNumberByLang(householdPercent.toFixed(0))}%, ${formatNumberByLang(
          model.household.fromPVAndBattery.toFixed(0),
        )} kWh`,
      },
    },
    {
      color: "#287E97",
      hoverLabel: "E-Mobility",
      value: parseInt(ev.fromPVAndBattery.toFixed(0)),
      shadowColor: "rgba(40, 126, 151, 1)",
      label: {
        mainLabel: "E-Mobility",
        subLine: `${formatNumberByLang(evPercent.toFixed(0))}%, ${formatNumberByLang(
          model.ev.fromPVAndBattery.toFixed(0),
        )} kWh`,
      },
    },
    {
      color: "#B5262B",
      hoverLabel: "Heatpump",
      value: parseInt(heatpump.fromPVAndBattery.toFixed(0)),
      shadowColor: "rgba(181, 38, 43, 1)",
      label: {
        mainLabel: "Heatpump",
        subLine: `${formatNumberByLang(heatpumpPercent.toFixed(0))}%, ${formatNumberByLang(
          model.heatpump.fromPVAndBattery.toFixed(0),
        )} kWh`,
      },
    },
    {
      color: "#B5268D",
      hoverLabel: "Heating-rod",
      value: parseInt(heatingRod.fromPVAndBattery.toFixed(0)),
      shadowColor: "#020102",
      label: {
        mainLabel: "Heating-rod",
        subLine: `${formatNumberByLang(heatingRodPercent.toFixed(0))}%, ${formatNumberByLang(
          model.heatingRod.fromPVAndBattery.toFixed(0),
        )} kWh`,
      },
    },
  ];
};
