import { clone, merge } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const updateConstants = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateConstants>,
): ProjectState => {
  const newState = clone(currentState);

  newState.constants = merge({}, currentState.constants, payload);
  return newState;
};
