import styled from "styled-components/macro";

export const ConfigurationRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MapContainer = styled.div`
  .gm-control-active {
    img {
      height: 30px !important;
    }
  }

  .map-container{
    height: 350px !important;
  }
`;
