import { InstallerOfferDB } from "src/db-types";
import { actionCreators, ProjectState } from "src/redux/project";

export const addSelectedInstaller = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addSelectedInstaller>,
): ProjectState => {
  return {
    ...currentState,
    selectedInstallers: [...currentState.selectedInstallers, payload],
  };
};

export const updateSelectedInstaller = (
  currentState: ProjectState,
  action: {
    payload: {
      installerId: string;
    };
  },
): ProjectState => {
  const { installerId } = action.payload;

  const index = currentState.selectedInstallers.findIndex(
    (installer) => installer._id === installerId,
  );

  if (index !== -1) {
    const updatedInstallers = [...currentState.selectedInstallers];
    updatedInstallers[index] = {
      ...updatedInstallers[index],
      offerAccepted: true,
    };

    return {
      ...currentState,
      selectedInstallers: updatedInstallers,
    };
  }

  return currentState;
};
