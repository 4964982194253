import {
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";

// import { sanitizeCurrentURL } from "src/utils";

import { sanitizeCurrentURL } from "src/utils";

import Email from "./Email.svg";
import { useStyles } from "./MixButton.styles";

const MixButton: React.FC = () => {
  const classes: any = useStyles();
  const msg = `${sanitizeCurrentURL()}`;

  return (
    <div className={classes.mainMixButton}>
      <EmailShareButton url={msg}>
        <img src={Email} className={classes.comman} alt="" />
      </EmailShareButton>
      <LinkedinShareButton url={msg} windowWidth={700} windowHeight={800}>
        <LinkedinIcon round size={30} />
      </LinkedinShareButton>
      <FacebookShareButton windowWidth={700} windowHeight={800} url={msg}>
        <FacebookIcon round size={30} />
      </FacebookShareButton>
      <WhatsappShareButton windowWidth={700} windowHeight={800} url={msg}>
        <WhatsappIcon round size={30} />
      </WhatsappShareButton>
    </div>
  );
};

export default MixButton;
