import { colors } from "src/utils";
import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  width: 80%;
  min-height: 350px;
  border-radius: 22px;
  background-color: ${colors.ctaSolid};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InstallerDetails = styled.div`
  flex: 3;
  padding: 5%;
  background-color: ${colors.white};
  border-radius: 22px 0px 0px 22px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: 768px) {
    flex: 1;
    border-radius: 22px 22px 0px 0px;
  }
`;

export const Logo = styled.div``;

export const Details = styled.div`
  font-size: 18px;
  font-weight: 700;
  span {
    font-weight: 400;
    max-width: 70%;
  }
`;

export const DownloadDetails = styled.div`
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
`;

export const MapDetails = styled.div`
  flex: 7;
  padding-inline: 5%;
  padding-top: 2%;
  height: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex: 1;
    border-radius: 22px;
    margin-top: 5%;
  }
`;

export const MapHeading = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const MapView = styled.div`
  width: 100%;
  max-height: 300px;
  text-align: center;
  border-radius: 20px;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    max-height: 150px;
  }
`;

export const MapBottom = styled.div`
  font-size: 18px;
  font-weight: 600;
  min-width: max-content;
  word-wrap: break-word;
  word-break: break-all;
`;
