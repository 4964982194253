import { clone, isNull } from "lodash";

import { MainUserState, UserState, actionCreators } from "src/redux/user";

export const setUser = (
  currentState: UserState,
  { payload }: ReturnType<typeof actionCreators.setUser>,
): UserState => {
  const newState = clone(currentState);
  let newUser: MainUserState | null = null;

  if (!isNull(payload)) {
    newUser = payload;
  }

  newState.user = newUser;
  return newState;
};
