import React from "react";
import { CardContainer, CardCount, CardImage, CardText, CardTitle } from "./tenantCardStyles";
import { InfoIcon } from "src/shared/components";

const TenantCard = ({
  image,
  title,
  text,
  count = 1,
  handleConceptSelect,
  setactiveStep,
  isActive,
}: any) => {
  const handleSelectConcept = () => {
    handleConceptSelect(title);
    setactiveStep(2);
  };

  return (
    <CardContainer onClick={handleSelectConcept} isActive={isActive}>
      <CardTitle>
        {title} <InfoIcon />
      </CardTitle>
      <CardImage src={image} />
      <CardText>{text}</CardText>
      <CardCount>{count}</CardCount>
    </CardContainer>
  );
};

export default TenantCard;
