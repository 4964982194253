import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { TabBar } from "src/shared/components";

import { ImageView, SatelliteLabel } from "./components";
import ConsumptionCharts from "./ConsumptionCharts/ConsumptionCharts";
import * as S from "./styles";
import { useSelector } from "react-redux";
import { UserSelectors } from "src/redux/selectors";

const TAB_KEYS = {
  SATELLITE: "SATELLITE",
  CONSUMPTION: "CONSUMPTION",
};

const ImageConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const simplify = useSelector(UserSelectors.getSimplify);

  const tabs = useMemo(() => {
    if (!simplify) {
      return [
        {
          key: TAB_KEYS.SATELLITE,
          labelComponent: <SatelliteLabel />,
        },
        {
          key: TAB_KEYS.CONSUMPTION,
          label: t("Consumption"),
        },
      ];
    } else {
      return [
        {
          key: TAB_KEYS.SATELLITE,
          labelComponent: <SatelliteLabel />,
        },
      ];
    }
  }, [t, simplify]);

  const [selectedTab, setSelectedTab] = useState(TAB_KEYS.SATELLITE);
  const handleTabClick = useCallback(
    (tabKey: string) => {
      setSelectedTab(tabKey);
    },
    [setSelectedTab],
  );

  return (
    <S.MainContainer>
      <TabBar tabs={tabs} clickHandler={handleTabClick}></TabBar>

      {selectedTab === TAB_KEYS.SATELLITE && <ImageView />}
      {selectedTab === TAB_KEYS.CONSUMPTION && <ConsumptionCharts></ConsumptionCharts>}
    </S.MainContainer>
  );
};

export default ImageConfiguration;
