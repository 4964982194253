import _ from "lodash";
import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PredictionModel } from "src/prediction-model/PredictionModel";
import { defaultModelInput } from "src/prediction-model/utility/default-model-values";

import { useModelInput } from "./useModelInput";

import { AppActions, ProjectActions } from "src/redux/actionCreators";
import { AppSelectors, ProjectSelectors } from "src/redux/selectors";

export const predModel = new PredictionModel({ ...defaultModelInput });

export const useModelUpdate = (): void => {
  const dispatch = useDispatch();
  const previousInputs = useRef({ ...defaultModelInput });
  const timeout = useRef<any>(null);
  const { setAutarkiesTo0, setAutarkiesToMax } = useSelector(AppSelectors.getState);
  const { selectedFeedInReason } = useSelector(ProjectSelectors.getFinancialDetails);

  const modelInputs = useModelInput();

  useEffect(() => {
    if (_.isEqual(previousInputs.current, modelInputs)) {
      return;
    }

    if (timeout.current) clearTimeout(timeout.current);
    predModel.setInputsAndPredict(modelInputs);
    previousInputs.current = modelInputs;

    if (selectedFeedInReason === "full-feed-in") {
      dispatch(AppActions.updateAppState({ setAutarkiesTo0: true }));
    } else {
      dispatch(ProjectActions.updateModel(predModel.state));
    }
  }, [dispatch, modelInputs, selectedFeedInReason]);

  useEffect(() => {
    if (!setAutarkiesToMax) return;

    predModel.setInputsAndPredict();
    dispatch(ProjectActions.updateModel(predModel.state));
    dispatch(AppActions.updateAppState({ setAutarkiesToMax: false }));
  }, [dispatch, setAutarkiesToMax]);

  useEffect(() => {
    if (!setAutarkiesTo0) return;
    predModel.updateDerivedValuesOnAutarkyChange({
      ev: 0,
      heatpump: 0,
      household: 0,
    });

    dispatch(ProjectActions.updateModel(predModel.state));
    dispatch(AppActions.updateAppState({ setAutarkiesTo0: false }));
  }, [dispatch, setAutarkiesTo0]);
};
