import { InstallerOfferDB } from "src/db-types";
import { UseInstallerDetails } from "../../hooks/useInstallerDetails";
import * as S from "./Offers.styles";
import { useTranslation } from "react-i18next";
import MapGL, { Marker, SVGOverlay } from "react-map-gl";
import OfferCheckout from "./components/OfferCheckout/OfferCheckout";
import { useEffect, useState } from "react";
import RoomIcon from "@mui/icons-material/Room";
import BusinessIcon from "@mui/icons-material/Business";
import { useSelector } from "react-redux";
import { UserSelectors } from "src/redux/selectors";

interface IOffers {
  offer: InstallerOfferDB;
  handleAcceptOffer: ({ id }: { id: string }) => void;
  handleRejectOffer: ({ id, reason }: { id: string; reason: string }) => void;
}

const Offers = ({ offer, handleAcceptOffer, handleRejectOffer }: IOffers) => {
  const installerDetails = UseInstallerDetails(offer);
  const Installer = useSelector(UserSelectors?.getInstaller);
  const { t } = useTranslation();
  const [viewport, setViewport] = useState({
    width: "100%",
    height: 400,
    latitude: 89.9,
    longitude: 89.9,
    zoom: 12,
  });

  const [highlightPath, setHighlightPath] = useState(false);

  useEffect(() => {
    setViewport({
      ...viewport,
      latitude: installerDetails.userGeometry?.coordinates[1] || 0.0,
      longitude: installerDetails.userGeometry?.coordinates[0] || 0.0,
      zoom: 14,
    });

    const timeout = setTimeout(() => {
      setHighlightPath(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [installerDetails.userGeometry]);

  const handleViewportChange = (newViewport: any) => {
    setViewport({ ...viewport, ...newViewport });
  };

  return (
    <>
      <S.Card>
        <S.InstallerDetails>
          <S.Logo>
            <img src={installerDetails?.logoUrl[0]} className="logo_url" alt="" />{" "}
          </S.Logo>
          <S.Details>
            <div>{t("Your project partner")}</div>
            <span>{installerDetails?.companyName}</span>
          </S.Details>
          <S.Details>
            <div>{t("Distance to your roof")}</div>
            <span>{installerDetails?.distance?.toFixed(2)} km</span>
          </S.Details>
          <S.Details>
            <div>{t("Planned realization")}</div>
            <span>
              {installerDetails?.realizationWeekShort || Installer?.realizationWithIn6Weeks}
            </span>
          </S.Details>
          <S.DownloadDetails onClick={() => (window.location.href = installerDetails?.pdfUrl)}>
            <div>{t("Download PDF Offer")}</div>
          </S.DownloadDetails>
        </S.InstallerDetails>
        <S.MapDetails>
          <S.MapHeading>Congratulations you have received an offer</S.MapHeading>
          <S.MapView>
            <MapGL
              {...viewport}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              mapboxApiAccessToken={
                "pk.eyJ1IjoicnVzaGlsLXBhdGVsIiwiYSI6ImNsczFrMGt2cjA5eWgyc21rN2l6aGV2MHYifQ.CQUKx8WJJrkVL3l3LnW2vg"
              }
              onViewportChange={handleViewportChange}
            >
              <Marker
                latitude={installerDetails.userGeometry?.coordinates[1] || 0.0}
                longitude={installerDetails.userGeometry?.coordinates[0] || 0.0}
                offsetTop={-20}
                offsetLeft={-10}
              >
                <RoomIcon style={{ fontSize: 30, color: "green" }} />
              </Marker>

              {installerDetails?.installerGeometry && (
                <Marker
                  latitude={installerDetails.installerGeometry.coordinates[1]}
                  longitude={installerDetails.installerGeometry.coordinates[0]}
                  offsetTop={-20}
                  offsetLeft={-10}
                >
                  <BusinessIcon style={{ fontSize: 30, color: "blue" }} />
                </Marker>
              )}

              {highlightPath &&
                installerDetails?.userGeometry &&
                installerDetails?.installerGeometry && (
                  <SVGOverlay
                    redraw={({ project }: any) => {
                      const [x1, y1] = project([
                        installerDetails.userGeometry?.coordinates[0],
                        installerDetails.userGeometry?.coordinates[1],
                      ]);
                      const [x2, y2] = project([
                        installerDetails.installerGeometry?.coordinates[0],
                        installerDetails.installerGeometry?.coordinates[1],
                      ]);

                      return (
                        <line
                          x1={x1}
                          y1={y1}
                          x2={x2}
                          y2={y2}
                          stroke="#3498db"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      );
                    }}
                  />
                )}
            </MapGL>
          </S.MapView>
          <S.MapBottom>{installerDetails?.address}</S.MapBottom>
        </S.MapDetails>
      </S.Card>
      <OfferCheckout
        finalPrice={installerDetails?.finalPrice}
        discount={installerDetails?.totalProjectCost - installerDetails?.finalPrice}
        handleAcceptOffer={handleAcceptOffer}
        handleRejectOffer={handleRejectOffer}
        id={installerDetails?.id || ""}
      />
    </>
  );
};

export default Offers;
