import { MouseEventHandler } from "react";

import * as S from "./styles";

interface MobileNavLinkProps {
  Icon?: any;
  linkText: string;
  onClick: MouseEventHandler<HTMLDivElement>;
  style?: any;
}

const MobileNavLink: React.FC<MobileNavLinkProps> = (props: MobileNavLinkProps) => {
  let { Icon, linkText, onClick, style } = props;
  if (!style) style = {};

  return (
    <S.NavMaterialButton variant="text" style={style}>
      <S.MobileNavLink onClick={onClick}>
        {Icon && (
          <S.Icon>
            <Icon />
          </S.Icon>
        )}
        <div style={{ textAlign: "left" }}>{linkText}</div>
      </S.MobileNavLink>
    </S.NavMaterialButton>
  );
};

export default MobileNavLink;
