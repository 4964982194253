import { CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { BackendApis } from "src/api";

import { DownArrow } from "src/assets/svgs";
import { InstallerDB, PaginationDB } from "src/db-types";
import { useExploreInstallerAPI } from "src/hooks/apis";

import { useIsInstallerSelected } from "../../../../hooks/useIsInstallerSelected";
import InstallerCard from "../InstallerCard/InstallerCard";

import {
  Container,
  Form,
  Heading,
  Input,
  InstallerCards,
  MainHead,
  ViewMore,
} from "./Explore.styles";

import { ProjectSelectors } from "src/redux/selectors";
import { debounce } from "lodash";

const defaultPaginationData: PaginationDB = {
  hasNextPage: true,
  hasPreviousPage: false,
  limit: 0,
  nextPage: 1,
  pageCount: 0,
  previousPage: 0,
  totalDocs: 0,
};

interface ExploreProps {
  onInstallerCancelRequest: (installer: InstallerDB) => void;
  onAcceptOffer: () => void;
  onRequestInstallerOffer: (installer: InstallerDB) => void;
}
export const Explore: React.FC<ExploreProps> = (props: ExploreProps) => {
  const { onAcceptOffer, onInstallerCancelRequest, onRequestInstallerOffer } = props;
  const { t } = useTranslation();
  const exploreRef = useRef<HTMLDivElement>(null);

  const [showCards, setShowCards] = useState(false);
  const [vendorData, setVendorData] = useState<InstallerDB[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationDB>(defaultPaginationData);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const mapboxCoords = useSelector(ProjectSelectors.getAddressCoords);

  const { fetchData, response, loading } = useExploreInstallerAPI();
  const { isSelected: isInstallerSelected } = useIsInstallerSelected();

  /** Explore button click and expansion + scrolling */
  const expandBtnClickHandler = (): void => {
    const ele = document.getElementById("application-body-container");

    const scrollDown = (): void => {
      if (!exploreRef.current) return;
      if (!ele) return;

      const spaceFromTop = exploreRef.current.getBoundingClientRect().top - 100;
      if (spaceFromTop < 0) return;
      const scrollAmount = ele.scrollTop + exploreRef.current.getBoundingClientRect().top - 100;

      setTimeout(() => {
        ele.scrollTo({
          top: scrollAmount,
          behavior: "smooth",
        });
      });
    };

    const scrollUp = (): void => {
      ele?.scrollBy({
        top: 0,
        behavior: "smooth",
      });
    };

    setShowCards((show) => {
      // next state = !show
      if (!show) {
        // now it will be shown
        scrollDown();
      } else {
        // now it will collapse
        scrollUp();
      }

      return !show;
    });
  };

  /** fetching more installers based on previous index */
  const viewMoreClickHandler = (): void => {
    if (!paginationData.hasNextPage) return;
    if (!paginationData.nextPage) return;

    loadNextSetOfInstallers({
      coords: mapboxCoords,
      pageNumber: paginationData.nextPage,
    });
  };

  const loadNextSetOfInstallers = useCallback(
    ({
      coords,
      pageNumber,
      companyName,
    }: {
      coords: [number, number];
      pageNumber: number;
      companyName?: string;
    }) => {
      void fetchData({
        ...BackendApis.exploreInstaller,
        data: {
          mapboxCoordinates: coords,
          hideNotNeededInstallers: true,
          companyName: companyName,
        },
        params: {
          page: pageNumber,
          limit: 9,
        },
      });
    },
    [fetchData],
  );

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setVendorData([]);
      setPaginationData(defaultPaginationData);
      loadNextSetOfInstallers({
        coords: mapboxCoords,
        pageNumber: 1,
        companyName: value,
      });
    }, 300),
    [loadNextSetOfInstallers, mapboxCoords],
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    loadNextSetOfInstallers({
      coords: mapboxCoords,
      pageNumber: 1,
    });
  }, [loadNextSetOfInstallers, mapboxCoords]);

  useEffect(() => {
    if (!response) return;

    const {
      data: {
        data: { docs, pagination },
      },
    } = response;
    setVendorData((d) => d.concat(docs));
    setPaginationData(pagination);
  }, [response]);

  return (
    <Container id="container" ref={exploreRef}>
      <Heading rotate={showCards ? "270deg" : "90deg"} border={showCards}>
        <MainHead onClick={expandBtnClickHandler}>
          <span>{t("Explore more installers")}</span>
          <DownArrow />
        </MainHead>
      </Heading>

      {showCards && (
        <>
          <Form>
            <Input
              placeholder={t("Search Installers")}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Form>
          <InstallerCards>
            {vendorData.map((data) => {
              return (
                <InstallerCard
                  key={data._id}
                  data={data}
                  isAlreadyRequested={isInstallerSelected(data._id)}
                  onAcceptBtnClick={onAcceptOffer}
                  onCancelBtnClick={(installer) => {
                    setVendorData((v) => v.filter((v) => v._id !== installer._id));
                    // onInstallerCancelRequest(installer);
                  }}
                  onRequestBtnClick={onRequestInstallerOffer}
                />
              );
            })}
          </InstallerCards>
          {paginationData.nextPage && (
            <ViewMore onClick={viewMoreClickHandler} disabled={loading}>
              {loading && <CircularProgress size={"1rem"} style={{ marginRight: "10px" }} />}
              {t("View More")} <span>&darr;</span>
            </ViewMore>
          )}
        </>
      )}
    </Container>
  );
};
