import { cloneDeep } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const addBattery = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addBattery>,
): ProjectState => {
  return {
    ...currentState,
    components: {
      ...currentState.components,
      battery: cloneDeep(payload),
    },
  };
};

export const addBatteryProduct = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addBatteryProduct>,
): ProjectState => {
  return {
    ...currentState,
    components: {
      ...currentState.components,
      battery: {
        ...currentState.components.battery,
        item: cloneDeep(payload),
      },
    },
  };
};
