import { clone } from "lodash";

import { UserState, actionCreators } from "src/redux/user";

export const setIsAuthLoading = (
  currentState: UserState,
  { payload }: ReturnType<typeof actionCreators.setIsAuthLoading>,
): UserState => {
  const newState = clone(currentState);

  newState.isAuthStateLoading = payload;
  return newState;
};
