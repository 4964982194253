import { clone } from "lodash";

import { actionCreators } from "../..";
import { ProductsState } from "../../types";

export const setProductObjFiles = (
  currentState: ProductsState,
  { payload }: ReturnType<typeof actionCreators.setProductObjFiles>,
): ProductsState => {
  const data = clone(payload);

  return {
    ...currentState,
    productObjFiles: data,
  };
};
