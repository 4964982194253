import { Slider } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { MasterInput } from "src/shared/components";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import { HeatpumpConfiguration } from "src/redux/project";
import { ProjectSelectors } from "src/redux/selectors";

interface EnergyCertificateLineProps {
  onValueChange: (heatpumpPartData: Partial<HeatpumpConfiguration>) => void;
}
const EnergyCertificateLine: React.FC<EnergyCertificateLineProps> = (
  props: EnergyCertificateLineProps,
) => {
  const { onValueChange } = props;
  const { t } = useTranslation();

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);

  const onSliderChangeHandler = useCallback(
    (value: any) => {
      onValueChange({
        energyDemand: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line hasSlider>
      <ParameterStyles.Label hasSlider>{t("Energy certificate")}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider max={300} value={configuration.energyDemand} onChange={onSliderChangeHandler} />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <MasterInput
          value={configuration.energyDemand}
          onChange={onSliderChangeHandler}
          label="kWh/m²"
          appearance={{
            color:
              (configuration.totalAreaToBeHeated * configuration.energyDemand !==
                configuration.consumptionHeatpump &&
                "#666666") ||
              undefined,
          }}
        />
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default EnergyCertificateLine;
