import { FeedInReason } from "src/redux/project/types/financial-details";

export const feedInCategories = [
  {
    minCap: 0,
    maxCap: 10,
    feedInTarrif: 0.082,
    fullFeedIn: 0.13,
    directSaleTo3rdParty: 0.09,
  },
  {
    minCap: 10,
    maxCap: 40,
    feedInTarrif: 0.071,
    fullFeedIn: 0.109,
    directSaleTo3rdParty: 0.085,
  },
  {
    minCap: 40,
    maxCap: 100,
    feedInTarrif: 0.058,
    fullFeedIn: 0.109,
    directSaleTo3rdParty: 0.085,
  },
  {
    minCap: 100,
    maxCap: 300,
    feedInTarrif: 0.062,
    fullFeedIn: 0.113,
    directSaleTo3rdParty: 0.085,
  },
  {
    minCap: 300,
    maxCap: 750,
    feedInTarrif: 0.062,
    fullFeedIn: 0.107,
    directSaleTo3rdParty: 0.085,
  },
  {
    minCap: 750,
    maxCap: Number.MAX_SAFE_INTEGER,
    feedInTarrif: 0.062,
    fullFeedIn: 0.062,
    directSaleTo3rdParty: 0.085,
  },
];

export const getFeedInTarrifByCapacity = (
  capacity: number,
  feedInReason: FeedInReason,
): number | undefined => {
  const fittingCategory = feedInCategories.find(
    (c) => c.minCap <= capacity && c.maxCap >= capacity,
  );
  if (!fittingCategory) {
    console.error("FeedInCategory is null");
  }

  if (feedInReason === "direct-sale-to-3rd-party") return fittingCategory?.directSaleTo3rdParty;
  if (feedInReason === "feed-in-tariff") return fittingCategory?.feedInTarrif;
  if (feedInReason === "full-feed-in") return fittingCategory?.fullFeedIn;
};
