import styled from "styled-components/macro";

interface TabTitleProps {
  selected: boolean;
  disabled?: boolean;
}

export const TabTitleRow = styled.div`
  height: 39px;
  width: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  @media (min-width: 768px) {
    overflow-x: hidden;
  }
`;

export const TabTitle = styled.div<TabTitleProps>`
  padding-inline: 24px;
  height: 39px;
  width: max-content;
  flex: 1;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  font-family: "Nunito";
  font-style: normal;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.005em;
  /* text-transform: capitalize; */

  font-weight: ${(props) => {
    if (props.selected) {
      return "700";
    } else {
      return "600";
    }
  }};

  color: ${(props) => {
    if (props.selected) {
      return "#2D4764";
    } else {
      return "#666666";
    }
  }};

  cursor: ${(props) => {
    if (props.disabled) {
      return "not-allowed";
    } else {
      return "pointer";
    }
  }};

  border-bottom: ${(props) => {
    if (props.disabled) {
      return "3px solid #ffd75d66";
    } else if (props.selected) {
      return "3px solid #ffd75d";
    } else {
      return "3px solid #fff7dd";
    }
  }};

  :hover {
    background: ${(props) => {
      if (props.disabled) {
        return "white";
      } else if (props.selected) {
        return "#FFF5D8";
      } else {
        return "#FFF5D8";
      }
    }};

    border-bottom: ${(props) => {
      if (props.disabled) {
        return "#white";
      } else if (props.selected) {
        return "3px solid #ffd75d";
      } else {
        return "3px solid #ffd75d";
      }
    }};

    font-weight: ${(props) => {
      if (props.selected && !props.disabled) {
        return "700";
      }
    }};
  }

  @media (max-width: 500px) {
    padding-inline: 12px;
    font-size: 14px;
  }
`;
