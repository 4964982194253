import { IbanElement } from "@stripe/react-stripe-js";
import React, { FormEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getLangCode } from "../../../redux/user/selectors";

import { IbanFormWrapper } from "./styles";

const IBAN_STYLE = {
  base: {
    color: "#32325d",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4",
    },
    ":-webkit-autofill": {
      color: "#32325d",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
    ":-webkit-autofill": {
      color: "#fa755a",
    },
  },
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ["SEPA"],
  placeholderCountry: "DE",
  style: IBAN_STYLE,
};

interface IbanFormProps {
  onSubmit: FormEventHandler<HTMLFormElement>;
  disabled: boolean;
  price: string;
}

const IbanForm: React.FC<IbanFormProps> = ({ onSubmit, disabled, price }) => {
  const { t } = useTranslation();
  const isEnglish = useSelector(getLangCode);
  return (
    <IbanFormWrapper>
      <div className="sr-main">
        <form onSubmit={onSubmit}>
          <div className="sr-combo-inputs-row">
            <div className="col">
              <label>Name</label>
              <input name="accountholder-name" placeholder="Jenny Rosen" required id="work" />
            </div>

            <div className="col">
              <label>{t("Email Address")}</label>
              <input
                name="email"
                type="email"
                id="work"
                placeholder="jenny.rosen@example.com"
                required
              />
            </div>
          </div>

          <div className="sr-combo-inputs-row">
            <div className="col">
              <label>IBAN</label>
              <IbanElement options={IBAN_ELEMENT_OPTIONS} />
            </div>
          </div>
          <button type="submit" id="confirm-mandate" disabled={disabled}>
            {isEnglish !== "de"
              ? `Confirm mandate and initiate debit for ${price}€`
              : `Zahlung bestätigen ${price}€`}
          </button>
          <div className="mandate-acceptance">
            {t(
              "By providing your payment information and confirming this payment, you authorise (A) Rocket Rides and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.",
            )}
          </div>
        </form>
      </div>
    </IbanFormWrapper>
  );
};

export default IbanForm;
