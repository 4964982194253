import React from "react";
import { useSelector } from "react-redux";

import { getAverageCapacity, getAverageMaxCapacity, getAverageProductCost } from "src/helpers";

import {
  getAdditionalHardware,
  getAdditionalSoftware,
  getModel,
} from "src/redux/project/selectors";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

import { useAppSelector, useYearlySystemProfit } from ".";

const useCalculateMultiTenants = () => {
  const { expectedSolar, expectedTenants, electricityPrice, multiTenantLicenceFees } = useSelector(
    ProjectSelectors.getHousehold,
  );
  const { solarPanelProduction } = useSelector(ProjectSelectors.getEnergy);
  const { profitByYear } = useYearlySystemProfit();
  const {
    costs: { appliedProjectCost },
    pricePerKwh,
    feedInTariff,
    expectedReturnTime,
  } = useSelector(ProjectSelectors.getFinancialDetails);
  const solarPanel = useAppSelector(ProjectSelectors.getSolarPanel);
  const battery = useAppSelector(ProjectSelectors.getBattery);
  const filteredSolarPanels = useAppSelector(ProductSelectors.getFilteredSolarPanels);
  const filteredBatteries = useAppSelector(ProductSelectors.getFilteredBatteries);
  const hardware = useSelector(getAdditionalHardware);
  const software = useSelector(getAdditionalSoftware);
  const filteredHardwares = useSelector(ProductSelectors.getFilteredAdditionalHardware);
  const filteredSoftwares = useSelector(ProductSelectors.getFilteredAdditionalSoftware);
  const { fromGrid } = useSelector(getModel);
  let capacity = getAverageCapacity(filteredSolarPanels);
  let batteryStorage = getAverageMaxCapacity(filteredBatteries);
  const { profit } = profitByYear(expectedReturnTime);

  const hardwareCost = hardware?.item
    ? hardware?.item?.priceB2c * hardware?.quantity
    : getAverageProductCost(filteredSoftwares);

  const softwareCost = software?.item
    ? software?.item?.priceB2c * software?.quantity
    : getAverageProductCost(filteredHardwares);

  if (solarPanel?.item) {
    capacity = Number(solarPanel?.item?.capacity);
  }
  if (solarPanel?.item) {
    batteryStorage = Number(battery?.item?.applicableMaxCapacity);
  }
  const newSelfConsumption = expectedSolar * expectedTenants;
  const feedInForMultiTenants = solarPanelProduction - newSelfConsumption;
  const yearlyGains = electricityPrice * newSelfConsumption;
  const totalInvestMent = appliedProjectCost + hardwareCost + softwareCost;
  const equipmentCost = hardwareCost + softwareCost;
  const systemSize = solarPanel.quantity * capacity;
  const totalEnergyProduction = solarPanelProduction;
  const totalEnergyConsumption = newSelfConsumption + fromGrid;
  const advantageTenantYearly = newSelfConsumption * (pricePerKwh - electricityPrice);
  const projectingCost = 3100;
  const baseTiff = 4.9;
  const chargeForTotalsAndGenerationMeters = 348;
  const multiTenantTurnOverBaseFees = multiTenantLicenceFees * 12 * expectedTenants;
  const operationalCost = multiTenantLicenceFees * 12 * expectedTenants;
  const fullFeedInROI = ((feedInTariff * solarPanelProduction) / appliedProjectCost) * 100;
  const ROIinMeterGrid = (profit / totalInvestMent) * 100;

  return {
    newSelfConsumption,
    chargeForTotalsAndGenerationMeters,
    multiTenantTurnOverBaseFees,
    operationalCost,
    feedInForMultiTenants,
    yearlyGains,
    batteryStorage,
    totalInvestMent,
    systemSize,
    totalEnergyProduction,
    totalEnergyConsumption,
    advantageTenantYearly,
    projectingCost,
    baseTiff,
    equipmentCost,
    fullFeedInROI,
    ROIinMeterGrid,
  };
};

export default useCalculateMultiTenants;
