import styled from "styled-components/macro";

export const ImageSlider = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 75%);
  z-index: 100000;

  display: flex;
  align-items: center;
  justify-content: center;

  .close-button-div {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .content {
    position: relative;
    z-index: 99999;
    width: 100%;
    max-width: min(90vw, 1000px);
    .loader {
      display: flex;
      justify-content: center;

      circle {
        stroke: var(--primary-color);
      }
    }
    img {
      border-radius: 25px;
    }

    .slick-track {
      display: flex;
      align-items: center;
    }
  }
`;
