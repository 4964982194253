import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PopupSkeleton } from "src/shared/components";

import * as S from "./DataPolicy.styles";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import SolarHubLogo from "src/assets/logos/SolarhubLogo.svg";
import { UserSelectors } from "src/redux/selectors";

export const DataPolicy: React.FC = () => {
  const dispatch = useDispatch();
  const installerDetails = useSelector(UserSelectors?.getInstaller);
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    dispatch(PopupActions.closePopup(POPUP_KEYS.data_policy));
  }, [dispatch]);

  const handleDiscard = useCallback(() => {
    dispatch(PopupActions.closeDataPolicyPopup());
  }, []);

  const handleRequest = useCallback(() => {
    dispatch(PopupActions.closeDataPolicyPopup(true));
  }, []);

  return (
    <PopupSkeleton handleClose={handleClose} open={true}>
      <S.MainContentWrapper>
        <img
          src={installerDetails?.logoUrl?.[0] || SolarHubLogo}
          style={{ height: 90 }}
          alt="Logo"
        />
        <S.Section1>
          <div>{t("You would like to make a request to the installation company.")}</div>
          <div>
            {t(
              "We are obliged to ask for your consent to share information in accordance with the GDPR.",
            )}
          </div>
        </S.Section1>

        <S.Section2>
          <S.Section2Title>{t("The following data will be shared:")}</S.Section2Title>
          <S.Section2List>
            <li>{t("Address")}</li>
            <li>{t("Contact details (Name, Email & Phone number)")}</li>
            <li>{t("PV planning")}</li>
            <li>{t("SolarHub Configuration")}</li>
          </S.Section2List>
        </S.Section2>

        <S.ButtonContainer>
          <S.Btn1 onClick={handleRequest}>{t("Confirm")}</S.Btn1>
          <S.Btn2 onClick={handleDiscard}>{t("Decline (do not make a request)")}</S.Btn2>
        </S.ButtonContainer>
      </S.MainContentWrapper>
    </PopupSkeleton>
  );
};

export default DataPolicy;
