import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useIsComponentAdded } from "src/hooks";

import { getAverageMaxCapacity } from "src/helpers";
import { ModelInputs } from "src/prediction-model/types/model.types";

import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

export function useModelAdjustedPVCount(): number {
  const { solarPanelProduction } = useSelector(ProjectSelectors.getEnergy);

  const [pv, setPV] = useState<number>(0);

  useEffect(() => {
    setPV(solarPanelProduction / 999.6088673569609);
  }, [solarPanelProduction]);

  return pv;
}

export function useEVAdjustedDistance(): number {
  const cars = useSelector(ProjectSelectors.getCars);

  const [evDistance, setEvDistance] = useState(0);

  useEffect(() => {
    const evConsumption = cars.reduce((acc, car) => acc + car.configuration.evYearlyConsumption, 0);

    const distance = (evConsumption * 10000) / 1689.557645922011;
    setEvDistance(distance);
  }, [cars]);

  return evDistance;
}

export function useBatteryKWH(): number {
  const battery = useSelector(ProjectSelectors.getBattery);
  const filteredBatteries = useSelector(ProductSelectors.getFilteredBatteries);

  const [batteryKWH, setBatteryKWH] = useState(0);

  useEffect(() => {
    const hasNoBattery = battery.quantity === 0;
    if (hasNoBattery) {
      setBatteryKWH(0);
    } else {
      const btKwh = getAverageMaxCapacity(filteredBatteries) / 1000;
      setBatteryKWH(btKwh);
    }
  }, [battery, filteredBatteries]);

  return batteryKWH;
}

export function useModelInput(): ModelInputs {
  const {
    people: noOfPeople,
    consumption,
    // calculatedConsumption,
  } = useSelector(ProjectSelectors.getHousehold);

  const pv = useModelAdjustedPVCount();
  const battery = useBatteryKWH();
  const evDistance = useEVAdjustedDistance();

  const { isHeatpumpAdded, isWaterHeaterAdded } = useIsComponentAdded();
  const { consumptionHeatpump: heatpumpConsumption, electricityCostHeatpump: heatpumpRequirement } =
    useSelector(ProjectSelectors.getHeatpump).configuration;

  return {
    noOfPeople,
    householdConsumption: consumption, // || calculatedConsumption,
    pv,
    battery,
    evDistance,
    haveHeatingRod: isWaterHeaterAdded,
    haveHeatpump: isHeatpumpAdded,
    heatpumpConsumption: isHeatpumpAdded ? heatpumpConsumption : 0,
    heatpumpRequirement: isHeatpumpAdded ? heatpumpRequirement : 0,
  };
}
