import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React from "react";
import { useTranslation } from "react-i18next";

import * as S from "src/pages/ApplicationPage/components/Stepper/Stepper.styles";
import * as SC from "src/shared/components/StepperPresentational/StepperPresentational.styles";

interface IMultiTenantPopupStepper {
  popupStepperHeading: string[];
  setactiveStep: (step: number) => void;
  activeStep: number;
}

const MultiTenantPopupStepper: React.FC<IMultiTenantPopupStepper> = ({
  popupStepperHeading,
  setactiveStep,
  activeStep,
}: IMultiTenantPopupStepper) => {
  const { t } = useTranslation();

  return (
    <S.Container style={{ marginBottom: "30px" }}>
      {/** @ts-ignore */}
      <Stepper connector={<SC.Connector />} activeStep={activeStep} alternativeLabel>
        {popupStepperHeading.map((heading: any, index: number) => (
          <Step key={heading}>
            <StepLabel
              onClick={() => {
                setactiveStep(index);
              }}
            >
              {t(heading)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </S.Container>
  );
};

export default MultiTenantPopupStepper;
