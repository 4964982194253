import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector, useURLData } from "src/hooks";

import {
  isOnUserOverview,
  isOnResetPasswordPage,
  isQuotePresent,
  isOnWebinar,
  isOnDefaultProject,
  isOnSavedProject,
  isOnCustomProject,
} from "src/utils";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { AppSelectors, ProjectSelectors } from "src/redux/selectors";

export const useWelcomeAddressPopup = (): void => {
  const dispatch = useAppDispatch();
  const [query] = useSearchParams();
  const timer = useRef<any>(null);

  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const isQuoteFetching = useSelector(AppSelectors.getQuoteFetching);
  const {
    apiStates: { isUserApiLoading },
  } = useSelector(AppSelectors.getState);

  const [count, setCount] = useState(0);

  const { isOnDefault, isOnSaved, isOnView, isOnViewAndEdit } = useURLData();

  useEffect(() => {
    if (count > 0) return;
    if (isQuoteFetching) return;
    if (isOnUserOverview()) return;
    if (isOnResetPasswordPage(query)) return;
    if (isQuotePresent(quoteId)) return;
    if (isOnWebinar()) return;
    if (isOnDefault) return;
    if (isOnSaved) return;
    if (isOnView) return;
    if (isOnViewAndEdit) return;
    if (isUserApiLoading) return;

    timer.current = setTimeout(() => {
      const action = PopupActions.openPopup(POPUP_KEYS.welcome_address);
      dispatch(action);
    }, 2000);

    setCount((c: number) => c + 1);

    return () => {
      clearTimeout(timer.current);
    };
  }, [
    count,
    dispatch,
    isOnDefault,
    isOnSaved,
    isOnView,
    isOnViewAndEdit,
    isQuoteFetching,
    isUserApiLoading,
    query,
    quoteId,
  ]);
};
