import { actionCreators, ProjectState } from "src/redux/project";

export const updateThreeDModels = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.updateDroneData>,
): ProjectState => {
  const {
    images: { ThreeDModels: currThreeDModels },
  } = currentState;

  return {
    ...currentState,
    images: {
      ...currentState.images,
      ThreeDModels: {
        description: payload.description ?? currThreeDModels.description,
        imageURLs: payload.imageURLs ?? currThreeDModels.imageURLs,
        planYourSelf: payload.planYourSelf ?? currThreeDModels.planYourSelf,
        pvSolModifyModel: payload.pvSolModifyModel ?? currThreeDModels.pvSolModifyModel,
        threeDpvModelSunnyDesign:
          payload.threeDpvModelSunnyDesign ?? currThreeDModels.threeDpvModelSunnyDesign,
        threeDpvModelSolarEdgeDesign:
          payload.threeDpvModelSolarEdgeDesign ?? currThreeDModels.threeDpvModelSolarEdgeDesign,
        selectedFiles: payload.selectedFiles ?? currThreeDModels.selectedFiles,
      },
    },
  };
};
