import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { useRequestBakeModel } from "./apis";


import { ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { closePopup } from "src/redux/popups/action/action.creators";
import { ProjectSelectors } from "src/redux/selectors";

import { useAppSelector } from ".";


const REQUEST_IMAGE_TYPE = "byImageUrls";

const useRequestBakeModelHook = () => {
  const { error, fetchData, response } = useRequestBakeModel();
  const dispatch = useDispatch();
  const houseImageDetails = useAppSelector(ProjectSelectors.getDroneData);

  const requestBakeModel = useCallback(async () => {
    void fetchData({
      ...BackendApis.requestBakeModel,
      data: {
        type: REQUEST_IMAGE_TYPE,
        imageUrls: houseImageDetails?.imageURLs.map(
          (imageData: any) =>
            `s3://solarhub-medias-staging/${imageData?.type}/${imageData?._id}/${imageData?.fileName}`,
        ),
      },
    });
  }, [fetchData, houseImageDetails?.imageURLs]);

  useEffect(() => {
    const message = error?.response?.data;
    
    if (message) {
      toast.error(`${message}`);
    }
  }, [error]);

  useEffect(() => {
    if (!response) return;

    const { data } = response;
    const bakedModelId = data?.bakedModelId;

    if (!bakedModelId) return;

    dispatch(ProjectActions.updateBakeModelId({ id: bakedModelId, artifacts: "" }));
    dispatch(closePopup(POPUP_KEYS.request_pv_model));
  }, [response]);

  return { requestBakeModel, response, error };
};

export default useRequestBakeModelHook;
