import { cloneDeep } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { ProductFilterKeys, ProductFilters } from "src/hooks";

import { ProductDB } from "src/db-types";
import { filterProducts } from "src/helpers";
import { ValueOf } from "src/utils";

export type FilterPossibilityMap = Record<
  ProductFilterKeys,
  {
    canBeChosen: boolean;
    canNotBeChosen: boolean;
    productCountIfChosen: number;
    productCountIfNotChosen: number;
  }
>;
export type FilterPossibilityArray = Array<[ProductFilterKeys, ValueOf<FilterPossibilityMap>]>;

export const simulatePrefPossibility = (props: ValidPreferenceMapProps): FilterPossibilityMap => {
  const { preferences, products } = props;
  const keys: ProductFilterKeys[] = Object.keys(preferences) as ProductFilterKeys[];
  const updatedValidPref = cloneDeep(props?.defaultPreferenceMap || {}) as FilterPossibilityMap;

  for (const currPref of keys) {
    if (currPref === "applicableMaxCapacity") {
      continue;
    }
    const prefChosen = {
      ...preferences,
      [currPref]: true,
    };
    const prefNotChosen = {
      ...preferences,
      [currPref]: false,
    };
    const ifChosenFiltered = filterProducts({
      filters: prefChosen,
      products,
    });

    const ifNotChosenFitlered = filterProducts({
      filters: prefNotChosen,
      products,
    });

    updatedValidPref[currPref] = {
      canBeChosen: ifChosenFiltered.length > 0,
      canNotBeChosen: ifChosenFiltered.length === 0,
      productCountIfChosen: ifChosenFiltered.length,
      productCountIfNotChosen: ifNotChosenFitlered.length,
    };
  }
  return updatedValidPref;
};

interface ValidPreferenceMapProps {
  preferences: ProductFilters;
  products: ProductDB[];
  defaultPreferenceMap?: any;
}
export const useValidPreferenceMap = (props: ValidPreferenceMapProps): FilterPossibilityMap => {
  const { preferences, products } = props;

  const defaultPreferenceMap = useMemo(
    () =>
      (Object.keys(preferences) as ProductFilterKeys[]).reduce(
        (acc: Partial<FilterPossibilityMap>, key: ProductFilterKeys) => {
          const data: Partial<FilterPossibilityMap> = {
            ...acc,
            [key]: {
              canBeChosen: true,
              canNotBeChosen: false,
              productCountIfChosen: 0,
              productCountIfNotChosen: 0,
            },
          };
          return data;
        },
        {},
      ),
    [preferences],
  );

  const [validPreferences, setValidPreferences] = useState(
    defaultPreferenceMap as FilterPossibilityMap,
  );

  useEffect(() => {
    const updatedValidPref = simulatePrefPossibility({
      preferences,
      products,
      defaultPreferenceMap,
    });

    setValidPreferences(updatedValidPref);
  }, [defaultPreferenceMap, products]);

  return validPreferences;
};
