import { MainUserState } from "src/redux/user";

export const userIsNotAuthenticated = (user: MainUserState | null): boolean => {
  return !user;
};

export const userIsAuthenticated = (user: MainUserState | null): boolean => {
  return !userIsNotAuthenticated(user);
};

export const imageUrlToBase64 = async (url: string): Promise<any> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};