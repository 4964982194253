import { PROCESS_STATUS } from "src/data";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";
import store from "src/redux/store";

export const openCorrPopupBasedOnPartnerStatus = (): any => {
  const state = store.getState();
  const dispatch = store.dispatch;
  const quote = state.project.quote;
  const user = state.user;
  const partnerCityData = state.application.partnerCity;
  const isQuoteVerified = quote.status === PROCESS_STATUS.VERIFIED;
  const isConsultingAvalible =
    Number(partnerCityData.yearlyConsultings) - Number(partnerCityData.usedYearlyConsultings);
  const isCityPartnerCity = partnerCityData.partnerStatus;

  switch (true) {
    case !user.user:
      dispatch(openPopup(POPUP_KEYS.auth));
      dispatch(PopupActions.closePopup(POPUP_KEYS.PARTNER_CITY_CONSULTINNG));
      break;

    case !isQuoteVerified && !isCityPartnerCity: // city is not partner-city + quote is verified
      dispatch(openPopup(POPUP_KEYS.pay_wall));
      dispatch(PopupActions.closePopup(POPUP_KEYS.PARTNER_CITY_CONSULTINNG));
      break;
    case !!(isConsultingAvalible && !isQuoteVerified && isCityPartnerCity): // city is partner-city + quote is not verified + consulting avalible
      dispatch(openPopup(POPUP_KEYS.hubspot_meeting));
      dispatch(PopupActions.closePopup(POPUP_KEYS.PARTNER_CITY_CONSULTINNG));
      break;
    case !!(isConsultingAvalible && isQuoteVerified && isCityPartnerCity): // city is partner-city + quote verified + consulting avalible
      dispatch(openPopup(POPUP_KEYS.hubspot_meeting));
      dispatch(PopupActions.closePopup(POPUP_KEYS.PARTNER_CITY_CONSULTINNG));
      break;
    case !!(!isConsultingAvalible && isQuoteVerified && isCityPartnerCity): // city is partner-city + quote verified + consulting not avalible
      dispatch(openPopup(POPUP_KEYS.pay_wall));
      dispatch(PopupActions.closePopup(POPUP_KEYS.PARTNER_CITY_CONSULTINNG));
      break;
    case !!(!isConsultingAvalible && !isQuoteVerified && isCityPartnerCity): // city is partner-city + quote not verified + consulting not avalible
      dispatch(openPopup(POPUP_KEYS.pay_wall));
      dispatch(PopupActions.closePopup(POPUP_KEYS.PARTNER_CITY_CONSULTINNG));
      break;
    default:
      dispatch(PopupActions.closePopup(POPUP_KEYS.PARTNER_CITY_CONSULTINNG));
      break;
  }
};
