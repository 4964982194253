import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  registerables as registerablesJS,
  Tooltip,
  ChartOptions,
} from "chart.js";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { useTranslation } from "react-i18next";

import { createGradient } from "src/utils";

import { getLables, getMonthlyEnergyDetails } from "../MonthlyPersentage";

ChartJS.register(...registerablesJS);
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
);

export const options: ChartOptions<"bar"> = {
  plugins: {
    title: {
      display: false,
      text: "Dummy chart",
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
      },
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: window.innerWidth < 500 ? 1 / 1 : 2 / 1,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: any, index: any, values: any) {
          return `${value as number} kWh`;
        },
      },
      grid: {
        borderDash: [8, 4],
        display: true,
      },
    },
  },
};

interface MonthlyEnergyChartProps {
  detailed?: boolean;
}

export const MonthlyEnergyChart: React.FC<MonthlyEnergyChartProps> = (
  props: MonthlyEnergyChartProps,
) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    const monthlyEnergies = getMonthlyEnergyDetails();
    return {
      labels: getLables(),
      datasets: [
        {
          label: t("PV production"),
          data: monthlyEnergies.map((m) => m.pvProduction),
          barThickness: 15,
          stack: "Stack 0",
          start: "#FFC371",
          end: "#FBDA61",
        },
        {
          label: t("Grid-supply"),
          data: monthlyEnergies.map((m) => m.gridSupply),
          barThickness: 15,
          stack: "Stack 0",
          start: "#DF6569",
          end: "#DF6569",
        },
        ...(!props.detailed
          ? [
              {
                label: t("Total consumption"),
                data: monthlyEnergies.map((m) => m.consumption),
                barThickness: 15,
                stack: "Stack 1",
                start: "rgba(36, 125, 56, 1)",
                end: "rgba(36, 125, 56, 1)",
              },
            ]
          : []),
        ...(props.detailed
          ? [
              {
                label: t("Home energy"),
                data: monthlyEnergies.map((m) => m.household),
                barThickness: 15,
                stack: "Stack 1",
                start: "#F2994A",
                end: "#F2994A",
              },
            ]
          : []),
        ...(props.detailed
          ? [
              {
                label: t("Heat demand"),
                data: monthlyEnergies.map((m) => m.heatpump),
                barThickness: 15,
                stack: "Stack 1",
                start: "#CB0000",
                end: "#CB0000",
              },
            ]
          : []),
        ...(props.detailed
          ? [
              {
                label: t("Warm water"),
                data: monthlyEnergies.map((m) => m.heatingWater),
                barThickness: 15,
                stack: "Stack 1",
                start: "#9426B5",
                end: "#9426B5",
              },
            ]
          : []),
        ...(props.detailed
          ? [
              {
                label: t("E-mobility"),
                data: monthlyEnergies.map((m) => m.ev),
                barThickness: 15,
                stack: "Stack 1",
                start: "#287E97",
                end: "#287E97",
              },
            ]
          : []),
        {
          label: t("feed-in"),
          data: monthlyEnergies.map((m) => m.feedIn),
          barThickness: 15,
          stack: "Stack 1",
          start: "#3FB10A",
          end: "#3FB10A",
        },
      ],
    };
  }, [props.detailed, t]);

  const [chartData, setChartData] = useState<any>({
    datasets: [],
  });

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: createGradient(chart.ctx, chart.chartArea, dataset.start, dataset.end),
      })),
    };

    setChartData(chartData);
  }, [data]);

  const chartRef = useRef<ChartJSOrUndefined<"bar">>(null);

  return <Chart ref={chartRef} type="bar" options={options} data={chartData} />;
};
