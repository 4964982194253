import { useCallback, useState } from "react";

import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import {
  Heading,
  MessageForPanels,
  PanelCapacityLine,
  ProductionPerYearLine,
  SolarModuleInput,
  SystemCapacityLine,
} from "./components";
import { useSelector } from "react-redux";
import { UserSelectors } from "src/redux/selectors";

const ProductionParam: React.FC = () => {
  const [showSolarPanelMessage, setShowSolarPanelMessage] = useState(false);

  const closeSolarMessage = useCallback(() => setShowSolarPanelMessage(false), []);
  const openSolarMessage = useCallback(() => setShowSolarPanelMessage(true), []);

  const simplify = useSelector(UserSelectors.getSimplify);

  return (
    <ParameterStyles.Container>
      <Heading />
      <SolarModuleInput openSolarMessageWindow={openSolarMessage} />
      {showSolarPanelMessage && <MessageForPanels closeSolarMessageWindow={closeSolarMessage} />}
      <ParameterStyles.SectionDivider />
      {!simplify && <PanelCapacityLine />}
      <SystemCapacityLine />
      <ProductionPerYearLine />
    </ParameterStyles.Container>
  );
};

export default ProductionParam;
