import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { BackendApis } from "src/api";

import { convertUserFromDB } from "src/db-converters";
import { UserDB } from "src/db-types";
import { IRefCode } from "src/db-types/user";

import { useGetRefCode } from "./apis";

import { getProject } from "src/redux/project/selectors";
import { setUser } from "src/redux/user/action/action.creators";
import { getUser } from "src/redux/user/selectors";

import { useAppDispatch } from ".";

export const useFetchRefCode = (): any => {
  const dispatch = useAppDispatch();
  const [userData, setUserData] = useState<UserDB | null>(null);

  const { fetchData, error, response } = useGetRefCode();
  const user = useSelector(getUser);
  const project = useSelector(getProject);

  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);

  useEffect(() => {
    if (!response) return;

    setUserData(response.data.data);
  }, [response]);

  useEffect(() => {
    if (user?.id && project.quoteId) {
      const isRefCodeExist = user?.refCode.find(
        (code: IRefCode) => code.quoteId === project.quoteId,
      );
      if (isRefCodeExist) return;
      void fetchData({
        ...BackendApis.getRefCode(user.id, project.quoteId),
      });
    }
  }, [fetchData, project.quoteId, user, user?.refCode]);

  const getRefCode = useCallback(() => {
    if (user?.id) {
      void fetchData({
        ...BackendApis.getRefCode(user.id, project.quoteId),
      });
    }
  }, [fetchData, project.quoteId, user?.id]);

  useEffect(() => {
    if (userData) {
      const convertedUser = convertUserFromDB(userData);
      dispatch(setUser(convertedUser));
    }
  }, [userData, dispatch]);

  return { getRefCode };
};
