import { ApplicationState } from "../../types";

import { actionCreators } from "src/redux/application";

export const updatePartnerCityData = (
  currentState: ApplicationState,
  { payload }: ReturnType<typeof actionCreators.updatePartnerCityData>,
): ApplicationState => {
  if (!payload) {
    return currentState;
  }
  return {
    ...currentState,
    partnerCity: {
      ...payload,
    },
  };
};
