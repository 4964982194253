export const buildingStandards = [
  {
    key: "Passivhaus 0.015 kW/m²",
    label: "Passiv home 0.015 kW/m²",
    value: 0.015,
  },
  {
    key: "Neubau nach EnEV 0.015 kW/m²",
    label: "Neu building according to EnEV 0.015 kW/m²",
    value: 0.015,
  },
  {
    key: "Neubau mit Standardwärmedämmung 0.06 kW/m²",
    label: "New building with standard heat insulation 0.06 kW/m²",
    value: 0.06,
  },
  {
    key: "Sanierter Altbau mit oder Neubau ohne Wärmedämmung 0.08 kW/m²",
    label: "Sanified old building or new building without insulation 0.08 kW/m²",
    value: 0.08,
  },
  {
    key: "Altbau ohne Wärmedämmung 0.12 kW/m²",
    label: "Old building without insulation 0.12 kW/m²",
    value: 0.12,
  },
];
