import React from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "src/hooks";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";

const NoAddressBar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div className="adressNotSelected">
      {t("You haven't selected any address yet")}.{" "}
      <span
        onClick={() => {
          const action = PopupActions.openPopup(POPUP_KEYS.welcome_address);
          dispatch(action);
        }}
      >
        {t("Click here to select an address")}
      </span>{" "}
      {t("or play around with sample data")}
    </div>
  );
};

export default NoAddressBar;
