import { cloneDeep } from "lodash";

import { ReduxAction } from "src/redux/common.types";
import { SolarAPIState } from "./types";
import { actionNames, handlers } from "..";

const INITIAL_STATE: SolarAPIState = {
  solarAPIData: null,
};

const solarAPIReducer = (state = INITIAL_STATE, action: ReduxAction): SolarAPIState => {
  switch (action.type) {
    case actionNames.SET_SOLAR_API_DATA:
      return handlers.setSolarAPIData(state, action);
    case "RESET_REDUX":
      return cloneDeep(INITIAL_STATE);
    default:
      return state;
  }
};

export default solarAPIReducer;
