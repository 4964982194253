import React, { useState } from "react";
import svgTick from "./icons8-tick.svg";
import { t } from "i18next";

interface BarData {
  label: string;
  value: number;
  color: string;
  active?: boolean;
}

interface IBarCharts {
  fullFeedInROI: number;
  ROIinMeterGrid: number;
}

const BarChart: React.FC<IBarCharts> = ({ fullFeedInROI, ROIinMeterGrid }: IBarCharts) => {
  console.log(fullFeedInROI, ROIinMeterGrid);
  const [bars, setBars] = useState<BarData[]>([
    {
      label: t("Tenant electricity"),
      value: Number(fullFeedInROI),
      color: "#1f3d68",
    },
    { label: t("Full feed-in"), value: Number(ROIinMeterGrid), color: "#5a5a5a", active: true },
  ]);

  const handleBarClick = (index: number) => {
    setBars((prevBars) =>
      prevBars.map((bar, i) => ({
        ...bar,
        active: i === index,
      })),
    );
  };

  return (
    <div className={` flex flex-col items-center justify-center w-full h-full p-4`}>
      <div className="flex items-center justify-center">
        {bars.map((bar, index) => (
          <div
            key={index}
            onClick={() => handleBarClick(index)}
            className={`relative flex flex-col items-center ${
              bar.active ? "border border-[#2D4764]" : ""
            } rounded-lg p-4 ${index === 0 ? "mr-2" : "ml-2"}`}
          >
            {bar.active ? <img src={svgTick} className="absolute -top-3 -left-3"></img> : ""}

            <div className="text-2xl font-bold">{bar.value.toFixed(1)} %</div>
            <div>Rendite</div>
            <div
              className="mt-2 mb-2"
              style={{
                backgroundColor: bar.color,
                width: "50px",
                height: `${bar.value * 2}px`, // Height proportional to value
              }}
            ></div>
            <div>{bar.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarChart;
