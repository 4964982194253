import DrawerWrapper from "antd/lib/drawer";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

// import { Mixpanel } from "src/mix-panel/mixpanel";
// import * as trackingEvents from "src/mix-panel/trackingEvents";
import { sanitizeCurrentURL } from "src/utils";

import { useShareButtonStyles } from "./ShareDrawer.styles";

const ShareDrawer: React.FC<any> = ({ visibleShare, setvisibleShare, referral }: any) => {
  const classes = useShareButtonStyles();

  const msg = `${sanitizeCurrentURL()}`;

  const handleClose = (): void => {
    // Mixpanel.track(trackingEvents.USER_CLICKS_ON_PROJECT_SHARE_BUTTON, {});
    setvisibleShare(false);
  };

  return (
    <DrawerWrapper
      title="SHARE"
      placement="bottom"
      closable={false}
      onClose={handleClose}
      visible={visibleShare}
      key="bottom"
    >
      <div className={classes.flex_x}>
        <div className={classes.pa10}>
          <WhatsappShareButton url={msg}>
            <WhatsappIcon round size={30} />
            <div className="an-10 medium-text">WhatsApp</div>
          </WhatsappShareButton>
        </div>
        <div className={classes.pa10}>
          <FacebookShareButton url={msg}>
            <FacebookIcon round size={30} />
            <div className="an-10 medium-text">Facebook</div>
          </FacebookShareButton>
        </div>
        <div className={classes.pa10}>
          <TwitterShareButton url={msg}>
            <TwitterIcon round size={30} />
            <div className="an-10 medium-text">Twitter</div>
          </TwitterShareButton>
        </div>
        <div className={classes.pa10}>
          <LinkedinShareButton url={msg}>
            <LinkedinIcon round size={30} />
            <div className="an-10 medium-text">Linkedin</div>
          </LinkedinShareButton>
        </div>
        <div className={classes.pa10}>
          <TelegramShareButton url={msg}>
            <TelegramIcon round size={30} />
            <div className="an-10 medium-text">Telegram</div>
          </TelegramShareButton>
        </div>
        <div className={classes.pa10}>
          <EmailShareButton url={msg}>
            <EmailIcon round size={30} />
            <div className="an-10 medium-text">Gmail</div>
          </EmailShareButton>
        </div>
      </div>
    </DrawerWrapper>
  );
};

export default ShareDrawer;
