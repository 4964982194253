const backablePaths: string[] = [
  "home",
  "popular",
  "notification",
  "faq",
  "useroverview",
  "knowledgehub",
  "community/saved",
  "profile",
  "feedback",
  "payment",
];

export const canShowBackBtn = (path: string): boolean => {
  return backablePaths.some((p) => path.includes(p));
};
