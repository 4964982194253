import React from "react";
import { useTranslation } from "react-i18next";

import { PrimaryButton } from "src/shared/StyledComponents";

import * as S from "../../HeatpumpConfigurationBox.styles";

interface SelectHeatpumpButtonProps {
  onClick: () => void;
}
const SelectHeatpumpButton: React.FC<SelectHeatpumpButtonProps> = (
  props: SelectHeatpumpButtonProps,
) => {
  const { t } = useTranslation();

  return (
    <S.SubContainer3>
      <PrimaryButton
        style={{
          width: "200px",
          fontWeight: "bold",
        }}
        onClick={props.onClick}
      >
        {t("SELECT HEATPUMP")}
      </PrimaryButton>
    </S.SubContainer3>
  );
};

export default SelectHeatpumpButton;
