import styled from "styled-components/macro";

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px;
  align-items: center;
  gap: 3rem;
  padding-inline: 10px;
  max-width: 70vw;
  font-family: "NuNito";
`;

export const Section1 = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  color: #2d4764;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
`;

export const Section2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 24px;
  font-weight: 600;
  color: #2d4764;
  width: fit-content;
`;

export const Section2Title = styled.div`
  text-align: center;
`;

export const Section2List = styled.ul`
  list-style: disc;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  width: fit-content;
`;

export const Btn1 = styled.button`
  background: #ffd75d;
  color: #2d4764;
  padding: 10px 20px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 700;
`;

export const Btn2 = styled.button`
  background: #666666;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 700;
`;
