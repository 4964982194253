import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useYearlySystemProfit } from "./useYearlySystemProfit";

import { ProjectActions } from "src/redux/actionCreators";

export const useAmortizationTime = (): void => {
  const dispatch = useDispatch();

  const { profitByYear } = useYearlySystemProfit();

  useEffect(() => {
    for (let i = 1; i <= 60; i++) {
      if (profitByYear(i).profit > 0) {
        dispatch(
          ProjectActions.updateFinancialDetails({
            amortizationTime: i,
          }),
        );
        return;
      }
    }
  }, [dispatch, profitByYear]);
};
