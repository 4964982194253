export const consumptionProfilesB2C = {
  1: {
    name: "Home Office - all day high consumption",
    self_supply: 0.6,
    short_title: "home office",
  },
  2: {
    name: "consumption in the morning and evening",
    self_supply: 0.3,
    short_title: "Morning and evening",
  },
  3: {
    name: "only in the evening",
    self_supply: 0.25,
    short_title: "Only evening",
  },
  4: {
    name: "Multi family house",
    self_supply: 0.6,
    short_title: "Multi family house",
  },
  5: {
    name: "Family with children in schooling age",
    self_supply: 0.5,
    short_title: "Family w. children",
  },
  6: {
    name: "Family with small children",
    self_supply: 0.55,
    short_title: "Family w. small children",
  },
  7: {
    name: "Single or two person household",
    self_supply: 0.55,
    short_title: "Single / two Person household",
  },
  8: {
    name: "Home Office / Retired home",
    self_supply: 0.55,
    short_title: "Home Office",
  },
  9: {
    name: "High energy consumption at night",
    self_supply: 0.55,
    short_title: "High conusmption at night",
  },
};
