import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import * as S from "src/pages/ApplicationPage/components/Stepper/Stepper.styles";
import * as SC from "src/shared/components/StepperPresentational/StepperPresentational.styles";

import { setPopupCurrentStep } from "src/redux/application/action/action.creators";
import { AppSelectors } from "src/redux/selectors";


interface IPopupStepper {
  popupStepperHeading: string[];
}

const PopupStepper: React.FC<IPopupStepper> = ({ popupStepperHeading }: IPopupStepper) => {
  const { t } = useTranslation();
  const [activeStep, setactiveStep] = useState(1);
  const currentStep = useSelector(AppSelectors.getPopupCurrentStep);
  const dispatch = useDispatch();
  useEffect(() => {
    setactiveStep(currentStep);
  }, [currentStep]);

  return (
    <S.Container style={{ marginBottom: "30px" }}>
      {/** @ts-ignore */}
      <Stepper connector={<SC.Connector />} activeStep={activeStep} alternativeLabel>
        {popupStepperHeading.map((heading: any, index: number) => (
          <Step key={heading}>
            <StepLabel
              onClick={() => {
                if (index !== 1) return;
                setactiveStep(index);
                dispatch(setPopupCurrentStep(index));
              }}
            >
              {t(heading)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </S.Container>
  );
};

export default PopupStepper;
