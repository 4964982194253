export const stepperData = [
  {
    h1: "SYSTEM CONFIGURATION",
    h1German: "SYSTEM KONFIGURATION",
    h3German: "DIE VORKONFIGURATION IHRES SOLARDACHS - FLEXIBEL EINGESTELLT",
    h3: "The pre configuration of your solar roof",
    toolTipContent:
      "Here you can check your digital roof profile with all relevant information, please update your particular details to get an exact planning. The amount of panels is the maximum, with coming electromobility you want to produce as much power as possible.",
    toolTipHeaderGerman:
      "Hier kannst du dein digitales Dachprofil mit allen relevanten Informationen überprüfen. Bitte aktualisiere deine Angaben, um eine genauere Planung zu erhalten. Die vorgeplante Anzahl an Panels entspricht der Maximalbelegung auf deinem Dach. Für die kommende Elektromobilität bist du damit bestens gerüstet.",
    buttonContent: "Share project",
  },
  {
    h1: "CHOOSE YOUR PREFERRED COMPONENTS",
    h3: "CONFIGURE YOUR SYSTEM",
    h1German: "Wählen Sie ihre bevorzugten Komponenten",
    h3German: "KONFIGURIEREN SIE IHR SYSTEM",
    toolTipContent:
      "We have preselected our recommended products for you - feel free to change them - we´re happy to help you.",
    toolTipHeaderGerman:
      "Wir haben unsere empfohlenen Produkte für sie Vorausgewählt - wählen Sie aus unserer Auswahl an Produkten - wir helfen ihnen gerne dabei.",
  },
  {
    h1: "UPLOAD YOUR IMAGES",
    h1German: "Laden Sie ihre Bilder hoch",
    h3German:
      "Für die optimale Planung ihres Systems hilft es uns wenn wir detaillierte Informationen von Ihnen erhalten. Wenn Sie Fragen dazu haben fragen Sie uns gerne!",
    h3: "For the optimal planning of your system it helps if we receive detailed information from you. If you have any questions about this just reach out to us.",
    toolTipContent:
      "We're using satelite images for the planning of your PV project. For a more detailed planning upfront it helps us if you upload images of your building to make sure we know all important details upfront.",
    toolTipHeaderGerman:
      "Wir verwenden Satellitenbilder für die Planung Ihres PV-Projekts. Für eine genauere Planung im Vorfeld hilft es uns, wenn Sie Bilder Ihres Gebäudes hochladen, damit wir im Vorfeld alle wichtigen Details kennen.",
  },
  {
    h1: "CHOOSE YOUR LOCAL INSTALLER",
    h1German: "Wählen Sie ihre lokale Installationsfirma",
    h3German:
      "Wir haben die besten Umsetzungspartner für sie gefunden. Wählen Sie ihre bevorzugte Firmen aus um einen Umsetzungszeitraum anzufragen",
    h3: "We have found the best local installation partners for you. Choose your prefered companies to ask for a realization time frame.",
    toolTipContent:
      "We have pre selected the best installers in your area for you. Feel free to request possible realization times with s for you to realize your project these installers and in the next step you can proceed with the checkout to safe the fix price for your solar project.",
    toolTipHeaderGerman:
      "Wir haben die besten Installateure in Ihrer Nähe für Sie ausgewählt. Fragen Sie gerne mögliche Realisierungszeiten bei s an, damit Sie Ihr Projekt bei diesen Installateuren realisieren können und im nächsten Schritt können Sie mit der Kasse fortfahren, um sich den Festpreis für Ihr Solarprojekt zu sichern.",
  },
];
