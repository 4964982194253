import React from "react";

const Logo: React.FC<any> = ({ onClick }) => {
  return (
    <svg
      width={187}
      height={74}
      fill="none"
      onClick={onClick}
      viewBox="0 0 187 74"
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.221 59.7829L34.4663 37.9624L32.1387 12.8281"
        stroke="#DBA726"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M18.7559 56.7948L34.4682 37.9629"
        stroke="#DBA726"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M34.4662 50.6273C41.487 50.6273 47.1785 44.9573 47.1785 37.9631C47.1785 30.9688 41.487 25.2988 34.4662 25.2988C27.4454 25.2988 21.7539 30.9688 21.7539 37.9631C21.7539 44.9573 27.4454 50.6273 34.4662 50.6273Z"
        fill="url(#paint0_linear_4986_27767)"
      />
      <path
        d="M32.1387 18.8063C35.4524 18.8063 38.1388 16.1301 38.1388 12.8289C38.1388 9.52772 35.4524 6.85156 32.1387 6.85156C28.825 6.85156 26.1387 9.52772 26.1387 12.8289C26.1387 16.1301 28.825 18.8063 32.1387 18.8063Z"
        fill="#DBA726"
      />
      <path
        d="M18.7539 59.784C20.4108 59.784 21.754 58.4459 21.754 56.7953C21.754 55.1447 20.4108 53.8066 18.7539 53.8066C17.0971 53.8066 15.7539 55.1447 15.7539 56.7953C15.7539 58.4459 17.0971 59.784 18.7539 59.784Z"
        fill="#DBA726"
      />
      <path
        d="M48.2219 66.4634C51.9246 66.4634 54.9262 63.4731 54.9262 59.7844C54.9262 56.0957 51.9246 53.1055 48.2219 53.1055C44.5192 53.1055 41.5176 56.0957 41.5176 59.7844C41.5176 63.4731 44.5192 66.4634 48.2219 66.4634Z"
        fill="#DBA726"
      />
      <path
        d="M10.6852 50.9783C9.58919 50.9783 8.52747 50.8869 7.5 50.7042C6.47252 50.5215 5.51355 50.2474 4.62307 49.882C3.75543 49.4937 2.9677 49.0255 2.25989 48.4773C2.03156 48.3175 1.87173 48.1348 1.7804 47.9292C1.7119 47.7008 1.70048 47.4952 1.74615 47.3125C1.81465 47.107 1.91739 46.9357 2.05439 46.7986C2.21422 46.6616 2.39688 46.5931 2.60238 46.5931C2.80787 46.5702 3.02478 46.6388 3.25311 46.7986C4.30341 47.5752 5.4108 48.1462 6.57527 48.5116C7.76257 48.877 9.13253 49.0598 10.6852 49.0598C12.8543 49.0598 14.4754 48.6372 15.5485 47.7922C16.6445 46.9471 17.1925 45.8394 17.1925 44.469C17.1925 43.327 16.7929 42.4363 15.9938 41.7968C15.2175 41.1573 13.9274 40.6548 12.1236 40.2894L8.66446 39.5699C6.49536 39.136 4.88565 38.4051 3.83534 37.3773C2.78504 36.3496 2.25989 34.9792 2.25989 33.2662C2.25989 32.2156 2.46538 31.2678 2.87637 30.4227C3.28736 29.5548 3.86959 28.8125 4.62307 28.1959C5.37655 27.5792 6.26703 27.111 7.2945 26.7912C8.34481 26.4486 9.49786 26.2773 10.7537 26.2773C12.2378 26.2773 13.5963 26.4829 14.8293 26.894C16.0851 27.3051 17.2267 27.956 18.2542 28.8468C18.4597 29.0067 18.5967 29.2008 18.6652 29.4292C18.7337 29.6348 18.7337 29.8403 18.6652 30.0459C18.5967 30.2286 18.494 30.377 18.357 30.4912C18.22 30.6054 18.0487 30.6739 17.8432 30.6968C17.6377 30.6968 17.4208 30.6054 17.1925 30.4227C16.2335 29.6462 15.2289 29.0866 14.1786 28.744C13.1511 28.3786 12.0095 28.1959 10.7537 28.1959C9.47503 28.1959 8.35622 28.4014 7.39725 28.8125C6.46111 29.2236 5.73046 29.806 5.20531 30.5598C4.68015 31.3135 4.41758 32.2042 4.41758 33.232C4.41758 34.4196 4.7829 35.3675 5.51355 36.0755C6.2442 36.7835 7.42008 37.2974 9.0412 37.6172L12.5346 38.3366C14.8407 38.8162 16.5532 39.5357 17.672 40.4949C18.7908 41.4314 19.3502 42.7218 19.3502 44.3662C19.3502 45.3483 19.1447 46.2505 18.7337 47.0727C18.3455 47.8721 17.7747 48.5687 17.0212 49.1625C16.2678 49.7564 15.3545 50.2131 14.2813 50.5329C13.231 50.8298 12.0323 50.9783 10.6852 50.9783Z"
        fill="#2D4764"
      />
      <path
        d="M50.9032 50.7042C50.5836 50.7042 50.321 50.6128 50.1155 50.4301C49.9328 50.2246 49.8415 49.9505 49.8415 49.6079V27.4764C49.8415 27.111 49.9328 26.8369 50.1155 26.6542C50.2981 26.4715 50.5493 26.3801 50.869 26.3801C51.2115 26.3801 51.474 26.4715 51.6567 26.6542C51.8394 26.8369 51.9307 27.111 51.9307 27.4764V48.8199H63.7124C64.0549 48.8199 64.306 48.8999 64.4659 49.0598C64.6485 49.2196 64.7398 49.448 64.7398 49.7449C64.7398 50.0647 64.6485 50.3045 64.4659 50.4644C64.306 50.6243 64.0549 50.7042 63.7124 50.7042H50.9032Z"
        fill="#2D4764"
      />
      <path
        d="M66.5112 50.9098C66.2143 50.9098 65.9746 50.8412 65.7919 50.7042C65.6321 50.59 65.5408 50.4187 65.5179 50.1903C65.5179 49.9619 65.575 49.7107 65.6892 49.4366L75.3132 27.3051C75.473 26.9625 75.6671 26.7227 75.8954 26.5857C76.1237 26.4258 76.3521 26.3459 76.5804 26.3459C76.8544 26.3459 77.0941 26.4258 77.2996 26.5857C77.528 26.7227 77.7106 26.9625 77.8476 27.3051L87.4716 49.4366C87.6086 49.7107 87.6657 49.9619 87.6429 50.1903C87.6429 50.4187 87.5629 50.59 87.4031 50.7042C87.2661 50.8412 87.0492 50.9098 86.7524 50.9098C86.4327 50.9098 86.1701 50.8298 85.9646 50.6699C85.782 50.4872 85.6222 50.2474 85.4852 49.9505L82.848 43.7496L83.9782 44.2635H69.1483L70.3128 43.7496L67.6756 49.9505C67.5386 50.2931 67.3674 50.5443 67.1619 50.7042C66.9792 50.8412 66.7623 50.9098 66.5112 50.9098ZM76.5461 29.1551L70.7238 42.8588L69.9361 42.4477H83.1905L82.4712 42.8588L76.6146 29.1551H76.5461Z"
        fill="#2D4764"
      />
      <path
        d="M93.1117 50.9098C92.7921 50.9098 92.5409 50.8184 92.3583 50.6357C92.1756 50.4301 92.0843 50.156 92.0843 49.8135V27.6477C92.0843 27.2823 92.1756 27.0082 92.3583 26.8255C92.5638 26.6428 92.8263 26.5514 93.146 26.5514H101.195C103.752 26.5514 105.715 27.1452 107.085 28.3329C108.455 29.5206 109.14 31.2107 109.14 33.4033C109.14 34.8193 108.832 36.0412 108.216 37.069C107.599 38.0739 106.686 38.8391 105.476 39.3644C104.288 39.8897 102.861 40.1523 101.195 40.1523L101.571 39.707H102.051C102.918 39.707 103.672 39.9354 104.311 40.3922C104.973 40.8489 105.567 41.5912 106.092 42.619L109.723 49.3681C109.882 49.6193 109.951 49.8706 109.928 50.1218C109.928 50.3502 109.848 50.5443 109.688 50.7042C109.528 50.8412 109.323 50.9098 109.072 50.9098C108.775 50.9098 108.524 50.8412 108.318 50.7042C108.136 50.5672 107.976 50.373 107.839 50.1218L104.003 42.9616C103.409 41.8653 102.747 41.1459 102.016 40.8033C101.286 40.4378 100.304 40.2551 99.0711 40.2551H94.1735V49.8135C94.1735 50.156 94.0821 50.4301 93.8995 50.6357C93.7396 50.8184 93.4771 50.9098 93.1117 50.9098ZM94.1735 38.4736H100.921C102.953 38.4736 104.471 38.0511 105.476 37.206C106.503 36.3381 107.017 35.0706 107.017 33.4033C107.017 31.7588 106.503 30.5141 105.476 29.669C104.471 28.8011 102.953 28.3672 100.921 28.3672H94.1735V38.4736Z"
        fill="#2D4764"
      />
      <path
        d="M116.166 50.944C115.595 50.944 115.15 50.7841 114.83 50.4644C114.533 50.1446 114.385 49.6993 114.385 49.1283V28.1273C114.385 27.5335 114.533 27.0881 114.83 26.7912C115.15 26.4715 115.595 26.3116 116.166 26.3116C116.737 26.3116 117.171 26.4715 117.467 26.7912C117.787 27.0881 117.947 27.5335 117.947 28.1273V36.9662H131.475V28.1273C131.475 27.5335 131.624 27.0881 131.92 26.7912C132.24 26.4715 132.685 26.3116 133.256 26.3116C133.827 26.3116 134.261 26.4715 134.558 26.7912C134.854 27.0881 135.003 27.5335 135.003 28.1273V49.1283C135.003 49.6993 134.854 50.1446 134.558 50.4644C134.261 50.7841 133.827 50.944 133.256 50.944C132.685 50.944 132.24 50.7841 131.92 50.4644C131.624 50.1446 131.475 49.6993 131.475 49.1283V39.9468H117.947V49.1283C117.947 49.6993 117.798 50.1446 117.502 50.4644C117.205 50.7841 116.76 50.944 116.166 50.944Z"
        fill="#2D4764"
      />
      <path
        d="M150.401 51.0125C148.78 51.0125 147.353 50.807 146.12 50.3959C144.887 49.9619 143.848 49.3224 143.004 48.4773C142.182 47.6323 141.554 46.5817 141.12 45.3255C140.686 44.0693 140.469 42.619 140.469 40.9746V28.0931C140.469 27.4993 140.618 27.0539 140.914 26.757C141.234 26.4601 141.679 26.3116 142.25 26.3116C142.821 26.3116 143.255 26.4601 143.552 26.757C143.871 27.0539 144.031 27.4993 144.031 28.0931V40.906C144.031 43.2585 144.568 45.0172 145.641 46.182C146.737 47.3468 148.324 47.9292 150.401 47.9292C152.502 47.9292 154.089 47.3468 155.162 46.182C156.235 45.0172 156.772 43.2585 156.772 40.906V28.0931C156.772 27.4993 156.92 27.0539 157.217 26.757C157.537 26.4601 157.97 26.3116 158.518 26.3116C159.089 26.3116 159.523 26.4601 159.82 26.757C160.117 27.0539 160.265 27.4993 160.265 28.0931V40.9746C160.265 43.1672 159.888 45.0172 159.135 46.5246C158.404 48.0091 157.308 49.1283 155.847 49.882C154.386 50.6357 152.57 51.0125 150.401 51.0125Z"
        fill="#2D4764"
      />
      <path
        d="M167.677 50.7042C167.06 50.7042 166.581 50.5443 166.238 50.2246C165.918 49.882 165.759 49.4023 165.759 48.7857V28.4699C165.759 27.8533 165.918 27.3851 166.238 27.0653C166.581 26.7227 167.06 26.5514 167.677 26.5514H175.828C177.495 26.5514 178.91 26.8027 180.075 27.3051C181.262 27.8076 182.164 28.527 182.78 29.4635C183.42 30.3999 183.739 31.5304 183.739 32.8551C183.739 34.3169 183.306 35.5502 182.438 36.5551C181.57 37.5601 180.394 38.2338 178.91 38.5764V38.0625C180.6 38.2909 181.924 38.9304 182.883 39.981C183.842 41.0088 184.322 42.3564 184.322 44.0236C184.322 46.1706 183.614 47.8264 182.198 48.9912C180.783 50.1332 178.796 50.7042 176.239 50.7042H167.677ZM169.183 47.9635H175.828C177.54 47.9635 178.807 47.6323 179.629 46.9699C180.451 46.2848 180.862 45.257 180.862 43.8866C180.862 42.5162 180.451 41.4999 179.629 40.8375C178.807 40.1752 177.54 39.844 175.828 39.844H169.183V47.9635ZM169.183 37.1033H175.28C176.924 37.1033 178.168 36.7721 179.013 36.1098C179.858 35.4474 180.28 34.4767 180.28 33.1977C180.28 31.9187 179.858 30.948 179.013 30.2857C178.168 29.6233 176.924 29.2922 175.28 29.2922H169.183V37.1033Z"
        fill="#2D4764"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4986_27767"
          x1="37.51"
          y1="40.9081"
          x2="25.0823"
          y2="28.7954"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBB03B" />
          <stop offset="0.75" stopColor="#F7931E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Logo;
