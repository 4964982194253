import React, { useContext, useEffect, useMemo, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { AbsoluteNavigate } from "src/contexts/AbsoluteNavigate";

export const useNaivgateAbsolute = (): NavigateFunction => {
  const navigate = useNavigate();

  const memoizedNavigate = useMemo(() => navigate, []);

  return memoizedNavigate;
};
