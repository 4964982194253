  import { useEffect } from "react";
import { useSelector } from "react-redux";

import { ConsumptionModel, OverallConsumptionModel } from "src/prediction-model/types/model.types";
import { RecursivePartial } from "src/utils";

import { ProjectActions } from "src/redux/actionCreators";
import { CarComponent, ProjectFinancialDetails } from "src/redux/project";
import { ProjectSelectors } from "src/redux/selectors";

import { useAppDispatch, useAppSelector } from ".";

// TODO : update heatpump configuration oil gas cost logic
interface IFinancialCalculations {
  model: OverallConsumptionModel;
  feedInTariff: number;
  cars: CarComponent[];
  pricePerKwh: number;
  oilGasCost: number;
}
export const getFinancialCalculations = (
  props: IFinancialCalculations,
): RecursivePartial<ProjectFinancialDetails> => {
  const { cars, feedInTariff, model, oilGasCost, pricePerKwh } = props;
  const feedInSavings = model.feedIn * feedInTariff;
  const electricityBillSavings = model.household.fromPVAndBattery * pricePerKwh;
  const evSavings = cars.reduce((acc, car) => {
    const { gasPricePer100Km, evKmsTravelled } = car.configuration;
    return acc + (gasPricePer100Km * evKmsTravelled) / 100;
  }, 0);
  const heatpumpSavings = model.heatpump.consumption * oilGasCost;
  const totalSavings = electricityBillSavings + evSavings + heatpumpSavings + feedInSavings;

  const evGridCost = model.ev.fromGrid * pricePerKwh;
  const heatpumpGridCost = model.heatpump.fromGrid * pricePerKwh;
  const householdGridCost = model.household.fromGrid * pricePerKwh;
  const heatingRodGridCost = model.heatingRod.fromGrid * pricePerKwh;
  const totalGridCost = evGridCost + heatpumpGridCost + householdGridCost + heatpumpGridCost;

  return {
    costs: {
      gridCosts: {
        evGridCost,
        heatpumpGridCost,
        householdGridCost,
        heatingRodGridCost,
        totalGridCost,
      },
      savings: {
        electricityCostSavings: electricityBillSavings,
        feedInSavings,
        gasSavings: heatpumpSavings,
        mobilitySavings: evSavings,
        totalSavings,
      },
    },
  };
};

export const useFinancialCalculations = (): void => {
  const dispatch = useAppDispatch();

  const {
    costs: { appliedProjectCost },
    feedInTariff,
    pricePerKwh,
  } = useAppSelector(ProjectSelectors.getFinancialDetails);

  const { feedIn, household, ev, heatpump, heatingRod } = useSelector(ProjectSelectors.getModel);
  const cars = useSelector(ProjectSelectors.getCars);
  const {
    configuration: { oilGasCost, consumptionHeatpump, futureOilGasCostIncrease },
  } = useSelector(ProjectSelectors.getHeatpump);

  useEffect(() => {
    const feedInSavings = feedIn * feedInTariff;
    const electricityBillSavings = household.fromPVAndBattery * pricePerKwh;
    const evSavings = cars.reduce((acc, car) => {
      const { gasPricePer100Km, evKmsTravelled } = car.configuration;
      return acc + (gasPricePer100Km * evKmsTravelled) / 100;
    }, 0);
    const heatpumpSavings = consumptionHeatpump * oilGasCost;
    const totalSavings = electricityBillSavings + evSavings + heatpumpSavings + feedInSavings;

    const evGridCost = ev.fromGrid * pricePerKwh;
    const heatpumpGridCost = heatpump.fromGrid * pricePerKwh;
    const householdGridCost = household.fromGrid * pricePerKwh;
    const heatingRodGridCost = heatingRod.fromGrid * pricePerKwh;
    const totalGridCost = evGridCost + heatpumpGridCost + householdGridCost + heatpumpGridCost;

    dispatch(
      ProjectActions.updateFinancialDetails({
        costs: {
          gridCosts: {
            evGridCost,
            heatpumpGridCost,
            householdGridCost,
            heatingRodGridCost,
            totalGridCost,
          },
          savings: {
            electricityCostSavings: electricityBillSavings,
            feedInSavings,
            gasSavings: heatpumpSavings,
            futureOilGasCostIncrease,
            mobilitySavings: evSavings,
            totalSavings,
          },
        },
      }),
    );
  }, [
    appliedProjectCost,
    cars,
    consumptionHeatpump,
    dispatch,
    ev.fromGrid,
    feedIn,
    feedInTariff,
    heatingRod.fromGrid,
    heatpump.consumption,
    heatpump.fromGrid,
    household.fromGrid,
    household.fromPVAndBattery,
    oilGasCost,
    pricePerKwh,
    futureOilGasCostIncrease
  ]);
};
