import { clone } from "lodash";

import { ApplicationState } from "../../types";

import { actionCreators } from "src/redux/application";

export const setPopupCurrentStep = (
  currentState: ApplicationState,
  { payload }: ReturnType<typeof actionCreators.setPopupCurrentStep>,
): ApplicationState => {
  const newState = clone(currentState);

  newState.popupCurrentStep = payload;
  return newState;
};
