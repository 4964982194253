import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "src/hooks";

import { TalkToExpertIcon } from "src/assets/svgs";

import HoverableButton from "./HoverableButton";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { useSelector } from "react-redux";
import { getInstaller } from "src/redux/user/selectors";

const URL_OPTIONS = {
  goToHubspot: "goToHubspot",
  goToEmail: "goToEmail",
  goToCalendy: "goToCalendy",
  goToRedirect: "goToRedirect",
};

const TalkToOurExpertBtn: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const installerPartner = useSelector(getInstaller);

  const openHubspotPopup = useCallback(() => {
    if (installerPartner?.whichOptionSelected === URL_OPTIONS.goToHubspot) {
      dispatch(PopupActions.openPopup(POPUP_KEYS.hubspot_meeting));
    } else if (installerPartner?.whichOptionSelected === URL_OPTIONS.goToRedirect) {
      window.open(installerPartner.appointmentURL, "_blank", "noreferrer");
    } else if (installerPartner?.whichOptionSelected === URL_OPTIONS.goToEmail) {
      //@ts-ignore
      window.location.href = `mailto:${installerPartner?.appointmentURL}`;
    } else {
      dispatch(PopupActions.openPopup(POPUP_KEYS.hubspot_meeting));
    }
  }, [dispatch, installerPartner]);

  return (
    <HoverableButton
      ImgIcon={TalkToExpertIcon}
      text={t("Talk to our experts")}
      onClick={openHubspotPopup}
    />
  );
};

export default TalkToOurExpertBtn;
