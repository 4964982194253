import { CircularProgress, TextField } from "@mui/material";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { useNaivgateAbsolute } from "src/hooks";

import { convertUserFromDB } from "src/db-converters";
import { useUserDetailsApi } from "src/hooks/apis";
import { Mixpanel } from "src/mix-panel/mixpanel";
import * as trackingEvents from "src/mix-panel/trackingEvents";
import { AddressSelector } from "src/shared/components";
import { getErrorMessage, transformForURL } from "src/utils";

import cssClasses from "./login.module.css";

import { AppActions, UserActions } from "src/redux/actionCreators";
import { POPUP_KEYS, actionCreators as PopupActions } from "src/redux/popups";
import { MapboxAddress } from "src/redux/project";
import { UserSelectors } from "src/redux/selectors";

interface DetailsForm {
  phoneNumber: string;
  address: string;
}

const defaultDetailsForm: DetailsForm = {
  address: "",
  phoneNumber: "",
};

const UpdateDetailsForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNaivgateAbsolute();

  const { fetchData, error, loading, response } = useUserDetailsApi();

  const [showAddressField, setShowAddressField] = useState(true);
  const [detailsForm, setDetailsForm] = useState<DetailsForm>(cloneDeep(defaultDetailsForm));

  const validateForm = (): { isValid: boolean; messages: string[] } => {
    const status = {
      isValid: true,
      messages: [] as string[],
    };

    if (detailsForm.address === "") {
      status.isValid = false;
      status.messages.push("Please provide your address to continue the process");
    }

    if (detailsForm.phoneNumber === "") {
      status.isValid = false;
      status.messages.push("Please provide your phone number to continue the process");
    }

    return status;
  };

  const updateBtnClickHandler = (): void => {
    const { isValid, messages } = validateForm();
    if (!isValid) {
      toast.warn(messages.map((m) => t(m)).join("\n"), { style: { whiteSpace: "pre-line" } });
      return;
    }

    Mixpanel.track(trackingEvents.USER_REGISTER, {});
    void fetchData({
      ...BackendApis.userDetails,
      data: {
        address: detailsForm.address,
        mobile: detailsForm.phoneNumber,
      },
    });
  };

  const completeAddr = useSelector(UserSelectors.getUser)?.address;

  const updateForm = (target: keyof DetailsForm, value: string): void => {
    setDetailsForm((prev: DetailsForm) => {
      const newForm: DetailsForm = { ...prev };
      newForm[target] = value;
      return newForm;
    });
  };

  useEffect(() => {
    const addrAlreadySelected = completeAddr !== "";
    if (addrAlreadySelected) {
      updateForm("address", completeAddr as string);
      setShowAddressField(false);
    }
  }, [completeAddr]);

  useEffect(() => {
    dispatch(
      AppActions.updateAppState({
        apiStates: {
          isUpdateUserApiLoading: loading,
        },
      }),
    );
  }, [dispatch, loading]);

  useEffect(() => {
    if (!error) return;

    const message = getErrorMessage(error);
    const id = toast.error(t(message));

    return () => {
      toast.dismiss(id);
    };
  }, [error, t]);

  useEffect(() => {
    if (!response) return;

    toast.success(t("Details updated successfully"));

    const {
      data: { data: updatedUser },
    } = response;
    const userState = convertUserFromDB(updatedUser);
    dispatch(UserActions.setUser(userState));

    const addr = transformForURL(updatedUser.address);
    navigate(`application/default-${addr}`);
    dispatch(
      AppActions.updateAppState({
        saveProjectRemaining: true,
      }),
    );

    dispatch(PopupActions.closePopup(POPUP_KEYS.auth));
  }, [dispatch, navigate, response, t]);

  const addressUpdateHandler = useCallback((addr: MapboxAddress): void => {
    updateForm("address", addr.complete);
  }, []);

  return (
    <>
      <div className={cssClasses.loginContainer}>
        <TextField
          fullWidth
          id="phone-number-field"
          label={t("Enter phone number")}
          variant="outlined"
          onChange={(e) => {
            updateForm("phoneNumber", e.target.value);
          }}
        />

        {showAddressField && (
          <AddressSelector
            showPartnerCityPopup={false}
            onAddressSelect={addressUpdateHandler}
          ></AddressSelector>
        )}
      </div>

      <button className={cssClasses.loginButton} onClick={updateBtnClickHandler} disabled={loading}>
        {t("Save details")}
        {loading && <CircularProgress size={20} className="circular-progress-custom" />}
      </button>
    </>
  );
};

export default UpdateDetailsForm;
