import { Slider } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { MasterInput } from "src/shared/components";
import { ParameterStyles } from "src/shared/StyledComponents";

import { CarConfiguration } from "src/redux/project";

interface ConsumptionPer100KMLineProps {
  onValueChange: (data: Partial<CarConfiguration>) => void;
  consPer100KM: number;
}
const ConsumptionPer100KMLine: React.FC<ConsumptionPer100KMLineProps> = (
  props: ConsumptionPer100KMLineProps,
) => {
  const { onValueChange, consPer100KM } = props;

  const { t } = useTranslation();

  const onChangeHandler = useCallback(
    (value: number) => {
      onValueChange({
        consumptionPer100Km: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line hasSlider isPadding>
      <ParameterStyles.Label hasSlider>{t("CONSUMPTION PER 100KM")}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider onChange={onChangeHandler} value={consPer100KM} max={40} />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <MasterInput
          value={consPer100KM}
          appearance={{
            styles: { color: "#666666" },
          }}
          onChange={onChangeHandler}
          label="kWh"
          maxNumber={40}
        />
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default ConsumptionPer100KMLine;
