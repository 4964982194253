import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useCallback, useMemo } from "react";
import { Settings } from "react-slick";

import { useWindowDimensions } from "src/hooks";

export const useSliderSettings = (): Settings => {
  const { width } = useWindowDimensions();

  const SampleNextArrow = useCallback(
    (props: any) => {
      const { className, style, onClick } = props;
      return (
        <ArrowForwardIos
          className="arrow_icon"
          style={{
            ...style,
            display: onClick === null ? "none" : "block",
            position: "absolute",
            right: "-5px",
            top: width > 500 ? "45%" : "40%",
            cursor: "pointer",
            zIndex: 100,
          }}
          onClick={onClick}
        />
      );
    },
    [width],
  );

  const SamplePrevArrow = useCallback(
    (props: any) => {
      const { className, style, onClick } = props;
      return (
        <ArrowBackIos
          className="arrow_icon"
          style={{
            ...style,
            display: onClick === null ? "none" : "block",
            position: "absolute",
            left: "0px",
            top: width > 500 ? "41%" : "40%",
            cursor: "pointer",
            zIndex: 100,
          }}
          onClick={onClick}
        />
      );
    },
    [width],
  );

  const settings: Settings = useMemo(
    () => ({
      dots: true,
      accessibility: true,
      infinite: false,
      speed: 600,
      slidesToScroll: 1,
      slidesToShow: 3,
      // afterChange: function (currentSlide) {
      // },
      beforeChange: function (prev: any, next: any) {},
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
          },
        },
      ],
    }),
    [SampleNextArrow, SamplePrevArrow],
  );

  return settings;
};
