import styled from "styled-components/macro";

export const MainText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 22px;
  color: #304864;
  display: flex;
  gap: 5px;
`;
