import styled from "styled-components/macro";
export const PaymentCardWrapper = styled("div")`
  .sr-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: var(--form-width);
    align-self: center;
    box-shadow: rgba(50, 50, 93, 0.1) 0px 0px 0px 0.5px, rgba(50, 50, 93, 0.1) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.07) 0px 1px 1.5px 0px;
    padding: 30px;
    background: var(--body-color);
    border-radius: var(--radius);
    @media (min-width: 1000px) {
      min-width: 450px;
    }
  }
  .loginBtn {
    color: white;
    margin-top: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    box-shadow: rgb(0 0 0 / 7%) 0px 4px 5.5px 0px;
    width: 100%;
    background: #32325d;
    border-radius: 6px;
    margin-bottom: 10px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    padding: 12px 16px;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
  }
  .lable_class {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    max-width: 100%;
    color: rgb(107, 124, 147);
  }
  .ant-select {
    width: 100%;
    margin-bottom: 10px;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    height: 55px;
  }
  .ant-select-selector {
    border-radius: 0px !important;
    border: none !important;
    background-color: white;
    box-shadow: rgb(230, 235, 241) 0px 1px 3px 0px;
  }
  .myInputClass {
    width: 100%;
    margin-bottom: 10px;
    outline: none;
    height: 55px;
    color: rgb(50, 50, 93);
    background-color: white;
    box-shadow: rgb(230, 235, 241) 0px 1px 3px 0px;
    padding: 10px 12px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    border-radius: 4px;
    transition: box-shadow 150ms ease 0s;
    :-internal-autofill-selected {
      appearance: menulist-button;
      background-image: none !important;
      background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
      color: -internal-light-dark(black, white) !important;
    }
  }
`;
