import styled from "styled-components/macro";

export const MainContainer = styled.div`
  display: flex;
  padding: 5px;
  gap: 15px;
  width: 100%;
  margin: auto;
  @media only screen and (max-width: 900px) {
    overflow-x: auto;
  }
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    margin-bottom: 2rem;
  }
`;
