import React, { useCallback, useRef, useState } from "react";

import { useAppDispatch } from "src/hooks";

import { DownArrow, ExpandIcon } from "src/assets/svgs";

// import ChartTabs from "src/components/popups/ChartsTabs/ChartTabs";
import { CardTitle, ChartCardStyled } from "./ChartCard.styles";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";

interface ChartCardProps {
  title: string;
  children: any;
  isOpen?: any;
  hideTitle?: boolean;
  disableExpand?: boolean;
}

const ChartCard: React.FC<ChartCardProps> = (props: ChartCardProps) => {
  const { title, children, isOpen, hideTitle, disableExpand } = props;

  const [isExpanded, setIsExpanded] = useState(isOpen || disableExpand);
  const dispatch = useAppDispatch();

  const ChartCardRef = useRef<HTMLDivElement>(null);

  const downArrowClickHandler = (): void => {
    if (props.disableExpand) return;

    setIsExpanded((p: boolean) => !p);

    setTimeout(() => {
      if (!ChartCardRef.current) return;

      const scrollAmount =
        ChartCardRef.current?.getBoundingClientRect().bottom - window.innerHeight;
      if (scrollAmount < 0) return;

      window.scrollBy({ top: scrollAmount + 50 });
      // ChartCardRef.current?.scrollIntoView({
      //   behavior: "smooth",
      //   block: "end",
      // });
    }, 200);
  };

  const openChartsDialog: React.MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      const action = PopupActions.openPopup(POPUP_KEYS.consumption_charts);
      dispatch(action);
    },
    [dispatch],
  );

  return (
    <ChartCardStyled
      isExpanded={isExpanded}
      ref={ChartCardRef}
      style={{ padding: hideTitle ? 0 : "0.5rem", background: hideTitle ? "none" : "" }}
    >
      {!hideTitle && (
        <CardTitle isExpanded={isExpanded} onClick={downArrowClickHandler}>
          {isExpanded && !disableExpand && <ExpandIcon onClick={openChartsDialog} />}
          <span>{title}</span>
          {!disableExpand && (
            <DownArrow style={{ transform: isExpanded ? "rotate(180deg)" : "initial" }} />
          )}
        </CardTitle>
      )}
      {isExpanded && children}
    </ChartCardStyled>
  );
};

export default ChartCard;
