import { useCallback, useEffect } from "react";

import { ThirdPartyApis } from "src/api";

import { isPreferredLangSetAndValid, getLanguageFullName } from "src/utils";

import { useIPApi } from "./apis";

import { UserActions } from "src/redux/actionCreators";
import { LanguagesShortCodes } from "src/redux/user";

import { useAppDispatch } from ".";

const getLangCodeFromCountryCode = (countryCode: string): LanguagesShortCodes => {
  switch (countryCode) {
    case "DE":
      return "de";
    case "ES":
      return "es";
    default:
      return "en";
  }
};

export const useInitIPRequest = (): void => {
  const dispatch = useAppDispatch();

  const { fetchData: fetchIPApi, response: IPApiResponse } = useIPApi();

  const initializeAppLanguage = useCallback(() => {
    // if (isPreferredLangSetAndValid()) return;

    void fetchIPApi({
      url: ThirdPartyApis.ipInfo,
    });
  }, [fetchIPApi]);

  useEffect(() => {
    if (!IPApiResponse) return;

    const { country } = IPApiResponse.data;

    const lang = getLangCodeFromCountryCode(country);
    localStorage.setItem("preferredLanguage", getLanguageFullName(lang));
    dispatch(UserActions.setLanguage(lang));
  }, [IPApiResponse, dispatch]);

  useEffect(() => {
    initializeAppLanguage();
  }, [initializeAppLanguage]);
};
