import { useCallback } from "react";
import { useSelector } from "react-redux";

import { ProjectSelectors } from "src/redux/selectors";

interface UseIsInstallerSelectedReturn {
  isSelected: (id: string) => boolean;
}
export const useIsInstallerSelected = (): UseIsInstallerSelectedReturn => {
  const selectedInstallers = useSelector(ProjectSelectors.getSelectedInstaller);

  const isSelected = useCallback(
    (id: string) => {
      return selectedInstallers.some((doc) => doc.iid === id);
    },
    [selectedInstallers],
  );

  return {
    isSelected,
  };
};
