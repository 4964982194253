import styled from "styled-components/macro";
export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
interface SubContainer1Props {
  openAssumptions: any;
}
interface PercentageProps {
  istwoNum?: Boolean;
}
interface InputPropsThing {
  isFloatValue?: boolean;
}
export const SubContainer1 = styled.div<SubContainer1Props>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 48px;
  padding-left: 17px;
  cursor: pointer;
  &:hover {
    background: #f9f9f9;
  }
`;
export const SubContainer2 = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 208px;
  padding-left: 12px;
`;
export const SubContainer3 = styled.div`
  width: 90%;
`;
export const SubContainer4 = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .percentage {
    position: absolute;
    right: 6px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2d4764;
  }
`;
export const Percentage = styled.div<PercentageProps>`
  position: absolute;
  right: ${(props) => {
    if (props.istwoNum) {
      return "13px";
    } else {
      return "6px";
    }
  }};
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
`;
export const PercentageEUR = styled.div`
  position: absolute;
  font-family: "Nunito";
  right: 11px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
  top: 6px;
`;
export const SubContainer22 = styled.div<SubContainer1Props>`
  margin-top: 10px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: ${(props) => {
      if (props.openAssumptions) {
        return "#dfdfdf";
      }
    }};
  }
`;
export const AllSubMainText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
  width: 40%;
`;
export const AllSubMainTextTemp = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
`;
export const AllSubMainValue = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
`;
export const AllSubMain1Text = styled.span`
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #2d4764;
`;

export const InputValueWrapper = styled.div`
  min-width: 85px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

export const AllSubMainInput = styled.input<InputPropsThing>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  margin-left: 1.5rem;
  width: 63px;
  height: 27px;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 16px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #304864;
`;
