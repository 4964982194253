import ArrowIcon from "./ArrowIcon";
import * as S from "./Arrows.styles";

interface ArrowBtnProps {
  className?: string;
  style?: React.CSSProperties;
  ownStyles?: React.CSSProperties;
  onClick: () => void;
}
export const NextArrow: React.FC<ArrowBtnProps> = (props: ArrowBtnProps) => {
  const { style, ownStyles, onClick } = props;

  return (
    <S.NextButton
      onClick={onClick}
      style={{
        ...style,
        ...ownStyles,
        display: onClick === null ? "none" : "block",
        position: "absolute",
        right: window.innerWidth > 500 ? "-56px" : "-20px",
        stroke: "var(--primary-color)",
      }}
    >
      <ArrowIcon></ArrowIcon>
    </S.NextButton>
  );
};

export const PrevArrow: React.FC<ArrowBtnProps> = (props: ArrowBtnProps) => {
  const { style, ownStyles, onClick } = props;

  return (
    <S.PrevButton
      onClick={onClick}
      style={{
        ...style,
        ...ownStyles,
        display: onClick === null ? "none" : "block",
        position: "absolute",
        left: window.innerWidth > 500 ? "-56px" : "-20px",
        stroke: "var(--primary-color)",
      }}
    >
      <ArrowIcon></ArrowIcon>
    </S.PrevButton>
  );
};
