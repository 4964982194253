import { getNumber } from "src/utils";

import store from "src/redux/store";

export const getUniqueCarId = (): string => {
  const state = store.getState();
  const carsIds = state.project.components.cars
    .map((c) => getNumber(c.id.split(" ")[1]))
    .sort((a, b) => b - a);
  if (carsIds.length === 0) return "Car 1";
  else return `Car ${carsIds[0] + 1}`;
};
