import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";

import { ProductDB } from "src/db-types";
import { PopupSkeleton } from "src/shared/components";
import { convertToEmbedYoutubeLink } from "src/utils";

import { closeProductDetailsPopup } from "src/redux/popups/action/action.creators";
import { PopupSelectors } from "src/redux/selectors";

import "react-image-gallery/styles/css/image-gallery.css";

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "100%",
    maxWidth: "900px",
    padding: "0 100px 50px",
    paddingTop: "2rem",
    margin: "0px",
    display: "flex",
    flexDirection: "column",

    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
    "& h1": {
      margin: "10px 3px",
      maxWidth: "700px",
      fontSize: "25px",
      borderLeft: "3px solid #FFD75D",
      paddingLeft: "10px",
    },
    "& p": {
      maxWidth: "700px",
      margin: "0px 3px 10px 0px",
    },
    "& h3": {
      maxWidth: "700px",
      margin: "25px 0px",
    },
    "& h5": {
      maxWidth: "700px",
      margin: "10px 0px",
      display: "flex",
      alignItem: "center",
      fontSize: "15px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0  60px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 30px 50px",
    },
  },

  img_container: {
    maxWidth: "700px",
    margin: "25px 0px",
    backgroundColor: "white",
  },
  container1: {
    display: "flex",
    "& img": {
      width: "25px",
      height: "25px",
      marginRight: "15px",
    },
  },
  container2: {
    display: "flex",
    "& img": {
      width: "20px",
      height: "20px",
      marginRight: "15px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "0px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  img: {
    objectFit: "contain",
    width: "100% !important",
    margin: "auto !important",
    maxWidth: "400px !important",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
  },
  vedioContainer: {
    width: "100% !important",
    margin: "auto !important",
    aspectRatio: "1.5",
  },
  bulletDot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    background: "#ffce31",
    marginRight: "20px",
    marginTop: "4px",
  },
}));

export interface ProductDetailsPopupProps {
  product: ProductDB;
}

export const ProductDetails: React.FC = () => {
  const {
    data: { product },
  } = useSelector(PopupSelectors.getProductDetailsPopup);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();

  const [imageArray, setimageArray] = useState<string[]>([]);
  const [videoLink, setVideoLink] = useState("");

  useEffect(() => {
    if (product.videoLink) {
      const embedLink = convertToEmbedYoutubeLink(product.videoLink);
      setVideoLink(embedLink);
    }

    setimageArray(product.imgUrls ?? []);
  }, [product.imgUrls, product.videoLink]);

  function SampleNextArrow(props: any): any {
    const { style, onClick } = props;
    return (
      <ArrowForwardIos
        className="arrow_icon"
        style={{
          ...style,
          display: onClick === null ? "none" : "block",
          position: "absolute",
          right: window.innerWidth > 500 ? "-30px" : "-20px",
          top: "40%",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props: any): any {
    const { style, onClick } = props;
    return (
      <ArrowBackIos
        className="arrow_icon"
        style={{
          ...style,
          display: onClick === null ? "none" : "block",
          position: "absolute",
          left: window.innerWidth > 500 ? "-30px" : "-10px",
          top: "40%",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    accessibility: true,
    infinite: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: function (prev: any, next: any) {},
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const productName = useMemo(() => {
    switch (product.type) {
      case "solarPanel":
        return "Solar Panel";
      case "battery":
        return "Battery";
      case "heatpump":
        return "Heat pump";
      case "wallbox":
        return "Wall Box";
      case "inverter":
        return "Inverter";
      case "car":
        return "Car";
      case "additionalSoftware":
        return "AdditionalSoftware";
      case "additionalHardware":
        return "AdditionalHardware";
      case "EMS":
        return "EMS";
    }
  }, [product.type]);

  if (!product) return <></>;

  return (
    <PopupSkeleton
      handleClose={() => {
        dispatch(closeProductDetailsPopup());
      }}
      open={true}
    >
      <div className={classes.root}>
        <h1>{t(productName)}</h1>
        <Typography>{product.name}</Typography>
        <div className={classes.img_container}>
          <Slider {...settings}>
            {imageArray.map((data: string) => {
              return (
                <div key={data} style={{ textAlign: "center" }}>
                  <img height={500} className={classes.img} src={data} alt={productName} />
                </div>
              );
            })}
            {videoLink && (
              <div className={classes.vedioContainer}>
                <iframe
                  width="100%"
                  height="100%"
                  src={videoLink}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </Slider>
        </div>
        <h3>{t("Product Details")}</h3>
        {Array.isArray(product.bullet_point_list) &&
          product.bullet_point_list?.map((point: any) => (
            <div key={point} className={classes.container2}>
              <div className={classes.bulletDot} />
              <Typography>{point}</Typography>
            </div>
          ))}
        {/* <Typography style={{ marginTop: "15px" }}>
          {t("Short Description")}
        </Typography>
        <Typography style={{ fontSize: "14px" }}>
          {product.}
        </Typography> */}
        <Typography style={{ marginTop: "15px" }}>{t("Long Description")}</Typography>
        <Typography style={{ fontSize: "14px" }}>{product.longDescription}</Typography>
        {product.Herstellerinformation && (
          <>
            <Typography style={{ marginTop: "15px" }}>{t("Suppliers Information")}</Typography>
            <Typography style={{ fontSize: "14px" }}>{product.Herstellerinformation}</Typography>
          </>
        )}
        <h5>
          <a
            href={`${product.pdfUrl ?? ""}`}
            target="_blank"
            style={{ color: "black", display: "flex" }}
            rel="noreferrer"
          >
            <GetAppIcon style={{ marginRight: "15px" }} />
            {t("Download the PDF")}
          </a>
        </h5>
        <Typography style={{ marginTop: "15px" }}>{t("Gaurantee")}</Typography>
        <Typography style={{ fontSize: "14px" }}>{product.Garantie}</Typography>
        <Typography style={{ marginTop: "15px" }}>{t("Technical Product Data")}</Typography>
        {Array.isArray(product.TechnischeProduktdaten) &&
          product.TechnischeProduktdaten.map((detail) => (
            <div key={detail} className={classes.container2}>
              <div className={classes.bulletDot} />
              <Typography>{detail}</Typography>
            </div>
          ))}
        <Typography style={{ marginTop: "15px" }}>
          <a href={`${product.warrantyLink ?? ""}`} target="_blank" rel="noreferrer">
            {t("Checkout warrenty")}
          </a>
        </Typography>
      </div>
    </PopupSkeleton>
  );
};
