import { Slider } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useNumberFormat } from "src/hooks/useNumberFormat";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import * as S from "../../HeatpumpConfigurationBox.styles";

import { HeatpumpConfiguration } from "src/redux/project";
import { ProjectSelectors } from "src/redux/selectors";

interface OilGasCostLineProps {
  onValueChange: (heatpumpPartData: Partial<HeatpumpConfiguration>) => void;
}
const OilGasCostLine: React.FC<OilGasCostLineProps> = (props: OilGasCostLineProps) => {
  const { onValueChange } = props;

  const { formatNumber } = useNumberFormat();
  const { t } = useTranslation();

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);

  const onSliderChangeHandler = useCallback(
    (value: any) => {
      onValueChange({
        oilGasCost: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line hasSlider>
      <ParameterStyles.Label hasSlider>{t("OIL GAS COST")}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider
          max={0.75}
          step={0.01}
          value={configuration.oilGasCost}
          onChange={onSliderChangeHandler}
        />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <S.SubContainerValue>
          {formatNumber(configuration.oilGasCost, { roundTill: 2 })} €/kWh
        </S.SubContainerValue>
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default OilGasCostLine;
