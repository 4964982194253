import styled from "styled-components/macro";

export const Installer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  .product_button {
    border: 1px solid #304864;
    border-radius: 36px;
    height: 46px;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
    border-radius: 36px;
    font-family: "Nunito";
    margin-top: 10px;
    margin-bottom: 10px;
    font-style: normal;
    background: #ffd75d;
    font-weight: 700;
    font-size: 17px;
    line-height: 33px;
    color: #304864;
    @media (max-width: 400px) {
      font-size: 12px;
      margin-inline: 6px;
      line-height: 0px;
    }
  }
  .makeItFlex {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 0px 9px rgba(162, 162, 162, 0.25);
  border-radius: 22px;
  padding: 40px;
  padding-right: 14px;
  padding-left: 24px;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 500px) {
    width: 100%;
    box-shadow: 0 0 0;
    padding: 0;
    padding-top: 10px;
    margin: 20px 0 0;
    align-items: center;
    gap: 0px;
  }
  .vendors {
    width: 810px;
    height: 350px;
    background-image: "url('images/application/application_step_4/searching.png')";
    display: flex;
    @media (max-width: 430px) {
      width: 98%;
      box-shadow: 0px 2px 10px rgb(0 0 0 / 15%);
      border-radius: 12px;
    }
    justify-content: center;
    align-items: center;
  }
`;
export const Heading = styled.h2`
  font-family: "Nunito";
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #2d4764;

  @media only screen and (max-width: 500px) {
    width: 80%;
  }
`;
export const InstallerSlider = styled.div`
  padding: 0 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  margin-top: -20px;
  @media only screen and (max-width: 500px) {
    max-width: 80%;
    margin: auto;
    padding: 0;
  }
  .slick-slider {
    width: 100%;
  }
`;
interface ButtonProps {
  shown: Boolean;
}
export const PrevButton = styled.button`
  border-radius: 50%;
  border: 1px solid #666666;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translatey(-50%) rotate(180deg);
  @media only screen and (max-width: 500px) {
    left: 12px;
  }

  img {
    margin: auto;
  }
`;

export const NextButton = styled.button`
  border-radius: 50%;
  border: 1px solid #666666;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translatey(-50%);
  @media only screen and (max-width: 500px) {
    right: 0px;
    background: white;
  }

  img {
    margin: auto;
  }
`;
