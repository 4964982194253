import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { getUserPreferredLanguageShort } from "src/utils";

import deTranslations from "./de/translation.json";
import enTranslations from "./en/translation.json";
import esTranslations from "./es/translation.json";
import frTranslations from "./fr/translation.json";
import { missingKeyHandler } from "./missingKeyHandler";

export const resources = {
  en: {
    translations: enTranslations,
  },
  de: {
    translations: deTranslations,
  },
  es: {
    translations: esTranslations,
  },
  fr: {
    translations: frTranslations,
  },
} as const;

void i18n.use(initReactI18next).init({
  lng: getUserPreferredLanguageShort(),
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  saveMissing: false,
  parseMissingKeyHandler: missingKeyHandler,
  resources,
  keySeparator: false,
});

export default i18n;
