import { ProductWithPrice } from "src/db-types";

export const getAverageProductCost = (products: ProductWithPrice[]): number => {
  if (products.length <= 0) {
    // console.error("Cannot find any filterable product for preference :", filters, products);
    return 0;
  }

  let total = 0;
  for (const product of products) {
    total += product.buyInfo.totalPrice;
  }

  return total / products.length;
};
