export const colors = {
  darkRain: "linear-gradient(to left,#09152F,#1B4963)",
  darkBlue: "#1B4963",
  greenHover: "linear-gradient(to left,#197061 ,#1B7A69)",
  linear: "linear-gradient(to left,#FFE925 ,#FFAA39)",
  sunny: "linear-gradient(to left,#FBDA61 ,#FFC371)",
  sun: "linear-gradient(to left,#FBDA61 ,#F76B1C)",
  sun_r: "linear-gradient(to right,#FBDA61 ,#F76B1C)",
  fire: "linear-gradient(to left,#FF5F6D  ,#FFC371)",
  orangeSolid: "#FFAA39",
  ctaSolid: "#FFD75D",
  lightYellow: "#FFF3CA",
  white: "#FFFFFF",
};
