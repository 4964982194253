import React from "react";
import * as S from "./ThreeDModel.styles"
import { PopupSkeleton } from "src/shared/components";
import { useDispatch, useSelector } from "react-redux";
import { POPUP_KEYS } from "src/redux/popups";
import { closePopup } from "src/redux/popups/action/action.creators";
import ThreeDViewer from "src/pages/ApplicationPage/components/ApplicationStep3/components/3DViewer/3DViewer";
import { useNaivgateAbsolute } from "src/hooks";

export const ThreeDModel: React.FC = () => {
    const dispatch = useDispatch();
  const navigate = useNaivgateAbsolute();


    return(
        <PopupSkeleton handleClose={() => {dispatch(closePopup(POPUP_KEYS.threedmodel));
         navigate(`/useroverview/my-project-id`) }} open={true}>
            <S.MainContentWrapper>
                <ThreeDViewer isFromSavedProject/>
            </S.MainContentWrapper>

        </PopupSkeleton>
    )
}
export default ThreeDModel
