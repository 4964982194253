import styled from "styled-components";

export const RadiationContainer = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .slider{
  }

  .radiation_text {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    color: #2D4764;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .shaddy_sunny{
    padding-top: 7px;   
    .shaddy_text {
        display: flex;
        justify-content: space-between;
        padding-right: 25px;
       padding-top: 7px;   
       padding-bottom: 12px;
    }
    
    p {
        font-family: Nunito;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
        color: #2D4764;
    }
  }
`;

export const SelectFeedInWrapper = styled("div")`
  .main_class {
    box-sizing: border-box;
    width: fit-content;
    height: 29px;
    border: 1px solid #2D4764;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 5px;
    gap: 10px;

    @media (max-width: 600px) {
      width: auto;
    }
  }
  .same_tab {
    padding-inline: 10px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #2d4764;
    height: 27px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      background: #ffd75d;
      cursor: pointer;
    }
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }
  .seleted {
    background: #ffd75d;
  }
`;