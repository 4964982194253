import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch, useWindowDimensions } from "src/hooks";

import { DeleteItIcon } from "src/assets/svgs";
import { MasterInput } from "src/shared/components";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";

interface InputBoxProps {
  onInputClick: React.MouseEventHandler;
}
const InputBox: React.FC<InputBoxProps> = (props: InputBoxProps) => {
  const { onInputClick } = props;

  const dispatch = useAppDispatch();

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);

  const [boxHover, setBoxHover] = useState(false);

  const { width } = useWindowDimensions();

  const onInputChangeHandler = useCallback(
    (data: number) => {
      dispatch(
        ProjectActions.updateHeatpump({
          configuration: {
            electricityCostHeatpump: data,
          },
        }),
      );
    },
    [dispatch],
  );

  const onDeleteClickHandler = useCallback(() => {
    dispatch(ProjectActions.deleteHeatpump());
  }, [dispatch]);

  const showDeleteButton = boxHover || width <= 500;

  return (
    <div
      className="make_it_bc_flex"
      onMouseEnter={() => setBoxHover(true)}
      onMouseLeave={() => setBoxHover(false)}
    >
      <div className="same_buttons2">
        <MasterInput
          value={configuration.electricityCostHeatpump}
          onChange={onInputChangeHandler}
          onClick={onInputClick}
          label="kWh"
        />
      </div>
      {showDeleteButton && (
        <DeleteItIcon style={{ cursor: "pointer" }} onClick={onDeleteClickHandler} />
      )}
    </div>
  );
};

export default InputBox;
