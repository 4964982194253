import styled from "styled-components/macro";
export const SearchSelectorWrapper = styled("div")`
  --border-radius: 24px;
  --border-color: #f2ce60;
  --border-width: 2px;

  width: 100%;
  .mainBar {
    position: relative;

    box-sizing: border-box;
    margin: auto;

    width: 100%;
    max-width: 500px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background: #ffffff;
    border: var(--border-width) solid var(--border-color);
    border-radius: var(--border-radius);
  }
  .extra {
    height: auto;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    border-inline: var(--border-width) solid var(--border-color);
    border-top: var(--border-width) solid var(--border-color);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }
  .right_button {
    background: #f2ce60;
    border-radius: 336px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    text-align: center;
    letter-spacing: 0.005em;
    color: #2d4764;
    // margin-left: 3px;
    :hover {
      background: #f2ce60;
    }
  }
  .reslut {
    height: auto;
    padding-top: 6px;
    border-radius: 0px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    :hover {
      /* background: rgba(253, 184, 0, 0.1); */
      cursor: pointer;
    }
    padding-left: 22px;
  }
  .resultContaijer {
    position: absolute;
    background: white;
    width: calc(100% + calc(2 * var(--border-width)));
    top: 41px;
    left: calc(-1 * var(--border-width));
    border-inline: var(--border-width) solid var(--border-color);
    border-bottom: var(--border-width) solid var(--border-color);
    padding-bottom: 1rem;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
  .active {
    background: rgba(253, 184, 0, 0.1);
  }
  .secound_part {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
  }
  .extra_button {
    border-radius: 0px 20px 0px 0px;
  }
  .left_side {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    padding-left: 14px;
    min-width: 0;
  }
  .extra_left {
    padding-top: 3px;
  }
  .input_main {
    height: 41px;
    outline: none;
    padding-top: 3px;
    padding-left: 10px;
    font-size: 18px;
    flex-grow: 1;
    min-width: 0;
    ::placeholder {
      font-weight: bold;
    }
  }
  .text {
    font-family: "Nunito";
    font-style: normal;
    padding-left: 5px;
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    color: #304864;
    margin-left: auto;
    margin-right: 4px;
  }
`;
